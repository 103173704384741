import { onboardingTasksKeysDTO, onboardingTasksKeysAPIDTO } from "@/constants";

const makeOnboardingTaskDTO = ({ onboardingTask = {} } = {}) => ({
  [onboardingTasksKeysDTO.TASK_ID]:
    onboardingTask?.[onboardingTasksKeysAPIDTO.TASK_ID] || "-",
  [onboardingTasksKeysDTO.FORM_ID]:
    onboardingTask?.[onboardingTasksKeysAPIDTO.FORM_ID] || "-",
  [onboardingTasksKeysDTO.LANGUAGE]:
    onboardingTask?.[onboardingTasksKeysAPIDTO.LANGUAGE] || "-",
  [onboardingTasksKeysDTO.TASK_STATUS]:
    onboardingTask?.[onboardingTasksKeysAPIDTO.TASK_STATUS] || "-",
  [onboardingTasksKeysDTO.TASK_TITLE]:
    onboardingTask?.[onboardingTasksKeysAPIDTO.TASK_TITLE] || "-",
  [onboardingTasksKeysDTO.USER_ID]:
    onboardingTask?.[onboardingTasksKeysAPIDTO.USER_ID] || "-",
  [onboardingTasksKeysDTO.THIRD_PARTY_ID]:
    onboardingTask?.[onboardingTasksKeysAPIDTO.THIRD_PARTY_ID] || "-",
  [onboardingTasksKeysDTO.COMP_NAME_ENG]:
    onboardingTask?.[onboardingTasksKeysAPIDTO.COMP_NAME_ENG] || "-",
  [onboardingTasksKeysDTO.COUNTRY]:
    onboardingTask?.[onboardingTasksKeysAPIDTO.COUNTRY] || "-",
  [onboardingTasksKeysDTO.ONBOARDING_STAGE_TASKS_CREATED_AT]:
    onboardingTask?.[
      onboardingTasksKeysAPIDTO.ONBOARDING_STAGE_TASKS_CREATED_AT
    ] || "-",
  [onboardingTasksKeysDTO.SCHEMA]:
    onboardingTask?.[onboardingTasksKeysAPIDTO.SCHEMA] || null,
  [onboardingTasksKeysDTO.TASK_DESCRIPTION]:
    onboardingTask?.[onboardingTasksKeysAPIDTO.TASK_DESCRIPTION] || "-",
  [onboardingTasksKeysDTO.TASK_TYPE]:
    onboardingTask?.[onboardingTasksKeysAPIDTO.TASK_TYPE] || "-",
  [onboardingTasksKeysDTO.FORM]:
    onboardingTask?.[onboardingTasksKeysAPIDTO.FORM] || null,
  [onboardingTasksKeysDTO.FORM_DATA]:
    onboardingTask?.[onboardingTasksKeysAPIDTO.FORM_DATA] || null
});

const makeOnboardingTasksDTO = ({
  onboardingTasks = [],
  _makeOnboardingTaskDTO = makeOnboardingTaskDTO
} = {}) =>
  onboardingTasks?.map((onboardingTask) =>
    _makeOnboardingTaskDTO({ onboardingTask })
  ) || [];

export { makeOnboardingTasksDTO, makeOnboardingTaskDTO };
