<template id="template-input">
  <div>
    <b-form-group class="mt-2">
      <label>
        {{
          attributeData.tp_alias
            ? attributeData.tp_alias
            : attributeData.tp_customatt
        }}
      </label>
      <b-button
        variant="outline-primary"
        @click="showAddDocumentModal = !showAddDocumentModal"
        v-if="!form.fileName && files.length == 0"
      >
        {{ attributeData.tp_type == "image" ? "Upload Image" : "Upload File" }}
      </b-button>
      <b-row v-if="files.length > 0">
        <b-overlay :show="isLoading">
          <b-col cols="12">
            <table class="table table-striped">
              <thead class="thead-dark">
                <tr>
                  <th v-if="files[0].hash_id"></th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Size</th>
                  <th>Action</th>
                </tr>
              </thead>
              <draggable v-model="files" tag="tbody" ghost-class="ghost">
                <template #item="{ element }">
                  <tr class="sort">
                    <td v-if="element.hash_id">
                      <b-btn
                        @click="openImage(element.hash_id)"
                        class="mr-2"
                        size="sm"
                        variant="outline-dark"
                      >
                        <font-awesome-icon icon="folder" />
                      </b-btn>
                    </td>
                    <td>{{ JSON.parse(element.meta_data).fileName }}</td>
                    <td>{{ JSON.parse(element.meta_data).fileType }}</td>
                    <td>
                      {{
                        readableBytes(JSON.parse(element.meta_data).fileSize)
                      }}
                    </td>
                    <td>
                      <font-awesome-icon
                        icon="trash"
                        @click.native="onDeleteSlide(index, element)"
                      />
                    </td>
                  </tr>
                </template>
              </draggable>
            </table>
          </b-col>
        </b-overlay>
      </b-row>
      <div
        style="font-size: 12px; color: red"
        v-if="parseInt(attributeData.tp_is_required) && !form.fileName"
      >
        A Required field has no value.
      </div>
    </b-form-group>
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      title="Add Document"
      v-model="showAddDocumentModal"
      scrollable
      size="lg"
    >
      <div>
        <div v-if="!submiting && !responseSuccess">
          <b-overlay :show="uploading">
            <b-row>
              <b-col cols="12">
                <label>Upload Supporting Document :</label>
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  @vdropzone-success="vsuccess"
                  :options="
                    attributeData.tp_type == 'image'
                      ? dropzoneImageOptions
                      : dropzoneOptions
                  "
                  :useCustomSlot="true"
                  @vdropzone-file-added="onUploadPreview"
                  v-on:vdropzone-sending="sendingEvent"
                  @vdropzone-error="verror"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      Drag and drop to upload content!
                    </h3>
                    <div class="subtitle">
                      ...or click to select a file from your computer
                    </div>
                  </div>
                </vue-dropzone>
              </b-col>
            </b-row>
          </b-overlay>
        </div>
        <div v-else-if="submiting && !responseSuccess">
          <p>{{ $t("eb360.documentsTabComponent.uploadingDoc") }}</p>
          <b-spinner type="grow" :label="$t('loadingSpinnerText')" />
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button
          variant="outline-danger"
          v-if="!submiting"
          :disabled="uploading"
          @click="resetModal"
          >Close</b-button
        >
        <b-button
          size="md"
          v-if="!submiting && !responseSuccess"
          :disabled="uploading"
          @click="checkValidation()"
          variant="outline-success"
          >{{ $t("uploadBtn") }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import vue2Dropzone from "@/components/Dropzone/VueDropzone";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import draggable from "vuedraggable";
import { mapState } from "vuex";

draggable.compatConfig = { MODE: 3 };

export default {
  name: "File",
  components: {
    vueDropzone: vue2Dropzone,
    draggable
  },
  data() {
    return {
      form: {
        fileName: null,
        fileType: null,
        fileUploader: null,
        uploadedDate: null,
        status: null,
        fileSize: null
      },
      dropzoneOptions: {
        url: this.$store.state.isTpEditModal
          ? `${process.env.VUE_APP_API_URL}thirdparty/uploaddocument`
          : `${process.env.VUE_APP_API_URL}thirdparty/uploaddocumentfornewtp`,
        thumbnailWidth: 150,
        maxFilesize: 25,
        autoProcessQueue: false,
        addRemoveLinks: true,
        acceptedFiles: [
          "application/pdf",
          "application/msword",
          "application/vnd.ms-excel",
          "application/vnd.ms-powerpoint",
          "text/plain",
          "image/jpeg",
          "image/png"
        ].join(),
        headers: makeAuthorizationHeader()
      },
      dropzoneImageOptions: {
        url: `${process.env.VUE_APP_API_URL}thirdparty/${
          this.$store.state.isTpEditModal
            ? "uploaddocument"
            : "uploaddocumentfornewtp"
        }`,
        thumbnailWidth: 150,
        maxFilesize: 25,
        autoProcessQueue: false,
        addRemoveLinks: true,
        acceptedFiles: ["image/jpg", "image/jpeg", "image/png"].join(),
        headers: makeAuthorizationHeader()
      },
      submiting: false,
      responseSuccess: false,
      uploading: false,
      showAddDocumentModal: false,
      thumbnailProps: { width: 90, height: 90 },
      files: [],
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId,
      userId: (state) => state.user.userId
    })
  },
  mounted() {
    if (this.values.value && this.values.hash_id) {
      this.form = {
        fileName: JSON.parse(this.values.meta_data).fileName,
        displayName: JSON.parse(this.values.meta_data).fileName,
        fileType: JSON.parse(this.values.meta_data).fileType,
        comments: null,
        fileSize: JSON.parse(this.values.meta_data).fileSize
      };
      this.files = [
        {
          meta_data: this.values.meta_data,
          id: parseInt(this.values.value),
          file: this.values.file,
          hash_id: this.values.hash_id
        }
      ];
    }
  },
  methods: {
    openImage(hashId) {
      let urlAddress =
        `${process.env.VUE_APP_BASE_URL}/` +
        `download_documents.php?docid=${hashId}`;
      window.open(urlAddress);
    },
    resetModal() {
      this.showAddDocumentModal = false;
      if (this.files.length == 0) {
        this.form = {
          fileName: null,
          displayName: null,
          fileType: null,
          comments: null,
          fileSize: null
        };
      }
      this.submiting = false;
      this.uploading = false;
      this.responseSuccess = false;
    },
    readableBytes(bytes) {
      let i = Math.floor(Math.log(bytes) / Math.log(1024));
      let sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      return `${(bytes / Math.pow(1024, i)).toFixed(2) * 1} ${sizes[i]}`;
    },
    onDeleteSlide(index, file) {
      this.isLoading = true;
      let formData = {
        documentId: file.id,
        file: file.file,
        companyId: this.companyId
      };
      let result = HTTP(
        "post",
        "thirdparty/deletedocumentfornewtp",
        formData,
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          if (response.data.status == 1) {
            this.$bvToast.toast(response.data.message, {
              noAutoHide: false,
              appendToast: true
            });
            this.files = [];
            this.form = {
              fileName: null,
              displayName: null,
              fileType: null,
              comments: null,
              fileSize: null
            };
          }
          this.isLoading = false;
        })

        .catch((error) => {
          this.isLoading = false;
          return [error];
        });
    },
    checkValidation() {
      if (parseInt(this.attributeData.tp_is_required) && !this.form.fileName) {
        return false;
      } else {
        if (this.$refs.myVueDropzone.getQueuedFiles().length > 0) {
          this.uploading = true;
          this.$refs.myVueDropzone.processQueue();
        }
      }
    },
    vsuccess(file, response) {
      this.uploading = false;
      this.submiting = false;
      this.responseSuccess = true;
      this.files.push(response.document);
      this.$bvToast.toast(
        this.$t("eb360.documentsTabComponent.uploadSuccessfull"),
        {
          title: this.$t("eb360.documentsTabComponent.completeDoc"),
          noAutoHide: false,
          appendToast: true
        }
      );
      this.resetModal();
      this.saveValue();
    },
    verror(file, message) {
      this.uploading = false;
      this.submiting = false;
      this.responseSuccess = false;
      this.$bvToast.toast(message, {
        noAutoHide: false,
        appendToast: true
      });
    },
    onUploadPreview(file) {
      if (file) {
        this.form.fileName = file.name;
        this.form.fileType = file.type;
        this.form.fileSize = file.size;
      }
    },
    sendingEvent(file, xhr, formData) {
      this.uploading = false;
      let metaData = {
        fileName: this.form.fileName,
        fileType: this.form.fileType,
        fileSize: this.form.fileSize
      };
      formData.append("companyId", this.companyId);
      formData.append("memberId", this.userId);
      formData.append("displayName", this.form.fileName.split(".")[0]);
      formData.append("meta_data", JSON.stringify(metaData));
      if (this.$store.state.isTpEditModal) {
        formData.append(
          "thirdPartyId",
          localStorage.getItem("editThirdPartyId")
        );
      }
    },
    saveValue() {
      if (parseInt(this.attributeData.tp_is_required)) {
        EventBus.emit("saveValue", {
          value: this.files[0].id,
          id: this.attributeData.id,
          error: parseInt(this.attributeData.tp_is_required) && !this.form,
          type: this.attributeData.tp_type
        });
      } else {
        EventBus.emit("saveValue", {
          value: this.files[0].id,
          id: this.attributeData.id,
          error: false,
          type: this.attributeData.tp_type
        });
      }
    }
  },
  props: {
    attributeData: Object,
    values: Object
  }
};
</script>
