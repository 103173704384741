import { IfStatementClass } from "@/molecules/IfBlock/classes/IfStatement.class";
import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";
import IfBlock from "@/molecules/IfBlock/IfBlock";
import {
  actionKeys,
  actionStepType,
  comparatorOptions,
  ifBlockEventType,
  joinOperatorOptions,
  operations,
  variableType,
  actionStepSubType
} from "@/constants";
import { makeBracketSectionDTO } from "@/molecules/BracketSection/BracketSection.dto";
import { cloneDeep, isEmpty } from "lodash";
import SetToBlock from "@/molecules/SetToBlock/SetToBlock";
import SetToBlockMixin from "@/organisms/AddEditAction/mixins/SetToBlockMixin/SetToBlock.mixin";
import { getSelectedComparatorOption, getSelectedOption } from "@/utils";

export default {
  data() {
    return {
      ifStatementList: [],
      emptyIfBlock: {
        stepType: "IF",
        ifBlock: {
          condition: [
            {
              comparator: "",
              isInverse: false,
              isGroup: false,
              left: {
                type: "",
                data: {
                  value: ""
                }
              },
              right: {
                type: "",
                data: {
                  value: ""
                }
              }
            }
          ],
          successSteps: []
        }
      }
    };
  },
  mixins: [SetToBlockMixin],
  methods: {
    isHasOrHasNoValue(value) {
      return (
        value === operations.HAS_NO_VALUE || value === operations.HAS_VALUE
      );
    },
    isRightValueNull(right) {
      return right?.data?.data?.value === null;
    },
    makeNewIfStatement({
      selectedLeftValue,
      leftOptions,
      comparatorOptions,
      operatorOptions,
      stepIndex,
      rightComponentOptions
    }) {
      const ifStatement = new IfStatementClass({
        selectedLeftValue,
        leftOptions,
        comparatorOptions,
        operatorOptions,
        rightComponent: SetToBlock,
        rightComponentOptions
      });

      this.ifStatementList.push({
        stepIndex,
        ifStatement,
        setToBlockId: rightComponentOptions?.id
      });
      return ifStatement;
    },
    makeIfStatementFromStepData(
      left,
      right,
      stepIndex,
      comparator,
      isInverse,
      operatorOptions
    ) {
      const selectedLeftValue =
        left?.data?.data?.name || left?.data?.data?.data?.name || "";
      const leftOptions = this.makeIfBlockLeftOptions(
        stepIndex,
        selectedLeftValue
      );
      const selectedOperatorValue =
        getSelectedOption(operatorOptions)?.value || "";
      const mappedOperatorOptions = makeOptionsForSelect(
        selectedOperatorValue,
        operatorOptions
      );
      const comparatorOptions = this.makeInitialComparatorOptions(
        comparator,
        isInverse,
        this.isRightValueNull(right)
      );
      const rightComponentOptions = this.makeSetToBlock({
        leftOptions,
        right,
        stepIndex,
        selectedLeftValue
      });

      if (isEmpty(getSelectedOption(leftOptions)) && leftOptions?.length) {
        leftOptions[0].selected = true;
      }

      if (
        isEmpty(getSelectedOption(comparatorOptions)) &&
        comparatorOptions?.length
      ) {
        comparatorOptions[0].selected = true;
      }

      return this.makeNewIfStatement({
        selectedLeftValue: selectedLeftValue || leftOptions[0]?.value,
        leftOptions,
        comparatorOptions,
        operatorOptions: mappedOperatorOptions,
        stepIndex,
        rightComponentOptions
      });
    },
    makeIfBlockBracket(ifStatements) {
      return {
        component: IfBlock,
        componentOptions: {
          ifStatements
        }
      };
    },
    makeSingleIfStatementBracket(condition, stepIndex) {
      const ifStatement = this.makeIfStatementFromStepData(
        condition.left,
        condition.right,
        stepIndex,
        condition.comparator,
        condition.isInverse,
        makeOptionsForSelect(operations.AND, joinOperatorOptions)
      );

      return this.makeIfBlockBracket([ifStatement]);
    },
    makeGroupIfStatementBracket(condition, stepIndex, operatorOptions) {
      const conditions = condition?.conditions || condition;
      const ifStatements = conditions.map(
        ({ comparator = "", left = {}, right = {}, isInverse }) =>
          this.makeIfStatementFromStepData(
            left,
            right,
            stepIndex,
            comparator,
            isInverse,
            operatorOptions
          )
      );

      return this.makeIfBlockBracket(ifStatements);
    },
    makeNewIfStep(stepIndex = this.functionSteps.length - 1) {
      this.updateAllStepIndexes(operations.ADD, stepIndex);
      const ifStatementIndex = this.ifStatementList.length;
      const newIfBlockData = this.makeIfStep(
        cloneDeep(this.emptyIfBlock),
        stepIndex
      );

      this.functionSteps.splice(stepIndex, 0, newIfBlockData);
      this.updateSingleIfStatementLeftOptions(ifStatementIndex);
    },
    makeSuccessSteps(functionSteps, stepIndex) {
      return (
        functionSteps?.map((step) => {
          if (step.stepType === actionStepType.SET) {
            return this.makeSuccessSetStep(step, stepIndex);
          } else if (step.stepType === actionStepType.END) {
            return this.makeSuccessEndStep(stepIndex);
          } else if (step.stepType === actionStepType.TRIGGER) {
            return this.makeSuccessTriggerStep(step, stepIndex);
          } else if (step.stepType === actionStepType.TASK) {
            return this.makeSuccessTaskStep({ taskStep: step, stepIndex });
          } else if (step.stepType === actionStepSubType.EMAIL) {
            return this.makeSuccessEmailNotificationStep({
              emailNotificationStep: step,
              stepIndex
            });
          }
        }) || []
      );
    },
    makeIfStatementCondition(condition, stepIndex) {
      const bracketSections = [];

      if (condition.isGroup) {
        if (condition.operator === operations.AND) {
          const operatorOptions = makeOptionsForSelect(
            condition.operator,
            joinOperatorOptions
          );
          const bracket = this.makeGroupIfStatementBracket(
            condition,
            stepIndex,
            operatorOptions
          );

          bracketSections.push(
            makeBracketSectionDTO({
              bracket,
              operatorOptions
            })
          );
        } else if (condition.operator === operations.OR) {
          const splitOperator = makeOptionsForSelect(
            condition.operator,
            joinOperatorOptions
          );
          condition.conditions.forEach((condition) => {
            let bracket;

            if (condition.isGroup && condition.operator === operations.AND) {
              const operatorOptions = makeOptionsForSelect(
                condition.operator,
                joinOperatorOptions
              );

              bracket = this.makeGroupIfStatementBracket(
                condition.conditions,
                stepIndex,
                operatorOptions
              );
            } else {
              bracket = this.makeSingleIfStatementBracket(condition, stepIndex);
            }

            bracketSections.push(
              makeBracketSectionDTO({
                bracket,
                operatorOptions: splitOperator
              })
            );
          });
        }
      } else {
        const bracket = this.makeSingleIfStatementBracket(condition, stepIndex);

        bracketSections.push(
          makeBracketSectionDTO({
            bracket
          })
        );
      }

      return bracketSections;
    },
    makeIfStep(data, stepIndex) {
      const ifBlockSuccessSteps = this.makeSuccessSteps(
        data.ifBlock.successSteps,
        stepIndex
      );
      const ifBrackets = this.makeIfStatementCondition(
        data.ifBlock.condition,
        stepIndex
      );
      let elseIfBrackets;
      let elseSuccessSteps;

      if (data.elseIfBlocks?.length) {
        elseIfBrackets = data.elseIfBlocks.map((block) => {
          const successSteps = this.makeSuccessSteps(
            block.successSteps,
            stepIndex
          );
          const condition = this.makeIfStatementCondition(
            block.condition,
            stepIndex
          );

          return {
            condition,
            successSteps
          };
        });
      }

      if (data.elseBlock?.length) {
        elseSuccessSteps = this.makeSuccessSteps(data.elseBlock, stepIndex);
      }

      return {
        stepType: actionStepType.IF,
        comment: data?.comment || "",
        hasComment: !!data?.comment,
        ifBlock: [
          {
            condition: ifBrackets,
            successSteps: ifBlockSuccessSteps
          }
        ],
        ...(elseIfBrackets?.length && { elseIfBlocks: elseIfBrackets }),
        ...(elseSuccessSteps?.length && { elseBlock: elseSuccessSteps })
      };
    },
    makeInitialComparatorOptions(selectedValue, isInverse, isHasOrHasNoValue) {
      const mappedSelectedValue = getSelectedComparatorOption(
        selectedValue,
        isInverse,
        isHasOrHasNoValue
      );

      const clonedComparatorOptions = cloneDeep(comparatorOptions);

      return clonedComparatorOptions.map((option) => {
        delete option.selected;

        if (option.value === mappedSelectedValue) {
          option.selected = true;
        }

        return option;
      });
    },
    makeIfBlockLeftOptions(stepIndex = 0, selectedValue = "") {
      const allLeftOptions =
        this.getAllAvailableOptionsByStepIndex(stepIndex)?.filter(
          ({ type, subType }) =>
            type !== variableType.PROXY && subType !== variableType.PROXY
        ) || [];

      if (selectedValue) {
        return makeOptionsForSelect(selectedValue, allLeftOptions);
      } else if (allLeftOptions?.length) {
        allLeftOptions[0].selected = true;
        return allLeftOptions;
      }

      return [];
    },
    updateSingleIfStatementLeftOptions(index) {
      const stepIndex = this.ifStatementList[index].stepIndex;
      const availableOptions =
        this.getAllAvailableOptionsByStepIndex(stepIndex);
      const selectedValue =
        this.ifStatementList[index].ifStatement.left.value ||
        availableOptions?.[0]?.value ||
        "";
      this.ifStatementList[index].ifStatement.setLeftValue(selectedValue);
      const leftOptions = this.makeIfBlockLeftOptions(stepIndex, selectedValue);
      const selectedComparator =
        this.ifStatementList[index].ifStatement.getSelectedComparator()?.value;
      const newComparatorOptions = makeOptionsForSelect(
        selectedComparator,
        comparatorOptions
      );

      this.ifStatementList[index].ifStatement.setLeftOptions(leftOptions);
      this.ifStatementList[index].ifStatement.setComparatorOptions(
        leftOptions,
        newComparatorOptions
      );

      this.updateSetToBlockByIfStatementLeftOptions(
        this.ifStatementList[index],
        leftOptions,
        selectedComparator
      );

      if (!selectedValue) {
        this.hideSetToBlockByIfStatementBlock(this.ifStatementList[index]);
      }
    },
    updateAllIfStatementLeftOptions() {
      for (let index = 0; index < this.ifStatementList.length; index++) {
        this.updateSingleIfStatementLeftOptions(index);
      }
    },
    updateIfStatementLeftValue(newValue, ifStatementBlock) {
      this.resetSetToBlockValue(ifStatementBlock);
      const leftOptions = this.makeIfBlockLeftOptions(
        ifStatementBlock.stepIndex,
        newValue
      );
      const selectedComparator =
        ifStatementBlock.ifStatement.getSelectedComparator()?.value;
      const newComparatorOptions = makeOptionsForSelect(
        selectedComparator,
        comparatorOptions
      );

      ifStatementBlock.ifStatement.setLeftValue(newValue);
      ifStatementBlock.ifStatement.setLeftOptions(leftOptions);
      ifStatementBlock.ifStatement.setComparatorOptions(
        leftOptions,
        newComparatorOptions
      );
      if (!this.isHasOrHasNoValue(selectedComparator)) {
        this.updateSetToBlockByIfStatementLeftOptions(
          ifStatementBlock,
          leftOptions,
          selectedComparator
        );
      }
    },
    getIfStatementBySetToBlockId(id) {
      return (
        this.ifStatementList.find(({ setToBlockId }) => setToBlockId === id) ||
        {}
      );
    },
    removeIfStatementFromList(ifStatementId) {
      this.ifStatementList = this.ifStatementList.reduce((acc, ifStatement) => {
        if (ifStatement.ifStatement.id !== ifStatementId) {
          acc.push(ifStatement);
        } else {
          this.deleteSetToBlockById(ifStatement.setToBlockId);
        }

        return acc;
      }, []);
    },
    deleteIfStatement(
      event,
      stepIndex,
      ifTypeProperty,
      bracketIndex,
      statementIndex
    ) {
      this.removeIfStatementFromList(event.event.event.id);

      this.functionSteps[stepIndex][ifTypeProperty][statementIndex].condition[
        bracketIndex
      ].bracket.componentOptions.ifStatements.splice(
        event.event.event.index,
        1
      );
    },
    deleteIfStepStatements(deletedStepIndex) {
      const ifStatementToDelete = this.ifStatementList.find(
        ({ stepIndex }) => stepIndex === deletedStepIndex
      );

      this.ifStatementList = this.ifStatementList.filter(
        ({ stepIndex }) => stepIndex !== deletedStepIndex
      );
      this.deleteSetToBlockById(ifStatementToDelete?.setToBlockId);
      this.deleteEndStepIndexFromList(deletedStepIndex);
      this.deleteSetStepIndexFromList(deletedStepIndex);
      this.deleteEmailNotificationStepIndexFromList(deletedStepIndex);
    },
    addIfStatementBracket(
      stepIndex,
      ifTypeProperty,
      bracketIndex,
      event,
      statementIndex
    ) {
      const leftOptions = this.makeIfBlockLeftOptions(stepIndex, "");
      leftOptions[0].selected = true;
      const rightComponentOptions = this.makeSetToBlock({
        leftOptions,
        stepIndex
      });
      const newIfStatement = this.makeNewIfStatement({
        selectedLeftValue: leftOptions[0]?.value,
        leftOptions,
        comparatorOptions: makeOptionsForSelect(
          operations.EQUALS,
          comparatorOptions
        ),
        operatorOptions: makeOptionsForSelect(
          operations.AND,
          joinOperatorOptions
        ),
        stepIndex,
        rightComponentOptions
      });

      this.functionSteps[stepIndex][ifTypeProperty][statementIndex].condition[
        bracketIndex
      ].bracket.componentOptions.ifStatements.splice(
        event.event.event.index,
        0,
        newIfStatement
      );
    },
    updateIfStatementComparatorOptions(newValue, ifStatementBlock) {
      const newComparatorOptions = makeOptionsForSelect(
        newValue,
        comparatorOptions
      );
      ifStatementBlock.ifStatement.setComparatorOptions(
        ifStatementBlock.ifStatement.left.options,
        newComparatorOptions
      );
      const { value: selectedComparator } =
        ifStatementBlock.ifStatement.getSelectedComparator();

      if (this.isHasOrHasNoValue(newValue)) {
        this.updateSetToBlockForHasOrHasNoValue(ifStatementBlock);
      } else {
        const leftOptions = ifStatementBlock.ifStatement.getLeftOptions();
        this.updateSetToBlockByIfStatementLeftOptions(
          ifStatementBlock,
          leftOptions,
          selectedComparator
        );
      }
    },
    updateIfStatementOperatorToOr(
      stepIndex,
      ifTypeProperty,
      bracketIndex,
      ifStatementIndex,
      operatorOptions,
      statementIndex
    ) {
      const allBrackets =
        this.functionSteps[stepIndex][ifTypeProperty][statementIndex].condition[
          bracketIndex
        ].bracket.componentOptions.ifStatements;
      const splitBrackets = allBrackets.splice(
        ifStatementIndex,
        allBrackets.length - 1
      );
      const bracket = {
        component: IfBlock,
        componentOptions: {
          ifStatements: splitBrackets
        }
      };
      const newBracket = makeBracketSectionDTO({
        bracket,
        operatorOptions
      });

      this.functionSteps[stepIndex][ifTypeProperty][
        statementIndex
      ].condition.splice(bracketIndex + 1, 0, newBracket);
    },
    makeNewIfStatementBracket(stepIndex) {
      const leftOptions = this.makeIfBlockLeftOptions(stepIndex, "");
      leftOptions[0].selected = true;
      const rightComponentOptions = this.makeSetToBlock({
        leftOptions,
        stepIndex
      });
      const newIfStatement = this.makeNewIfStatement({
        selectedLeftValue: leftOptions[0]?.value,
        leftOptions,
        comparatorOptions: makeOptionsForSelect(
          operations.EQUALS,
          comparatorOptions
        ),
        operatorOptions: makeOptionsForSelect(
          operations.AND,
          joinOperatorOptions
        ),
        stepIndex,
        rightComponentOptions
      });
      const newBracket = {
        component: IfBlock,
        componentOptions: {
          ifStatements: [newIfStatement]
        }
      };
      return makeBracketSectionDTO({
        bracket: newBracket,
        operatorOptions: makeOptionsForSelect(
          operations.OR,
          joinOperatorOptions
        )
      });
    },
    makeNewIfStatementIfBlockBracket(stepIndex, ifTypeProperty, event) {
      const newBracketSection = this.makeNewIfStatementBracket(stepIndex);

      this.functionSteps[stepIndex][ifTypeProperty][
        event.index
      ].condition.splice(event.event.index, 0, newBracketSection);
    },
    makeNewIfStatementElseIfBlockBracket(stepIndex) {
      const newBracketSection = this.makeNewIfStatementBracket(stepIndex);
      const newElseIfBlock = {
        condition: [newBracketSection],
        successSteps: []
      };
      const elseIfBlocks = [
        ...(this.functionSteps[stepIndex]?.[actionKeys.ELSE_IF_BLOCKS] || []),
        newElseIfBlock
      ];

      this.functionSteps[stepIndex][actionKeys.ELSE_IF_BLOCKS] = elseIfBlocks;
    },
    makeNewIfStatementElseBlock(event, stepIndex) {
      if (!this.functionSteps[stepIndex][actionKeys.ELSE_BLOCK]) {
        this.functionSteps[stepIndex][actionKeys.ELSE_BLOCK] = [];
      }
      this.showSuccessStepModal(event, stepIndex);
    },
    deleteIfStatementBracket(stepIndex, ifTypeProperty, event) {
      const ifStatementIds = this.functionSteps[stepIndex][ifTypeProperty][
        event.index
      ].condition[event.event.index].bracket.componentOptions.ifStatements.map(
        ({ id }) => id
      );

      // Remove ifStatement references from list
      for (const id of ifStatementIds) {
        this.removeIfStatementFromList(id);
      }

      // remove bracket
      this.functionSteps[stepIndex][ifTypeProperty][
        event.index
      ].condition.splice(event.event.index, 1);
    },
    updateBracketOperatorToAnd({
      stepIndex,
      ifTypeProperty,
      statementIndex,
      bracketIndex,
      bracketEvent
    }) {
      const bracketToUpdate = this.functionSteps[stepIndex][ifTypeProperty][
        statementIndex
      ].condition.splice(bracketIndex, 1);
      const ifStatementsToUpdate =
        bracketToUpdate[0].bracket.componentOptions.ifStatements;
      this.functionSteps[stepIndex][ifTypeProperty][statementIndex].condition[
        bracketIndex - 1
      ].bracket.componentOptions.ifStatements.push(...ifStatementsToUpdate);
      ifStatementsToUpdate[0].updateOperatorOptions(
        makeOptionsForSelect(bracketEvent, joinOperatorOptions)
      );
    },
    onIfBlockChange(event, stepIndex) {
      const {
        property: ifTypeProperty,
        eventType,
        index: statementIndex
      } = event;
      if (eventType === ifBlockEventType.CHANGE) {
        const {
          index: bracketIndex,
          property: bracketProperty,
          event: bracketEvent,
          eventType: bracketEventType
        } = event.event;
        if (bracketProperty === actionKeys.COMPONENT) {
          const {
            id: ifStatementId,
            event: ifStatementEvent,
            property: ifStatementProperty,
            index: ifStatementIndex
          } = bracketEvent;
          const ifStatementBlock = this.ifStatementList.find(
            ({ ifStatement }) => ifStatement.id === ifStatementId
          );

          if (ifStatementProperty === actionKeys.LEFT) {
            this.updateIfStatementLeftValue(ifStatementEvent, ifStatementBlock);
          } else if (ifStatementProperty === actionKeys.OPERATOR) {
            const operatorOptions = makeOptionsForSelect(
              ifStatementEvent,
              joinOperatorOptions
            );

            if (ifStatementEvent === operations.AND) {
              ifStatementBlock.ifStatement.updateOperatorOptions(
                operatorOptions
              );
            } else if (ifStatementEvent === operations.OR) {
              this.updateIfStatementOperatorToOr(
                stepIndex,
                ifTypeProperty,
                bracketIndex,
                ifStatementIndex,
                operatorOptions,
                statementIndex
              );
            }
          } else if (ifStatementProperty === actionKeys.RIGHT) {
            this.updateSetToBlockFromIfStatement({
              event: ifStatementEvent,
              ifStatement: ifStatementBlock,
              stepIndex
            });
          } else if (ifStatementProperty === actionKeys.COMPARATOR) {
            this.updateIfStatementComparatorOptions(
              ifStatementEvent,
              ifStatementBlock
            );
          } else if (ifStatementProperty === operations.ADD) {
            this.addIfStatementBracket(
              stepIndex,
              ifTypeProperty,
              bracketIndex,
              event,
              statementIndex
            );
          } else if (ifStatementProperty === operations.DELETE) {
            this.deleteIfStatement(
              event,
              stepIndex,
              ifTypeProperty,
              bracketIndex,
              statementIndex
            );
          }
        } else if (bracketProperty === actionKeys.OPERATOR) {
          if (bracketEvent === operations.AND) {
            this.updateBracketOperatorToAnd({
              stepIndex,
              ifTypeProperty,
              statementIndex,
              bracketIndex,
              bracketEvent
            });
          }
        } else if (bracketEventType === operations.ADD_BRACKET) {
          this.makeNewIfStatementIfBlockBracket(
            stepIndex,
            ifTypeProperty,
            event
          );
        } else if (bracketEventType === operations.DELETE_BRACKET) {
          this.deleteIfStatementBracket(stepIndex, ifTypeProperty, event);
        }
      } else if (eventType === ifBlockEventType.ADD_SUCCESS_STEP) {
        this.showSuccessStepModal(event, stepIndex);
      } else if (eventType === ifBlockEventType.AMEND_SUCCESS_STEP) {
        this.amendSuccessStep(event, stepIndex);
      } else if (eventType === ifBlockEventType.DELETE_SUCCESS_STEP) {
        this.deleteSuccessStep(event, stepIndex);
      } else if (eventType === ifBlockEventType.ADD_BLOCK) {
        if (ifTypeProperty === actionKeys.ELSE_IF_BLOCKS) {
          this.makeNewIfStatementElseIfBlockBracket(stepIndex);
        } else if (ifTypeProperty === actionKeys.ELSE_BLOCK) {
          this.makeNewIfStatementElseBlock(event, stepIndex);
        }
      } else if (eventType === ifBlockEventType.DELETE_ELSE_IF_BLOCK) {
        const ifStatementIdsToDelete = event.block.condition
          .map(({ bracket }) =>
            bracket.componentOptions.ifStatements.map(({ id }) => id)
          )
          .flat();
        const setToBlocksToDelete = [];

        this.ifStatementList = this.ifStatementList.filter((ifStatement) => {
          const toDelete = ifStatementIdsToDelete.includes(
            ifStatement.ifStatement.id
          );

          if (toDelete) {
            setToBlocksToDelete.push(ifStatement.setToBlockId);
          }

          return !toDelete;
        });

        this.setToBlockList = this.setToBlockList.filter(
          ({ id }) => !setToBlocksToDelete.includes(id)
        );

        this.functionSteps[stepIndex].elseIfBlocks.splice(event.index, 1);
      }
    },
    updateIfStatementStepIndexes(editType, index) {
      for (
        let ifStatementIndex = 0;
        ifStatementIndex < this.ifStatementList.length;
        ifStatementIndex++
      ) {
        if (this.ifStatementList[ifStatementIndex].stepIndex >= index) {
          if (
            editType === operations.ADD &&
            this.ifStatementList[ifStatementIndex].stepIndex >= index
          ) {
            this.ifStatementList[ifStatementIndex].stepIndex += 1;
          } else if (
            editType === operations.DELETE &&
            this.ifStatementList[ifStatementIndex].stepIndex > index
          ) {
            this.ifStatementList[ifStatementIndex].stepIndex -= 1;
          }
        }
      }
    }
  }
};
