<template>
  <div>
    <div class="d-flex" style="justify-content: center">
      <h4 class="ml-auto">Configure Bulk Order for {{ companyName }}</h4>

      <div class="ml-auto my-2 d-flex">
        <!-- default actions button for resetting configuration -->
        <b-form-checkbox
          v-model="status"
          name="checkbox-1"
          :value="1"
          :unchecked-value="0"
          class="mt-2"
        >
          Default Bulk Order Actions
        </b-form-checkbox>

        <!-- save btn -->
        <b-btn @click="onSubmitActions" variant="outline-success">
          Submit
        </b-btn>
      </div>
    </div>
    <b-overlay :show="isSubmiting">
      <b-row class="ml-5">
        <!-- All actions col -->
        <b-col
          class="kanban-col"
          style="border: 1px dashed black; padding: 0px"
          cols="3"
        >
          <h5 class="pt-1">All Actions</h5>
          <hr style="margin: 0px" />
          <draggable
            :list="bulkOrderAllActions"
            :v-model="bulkOrderAllActions"
            group="actions"
            style="height: calc(100vh - 165px); overflow-y: auto"
          >
            <template #item="{ element }">
              <b-list-group v-show="!element.isHidden">
                <b-list-group-item class="mb-1">{{
                  element.btnText
                }}</b-list-group-item>
              </b-list-group>
            </template>
          </draggable>
        </b-col>

        <!-- What action will occur col -->
        <b-col
          class="kanban-col"
          style="border: 1px dashed black; padding: 0px"
          cols="3"
        >
          <h5 class="pt-1">What action will occur</h5>
          <hr style="margin: 0px" />
          <draggable
            style="height: calc(100vh - 165px); overflow-y: auto"
            :list="bulkOrderAvailableOccur"
            :v-model="bulkOrderAvailableOccur"
            group="actions"
          >
            <template #item="{ element }">
              <b-list-group>
                <b-list-group-item class="mb-1">{{
                  element.btnText
                }}</b-list-group-item>
              </b-list-group>
            </template>
          </draggable>
        </b-col>

        <!-- Unavailable Actions col -->
        <b-col
          class="kanban-col"
          style="border: 1px dashed black; padding: 0px"
          cols="3"
        >
          <h5 class="pt-1">Unavailable Actions</h5>
          <hr style="margin: 0px" />
          <draggable
            style="height: calc(100vh - 165px); overflow-y: auto"
            :list="bulkOrderActionsNotAvailable"
            :v-model="bulkOrderActionsNotAvailable"
            group="actions"
          >
            <template #item="{ element }">
              <b-list-group>
                <b-list-group-item class="mb-1">{{
                  element.btnText
                }}</b-list-group-item>
              </b-list-group>
            </template>
          </draggable>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import allActionsJson from "../../../utils/bulkOrderAllActions.json";
import BulkOrderDefaultActionsJson from "../../../utils/bulkOrderDefaultActions.json";
import _ from "lodash";
import { EventBus } from "@/event-bus.js";

draggable.compatConfig = { MODE: 3 };

export default {
  components: {
    draggable
  },
  data() {
    return {
      isSubmiting: false,
      isLoading: false,
      isLoadingData: false,
      bulkOrderAllActions: _.clone(allActionsJson.allActions),
      bulkOrderAvailableOccur: [],
      bulkOrderActionsNotAvailable: [],
      status: 0,
      companyName: null,
      questionnaires: [],
      questionnairesReminder: []
    };
  },
  mounted() {
    this.companyName = localStorage.getItem("openedCompanyProfile");
    this.getAdditionalVouchers();
  },
  created() {
    EventBus.on("callgetAdditionalVouchers", () => {
      this.getAdditionalVouchers();
    });
  },
  methods: {
    async getAdditionalVouchers() {
      this.isSubmiting = true;
      let schema = JSON.parse(localStorage.getItem("actionsSchema"));
      if (schema) {
        if (schema.bulkAllAvailableActions) {
          schema.bulkAllAvailableActions.map((column) => {
            let staticFieldIndex =
              BulkOrderDefaultActionsJson.availableActions.findIndex(
                (x) => x.id == column.id
              );
            if (staticFieldIndex != -1) {
              let staticAction =
                BulkOrderDefaultActionsJson.availableActions[staticFieldIndex];
              Object.keys(staticAction).map((staticKey) => {
                if (column[staticKey] == null) {
                  column[staticKey] = staticAction[staticKey];
                } else if (typeof staticAction[staticKey] == "object") {
                  Object.keys(staticAction[staticKey]).map((subStaticKey) => {
                    if (column[staticKey][subStaticKey] == null) {
                      column[staticKey][subStaticKey] =
                        staticAction[staticKey][subStaticKey];
                    }
                  });
                }
              });
            }
            this.bulkOrderAvailableOccur = schema.bulkAllAvailableActions || [];
          });
        }
        if (schema.bulkAllAvailableActions) {
          schema.bulkActionNotAvailable.map((column) => {
            let staticFieldIndex =
              BulkOrderDefaultActionsJson.actionsNotAvailable.findIndex(
                (x) => x.id == column.id
              );
            if (staticFieldIndex != -1) {
              let staticAction =
                BulkOrderDefaultActionsJson.actionsNotAvailable[
                  staticFieldIndex
                ];
              Object.keys(staticAction).map((staticKey) => {
                if (column[staticKey] == null) {
                  column[staticKey] = staticAction[staticKey];
                } else if (typeof staticAction[staticKey] == "object") {
                  Object.keys(staticAction[staticKey]).map((subStaticKey) => {
                    if (column[staticKey][subStaticKey] == null) {
                      column[staticKey][subStaticKey] =
                        staticAction[staticKey][subStaticKey];
                    }
                  });
                }
              });
            }
            this.bulkOrderActionsNotAvailable =
              schema.bulkActionNotAvailable || [];
          });
        }
      }
      this.getQuestionnariesData();
      this.getQuestionnariesReminderData();
    },
    resetAllActionsValue(allSelectedActions) {
      let abcqActionIndex = this.bulkOrderAllActions.findIndex(
        (x) => x.id == 9
      );
      if (abcqActionIndex != -1) {
        this.bulkOrderAllActions.splice(abcqActionIndex, 1);
      }
      abcqActionIndex = this.bulkOrderActionsNotAvailable.findIndex(
        (x) => x.id == 9
      );
      if (abcqActionIndex != -1) {
        this.bulkOrderActionsNotAvailable.splice(abcqActionIndex, 1);
      }
      allSelectedActions.map((actions) => {
        let actionsIndex = this.bulkOrderAllActions.findIndex(
          (x) => x.id == actions.id
        );
        if (actionsIndex != -1) {
          this.bulkOrderAllActions.splice(actionsIndex, 1);
        }
      });
      this.bulkOrderAllActions.map((actions) => {
        if (!actions.isHidden) {
          let actionsOccurIndex = this.bulkOrderAvailableOccur.findIndex(
            (x) => x.btnText == actions.btnText
          );
          if (actionsOccurIndex != -1) {
            this.bulkOrderAvailableOccur[actionsOccurIndex] = actions;
          }

          let actionsNotAvailableIndex =
            this.bulkOrderActionsNotAvailable.findIndex(
              (x) => x.btnText == actions.btnText
            );
          if (actionsNotAvailableIndex != -1) {
            this.bulkOrderActionsNotAvailable[actionsNotAvailableIndex] =
              actions;
          }
        }
      });
      allSelectedActions.map((actions) => {
        let actionsIndex = this.bulkOrderAllActions.findIndex(
          (x) => x.btnText == actions.btnText
        );
        if (actionsIndex != -1) {
          this.bulkOrderAllActions.splice(actionsIndex, 1);
        }
      });
      this.onSubmitActions();
    },

    // save configuarion API
    async onSubmitActions() {
      let questionare = _.clone(allActionsJson.allActions);
      questionare.push(...this.questionnaires);
      questionare.push(...this.questionnairesReminder);

      let formData = {
        company_id: this.$route.params.id,
        bulkAllActions:
          this.status == 1 ? questionare : this.bulkOrderAllActions,
        bulkAllAvailableActions: this.bulkOrderAvailableOccur,
        bulkActionNotAvailable: this.bulkOrderActionsNotAvailable,
        is_bulk_default_actions: this.status
      };
      this.isSubmiting = true;
      this.isLoading = true;
      try {
        let result = await HTTP(
          "post",
          "thirdparty/update-tp/dynamic-actions",
          formData,
          makeAuthorizationHeader()
        );
        //
        if (result.status == 200) {
          let schema = JSON.parse(result.data.additional_data);
          if (schema) {
            this.bulkOrderAllActions =
              schema.bulkAllActions && schema.bulkAllActions.length
                ? schema.bulkAllActions
                : [];
            this.bulkOrderAvailableOccur =
              schema.bulkAllAvailableActions &&
              schema.bulkAllAvailableActions.length
                ? schema.bulkAllAvailableActions
                : [];
            this.bulkOrderActionsNotAvailable =
              schema.bulkActionNotAvailable &&
              schema.bulkActionNotAvailable.length
                ? schema.bulkActionNotAvailable
                : [];
          }
          let actionsData = schema || null;
          localStorage.setItem("actionsSchema", JSON.stringify(actionsData));
          this.status = 0;
          this.isSubmiting = false;
          this.isLoading = false;
        }
      } catch (error) {
        this.isSubmiting = false;
        this.isLoading = false;
      }
    },

    // get MSQ
    async getQuestionnariesData() {
      this.isSubmiting = true;
      try {
        let result = await HTTP(
          "get",
          `v1/getactiveformslist/${this.$route.params.id}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.data) {
          result.data.questionnaires.map((items) => {
            this.bulkOrderAllActions.push(items);
          });
          this.questionnaires = result.data.questionnaires;

          let allSelectedActions = [];
          allSelectedActions.push(...this.bulkOrderAvailableOccur);
          allSelectedActions.push(...this.bulkOrderActionsNotAvailable);

          if (allSelectedActions && allSelectedActions.length > 0) {
            this.resetAllActionsValue(allSelectedActions);
          } else {
            this.isSubmiting = false;
          }
        }
      } catch (error) {
        this.isSubmiting = false;
      }
    },

    // get MSQ reminder
    async getQuestionnariesReminderData() {
      this.isSubmiting = true;
      try {
        let result = await HTTP(
          "get",
          `v1/getactiveformslistforbulk/${this.$route.params.id}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.data) {
          result.data.questionnaires.map((items) => {
            this.bulkOrderAllActions.push(items);
          });
          this.questionnairesReminder = result.data.questionnaires;

          let allSelectedActions = [];
          allSelectedActions.push(...this.bulkOrderAvailableOccur);
          allSelectedActions.push(...this.bulkOrderActionsNotAvailable);

          if (allSelectedActions && allSelectedActions.length > 0) {
            this.resetAllActionsValue(allSelectedActions);
          } else {
            this.isSubmiting = false;
          }
        }
      } catch (error) {
        this.isSubmiting = false;
      }
    }
  }
};
</script>

<style>
.kanban-col {
  min-height: 500px;
}
</style>
