<template>
  <div class="dynamic-component-with-link">
    <component v-bind="componentOptions" :is="component" />
    <router-link
      v-bind="makeRouterLinkOptions"
      class="dynamic-component-with-link__link"
      data-test-id="dynamic-component-with-link__link"
      @click="$emit('click')"
    >
      {{ linkName }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "DynamicComponentWithLink",
  props: {
    component: {
      type: Object,
      default: () => ({})
    },
    componentOptions: {
      type: Object,
      default: () => ({})
    },
    routerLinkOptions: {
      type: Object,
      default: () => ({})
    },
    linkName: {
      type: String,
      default: "Legend"
    }
  },
  computed: {
    makeRouterLinkOptions() {
      return {
        to: "", // this property is mandatory to exist
        ...this.routerLinkOptions
      };
    }
  }
};
</script>
