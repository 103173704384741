import {
  trainingLearnersKeysDTO,
  trainingLearnersKeysAPIDTO
} from "@/constants";

const makeLearnerAPIDTO = (learner = {}) => ({
  [trainingLearnersKeysAPIDTO.EMAIL_ADDRESS]:
    learner[trainingLearnersKeysDTO.EMAIL] || "",
  [trainingLearnersKeysAPIDTO.FIRST_NAME]:
    learner[trainingLearnersKeysDTO.FIRST_NAME] || "",
  [trainingLearnersKeysAPIDTO.LAST_NAME]:
    learner[trainingLearnersKeysDTO.LAST_NAME] || "",
  [trainingLearnersKeysAPIDTO.PREFERRED_LANGUAGE]:
    learner[trainingLearnersKeysDTO.PREFERRED_LANGUAGE] || "",
  [trainingLearnersKeysAPIDTO.JOB_TITLE]:
    learner[trainingLearnersKeysDTO.JOB_TITLE] || "",
  [trainingLearnersKeysAPIDTO.THIRD_PARTY_COURSE_ID]:
    learner[trainingLearnersKeysDTO.ID] || "",
  [trainingLearnersKeysAPIDTO.THIRD_PARTY_ID]:
    learner[trainingLearnersKeysDTO.THIRD_PARTY_ID] || ""
});

export { makeLearnerAPIDTO };
