import { getters } from "./tasks.getters.store";
import { actions } from "./tasks.actions.store";
import { mutations } from "./tasks.mutations.store";
import { state } from "./tasks.state.store";

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state
};
