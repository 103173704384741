import {
  breadcrumbRouteNames,
  routeNames,
  templates,
  urls,
  routeKeys
} from "@/constants";
import Permissions from "@/pages/Permissions/Permissions";
import PermissionsPlatformFeatures from "@/organisms/PermissionsPlatformFeatures/PermissionsPlatformFeatures";

export default [
  {
    path: urls.PERMISSIONS,
    name: routeNames.PERMISSIONS,
    component: Permissions,
    redirect: {
      name: routeNames[routeKeys.PERMISSIONS_PLATFORM_FEATURES]
    },
    children: [
      {
        path: urls.PERMISSIONS_PLATFORM_FEATURES,
        name: routeNames[routeKeys.PERMISSIONS_PLATFORM_FEATURES],
        component: PermissionsPlatformFeatures,
        meta: {
          requiresAuth: true,
          template: templates.EB360,
          isEb360HeaderRequired: true,
          isEB360SideBarRequired: true,
          breadcrumbs: () => [
            { name: breadcrumbRouteNames.HOME, link: urls.HOME },
            {
              name: breadcrumbRouteNames.PERMISSIONS_PLATFORM_FEATURES
            }
          ]
        }
      }
    ]
  }
];
