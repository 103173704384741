<template>
  <div>
    <ThirdPartyTrainingList
      v-if="currentView === views.LIST"
      @click="handleChangeView"
      :third-party-id="thirdPartyId"
    />
    <ThirdPartyTrainingDetails
      v-else-if="currentView === views.DETAILS"
      @click="handleChangeView"
      :course-id="courseId"
      :third-party-id="thirdPartyId"
    />
  </div>
</template>
<script>
import ThirdPartyTrainingList from "@/organisms/ThirdPartyTrainingList/ThirdPartyTrainingList";
import ThirdPartyTrainingDetails from "@/organisms/ThirdPartyTrainingDetails/ThirdPartyTrainingDetails";
import { views } from "@/constants";

export default {
  name: "ThirdPartyTrainingWrapper",
  components: {
    ThirdPartyTrainingList,
    ThirdPartyTrainingDetails
  },
  data() {
    return {
      currentView: views.LIST,
      courseId: null,
      views
    };
  },
  props: {
    thirdPartyId: {
      type: Number,
      default: 0
    }
  },
  methods: {
    handleChangeView({ view, selectedCourse }) {
      this.currentView = view;
      this.courseId = selectedCourse?.id;
    }
  }
};
</script>
