import { makeUUID } from "@/utils";

export class FilterSectionClass {
  id = 0;

  availableFilterOptions = [];

  availableFilterValueComponentOptions = {};

  availableFilterValueOptionsComponent = {};

  constructor({
    id = makeUUID(),
    availableFilterOptions = [],
    availableFilterValueComponentOptions = {},
    availableFilterValueOptionsComponent = {}
  }) {
    this.id = id;
    this.availableFilterOptions = availableFilterOptions;
    this.availableFilterValueComponentOptions =
      availableFilterValueComponentOptions;
    this.availableFilterValueOptionsComponent =
      availableFilterValueOptionsComponent;
  }

  getSelectedFilterValue() {
    return (
      this.availableFilterOptions.find(({ selected }) => selected)?.value || ""
    );
  }

  getSelectedFilterOptionValues() {
    return (
      this.availableFilterValueComponentOptions?.options?.reduce(
        (accumulate, option) => {
          if (option?.selected) {
            accumulate.push(option.value);
          }

          return accumulate;
        },
        []
      ) || []
    );
  }
}
