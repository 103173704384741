<template>
  <div>
    <div class="d-flex" style="justify-content: center">
      <h4 class="ml-auto">Configure Columns for {{ companyName }}</h4>
      <div class="ml-auto my-2 d-flex">
        <!-- default column button for resetting configuration -->
        <b-form-checkbox
          v-model="status"
          name="checkbox-1"
          :value="1"
          :unchecked-value="0"
          class="mt-2"
        >
          Default Columns
        </b-form-checkbox>

        <!-- save btn -->
        <b-btn @click="checkIsDefault" variant="outline-success">
          Submit
        </b-btn>
      </div>
    </div>
    <b-overlay :show="isSubmiting">
      <b-row class="ml-5">
        <!-- All columns col -->
        <b-col
          class="kanban-col"
          style="border: 1px dashed black; padding: 0px"
          cols="3"
        >
          <h5 class="pt-1">All Columns</h5>
          <hr style="margin: 0px" />

          <draggable
            :v-model="allColumns"
            :list="allColumns"
            group="actions"
            style="height: calc(100vh - 180px); overflow-y: auto"
          >
            <template #item="{ element }">
              <b-list-group>
                <b-list-group-item class="mb-1" v-if="element.isActive">{{
                  element.label
                }}</b-list-group-item>
              </b-list-group>
            </template>
          </draggable>
        </b-col>

        <!-- Selected columns col -->
        <b-col
          class="kanban-col"
          style="border: 1px dashed black; padding: 0px"
          cols="9"
        >
          <h5 class="pt-1">Selected Columns</h5>
          <hr style="margin: 0px" />
          <div style="display: grid">
            <b-table-simple
              responsive="sm"
              sticky-header="calc(100vh - 180px)"
              style="overflow-y: auto"
              fixed
              small
              bordered
              outlined
            >
              <b-thead>
                <b-tr style="text-align: center">
                  <b-th>Columns</b-th>
                  <b-th>Default Columns</b-th>
                  <b-th>Width</b-th>
                </b-tr>
              </b-thead>
              <draggable
                style="height: calc(100vh - 180px); overflow-y: auto"
                :empty-insert-threshhold="500"
                v-model="selectedColumns"
                tag="b-tbody"
                group="actions"
              >
                <template #item="{ element }">
                  <b-tr style="text-align: center">
                    <b-td>{{ element.label }}</b-td>
                    <b-td>
                      <b-form-checkbox
                        class="align-items-center"
                        :checked="element.default"
                        @change="checkStickyField($event, element)"
                      />
                    </b-td>
                    <b-td>
                      <vue-number-input
                        :value="parseInt(element.width)"
                        :min="50"
                        :max="1000"
                        @change="setFieldsWidth($event, element)"
                        inline
                        controls
                        :step="10"
                        size="200px"
                        center
                        :inputtable="false"
                      ></vue-number-input>
                    </b-td>
                  </b-tr>
                </template>
              </draggable>
            </b-table-simple>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import NumberInput from "@chenfengyuan/vue-number-input";
import ThirdPartyListAllColumn from "../../../utils/staticFields.json";
import { EventBus } from "@/event-bus.js";
import { clone } from "lodash";

draggable.compatConfig = { MODE: 3 };

export default {
  components: {
    draggable
  },
  data() {
    return {
      companyName: null,
      isSubmiting: false,
      isLoading: false,
      allColumns: ThirdPartyListAllColumn.fields,
      selectedColumns: [],
      "vue-number-input": NumberInput,
      isDefault: false,
      status: 0,
      questionnaireColumns: []
    };
  },
  mounted() {
    this.companyName = localStorage.getItem("openedCompanyProfile");
    this.getAdditionalVouchers();
  },
  created() {
    EventBus.on("callgetAdditionalVouchers", () => {
      this.getAdditionalVouchers();
    });
  },
  methods: {
    // check is default column
    checkIsDefault() {
      this.isSubmiting = true;
      this.isLoading = true;
      this.selectedColumns.map((items) => {
        if (items.default == true) {
          this.isDefault = true;
        }
      });
      if (this.isDefault || this.status == 1) {
        this.onSubmitCols();
      } else {
        this.$bvToast.toast("Please set at least one column default", {
          title: "Error",
          variant: "danger",
          autoHideDelay: 2000,
          appendToast: false
        });
        this.isSubmiting = false;
        this.isLoading = false;
      }
    },

    // save configuration API
    async onSubmitCols() {
      let questionare = clone(ThirdPartyListAllColumn.fields);
      questionare.push(...this.questionnaireColumns);
      let formData = {
        company_id: this.$route.params.id,
        selectedColumns: this.selectedColumns,
        allColumns: this.status == 1 ? questionare : this.allColumns,
        is_default_columns: this.status
      };
      try {
        let result = await HTTP(
          "post",
          "thirdparty/update-tp/selected-column-actions",
          formData,
          makeAuthorizationHeader()
        );
        if (result.status == 200) {
          let schema = JSON.parse(result.data.additional_data);
          if (schema) {
            this.allColumns =
              schema.allColumns && schema.allColumns.length
                ? schema.allColumns
                : [];
            this.selectedColumns =
              schema.selectedColumns && schema.selectedColumns.length
                ? schema.selectedColumns
                : [];
          }
          let actionsData = schema || null;
          localStorage.setItem("actionsSchema", JSON.stringify(actionsData));
          this.status = 0;
          this.isSubmiting = false;
          this.isLoading = false;
          this.isDefault = false;
        }
      } catch (error) {
        this.isSubmiting = false;
        this.isLoading = false;
      }
    },
    async getAdditionalVouchers() {
      this.isSubmiting = true;
      let schema = JSON.parse(localStorage.getItem("actionsSchema"));
      this.selectedColumns = schema.selectedColumns || [];
      this.allColumns = schema.allColumns || [];
      ThirdPartyListAllColumn.fields.map((data) => {
        let selectedColumnsIndex = this.selectedColumns.findIndex(
          (x) => x.key == data.key
        );
        if (selectedColumnsIndex == -1) {
          let allColumnsIndex = this.allColumns.findIndex(
            (x) => x.key == data.key
          );
          if (allColumnsIndex == -1) {
            this.allColumns.push(data);
          }
        }
      });
      this.isSubmiting = false;
      this.getQuestionnariesColumnsData();
    },

    // default visible cols
    checkStickyField(value, fields) {
      let columnsIndex = this.selectedColumns.findIndex((x) => x == fields);
      if (columnsIndex != -1) {
        this.selectedColumns[columnsIndex].default = !!value;
      }
    },

    // set column width
    setFieldsWidth(value, fields) {
      let columnsIndex = this.selectedColumns.findIndex((x) => x == fields);
      if (columnsIndex != -1) {
        this.selectedColumns[columnsIndex].width = value;
      }
    },

    // get MSQ cols
    async getQuestionnariesColumnsData() {
      this.isSubmiting = true;
      let formData = {
        company_id: this.$route.params.id
      };
      try {
        let result = await HTTP(
          "post",
          "thirdparty/dynamic/columns",
          formData,
          makeAuthorizationHeader()
        );
        if (result.data) {
          result.data.dynamic_columns.map((items) => {
            let actionsIndex = this.allColumns.findIndex(
              (x) => x.key == items.key
            );
            if (actionsIndex == -1) {
              this.allColumns.push(items);
            }
          });
          this.questionnaireColumns = result.data.dynamic_columns;
          if (this.selectedColumns && this.selectedColumns.length > 0) {
            this.resetAllActionsValue(this.selectedColumns);
          }
        }
        this.isSubmiting = false;
      } catch (error) {
        this.isSubmiting = false;
      }
    },
    resetAllActionsValue(allSelectedActions) {
      if (allSelectedActions) {
        allSelectedActions.map((actions) => {
          let actionsIndex = this.allColumns.findIndex(
            (x) => x.key == actions.key
          );
          if (actionsIndex != -1) {
            this.allColumns.splice(actionsIndex, 1);
          }
        });
        this.allColumns.map((column) => {
          let actionsOccurIndex = this.selectedColumns.findIndex(
            (x) => x.label == column.label
          );
          if (actionsOccurIndex != -1) {
            this.selectedColumns[actionsOccurIndex] = column;
          }
        });
        allSelectedActions.map((actions) => {
          let actionsIndex = this.allColumns.findIndex(
            (x) => x.label == actions.label
          );
          if (actionsIndex != -1) {
            this.allColumns.splice(actionsIndex, 1);
          }
        });
      }
      this.onSubmitCols();
    }
  }
};
</script>

<style>
.kanban-col {
  min-height: 500px;
}
</style>
