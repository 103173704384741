import { FORM_SCORING_CONFIGURATION } from "@/constants";
import { cloneDeep } from "lodash";

/**
 * @namespace QuestionnaireRiskScoring
 */

/**
 * @typedef {object} RiskRatingObject
 * @property {number} maximumScore - highest possible score
 * @property {string} risk - risk score
 * @property {string} color - risk colour
 * @property {string} rating - risk rating
 */

/**
 * @typedef {object} TopicConfigurationObject
 * @property {string} name - name of topic
 * @property {number} highestPossibleScore - maximum allowed score
 * @property {number} weightage - weight of topic score for overall score
 * @property {RiskRatingObject[]} riskScoreRatings - list of risk ratings
 */

/**
 * @typedef {object} FormConfigurationObject
 * @property {RiskRatingObject[]} riskScoreRatings - List of risk score ratings
 * @property {TopicConfigurationObject[]} topics - list of topics
 */

/**
 * @typedef {object} FormRiskScore
 * @property {string} color - risk colour
 * @property {number} percentage - percentage of risk
 * @property {string} rating - risk rating
 * @property {string} risk - risk score
 * @property {object} data - form data
 */

/**
 * @typedef {object} TopicRisk
 * @property {string} topic - topic name
 * @property {number} weightage - weight of topic score for overall score
 * @property {number} actualScore - calculated score without limits
 * @property {number} finalScoreForTopic - calculated score with limits
 * @property {number} highestScore1 - highest allowed score
 * @property {object} data - form data
 */

/**
 * Get the total topics score from all questions
 * @memberof QuestionnaireRiskScoring
 * @param {object} parameter - Provided Object
 * @param {Array} parameter.form - form data
 * @param {TopicConfigurationObject[]} parameter.topicsData - topic config data for scoring
 * @returns {{string, number}} - Scores for provided topics
 */
const getTotalTopicScores = ({ form, topicsData } = {}) => {
  /*
   Create an object with all available topics as a key and default value to 0.
   This ensures we can reference all topics later.
   */
  const initialTopicsObject = topicsData.reduce((previousValue, topic) => {
    previousValue[topic.name] = 0;

    return previousValue;
  }, {});

  return form.reduce((topicsObject, question) => {
    const topicName = question?.dataAttributes?.find(
      (dataAttribute) => dataAttribute?.dataAttributeKey === "topic"
    )?.dataAttributeValue;
    const answerScore = question?.elements?.filter((answer) => answer?.score);

    // If the element doesn't have a topic or an answers we ignore it
    if (!topicName || !answerScore?.length) {
      return topicsObject;
    }

    const userScore =
      answerScore[0]?.postValue[0]?.score ||
      answerScore[0]?.postValue?.score ||
      answerScore[0]?.score;

    topicsObject[topicName] += parseFloat(userScore);

    return topicsObject;
  }, initialTopicsObject);
};

/**
 * Limit topic score by its highest possible score
 * @memberof QuestionnaireRiskScoring
 * @param {object} parameter - provided object
 * @param {{string, number}} parameter.totalTopicScores - calculated topic scores
 * @param {TopicConfigurationObject[]} parameter.topicsData - topics config data
 */
const capTotalTopicScore = ({ totalTopicScores, topicsData } = {}) => {
  for (const topic in totalTopicScores) {
    const highestTopicScore = topicsData.find(
      (topicConfig) => topicConfig?.name === topic
    )?.highestPossibleScore;

    // Cap the total topic score to the highest possible score.
    totalTopicScores[topic] = Math.min(
      totalTopicScores[topic],
      highestTopicScore
    );
  }
};

/**
 * Get weighted percentage
 * @memberof QuestionnaireRiskScoring
 * @param {object} parameter - provided object
 * @param {number} parameter.score - calculated score
 * @param {number} parameter.weight - weight of score
 * @param {number} parameter.highestScore - highest available score
 * @returns {number} - score percentage including weight
 */
const getPercentageBasedOnWeight = ({ score, weight, highestScore } = {}) =>
  (score / highestScore) * weight;

/**
 *
 * @memberof QuestionnaireRiskScoring
 * @param {object} parameter - provided object
 * @param {number} parameter.lowestRiskRange - lowest range of risk score
 * @param {number} parameter.highestRiskRange - highest range of risk score
 * @param {number} parameter.score - actual score
 * @returns {boolean} - result
 */
const isScoreBetweenZeroAndHighestScoreInLowestRiskRating = ({
  lowestRiskRange,
  highestRiskRange,
  score
} = {}) => !lowestRiskRange && score >= 0 && score < highestRiskRange;

/**
 *
 * @memberof QuestionnaireRiskScoring
 * @param {object} parameter - provided object
 * @param {number} parameter.lowestRiskRange - lowest range of risk score
 * @param {number} parameter.highestRiskRange - highest range of risk score
 * @param {number} parameter.score - actual score
 * @returns {boolean} - result
 */
const isScoreGreaterThanTheLowestScoreInHighestRiskRating = ({
  lowestRiskRange,
  highestRiskRange,
  score
} = {}) => !highestRiskRange && score >= lowestRiskRange;

/**
 *
 * @memberof QuestionnaireRiskScoring
 * @param {object} parameter - provided object
 * @param {number} parameter.lowestRiskRange - lowest range of risk score
 * @param {number} parameter.highestRiskRange - highest range of risk score
 * @param {number} parameter.score - actual score
 * @returns {boolean} - result
 */
const isScoreBetweenLowestAndHighestScoreInGivenRiskRating = ({
  lowestRiskRange,
  highestRiskRange,
  score
} = {}) => score >= lowestRiskRange && score < highestRiskRange;

/**
 * Get risk rating object based on score
 * @memberof QuestionnaireRiskScoring
 * @param {object} parameter - provided object
 * @param {number} parameter.score - score
 * @param {RiskRatingObject[]} parameter.riskScoreRatings - list of risk scores
 * @param {boolean} parameter.useHighRiskScore - If true uses the highest risk score
 * @returns {{}|RiskRatingObject} - returns risk rating or an empty object
 */
const getRiskRatings = ({ score, riskScoreRatings, useHighRiskScore } = {}) => {
  // Ensure the risk ratings are in the correct order
  const riskRatingInAscOrderOfMaximumScore = riskScoreRatings.sort((a, b) => {
    // If risk rating has no maximum score add it to the bottom of the sorted array.
    if (!a?.maximumScore) {
      return 1;
    }

    if (!b?.maximumScore) {
      return -1;
    }

    return a.maximumScore - b.maximumScore;
  });

  if (useHighRiskScore) {
    // Return the highest risk rating
    return riskRatingInAscOrderOfMaximumScore[
      riskRatingInAscOrderOfMaximumScore.length - 1
    ];
  }

  // Loop through risk score options and return the correct risk based on the score
  for (
    let index = 0;
    index < riskRatingInAscOrderOfMaximumScore.length;
    index++
  ) {
    // The highest value from the previous risk rating is the lowest value of the current risk rating
    const lowestRiskRange =
      riskRatingInAscOrderOfMaximumScore[index - 1]?.maximumScore;
    const highestRiskRange =
      riskRatingInAscOrderOfMaximumScore[index]?.maximumScore;

    if (
      isScoreBetweenZeroAndHighestScoreInLowestRiskRating({
        lowestRiskRange,
        highestRiskRange,
        score
      }) ||
      isScoreGreaterThanTheLowestScoreInHighestRiskRating({
        lowestRiskRange,
        highestRiskRange,
        score
      }) ||
      isScoreBetweenLowestAndHighestScoreInGivenRiskRating({
        lowestRiskRange,
        highestRiskRange,
        score
      })
    ) {
      return riskRatingInAscOrderOfMaximumScore[index];
    }
  }

  // If no risk ratings match the overall score it is invalid.
  return {};
};

/**
 * Get a list of topic risk objects
 * @memberof QuestionnaireRiskScoring
 * @param {object} parameter - provided object
 * @param {{string, number}} parameter.totalTopicScores - topic scores
 * @param {TopicConfigurationObject[]} parameter.topicsData - topics config data
 * @returns {TopicRisk[]} - list of topic risks
 */
const getTopicDataObjectList = ({ totalTopicScores, topicsData } = {}) =>
  topicsData.map(
    ({ name, weightage, highestPossibleScore, riskScoreRatings }) => {
      const topicPercentage = getPercentageBasedOnWeight({
        score: totalTopicScores[name],
        weight: weightage,
        highestScore: highestPossibleScore
      });
      const { risk = "Invalid", color = "Invalid" } = getRiskRatings({
        score: totalTopicScores[name],
        riskScoreRatings
      });

      // Build transfer object for each topic.
      // TODO: check if all this data is needed.
      return {
        topic: name,
        weightage,
        actualScore: totalTopicScores[name],
        finalScoreForTopic: topicPercentage,
        highestScore1: highestPossibleScore,
        data: {
          percentage: topicPercentage,
          score: totalTopicScores[name],
          risk,
          color
        }
      };
    }
  );

/**
 * Get the risk score details for entire form
 * @memberof QuestionnaireRiskScoring
 * @param {object} parameter - provided object
 * @param {{string, number}} parameter.topicDataObjectList - topic config data
 * @param {FormConfigurationObject} parameter.formConfiguration - form config object
 * @param {boolean} parameter.useHighRiskScore - use the highest risk score
 * @returns {FormRiskScore} - risk score for form
 */
const getTotalRiskData = ({
  topicDataObjectList,
  formConfiguration,
  useHighRiskScore
} = {}) => {
  let totalRiskPercentage = 0;

  for (let index = 0; index < topicDataObjectList.length; index++) {
    totalRiskPercentage += topicDataObjectList[index].finalScoreForTopic;
  }

  const {
    risk = "Invalid",
    color = "Invalid",
    rating = "Invalid"
  } = getRiskRatings({
    score: totalRiskPercentage,
    riskScoreRatings: formConfiguration.riskScoreRatings,
    useHighRiskScore
  });

  return {
    color,
    percentage: totalRiskPercentage,
    rating,
    risk
  };
};

/**
 * @memberof QuestionnaireRiskScoring
 * @param {object} parameter - provided object
 * @param {object} parameter.section - form section
 * @param {object} parameter.userQuestion - question answer from user
 * @returns {any} - list of high risk answers
 */
const getHighRiskAnswers = ({ section, userQuestion } = {}) => {
  for (let index = 0; index < section?.formElements?.length; index++) {
    const schemaTitle = section.formElements[index]?.label?.english;
    const userAnswerTitle = userQuestion?.elementTitle?.english;

    // Find the matching form schema element and add all possible high risk answers to an array
    if (
      schemaTitle === userAnswerTitle &&
      section?.formElements[index]?.highRiskAnsValue?.length
    ) {
      return section.formElements[index].highRiskAnsValue.map(
        (answer) => answer?.value
      );
    }
  }
};

/**
 * Returns true if a high risk answer has been entered by a user
 * @memberof QuestionnaireRiskScoring
 * @param {object} parameter - Provided object
 * @param {Array} parameter.form - user form
 * @param {Array} parameter.formSchema - form schema
 * @returns {boolean} - result
 */
const hasDefaultHighRiskAnswers = ({ form, formSchema } = {}) => {
  const highRiskQuestions = form.find((userQuestion) => {
    // Only check answers if the question is high risk
    if (userQuestion?.isDefaultHighRisk) {
      for (let index = 0; index < formSchema?.length; index++) {
        const highRiskAnswersForUserQuestion = getHighRiskAnswers({
          section: formSchema[index],
          userQuestion
        });

        // Check if the users answer matches any high risk answers
        if (
          highRiskAnswersForUserQuestion?.includes(
            userQuestion?.elements[0]?.postValue?.value
          )
        ) {
          return true;
        }
      }
    }
    return false;
  });

  // If any user answers are high risk return true;
  return !!highRiskQuestions;
};

/**
 * Returns a question schema that is marked as a combined question
 * @memberof QuestionnaireRiskScoring
 * @param {object} parameter - provided object
 * @param {object} parameter.section - form section
 * @param {object} parameter.userQuestion - user questions
 * @returns {*} - question schema or void
 */
const getCombinedHighRiskQuestionSchema = ({ section, userQuestion } = {}) => {
  for (let index = 0; index < section?.formElements?.length; index++) {
    const schemaTitle = section.formElements[index]?.label?.english;
    const userAnswerTitle = userQuestion?.elementTitle?.english;
    const combinedHighRisk =
      section.formElements[index]?.combinedHighRiskAnswerValues?.length;

    if (combinedHighRisk && schemaTitle === userAnswerTitle) {
      return section.formElements[index];
    }
  }
};

/**
 * Returns a question with marked as a combined question with user matched answers
 * @memberof QuestionnaireRiskScoring
 * @param {object} parameter - provided object
 * @param {Array} parameter.form - user form
 * @param {Array} parameter.formSchema - for schema
 * @returns {*} high risk question schema or null
 */
const getInitialCombinedHighRiskQuestionWithUserMatchedAnswersSchema = ({
  form,
  formSchema
} = {}) => {
  for (let formIndex = 0; formIndex < form.length; formIndex++) {
    if (form[formIndex].isCombinedHighRisk) {
      for (
        let schemaIndex = 0;
        schemaIndex < formSchema?.length;
        schemaIndex++
      ) {
        // Get the high risk question schema
        const combinedHighRiskQuestionSchema =
          getCombinedHighRiskQuestionSchema({
            section: formSchema[schemaIndex],
            userQuestion: form[formIndex]
          });

        if (combinedHighRiskQuestionSchema) {
          // Create array of all high risk answers
          const combinedHighRiskAnswersForUserQuestion =
            combinedHighRiskQuestionSchema?.combinedHighRiskAnswerValues?.map(
              (answer) => answer?.value
            );

          // Check if the users answer matches any high risk answers
          if (
            combinedHighRiskAnswersForUserQuestion?.includes(
              form[formIndex]?.elements[0]?.postValue?.value
            )
          ) {
            return combinedHighRiskQuestionSchema;
          }
        }
      }
    }
  }

  return null;
};

/**
 * Returns true if the provided question has a high risk user answer
 * @memberof QuestionnaireRiskScoring
 * @param {object} parameter - provided object
 * @param {Array} parameter.form - user form
 * @param {string} parameter.combinedQuestion - question title
 * @param {Array} parameter.combinedAnswers - list of answers from combined questions
 * @returns {boolean} true if user form has any combined question answers
 */
const getUserAnsweredCombinedQuestions = ({
  form,
  combinedQuestion,
  combinedAnswers
}) => {
  let userSelectedValue;
  const combinedUserQuestion = form.find(
    (userQuestion) => userQuestion?.elementTitle?.english === combinedQuestion
  );

  // Get the user selected value;
  if (combinedUserQuestion?.elements[0]?.postValue?.comboSelectThird) {
    userSelectedValue =
      combinedUserQuestion.elements[0].postValue.comboSelectThird[0].value
        ?.groupName?.english;
  } else {
    userSelectedValue = combinedUserQuestion?.elements[0]?.postValue?.value;
  }

  return combinedAnswers.includes(userSelectedValue);
};

/**
 *  Returns an array of booleans - each boolean represents if a user has answered
 *  a high risk combination question
 * @memberof QuestionnaireRiskScoring
 * @param {object} parameter - provided object
 * @param {object} parameter.initialCombinedHighRiskQuestionSchema - High risk question schema
 * @param {Array} parameter.formSchema - form schema
 * @param {Array} parameter.form - user completed form
 * @returns {boolean[]} - list of high risk user answers
 */
const getMatchingCombinedUserAnswers = ({
  initialCombinedHighRiskQuestionSchema,
  formSchema,
  form
} = {}) =>
  initialCombinedHighRiskQuestionSchema.combinedHighRiskQuestionsAndAnswers.reduce(
    (hasCombinedHighRiskAnswers, currentHighRiskQuestion) => {
      const combinedQuestion = currentHighRiskQuestion.question;
      const combinedAnswers = currentHighRiskQuestion.answers.map(
        (answers) => answers?.value?.value
      );

      for (
        let schemaIndex = 0;
        schemaIndex < formSchema.length;
        schemaIndex++
      ) {
        const formElements = formSchema[schemaIndex]?.formElements;

        for (
          let elementIndex = 0;
          elementIndex < formElements?.length;
          elementIndex++
        ) {
          const schemaTitle = formElements[elementIndex].label?.english;

          if (schemaTitle === combinedQuestion) {
            const userAnsweredCombinedQuestions =
              getUserAnsweredCombinedQuestions({
                form,
                combinedQuestion,
                combinedAnswers
              });

            hasCombinedHighRiskAnswers.push(userAnsweredCombinedQuestions);
          }
        }
      }

      return hasCombinedHighRiskAnswers;
    },
    []
  );

/**
 * Returns true if user answers any high risk combined questions
 * @memberof QuestionnaireRiskScoring
 * @param {object} parameter - provided object
 * @param {Array} parameter.form - user form data
 * @param {Array} parameter.formSchema - form schema
 * @returns {boolean} - returns true if user form has any high risk answers
 */
const hasCombinedHighRiskAnswers = ({ form, formSchema } = {}) => {
  let userHasMatchingCombinedAnswers;
  const initialCombinedHighRiskQuestionSchema =
    getInitialCombinedHighRiskQuestionWithUserMatchedAnswersSchema({
      form,
      formSchema
    });

  if (initialCombinedHighRiskQuestionSchema) {
    userHasMatchingCombinedAnswers = getMatchingCombinedUserAnswers({
      initialCombinedHighRiskQuestionSchema,
      formSchema,
      form
    });

    // All questions need to match otherwise it's a false match
    return !userHasMatchingCombinedAnswers.includes(false);
  }

  return false;
};

/**
 * Process form and return risk score for form and individual topics
 * @memberof QuestionnaireRiskScoring
 * @param {object} parameter - provided object
 * @param {Array} parameter.form - user form
 * @param {string} parameter.formId - form id
 * @param {Array} parameter.formSchema - form schema
 * @returns {object} - object of risk scores
 */
const getRiskScores = ({ form, formId, formSchema } = {}) => {
  let overrideScoreToHighRisk;

  formSchema.forEach((section) => {
    section.formElements.forEach(() => {});
  });

  // Fetch form configuration object.
  const formConfiguration = cloneDeep(FORM_SCORING_CONFIGURATION[formId]);

  // Combine all scores for each topic
  const totalTopicScores = getTotalTopicScores({
    form,
    topicsData: formConfiguration.topics
  });

  // Max score for each topic
  capTotalTopicScore({
    totalTopicScores,
    topicsData: formConfiguration.topics
  });

  // Create topic object list for API
  const topicDataObjectList = getTopicDataObjectList({
    totalTopicScores,
    topicsData: formConfiguration.topics
  });

  overrideScoreToHighRisk = hasDefaultHighRiskAnswers({
    form,
    formSchema
  });

  if (!overrideScoreToHighRisk) {
    overrideScoreToHighRisk = hasCombinedHighRiskAnswers({
      form,
      formSchema
    });
  }

  // Get risk score for whole form
  const totalRisk = getTotalRiskData({
    topicDataObjectList,
    formConfiguration,
    useHighRiskScore: overrideScoreToHighRisk
  });

  // Return total risk and array of topic risks
  return { totalRisk, topicDataObjectList };
};

export {
  getTotalTopicScores,
  capTotalTopicScore,
  getPercentageBasedOnWeight,
  isScoreBetweenZeroAndHighestScoreInLowestRiskRating,
  isScoreGreaterThanTheLowestScoreInHighestRiskRating,
  isScoreBetweenLowestAndHighestScoreInGivenRiskRating,
  getRiskRatings,
  getTopicDataObjectList,
  getTotalRiskData,
  getHighRiskAnswers,
  hasDefaultHighRiskAnswers,
  getCombinedHighRiskQuestionSchema,
  getInitialCombinedHighRiskQuestionWithUserMatchedAnswersSchema,
  getUserAnsweredCombinedQuestions,
  getMatchingCombinedUserAnswers,
  hasCombinedHighRiskAnswers,
  getRiskScores
};
