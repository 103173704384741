
class Helper {
  makeToast(show, variant = null, message, title, noAutoHide = false, noCloseButton = false, href = '') {
    show.toast(message, {
      title,
      variant,
      solid: true,
      autoHideDelay: 500,
      noAutoHide,
      noCloseButton,
      href
    });
  }
}
export default new Helper();
