import { userTrainingService } from "@/services";
import { actionName } from "@/constants";

export const actions = {
  async addNewTrainingLearner({ rootState, dispatch }, learner) {
    const { tenantId } = rootState.company;
    const { thirdPartyId, id: courseId } = learner;

    await userTrainingService.addNewTrainingLearner({ tenantId, learner });

    await dispatch(
      actionName.THIRD_PARTY.FETCH_THIRD_PARTY_TRAINING_DETAILS,
      { thirdPartyId, courseId },
      { root: true }
    );
  }
};
