import { endpoints } from "@/constants";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { makeLearnerAPIDTO } from "./userTraining.dto";

const addNewTrainingLearner = async ({ tenantId, learner } = {}) => {
  const makeCustomHeaders = {
    ...makeAuthorizationHeader(),
    tenantId
  };
  const response = await HTTP(
    "post",
    endpoints.ADD_USER_TRAINING_LEARNER,
    makeLearnerAPIDTO(learner),
    makeCustomHeaders
  );
  return response;
};

export const userTrainingService = {
  addNewTrainingLearner
};
