import { actionKeys, actionKeysApi, actionStepType } from "@/constants";
import {
  makeDestinationAPIDTO,
  makeDestinationDTO,
  makeExpectedDataMappingDTO,
  makeExpectedDataMappingAPIDTO
} from "@/services/commonDto";
import { getSelectedOption } from "@/utils";

const makeNotificationEmailStepAPIDTO = ({
  functionStep = {},
  entities = [],
  _makeExpectedDataMappingAPIDTO = makeExpectedDataMappingAPIDTO,
  _makeDestinationAPIDTO = makeDestinationAPIDTO
} = {}) => ({
  [actionKeysApi.COMMENT]: functionStep?.comment,
  [actionKeysApi.STEP_TYPE]: actionStepType.NOTIFICATION,
  [actionKeysApi.SPECIFICATION]: {
    [actionKeysApi.TYPE]: functionStep?.stepType,
    [actionKeysApi.TEMPLATE_ID]: getSelectedOption(
      functionStep?.emailNotificationOptions
    )?.value,
    [actionKeysApi.EXPECTED_DATA_MAPPING]:
      functionStep?.emailNotificationExpectedData.map((expectedData) =>
        _makeExpectedDataMappingAPIDTO({ expectedData, entities })
      ),
    [actionKeysApi.TO]: _makeDestinationAPIDTO({
      emailDestinations:
        functionStep.emailNotificationToDestinationOptions.filter(
          ({ selected, amendable }) => selected && amendable
        ),
      entities
    }),
    [actionKeysApi.CC]: _makeDestinationAPIDTO({
      emailDestinations:
        functionStep.emailNotificationCcDestinationOptions.filter(
          ({ selected, amendable }) => selected && amendable
        ),
      entities
    }),
    [actionKeysApi.BCC]: _makeDestinationAPIDTO({
      emailDestinations:
        functionStep.emailNotificationBccDestinationOptions.filter(
          ({ selected, amendable }) => selected && amendable
        ),
      entities
    })
  }
});
const makeNotificationEmailStepDTO = ({
  functionStep = {},
  _makeExpectedDataMappingDTO = makeExpectedDataMappingDTO,
  _makeDestinationDTO = makeDestinationDTO
}) => ({
  [actionKeys.COMMENT]: functionStep?.[actionKeysApi.COMMENT],
  [actionKeys.STEP_TYPE]:
    functionStep?.[actionKeysApi.SPECIFICATION]?.[actionKeysApi.TYPE],
  [actionKeys.SPECIFICATION]: {
    [actionKeys.TYPE]: functionStep?.[actionKeysApi.STEP_TYPE],
    [actionKeys.TEMPLATE_ID]:
      functionStep?.[actionKeysApi.SPECIFICATION]?.[actionKeysApi.TEMPLATE_ID],
    [actionKeys.EXPECTED_DATA_MAPPING]: functionStep?.[
      actionKeysApi.SPECIFICATION
    ]?.[actionKeysApi.EXPECTED_DATA_MAPPING].map((expectedData) =>
      _makeExpectedDataMappingDTO({ expectedData })
    ),
    [actionKeys.TO]: _makeDestinationDTO({
      emailDestinations:
        functionStep?.[actionKeysApi.SPECIFICATION]?.[actionKeysApi.TO]
    }),
    [actionKeys.CC]: _makeDestinationDTO({
      emailDestinations:
        functionStep?.[actionKeysApi.SPECIFICATION]?.[actionKeysApi.CC]
    }),
    [actionKeys.BCC]: _makeDestinationDTO({
      emailDestinations:
        functionStep?.[actionKeysApi.SPECIFICATION]?.[actionKeysApi.BCC]
    })
  }
});

export { makeNotificationEmailStepDTO, makeNotificationEmailStepAPIDTO };
