import {
  additionalVouchersKeysAPIDTO,
  additionalVouchersKeysDTO,
  companyKeysAPIDTO,
  companyKeysDTO,
  companyUserListKeysAPIDTO,
  companyUserListKeysDTO,
  deleteCompanyUserKeysAPIDTO,
  deleteCompanyUserKeysDTO,
  companyFeaturesKeysDTO,
  companyFeaturesKeysAPIDTO
} from "@/constants";
import { makeRoleDTO } from "@/services/user/dto/user.dto";
import { makePermissionsDTO } from "@/services/permissions/dto/permissions.dto";

import { isJsonString } from "@/utils";

const makeSelectedCompanyDTO = (response = {}, _makeRoleDTO = makeRoleDTO) => {
  const { data = {} } = response;
  return {
    [companyKeysDTO.IBF_MEMBER_LOG]: data.hasOwnProperty([
      companyKeysAPIDTO.IBF_MEMBER_LOG
    ])
      ? {
          [companyKeysDTO.COMPANY_ID]:
            data[companyKeysAPIDTO.IBF_MEMBER_LOG]?.[
              companyKeysAPIDTO.COMPANY_ID
            ] || "",
          [companyKeysDTO.ID]:
            data[companyKeysAPIDTO.IBF_MEMBER_LOG]?.[companyKeysAPIDTO.ID] ||
            "",
          [companyKeysDTO.MEMBER_ID]:
            data[companyKeysAPIDTO.IBF_MEMBER_LOG]?.[
              companyKeysAPIDTO.MEMBER_ID
            ] || "",
          [companyKeysDTO.UPDATED_ON]:
            data[companyKeysAPIDTO.IBF_MEMBER_LOG]?.[
              companyKeysAPIDTO.UPDATED_ON
            ] || ""
        }
      : {},
    [companyKeysDTO.COMPANY_ID]: data[companyKeysAPIDTO.COMPANY_ID] || "",
    [companyKeysDTO.ROLES]:
      data[companyKeysAPIDTO.ROLES]?.map((role) => _makeRoleDTO(role)) || [],
    [companyKeysDTO.PERMISSIONS]:
      data[companyKeysAPIDTO.PERMISSIONS]?.map((permission) =>
        makePermissionsDTO(permission)
      ) || [],
    [companyKeysDTO.FAKE_HTTP_STATUS]:
      response[companyKeysAPIDTO.FAKE_HTTP_STATUS] || "",
    [companyKeysDTO.TENANT_ID]: data[companyKeysAPIDTO.TENANT_ID] || null
  };
};

const makeCompaniesDTO = (company = {}) => ({
  [companyKeysDTO.COMPANY_LIST]:
    company?.[companyKeysAPIDTO.COMPANY_LIST]?.map((company) => ({
      [companyKeysDTO.ID]: company?.[companyKeysAPIDTO.ID] || "",
      [companyKeysDTO.COMPANY_NAME]:
        company?.[companyKeysAPIDTO.COMPANY_NAME] || ""
    })) || [],
  [companyKeysDTO.FAKE_HTTP_STATUS]:
    company?.[companyKeysAPIDTO.FAKE_HTTP_STATUS] || ""
});

const makeCompanyUsersDTO = (response = {}) => ({
  [companyUserListKeysDTO.LAST_PAGE]:
    response?.[companyUserListKeysAPIDTO.LAST_PAGE] || "",
  [companyUserListKeysDTO.FROM]:
    response?.[companyUserListKeysAPIDTO.FROM] || "",
  [companyUserListKeysDTO.TO]: response?.[companyUserListKeysAPIDTO.TO] || "",
  [companyUserListKeysDTO.TOTAL]:
    response?.[companyUserListKeysAPIDTO.TOTAL] || "",
  [companyUserListKeysDTO.COMPANY_USERS]:
    response?.[companyUserListKeysAPIDTO.DATA]?.map((companyUser) => ({
      [companyUserListKeysDTO.COUNTRY_ID]:
        companyUser?.[companyUserListKeysAPIDTO.COUNTRY_ID] || "",
      [companyUserListKeysDTO.EMAIL]:
        companyUser?.[companyUserListKeysAPIDTO.EMAIL] || "",
      [companyUserListKeysDTO.FIRST_NAME]:
        companyUser?.[companyUserListKeysAPIDTO.FIRST_NAME] || "",
      [companyUserListKeysDTO.JOB_TITLE]:
        companyUser?.[companyUserListKeysAPIDTO.JOB_TITLE] || "",
      [companyUserListKeysDTO.LAST_NAME]:
        companyUser?.[companyUserListKeysAPIDTO.LAST_NAME] || "",
      [companyUserListKeysDTO.MEMBER_GROUP_ID]:
        parseInt(companyUser?.[companyUserListKeysAPIDTO.MEMBER_GROUP_ID]) ||
        null,
      [companyUserListKeysDTO.MEMBER_ID]:
        companyUser?.[companyUserListKeysAPIDTO.MEMBER_ID],
      [companyUserListKeysDTO.NAME]:
        companyUser?.[companyUserListKeysAPIDTO.NAME] || "",
      [companyUserListKeysDTO.ROLES]:
        companyUser?.[companyUserListKeysAPIDTO.ROLES]?.map((role) =>
          makeRoleDTO(role)
        ) || []
    })) || []
});

const makeDeleteCompanyUserDTO = (response = {}) => ({
  [deleteCompanyUserKeysDTO.FAKE_HTTP_STATUS]:
    response?.[deleteCompanyUserKeysAPIDTO.FAKE_HTTP_STATUS] || "",
  [deleteCompanyUserKeysDTO.MESSAGE]:
    response?.[deleteCompanyUserKeysAPIDTO.MESSAGE] || ""
});

const makeAdditionalVouchersDTO = (additionalVouchers = {}) => ({
  [additionalVouchersKeysDTO.FAKE_HTTP_STATUS]:
    additionalVouchers[additionalVouchersKeysAPIDTO.FAKE_HTTP_STATUS],
  [additionalVouchersKeysDTO.REMAINING_VOUCHERS]:
    parseInt(
      additionalVouchers[additionalVouchersKeysAPIDTO.REMAINING_VOUCHERS]
    ) || undefined,
  [additionalVouchersKeysDTO.COMPANY_DETAILS]:
    additionalVouchers.hasOwnProperty(
      additionalVouchersKeysAPIDTO.COMPANY_DETAILS
    )
      ? {
          [additionalVouchersKeysDTO.COMPANY_VIEWS]: additionalVouchers[
            additionalVouchersKeysAPIDTO.COMPANY_DETAILS
          ]?.[additionalVouchersKeysAPIDTO.COMPANY_VIEWS].map(
            (companyView) => ({
              [additionalVouchersKeysDTO.COMPANY_ID]: parseInt(
                companyView[additionalVouchersKeysAPIDTO.COMPANY_ID]
              ),
              [additionalVouchersKeysDTO.VIEW_TYPE]: parseInt(
                companyView[additionalVouchersKeysAPIDTO.VIEW_TYPE]
              ),
              [additionalVouchersKeysDTO.ID]:
                companyView[additionalVouchersKeysAPIDTO.ID],
              [additionalVouchersKeysDTO.TITLE]:
                companyView[additionalVouchersKeysAPIDTO.TITLE]
            })
          ),
          [additionalVouchersKeysDTO.ADDIONAL_DATA]: isJsonString(
            additionalVouchers[additionalVouchersKeysAPIDTO.COMPANY_DETAILS]?.[
              additionalVouchersKeysAPIDTO.ADDIONAL_DATA
            ]
          )
            ? JSON.parse(
                additionalVouchers[
                  additionalVouchersKeysAPIDTO.COMPANY_DETAILS
                ]?.[additionalVouchersKeysAPIDTO.ADDIONAL_DATA]
              )
            : "",
          [additionalVouchersKeysDTO.COMPANY_NAME]:
            additionalVouchers[additionalVouchersKeysAPIDTO.COMPANY_DETAILS]?.[
              additionalVouchersKeysAPIDTO.COMPANY_NAME
            ],
          [additionalVouchersKeysDTO.AUTO_FILL_MSQ_V2]:
            additionalVouchers[additionalVouchersKeysAPIDTO.COMPANY_DETAILS]?.[
              additionalVouchersKeysAPIDTO.AUTO_FILL_MSQ_V2
            ],
          [additionalVouchersKeysDTO.COMPANY_APPROVER]:
            additionalVouchers[additionalVouchersKeysAPIDTO.COMPANY_DETAILS]?.[
              additionalVouchersKeysAPIDTO.COMPANY_APPROVER
            ],
          [additionalVouchersKeysDTO.ONBOARDING_FORMS]:
            additionalVouchers[additionalVouchersKeysAPIDTO.COMPANY_DETAILS]?.[
              additionalVouchersKeysAPIDTO.ONBOARDING_FORMS
            ],
          [additionalVouchersKeysDTO.BILL_NAME]:
            additionalVouchers[additionalVouchersKeysAPIDTO.COMPANY_DETAILS]?.[
              additionalVouchersKeysAPIDTO.BILL_NAME
            ],
          [additionalVouchersKeysDTO.BILL_EMAIL]:
            additionalVouchers[additionalVouchersKeysAPIDTO.COMPANY_DETAILS]?.[
              additionalVouchersKeysAPIDTO.BILL_EMAIL
            ],
          [additionalVouchersKeysDTO.BILL_COUNTRY]:
            additionalVouchers[additionalVouchersKeysAPIDTO.COMPANY_DETAILS]?.[
              additionalVouchersKeysAPIDTO.BILL_COUNTRY
            ],
          [additionalVouchersKeysDTO.BILL_ADD]:
            additionalVouchers[additionalVouchersKeysAPIDTO.COMPANY_DETAILS]?.[
              additionalVouchersKeysAPIDTO.BILL_ADD
            ],
          [additionalVouchersKeysDTO.EDD_CONTRACT]:
            additionalVouchers[additionalVouchersKeysAPIDTO.COMPANY_DETAILS]?.[
              additionalVouchersKeysAPIDTO.EDD_CONTRACT
            ],
          [additionalVouchersKeysDTO.EDD_DEFAULT_REGION]:
            additionalVouchers[additionalVouchersKeysAPIDTO.COMPANY_DETAILS]?.[
              additionalVouchersKeysAPIDTO.EDD_DEFAULT_REGION
            ]
        }
      : {}
});

const makeCompanyFeaturesSettingOptionDTO = ({
  companyFeatureOptionSetting = {}
}) => ({
  [companyFeaturesKeysDTO.ID]:
    companyFeatureOptionSetting[companyFeaturesKeysAPIDTO.ID],
  [companyFeaturesKeysDTO.TEXT]:
    companyFeatureOptionSetting[companyFeaturesKeysAPIDTO.TEXT],
  [companyFeaturesKeysDTO.VALUE]:
    companyFeatureOptionSetting[companyFeaturesKeysAPIDTO.VALUE],
  [companyFeaturesKeysDTO.SETTING_ID]:
    companyFeatureOptionSetting[companyFeaturesKeysAPIDTO.SETTING_ID],
  [companyFeaturesKeysDTO.SEQUENCE]:
    companyFeatureOptionSetting[companyFeaturesKeysAPIDTO.SEQUENCE]
});

const makeCompanyFeaturesDTO = ({
  companyFeatures = [],
  _makeCompanyFeaturesSettingOptionDTO = makeCompanyFeaturesSettingOptionDTO
} = {}) =>
  companyFeatures?.map((companyFeature) => ({
    [companyFeaturesKeysDTO.NAME]:
      companyFeature[companyFeaturesKeysAPIDTO.NAME],
    [companyFeaturesKeysDTO.SETTING_ID]:
      companyFeature[companyFeaturesKeysAPIDTO.SETTING_ID],
    [companyFeaturesKeysDTO.TYPE]:
      companyFeature[companyFeaturesKeysAPIDTO.TYPE],
    [companyFeaturesKeysDTO.VALUE]:
      companyFeature[companyFeaturesKeysAPIDTO.VALUE],
    [companyFeaturesKeysDTO.SETTING_OPTION]: companyFeature?.[
      companyFeaturesKeysAPIDTO.SETTING_OPTION
    ]?.map((companyFeatureOptionSetting) =>
      _makeCompanyFeaturesSettingOptionDTO({
        companyFeatureOptionSetting
      })
    )
  })) || [];

export {
  makeSelectedCompanyDTO,
  makeCompaniesDTO,
  makeCompanyUsersDTO,
  makeDeleteCompanyUserDTO,
  makeAdditionalVouchersDTO,
  makeCompanyFeaturesDTO,
  makeCompanyFeaturesSettingOptionDTO
};
