const destinationKeysDTO = {
  TYPE: "type",
  VALUE: "value",
  EMAIL: "email",
  COMPANY_ID: "companyId",
  ROLE_ID: "roleId",
  USER_ID: "userId",
  DATA: "data",
  NAME: "name"
};

const destinationKeysAPIDTO = {
  TYPE: "type",
  VALUE: "value",
  EMAIL: "email",
  COMPANY_ID: "company_id",
  ROLE_ID: "role_id",
  USER_ID: "user_id",
  DATA: "data",
  NAME: "name"
};

export { destinationKeysDTO, destinationKeysAPIDTO };
