import { actions } from "./taskTemplates.actions.store";
import { mutations } from "./taskTemplates.mutations.store";
import { state } from "./taskTemplates.state.store";
import { getters } from "./taskTemplates.getters.store";

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state
};
