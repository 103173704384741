import { endpoints } from "@/constants";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { makeActionAPIDTO, makeActionDTO } from "./dto/actionsDTO/actions.dto";

const fetchActions = async (companyId, params = null) => {
  const response = await HTTP(
    "get",
    endpoints.ACTIONS(companyId),
    null,
    makeAuthorizationHeader(),
    params
  );
  response.data = response.data.map(makeActionDTO);
  return response;
};

const fetchAction = async (companyId, id, params = null) => {
  const response = await HTTP(
    "get",
    endpoints.ACTION(companyId, id),
    null,
    makeAuthorizationHeader(),
    params
  );
  response.data = makeActionDTO(response.data);
  return response;
};

const saveAction = async ({ companyId, action, entities, expectedData }) => {
  const response = await HTTP(
    "post",
    endpoints.ACTIONS(companyId),
    makeActionAPIDTO({ companyId, action, entities, expectedData }),
    makeAuthorizationHeader()
  );
  response.data = makeActionDTO(response.data);
  return response;
};

const amendAction = async ({
  companyId,
  actionId,
  action,
  entities,
  expectedData
}) => {
  const response = await HTTP(
    "put",
    endpoints.AMEND_ACTION(companyId, actionId),
    makeActionAPIDTO({ companyId, action, entities, expectedData }),
    makeAuthorizationHeader()
  );
  response.data = makeActionDTO(response.data);
  return response;
};

const deleteAction = (companyId, actionId) =>
  HTTP(
    "delete",
    endpoints.AMEND_ACTION(companyId, actionId),
    null,
    makeAuthorizationHeader()
  );

const cloneAction = (companyId, actionId) =>
  HTTP(
    "post",
    endpoints.CLONE_ACTION(companyId, actionId),
    null,
    makeAuthorizationHeader()
  );

export const actionsService = {
  fetchActions,
  fetchAction,
  saveAction,
  amendAction,
  deleteAction,
  cloneAction
};
