import {
  ultimateBeneficialOwnersKeysAPIDTO,
  ultimateBeneficialOwnersTableColumnKeys,
  urls
} from "@/constants";

const makeCreateThirdPartyDueDiligencePayload = ({
  selectedMemberIds = [],
  thirdPartyActionIds = []
}) => ({
  [ultimateBeneficialOwnersKeysAPIDTO.MEMBER_IDS]: selectedMemberIds,
  [ultimateBeneficialOwnersKeysAPIDTO.ACTION_IDS]: thirdPartyActionIds
});

const orderDueDiligence = ({
  gridAPI = {},
  thirdPartyId = 0,
  createThirdPartyFromUBO,
  thirdPartyActionIds,
  _makeCreateThirdPartyDueDiligencePayload = makeCreateThirdPartyDueDiligencePayload
}) => {
  const selectedRows = gridAPI.getSelectedRows();
  const selectedMemberIds = selectedRows.map((row) => row.memberId);
  const postData = _makeCreateThirdPartyDueDiligencePayload({
    selectedMemberIds,
    thirdPartyActionIds
  });

  createThirdPartyFromUBO({
    postData,
    thirdPartyId
  });
};

const navigateToUBOProfile = ({ router, memberId = 0, thirdPartyId = 0 }) => {
  router.push(
    urls.ULTIMATE_BENEFICIAL_OWNERS_PROFILE(`${thirdPartyId}`, `${memberId}`)
  );
};

const setAllRowsSelected = ({
  gridAPI,
  agGridOptions,
  setActionButtonDisabledState
}) => {
  const selectedRows = gridAPI.getSelectedRows();
  const newValue = selectedRows?.length === agGridOptions.rowData?.length;
  const columnDefs = gridAPI.getColumnDefs();
  const selectAllColumnIndex = columnDefs.findIndex(
    (column) => column.colId === ultimateBeneficialOwnersTableColumnKeys.SELECT
  );
  const oldValue =
    columnDefs[selectAllColumnIndex].headerComponentParams.value
      .componentOptions.checked;

  if (newValue !== oldValue) {
    columnDefs[
      selectAllColumnIndex
    ].headerComponentParams.value.componentOptions.checked = newValue;
  }

  gridAPI.setGridOption("columnDefs", columnDefs);

  setActionButtonDisabledState(selectedRows?.length);
};

const setRowSelection = ({
  rowNode,
  newValue,
  gridAPI,
  agGridOptions,
  setActionButtonDisabledState,
  _setAllRowsSelected = setAllRowsSelected
}) => {
  rowNode.setSelected(newValue, false);
  rowNode.data[
    ultimateBeneficialOwnersTableColumnKeys.SELECT
  ].componentOptions.checked = newValue;

  _setAllRowsSelected({
    gridAPI,
    agGridOptions,
    setActionButtonDisabledState
  });
};

const updateRowSelection = ({
  rowId = 0,
  gridAPI,
  agGridOptions,
  setActionButtonDisabledState,
  _setRowSelection = setRowSelection
}) => {
  const rowNode = gridAPI.getRowNode(rowId);
  const newValue = !rowNode.isSelected();

  _setRowSelection({
    rowNode,
    newValue,
    gridAPI,
    agGridOptions,
    setActionButtonDisabledState
  });
};

const updateAllRowSelections = ({
  gridAPI,
  agGridOptions,
  _setRowSelection = setRowSelection,
  setActionButtonDisabledState
}) => {
  const selectedRows = gridAPI.getSelectedRows();
  const newValue = selectedRows?.length !== agGridOptions.rowData?.length;

  gridAPI.forEachNode((rowNode) => {
    _setRowSelection({
      rowNode,
      newValue,
      gridAPI,
      agGridOptions,
      setActionButtonDisabledState
    });
  });
};

export {
  orderDueDiligence,
  makeCreateThirdPartyDueDiligencePayload,
  navigateToUBOProfile,
  updateRowSelection,
  setRowSelection,
  setAllRowsSelected,
  updateAllRowSelections
};
