export const getters = {
  getCountryCodeBasedOnCountryName:
    (state) =>
    (countryName = "") => {
      const country = state.countries.find(
        ({ country }) =>
          country?.toLowerCase?.() === countryName?.toLowerCase?.()
      );
      return country?.code;
    }
};
