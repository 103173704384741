<template>
  <div>
    <b-row>
      <b-col cols="12">
        <!-- Archived Due Diligence Reports -->
        <b-overlay
          :show="isLoadingInstantDueDiligenceArchivedReportsData"
          rounded="sm"
        >
          <b-card
            header-tag="header"
            class="border-0"
            body-class="cardBodyClass"
          >
            <template v-slot:header>
              <b-row>
                <b-col cols="9">
                  <h6>Archived Due Diligence Reports</h6>
                </b-col>
                <b-col cols="3" style="display: flex">
                  <b-button
                    class="mr-2"
                    variant="outline-info"
                    size="sm"
                    @click="getTpArchiveDueDeligenceReportStatus"
                  >
                    <font-awesome-icon icon="sync" />
                  </b-button>
                  <b-input-group size="sm">
                    <b-form-input
                      v-model="filterArchived"
                      type="search"
                      id="filterInputArchived"
                      placeholder="Type to Search"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        :disabled="!filterArchived"
                        @click="filterArchived = ''"
                        >{{ $t("clearBtnText") }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
            </template>
            <b-card-body
              style="paddingtop: 15px"
              class="dueScrollFlow"
              body-class="cardBodyClass"
            >
              <!-- Archived Due diligence table data start -->
              <b-table
                responsive="sm"
                :sort-by.sync="sortByArchive"
                hover
                :filter="filterArchived"
                :per-page="perPageArchive"
                :current-page="currentPageArchive"
                :items="instantDueDiligenceArchivedReportsItems"
                :fields="instantDueDiligenceReportsfields"
              >
                <template #thead-top="data">
                  <b-tr class="text-center">
                    <b-th variant="info" colspan="4">{{
                      $t("eb360.dueDiligenceRemediation.initialReport")
                    }}</b-th>
                    <b-th
                      v-if="
                        data.fields.findIndex(
                          (x) => x.key == 'after_remediation_alert_status'
                        ) != -1
                      "
                      variant="primary"
                      colspan="5"
                      >{{
                        $t("eb360.dueDiligenceRemediation.remediation")
                      }}</b-th
                    >
                  </b-tr>
                </template>
                <template v-slot:cell(inserted_date)="data">
                  {{ dateFormat(data.item.inserted_date) }}
                </template>
                <template v-slot:cell(product_type)="data">
                  <div
                    v-if="data.item.reportTypeText == 'OGM'"
                    style="text-transform: uppercase"
                  >
                    <div>
                      {{
                        data.item.product_type == "1"
                          ? "IDD"
                          : data.item.product_type == "2"
                          ? "IDD+"
                          : ""
                      }}
                      OGM
                      {{
                        data.item.remediation_date == null
                          ? "Unremediated"
                          : "Remediated"
                      }}
                      {{
                        data.item.rpt_status == "0"
                          ? "Green"
                          : data.item.rpt_status == "1"
                          ? "Red"
                          : ""
                      }}
                    </div>
                  </div>
                  <p v-else style="text-transform: uppercase">
                    {{ data.item.reportTypeText }}
                  </p>
                </template>
                <template v-slot:cell(initial_alert_status)="data">
                  <div v-if="data.item.reportTypeText == 'OGM'">
                    <IconWithText
                      v-bind="riskIcons.RISK_ALERT"
                    />
                  </div>
                  <div v-else>
                    <IconWithText
                      v-if="!data.item.initial_alert_status"
                      v-bind="riskIcons.NO_RISK_ALERT"
                    />
                    <IconWithText
                      v-else
                      v-bind="riskIcons.RISK_ALERT"
                    />
                  </div>
                </template>
                <template v-slot:cell(action)="data">
                  <b-dropdown
                    size="sm"
                    variant="outline"
                    toggle-class="text-decoration-none"
                    no-caret
                    v-if="data.item.reportTypeText != 'OGM'"
                  >
                    <template #button-content>
                      <font-awesome-icon icon="ellipsis-v" />
                    </template>
                    <b-dropdown-item-button
                      class="text-secondary"
                      @click="downloadArchivedPdfReport(data)"
                      >Download</b-dropdown-item-button
                    >
                  </b-dropdown>
                </template>
                <template v-slot:cell(remediation_date)="data">
                  <p v-if="data.item.remediation_date == null">&nbsp;</p>
                  <p v-else>{{ dateFormat(data.item.remediation_date) }}</p>
                </template>
                <template v-slot:cell(after_remediation_alert_status)="data">
                  <IconWithText
                    v-if="
                      data.item.after_remediation_alert_status == 5 ||
                      data.item.after_remediation_alert_status == 6
                    "
                    v-bind="
                      getIcon(data.item.after_remediation_alert_status)
                    "
                  />
                  <div
                    v-else-if="
                      (data.item.initial_alert_status == '0' ||
                        data.item.initial_alert_status == null) &&
                      data.item.reportTypeText != 'OGM'
                    "
                  ></div>
                  <div
                    v-else-if="
                      data.item.reportTypeText == 'OGM' &&
                      data.item.risk_document_id != null
                    "
                  >
                    <div
                      v-if="
                        data.item.product_type == '1' &&
                        data.item.rpt_status == '0'
                      "
                    >
                      <IconWithText
                        v-bind="riskIcons.NO_RISK_ALERT_REMEDIATED"
                      />
                    </div>
                    <div
                      v-else-if="
                        data.item.product_type == '2' &&
                        data.item.rpt_status == '0'
                      "
                    >
                      <IconWithText
                        v-bind="riskIcons.NO_RISK_ALERT_REMEDIATED"
                      />
                    </div>
                    <div
                      v-else-if="
                        data.item.product_type == '1' &&
                        data.item.rpt_status == '1'
                      "
                    >
                      <IconWithText
                        v-bind="riskIcons.RISK_ALERT_REMEDIATED"
                      />
                    </div>
                    <div
                      v-if="
                        data.item.product_type == '2' &&
                        data.item.rpt_status == '1'
                      "
                    >
                      <IconWithText
                        v-bind="riskIcons.RISK_ALERT_REMEDIATED"
                      />
                    </div>
                  </div>
                </template>
                <template v-slot:cell(remediation_action)="data">
                  <b-dropdown
                    size="sm"
                    variant="outline"
                    toggle-class="text-decoration-none"
                    no-caret
                    v-if="
                      data.item.after_remediation_alert_status == '6' ||
                      data.item.after_remediation_alert_status == '5'
                    "
                  >
                    <template #button-content>
                      <font-awesome-icon icon="ellipsis-v" />
                    </template>
                    <b-dropdown-item-button
                      class="text-secondary"
                      @click="downloadArchivedRemediatedReport(data)"
                      >Download</b-dropdown-item-button
                    >
                  </b-dropdown>
                  <b-dropdown
                    size="sm"
                    variant="outline"
                    toggle-class="text-decoration-none"
                    no-caret
                    v-else-if="
                      data.item.reportTypeText == 'OGM' &&
                      data.item.risk_document_id != null
                    "
                  >
                    <template #button-content>
                      <font-awesome-icon icon="ellipsis-v" />
                    </template>
                    <b-dropdown-item-button
                      class="text-secondary"
                      @click="downloadArchivedRemediatedOGMReport(data)"
                      >Download</b-dropdown-item-button
                    >
                  </b-dropdown>
                </template>
              </b-table>
              <!-- Archived Due diligence table data end -->

              <b-row>
                <b-col cols="2">
                  <b-form-select
                    v-model="perPageArchive"
                    id="perPageSelect"
                    size="sm"
                    :options="pageOptionsArchive"
                  ></b-form-select>
                </b-col>
                <b-col>
                  <b-pagination
                    class="float-right"
                    v-model="currentPageArchive"
                    :total-rows="rowsArchive"
                    :per-page="perPageArchive"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                    aria-controls="my-table"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { HTTP, makeAuthorizationHeader } from "@/utils";
import moment from "moment";
import "moment/locale/es";
import iconJson from "@/utils/icon.json";
import { mapState } from "vuex";
import IconWithText from "@/molecules/IconWithText/IconWithText";
import { actionStatusValue, riskIcons } from "@/constants";

export default {
  name: "ArchivedDueDiligenceRemediationMonitoringTabComponent",
  components: {
    IconWithText
  },
  data() {
    return {
      riskIcons,
      eb2AppUrl: process.env.VUE_APP_BASE_URL,
      instantDueDiligenceReportsfields: [
        {
          key: "inserted_date",
          label: this.$t("eb360.associateTabComponent.createdAt"),
          sortable: true
        },
        {
          key: "product_type",
          label: this.$t("eb360.documentsTabComponent.type"),
          sortable: true
        },
        {
          key: "initial_alert_status",
          label: this.$t("toast.title"),
          sortable: true
        },
        {
          key: "action",
          label: this.$t("eb360.associateDetailModal.action"),
          sortable: false
        }
      ],
      headerConfigData: null,
      iconData: iconJson,
      instantDueDiligenceArchivedReportsItems: [],
      isLoadingInstantDueDiligenceArchivedReportsData: false,
      pageOptionsArchive: [5, 10, 25, 50, 100],
      sortByArchive: "",
      perPageArchive: 5,
      currentPageArchive: 1,
      totalRowsArchive: 0,
      filterArchived: null
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId
    }),
    rowsArchive() {
      return this.instantDueDiligenceArchivedReportsItems.length;
    }
  },
  mounted() {
    this.isLoadingInstantDueDiligenceArchivedReportsData = true;
    this.getheaderConfigData();
  },
  methods: {
    dateFormat(data) {
      moment.locale("en");
      return moment.utc(data).local().format("LLLL");
    },
    async getheaderConfigData() {
      try {
        this.isLoading = true;
        let result = await HTTP(
          "get",
          `v1/get-header-config/${this.companyId}/${this.userId}`,
          null,
          makeAuthorizationHeader(),
          null,
          true
        );
        if (result.data && result.status == 200) {
          this.headerConfigData = result.data.data;
          if (
            (this.headerConfigData.autoremidd &&
              this.headerConfigData.autoremidd == 1) ||
            (this.headerConfigData.autoremiddplus &&
              this.headerConfigData.autoremiddplus)
          ) {
            this.instantDueDiligenceReportsfields.push(
              {
                key: "remediation_date",
                label: this.$t("eb360.dueDiligenceRemediation.date"),
                sortable: true
              },
              {
                key: "after_remediation_alert_status",
                label: this.$t("eb360.reviewResult.status"),
                sortable: true
              },
              {
                key: "remediation_action",
                label: this.$t("eb360.associateDetailModal.action"),
                sortable: false
              }
              // { key: "report_status", label: this.$t( 'eb360.reviewResult.status' ), sortable: true }
            );
          }
        }
        this.getTpArchiveDueDeligenceReportStatus();
        // this.getTpEDDReports();
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },
    downloadArchivedPdfReport(data) {
      let url = data.item.reportUrl ? data.item.reportUrl : "/";
      let apiUrl = process.env.VUE_APP_API_URL;
      apiUrl = apiUrl.substring(0, apiUrl.length - 4);
      window.open(`${apiUrl}${url}`, "_blank");
      return false;
    },

    downloadArchivedRemediatedReport(data) {
      let url = data.item.remediatedReport ? data.item.remediatedReport : "/";
      if (data.item.remediatedReport) {
        let apiUrl = process.env.VUE_APP_API_URL;
        apiUrl = apiUrl.substring(0, apiUrl.length - 4);
        window.open(`${apiUrl}${url}`, "_blank");
        return false;
      }
    },
    downloadArchivedRemediatedOGMReport(data) {
      if (data.item.remediation_date) {
        let url = data.item.remediatedReport ? data.item.remediatedReport : "/";
        if (data.item.remediatedReport) {
          let apiUrl = process.env.VUE_APP_API_URL;
          apiUrl = apiUrl.substring(0, apiUrl.length - 4);
          window.open(`${apiUrl}${url}`, "_blank");
          return false;
        }
      } else {
        let url = data.item.reportUrl ? data.item.reportUrl : "/";
        let apiUrl = process.env.VUE_APP_API_URL;
        apiUrl = apiUrl.substring(0, apiUrl.length - 4);
        window.open(`${apiUrl}${url}`, "_blank");
        return false;
      }
    },
    // get archive reports API
    async getTpArchiveDueDeligenceReportStatus() {
      let formData = {
        thirdPartyId: this.thirdPartyId
      };
      try {
        this.isLoadingInstantDueDiligenceArchivedReportsData = true;
        let result = await HTTP(
          "post",
          "thirdparty/instant/archivereports",
          formData,
          makeAuthorizationHeader()
        );
        if (result && result.data.success == 1 && result.status == 200) {
          let eddReports =
            result.data.eddReports && result.data.eddReports.length
              ? result.data.eddReports
              : [];
          let ogmReports =
            result.data.ogmReports && result.data.ogmReports.length
              ? result.data.ogmReports
              : [];

          this.instantDueDiligenceArchivedReportsItems =
            result.data.tpi_reports;
          if (eddReports && eddReports.length) {
            this.instantDueDiligenceArchivedReportsItems.push(...eddReports);
          }
          if (
            this.headerConfigData.specialFeature.autoremiddplusmoni &&
            parseInt(this.headerConfigData.specialFeature.autoremiddplusmoni) ==
              1 &&
            ogmReports &&
            ogmReports.length
          ) {
            this.instantDueDiligenceArchivedReportsItems.push(...ogmReports);
          }
        } else {
          this.instantDueDiligenceArchivedReportsItems = [];
        }
        this.$store.state.archivedDueDiligenceTabLazyLoad = false;
        this.isLoadingInstantDueDiligenceArchivedReportsData = false;
        this.$store.state.dueDiligenceRemediationMonitoringTabLazyLoad = false;
      } catch (error) {
        this.isLoadingInstantDueDiligenceArchivedReportsData = false;
      }
    },
    getIcon(actionStatus) {
      if (actionStatus === actionStatusValue.OK) {
        return riskIcons.NO_RISK_ALERT
      }
      return riskIcons.RISK_ALERT
    }
  },
  props: {
    companyId: Number,
    thirdPartyId: Number,
    thirdPartyData: Object
  }
};
</script>
<style lang="scss">
.page-item .page-link {
  color: $black-olive;
}

.page-item.active .page-link {
  background-color: $black-olive !important;
  border-color: $black-olive !important;
  color: $white !important;
}
.cardBodyClass {
  padding: 0px;
}
</style>
