<template>
  <div
    :class="[
      'dropdown-menu-with-cta',
      isDropdownVisible && 'dropdown-menu-with-cta--active'
    ]"
    data-test-id="dropdown-menu-with-cta"
    v-click-outside="hideDropdown"
  >
    <CallToAction
      v-bind="combinedCTAOptions"
      class="dropdown-menu-with-cta__cta"
      data-test-id="dropdown-menu-with-cta__cta"
      :aria-expanded="isDropdownVisible"
      @click="handleDropdownToggle"
      ref="ctaButton"
    />
    <Teleport to="body">
      <DropdownMenuList
        v-if="isDropdownVisible"
        class="dropdown-menu-with-cta__list"
        data-test-id="dropdown-menu-with-cta__list"
        :menu-options="menuOptions"
        :style="getStyles"
      />
    </Teleport>
  </div>
</template>

<script>
import CallToAction from "@/atoms/CallToAction/CallToAction";
import DropdownMenuList from "@/molecules/DropdownMenuList/DropdownMenuList";
import vClickOutside from "v-click-outside";
import { themes } from "@/constants";

export default {
  name: "DropdownMenuWithCallToAction",
  components: {
    CallToAction,
    DropdownMenuList
  },
  data() {
    return {
      isDropdownVisible: false,
      themes,
      top: 0,
      left: 0
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    CTAOptions: {
      type: Object,
      default: () => ({})
    },
    menuOptions: {
      type: Array,
      default: () => []
    },
    dropdownWidth: {
      type: Number,
      default: 195
    }
  },
  computed: {
    combinedCTAOptions() {
      return {
        icon: "ellipsis-v",
        theme: themes.GREY,
        ...this.CTAOptions
      };
    },
    getStyles() {
      return {
        top: this.top,
        left: this.left,
        width: `${this.dropdownWidth}px`
      };
    }
  },
  methods: {
    handleDropdownToggle() {
      if (this.isDropdownVisible) {
        this.hideDropdown();
      } else {
        this.showDropdown();
        this.setDropdownPosition();
      }
    },
    hideDropdown() {
      this.isDropdownVisible = false;
    },
    showDropdown() {
      this.isDropdownVisible = true;
    },
    setDropdownPosition() {
      const { x, y } = this.$refs.ctaButton?.$el?.getBoundingClientRect() || {};
      this.top = `${y}px`;
      this.left = `${x - this.dropdownWidth}px`;
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown-menu-with-cta {
  position: relative;
  display: inline-block;
  z-index: 1;
  transition: display $transition-duration;

  &--active {
    z-index: 2;
  }

  &__list {
    position: absolute;
    top: 0;
    z-index: 10;
    width: max-content;
  }
}
</style>
