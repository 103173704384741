import { actionTypes } from "@/constants";

export const getters = {
  getSystemActions: (state) =>
    state.actions.filter(
      ({ isSystem }) => isSystem === actionTypes.SYSTEM_ACTIONS
    ),
  getActionOptions: (state) =>
    state.actions.map(({ name, id }) => ({
      text: name,
      value: id
    })),
  getCustomActionOptions: (state) =>
    state.actions.reduce((acc, { name, id, isSystem }) => {
      if (isSystem === actionTypes.CUSTOM_ACTIONS) {
        acc.push({
          text: name,
          value: id
        });
      }

      return acc;
    }, []),
  getActionBasedOnId: (state) => (actionId) =>
    state.actions.find(({ id }) => id === actionId) || {}
};
