<template>
  <b-row>
    <b-col cols="12">
      <b-tabs content-class="mt-3">
        <b-tab title="Text">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabClick(data)"
              v-for="(data, index) in form.formTranslations"
              :key="index"
              :title="data"
            >
              <div>
                <b-form-group label="Title">
                  <b-form-input
                    v-model="fieldData.label[translationLanguage]"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Description">
                  <b-form-textarea
                    v-model="fieldData.description[translationLanguage]"
                  ></b-form-textarea>
                </b-form-group>
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- Items tabb -->
        <b-tab title="Add Items">
          <!-- Add Items tab -->
          <b-tabs pills card vertical>
            <b-tab
              @click="tabClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="active text-left mt-2">
                <table class="table table-sm">
                  <thead>
                    <tr class="text-center">
                      <th>Item text</th>
                      <th>Item Value</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in fieldData.items" :key="index">
                      <td>
                        <b-form-input
                          :value="item.message[translationLanguage]"
                          @input="setFieldItemsText($event, index)"
                          @focus="$event.target.select()"
                        ></b-form-input>
                      </td>
                      <td>
                        <b-form-input
                          :disabled="translationLanguage !== 'english'"
                          :value="item.value"
                          @input="setFieldItemsValue($event, index)"
                          @focus="$event.target.select()"
                        ></b-form-input>
                      </td>
                      <td>
                        <b-button
                          variant="outline-danger"
                          v-if="index > 1"
                          class="btn btn-outline-danger btn-sm"
                          @click="onDeleteOption(index)"
                        >
                          <i class="fa fa-trash"></i>
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <b-button variant="primary" @click="addItems">
                  <i class="fa fa-plus"></i>
                </b-button>
                <br />
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>
        <b-tab title="List Type">
          <b-form-radio-group
            id="radio-group-1"
            v-model="selectedListType"
            :options="options"
            name="list-type-options"
          ></b-form-radio-group>
        </b-tab>
        <b-tab title="List Style" v-if="selectedListType === 1">
          <b-form-radio-group
            v-model="selectedListStyle"
            :options="styleOptions"
            name="list-style-options"
          ></b-form-radio-group>
        </b-tab>
      </b-tabs>
    </b-col>
    <!-- End of column -->
  </b-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "AdvanceHeadingSettings",
  components: {},
  data() {
    return {
      description: null,
      selectedListType: 1,
      options: [
        { text: "Unorder List", value: 1 },
        { text: "Order List", value: 2 }
      ],
      selectedListStyle: "c",
      styleOptions: [
        { text: "Number", value: "a" },
        { text: "Alpha", value: "b" },
        { text: "Circle", value: "c" },
        { text: "Square", value: "d" }
      ],
      itemName: undefined,
      itemValue: undefined,

      translationLanguage: "english",

      label: undefined,

      isLoading: false
    };
  },
  created() {
    EventBus.on("onSaveSettings", () => {
      this.setAdvanceSettingsForSelect();
      this.addLanguageToField(this.translationLanguage);
    });
  },
  mounted() {
    this.selectedListType = this.fieldData.selectedListType;
    this.selectedListStyle = this.fieldData.selectedListStyle;
    this.label = this.fieldData.label[this.translationLanguage];
    this.description = this.fieldData.description[this.translationLanguage];
  },
  computed: {
    ...mapGetters({
      form: "form1"
    })
  },
  methods: {
    ...mapActions(["updateField"]),

    tabClick(key) {
      this.translationLanguage = key.toLowerCase();
      this.label = this.fieldData.label[this.translationLanguage];
      this.description = this.fieldData.description[this.translationLanguage];
    },

    addLanguageToField(language) {
      let tempKey = language.toLowerCase();
      this.fieldData.label[tempKey] = this.label;
      this.fieldData.description[tempKey] = this.description;
    },
    setFieldItemsValue(value, index) {
      this.fieldData.items[index].value;
      this.itemValue = value;
      this.fieldData.items[index].value = value;
    },

    setFieldItemsText(value, index) {
      this.itemName = value;
      this.fieldData.items[index].message[this.translationLanguage] = value;
    },

    addItems() {
      let newItem = {
        value: this.itemValue
          ? this.itemValue
          : `${this.fieldData.inputType} value${
              this.fieldData.items.length
            }${1}`,
        message: {
          english: this.itemName
            ? this.itemName
            : `${this.fieldData.inputType} message ${
                this.fieldData.items.length
              }${1}`
        }
      };

      this.fieldData.items.push(newItem);
    },

    onDeleteOption(index) {
      if (this.fieldData.items.length > 2) {
        this.fieldData.items.splice(this.fieldData.items.indexOf(index), 1);
      }
    },

    setAdvanceSettingsForSelect() {
      this.fieldData.selectedListType = this.selectedListType;
      this.fieldData.selectedListStyle = this.selectedListStyle;
    }
  },
  props: {
    fieldData: Object,
    sectionIndex: Number,
    fieldIndex: Number
  }
};
</script>

<style>
.cursor {
  cursor: pointer;
}
</style>
