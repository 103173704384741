import { breadcrumbRouteNames, routeNames, urls, templates } from "@/constants";
import TasksTemplate from "@/templates/TasksTemplate/TasksTemplate";
import Tasks from "@/organisms/Tasks/Tasks";
import Task from "@/organisms/Task/Task";

export default {
  path: urls.TASKS,
  name: routeNames.TASKS_WRAPPER,
  component: TasksTemplate,
  children: [
    {
      path: "",
      name: routeNames.TASKS,
      component: Tasks,
      meta: {
        requiresAuth: true,
        template: templates.EB360,
        isEb360HeaderRequired: true,
        isEB360SideBarRequired: true,
        breadcrumbs: () => [
          { name: breadcrumbRouteNames.HOME, link: urls.HOME },
          { name: breadcrumbRouteNames.TASKS, link: urls.TASKS }
        ]
      }
    },
    {
      path: urls.TASK,
      name: routeNames.TASK,
      redirect: () => urls.TASKS,
      meta: {
        requiresAuth: true,
        template: templates.EB360,
        isEb360HeaderRequired: true,
        isEB360SideBarRequired: true
      }
    },
    {
      path: urls.TASK_ID(),
      name: routeNames.TASK_ID,
      component: Task,
      meta: {
        requiresAuth: true,
        template: templates.EB360,
        isEb360HeaderRequired: true,
        isEB360SideBarRequired: true,
        breadcrumbs: () => [
          { name: breadcrumbRouteNames.HOME, link: urls.HOME },
          { name: breadcrumbRouteNames.TASKS, link: urls.TASKS },
          {
            name: breadcrumbRouteNames.TASK,
            link: urls.TASK
          }
        ]
      }
    }
  ]
};
