import { defaultRegisterProperties } from "@/constants";
import { state as makeState } from "./posthog.state.store";
import store from "@/store";

export const mutations = {
  setDefaultProperties(state) {
    state.defaultProperties = {
      [defaultRegisterProperties.PLATFORM_NAME]:
        store.state.company.companyName,
      [defaultRegisterProperties.PLATFORM_VERSION]:
        store.state.user.platformVersion
    };
  },
  resetPosthogStore(state) {
    Object.assign(state, makeState());
  }
};
