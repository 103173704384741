import { breadcrumbRouteNames, routeNames, urls, templates } from "@/constants";
import TaskWrapperTemplate from "@/templates/TaskWrapperTemplate/TaskWrapperTemplate";
import AddEditTaskTemplate from "@/organisms/AddEditTaskTemplate/AddEditTaskTemplate";
import TaskTemplates from "@/organisms/TaskTemplates/TaskTemplates";

export default {
  path: urls.TASK_TEMPLATES,
  name: routeNames.TASK_TEMPLATE_WRAPPER,
  component: TaskWrapperTemplate,
  children: [
    {
      path: "",
      name: routeNames.TASK_TEMPLATES,
      component: TaskTemplates,
      meta: {
        requiresAuth: true,
        template: templates.EB360,
        isEb360HeaderRequired: true,
        isEB360SideBarRequired: true,
        breadcrumbs: () => [
          { name: breadcrumbRouteNames.HOME, link: urls.HOME },
          {
            name: breadcrumbRouteNames.TASK_TEMPLATES,
            link: urls.TASK_TEMPLATES
          }
        ]
      }
    },
    {
      path: urls.EDIT_TASK_TEMPLATE,
      name: routeNames.EDIT_TASK_TEMPLATE,
      redirect: () => urls.TASK_TEMPLATES,
      meta: {
        requiresAuth: true,
        template: templates.EB360,
        isEb360HeaderRequired: true,
        isEB360SideBarRequired: true
      }
    },
    {
      path: urls.EDIT_TASK_TEMPLATE_ID(),
      name: routeNames.EDIT_TASK_TEMPLATE_ID,
      component: AddEditTaskTemplate,
      meta: {
        requiresAuth: true,
        template: templates.EB360,
        isEb360HeaderRequired: true,
        isEB360SideBarRequired: true,
        breadcrumbs: () => [
          { name: breadcrumbRouteNames.HOME, link: urls.HOME },
          {
            name: breadcrumbRouteNames.TASK_TEMPLATES,
            link: urls.TASK_TEMPLATES
          },
          {
            name: breadcrumbRouteNames.EDIT_TASK_TEMPLATE
          }
        ]
      }
    },
    {
      path: urls.ADD_TASK_TEMPLATE,
      name: routeNames.ADD_TASK_TEMPLATE,
      component: AddEditTaskTemplate,
      meta: {
        requiresAuth: true,
        template: templates.EB360,
        isEb360HeaderRequired: true,
        isEB360SideBarRequired: true,
        breadcrumbs: () => [
          { name: breadcrumbRouteNames.HOME, link: urls.HOME },
          {
            name: breadcrumbRouteNames.TASK_TEMPLATES,
            link: urls.TASK_TEMPLATES
          },
          {
            name: breadcrumbRouteNames.ADD_TASK_TEMPLATE
          }
        ]
      }
    }
  ]
};
