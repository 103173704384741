import { destinationTypes, getterName } from "@/constants";

export const getters = {
  getRolesDestinationOptions: (_, __, rootState) =>
    rootState.roles.roles.map(({ id, name }) => ({
      text: name,
      value: id,
      type: destinationTypes.ROLE,
      companyId: rootState.company.companyId
    })),
  getUsersDestinationOptions: (_, __, rootState) =>
    rootState.platformSettings.platformUserList.map(({ email, memberId }) => ({
      text: email,
      value: memberId,
      type: destinationTypes.USER,
      companyId: rootState.company.companyId
    })),
  getDestinationBaseOptions: (_, __, ___, rootGetters) => [
    ...rootGetters[
      getterName.USER_EMAIL_TEMPLATES.GET_ROLES_DESTINATION_OPTIONS
    ],
    ...rootGetters[
      getterName.USER_EMAIL_TEMPLATES.GET_USERS_DESTINATION_OPTIONS
    ]
  ],
  getUserEmailTemplatesOptions: (state) =>
    state.userEmailTemplates.map(({ id, name }) => ({
      text: name,
      value: id
    })),
  getUserEmailTemplateBasedById: (state) => (id) =>
    state.userEmailTemplates.find(
      (userEmailTemplate) => id === userEmailTemplate.id
    ) || {}
};
