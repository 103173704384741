<template>
  <section class="add-edit-role">
    <h2 class="add-edit-role__title">Role Configuration</h2>
    <form
      class="add-edit-role__form"
      data-test-id="add-edit-role__form"
      @submit.prevent
    >
      <div class="add-edit-role__form-wrapper">
        <InputErrorMessage :error="APIError" />
        <div class="add-edit-role__form-inputs">
          <Input
            id="roleName"
            label="Name"
            placeholder="Role name"
            class="add-edit-role__form-input"
            data-test-id="add-edit-role__form-input--name"
            :value="roleName"
            :error="getRoleNameError"
            @change="onChangeInput('roleName', $event)"
          />
          <Textarea
            id="roleDescription"
            label="Description"
            placeholder="Role description"
            class="add-edit-role__form-input"
            data-test-id="add-edit-role__form-input--description"
            :is-required="false"
            :value="roleDescription"
            :error="getRoleDescriptionError"
            @change="(_, $event) => onChangeInput('roleDescription', $event)"
          />
        </div>
      </div>
      <div class="add-edit-role__form-cta-holder">
        <BaseFooter>
          <template #right>
            <div class="add-edit-role__form-cta">
              <CallToAction
                type="button"
                theme="secondary"
                value="Close"
                @click="onCancel"
              />
            </div>
            <div class="add-edit-role__form-cta">
              <CallToAction
                type="button"
                value="Submit"
                :is-loading="isSavingRole"
                :is-error="hasRoleError"
                :is-success="isSavingSuccessful"
                @click="onSubmit"
              />
            </div>
          </template>
        </BaseFooter>
      </div>
    </form>
  </section>
</template>

<script>
import Input from "@/molecules/Input/Input";
import Textarea from "@/molecules/Textarea/Textarea";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import BaseFooter from "@/atoms/BaseFooter/BaseFooter";
import {
  actionName,
  routeNames,
  urls,
  userRolesKeysDTO,
  timers
} from "@/constants";
import { isEmpty } from "lodash";
import { mapActions } from "vuex";
import { makeRoleAPIDTO } from "@/services/user/dto/user.dto";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import { isLessThanOrEqualToMaxLength, isRequired } from "@/utils";

export default {
  name: "AddEditRoles",
  components: {
    InputErrorMessage,
    BaseFooter,
    CallToAction,
    Input,
    Textarea
  },
  data() {
    return {
      userRolesKeys: userRolesKeysDTO,
      roleName: "",
      roleNameErrorMessage: "",
      roleDescription: "",
      roleDescriptionErrorMessage: "",
      isSavingRole: false,
      hasRoleError: false,
      isSavingSuccessful: false,
      APIOperation: "",
      APIValidationError: {},
      APIError: ""
    };
  },
  props: {
    role: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    getRoleNameError() {
      return (
        this.roleNameErrorMessage ||
        this.APIValidationError[userRolesKeysDTO.NAME] ||
        ""
      );
    },
    getRoleDescriptionError() {
      return (
        this.roleDescriptionErrorMessage ||
        this.APIValidationError[userRolesKeysDTO.DESCRIPTION] ||
        ""
      );
    }
  },
  watch: {
    role: {
      deep: true,
      handler() {
        this.setEditRole();
      }
    },
    hasRoleError(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.hasRoleError = false;
        }, timers.MODERATE);
      }
    }
  },
  created() {
    this.setEditRole();
  },
  methods: {
    ...mapActions({
      createRole: actionName.ROLES.CREATED_ROLE,
      updateRole: actionName.ROLES.UPDATE_ROLE
    }),
    onChangeInput(inputName, value) {
      this[inputName] = value.trim();
    },
    onCancel() {
      this.$router.push(urls.ROLES);
    },
    setEditRole() {
      if (!isEmpty(this.role)) {
        this.roleName = this.role.name;
        this.roleDescription = this.role.description;
      }
    },
    makeRolePayload() {
      return {
        [userRolesKeysDTO.NAME]: this.roleName,
        [userRolesKeysDTO.DESCRIPTION]: this.roleDescription
      };
    },
    roleNameIsInvalid() {
      if (!isRequired(this.roleName)) {
        this.roleNameErrorMessage = "required";
        return true;
      } else if (!isLessThanOrEqualToMaxLength(this.roleName, 255)) {
        this.roleNameErrorMessage = "maximum of 255 characters";
        return true;
      } else {
        this.roleNameErrorMessage = "";
        return false;
      }
    },
    async onSubmit() {
      this.isSavingRole = true;
      this.APIError = "";
      this.APIValidationError = {};

      try {
        if (!this.roleNameIsInvalid()) {
          const payload = makeRoleAPIDTO(this.makeRolePayload());

          if (isEmpty(this.role)) {
            await this.createRole(payload);
            this.APIOperation = "created";
          } else {
            await this.updateRole({ role: payload, roleId: this.role.id });
            this.APIOperation = "updated";
          }

          this.isSavingSuccessful = true;
          setTimeout(() => {
            this.$router.push({
              name: routeNames.ROLES,
              state: { message: `Role ${this.APIOperation} successfully` }
            });
          }, timers.MODERATE);
        } else {
          this.hasRoleError = true;
        }
      } catch (error) {
        this.APIValidationError = error?.response?.data?.errors || {};
        if (isEmpty(this.APIValidationError)) {
          this.APIError =
            "Something went wrong, please try again. If the issue persists please contact support.";
        }
        this.hasRoleError = true;
      } finally {
        this.isSavingRole = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.add-edit-role {
  text-align: left;

  &__form {
    text-align: left;

    &-wrapper {
      height: calc(100vh - 230px);
      overflow-y: scroll;
    }

    &-inputs {
      padding-right: 20px;
      max-width: 50%;
    }

    &-cta-holder {
      text-align: right;
    }

    &-cta {
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
</style>
