<template>
  <div class="pri-results">
    <b-card style="border: none">
      <b-card-title>
        <div class="text-right">
          <b-dropdown size="sm" variant="outline-info" class="text-capitalize">
            <template v-slot:button-content>
              <font-awesome-icon icon="cogs" />
            </template>
            <b-dropdown-item
              data-test-id="pri-result__pie"
              @click="onSelectPieChart(chartTypes.PIE)"
            >
              Pie
            </b-dropdown-item>
            <b-dropdown-item
              data-test-id="pri-result__pyramid"
              @click="onSelectPieChart(chartTypes.PYRAMID)"
            >
              Pyramid
            </b-dropdown-item>
            <b-dropdown-item
              data-test-id="pri-result__funnel"
              @click="onSelectPieChart(chartTypes.FUNNEL)"
            >
              Funnel
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-card-title>
      <highcharts :options="getPriChartOptions" />
    </b-card>
  </div>
</template>

<script>
import { chartTypes } from "@/constants";

export default {
  name: "PriResultsComponent",
  props: {
    priChartOptions: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentChartType: this.priChartOptions.chart.type,
      chartTypes
    };
  },
  computed: {
    getPriChartOptions() {
      return {
        ...this.priChartOptions,
        chart: {
          type: this.currentChartType,
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        }
      };
    }
  },
  methods: {
    onSelectPieChart(chartType) {
      this.currentChartType = chartType;
    }
  }
};
</script>

<style lang="scss" scoped>
.pri-results {
  border: $element-border;
}
</style>
