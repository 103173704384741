import { permissionsService } from "@/services";
import { mutationName } from "@/constants";

export const actions = {
  async fetchFeaturePermissionsData({ commit, rootState }) {
    try {
      commit(
        mutationName.PERMISSIONS.SET_IS_FEATURE_PERMISSIONS_LOADING,
        true,
        {
          root: true
        }
      );

      const { data } = await permissionsService.fetchCompanyPermissions(
        rootState.company.companyId
      );

      commit(
        mutationName.PERMISSIONS.SET_FEATURE_PERMISSIONS,
        data?.permissions,
        {
          root: true
        }
      );

      return data;
    } finally {
      commit(
        mutationName.PERMISSIONS.SET_IS_FEATURE_PERMISSIONS_LOADING,
        false,
        {
          root: true
        }
      );
    }
  },
  async updateFeaturePermissionsData({ commit, rootState }, payload) {
    try {
      commit(
        mutationName.PERMISSIONS.SET_IS_UPDATING_FEATURE_PERMISSIONS,
        true,
        {
          root: true
        }
      );

      await permissionsService.updateCompanyPermissions({
        companyId: rootState.company.companyId,
        payload
      });

      commit(mutationName.PERMISSIONS.SET_FEATURE_PERMISSIONS, payload, {
        root: true
      });

      return true;
    } catch {
      return false;
    } finally {
      commit(
        mutationName.PERMISSIONS.SET_IS_UPDATING_FEATURE_PERMISSIONS,
        false,
        {
          root: true
        }
      );
    }
  },
  async fetchUserPermissionsData({ commit, rootState }) {
    try {
      commit(mutationName.PERMISSIONS.SET_IS_USER_PERMISSIONS_LOADING, true, {
        root: true
      });

      const { data } = await permissionsService.fetchUserPermissions(
        rootState.company.companyId
      );

      commit(mutationName.PERMISSIONS.SET_PERMISSIONS, data?.permissions, {
        root: true
      });

      return data;
    } finally {
      commit(mutationName.PERMISSIONS.SET_IS_USER_PERMISSIONS_LOADING, false, {
        root: true
      });
    }
  }
};
