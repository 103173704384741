import {
  expectedDataTypes,
  expectedDataTypesText,
  expectedDataPrimitiveTypes,
  expectedDataEntitiesTypes,
  expectedDataInstanceTypes
} from "@/constants/types.constant";
import {
  customAttributeRiskScales,
  customAttributeRiskScalesTextMap
} from "@/constants/ratings.constant";
import { emptyOption } from "@/constants/options-constant/options.constant";

const expectedDataMappingOptions = [
  {
    ...emptyOption,
    text: "Select..."
  },
  { value: expectedDataTypes.DDQ_FORM, text: expectedDataTypesText.DDQ_FORM },
  {
    value: expectedDataEntitiesTypes.THIRD_PARTY,
    text: expectedDataTypesText.THIRD_PARTY
  },
  {
    value: expectedDataEntitiesTypes.IDD_IDD_PLUS,
    text: expectedDataTypesText.IDD_IDD_PLUS
  },
  {
    value: expectedDataEntitiesTypes.DDQ_TASK,
    text: expectedDataTypesText.DDQ_TASK
  },
  {
    value: expectedDataInstanceTypes.STRING_LIST,
    text: expectedDataTypesText.STRING_LIST
  },
  {
    value: expectedDataInstanceTypes.MULTI_SELECT_STRING_LIST,
    text: expectedDataTypesText.MULTI_SELECT_STRING_LIST
  },
  {
    value: customAttributeRiskScales.TEN_POINT_SCALE,
    text: customAttributeRiskScalesTextMap.TEN_POINT_SCALE
  },
  {
    value: customAttributeRiskScales.ETHIXBASE_RISK_SCALE,
    text: customAttributeRiskScalesTextMap.ETHIXBASE_RISK_SCALE
  },
  {
    value: customAttributeRiskScales.FOUR_POINT_SCALE,
    text: customAttributeRiskScalesTextMap.FOUR_POINT_SCALE
  },
  {
    value: customAttributeRiskScales.ELEVEN_POINT_SCALE,
    text: customAttributeRiskScalesTextMap.ELEVEN_POINT_SCALE
  },
  {
    value: expectedDataPrimitiveTypes.STRING,
    text: expectedDataTypesText.STRING
  },
  {
    value: expectedDataPrimitiveTypes.NUMERIC,
    text: expectedDataTypesText.NUMERIC
  },
  {
    value: expectedDataPrimitiveTypes.BOOLEAN,
    text: expectedDataTypesText.BOOLEAN
  },
  {
    value: expectedDataPrimitiveTypes.DATETIME,
    text: expectedDataTypesText.DATETIME
  },
  {
    value: expectedDataPrimitiveTypes.COUNTRY,
    text: expectedDataTypesText.COUNTRY
  },
  {
    value: expectedDataPrimitiveTypes.MULTI_SELECT_COUNTRY,
    text: expectedDataTypesText.MULTI_SELECT_COUNTRY
  }
];

export { expectedDataMappingOptions };
