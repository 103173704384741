export default {
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
  "dashboardTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel úkolů"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domov"])},
  "my_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mé úkoly"])},
  "shared_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sdílené úkoly"])},
  "loadingSpinnerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítání..."])},
  "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termín pro vyplnění"])},
  "nav": {
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablony"])},
    "dandi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D&I"])}
  },
  "questionnaires": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dotazníky"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte dotazníky due diligence a poskytněte dokumentaci"])},
    "submitModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat dotazník"])},
    "formSubmittedModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulář odeslán"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhlasím"])},
    "backToDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět na panel nástrojů"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přehled"])},
    "dateOfAssigned:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum přiřazeno:"])},
    "dateOfCompleted:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum dokončení:"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stažení"])},
    "batchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokončeno"])},
    "batchInProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROBÍHÁ"])},
    "batchInProgressDateSetText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROBÍHÁ - SADA DAT"])},
    "batchInProgressNoDateSetText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROBÍHÁ - SADA NENÍ DATUM"])},
    "batchNotStartedext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezahájeno"])},
    "deleteRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Row"])},
    "editRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Row"])},
    "noSearchOptionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litujeme, žádné odpovídající možnosti."])},
    "requestedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadované datum"])}
  },
  "formLeaveAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chystáte se opustit stránku. Všechny neuložené změny budou ztraceny. Opravdu chcete odejít?"])},
  "formSubmitingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chystáte se odeslat svůj dotazník. Tuto akci nelze vrátit zpět. Opravdu chcete odeslat?"])},
  "formSubmittedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš formulář byl úspěšně odeslán."])},
  "formValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bylo nalezeno jedno nebo více chybějících polí nebo chyb. Zkontrolujte a podle potřeby aktualizujte."])},
  "toast": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výstraha"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit úspěch"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průběh se neukládá, zkuste to znovu"])},
    "updateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizovat úspěch"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
    "generatePDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generujeme váš PDF..."])},
    "successPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stažení souboru PDF proběhlo úspěšně"])},
    "errorPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF nebylo staženo, zkuste to znovu"])},
    "logoutSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšné odhlášení"])}
  },
  "createProfile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokončit registraci"])},
    "titleUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Aktualizovat profil"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailem"])},
    "contactHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktní údaje"])},
    "passwordHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizovat heslo"])},
    "fNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno"])},
    "lNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjmení"])},
    "contactLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktní číslo"])},
    "jobTitleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovní pozice"])},
    "currentPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální heslo"])},
    "newPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nové heslo"])},
    "confirmPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrďte heslo"])},
    "fNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte své křestní jméno"])},
    "lNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte své příjmení/příjmení "])},
    "contactPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte své kontaktní číslo"])},
    "jobTitlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte své pracovní místo nebo roli"])},
    "currentPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vložte stávající heslo"])},
    "newPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte nové heslo"])},
    "confirmPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte Potvrdit heslo"])},
    "requiredValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto pole je povinné"])},
    "passwordValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesla musí být stejná"])},
    "loaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše žádost se zpracovává..."])}
  },
  "upload": {
    "dragDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsah nahrajte přetažením myší!"])},
    "dragDropContnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...nebo kliknutím vyberte soubor z počítače."])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název souboru"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ souboru"])},
    "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velikost souboru (byty)"])},
    "fileAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opatření"])}
  },
  "signature": {
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vymazat"])},
    "require": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadované"])}
  },
  "workflow": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jasná"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetovat"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizace"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazat"])}
  },
  "languages": {
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
    "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
    "chinesetr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Traditional"])},
    "chinesesm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Simplified"])},
    "dutch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "gujarati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gujrati"])},
    "hebrew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebrew"])},
    "hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
    "indonesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesian"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
    "kannada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kannada"])},
    "marathi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marathi"])},
    "polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
    "portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
    "romanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romanian"])},
    "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
    "swedish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
    "tamil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamil"])},
    "telugu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telugu"])},
    "thai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai"])},
    "turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "ukrainian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrainian"])},
    "vietnamese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamese"])},
    "greek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greek"])},
    "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
    "czech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech"])},
    "hungarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungarian"])},
    "slovenian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenian"])}
  },
  "validations": {
    "invalidFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávný formát"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email je neplatný"])},
    "wrongPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatné aktuální heslo."])}
  },
  "dateRemainingCard": {
    "daysRemaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zbývající dny"])},
    "dateOfComp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum dokončení:"])}
  },
  "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
  "savingProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukládání..."])},
  "startBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "saveBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
  "confirmBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit"])},
  "continueBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])},
  "cancelBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
  "closeBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavřít"])},
  "deleteBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazat"])},
  "editBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit"])},
  "submitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat"])},
  "saveProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit postup"])},
  "loginBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se"])},
  "logoutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásit se"])},
  "signInBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se"])},
  "signOutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásit se"])},
  "updateBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizace"])},
  "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "helpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nápověda"])}
}