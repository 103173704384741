import { endpoints } from "@/constants";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import {
  makeExpectedDataDTO,
  makeParsedExpectedDataDTO,
  makeExpectedDataEntitiesDynamicReferenceDTO
} from "./dto/expectedData.dto";

const fetchExpectedData = async (companyId = "") => {
  const response = await HTTP(
    "get",
    endpoints.FETCH_EXPECTED_DATA(companyId),
    null,
    makeAuthorizationHeader()
  );
  response.data = response.data.map((expectedData) =>
    makeExpectedDataDTO({ expectedData })
  );
  return response;
};

const fetchParsedProxyExpectedData = async (companyId, payload) => {
  const response = await HTTP(
    "post",
    endpoints.FETCH_PROXY_EXPECTED_DATA(companyId),
    payload,
    makeAuthorizationHeader()
  );
  response.data = makeParsedExpectedDataDTO(response.data);
  return response;
};

const fetchEntitiesDynamicReference = async (dynamicUrl) => {
  const response = await HTTP(
    "get",
    dynamicUrl,
    null,
    makeAuthorizationHeader()
  );
  response.data = makeExpectedDataEntitiesDynamicReferenceDTO(response.data);
  return response;
};

const fetchParsedExpectedData = async (companyId, payload) => {
  const response = await HTTP(
    "post",
    endpoints.FETCH_PARSED_ACTIONS_DATA(companyId),
    payload,
    makeAuthorizationHeader()
  );
  response.data = response.data.map((expectedData) =>
    makeParsedExpectedDataDTO(expectedData)
  );
  return response;
};

export const expectedDataService = {
  fetchExpectedData,
  fetchParsedExpectedData,
  fetchParsedProxyExpectedData,
  fetchEntitiesDynamicReference
};
