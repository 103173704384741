<template>
  <div class="dashboard">
    <div class="dashboard__header">
      <div>
        <h5 class="text-left dashboard__title">
          <font-awesome-icon icon="chart-bar" />
          {{ getPageTitle }}
        </h5>
      </div>
      <div class="ml-auto mr-2 dashboard__question">
        <a :href="platformKnowledgeCentreLink" target="_blank">
          <BaseIcon
            :icon="icons.QUESTION"
            :shape="shapes.CIRCLE"
            :size="12"
            :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
          />
        </a>
      </div>
      <div class="mt-3">
        <b-button
          class="mr-2 mb-1"
          variant="outline-dark"
          size="sm"
          @click="printPageContent"
        >
          <i class="fa fa-print" aria-hidden="true"></i>
        </b-button>
        <b-dropdown
          v-if="hasSettingsDropdown"
          class="mr-3 mb-1"
          dropleft
          no-caret
          size="sm"
          toggle-class="text-decoration-none"
          variant="outline"
        >
          <template #button-content>
            <font-awesome-icon icon="ellipsis-v" />
          </template>
          <b-dropdown-item-button
            v-if="hasEditUserViewPermission"
            class="text-secondary"
            @click="openEditViewModal"
          >
            View Settings
          </b-dropdown-item-button>
          <b-dropdown-item-button
            v-if="hasDeleteViewPermission"
            class="text-secondary"
            @click="showDeleteModal"
          >
            Delete View
          </b-dropdown-item-button>
        </b-dropdown>
      </div>
    </div>
    <b-collapse v-model="advanceFilterForms" class="ml-5 mr-2">
      <b-card class="mb-2 bg-light">
        <b-row class="text-center">
          <b-col cols="12">
            <h4>Filter Result</h4>
            <div class="text-right">
              <b-btn
                class="mx-1"
                variant="outline-danger"
                @click="onResetFilters"
              >
                Clear Filters
              </b-btn>
              <b-btn
                class="mx-1"
                variant="outline-success"
                @click="onApplyFilters"
              >
                Apply
              </b-btn>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b-form-group label="Date Sent From:">
              <b-form-datepicker v-model="form.dateSentFrom" :max="maxDate" />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Date Sent To:">
              <b-form-datepicker
                v-model="form.dateSentTo"
                :min="form.dateSentFrom"
                :max="maxDate"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Count Type:">
              <v-select v-model="form.countType" :options="countTypeOptions" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-collapse>
    <b-alert
      v-model="resetFilter"
      class="ml-5 mr-2 dashboard__reset-filter"
      variant="warning"
    >
      <b>You are viewing filtered data.</b>
    </b-alert>
    <div id="analyticsUserView" class="dashboard__body">
      <div
        v-for="keyName in Object.keys(widgetsPerRow)"
        :key="keyName"
        class="mt-2"
      >
        <b-row class="dashboard__body--row">
          <b-col
            v-for="widget in widgetsPerRow[keyName]"
            :key="widget.key + widget.formId"
            :cols="calculateColumns(widget)"
          >
            <div
              v-if="
                widget.key === chartsEnum.TP_COUNT ||
                widget.key === chartsEnum.TP_OVER_TIME
              "
              v-observe-visibility="makeObserveVisibility(tpCountApiCall)"
            >
              <b-overlay :show="savedThirdPartiesLoading" rounded="sm">
                <TotalTPComponent
                  v-if="widget.key === chartsEnum.TP_COUNT"
                  :total-number-of-third-parties="totalNumberOfThirdParties"
                />
                <TpOverTimeComponent
                  v-else-if="widget.key === chartsEnum.TP_OVER_TIME"
                  :saved-third-party-charts-options="
                    savedThirdPartyChartsOptions
                  "
                />
              </b-overlay>
            </div>
            <div
              v-else-if="widget.key === chartsEnum.OGM_RESULTS"
              v-observe-visibility="makeObserveVisibility(ogmResultsApiCall)"
            >
              <b-overlay :show="ogmResultsLoading" rounded="sm">
                <OngoingMonitoringResults
                  :total-number-of-monitoring-alerts-this-month="
                    totalNumberOfMonitoringAlertsThisMonth
                  "
                  :total-number-of-third-parties-alerts-this-month="
                    totalNumberOfThirdPartiesAlertsThisMonth
                  "
                  :total-number-of-monitoring-alerts-last-month="
                    totalNumberOfMonitoringAlertsLastMonth
                  "
                  :total-number-of-third-parties-alerts-last-month="
                    totalNumberOfThirdPartiesAlertsLastMonth
                  "
                  :total-number-of-monitoring-alerts-all-time="
                    totalNumberOfMonitoringAlertsAllTime
                  "
                  :total-number-of-third-parties-alerts-all-time="
                    totalNumberOfThirdPartiesAlertsAllTime
                  "
                />
              </b-overlay>
            </div>
            <div
              v-else-if="widget.key === chartsEnum.TP_OVER_COUNTRIES_MAP"
              v-observe-visibility="makeObserveVisibility(mapChartApiCall)"
            >
              <b-overlay :show="mapChartLoading" rounded="sm">
                <TpOverCountriesComponent
                  :map-chart-options="mapChartOptions"
                />
              </b-overlay>
            </div>
            <div
              v-else-if="widget.key === chartsEnum.PRI_RESULTS"
              v-observe-visibility="makeObserveVisibility(priChartApiCall)"
            >
              <b-overlay :show="priActiveSanctionsChartLoading" rounded="sm">
                <PriResultsComponent :pri-chart-options="priChartOptions" />
              </b-overlay>
            </div>
            <div
              v-else-if="widget.key.includes(chartsEnum.DDQ_RISK_SCORE)"
              v-observe-visibility="
                makeObserveVisibility(ddqRiskScoreApiCall, widget)
              "
            >
              <b-overlay
                :show="ddqRiskScoreLoadingList.includes(widget.id)"
                rounded="sm"
              >
                <PriResultsComponent
                  :pri-chart-options="makeAddRiskScoreChartOptionList(widget)"
                />
              </b-overlay>
            </div>
            <div
              v-else-if="widget.key === chartsEnum.IDD_RESULTS"
              v-observe-visibility="
                makeObserveVisibility(iddActiveSanctionsChartApiCall)
              "
            >
              <b-overlay :show="iddActiveSanctionsChartLoading" rounded="sm">
                <IddResultsComponent
                  :sanctions-alerts-count-chart-options="
                    sanctionsAlertsCountChartOptions
                  "
                />
              </b-overlay>
            </div>
            <div
              v-else-if="
                widget.key === chartsEnum.IDD_PLUS_PRE_REMEDIATION_RESULTS
              "
              v-observe-visibility="
                makeObserveVisibility(iddPlusPreRemediationChartApiCall)
              "
            >
              <b-overlay
                :show="iddPlusPreRemediationActiveSanctionsChartLoading"
                rounded="sm"
              >
                <IddPlusPreRemediationResultsComponent
                  :idd-plus-pre-remediation-count-chart-options="
                    iddPlusPreRemediationCountChartOptions
                  "
                />
              </b-overlay>
            </div>
            <div
              v-else-if="
                widget.key === chartsEnum.IDD_PLUS_POST_REMEDIATION_RESULTS
              "
              v-observe-visibility="
                makeObserveVisibility(iddPlusPostRemediationChartApiCall)
              "
            >
              <b-overlay
                :show="iddPlusPostRemediationChartLoading"
                rounded="sm"
              >
                <IddPlusPostRemediationResultsComponent
                  :idd-plus-post-remediation-count-chart-options="
                    iddPlusPostRemediationCountChartOptions
                  "
                />
              </b-overlay>
            </div>
            <div
              v-else-if="widget.key === chartsEnum.EDD_RESULTS"
              v-observe-visibility="makeObserveVisibility(eddChartApiCall)"
            >
              <b-overlay :show="eddChartLoading" rounded="sm">
                <EddResultsComponent
                  :edd-result-count-chart-options="eddResultCountChartOptions"
                />
              </b-overlay>
            </div>
            <div v-else-if="widget.key.includes(chartsEnum.STANDARD_QUESTION)">
              <StandardQuestionsComponent
                :question-id="widget.questionId"
                :form-id="widget.formId"
                :company-id="companyId"
                :items-key="widget.key"
                :items-index="widget.index"
                :selected-widgets="selectedWidgets"
                :chart-type="widget.chartType"
                @questionClicked="handleStandardQuestionClick"
                @chart-type-select="handleChartTypeSelect"
              />
            </div>
            <div
              v-else-if="widget.key === chartsEnum.TP_OVER_COUNTRIES"
              v-observe-visibility="
                makeObserveVisibility(tpOverCountriesApiCall)
              "
            >
              <b-overlay :show="tpOverCountriesChartLoading" rounded="sm">
                <TpOverCountriesResultsComponent
                  :tp-countries-chart-options="tpCountriesChartOptions"
                />
              </b-overlay>
            </div>
            <div v-else-if="isChart(widget)">
              <DdqStatusComponent
                :form-id="widget.formId"
                :form-label="widget.label"
                :form-title="widget.title"
                :form-type="widget.formType"
                :items-index="widget.index"
                :items-key="widget.key"
                :items-type="widget.type"
                :selected-widgets="selectedWidgets"
              />
            </div>
            <div
              v-else-if="isEsgRatingChart(widget.key)"
              v-observe-visibility="
                makeObserveVisibility(overallEsgRatingsApiCall, widget)
              "
            >
              <b-overlay
                :show="isNil(esgRatingChartTypes[widget.key])"
                rounded="sm"
              >
                <BaseChartWidget
                  v-if="esgRatingChartTypes[widget.key]"
                  :chart-title="widget.label"
                  :chart-options="esgRatingChartTypes[widget.key]"
                />
              </b-overlay>
            </div>
            <div
              v-else-if="isEsgRatingChartStatus(widget.key)"
              v-observe-visibility="
                makeObserveVisibility(esgRatingChartStatusApiCall, widget)
              "
            >
              <b-overlay
                :show="isNil(esgRatingChartStatuses[widget.key])"
                rounded="sm"
              >
                <BaseChartWidget
                  v-if="esgRatingChartStatuses[widget.key]"
                  :chart-title="widget.label"
                  :chart-options="esgRatingChartStatuses[widget.key]"
                >
                  <template v-slot:chart>
                    <PointValue
                      :count="esgRatingChartStatuses[widget.key].count"
                      :total-esg-reports="
                        esgRatingChartStatuses[widget.key].total
                      "
                    />
                  </template>
                </BaseChartWidget>
              </b-overlay>
            </div>
            <div
              v-else-if="widget.key === esgChartsEnum.ESG_RISK_RESILIENCE"
              v-observe-visibility="
                makeObserveVisibility(scoreByRiskAndResilienceApiCall, widget)
              "
            >
              <b-overlay
                :show="isNil(riskResilienceScoreChartData)"
                rounded="sm"
              >
                <BaseChartWidget
                  v-if="riskResilienceScoreChartData"
                  :chart-title="widget.label"
                  :chart-options="riskResilienceScoreChartData"
                />
              </b-overlay>
            </div>
            <div
              v-else-if="isEsgResilienceStrengthChart(widget.key)"
              v-observe-visibility="
                makeObserveVisibility(
                  overallEsgResilienceStrengthApiCall,
                  widget
                )
              "
            >
              <b-overlay
                :show="isNil(esgResilienceStrengthChartData)"
                rounded="sm"
              >
                <BaseChartWidget
                  v-if="esgResilienceStrengthChartData"
                  :chart-title="widget.label"
                  :chart-options="esgResilienceStrengthChartData"
                />
              </b-overlay>
            </div>
            <div
              v-else-if="isEsgRiskSeverityChart(widget.key)"
              v-observe-visibility="
                makeObserveVisibility(overallEsgRiskSeverityApiCall, widget)
              "
            >
              <b-overlay :show="isNil(esgRiskSeverityChartData)" rounded="sm">
                <BaseChartWidget
                  v-if="esgRiskSeverityChartData"
                  :chart-title="widget.label"
                  :chart-options="esgRiskSeverityChartData"
                />
              </b-overlay>
            </div>
            <div
              v-else-if="isCustomAttributeWidgetChart(widget.key)"
              v-observe-visibility="
                makeObserveVisibility(customAttributeDataApiCall, widget)
              "
            >
              <b-overlay
                :show="isCustomAttributeDataLoading(widget.id)"
                rounded="sm"
              >
                <CustomAttributeChartWidget
                  v-if="customAttributesWidgetData[widget.id]"
                  v-bind="returnCustomAttributeOptions(widget.id)"
                  :widget="widget"
                  :data="customAttributesWidgetData[widget.id].data"
                  @drilldown-click="handleCustomAttributeDrilldownClick"
                />
              </b-overlay>
            </div>
            <span v-else>{{ widget.key }}</span>
          </b-col>
        </b-row>
      </div>
    </div>
    <ThirdPartyAnalysisModalTable
      :company-id="companyId"
      :modal-title="mapDrillDownOptions.modalTitle"
      :query-specific-data="mapDrillDownOptions.querySpecificData"
      :uri-data="mapDrillDownOptions.uriData"
      :table-id="mapDrillDownOptions.tableId"
      :table-title="mapDrillDownOptions.tableTitle"
      :use-new-query-parameter="mapDrillDownOptions.useNewQueryParameter"
      :useNameAsFilterQueryParameter="true"
      @hideModal="hideModal"
    />
    <b-modal ref="modal-delete" title="Are you sure?" @ok="deletePlatformView">
      <p class="my-4">Are you sure you want to delete view?</p>
    </b-modal>
  </div>
</template>

<script>
import TotalTPComponent from "./analyticsComponents/totalTPComponent.vue";
import TpOverTimeComponent from "./analyticsComponents/TpOverTimeComponent.vue";
import OngoingMonitoringResults from "./analyticsComponents/ongoingMonitoringResults.vue";
import TpOverCountriesComponent from "./analyticsComponents/TpOverCountriesComponent.vue";
import PriResultsComponent from "./analyticsComponents/PriResultsComponent/PriResultsComponent.vue";
import IddResultsComponent from "./analyticsComponents/iddResultComponent.vue";
import IddPlusPreRemediationResultsComponent from "./analyticsComponents/iddPlusPreRemediationResultsComponent.vue";
import IddPlusPostRemediationResultsComponent from "./analyticsComponents/iddPlusPostRemediationResultsComponent.vue";
import EddResultsComponent from "./analyticsComponents/eddResultsComponent.vue";
import TpOverCountriesResultsComponent from "./analyticsComponents/TpOverCountriesResultsComponent.vue";
import StandardQuestionsComponent from "./analyticsComponents/standardQuestionsComponent.vue";
import DdqStatusComponent from "./analyticsComponents/ddqStatusComponent.vue";
import ThirdPartyAnalysisModalTable from "@/organisms/ThirdPartyAnalysisModalTable/ThirdPartyAnalysisModalTable";
import BaseChartWidget from "@/atoms/BaseChartWidget/BaseChartWidget";
import PointValue from "@/molecules/PointValue/PointValue";
import CustomAttributeChartWidget from "@/molecules/CustomAttributeChartWidget/CustomAttributeChartWidget";
import {
  chartsEnum,
  chartTypes,
  countTypeDashboardOptions,
  countTypeMostRecentOption,
  ddqRiskScoreName,
  eddResultName,
  httpStatuses,
  iddPlusPostRemediationName,
  iddPlusPreRemediationName,
  pieChartName,
  pieChartNameMap,
  sanctionsAlertsName,
  queryObjectKeys,
  chartsEnumId,
  colors,
  chartsStatusType,
  esgChartsEnum,
  mutationName,
  actionName,
  customAttributesWidgetKeys,
  attributeType,
  themes,
  icons,
  shapes,
  chartsStandardQuestionChartType,
  endpoints,
  featurePermissionsKeys,
  userPermissionKeysDTO,
  getterName,
  urls
} from "@/constants";
import { chartsService, viewsService, esgService } from "@/services";
import {
  isValidResponse,
  makeChartCountryObj,
  makeDdqRiskScoreChartOptions,
  makeDrillDownOptions,
  makeEddResultCountChartOptions,
  makeIddPlusPostRemediationCountChartOptions,
  makeIddPlusPreRemediationCountChartOptions,
  makeMapChartOptions,
  makeObserveVisibility,
  makePriChartOptions,
  makeSanctionsAlertsCountChartOptions,
  makeSavedThirdPartyChartsOptions,
  makeTpCountriesChartOptions,
  convertListToObject,
  makeBarChartObj,
  makeBubbleChartObj,
  HTTP,
  makeAuthorizationHeader,
  hasPermission
} from "@/utils";
import { EventBus } from "@/event-bus";
import { isNil } from "lodash";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";

export default {
  name: "AnalyticsTabChartComponent",
  components: {
    TotalTPComponent,
    TpOverTimeComponent,
    OngoingMonitoringResults,
    TpOverCountriesComponent,
    PriResultsComponent,
    IddResultsComponent,
    IddPlusPreRemediationResultsComponent,
    IddPlusPostRemediationResultsComponent,
    EddResultsComponent,
    TpOverCountriesResultsComponent,
    StandardQuestionsComponent,
    DdqStatusComponent,
    ThirdPartyAnalysisModalTable,
    BaseChartWidget,
    PointValue,
    CustomAttributeChartWidget,
    BaseIcon
  },
  data() {
    return {
      themes,
      icons,
      shapes,
      mapData: "",
      savedThirdParties: false,
      savedThirdPartiesLoading: false,
      mapCharts: false,
      mapChartLoading: false,
      priActiveSanctionsChartLoading: false,
      iddPlusPostRemediationChart: false,
      iddPlusPostRemediationChartLoading: false,
      tpCountrySanctionsAlertsiddPlusPreRemediationChartLoading: false,
      tpCountrySanctionsAlertsiddPlusPreRemediationChart: false,
      priActiveSanctionsChart: false,
      riskScoreChartLoading: false,
      callTpStatApi: false,
      advanceFilterForms: false,
      resetFilter: false,
      ogmResults: false,
      ogmResultsLoading: false,
      iddActiveSanctionsChartLoading: false,
      iddActiveSanctionsChart: false,
      iddPlusPreRemediationActiveSanctionsChartLoading: false,
      iddPlusPreRemediationActiveSanctionsChart: false,
      eddChartLoading: false,
      eddChart: false,
      tpOverCountriesChartLoading: false,
      tpOverCountriesChart: false,
      totalNumberOfThirdParties: 0,
      totalNumberOfThirdPartiesAlertsAllTime: 0,
      totalNumberOfMonitoringAlertsAllTime: 0,
      totalNumberOfMonitoringAlertsLastMonth: 0,
      totalNumberOfThirdPartiesAlertsLastMonth: 0,
      totalNumberOfMonitoringAlertsThisMonth: 0,
      totalNumberOfThirdPartiesAlertsThisMonth: 0,
      questionnaireFormId: 349,
      ddqRiskScoreActiveChartList: [],
      ddqRiskScoreChartOptionList: [],
      ddqRiskScoreLoadingList: [],
      maxDate: new Date(),
      formsOptions: [],
      ddqStatChart: [],
      selectedWidgets: [],
      countTypeOptions: countTypeDashboardOptions,
      widgetsPerRow: {},
      columnsValue: null,
      viewDetail: null,
      selectedDDq: null,
      selectedDDqTitle: null,
      savedThirdPartyChartsOptions: makeSavedThirdPartyChartsOptions(),
      priChartOptions: makePriChartOptions(),
      mapChartOptions: makeMapChartOptions(),
      sanctionsAlertsCountChartOptions: makeSanctionsAlertsCountChartOptions(),
      iddPlusPreRemediationCountChartOptions:
        makeIddPlusPreRemediationCountChartOptions(),
      iddPlusPostRemediationCountChartOptions:
        makeIddPlusPostRemediationCountChartOptions(),
      eddResultCountChartOptions: makeEddResultCountChartOptions(),
      tpCountriesChartOptions: makeTpCountriesChartOptions(),
      form: this.makeFormOptions(),
      mapDrillDownOptions: makeDrillDownOptions(),
      chartsEnum,
      esgRatingChartTypes: this.makeEsgRatingChartTypes(),
      esgRatingChartStatuses: this.makeEsgRatingChartStatuses(),
      riskResilienceScoreChartData: null,
      esgResilienceStrengthChartData: null,
      esgRiskSeverityChartData: null,
      esgChartsEnum,
      querySpecificRatingParamsMap: {
        Social: "overall_social_rating",
        Rating: "overall_rating",
        Governance: "overall_governance_rating",
        Environment: "overall_environment_rating",
        Resilience: "resilience_strength",
        Risk: "overall_risk_severity"
      },
      customAttributesWidgetData: {}
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId,
      userId: (state) => state.user.userId,
      platformKnowledgeCentreLink: (state) =>
        state.platformSettings.platformKnowledgeCentreLink,
      customAttributesList: (state) =>
        state.customAttributes.customAttributesList,
      customAttributeListIsLoading: (state) =>
        state.customAttributes.customAttributeListIsLoading
    }),
    ...mapGetters({
      permittedLandingPage: getterName.USER.PERMITTED_LANDING_PAGE
    }),
    getPageTitle() {
      return this.viewDetail?.title || "";
    },
    hasDeleteViewPermission() {
      return hasPermission({
        key: featurePermissionsKeys.CREATE_AND_REMOVE_USER_VIEWS
      });
    },
    hasEditUserViewPermission() {
      return hasPermission({
        key: featurePermissionsKeys.EDIT_USER_VIEW,
        contextId: parseInt(this.$route.params.id),
        contextKey: userPermissionKeysDTO.USER_VIEW_ID
      });
    },
    hasSettingsDropdown() {
      return this.hasEditUserViewPermission || this.hasDeleteViewPermission;
    }
  },
  created() {
    this.fetchCustomAttributesList({
      sort: `tp_sequence|asc`,
      comp_id: this.companyId
    });
    EventBus.on("refreshDashboardView", () => {
      this.setUserViewData();
    });
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.setUserViewData();
      }
    }
  },
  async mounted() {
    await this.$router.isReady();
    this.setUserViewData();
  },
  methods: {
    ...mapMutations({
      setCompanyId: mutationName.COMPANY.SET_COMPANY_ID
    }),
    ...mapActions({
      fetchAdditionalVouchers: actionName.COMPANY.FETCH_ADDITIONAL_VOUCHERS,
      fetchCustomAttributesList:
        actionName.CUSTOM_ATTRIBUTES.FETCH_CUSTOM_ATTRIBUTES_LIST
    }),
    isNil,
    hideModal() {
      this.mapDrillDownOptions = makeDrillDownOptions();
    },
    getQueryParamExcludeSbos(isExcludeScreenedBeneficialOwners = true) {
      return {
        exclude_sbos: isExcludeScreenedBeneficialOwners
      };
    },
    makeFormOptions() {
      return {
        dateSentFrom: null,
        dateSentTo: null,
        countType: countTypeMostRecentOption
      };
    },
    makeAddRiskScoreChartOptionList({ label, id }) {
      let index = this.getDdqRiskScoreChartOptionIndex(id);
      const isNotInDdqRiskScoreChartOptionList = index === -1;
      if (isNotInDdqRiskScoreChartOptionList) {
        this.ddqRiskScoreChartOptionList.push({
          id,
          ddqRiskScoreChartOptions: {
            ...makeDdqRiskScoreChartOptions(),
            title: {
              text: label
            }
          }
        });
        index++;
      }
      return this.ddqRiskScoreChartOptionList[index].ddqRiskScoreChartOptions;
    },
    isChart(widget) {
      return (
        widget.key.includes(chartsEnum.NOT_STARTED) ||
        widget.key.includes(chartsEnum.IN_PROGRESS_PAUSE_REMINDERS) ||
        widget.key.includes(chartsEnum.IN_PROGRESS) ||
        widget.key.includes(chartsEnum.COMPLETED) ||
        widget.key.includes(chartsEnum.CANCELLED) ||
        widget.key.includes(chartsEnum.SELF_EVIDENCE) ||
        widget.key.includes(chartsEnum.DECLINED_REFUSED)
      );
    },
    isEsgRatingChart(key) {
      return (
        key === chartsEnum.ESG_OVERALL_RATING ||
        key === chartsEnum.GOVERNANCE_PILLAR_OVERALL_RATING ||
        key === chartsEnum.SOCIAL_PILLAR_OVERALL_RATING ||
        key === chartsEnum.ENVIRONMENT_PILLAR_OVERALL_RATING
      );
    },
    isEsgRiskSeverityChart(key) {
      return key === chartsEnum.OVERALL_RISK_SEVERITY;
    },
    isCustomAttributeWidgetChart(key) {
      return key.includes(chartsEnum.CUSTOM_ATTRIBUTE);
    },
    isEsgRatingChartStatus(key) {
      return (
        key === chartsEnum.ESG_COMPLETED ||
        key === chartsEnum.ESG_QUEUED ||
        key === chartsEnum.ESG_IN_REMEDIATION
      );
    },
    isEsgResilienceStrengthChart(key) {
      return key === chartsEnum.RESILIENCE_OVERALL_STRENGTH;
    },
    setUserViewData() {
      this.customAttributesWidgetData = {};
      this.form = this.makeFormOptions();
      this.resetFilter = false;
      this.advanceFilterForms = false;
      this.callTpStatApi = false;
      this.columnsValue = null;
      this.selectedWidgets = [];
      this.widgetsPerRow = {};
      this.ddqRiskScoreChartOptionList = [];
      this.savedThirdPartyChartsOptions = makeSavedThirdPartyChartsOptions();
      this.priChartOptions = makePriChartOptions();
      this.mapChartOptions = makeMapChartOptions();
      this.sanctionsAlertsCountChartOptions =
        makeSanctionsAlertsCountChartOptions();
      this.iddPlusPreRemediationCountChartOptions =
        makeIddPlusPreRemediationCountChartOptions();
      this.iddPlusPostRemediationCountChartOptions =
        makeIddPlusPostRemediationCountChartOptions();
      this.eddResultCountChartOptions = makeEddResultCountChartOptions();
      this.tpCountriesChartOptions = makeTpCountriesChartOptions();
      this.esgRatingChartTypes = this.makeEsgRatingChartTypes();
      this.esgResilienceStrengthChartData = null;
      this.esgRiskSeverityChartData = null;
      this.savedThirdParties = false;
      this.savedThirdPartiesLoading = false;
      this.mapCharts = false;
      this.mapChartLoading = false;
      this.priActiveSanctionsChart = false;
      this.ddqRiskScoreActiveChartList = [];
      this.priActiveSanctionsChartLoading = false;
      this.ddqRiskScoreLoadingList = [];
      this.ogmResults = false;
      this.ogmResultsLoading = false;
      this.iddActiveSanctionsChartLoading = false;
      this.iddActiveSanctionsChart = false;
      this.iddPlusPreRemediationActiveSanctionsChartLoading = false;
      this.iddPlusPreRemediationActiveSanctionsChart = false;
      this.iddPlusPostRemediationChartLoading = false;
      this.iddPlusPostRemediationChart = false;
      this.eddChartLoading = false;
      this.eddChart = false;
      this.tpOverCountriesChartLoading = false;
      this.tpOverCountriesChart = false;
      this.ddqStatChart = [];
      const filteredFormData = JSON.parse(
        localStorage.getItem("dashboard_view")
      );
      if (filteredFormData) {
        this.form = filteredFormData;
        this.resetFilter = true;
      }
      this.fetchPlatformView();
    },
    async fetchPlatformView() {
      try {
        const { data = {} } = await viewsService.fetchPlatformView({
          userViewId: this.$route.params.id,
          companyId: this.companyId
        });
        this.fetchPlatformViewSuccess(data);
      } catch {
        this.$router.push(urls.PAGE_NOT_FOUND);
      }
    },
    fetchPlatformViewSuccess(data = {}) {
      this.viewDetail = data;
      const { widgets = {} } = data.configuration;
      this.columnsValue = widgets.columnsValue;
      this.selectedWidgets = this.setSelectedWidgets(widgets);
      let availableColumnsInRow = widgets.columnsValue;
      let widgetPerRowObjKey = this.makeRowKeyName();
      this.selectedWidgets.forEach((widget) => {
        if (widget.colspan > availableColumnsInRow) {
          availableColumnsInRow = this.columnsValue;
          widgetPerRowObjKey = this.makeRowKeyName();
        }
        if (this.widgetsPerRow[widgetPerRowObjKey]) {
          this.widgetsPerRow[widgetPerRowObjKey].push(widget);
        } else {
          this.widgetsPerRow[widgetPerRowObjKey] = [widget];
        }
        availableColumnsInRow -= widget.colspan;
        if (this.isChart(widget)) {
          this.ddqStatChart[widget.index] = false;
        }
      });
    },
    makeRowKeyName() {
      return `row${Object.keys(this.widgetsPerRow).length + 1}`;
    },
    setSelectedWidgets(widgets) {
      return widgets.selectedWidgets.map((widget) => {
        if (
          widget.key.includes(chartsEnum.STANDARD_QUESTION) &&
          !widget?.chartType
        ) {
          return { ...widget, chartType: chartsStandardQuestionChartType.BAR };
        }

        return widget;
      });
    },
    showDeleteModal() {
      this.$refs["modal-delete"].show();
    },
    openEditViewModal() {
      EventBus.emit("addNewViewModal", {
        viewName: this.viewDetail?.title,
        viewType: this.viewDetail?.view_type,
        id: this.viewDetail?.id,
        columnsValue: this.columnsValue,
        selectedWidgets: this.selectedWidgets,
        permissions: this.viewDetail?.permissions
      });
    },
    updatePlatformView() {
      const formData = {
        company_id: this.companyId,
        configuration: {
          visibleFields: [],
          filters: [],
          widgets: {
            columnsValue: this.columnsValue,
            selectedWidgets: this.selectedWidgets
          }
        },
        title: this.viewDetail.title,
        created_by_id: this.userId,
        view_type: this.viewDetail.view_type,
        description: null
      };

      HTTP(
        "put",
        endpoints.PLATFORM_VIEW_UPDATE(this.viewDetail.id),
        formData,
        makeAuthorizationHeader()
      );
    },
    async deletePlatformView() {
      try {
        const result = await viewsService.deletePlatformView(
          this.$route.params.id,
          this.companyId
        );
        if (result?.data?.success === httpStatuses.SUCCESS) {
          this.$bvToast.toast(result.data.message, {
            title: "Done",
            variant: "success",
            autoHideDelay: 2000,
            appendToast: false
          });
          await this.fetchAdditionalVouchers();
          this.$router.push(this.permittedLandingPage);
        } else if (result?.data?.success === httpStatuses.FAIL) {
          this.$bvToast.toast(result.data.message, {
            title: "Error",
            variant: "danger",
            autoHideDelay: 2000,
            appendToast: false
          });
        }
        return result;
      } catch (error) {
        return error;
      }
    },
    is12Columns(widget) {
      return !!(
        (this.columnsValue === 1 && widget?.colspan === 1) ||
        (this.columnsValue === 2 && widget?.colspan === 2) ||
        (this.columnsValue === 3 && widget?.colspan === 3) ||
        (this.columnsValue === 4 && widget?.colspan === 4)
      );
    },
    is9Columns(widget) {
      return !!(this.columnsValue === 4 && widget?.colspan === 3);
    },
    is8Columns(widget) {
      return !!(this.columnsValue === 3 && widget?.colspan === 2);
    },
    is6Columns(widget) {
      return !!(
        (this.columnsValue === 2 && widget?.colspan === 1) ||
        (this.columnsValue === 4 && widget?.colspan === 2)
      );
    },
    is4Columns(widget) {
      return !!(this.columnsValue === 3 && widget?.colspan === 1);
    },
    is3Columns(widget) {
      return !!(this.columnsValue === 4 && widget?.colspan === 1);
    },
    calculateColumns(widget) {
      if (this.is12Columns(widget)) {
        return 12;
      } else if (this.is9Columns(widget)) {
        return 9;
      } else if (this.is8Columns(widget)) {
        return 8;
      } else if (this.is6Columns(widget)) {
        return 6;
      } else if (this.is4Columns(widget)) {
        return 4;
      } else if (this.is3Columns(widget)) {
        return 3;
      }
    },
    async fetchPriChartData() {
      this.priActiveSanctionsChartLoading = true;
      try {
        const result = await chartsService.fetchPriChartData(this.companyId);
        if (isValidResponse(result)) {
          const self = this;
          this.priChartOptions.series.push({
            name: "",
            point: {
              events: {
                click() {
                  self.mapDrillDownOptions = makeDrillDownOptions({
                    tableTitle: pieChartNameMap[this.name],
                    tableId: queryObjectKeys.PERCEIVED_RISK_INDICATOR,
                    modalTitle: "Perceived Risk Indicator Results",
                    querySpecificData: {
                      sanctionsEnforcementsType: pieChartNameMap[this.name]
                    }
                  });
                }
              }
            },
            data: [
              {
                name: pieChartName.RED,
                y: result?.data?.data?.highPri || 0
              },
              {
                name: pieChartName.AMBER,
                y: result?.data?.data?.mediumPri || 0
              },
              {
                name: pieChartName.GREEN,
                y: result?.data?.data?.lowPri || 0
              },
              {
                name: pieChartName.NO_PRI,
                y: result?.data?.data?.noPri || 0
              }
            ]
          });
          this.priActiveSanctionsChart = true;
          this.priActiveSanctionsChartLoading = false;
        } else {
          this.priChartOptions.series.push({
            data: []
          });
        }
      } catch (err) {
        return [err];
      } finally {
        this.priActiveSanctionsChartLoading = false;
      }
    },
    getDdqRiskScoreChartOptionIndex(id) {
      return this.ddqRiskScoreChartOptionList.findIndex(
        (ddqRiskScoreChartOptions) => ddqRiskScoreChartOptions.id === id
      );
    },
    extendDdqRiskScoreChartOptions({ data, status }, id, formId) {
      const index = this.getDdqRiskScoreChartOptionIndex(id);
      const self = this;

      if (isValidResponse({ data, status })) {
        this.ddqRiskScoreChartOptionList[
          index
        ].ddqRiskScoreChartOptions.series.push({
          data: [
            {
              name: ddqRiskScoreName.HIGH,
              y: data?.data?.high || 0
            },
            {
              name: ddqRiskScoreName.MODERATE,
              y: data?.data?.moderate || 0
            },
            {
              name: ddqRiskScoreName.LOW,
              y: data?.data?.low || 0
            }
          ],
          point: {
            events: {
              click() {
                self.mapDrillDownOptions = makeDrillDownOptions({
                  tableTitle: this.name,
                  tableId: queryObjectKeys.MSQ_RISK_SCORE_TP_LIST,
                  modalTitle: "Modern Slavery Questionnaire - Risk Score",
                  querySpecificData: {
                    form_id: formId,
                    risk_score: this.name
                  }
                });
              }
            }
          }
        });
      } else {
        this.ddqRiskScoreChartOptionList[index].series.push({
          data: []
        });
      }
    },
    async fetchDdqRiskScoreData({ formId, id }) {
      try {
        this.ddqRiskScoreActiveChartList.push(id);
        this.ddqRiskScoreLoadingList.push(id);
        const result = await chartsService.fetchDdqRiskScoreData(
          formId,
          this.companyId
        );
        this.extendDdqRiskScoreChartOptions(result, id, formId);
      } catch (err) {
        return [err];
      } finally {
        this.ddqRiskScoreLoadingList = this.ddqRiskScoreLoadingList.filter(
          (ddqRiskScoreLoadingElement) => ddqRiskScoreLoadingElement !== id
        );
      }
    },
    async fetchWorldMapChartsData() {
      this.mapChartLoading = true;
      try {
        const result = await chartsService.fetchWorldMapChartsData(
          this.companyId,
          this.getQueryParamExcludeSbos()
        );
        this.mapCharts = true;
        if (isValidResponse(result)) {
          const self = this;
          this.mapChartOptions.series.push(makeChartCountryObj(), {
            type: chartTypes.MAP_BUBBLE,
            name: "Third Parties",
            point: {
              events: {
                click() {
                  self.mapDrillDownOptions = makeDrillDownOptions({
                    tableTitle: this.name,
                    tableId: queryObjectKeys.THIRD_PARTY_DDQ_QUESTIONS,
                    modalTitle: "Third Parties By Countries",
                    querySpecificData: {
                      thirdParties: this.thirdParties
                    }
                  });
                }
              }
            },
            color: "#6BDC99",
            borderColor: "black",
            borderWidth: 0.2,
            joinBy: ["iso-a2", "code"],
            data: result.data.data,
            minSize: 4,
            maxSize: "12%",
            tooltip: {
              pointFormat: "{point.country}: {point.z}"
            }
          });
        } else {
          this.mapChartOptions.series.push(makeChartCountryObj(), {
            type: chartTypes.MAP_BUBBLE,
            name: "Third Parties",
            color: "#6BDC99",
            joinBy: ["iso-a2", "code"],
            data: [],
            minSize: 4,
            maxSize: "12%"
          });
        }
      } catch (err) {
        return [err];
      } finally {
        this.mapChartLoading = false;
      }
    },
    makeEsgRatingChartTypeOption({ ratings }, key, title) {
      const self = this;
      const barChartDataObj = convertListToObject("rating", "count", ratings);

      this.esgRatingChartTypes[key] = {
        ...makeBarChartObj(),
        colors: [
          colors.appleGreen,
          colors.robinEggBlue,
          colors.teaGreen,
          colors.shandy,
          colors.metallicYellow,
          colors.spanishRed,
          colors.bloodRed
        ],
        xAxis: {
          categories: Object.keys(barChartDataObj),
          labels: {
            style: {
              color: colors.black
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: "Number of Third Parties"
          },
          gridLineWidth: 0,
          lineWidth: 1,
          endOnTick: true
        },
        tooltip: {
          pointFormat: "<b>{point.category}:{point.y}</b>"
        },
        series: [
          {
            name: "Overall ESG Rating",
            data: Object.values(barChartDataObj),
            point: {
              events: {
                click({ point }) {
                  self.mapDrillDownOptions = makeDrillDownOptions({
                    tableTitle: this.name,
                    tableId: queryObjectKeys.ESG_THIRD_PARTY,
                    useNewQueryParameter: true,
                    modalTitle: title,
                    querySpecificData: {
                      [self.querySpecificRatingParamsMap[
                        chartsEnumId[key] === "" ? "Rating" : chartsEnumId[key]
                      ]]: `["${point.category}"]`
                    }
                  });
                }
              }
            }
          }
        ]
      };
    },
    makeEsgResilienceStrengthChartTypeOption({ strengths }, key, title) {
      const self = this;
      const barChartDataObj = convertListToObject(
        "strength",
        "count",
        strengths
      );
      this.esgResilienceStrengthChartData = {
        ...makeBarChartObj(),
        colors: [
          colors.black,
          colors.spanishRed,
          colors.metallicYellow,
          colors.teaGreen
        ],
        xAxis: {
          categories: Object.keys(barChartDataObj),
          labels: {
            style: {
              color: colors.black
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: "Number of Third Parties"
          },
          gridLineWidth: 0,
          lineWidth: 1,
          endOnTick: true
        },
        tooltip: {
          pointFormat: "<b>{point.category}:{point.y}</b>"
        },
        series: [
          {
            name: title,
            data: Object.values(barChartDataObj),
            point: {
              events: {
                click({ point }) {
                  self.mapDrillDownOptions = makeDrillDownOptions({
                    tableTitle: this.name,
                    tableId: queryObjectKeys.ESG_THIRD_PARTY,
                    useNewQueryParameter: true,
                    modalTitle: title,
                    querySpecificData: {
                      [self.querySpecificRatingParamsMap[
                        chartsEnumId[key]
                      ]]: `["${point.category}"]`
                    }
                  });
                }
              }
            }
          }
        ]
      };
    },
    makeEsgRiskSeverityChartTypeOption({ severities }, key, title) {
      const self = this;
      const barChartDataObj = convertListToObject(
        "severity",
        "count",
        severities
      );
      this.esgRiskSeverityChartData = {
        ...makeBarChartObj(),
        colors: [
          colors.black,
          colors.spanishRed,
          colors.shandy,
          colors.black,
          colors.teaGreen
        ],
        xAxis: {
          categories: Object.keys(barChartDataObj),
          labels: {
            style: {
              color: colors.black
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: "Number of Third Parties"
          },
          gridLineWidth: 0,
          lineWidth: 1,
          endOnTick: true
        },
        tooltip: {
          pointFormat: "<b>{point.category}:{point.y}</b>"
        },
        series: [
          {
            name: title,
            data: Object.values(barChartDataObj),
            point: {
              events: {
                click({ point }) {
                  self.mapDrillDownOptions = makeDrillDownOptions({
                    tableTitle: this.name,
                    tableId: queryObjectKeys.ESG_THIRD_PARTY,
                    useNewQueryParameter: true,
                    modalTitle: title,
                    querySpecificData: {
                      [self.querySpecificRatingParamsMap[
                        chartsEnumId[key]
                      ]]: `["${point.category}"]`
                    }
                  });
                }
              }
            }
          }
        ]
      };
    },
    makeRiskResilienceScoreChartTypeOption({ scores }, title) {
      this.riskResilienceScoreChartData = {
        ...makeBubbleChartObj(),
        xAxis: [
          {
            min: -1,
            max: 10,
            title: {
              align: "low",
              text: "Low Resilience",
              x: 30
            },
            tickLength: 0,
            tickInterval: 0.1,
            tickPositions: [0],
            startOnTick: false,
            endOnTick: false,
            gridLineWidth: 1,
            gridLineDashStyle: "dash",
            gridLineColor: "#999999",
            labels: {
              enabled: false
            }
          },
          {
            linkedTo: 0,
            title: {
              align: "high",
              text: "High Resilience",
              x: -30
            },
            offset: 0,
            tickLength: 0,
            tickInterval: 0.1,
            tickPositions: [9],
            startOnTick: false,
            endOnTick: false,
            gridLineWidth: 1,
            gridLineDashStyle: "dash",
            gridLineColor: "#999999",
            labels: {
              enabled: false
            }
          }
        ],
        yAxis: [
          {
            min: -1.5,
            max: 10.5,
            lineWidth: 1,
            tickWidth: 1,
            title: {
              align: "low",
              text: "High Risk",
              rotation: 0,
              offset: 63,
              y: -42
            },
            tickLength: 0,
            tickInterval: 0.1,
            tickPositions: [0],
            startOnTick: false,
            endOnTick: false,
            gridLineDashStyle: "dash",
            gridLineColor: "#999999",
            labels: {
              enabled: false
            }
          },
          {
            linkedTo: 0,
            title: {
              align: "high",
              text: "Low Risk",
              rotation: 0,
              y: 47
            },
            offset: 0,
            tickLength: 0,
            tickInterval: 0.1,
            tickPositions: [9],
            startOnTick: false,
            endOnTick: false,
            gridLineDashStyle: "dash",
            gridLineColor: "#999999",
            labels: {
              enabled: false
            }
          }
        ],
        series: [
          {
            name: title,
            data:
              scores?.reduce((acc, { risk, resilience, count }) => {
                acc.push([parseFloat(resilience), parseFloat(risk), count]);
                return acc;
              }, []) || [],
            tooltip: {
              pointFormat: "Third Parties: {point.z}"
            },
            point: {
              events: {
                click: ({ point }) => {
                  this.mapDrillDownOptions = makeDrillDownOptions({
                    tableTitle: this.name,
                    tableId: queryObjectKeys.ESG_THIRD_PARTY,
                    useNewQueryParameter: true,
                    modalTitle: title,
                    querySpecificData: {
                      overall_risk_score: `[${point.y}]`,
                      overall_resilience_score: `[${point.x}]`
                    }
                  });
                }
              }
            }
          }
        ]
      };
    },
    async fetchEsgRatingsData(key, title) {
      try {
        const { data = {} } = await esgService.fetchEsgRatings(this.companyId, {
          ...(chartsEnumId[key] && { component: chartsEnumId[key] })
        });
        this.makeEsgRatingChartTypeOption(data, key, title);
      } catch (err) {
        this.esgRatingChartTypes[key] = {};
        return err;
      }
    },
    async fetchScoreByRiskAndResilienceData(title) {
      try {
        const { data = {} } = await esgService.fetchScoreByRiskAndResilience(
          this.companyId
        );
        this.makeRiskResilienceScoreChartTypeOption(data, title);
      } catch (err) {
        this.riskResilienceScoreChartData = {};
        return err;
      }
    },
    async fetchEsgResilienceStrengthData(key, title) {
      try {
        const { data = {} } = await esgService.fetchEsgResilienceStrength(
          this.companyId
        );
        this.makeEsgResilienceStrengthChartTypeOption(data, key, title);
      } catch (err) {
        this.esgResilienceStrengthChartData = {};
        return err;
      }
    },
    async fetchEsgRiskSeverityData(key, title) {
      try {
        const { data = {} } = await esgService.fetchEsgRiskSeverity(
          this.companyId
        );
        this.makeEsgRiskSeverityChartTypeOption(data, key, title);
      } catch (err) {
        this.esgRiskSeverityChartData = {};
        return err;
      }
    },
    makeEsgRatingChartTypes() {
      return {
        [chartsEnum.ESG_OVERALL_RATING]: undefined,
        [chartsEnum.GOVERNANCE_PILLAR_OVERALL_RATING]: undefined,
        [chartsEnum.SOCIAL_PILLAR_OVERALL_RATING]: undefined,
        [chartsEnum.ENVIRONMENT_PILLAR_OVERALL_RATING]: undefined
      };
    },
    makeEsgRatingChartStatuses(esgRatingChartStatus) {
      const esgRatingChartStatusesValues = Object.values(esgChartsEnum);
      let total;
      let statuses;

      if (esgRatingChartStatus) {
        total = esgRatingChartStatus.total;
        statuses = esgRatingChartStatus.statuses;
      }

      return Object.values(chartsStatusType).reduce(
        (acc, esgChartsStatusType, index) => {
          if (isNil(total) && isNil(statuses)) {
            acc[esgRatingChartStatusesValues[index]] = esgRatingChartStatus;
          } else {
            acc[esgRatingChartStatusesValues[index]] = {
              total,
              ...statuses.find(({ status }) => status === esgChartsStatusType)
            };
          }
          return acc;
        },
        {}
      );
    },
    async fetchEsgRatingChartStatusData() {
      try {
        const { data = {} } = await esgService.fetchEsgStatusData(
          this.companyId
        );
        this.esgRatingChartStatuses = this.makeEsgRatingChartStatuses(data);
      } catch (error) {
        this.esgRatingChartStatuses = this.makeEsgRatingChartStatuses({});
        return error;
      }
    },
    tpCountApiCall(isVisible) {
      if (isVisible && !this.savedThirdParties) {
        this.fetchTpStatData();
      }
    },
    async ogmResultsApiCall(isVisible) {
      if (isVisible && !this.ogmResults) {
        this.ogmResultsLoading = true;
        await this.fetchMonitoringAlertsCountThisMonth();
        await this.fetchMonitoringAlertsCountLastMonth();
        await this.fetchMonitoringAlertsCountAllTime();
        this.ogmResultsLoading = false;
      }
    },
    mapChartApiCall(isVisible) {
      if (isVisible && !this.mapCharts) {
        this.fetchWorldMapChartsData();
      }
    },
    overallEsgRatingsApiCall(isVisible, { key, label }) {
      if (
        isVisible &&
        this.isEsgRatingChart(key) &&
        isNil(this.esgRatingChartTypes[key])
      ) {
        this.fetchEsgRatingsData(key, label);
      }
    },
    scoreByRiskAndResilienceApiCall(isVisible, { label }) {
      if (isVisible && isNil(this.riskResilienceScoreChartData)) {
        this.fetchScoreByRiskAndResilienceData(label);
      }
    },
    esgRatingChartStatusApiCall(isVisible, { key }) {
      if (
        isVisible &&
        this.isEsgRatingChartStatus(key) &&
        isNil(this.esgRatingChartStatuses[key])
      ) {
        this.fetchEsgRatingChartStatusData();
      }
    },
    overallEsgResilienceStrengthApiCall(isVisible, { key, label }) {
      if (
        isVisible &&
        this.isEsgResilienceStrengthChart(key) &&
        isNil(this.esgResilienceStrengthChartData)
      ) {
        this.fetchEsgResilienceStrengthData(key, label);
      }
    },
    priChartApiCall(isVisible) {
      if (isVisible && !this.priActiveSanctionsChart) {
        this.fetchPriChartData();
      }
    },
    overallEsgRiskSeverityApiCall(isVisible, { key, label }) {
      if (
        isVisible &&
        this.isEsgRiskSeverityChart(key) &&
        isNil(this.esgRiskSeverityChartData)
      ) {
        this.fetchEsgRiskSeverityData(key, label);
      }
    },
    customAttributeDataApiCall(isVisible, { id = "" } = {}) {
      if (isVisible && !this.customAttributesWidgetData[id]) {
        this.customAttributesWidgetData[id] =
          this.makeDefaultCustomAttributeDataStructure();

        this.fetchCustomAttributeData(this.companyId, id);
      }
    },
    makeDefaultCustomAttributeDataStructure() {
      return {
        [customAttributesWidgetKeys.IS_LOADING]: false,
        [customAttributesWidgetKeys.DATA]: null
      };
    },
    isCustomAttributeDataLoading(customAttributeId) {
      return (
        !!this.customAttributesWidgetData?.[customAttributeId]?.isLoading ||
        this.customAttributeListIsLoading
      );
    },
    ddqRiskScoreApiCall(isVisible, widget) {
      if (isVisible && !this.ddqRiskScoreActiveChartList.includes(widget.id)) {
        this.fetchDdqRiskScoreData(widget);
      }
    },
    iddActiveSanctionsChartApiCall(isVisible) {
      if (isVisible && !this.iddActiveSanctionsChart) {
        this.fetchSanctionsAlertsCount();
      }
    },
    iddPlusPreRemediationChartApiCall(isVisible) {
      if (isVisible && !this.iddPlusPreRemediationActiveSanctionsChart) {
        this.fetchIddPlusPreRemediationCount();
      }
    },
    iddPlusPostRemediationChartApiCall(isVisible) {
      if (isVisible && !this.iddPlusPostRemediationChart) {
        this.fetchIddPlusPostRemediationCount();
      }
    },
    eddChartApiCall(isVisible) {
      if (isVisible && !this.eddChart) {
        this.fetchEddResultCount();
      }
    },
    tpOverCountriesApiCall(isVisible) {
      if (isVisible && !this.tpOverCountriesChart) {
        this.fetchTpCountries();
      }
    },
    async fetchTpStatData() {
      this.callTpStatApi = true;
      this.totalNumberOfThirdParties = 0;
      this.savedThirdPartiesLoading = true;
      this.savedThirdParties = true;
      try {
        this.savedThirdPartiesLoading = true;
        const result = await chartsService.fetchTpStatData(
          this.companyId,
          this.getQueryParamExcludeSbos()
        );
        if (isValidResponse(result)) {
          const monthData = [];
          const thirdPartyData = [];
          let thirdPartyYear = null;
          this.totalNumberOfThirdParties = result.data.totalTp;
          result.data.totalTpPerMonth.map((value) => {
            thirdPartyData.push(value.data);
            monthData.push(value.month);
            thirdPartyYear = value.year;
          });
          this.savedThirdPartyChartsOptions.xAxis.categories = monthData;
          this.savedThirdPartyChartsOptions.series.push({
            name: `Year ${thirdPartyYear}`,
            data: thirdPartyData
          });
        } else {
          this.totalNumberOfThirdParties = 0;
          this.savedThirdPartyChartsOptions.series.push({
            name: "Year",
            data: []
          });
        }
      } catch (error) {
        return [error];
      } finally {
        this.savedThirdPartiesLoading = false;
      }
    },
    async fetchMonitoringAlertsCountThisMonth() {
      try {
        const result = await chartsService.fetchMonitoringAlerts(
          {
            startDate: undefined,
            endDate: undefined,
            companyId: this.companyId
          },
          this.getQueryParamExcludeSbos(false)
        );
        if (isValidResponse(result)) {
          this.totalNumberOfMonitoringAlertsThisMonth = result.data.omrTotal;
          this.totalNumberOfThirdPartiesAlertsThisMonth = result.data.tpAlerts;
        }
      } catch (err) {
        return [err];
      }
    },
    async fetchMonitoringAlertsCountLastMonth() {
      const currentDate = new Date();
      const lastMonthEndDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const lastMonthStartDate = new Date(
        lastMonthEndDate.getFullYear(),
        lastMonthEndDate.getMonth(),
        -29
      );

      try {
        const result = await chartsService.fetchMonitoringAlerts(
          {
            startDate: lastMonthStartDate,
            endDate: lastMonthEndDate,
            companyId: this.companyId
          },
          this.getQueryParamExcludeSbos(false)
        );
        if (isValidResponse(result)) {
          this.totalNumberOfMonitoringAlertsLastMonth = result.data.omrTotal;
          this.totalNumberOfThirdPartiesAlertsLastMonth = result.data.tpAlerts;
        }
      } catch (err) {
        return [err];
      }
    },
    async fetchMonitoringAlertsCountAllTime() {
      try {
        const result = await chartsService.fetchMonitoringAlerts(
          {
            companyId: this.companyId
          },
          this.getQueryParamExcludeSbos(false)
        );
        if (isValidResponse(result)) {
          this.totalNumberOfMonitoringAlertsAllTime = result.data.omrTotal;
          this.totalNumberOfThirdPartiesAlertsAllTime = result.data.tpAlerts;
          this.ogmResults = true;
        }
      } catch (err) {
        return [err];
      }
    },
    async fetchSanctionsAlertsCount() {
      this.iddActiveSanctionsChartLoading = true;
      try {
        const result = await chartsService.fetchSanctionsAlertsCount(
          this.companyId,
          this.getQueryParamExcludeSbos(false)
        );
        if (isValidResponse(result)) {
          this.sanctionsAlertsCountChartOptions.series.push({
            point: {
              events: {
                click() {
                  EventBus.emit("onShowSanctionsEnforcementsTable", this.name);
                }
              }
            },
            data: [
              {
                name: sanctionsAlertsName.NO_SE_ALERT,
                y: result?.data?.noSanctionsAlerts || 0
              },
              {
                name: sanctionsAlertsName.SE_ALERT,
                y: result?.data?.sanctionsAlerts || 0
              },
              {
                name: sanctionsAlertsName.NO_IDD,
                y: result?.data?.no_idd || 0
              }
            ]
          });
          this.iddActiveSanctionsChart = true;
        } else {
          this.sanctionsAlertsCountChartOptions.series.push({
            data: []
          });
        }
      } catch (error) {
        return [error];
      } finally {
        this.iddActiveSanctionsChartLoading = false;
      }
    },
    async fetchIddPlusPreRemediationCount() {
      try {
        this.iddPlusPreRemediationActiveSanctionsChartLoading = true;
        const result = await chartsService.fetchIddPlusPreRemediationCount(
          this.companyId
        );
        if (isValidResponse(result)) {
          const self = this;
          this.iddPlusPreRemediationCountChartOptions.series.push({
            name: "",
            point: {
              events: {
                click() {
                  self.mapDrillDownOptions = makeDrillDownOptions({
                    tableTitle: this.name,
                    tableId: queryObjectKeys.IDD_PRE_REMEDIATION,
                    modalTitle:
                      "Instant Due Diligence PLUS Results Pre-Remediation",
                    querySpecificData: {
                      iddPlusPreRemediationType: this.name
                    }
                  });
                }
              }
            },
            data: [
              {
                name: iddPlusPreRemediationName.FALSE_POSITIVE,
                y: result.data.falsePositive_green || 0
              },
              {
                name: iddPlusPreRemediationName.NO_IDD_ALERTS,
                y: result.data.noidd_noordered || 0
              },
              {
                name: iddPlusPreRemediationName.ALERTS,
                y: result.data.alerts_red || 0
              }
            ]
          });
          this.iddPlusPreRemediationActiveSanctionsChart = true;
        } else {
          this.iddPlusPreRemediationCountChartOptions.series.push({
            data: []
          });
        }
      } catch (error) {
        return [error];
      } finally {
        this.iddPlusPreRemediationActiveSanctionsChartLoading = false;
      }
    },
    async fetchIddPlusPostRemediationCount() {
      try {
        this.iddPlusPostRemediationChartLoading = true;
        const result = await chartsService.fetchIddPlusPostRemediationCount(
          this.companyId
        );
        if (isValidResponse(result)) {
          const self = this;
          this.iddPlusPostRemediationCountChartOptions.series.push({
            name: "",
            point: {
              events: {
                click() {
                  self.mapDrillDownOptions = makeDrillDownOptions({
                    tableTitle: this.name,
                    tableId: queryObjectKeys.IDD_POST_REMEDIATION,
                    modalTitle:
                      "Instant Due Diligence PLUS Results Post-Remediation",
                    querySpecificData: {
                      iddPlusPostRemediationType: this.name
                    }
                  });
                }
              }
            },
            data: [
              {
                name: iddPlusPostRemediationName.NO_IDD_ORDERED,
                y: result?.data?.noidd_noordered || 0
              },
              {
                name: iddPlusPostRemediationName.REMEDIATION_GREEN,
                y: result?.data?.remediatedGreen || 0
              },
              {
                name: iddPlusPostRemediationName.REMEDIATION_IN_PROGRESS,
                y: result?.data?.remediatedInProcess || 0
              },
              {
                name: iddPlusPostRemediationName.REMEDIATION_RED,
                y: result?.data?.remediatedRed || 0
              },
              {
                name: iddPlusPostRemediationName.REMEDIATION_NOT_ORDERED,
                y: result?.data?.remediationNotOrderd || 0
              }
            ]
          });
          this.iddPlusPostRemediationChart = true;
        } else {
          this.iddPlusPostRemediationCountChartOptions.series.push({
            data: []
          });
        }
      } catch (error) {
        return [error];
      } finally {
        this.iddPlusPostRemediationChartLoading = false;
      }
    },
    async fetchEddResultCount() {
      try {
        this.eddChartLoading = true;
        const result = await chartsService.fetchEddResultCount(this.companyId);
        if (isValidResponse(result)) {
          const self = this;
          this.eddResultCountChartOptions.series.push({
            name: "",
            point: {
              events: {
                click() {
                  self.mapDrillDownOptions = makeDrillDownOptions({
                    tableTitle: this.name,
                    tableId: queryObjectKeys.ENHANCE_DUE_DILIGENCE,
                    modalTitle: "Enhanced Due Diligence Results",
                    querySpecificData: {
                      enhancedDueDiligenceType: this.name
                    }
                  });
                }
              }
            },
            data: [
              {
                name: eddResultName.EDD_GREEN,
                y: result.data.eddGreen || 0
              },
              {
                name: eddResultName.EDD_NOT_ORDERED,
                y: result.data.eddNotOrdered || 0
              },
              {
                name: eddResultName.EDD_RED,
                y: result.data.eddRed || 0
              }
            ]
          });
          this.eddChart = true;
        } else {
          this.eddResultCountChartOptions.series.push({
            data: []
          });
        }
      } catch (error) {
        return [error];
      } finally {
        this.eddChartLoading = false;
      }
    },
    async fetchTpCountries() {
      try {
        this.tpOverCountriesChartLoading = true;
        const result = await chartsService.fetchTpCountries(
          this.companyId,
          this.getQueryParamExcludeSbos()
        );
        if (isValidResponse(result)) {
          const self = this;
          this.tpCountriesChartOptions.series.push({
            name: "",
            point: {
              events: {
                click() {
                  self.mapDrillDownOptions = makeDrillDownOptions({
                    tableTitle: this.name,
                    tableId: queryObjectKeys.THIRD_PARTY_COUNTRIES,
                    modalTitle:
                      "Third Parties by Country/Territory of Registration",
                    querySpecificData: {
                      country_name: this.name
                    }
                  });
                }
              }
            },
            data: result.data.data
          });
          this.tpOverCountriesChart = true;
        } else {
          this.tpCountriesChartOptions.series.push({
            data: []
          });
        }
      } catch (error) {
        return [error];
      } finally {
        this.tpOverCountriesChartLoading = false;
      }
    },
    async fetchCustomAttributeData(companyId, customAttributeId) {
      if (!this.customAttributesWidgetData?.[customAttributeId]) {
        return;
      }

      try {
        this.setCustomAttributeWidgetPropertyValue({
          customAttributeId,
          property: customAttributesWidgetKeys.IS_LOADING,
          value: true
        });

        const type = this.returnCustomAttributeOptions(customAttributeId)?.type;
        let data = await chartsService.fetchCustomAttributeChartData(
          companyId,
          customAttributeId
        );

        if (
          type === attributeType.RATING ||
          type === attributeType.MULTISELECT ||
          type === attributeType.DROPDOWN
        ) {
          data = this.sortDataInNumericalOrder(data);
        }

        this.setCustomAttributeWidgetPropertyValue({
          customAttributeId,
          property: customAttributesWidgetKeys.DATA,
          value: data
        });
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: "Error",
          variant: "danger"
        });

        return error;
      } finally {
        this.setCustomAttributeWidgetPropertyValue({
          customAttributeId,
          property: customAttributesWidgetKeys.IS_LOADING,
          value: false
        });
      }
    },
    setCustomAttributeWidgetPropertyValue({
      customAttributeId,
      property,
      value
    }) {
      if (!this.customAttributesWidgetData?.[customAttributeId]) {
        return;
      }

      this.customAttributesWidgetData[customAttributeId][property] = value;
    },
    handleCustomAttributeDrilldownClick({ title, value, label, attributeId }) {
      this.mapDrillDownOptions = makeDrillDownOptions({
        tableTitle: label,
        tableId: queryObjectKeys.CUSTOM_ATTRIBUTES,
        modalTitle: title,
        uriData: {
          company_id: this.companyId,
          attribute_id: attributeId,
          value
        },
        useNewQueryParameter: true
      });
    },
    returnCustomAttributeOptions(customAttributeId) {
      const attribute = this.customAttributesList?.find(
        ({ id } = {}) => id === customAttributeId
      );

      return {
        type: attribute?.tp_type || "",
        // Existing endpoint returns null for active and "2" for inactive custom attrs
        attributeIsInactive: !!attribute?.is_active
      };
    },
    sortDataInNumericalOrder(data) {
      const allDataLabelsAreNumerical = data?.every(
        (point) => !isNaN(parseFloat(point?.label))
      );

      if (allDataLabelsAreNumerical) {
        return data?.sort(
          (valueOne, valueTwo) =>
            parseFloat(valueTwo?.label) - parseFloat(valueOne?.label)
        );
      }

      return data;
    },
    onApplyFilters() {
      localStorage.setItem(
        "dashboard_view",
        JSON.stringify({
          dateSentFrom: this.form.dateSentFrom,
          dateSentTo: this.form.dateSentTo,
          countType: this.form.countType,
          path: window.location.pathname
        })
      );
      this.setUserViewData();
      this.resetFilter = true;
      this.advanceFilterForms = false;
    },
    onResetFilters() {
      this.form = {
        dateSentFrom: null,
        dateSentTo: null,
        countType: [{ value: null }]
      };
      localStorage.removeItem("dashboard_view");
      this.setUserViewData();
      this.advanceFilterForms = false;
      this.resetFilter = false;
    },
    printPageContent() {
      setTimeout(() => {
        const originalContents = document.body.innerHTML;
        const printReport =
          document.getElementById("analyticsUserView").innerHTML;
        document.body.innerHTML = printReport;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
      }, 2000);
    },
    handleStandardQuestionClick(questionDrillDownOptions) {
      this.mapDrillDownOptions = {
        ...this.mapDrillDownOptions,
        ...questionDrillDownOptions
      };
    },
    handleChartTypeSelect(currentWidget) {
      const index = this.selectedWidgets.findIndex(
        (widget) => widget.key === currentWidget?.key
      );

      if (index >= 0) {
        this.selectedWidgets[index].chartType = currentWidget?.type;
        this.updatePlatformView();
      }
    },
    makeObserveVisibility
  }
};
</script>

<style lang="scss" scoped>
.dashboard {
  .dashboard__header {
    display: flex;
    justify-content: space-between;

    .dashboard__title {
      font-size: 17px;
      margin: 0;
      padding: 12px 0 0 15px;
    }

    .dashboard__question {
      display: flex;
      align-items: center;
      padding-top: 5px;
    }
  }

  .dashboard__reset-filter {
    margin: 0px 0px 1px 0px;
    background: $carolina-blue;
    text-align: left;
    color: $white;
  }

  .dashboard__body {
    max-height: calc(100vh - 160px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .nav-tabs {
    li.nav-item {
      margin-right: 1px !important;
    }
  }
}
</style>
