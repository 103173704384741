<template>
  <b-modal
    v-model="showModal"
    hide-footer
    scrollable
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    :title="modalTitle"
    class="third-party-analysis-modal-table"
    @hidden="hideModal"
  >
    <b-overlay :show="isTableLoading" rounded="sm">
      <b-navbar toggleable="lg" type="dark" variant="light">
        <b-navbar-toggle target="nav-collapse" />
        <b-navbar-brand
          href="#"
          class="text-dark third-party-analysis-modal-table__table-title"
        >
          {{ tableTitle }}
        </b-navbar-brand>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-form>
              <b-btn
                v-if="hasDownloadIcon"
                class="mr-2"
                size="sm"
                variant="outline-info"
                @click="exportThirdParty"
              >
                <span v-if="isLoadingExport">
                  <b-spinner small></b-spinner>
                </span>
                <span v-else>
                  <font-awesome-icon icon="download" />
                </span>
              </b-btn>
              <b-form-group>
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-nav-form>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <!-- third party data table start -->
      <div v-if="hasTableError" class="third-party-analysis-modal-table__error">
        <ErrorMessage
          text="Something went wrong, please try again. If the issue persists please contact support."
        />
      </div>
      <div v-else>
        <b-table
          class="mx-1"
          bordered
          sticky-header="calc(100vh - 350px)"
          :items="getTableData"
          :fields="tableFields"
          :filter="filter"
          :per-page="itemsPerPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          responsive="sm"
        >
          <template v-slot:cell(created_date)="data">
            {{ setDateFormat(data.item.created_date) }}
          </template>
          <template v-slot:cell(comp_name_eng)="data">
            <a
              class="text-wrap"
              target="_blank"
              :href="getThirdPartyProfileLink(data.item.id)"
            >
              {{ data.item.comp_name_eng }}
            </a>
          </template>
        </b-table>

        <div v-if="!hasRows" class="text-center">
          <p>No data available</p>
        </div>
        <div v-else>
          <PerPagePagination
            :items-per-page="itemsPerPage"
            :current-page="currentPage"
            :total-items="totalItems"
            @change="onChangeItems"
            @click="onChangeCurrentPage"
          />
        </div>
      </div>
      <div
        v-if="hasExportError"
        class="third-party-analysis-modal-table__export-error"
      >
        <ErrorMessage
          text="Something went wrong, please try again. If the issue persists please contact support."
        />
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import moment from "moment";
import ErrorMessage from "@/molecules/ErrorMessage/ErrorMessage";
import {
  getThirdPartyQueryObject,
  HTTP,
  makeAuthorizationHeader,
  queryObjectMap
} from "@/utils";
import { queryObjectKeys, urls } from "@/constants";
import PerPagePagination from "@/molecules/PerPagePagination/PerPagePagination";

export default {
  name: "ThirdPartyAnalysisModalTable",
  components: {
    ErrorMessage,
    PerPagePagination
  },
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    tableId: {
      type: String,
      default: ""
    },
    tableTitle: {
      type: String,
      default: ""
    },
    modalTitle: {
      type: String,
      default: ""
    },
    querySpecificData: {
      type: Object,
      default: () => null
    },
    uriData: {
      type: Object,
      default: () => null
    },
    useNewQueryParameter: {
      type: Boolean,
      default: false
    },
    useNameAsFilterQueryParameter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: false,
      tableItems: [],
      isTableLoading: false,
      filter: null,
      itemsPerPage: 10,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      totalItems: 0,
      fromRows: 0,
      toRows: 0,
      pageOptions: [5, 10, 25, 50, 75, 100],
      hasRows: false,
      hasTableError: false,
      isLoadingExport: false,
      hasExportError: false,
      urls
    };
  },
  computed: {
    tableFields() {
      return queryObjectMap[this.tableId]?.tableFields || [];
    },
    hasDownloadIcon() {
      return !!queryObjectMap[this.tableId]?.downloadCSVFunction || false;
    }
  },
  watch: {
    tableId(newValue) {
      if (newValue) {
        this.sortBy = queryObjectMap[this.tableId]?.defaultSortBy || "";
        this.showModal = true;
      }
    },
    hasExportError(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.hasExportError = false;
        }, 5000);
      }
    }
  },
  methods: {
    onChangeItems(val) {
      this.currentPage = 1;
      this.itemsPerPage = parseInt(val);
    },
    onChangeCurrentPage(val) {
      this.currentPage = parseInt(val);
    },
    makeQueryObjectOptions() {
      const searchFilter = this.useNameAsFilterQueryParameter
        ? { name: this.filter }
        : { filter: this.filter };

      return {
        ...searchFilter,
        sort: `${this.sortBy}|${this.sortDesc ? "desc" : "asc"}`,
        per_page: this.itemsPerPage,
        ...(this.useNewQueryParameter
          ? { page_number: this.currentPage }
          : { page: this.currentPage }),
        company_id: this.companyId,
        querySpecificData: this.querySpecificData,
        uriData: this.uriData,
        queryTypeName: this.tableId
      };
    },
    setDateFormat(value) {
      const date = value || null;

      moment.locale("en");
      return moment.utc(date).local().format("DD dddd MMMM YYYY HH:mm");
    },
    getQueryParamExcludeSbos({ sanctionsEnforcementsType }) {
      return {
        exclude_sbos: !sanctionsEnforcementsType
      };
    },
    async getTableData() {
      try {
        if (this.sortBy) {
          this.isTableLoading = true;
          const { formData, uri, method } = getThirdPartyQueryObject(
            this.makeQueryObjectOptions()
          );
          const { data = {} } = await HTTP(
            method,
            uri,
            method.toLowerCase() !== "get" && formData,
            makeAuthorizationHeader(),
            this.getQueryParamExcludeSbos(formData)
          );
          this.setRowData(data);
          return this.tableDataDTO(data);
        }
      } catch (error) {
        this.hasTableError = true;
      } finally {
        this.isTableLoading = false;
      }
    },
    tableDataDTO(data) {
      if (data?.data) {
        if (
          this.tableId === queryObjectKeys.ESG_THIRD_PARTY ||
          this.tableId === queryObjectKeys.CUSTOM_ATTRIBUTES
        ) {
          return data.data.map(({ id, name }) => ({
            id,
            comp_name_eng: name
          }));
        } else {
          return data.data;
        }
      } else {
        return [];
      }
    },
    async exportThirdParty() {
      if (!this.hasDownloadIcon) {
        return false;
      }
      try {
        this.isLoadingExport = true;
        await queryObjectMap[this.tableId].downloadCSVFunction(
          this.querySpecificData
        );
      } catch (error) {
        this.hasExportError = true;
      } finally {
        this.isLoadingExport = false;
      }
    },
    setRowData(data) {
      this.totalItems = data?.total || 0;
      this.hasRows = !!data?.data?.length || false;
    },
    hideModal() {
      this.$emit("hideModal");
      this.showModal = false;
      this.isTableLoading = false;
      this.filter = null;
      this.itemsPerPage = 10;
      this.currentPage = 1;
      this.sortBy = "";
      this.sortDesc = false;
      this.totalItems = 0;
      this.fromRows = 0;
      this.toRows = 0;
      this.hasTableError = false;
      this.isLoadingExport = false;
      this.hasExportError = false;
    },
    getThirdPartyProfileLink(thirdPartyId) {
      const pathHash =
        this.tableId === queryObjectKeys.ESG_THIRD_PARTY ? "#greenlite" : "";

      return `${urls.THIRD_PARTY_PROFILE(thirdPartyId)}${pathHash}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.third-party-analysis-modal-table {
  &__error {
    display: flex;
    min-height: 200px;
  }

  &__table-title {
    font-size: 18px;
  }

  &:deep(.page-item.active .page-link) {
    // !important required to override styles
    background-color: $black-olive !important;
    border-color: $black-olive !important;
    color: $white !important;
  }
}
</style>
