import { isValidResponse, setAppDataInDom, setGlobalCssVars } from "@/utils";
import { platformSettingsService, viewsService } from "@/services";
import { actionName, mutationName } from "@/constants";

export const actions = {
  async fetchPublicPlatformSettings({ commit }) {
    try {
      commit(
        mutationName.PLATFORM_SETTINGS.SET_IS_LOADING_PLATFORM_SETTINGS,
        true,
        { root: true }
      );
      const result =
        await platformSettingsService.fetchPublicPlatformSettings();
      if (isValidResponse(result)) {
        commit(
          mutationName.PLATFORM_SETTINGS.SET_PLATFORM_LOGO,
          result.data.data.platformLogo,
          {
            root: true
          }
        );
        commit(
          mutationName.PLATFORM_SETTINGS.SET_TERMS_AND_CONDITIONS_LINK,
          result.data.data.platformTermsAndConditionsLink,
          {
            root: true
          }
        );
        commit(
          mutationName.PLATFORM_SETTINGS.SET_PRIVACY_POLICY_LINK,
          result.data.data.platformPrivacyPolicyLink,
          {
            root: true
          }
        );
        commit(
          mutationName.PLATFORM_SETTINGS.SET_DISABLE_PRIVACY_POLICY,
          result.data.data.disablePrivacyPolicy,
          {
            root: true
          }
        );
        commit(
          mutationName.PLATFORM_SETTINGS.SET_PLATFORM_PRIMARY_COLOR,
          result.data.data.platformPrimaryColor,
          {
            root: true
          }
        );
        commit(
          mutationName.PLATFORM_SETTINGS.SET_PLATFORM_SECONDARY_COLOR,
          result.data.data.platformSecondaryColor,
          {
            root: true
          }
        );
        commit(
          mutationName.PLATFORM_SETTINGS.SET_SSO_URL,
          result.data.data.platformSSOURL,
          {
            root: true
          }
        );
        commit(
          mutationName.PLATFORM_SETTINGS.SET_PLATFORM_REGISTERED_BUSINESS_NAME,
          result.data.data.platformRegisteredBusinessName,
          {
            root: true
          }
        );
        commit(
          mutationName.PLATFORM_SETTINGS.SET_PLATFORM_FAV_ICON,
          result.data.data.platformFavIcon,
          {
            root: true
          }
        );
        commit(
          mutationName.PLATFORM_SETTINGS.SET_POSTHOG_API_KEY,
          result?.data?.data?.posthogAPIKey,
          {
            root: true
          }
        );
        setGlobalCssVars(
          result.data.data.platformPrimaryColor,
          result.data.data.platformSecondaryColor
        );
        setAppDataInDom(
          result.data.data.platformFavIcon,
          result.data.data.platformRegisteredBusinessName
        );
      } else {
        // eslint-disable-next-line no-throw-literal
        throw { error: "is not valid response" };
      }
    } catch (error) {
      commit(
        mutationName.PLATFORM_SETTINGS.SET_PLATFORM_LOGO_FALLBACK,
        "Apologies. We have encountered a problem while loading your platform. Please contact support.",
        {
          root: true
        }
      );
    } finally {
      commit(
        mutationName.PLATFORM_SETTINGS.SET_IS_LOADING_PLATFORM_SETTINGS,
        false,
        { root: true }
      );
    }
  },
  async fetchPlatformSettings({ commit }) {
    commit(
      mutationName.PLATFORM_SETTINGS.SET_IS_LOADING_PLATFORM_SETTINGS,
      true,
      { root: true }
    );
    const result = await platformSettingsService.fetchPlatformSettings();
    if (isValidResponse(result)) {
      commit(
        mutationName.PLATFORM_SETTINGS.SET_PLATFORM_LOGO,
        result.data.data.platformLogo,
        {
          root: true
        }
      );
      commit(
        mutationName.PLATFORM_SETTINGS.SET_PLATFORM_PRIMARY_COLOR,
        result.data.data.platformPrimaryColor,
        {
          root: true
        }
      );
      commit(
        mutationName.PLATFORM_SETTINGS.SET_PLATFORM_SECONDARY_COLOR,
        result.data.data.platformSecondaryColor,
        {
          root: true
        }
      );
      commit(
        mutationName.PLATFORM_SETTINGS.SET_TERMS_AND_CONDITIONS_LINK,
        result.data.data.platformTermsAndConditionsLink,
        {
          root: true
        }
      );
      commit(
        mutationName.PLATFORM_SETTINGS.SET_PRIVACY_POLICY_LINK,
        result.data.data.platformTermsAndConditionsLink,
        {
          root: true
        }
      );
      commit(
        mutationName.PLATFORM_SETTINGS.SET_PLATFORM_BUSINESS_ADDRESS,
        result.data.data.platformTermsAndConditionsLink,
        {
          root: true
        }
      );
      commit(
        mutationName.PLATFORM_SETTINGS.SET_PLATFORM_COPYRIGHT_NOTICE,
        result.data.data.platformTermsAndConditionsLink,
        {
          root: true
        }
      );
      commit(
        mutationName.PLATFORM_SETTINGS.SET_PLATFORM_FAQ_LINK,
        result.data.data.platformFaqLink,
        {
          root: true
        }
      );
      commit(
        mutationName.PLATFORM_SETTINGS.SET_PLATFORM_FAV_ICON,
        result.data.data.platformFavIcon,
        {
          root: true
        }
      );
      commit(
        mutationName.PLATFORM_SETTINGS.SET_PLATFORM_KNOWLEDGE_CENTRE_LINK,
        result.data.data.platformKnowledgeCentreLink,
        {
          root: true
        }
      );
      commit(
        mutationName.PLATFORM_SETTINGS.SET_PLATFORM_LEGAL_DISCLAIMER,
        result.data.data.platformLegalDisclaimer,
        {
          root: true
        }
      );
      commit(
        mutationName.PLATFORM_SETTINGS.SET_PLATFORM_MAIN_HEADER_LOGO,
        result.data.data.platformMainHeaderLogo,
        {
          root: true
        }
      );
      commit(
        mutationName.PLATFORM_SETTINGS.SET_PLATFORM_NAME,
        result.data.data.platformName,
        {
          root: true
        }
      );
      commit(
        mutationName.PLATFORM_SETTINGS.SET_PLATFORM_REGISTERED_BUSINESS_NAME,
        result.data.data.platformRegisteredBusinessName,
        {
          root: true
        }
      );
      commit(
        mutationName.PLATFORM_SETTINGS.SET_PLATFORM_RELEASE_NOTE_LINK,
        result.data.data.platformReleaseNoteLink,
        {
          root: true
        }
      );
      commit(
        mutationName.PLATFORM_SETTINGS.SET_PLATFORM_REQUEST_SUPPORT_LINK,
        result.data.data.platformRequestSupportLink,
        {
          root: true
        }
      );
      commit(
        mutationName.PLATFORM_SETTINGS.SET_PLATFORM_SUPPORT_EMAIL,
        result.data.data.platformSupportEmail,
        {
          root: true
        }
      );
      commit(
        mutationName.PLATFORM_SETTINGS.SET_PLATFORM_WEB_URL,
        result.data.data.platformWebUrl,
        {
          root: true
        }
      );
      commit(
        mutationName.PLATFORM_SETTINGS.SET_SINGLE_TP_MODAL_WORDING,
        result.data.data.singleTpModalWording,
        {
          root: true
        }
      );
      commit(
        mutationName.PLATFORM_SETTINGS.SET_POSTHOG_API_KEY,
        result?.data?.data?.posthogAPIKey,
        {
          root: true
        }
      );
      setGlobalCssVars(
        result.data.data.platformPrimaryColor,
        result.data.data.platformSecondaryColor
      );
      setAppDataInDom(
        result.data.data.platformFavIcon,
        result.data.data.platformRegisteredBusinessName
      );
    }
    commit(
      mutationName.PLATFORM_SETTINGS.SET_IS_LOADING_PLATFORM_SETTINGS,
      false,
      { root: true }
    );
    return result;
  },
  async updatePlatformSettings({ rootState, dispatch }, payload) {
    const { companyId } = rootState.company;
    const response = await platformSettingsService.updatePlatformSettings({
      ...payload,
      companyId
    });
    if (isValidResponse(response)) {
      await dispatch(
        actionName.PLATFORM_SETTINGS.FETCH_PLATFORM_SETTINGS,
        null,
        { root: true }
      );
    }
    return response;
  },
  async fetchPlatformUserList({ commit, rootState }) {
    const { userId = "" } = rootState.user;
    const { companyId = "" } = rootState.company;
    const postObject = {
      filter: "",
      sort: "name|asc",
      companyId,
      userId,
      is_active: 1
    };
    const data = await viewsService.fetchPlatformUsers(postObject);

    commit(mutationName.PLATFORM_SETTINGS.SET_PLATFORM_USER_LIST, data, {
      root: true
    });
  },
  async fetchProductVersions({ commit }) {
    const { data = {} } = await platformSettingsService.fetchProductVersions();

    commit(mutationName.PLATFORM_SETTINGS.SET_PRODUCT_VERSIONS_LIST, data, {
      root: true
    });
  }
};
