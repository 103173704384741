<template>
  <div class="expected-data-mapping">
    <div class="expected-data-mapping__name">
      <BaseText :text="expectedData.expectedDataName" />
    </div>
    <SetToBlock
      v-bind="expectedData"
      class="expected-data-mapping__set-to-block"
      data-test-id="expected-data-mapping__set-to-block"
      @change="
        $emit('change', {
          id: expectedData.id,
          event: $event
        })
      "
    />
  </div>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import SetToBlock from "@/molecules/SetToBlock/SetToBlock";

export default {
  name: "ExpectedDataMapping",
  props: {
    expectedData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    BaseText,
    SetToBlock
  }
};
</script>

<style scoped lang="scss">
.expected-data-mapping {
  display: flex;
  gap: 14px;
  padding-bottom: 20px;

  &__name,
  &__set-to-block {
    flex: 1;
  }
}
</style>
