<template id="template-input">
  <b-form-group class="mt-2">
    <label>
        {{
          attributeData.tp_alias
            ? attributeData.tp_alias
            : attributeData.tp_customatt
        }}
        <Tooltip
          class="ml-2"
          v-if="getAttribute(attributeData)"
          :text="attributeData.description"
        />
    </label>

    <!-- if custom attribute is required -->
    <div v-if="parseInt(attributeData.tp_is_required)" :ref="attributeData.id">
      <v-select
        placeholder="---Required---"
        v-model="v$.form.$model"
        :state="v$.form.$dirty ? !v$.form.$error : null"
        :class="{ 'form-control is-invalid p-0': v$.form.$error }"
        :options="countries"
        label="name"
        track-by="name"
        @update:modelValue="saveValue"
        id="multiselect"
      >
        <template slot="option" slot-scope="option">
          <country-flag
            v-if="option.icon"
            :country="option.icon"
            size="small"
          />
          <span>{{ "&nbsp;" + option.name }}</span>
        </template>
      </v-select>
      <b-form-invalid-feedback id="multiselect"
        >A Required field has no value.</b-form-invalid-feedback
      >
    </div>
    <!-- if custom attribute is required -->

    <!-- if custom attribute is not required -->
    <div v-else>
      <v-select
        v-model="form"
        :options="countries"
        label="name"
        track-by="name"
        @update:modelValue="saveValue"
      >
        <template slot="option" slot-scope="option">
          <country-flag
            v-if="option.icon"
            :country="option.icon"
            size="small"
          />
          <span>{{ "&nbsp;" + option.name }}</span>
        </template>
      </v-select>
    </div>
    <!-- if custom attribute is not required -->
  </b-form-group>
</template>

<script>
import { EventBus } from "@/event-bus";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Tooltip from "@/molecules/Tooltip/Tooltip";
import { mapState } from "vuex";

export default {
  name: "CountryList",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {
    Tooltip
  },
  data() {
    return {
      form: null,
      tpRequired: false
    };
  },
  validations() {
    if (parseInt(this.attributeData.tp_is_required) || this.tpRequired) {
      return {
        form: {
          required
        }
      };
    }
  },
  created() {
    EventBus.on("countryListValidationError", (inValidObj) => {
      const element = this.$refs[inValidObj.id];
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      this.tpRequired = true;
      this.v$.form.$touch();
    });
  },

  mounted() {
    if (this.values.value) {
      this.form = this.countries.filter(
        (val) => val.id === JSON.parse(this.values.value)?.id
      );
    }
  },
  computed: {
    ...mapState({
      countries: (state) => state.country.countries
    })
  },
  methods: {
    getAttribute(attributeData) {
      return attributeData?.description || "";
    },
    // save custom attribute value
    saveValue() {
      let countryobj = {};
      if (this.form) {
        countryobj = {
          id: this.form.id,
          value: this.form.name
        };
      }

      if (parseInt(this.attributeData.tp_is_required)) {
        this.v$.form.$touch();
        // emit event saveValue when custom attribute is required
        EventBus.emit(
          "saveValue",
          {
            value: JSON.stringify(countryobj),
            id: this.attributeData.id,
            error: this.v$.form.$anyError,
            type: this.attributeData.tp_type
          }
        );
      } else {
        // emit event saveValue when custom attribute is not required
        EventBus.emit(
          "saveValue",
          {
            value: JSON.stringify(countryobj),
            id: this.attributeData.id,
            error: false,
            type: this.attributeData.tp_type
          }
        );
      }
    }
  },
  props: {
    attributeData: Object,
    values: Object
  }
};
</script>
