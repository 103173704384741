<template>
  <ErrorPageTemplate v-bind="errorPageOptions" />
</template>

<script>
import ErrorPageTemplate from "@/templates/ErrorPageTemplate/ErrorPageTemplate";

export default {
  name: "InvalidDocumentFormatTemplate",
  components: {
    ErrorPageTemplate
  },
  data() {
    return {
      errorPageOptions: {
        iconOptions: {
          icon: "file-upload"
        },
        headingOptions: {
          text: "Invalid document format"
        },
        contentOptions: {
          text: [
            "You have uploaded an unsupported document format, please re-upload your document in the correct format.",
            "If the issue persists please contact support."
          ]
        }
      }
    };
  }
};
</script>
