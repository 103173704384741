import {
  tasksKeysDTO,
  tasksKeysAPIDTO,
  tasksFilterKeysAPIDTO,
  tasksFilterKeysDTO,
  operations
} from "@/constants";
import { makeExpectedDataPayloadDTO } from "@/services/expectedData/dto/expectedData.dto";
import {
  makeDestinationDTO,
  makeDestinationAPIDTO
} from "@/services/commonDto";
import { makeExpectedDataMappingLiteralDTO } from "@/services/commonDto/expectedDataMapping/expectedDataMapping.dto";
import { makeTaskOutcomeDTO } from "@/services/taskTemplates/dto/taskTemplates.dto";
import {
  isDateFilterKey,
  isCreatedDateFilterKey,
  isUpdatedDateFilterKey
} from "@/organisms/Tasks/Tasks.logic";
import { getSelectedOption, getAllSelectedOptionValues } from "@/utils";

const makeTaskDTO = ({
  task = {},
  _makeExpectedDataPayloadDTO = makeExpectedDataPayloadDTO,
  _makeDestinationDTO = makeDestinationDTO,
  _makeExpectedDataMappingLiteralDTO = makeExpectedDataMappingLiteralDTO,
  _makeTaskOutcomeDTO = makeTaskOutcomeDTO
} = {}) => ({
  [tasksKeysDTO.ID]: task?.[tasksKeysAPIDTO.ID],
  [tasksKeysDTO.CREATED_AT]: task?.[tasksKeysAPIDTO.CREATED_AT],
  [tasksKeysDTO.UPDATED_AT]: task?.[tasksKeysAPIDTO.UPDATED_AT],
  [tasksKeysDTO.COMPANY_ID]: task?.[tasksKeysAPIDTO.COMPANY_ID],
  [tasksKeysDTO.COMPANY_TASK_ID]: task?.[tasksKeysAPIDTO.COMPANY_TASK_ID],
  [tasksKeysDTO.TEMPLATE_ID]: task?.[tasksKeysAPIDTO.TEMPLATE_ID],
  [tasksKeysDTO.SUBJECT]: task?.[tasksKeysAPIDTO.SUBJECT],
  [tasksKeysDTO.DESCRIPTION]: task?.[tasksKeysAPIDTO.DESCRIPTION],
  ..._makeExpectedDataPayloadDTO({
    expectedDataDefinition: task?.[tasksKeysAPIDTO.EXPECTED_DATA_DEFINITION]
  }),
  [tasksKeysDTO.STATUS]: task?.[tasksKeysAPIDTO.STATUS]?.toLowerCase?.(),
  [tasksKeysDTO.ASSIGNEE]: _makeDestinationDTO({
    emailDestinations: [task[tasksKeysAPIDTO.ASSIGNEE]]
  })[0],
  [tasksKeysDTO.EDIT_PERMISSIONS]: _makeDestinationDTO({
    emailDestinations: task[tasksKeysAPIDTO.EDIT_PERMISSIONS]
  }),
  [tasksKeysDTO.REOPEN_PERMISSIONS]: _makeDestinationDTO({
    emailDestinations: task[tasksKeysAPIDTO.REOPEN_PERMISSIONS]
  }),
  [tasksKeysDTO.SELECTED_OUTCOME_ID]:
    task?.[tasksKeysAPIDTO.SELECTED_OUTCOME_ID],
  [tasksKeysDTO.OUTCOMES_DATA]:
    task?.[tasksKeysAPIDTO.OUTCOMES_DATA]?.map?.((outcome) => ({
      [tasksKeysDTO.ACTION_ID]: outcome?.[tasksKeysAPIDTO.ACTION_ID],
      [tasksKeysDTO.EXPECTED_DATA_MAPPING]: outcome?.[
        tasksKeysAPIDTO.EXPECTED_DATA_MAPPING
      ].map?.((expectedData) =>
        _makeExpectedDataMappingLiteralDTO(expectedData)
      )
    })) || [],
  [tasksKeysDTO.OUTCOME_ACTIONS]:
    task?.[tasksKeysAPIDTO.OUTCOME_ACTIONS]?.map?.((outcome) => ({
      [tasksKeysDTO.OUTCOME_PERMISSIONS]: _makeDestinationDTO({
        emailDestinations: outcome[tasksKeysAPIDTO.OUTCOME_PERMISSIONS]
      }),
      [tasksKeysDTO.OUTCOME]: _makeTaskOutcomeDTO({
        taskOutcome: outcome?.[tasksKeysAPIDTO.OUTCOME]
      })
    })) || []
});

const makeDateTasksFilterAPIDTO = ({ key = "", date = "" } = {}) => {
  if (
    key === tasksFilterKeysDTO.CREATED_DATE_AFTER ||
    key === tasksFilterKeysDTO.UPDATED_DATE_AFTER
  ) {
    return {
      date,
      compare: operations.GREATER_THAN
    };
  } else if (
    key === tasksFilterKeysDTO.CREATED_DATE_BEFORE ||
    key === tasksFilterKeysDTO.UPDATED_DATE_BEFORE
  ) {
    return {
      date,
      compare: operations.LESS_THAN
    };
  }
};

const makeTasksFilterAPIDTO = ({
  filter = [],
  _isDateFilterKey = isDateFilterKey,
  _isCreatedDateFilterKey = isCreatedDateFilterKey,
  _isUpdatedDateFilterKey = isUpdatedDateFilterKey,
  _getSelectedOption = getSelectedOption,
  _getAllSelectedOptionValues = getAllSelectedOptionValues,
  _makeDateTasksFilterAPIDTO = makeDateTasksFilterAPIDTO,
  _makeDestinationAPIDTO = makeDestinationAPIDTO
}) =>
  filter.reduce(
    (acc, { availableFilterOptions, availableFilterValueComponentOptions }) => {
      const { value } = _getSelectedOption(availableFilterOptions);
      if (_isDateFilterKey({ dateValue: value })) {
        if (_isCreatedDateFilterKey(value)) {
          !acc?.[tasksFilterKeysAPIDTO.CREATED_DATE] &&
            (acc[tasksFilterKeysAPIDTO.CREATED_DATE] = []);
          acc[tasksFilterKeysAPIDTO.CREATED_DATE].push(
            _makeDateTasksFilterAPIDTO({
              key: value,
              date: availableFilterValueComponentOptions?.value
            })
          );
        } else if (_isUpdatedDateFilterKey(value)) {
          !acc?.[tasksFilterKeysAPIDTO.UPDATED_DATE] &&
            (acc[tasksFilterKeysAPIDTO.UPDATED_DATE] = []);
          acc[tasksFilterKeysAPIDTO.UPDATED_DATE].push(
            _makeDateTasksFilterAPIDTO({
              key: value,
              date: availableFilterValueComponentOptions?.value
            })
          );
        }
      } else if (value === tasksFilterKeysDTO.ASSIGNEE) {
        acc[tasksFilterKeysAPIDTO.ASSIGNEE] = _makeDestinationAPIDTO({
          emailDestinations: availableFilterValueComponentOptions.options
        });
      } else if (value === tasksFilterKeysDTO.EDIT_PERMISSIONS) {
        acc[tasksFilterKeysAPIDTO.EDIT_PERMISSIONS] = _makeDestinationAPIDTO({
          emailDestinations: availableFilterValueComponentOptions.options
        });
      } else if (value === tasksFilterKeysDTO.TEMPLATE_ID) {
        acc[tasksFilterKeysAPIDTO.TEMPLATE_ID] = _getAllSelectedOptionValues(
          availableFilterValueComponentOptions.options
        );
      } else if (value === tasksFilterKeysDTO.COMPANY_TASK_ID) {
        acc[tasksFilterKeysAPIDTO.COMPANY_TASK_ID] =
          _getAllSelectedOptionValues(
            availableFilterValueComponentOptions.options
          );
      } else if (value === tasksFilterKeysDTO.STATUS) {
        acc[tasksFilterKeysAPIDTO.STATUS] = _getAllSelectedOptionValues(
          availableFilterValueComponentOptions.options
        );
      } else if (value === tasksFilterKeysDTO.SUBJECT) {
        acc[tasksFilterKeysAPIDTO.SUBJECT] = _getAllSelectedOptionValues(
          availableFilterValueComponentOptions.options
        );
      } else if (value === tasksFilterKeysDTO.DESCRIPTION) {
        acc[tasksFilterKeysAPIDTO.DESCRIPTION] = _getAllSelectedOptionValues(
          availableFilterValueComponentOptions.options
        );
      } else if (value === tasksFilterKeysDTO.SUBJECT_OR_DESCRIPTION) {
        acc[tasksFilterKeysAPIDTO.SUBJECT_OR_DESCRIPTION] =
          _getAllSelectedOptionValues(
            availableFilterValueComponentOptions.options
          );
      }
      return acc;
    },
    {}
  );

const makeTaskOutcomeAPIDTO = ({
  amendedTaskOutcome = [],
  actionId = ""
} = {}) => ({
  [tasksKeysAPIDTO.ACTION_ID]: actionId,
  [tasksKeysAPIDTO.EXPECTED_DATA_MAPPING]: amendedTaskOutcome.reduce(
    (acc, { expectedData }) => {
      acc.push({
        [tasksKeysAPIDTO.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
          expectedData.name,
        [tasksKeysAPIDTO.TRIGGERING_DATA]: {
          [tasksKeysAPIDTO.DATA]: {
            [tasksKeysAPIDTO.DATA]: {
              [tasksKeysAPIDTO.VALUE]: expectedData.getValue()
            },
            [tasksKeysAPIDTO.TYPE]: expectedData.valueDataType
          },
          [tasksKeysAPIDTO.TYPE]: expectedData.valueType
        }
      });
      return acc;
    },
    []
  )
});

const makeTaskAPIDTO = ({
  task = {},
  amendedTaskData = {},
  _makeTaskOutcomeAPIDTO = makeTaskOutcomeAPIDTO,
  _makeDestinationAPIDTO = makeDestinationAPIDTO,
  _getSelectedOption = getSelectedOption
} = {}) => {
  const { value: selectedActionId = "" } = _getSelectedOption(
    amendedTaskData.actionsOptions
  );
  const newTaskOutcomes =
    Object.keys(amendedTaskData?.outcomesData || {}).reduce?.(
      (acc, selectedActionId) => {
        acc.push(
          _makeTaskOutcomeAPIDTO({
            actionId: selectedActionId,
            amendedTaskOutcome:
              amendedTaskData?.outcomesData?.[selectedActionId]
          })
        );
        return acc;
      },
      []
    ) || [];
  const currentTaskOutcomes =
    task.outcomesData?.reduce?.((acc, taskOutcome) => {
      if (!amendedTaskData.outcomesData.hasOwnProperty(taskOutcome.actionId)) {
        acc.push(
          _makeTaskOutcomeAPIDTO({
            actionId: taskOutcome.actionId,
            amendedTaskOutcome:
              amendedTaskData?.outcomesData?.[taskOutcome.actionId]
          })
        );
      }
      return acc;
    }, []) || [];
  return {
    [tasksKeysAPIDTO.ASSIGNEE]: _makeDestinationAPIDTO({
      emailDestinations: amendedTaskData.assigneeOptions
    })[0],
    [tasksKeysAPIDTO.SELECTED_OUTCOME_ID]: selectedActionId,
    [tasksKeysAPIDTO.OUTCOMES_DATA]: [
      ...currentTaskOutcomes,
      ...newTaskOutcomes
    ]
  };
};

export {
  makeTaskDTO,
  makeTaskAPIDTO,
  makeTasksFilterAPIDTO,
  makeTaskOutcomeAPIDTO,
  makeDateTasksFilterAPIDTO
};
