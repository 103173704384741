<template>
  <div class="ultimate-beneficial-owner-visualisation-chart">
    <highcharts v-if="hasChartData" :options="chartOptions" />
    <div
      v-else
      class="ultimate-beneficial-owner-visualisation-chart__not-available-text"
    >
      <BaseText text="Chart not available" />
    </div>
  </div>
</template>

<script>
import { isEqual } from "lodash";
import BaseText from "@/atoms/BaseText/BaseText";

export default {
  name: "UltimateBeneficialOwnersVisualisationChart",
  components: { BaseText },
  props: {
    beneficialOwners: {
      type: Array,
      default: () => []
    },
    relationships: {
      type: Array,
      default: () => []
    },
    thirdParty: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "networkgraph",
          height: 500
        },
        title: {
          text: ""
        },
        credits: {
          enabled: false
        },
        tooltip: {
          formatter() {
            return `<b>${
              this.point.name
            }</b><br><br><b>Person of significant control:</b> ${
              this.point.custom?.isPSC ? "Yes" : "No"
            }<br><b>Beneficial ownership (%):</b> ${
              this.point.custom?.beneficialOwnershipPercentage || "-"
            }<br><b>Direct ownership (%):</b> ${
              this.point.custom?.directOwnershipPercentage || "-"
            }<br><b>Indirect ownership (%):</b> ${
              this.point.custom?.indirectOwnershipPercentage || "-"
            }`;
          }
        },
        plotOptions: {
          series: {
            keys: ["to", "from", "id", "sharePercentage"]
          }
        },
        series: [
          {
            dataLabels: {
              enabled: true,
              linkFormatter() {
                return this.point.sharePercentage
                  ? `${this.point.sharePercentage}%`
                  : "";
              },
              align: "left",
              x: 15,
              verticalAlign: "middle",
              style: {
                fontSize: "0.75em"
              }
            },
            draggable: false,
            data: [],
            nodes: []
          }
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                "viewFullscreen",
                "separator",
                "downloadPNG",
                "downloadJPEG"
              ]
            }
          },
          sourceWidth: 1500,
          sourceHeight: 800,
          chartOptions: {
            plotOptions: {
              networkgraph: {
                dataLabels: {
                  enabled: true,
                  linkFormatter() {
                    return this.point.sharePercentage
                      ? `${this.point.sharePercentage}%`
                      : "";
                  },
                  align: "left",
                  x: 15,
                  verticalAlign: "middle",
                  style: {
                    fontSize: "0.75em"
                  }
                }
              }
            }
          }
        }
      }
    };
  },
  computed: {
    hasChartData() {
      return !!this.relationships?.length && !!this.beneficialOwners?.length;
    }
  },
  watch: {
    beneficialOwners: {
      deep: true,
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          this.makeChartOptions();
        }
      }
    },
    relationships: {
      deep: true,
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          this.makeChartOptions();
        }
      }
    }
  },
  created() {
    this.makeChartOptions();
  },
  methods: {
    makeChartData() {
      this.relationships.forEach(
        ({
          relationshipId,
          sourceMemberId,
          targetMemberId,
          sharePercentage
        }) => {
          const { memberId: targetId } =
            this.beneficialOwners.find(
              ({ memberId }) => memberId === targetMemberId
            ) || {};
          const { memberId: sourceId } =
            this.beneficialOwners.find(
              ({ memberId }) => memberId === sourceMemberId
            ) || {};

          if (targetId && sourceId) {
            this.chartOptions.series[0].data.push([
              targetId,
              sourceId,
              relationshipId,
              sharePercentage
            ]);
          }
        }
      );
    },
    makeChartNodes() {
      this.beneficialOwners.forEach(
        ({
          name,
          memberId,
          beneficialOwnershipPercentage,
          directOwnershipPercentage,
          indirectOwnershipPercentage,
          isPSC,
          isRootThirdParty
        }) => {
          this.chartOptions.series[0].nodes.push({
            name,
            id: memberId,
            marker: {
              radius: isRootThirdParty ? 20 : 15,
              fillColor: isRootThirdParty ? "#41b7dd" : "#164c6d"
            },
            custom: {
              directOwnershipPercentage,
              indirectOwnershipPercentage,
              beneficialOwnershipPercentage,
              isPSC
            }
          });
        }
      );
    },
    makeChartOptions() {
      this.makeChartData();
      this.makeChartNodes();
    }
  }
};
</script>

<style scoped lang="scss">
.ultimate-beneficial-owner-visualisation-chart {
  &__not-available-text {
    margin-top: 50px;
    text-align: center;
  }
}
</style>
