import { languagesService } from "@/services";
import { mutationName } from "@/constants";

export const actions = {
  async fetchLanguages({ commit }) {
    const { data = [] } = await languagesService.fetchLanguages();

    commit(mutationName.LANGUAGES.SET_LANGUAGES, data);
  }
};
