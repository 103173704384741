import { getterName } from "@/constants";

export const getters = {
  getIdToRoleNameMap(state) {
    return state.roles.reduce((acc, { id, name }) => {
      acc[id] = name;
      return acc;
    }, {});
  },
  getIdsToRoleNames: (_, __, ___, rootGetters) => (idsList) =>
    idsList?.map(
      (id) => rootGetters[getterName.ROLES.GET_ID_TO_ROLE_NAME_MAP][id]
    ) || [],
  getAllRolesAsMultiselectOptions(state) {
    return state?.roles?.map((role) => ({
      id: role?.id,
      text: role?.name,
      value: role?.id
    }));
  }
};
