<template>
  <div
    :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
    :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'"
  >
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :isHidden="isHidden"
      :label="label"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
    />

    <!-- input content -->

    <b-collapse class="card mt-5" id="collapse-1" v-model="collapse">
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1'"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <div class="mt-3" :id="fieldData.id">
        <h4>{{ fieldData.label[selectedLanguage] }}</h4>
        <p>{{ fieldData.description[selectedLanguage] }}</p>
      </div>
      <b-modal
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        v-model="showAddRowModal"
        :title="
          isUpdtating
            ? $t('workflow.update') + ' ' + fieldData.label[selectedLanguage]
            : $t('workflow.add') + ' ' + fieldData.label[selectedLanguage]
        "
        size="xl"
      >
        <div
          :class="
            $store.state.rightAlign
              ? 'card-body text-right'
              : 'card-body text-left'
          "
        >
          <div class="form-row">
            <div
              class="col-md-6"
              v-show="!fieldData.advance || !fieldData.advance.fullNameDisabled"
            >
              <b-form-group
                :description="fieldData.fullNameHelperText[selectedLanguage]"
                :invalid-feedback="
                  fieldData.fullNameValidationMessage[selectedLanguage]
                "
              >
                <label>
                  <span
                    v-if="
                      fieldData.validation &&
                      JSON.parse(fieldData.validation.isRequireFullName)
                    "
                    class="required-span"
                    >*</span
                  >
                  {{ fieldData.fullNameLabel[selectedLanguage] }}
                </label>
                <b-form-input
                  :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                  :disabled="isDisabled == 1"
                  v-model="v$.form.fullName.$model"
                  :placeholder="
                    fieldData.fullNamePlaceholderText[selectedLanguage]
                  "
                  :state="
                    v$.form && v$.form.fullName.$dirty
                      ? !v$.form.fullName.$error
                      : null
                  "
                  type="text"
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="col-md-6"
              v-show="
                !fieldData.advance || !fieldData.advance.dateAppointedDisabled
              "
            >
              <b-form-group
                :description="
                  fieldData.dateAppointedHelperText[selectedLanguage]
                "
              >
                <label>
                  <span
                    v-if="
                      fieldData.validation &&
                      JSON.parse(fieldData.validation.isRequireDateAppointed)
                    "
                    class="required-span"
                    >*</span
                  >
                  {{ fieldData.dateAppointedLabel[selectedLanguage] }}
                </label>
                <b-input-group
                  :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                >
                  <Datepicker
                    :disabled="isDisabled == 1"
                    input-class="custom-date-picker"
                    calendar-class="date-calender"
                    class="form-control"
                    :class="
                      v$.form.dateAppointed.$error
                        ? 'border border-danger rounded'
                        : null
                    "
                    v-model="v$.form.dateAppointed.$model"
                    :format="customDateFormat"
                    :disabled-dates="disabledDates"
                    :state="
                      v$.form && v$.form.dateAppointed.$dirty
                        ? !v$.form.dateAppointed.$error
                        : null
                    "
                  ></Datepicker>
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </b-input-group>
                <label
                  v-if="
                    v$.form.dateAppointed.$error &&
                    fieldData.validation.isRequireDateAppointed &&
                    !form.dateAppointed
                  "
                  class="text-danger"
                  style="font-size: 80%"
                  >{{
                    fieldData.dateAppointedValidationMessage[selectedLanguage]
                  }}</label
                >
              </b-form-group>
            </div>
          </div>

          <div class="form-row">
            <div
              class="col-md-6"
              v-show="
                !fieldData.advance || !fieldData.advance.officerRoleDisabled
              "
            >
              <b-form-group
                :description="fieldData.officerRoleHelperText[selectedLanguage]"
                :invalid-feedback="
                  fieldData.officerRoleValidationMessage[selectedLanguage]
                "
              >
                <label>
                  <span
                    v-if="
                      fieldData.validation &&
                      JSON.parse(fieldData.validation.isRequireOfficerRole)
                    "
                    class="required-span"
                    >*</span
                  >
                  {{ fieldData.officerRoleLabel[selectedLanguage] }}
                </label>
                <b-form-input
                  :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                  :disabled="isDisabled == 1"
                  v-model="v$.form.officerRole.$model"
                  :placeholder="
                    fieldData.officerRolePlaceholderText[selectedLanguage]
                  "
                  :state="
                    v$.form && v$.form.officerRole.$dirty
                      ? !v$.form.officerRole.$error
                      : null
                  "
                  type="text"
                ></b-form-input>
              </b-form-group>
            </div>

            <div
              class="col-md-6"
              v-show="!fieldData.advance || !fieldData.advance.freeFormDisabled"
            >
              <div v-if="fieldData.freeFormLabel">
                <b-form-group
                  :description="fieldData.freeFormHelperText[selectedLanguage]"
                  :invalid-feedback="
                    fieldData.freeFormValidationMessage[selectedLanguage]
                  "
                >
                  <label>
                    <span
                      v-if="
                        fieldData.validation &&
                        JSON.parse(fieldData.validation.isRequireFreeForm)
                      "
                      class="required-span"
                      >*</span
                    >
                    {{ fieldData.freeFormLabel[selectedLanguage] }}
                  </label>
                  <b-form-input
                    :class="
                      $store.state.rightAlign ? 'text-right' : 'text-left'
                    "
                    :disabled="isDisabled == 1"
                    v-model="v$.form.freeForm.$model"
                    :placeholder="
                      fieldData.freeFormPlaceholderText[selectedLanguage]
                    "
                    :state="
                      v$.form && v$.form.freeForm.$dirty
                        ? !v$.form.freeForm.$error
                        : null
                    "
                    type="text"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <template v-slot:modal-footer>
          <b-button variant="danger" @click="resetModal">{{
            $t("closeBtnText")
          }}</b-button>
          <b-button
            variant="success"
            @click="isUpdtating ? updateRow() : addRow()"
            >{{ isUpdtating ? $t("updateBtn") : $t("saveBtnText") }}</b-button
          >
        </template>
      </b-modal>

      <b-row v-if="taskStatus != 4">
        <b-col cols="12">
          <b-button
            :disabled="isDisabled == 1"
            class="mr-2 mt-2"
            variant="outline-success"
            size="lg"
            @click="showAddRowModal = !showAddRowModal"
          >
            <font-awesome-icon icon="plus" />
            {{ $t("workflow.add") }}
          </b-button>
        </b-col>
        <b-col cols="12">
          <label v-if="isValidationRequired(fieldData)" class="text-danger">{{
            $t("createProfile.requiredValidationMessage")
          }}</label>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col cols="12" class="p-5">
          <b-table-simple striped hover>
            <b-thead>
              <b-tr>
                <b-th
                  v-if="
                    !fieldData.advance || !fieldData.advance.fullNameDisabled
                  "
                  >{{ fieldData.fullNameLabel[selectedLanguage] }}</b-th
                >
                <b-th
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.dateAppointedDisabled
                  "
                  >{{ fieldData.dateAppointedLabel[selectedLanguage] }}</b-th
                >
                <b-th
                  v-if="
                    !fieldData.advance || !fieldData.advance.officerRoleDisabled
                  "
                  >{{ fieldData.officerRoleLabel[selectedLanguage] }}</b-th
                >
                <b-th
                  v-if="
                    !fieldData.advance || !fieldData.advance.freeFormDisabled
                  "
                  >{{
                    fieldData.freeFormLabel &&
                    fieldData.freeFormLabel[selectedLanguage]
                  }}</b-th
                >
                <b-th>{{ $t("upload.fileAction") }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-for="(item, index) in items" :key="index">
              <b-tr>
                <b-td
                  v-if="
                    !fieldData.advance || !fieldData.advance.fullNameDisabled
                  "
                  >{{ item.name }}</b-td
                >
                <b-td
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.dateAppointedDisabled
                  "
                  >{{ format_date(item.date_appointed) }}</b-td
                >
                <b-td
                  v-if="
                    !fieldData.advance || !fieldData.advance.officerRoleDisabled
                  "
                  >{{ item.officer_role }}</b-td
                >
                <b-td
                  v-if="
                    !fieldData.advance || !fieldData.advance.freeFormDisabled
                  "
                  >{{ item.free_form }}</b-td
                >
                <b-td>
                  <b-button
                    @click="onEditRow(item, index)"
                    size="sm"
                    class="mr-1"
                  >
                    <font-awesome-icon
                      :icon="taskStatus != 4 ? 'edit' : 'eye'"
                    />
                  </b-button>
                  <b-button
                    @click="onDeleteRow(index)"
                    variant="danger"
                    size="sm"
                    v-if="taskStatus != 4"
                  >
                    <font-awesome-icon icon="trash" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>

      <!-- show advance field options -->
      <b-modal
        cancel-title="Close"
        ok-title="save"
        @ok="onSaveData"
        size="xl"
        v-model="showSettings"
        scrollable
        title="Advanced Settings"
      >
        <AdvanceSettings
          :fieldData="fieldData"
          v-on:toggle-field="showSettings = !showSettings"
          :sectionIndex="sectionIndex"
          :fieldIndex="fieldIndex"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import MenuButtons from "@/components/menuButtons";
import AdvanceSettings from "@/components/workflowSettings/advanceCompanyOfficersSettings";
import { Helper } from "@/helpers";
import { useVuelidate } from "@vuelidate/core";
import { EventBus } from "@/event-bus.js";
import { required } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import moment from "moment";
import "moment/locale/es";
import Datepicker from "vuejs-datepicker";
import { routeKeys, routeNames } from "@/constants";
import { clone } from "lodash";

export default {
  name: "CompanyOfficersGroup",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {
    MenuButtons,
    AdvanceSettings,
    Datepicker
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage"])
  },
  data() {
    return {
      showFreeForm: false,
      showAddRowModal: false,
      items: [],
      isHidden: false,
      condition: {},
      title: null,
      form: {
        fullName: null,
        officerRole: null,
        dateAppointed: null,
        freeForm: null
      },
      label: null,
      showSettings: false,
      collapse: true,
      isUpdtating: false,
      selectedRowIndex: null,
      postData: [],
      taskStatus: 0,
      disabledDates: {
        to:
          this.fieldData.advanceDate &&
          this.fieldData.advanceDate.disabledMinDateInput
            ? new Date(Date.now() - 864e5)
            : this.fieldData.advanceDate && this.fieldData.advanceDate.minDate
            ? new Date(this.fieldData.advanceDate.minDate)
            : "", // Disable all dates up to specific date
        from:
          this.fieldData.advanceDate &&
          this.fieldData.advanceDate.disabledMaxDateInput
            ? new Date()
            : this.fieldData.advanceDate && this.fieldData.advanceDate.maxDate
            ? new Date(this.fieldData.advanceDate.maxDate)
            : "" // Disable all dates after specific date
      }
    };
  },

  watch: {
    selectedLanguage(newLanguage) {
      this.updateLabels(newLanguage);
    }
  },

  created() {
    EventBus.on("onSubmitEndUserForm", (value) => {
      if (value == "progress") {
        this.setDataToUser();
      } else {
        if (this.items && this.items.length > 0) {
          this.setDataToUser();
          this.$store.state.validFields = true;
          this.fieldData.isInValid = false;
        } else {
          this.$store.state.validFields = false;
          this.fieldData.isInValid = true;
        }
      }
    });

    EventBus.on("OnInvalidFieldCatch", (inValidData) => {
      document
        .getElementById(inValidData.elementId)
        .scrollIntoView({ behavior: "smooth" });
    });
  },

  mounted() {
    this.setFreeFormFields();
    this.setNewConditions();
    if (this.$route.name === routeNames[routeKeys.USER_FORM_PAGE]) {
      this.getDataForUser();
    }
    let st = parseInt(localStorage.getItem("activeTaskStatus"));
    this.taskStatus = st;
  },

  validations() {
    return {
      form: {
        fullName: {
          [this.fullName]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireFullName
              ? required
              : !required
        },
        officerRole: {
          [this.officerRole]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireOfficerRole
              ? required
              : !required
        },
        dateAppointed: {
          [this.dateAppointed]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireDateAppointed
              ? required
              : !required
        },
        freeForm: {
          [this.freeForm]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireFreeForm
              ? required
              : !required
        }
      }
    };
  },

  methods: {
    isValidationRequired(fieldData) {
      let condition = Object.values(fieldData.validation);
      return !!condition.includes(true) && fieldData.isInValid;
    },
    setFreeFormFields() {
      if (this.fieldData.freeFormLabel == undefined) {
        Object.assign(this.fieldData, {
          freeFormLabel: {
            english: "Free Form"
          },
          freeFormPlaceholderText: {
            english: ""
          },
          freeFormHelperText: {
            english: ""
          },
          freeFormValidationMessage: {
            english: "This field is required"
          }
        });
        let validation = {
          ...this.fieldData.validation,
          isRequireFreeForm:
            this.fieldData.validation.isRequireFreeForm || false
        };
        Object.assign(this.fieldData, { validation });
        let advance = {
          ...this.fieldData.advance,
          freeFormDisabled: this.fieldData.advance.freeFormDisabled || true
        };
        Object.assign(this.fieldData, { advance });
        // this.showFreeForm = true;
      }
    },

    setNewConditions() {
      Object.assign(this.fieldData, {
        isInValid: this.fieldData.isInValid || null
      });
      Object.assign(this.fieldData, {
        isHidden: this.fieldData.isHidden || false
      });
      Object.assign(this.fieldData, {
        isConditional: this.fieldData.isConditional || 0
      });
      Object.assign(this.fieldData, {
        conditions:
          this.fieldData.conditions && this.fieldData.conditions.length
            ? this.fieldData.conditions
            : []
      });
      Object.assign(this.fieldData, {
        conditionalFields:
          this.fieldData.conditionalFields &&
          this.fieldData.conditionalFields.length
            ? this.fieldData.conditionalFields
            : []
      });
      Object.assign(this.fieldData, {
        sectionConditions:
          this.fieldData.sectionConditions &&
          this.fieldData.sectionConditions.length
            ? this.fieldData.sectionConditions
            : []
      });
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },

    customDateFormat(value) {
      let customFormat = this.fieldData.format;
      if (customFormat == "default") {
        return moment(value).format("DD-MM-YYYY");
      } else {
        return moment(value).locale("en").format(customFormat);
      }
    },

    addRow() {
      const registerData = clone(this.$store.state.registerData);
      this.v$.form.$touch();
      if (this.v$.form.$anyError && this.v$.form.$invalid) {
        return false;
      }
      registerData[this.fieldData.id] = "company-officers";
      this.$store.state.registerData = registerData;

      // main calculations :p
      const numkeys = Object.keys(registerData).length;
      // const sectionLength = this.form1.formSection.length > 1 ? this.form1.formSection[this.sectionIndex].formElements.length : 1
      this.$store.state.progressValue = numkeys;

      let row = {
        name: this.form.fullName,
        date_appointed: this.form.dateAppointed,
        officer_role: this.form.officerRole,
        free_form: this.form.freeForm,
        actions: "&nbsp;"
      };

      if (this.$route.name === routeNames[routeKeys.USER_FORM_PAGE]) {
        let aa = [
          {
            postValue: row.name,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.fullNamePostName) ||
              "fullNamePostName",
            key: "name",
            labelKey: "fullNameLabel",
            label: this.fieldData.fullNameLabel[this.selectedLanguage]
          },
          {
            postValue: row.date_appointed,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.dateAppointedPostName) ||
              "dateAppointedPostName",
            key: "date_appointed",
            labelKey: "dateAppointedLabel",
            label: this.fieldData.dateAppointedLabel[this.selectedLanguage]
          },
          {
            postValue: row.officer_role,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.officerRolePostName) ||
              "officerRolePostName",
            key: "officer_role",
            labelKey: "officerRoleLabel",
            label: this.fieldData.officerRoleLabel[this.selectedLanguage]
          },
          {
            postValue: row.free_form,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.freeFormPostName) ||
              "freeFormPostName",
            key: "free_form",
            labelKey: "freeFormLabel",
            label: this.fieldData.freeFormLabel[this.selectedLanguage]
          }
        ];
        this.postData.push(aa);
        this.items.push(row);
      }

      this.resetModal();
    },

    updateLabels(newLanguage) {
      this.postData[0].forEach((data) => {
        data.label = this.fieldData?.[data.labelKey]?.[newLanguage];
      });
    },

    getDataForUser() {
      this.$store.state.getUserFormSubmitArray.map((data) => {
        if (data.elements && data.elements.length > 0) {
          data.elements.map((element) => {
            if (data.elementId === this.fieldData.id) {
              let row = {
                name: element[element.findIndex((x) => x.key == "name")]
                  .postValue,
                date_appointed:
                  element[element.findIndex((x) => x.key == "date_appointed")]
                    .postValue,
                officer_role:
                  element[element.findIndex((x) => x.key == "officer_role")]
                    .postValue,
                free_form:
                  element[element.findIndex((x) => x.key === "free_form")]
                    .postValue,
                actions: "&nbsp;"
              };
              this.items.push(row);
              this.postData = data.elements;
            }
          });
        }
      });
    },

    setDataToUser() {
      if (this.$route.name === routeNames[routeKeys.USER_FORM_PAGE]) {
        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId === this.fieldData.id) {
            a.elements = this.postData;
          }
        });
      }
    },

    resetModal() {
      (this.form.fullName = null),
        (this.form.dateAppointed = null),
        (this.form.officerRole = null),
        (this.form.freeForm = null);
      this.v$.$reset();
      this.showAddRowModal = false;
      this.isUpdtating = false;
    },

    updateRow() {
      this.v$.form.$touch();
      if (this.v$.form.$anyError && this.v$.form.$invalid) {
        return false;
      }
      this.items[this.selectedRowIndex].name = this.form.fullName;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex((x) => x.key == "name")
      ].postValue = this.form.fullName;

      this.items[this.selectedRowIndex].date_appointed =
        this.form.dateAppointed;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "date_appointed"
        )
      ].postValue = this.form.dateAppointed;

      this.items[this.selectedRowIndex].officer_role = this.form.officerRole;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "officer_role"
        )
      ].postValue = this.form.officerRole;

      this.items[this.selectedRowIndex].free_form = this.form.freeForm;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "free_form"
        )
      ].postValue = this.form.freeForm;

      this.resetModal();
    },

    onDeleteRow(index) {
      const registerData = clone(this.$store.state.registerData);
      if (this.items.length == 1 && this.postData.length == 1) {
        delete registerData[this.fieldData.id];
        this.$store.state.registerData = registerData;

        // main calculations :p
        const numkeys = Object.keys(registerData).length;
        // const sectionLength = this.form1.formSection.length > 1 ? this.form1.formSection[this.sectionIndex].formElements.length : 1
        this.$store.state.progressValue = numkeys;
      }
      this.items.splice(index, 1);
      this.postData.splice(index, 1);
    },

    onEditRow(item, index) {
      this.isUpdtating = true;
      this.showAddRowModal = true;
      this.selectedRowIndex = index;
      this.form = {
        fullName: item.name,
        dateAppointed: item.date_appointed,
        officerRole: item.officer_role,
        freeForm: item.free_form
      };
    },

    setValidation() {
      let validation = {
        isRequireFullName: true,
        isRequireDateAppointed: true,
        isRequireOfficerRole: true
      };
      Object.assign(this.fieldData, { validation });
    },

    onSaveData() {
      EventBus.emit("onSaveSettings", "saving settings");
      Helper.makeToast(this.$bvToast, "success", "Settings updated");
    }
  },
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>

<style>
.custom-date-picker {
  width: 100%;
  border: none;
}
.custom-date-picker:focus {
  border: none;
}
.date-calender {
  width: 350px !important;
}
</style>
