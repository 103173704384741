import { breadcrumbRouteNames, routeNames, urls, templates } from "@/constants";
import RolesTemplate from "@/templates/RolesTemplate/RolesTemplate.vue";
import Roles from "@/organisms/Roles/Roles.vue";
import AddEditRole from "@/organisms/AddEditRole/AddEditRole.vue";

export default {
  path: urls.ROLES,
  name: routeNames.ROLES_WRAPPER,
  component: RolesTemplate,
  children: [
    {
      path: "",
      name: routeNames.ROLES,
      component: Roles,
      meta: {
        requiresAuth: true,
        template: templates.EB360,
        isEb360HeaderRequired: true,
        isEB360SideBarRequired: true,
        breadcrumbs: () => [
          { name: breadcrumbRouteNames.HOME, link: urls.HOME },
          { name: breadcrumbRouteNames.ROLES, link: urls.ROLES }
        ]
      }
    },
    {
      path: urls.EDIT_ROLE,
      name: routeNames.EDIT_ROLE,
      redirect: () => urls.ROLES,
      meta: {
        requiresAuth: true,
        template: templates.EB360,
        isEb360HeaderRequired: true,
        isEB360SideBarRequired: true
      }
    },
    {
      path: urls.EDIT_ROLE_ID(),
      name: routeNames.EDIT_ROLE_ID,
      component: AddEditRole,
      meta: {
        requiresAuth: true,
        template: templates.EB360,
        isEb360HeaderRequired: true,
        isEB360SideBarRequired: true,
        breadcrumbs: () => [
          { name: breadcrumbRouteNames.HOME, link: urls.HOME },
          { name: breadcrumbRouteNames.ROLES, link: urls.ROLES },
          {
            name: breadcrumbRouteNames.EDIT_ROLE,
            link: urls.EDIT_ROLE
          }
        ]
      }
    },
    {
      path: urls.ADD_ROLE,
      name: routeNames.ADD_ROLE,
      component: AddEditRole,
      meta: {
        requiresAuth: true,
        template: templates.EB360,
        isEb360HeaderRequired: true,
        isEB360SideBarRequired: true,
        breadcrumbs: () => [
          { name: breadcrumbRouteNames.HOME, link: urls.HOME },
          { name: breadcrumbRouteNames.ROLES, link: urls.ROLES },
          {
            name: breadcrumbRouteNames.ADD_ROLE,
            link: urls.ADD_ROLE
          }
        ]
      }
    }
  ]
};
