<template>
  <a
    v-bind="anchorOptions"
    :class="['link', `link--theme-${getTextOptions.theme}`]"
  >
    <BaseIcon
      v-if="hasIcon"
      v-bind="iconOptions"
      class="link__icon"
      data-test-id="link-icon"
    />
    <BaseText
      v-if="hasText"
      v-bind="getTextOptions"
      class="link__text"
      data-test-id="link-text"
    />
  </a>
</template>

<script>
import { themes } from "@/constants";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import BaseText from "@/atoms/BaseText/BaseText";

export default {
  name: "Link",
  components: { BaseText, BaseIcon },
  props: {
    anchorOptions: {
      type: Object,
      default: () => ({})
    },
    iconOptions: {
      type: Object,
      default: () => ({})
    },
    textOptions: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    hasIcon() {
      return !!Object.keys(this.iconOptions)?.length;
    },
    hasText() {
      return !!this.textOptions?.text;
    },
    getTextOptions() {
      return {
        theme: themes.NONE,
        tag: "span",
        ...this.textOptions
      };
    }
  }
};
</script>

<style scoped lang="scss">
.link {
  display: inline-flex;
  align-items: center;
  gap: 5px;

  &__icon,
  &__text {
    display: flex;
  }

  @each $theme, $primary-color, $secondary-color in $themes {
    &--theme-#{$theme} {
      color: $secondary-color;
    }
  }
}
</style>
