import { emptyOption } from "@/constants";

export const getters = {
  getLanguageOptions: (state) => [
    {
      ...emptyOption,
      text: "Contact language"
    },
    ...state.languages.map((language) => ({
      text: language.text,
      value: language.value
    }))
  ]
};
