<template>
  <div class="task">
    <BaseLoader v-if="isLoadingTask" />
    <template v-else>
      <InputErrorMessage v-if="errorMessages.length" :error="errorMessages" />
      <div class="task__wrapper-list">
        <BaseText
          class="task__heading"
          data-test-id="task__heading"
          :size="typographySize.HEADING_TWO"
          tag="h2"
          :text="task.subject"
        />
        <ul class="task__list">
          <li
            v-for="({ name, description }, index) in getTaskDetails"
            :key="index"
            class="task__list-element"
          >
            <BaseText
              data-test-id="task__list-element-name"
              :size="typographySize.BODY_TEXT_BOLD"
              :text="`${name}:`"
            />
            <BaseText
              class="task__list-element-description"
              data-test-id="task__list-element-description"
              :size="typographySize.BODY_TEXT"
              :text="description"
            />
          </li>
        </ul>
      </div>
      <div class="task__wrapper">
        <Select
          class="task__assignee"
          data-test-id="task__assignee"
          label="Assignee"
          placeholder="Assignee"
          name="assignee"
          id="assignee"
          :isDisabled="isTaskClosed"
          :options="taskInstance.assigneeOptions"
          :error="taskInstance.assigneeError"
          @change="onAssigneeChange"
        />
        <Select
          class="task__actions"
          data-test-id="task__actions"
          label="Actions"
          placeholder="Actions"
          name="actions"
          id="actions"
          :isDisabled="isTaskClosed"
          :options="taskInstance.actionsOptions"
          :error="taskInstance.actionsError"
          @change="onChangeSelectedAction"
        />
        <BaseText
          v-if="taskInstance.actionInformation"
          data-test-id="task__actions-information"
          :text="taskInstance.actionInformation"
          :theme="themes.SUCCESS_INVERSE"
        />
        <div
          v-if="taskInstance.dynamicInputsBasedOnActionUserRequest.length"
          class="task__request-from-user-inputs"
        >
          <component
            v-for="{
              component,
              id,
              componentOptions
            } in taskInstance.dynamicInputsBasedOnActionUserRequest"
            v-bind="componentOptions"
            :key="id"
            :is="component"
          />
        </div>
      </div>
      <div class="task__form-cta-holder">
        <BaseFooter>
          <template #left>
            <CallToAction
              type="button"
              value="Go to task list"
              data-test-id="task__form-cta--back"
              @click="onGoToTaskList"
            />
          </template>
          <template #right>
            <template v-if="isTaskClosed">
              <CallToAction
                class="task__form-cta"
                type="button"
                value="Reopen task"
                :is-loading="isSavingAction"
                :is-error="hasActionError"
                :is-success="isSavingSuccessful"
                :isDisabled="!getIsTaskReopenAllowed"
                data-test-id="task__form-cta--complete-task"
                @click="onTaskReopen"
              />
            </template>
            <template v-else>
              <CallToAction
                class="task__form-cta"
                type="button"
                value="Update task"
                :isDisabled="!getIsTaskEditAllowed"
                :is-loading="isSavingAction"
                :is-error="hasActionError"
                :is-success="isSavingSuccessful"
                data-test-id="task__form-cta--save-updates"
                @click="onUpdateTaskWrapper"
              />
              <CallToAction
                class="task__form-cta"
                type="button"
                value="Complete task"
                :is-loading="isSavingAction"
                :is-error="hasActionError"
                :is-success="isSavingSuccessful"
                :isDisabled="isTaskCompleteBlocked"
                data-test-id="task__form-cta--complete-task"
                @click="onTaskComplete"
              />
            </template>
          </template>
        </BaseFooter>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import {
  actionName,
  mutationName,
  typographySize,
  getterName,
  taskKeyMapper,
  tasksKeysDTO,
  emptyOption,
  taskTemplatesExpectedDataTriggerType,
  variableType,
  inputType,
  themes,
  operations,
  urls,
  taskStateValue,
  variableAssignmentType,
  validity
} from "@/constants";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import BaseText from "@/atoms/BaseText/BaseText";
import Select from "@/molecules/Select/Select";
import {
  makeDateToMatchFormat,
  makeUUID,
  isCustomAttributeStringList,
  isCustomAttributeMultiSelectStringList,
  isExpectedDataCountryPrimitiveType,
  isCustomAttributeRiskScale,
  getAllSelectedOptionValues,
  getSelectedOption
} from "@/utils";
import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";
import { getSelectedValuesFromDestinationKey } from "@/organisms/AddEditUserEmailTemplate/AddEditUserEmailTemplate.logic";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import Input from "@/molecules/Input/Input";
import MultiSelect from "@/molecules/MultiSelect/MultiSelect";
import BaseFooter from "@/atoms/BaseFooter/BaseFooter";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import { makeOptionsForMultiSelect } from "@/molecules/MultiSelect/MultiSelect.dto";
import { isEqual } from "lodash";
import { validateTask, validateTaskPartially } from "./Task.validation";

export default {
  name: "Task",
  data() {
    return {
      typographySize,
      themes,
      operations,
      urls,
      variableAssignmentType,
      taskKeyMapper,
      errorMessages: [],
      isSavingAction: false,
      hasActionError: false,
      isSavingSuccessful: false,
      isTaskCompleteBlocked: true,
      taskInstance: this.makeTaskInstance()
    };
  },
  components: {
    BaseLoader,
    BaseText,
    Select,
    InputErrorMessage,
    BaseFooter,
    CallToAction
  },
  computed: {
    ...mapState({
      task: (state) => state.tasks.task,
      isLoadingTask: (state) => state.tasks.isLoadingTask
    }),
    ...mapGetters({
      getTaskTemplateBasedById:
        getterName.TASK_TEMPLATES.GET_TASK_TEMPLATE_BASED_BY_ID,
      getDestinationBaseOptions:
        getterName.USER_EMAIL_TEMPLATES.GET_DESTINATION_BASE_OPTIONS,
      getActionOptions: getterName.ACTIONS.GET_ACTION_OPTIONS,
      getActionBasedOnId: getterName.ACTIONS.GET_ACTION_BASED_ON_ID,
      getExpectedDataMapOptions:
        getterName.EXPECTED_DATA.GET_EXPECTED_DATA_MAP_OPTIONS,
      getIsTaskReopenAllowed: getterName.TASKS.GET_IS_TASK_REOPEN_ALLOWED,
      getIsTaskEditAllowed: getterName.TASKS.GET_IS_TASK_EDIT_ALLOWED,
      getIsTaskCompleteAllowed: getterName.TASKS.GET_IS_TASK_COMPLETE_ALLOWED
    }),
    getTaskDetails() {
      return Object.keys(this.taskKeyMapper).reduce((acc, key) => {
        if (key === tasksKeysDTO.TEMPLATE_ID) {
          acc.push({
            name: this.taskKeyMapper[key],
            description: this.getTaskTemplateBasedById(this.task[key])
              ?.templateName
          });
        } else if (
          key === tasksKeysDTO.CREATED_AT ||
          key === tasksKeysDTO.UPDATED_AT
        ) {
          acc.push({
            name: this.taskKeyMapper[key],
            description: this.makeDateToMatchFormat({
              stringifiedDate: this.task[key]
            })
          });
        } else {
          acc.push({
            name: this.taskKeyMapper[key],
            description: this.task[key]
          });
        }
        return acc;
      }, []);
    },
    isTaskClosed() {
      return this.task.status === taskStateValue.CLOSED;
    }
  },
  async created() {
    await this.fetchTaskWrapper(this.$route?.params?.id);
  },
  beforeRouteLeave(_, __, next) {
    this.resetTask();
    next();
  },
  methods: {
    makeDateToMatchFormat,
    makeOptionsForSelect,
    getSelectedValuesFromDestinationKey,
    makeUUID,
    isCustomAttributeStringList,
    isCustomAttributeMultiSelectStringList,
    isExpectedDataCountryPrimitiveType,
    isCustomAttributeRiskScale,
    makeOptionsForMultiSelect,
    getAllSelectedOptionValues,
    getSelectedOption,
    validateTask,
    validateTaskPartially,
    isEqual,
    ...mapActions({
      fetchTask: actionName.TASKS.FETCH_TASK,
      reopenTask: actionName.TASKS.REOPEN_TASK,
      updateTask: actionName.TASKS.UPDATE_TASK,
      completeTask: actionName.TASKS.COMPLETE_TASK
    }),
    ...mapMutations({
      resetTask: mutationName.TASKS.RESET_TASK
    }),
    makeTaskInstance() {
      return {
        assigneeOptions: [],
        assigneeError: "",
        actionsOptions: [],
        actionsError: "",
        outcomesData: {},
        dynamicInputsBasedOnActionUserRequest: [],
        actionInformation: ""
      };
    },
    getValueForAPI({ valueDataType, value }) {
      if (
        valueDataType === variableType.NUMERIC ||
        valueDataType === variableType.DATE_TIME
      ) {
        const parsedValue = Number.parseFloat(value);
        return isNaN(parsedValue) ? value : parsedValue;
      } else if (valueDataType === variableType.BOOLEAN) {
        switch (value) {
          case validity.TRUE:
            return true;
          case validity.FALSE:
            return false;
          default:
            return value;
        }
      } else {
        return value;
      }
    },
    onGoToTaskList() {
      this.$router.push(urls.TASKS);
    },
    async onTaskReopen() {
      try {
        this.resetErrorMessages();
        await this.reopenTask(this.task.id);
        this.taskInstance = this.makeTaskInstance();
        this.setTaskInstance();
      } catch {
        this.errorMessages.push("Failed to reopen the task");
      }
    },
    onUpdateTaskWrapper() {
      if (this.validateTaskPartially({ taskInstance: this.taskInstance })) {
        this.onUpdateTask();
      }
    },
    async onUpdateTask() {
      try {
        this.setOutcomeData();
        this.resetErrorMessages();
        await this.updateTask(this.taskInstance);
        this.taskInstance = this.makeTaskInstance();
        this.setTaskInstance();
      } catch {
        this.errorMessages.push("Failed to update the task");
      }
    },
    onTaskCompleteWrapper() {
      if (this.validateTask({ taskInstance: this.taskInstance })) {
        this.onTaskComplete();
      }
    },
    async onTaskComplete() {
      try {
        this.setOutcomeData();
        this.resetErrorMessages();
        await this.completeTask(this.taskInstance);
        this.taskInstance = this.makeTaskInstance();
        this.setTaskInstance();
      } catch {
        this.errorMessages.push("Failed to complete the task");
      }
    },
    resetErrorMessages() {
      this.errorMessages = [];
    },
    async fetchTaskWrapper(taskId = "") {
      try {
        this.resetErrorMessages();
        await this.fetchTask(taskId);
        this.setTaskInstance();
      } catch {
        this.errorMessages.push("Failed to fetch the requested task");
      }
    },
    onAssigneeChange(value) {
      this.taskInstance.assigneeError = "";
      this.taskInstance.assigneeOptions = this.makeOptionsForSelect(
        value,
        this.getDestinationBaseOptions
      );
    },
    makeActionsOptions() {
      const availableActionsId = this.task.outcomeActions.map(
        ({ outcome }) => outcome.actionId
      );
      const availableOptions = this.getActionOptions.filter?.(({ value }) =>
        availableActionsId.includes(value)
      );
      return this.makeOptionsForSelect(this.task.selectedOutcomeId, [
        {
          ...emptyOption,
          text: "Select action"
        },
        ...availableOptions
      ]);
    },
    setTaskInstance() {
      const defaultAssigneeValue =
        this.getSelectedValuesFromDestinationKey([this.task.assignee])
          ?.selectedValues[0] || "";
      this.taskInstance.assigneeOptions = this.makeOptionsForSelect(
        defaultAssigneeValue,
        this.getDestinationBaseOptions
      );
      this.taskInstance.assigneeError = "";
      this.taskInstance.actionsOptions = this.makeActionsOptions();
      this.taskInstance.actionsError = "";
      this.taskInstance.dynamicInputsBasedOnActionUserRequest =
        this.makeDynamicInputsBasedOnActionUserRequest(
          this.task.selectedOutcomeId
        );
    },
    onChangeSelectedAction(actionId) {
      this.setOutcomeData();
      this.taskInstance.actionsError = "";
      this.taskInstance.actionsOptions = this.makeOptionsForSelect(
        actionId,
        this.taskInstance.actionsOptions
      );
      this.setDynamicInputsBasedOnActionUserRequest(actionId);
    },
    setDynamicInputsBasedOnActionUserRequest(actionId) {
      if (this.taskInstance.outcomesData[actionId]) {
        this.taskInstance.dynamicInputsBasedOnActionUserRequest =
          this.taskInstance.outcomesData[actionId];
      } else {
        this.taskInstance.dynamicInputsBasedOnActionUserRequest =
          this.makeDynamicInputsBasedOnActionUserRequest(actionId);
      }
    },
    setOutcomeData() {
      const { value: selectedAction = "" } = this.getSelectedOption(
        this.taskInstance.actionsOptions
      );
      if (selectedAction) {
        this.taskInstance.outcomesData[selectedAction] =
          this.taskInstance.dynamicInputsBasedOnActionUserRequest;
      }
    },
    isTaskInstanceReferentialIntegrity(actionId) {
      const { outcomeActions: taskTemplateOutcomeActions = [] } =
        this.getTaskTemplateBasedById(this.task.templateId);
      const taskTemplateOutcomeAction = taskTemplateOutcomeActions.find(
        ({ outcome }) => outcome.actionId === actionId
      );
      const taskOutcomeData = this.task.outcomeActions?.find(
        ({ outcome }) => outcome.actionId === actionId
      );
      return this.isEqual(taskTemplateOutcomeAction, taskOutcomeData);
    },
    setTaskInstanceReferentialIntegrity(actionId = "") {
      if (actionId && !this.isTaskInstanceReferentialIntegrity(actionId)) {
        this.taskInstance.actionInformation =
          "The outcome you have selected is no longer valid because the underlying Actions expected data has been changed. You need to select a different outcome whose action has not been modified or delete this task.";
      } else {
        this.taskInstance.actionInformation = "";
      }
    },
    makeDynamicInputsBasedOnActionUserRequest(actionId = "") {
      this.isTaskCompleteBlocked = !this.getIsTaskCompleteAllowed(actionId);
      this.setTaskInstanceReferentialIntegrity(actionId);
      const { variables: selectedActionExpectedData = [] } =
        this.getActionBasedOnId(actionId)?.expectedDataDefinition || {};
      const { outcome = {} } =
        this.task.outcomeActions?.find(
          ({ outcome }) => outcome.actionId === actionId
        ) || {};
      const { expectedDataMapping: outcomeExpectedDataMapping = [] } =
        this.task?.outcomesData?.find?.(
          (outcomeData) => outcomeData?.actionId === actionId
        ) || {};
      return (
        outcome?.expectedDataMapping
          ?.filter?.(
            ({ triggeringData }) =>
              triggeringData.type ===
              taskTemplatesExpectedDataTriggerType.USER_INPUT
          )
          ?.reduce?.((acc, outcomeExpectedData, index) => {
            const actionExpectedData = selectedActionExpectedData?.find(
              ({ name }) =>
                name === outcomeExpectedData?.triggeredExpectedDataVariableName
            );
            const { triggeringData = {} } =
              outcomeExpectedDataMapping?.find?.(
                ({ triggeredExpectedDataVariableName }) =>
                  triggeredExpectedDataVariableName === actionExpectedData?.name
              ) || {};
            acc.push(
              this.makeRequestFormUserInput({
                actionExpectedData,
                outcomeExpectedData,
                value: triggeringData?.data?.data?.value,
                index
              })
            );
            return acc;
          }, []) || []
      );
    },
    makeRequestFormUserInput({
      actionExpectedData = {},
      outcomeExpectedData = {},
      value = "",
      index = 0
    } = {}) {
      if (actionExpectedData.type === variableType.STRING) {
        return this.makeInputForStringType({
          actionExpectedData,
          outcomeExpectedData,
          value,
          index
        });
      }
      if (
        actionExpectedData.type === variableType.NUMERIC ||
        actionExpectedData.type === variableType.DATE_TIME
      ) {
        return this.makeInputForNumericType({
          actionExpectedData,
          outcomeExpectedData,
          index,
          value
        });
      }
      if (actionExpectedData.type === variableType.BOOLEAN) {
        return this.makeSelectInput({
          label: outcomeExpectedData.triggeringData.message,
          name: outcomeExpectedData.triggeredExpectedDataVariableName,
          actionExpectedData,
          options: this.getExpectedDataMapOptions[actionExpectedData?.type],
          error: "",
          index,
          value
        });
      }
    },
    makeInputForArrayStringType({
      actionExpectedData = {},
      outcomeExpectedData = {},
      value = [],
      index = 0
    } = {}) {
      if (
        this.isCustomAttributeMultiSelectStringList(
          actionExpectedData?.subType
        ) ||
        this.isExpectedDataCountryPrimitiveType(actionExpectedData?.subType)
      ) {
        return this.makeMultiSelectInput({
          label: outcomeExpectedData.triggeringData.message,
          name: outcomeExpectedData.triggeredExpectedDataVariableName,
          actionExpectedData,
          options: this.getExpectedDataMapOptions[actionExpectedData?.subType],
          error: "",
          index,
          selectedValues: value
        });
      }
    },
    makeInputForNonArrayStringType({
      actionExpectedData = {},
      outcomeExpectedData = {},
      index = 0,
      value = ""
    } = {}) {
      if (
        this.isCustomAttributeStringList(actionExpectedData?.subType) ||
        this.isExpectedDataCountryPrimitiveType(actionExpectedData?.subType)
      ) {
        return this.makeSelectInput({
          label: outcomeExpectedData.triggeringData.message,
          name: outcomeExpectedData.triggeredExpectedDataVariableName,
          actionExpectedData,
          options: this.getExpectedDataMapOptions[actionExpectedData?.subType],
          value,
          error: "",
          index
        });
      }

      return this.makeInputComponent({
        label: outcomeExpectedData.triggeringData.message,
        name: outcomeExpectedData.triggeredExpectedDataVariableName,
        actionExpectedData,
        index,
        value,
        error: "",
        type: "text"
      });
    },
    makeInputForStringType({
      actionExpectedData = {},
      outcomeExpectedData = {},
      index = 0,
      value = ""
    } = {}) {
      if (actionExpectedData.isArray) {
        return this.makeInputForArrayStringType({
          actionExpectedData,
          outcomeExpectedData,
          index,
          value
        });
      }
      return this.makeInputForNonArrayStringType({
        actionExpectedData,
        outcomeExpectedData,
        index,
        value
      });
    },
    makeInputForNumericType({
      actionExpectedData = {},
      outcomeExpectedData = {},
      value = "",
      index = 0
    }) {
      if (this.isCustomAttributeRiskScale(actionExpectedData.subType)) {
        return this.makeSelectInput({
          label: outcomeExpectedData.triggeringData.message,
          name: outcomeExpectedData.triggeredExpectedDataVariableName,
          actionExpectedData,
          options: this.getExpectedDataMapOptions[actionExpectedData?.subType],
          error: "",
          index,
          value
        });
      }
      return this.makeInputComponent({
        label: outcomeExpectedData.triggeringData.message,
        name: outcomeExpectedData.triggeredExpectedDataVariableName,
        actionExpectedData,
        index,
        value,
        error: "",
        type: inputType.NUMBER
      });
    },
    makeInputComponent({
      label = "",
      name = "",
      index = 0,
      value = "",
      error = "",
      type = "text",
      actionExpectedData = {}
    } = {}) {
      const inputObject = {
        component: Input,
        componentOptions: {
          label,
          name,
          id: `task__dynamic-input-${name}`,
          class: "task__dynamic-input",
          "data-test-id": "task__dynamic-input",
          value,
          error,
          type,
          ...(type === inputType.NUMBER && { step: 0.000001 }),
          isDisabled: this.isTaskClosed,
          onChange: (event) =>
            this.onChangeInputRequestFromUser({ index, event })
        },
        expectedData: {
          name: actionExpectedData.name,
          valueDataType: actionExpectedData.type,
          valueType: variableAssignmentType.LITERAL,
          getValue: () =>
            this.getValueForAPI({
              value: inputObject.componentOptions.value,
              valueDataType: actionExpectedData.type
            })
        },
        id: this.makeUUID()
      };

      return inputObject;
    },
    onChangeInputRequestFromUser({ index, event }) {
      this.taskInstance.dynamicInputsBasedOnActionUserRequest[
        index
      ].componentOptions.value = event;
      this.taskInstance.dynamicInputsBasedOnActionUserRequest[
        index
      ].componentOptions.error = "";
    },
    makeMultiSelectInput({
      label = "",
      name = "",
      options = [],
      index = 0,
      error = "",
      selectedValues = [],
      actionExpectedData = {}
    } = {}) {
      const multiSelectObject = {
        component: MultiSelect,
        componentOptions: {
          label,
          name,
          id: `task__dynamic-input-${name}`,
          class: "task__dynamic-input",
          "data-test-id": "task__dynamic-input",
          options: this.makeOptionsForMultiSelect(
            selectedValues,
            selectedValues,
            options
          ),
          error,
          isDisabled: this.isTaskClosed,
          onChange: (event) =>
            this.onChangeMultiSelectRequestFromUser({ index, event })
        },
        expectedData: {
          name: actionExpectedData.name,
          valueDataType: actionExpectedData.type,
          valueType: variableAssignmentType.LITERAL,
          getValue: () =>
            this.getAllSelectedOptionValues(
              multiSelectObject.componentOptions.options
            )
        },
        id: this.makeUUID()
      };

      return multiSelectObject;
    },
    makeSelectedValuesForMultiSelect({ index, event }) {
      let selectedValues = this.getAllSelectedOptionValues(
        this.taskInstance.dynamicInputsBasedOnActionUserRequest[index]
          .componentOptions.options
      );
      if (event.eventType === operations.ADD) {
        selectedValues.push(event.value);
      } else if (event.eventType === operations.DELETE) {
        selectedValues = selectedValues.filter(
          (selectedValue) => selectedValue !== event.value
        );
      }
      return selectedValues;
    },
    onChangeMultiSelectRequestFromUser({ index, event }) {
      let selectedValues = this.makeSelectedValuesForMultiSelect({
        index,
        event
      });
      this.taskInstance.dynamicInputsBasedOnActionUserRequest[
        index
      ].componentOptions.options = this.makeOptionsForMultiSelect(
        selectedValues,
        selectedValues,
        this.taskInstance.dynamicInputsBasedOnActionUserRequest[index]
          .componentOptions.options
      );
      this.taskInstance.dynamicInputsBasedOnActionUserRequest[
        index
      ].componentOptions.error = "";
    },
    makeSelectInput({
      label = "",
      name = "",
      options = [],
      value = "",
      index = 0,
      error = "",
      actionExpectedData = {}
    } = {}) {
      const selectObject = {
        component: Select,
        componentOptions: {
          label,
          name,
          id: `task__dynamic-input-${name}`,
          class: "task__dynamic-input",
          "data-test-id": "task__dynamic-input",
          style: { display: "block" },
          options: this.makeOptionsForSelect(value, [emptyOption, ...options]),
          error,
          isDisabled: this.isTaskClosed,
          onChange: (event) =>
            this.onChangeSelectRequestFromUser({ index, event })
        },
        expectedData: {
          name: actionExpectedData.name,
          valueDataType: actionExpectedData.type,
          valueType: variableAssignmentType.LITERAL,
          getValue: () =>
            this.getValueForAPI({
              value: this.getSelectedOption(
                selectObject.componentOptions.options
              )?.value,
              valueDataType: actionExpectedData.type,
              variableType,
              validity
            })
        },
        id: this.makeUUID()
      };
      return selectObject;
    },
    onChangeSelectRequestFromUser({ index, event }) {
      this.taskInstance.dynamicInputsBasedOnActionUserRequest[
        index
      ].componentOptions.options = this.makeOptionsForSelect(
        event,
        this.taskInstance.dynamicInputsBasedOnActionUserRequest[index]
          .componentOptions.options
      );
      this.taskInstance.dynamicInputsBasedOnActionUserRequest[
        index
      ].componentOptions.error = "";
    }
  }
};
</script>

<style scoped lang="scss">
.task {
  text-align: left;

  &__wrapper {
    max-width: 500px;

    &-list {
      max-width: 800px;
    }
  }

  &__actions,
  &__assignee {
    display: block;
  }

  &__dynamic-input {
    padding: 0;
    margin: 0 0 $spacing20 0;
  }

  &__list {
    padding: 0;
    list-style-type: none;

    &-element {
      display: flex;

      &-description {
        padding-left: $spacing8;
      }
    }
  }

  &__form-cta {
    margin-left: $spacing20;
  }

  &__request-from-user-inputs {
    padding-top: $spacing20;
    border-top: $element-border;
  }
}
</style>
