<template>
  <div class="modal-header">
    <BaseText
      class="modal-header__title"
      data-test-id="modal-header__title"
      :text="title"
      :size="typographySize.BODY_TEXT_BOLD"
      hasDefaultSpacingRemoved
    />
    <CallToAction
      class="modal-header__cta"
      :icon="icons.TIMES"
      :theme="themes.SECONDARY_INVERSE"
      @click="$emit('click', $event)"
    />
  </div>
</template>

<script>
import CallToAction from "@/atoms/CallToAction/CallToAction";
import { themes, icons, typographySize } from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";

export default {
  name: "ModalHeader",
  components: { CallToAction, BaseText },
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      themes,
      icons,
      typographySize
    };
  }
};
</script>

<style lang="scss" scoped>
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing16 0;
  border-bottom: 1px solid $grey;

  &__title {
    @include heading-four;
    margin: $spacing6 0;
  }

  &__cta {
    width: $spacing40;
    height: $spacing40;
    border-radius: $spacing24;
  }
}
</style>
