<template>
  <div v-if="calculation.length" class="calculation-bracket">
    <div>
      <div class="calculation-bracket__step-calculation">
        <SearchSelect
          class="
            calculation-bracket__first-value
            calculation-bracket__step-calculation-value
          "
          :id="`${name}_first`"
          :options="calculation[0].value.options"
          :search-value="calculation[0].value.value"
          :error="calculation[0].value.error"
          @change="onCalculationStepValue(0, $event, 'value')"
          @create="onCalculationStepValue(0, $event, 'value')"
          @reset="onCalculationStepValue(0, $event, 'value')"
          placeholder="Select..."
          label="First value"
          isLabelHidden
        />
      </div>
      <BaseAddDeleteLine
        class="calculation-bracket__step-action"
        :is-hidden="!showAddStep[0]"
        :type="operations.ADD"
        @mouseenter="setShowAddStep(0, true)"
        @mouseleave="setShowAddStep(0, false)"
        @focusin="setShowAddStep(0, true)"
        @focusout="setShowAddStep(0, false)"
        @click="addStep(0)"
        tabindex="0"
      />
    </div>
    <div
      v-for="(calculationStep, index) in calculationSteps"
      class="calculation-bracket__step"
      :key="`calculation-step--${index}`"
    >
      <div
        class="calculation-bracket__step-calculation"
        @mouseover="setShowDeleteStep(index + 1, true)"
        @mouseleave="setShowDeleteStep(index + 1, false)"
        @focusin="setShowDeleteStep(index + 1, true)"
        @focusout="setShowDeleteStep(index + 1, false)"
        tabindex="0"
      >
        <Select
          :id="`${name}_bracket-operator-${index}`"
          :options="calculationStep.operator.options"
          @change="
            onCalculationStepValue(index + 1, $event, actionKeys.OPERATOR)
          "
          class="calculation-bracket__step-calculation-operator"
          :error="calculationStep.operator.error"
          placeholder="Select operator"
        />
        <SearchSelect
          :id="`${name}_bracket-value-${index}`"
          :options="calculationStep.value.options"
          :search-value="calculationStep.value.value"
          :error="calculationStep.value.error"
          @change="onCalculationStepValue(index + 1, $event, 'value')"
          @create="onCalculationStepValue(index + 1, $event, 'value')"
          @reset="onCalculationStepValue(index + 1, $event, 'value')"
          class="calculation-bracket__step-calculation-value"
          placeholder="Select..."
          label="Set value"
          isLabelHidden
        />
        <BaseAddDeleteLine
          v-if="showDeleteStep[index + 1]"
          class="calculation-bracket__step-action"
          :position="position.RIGHT"
          :type="operations.DELETE"
          @click="deleteStep(index + 1)"
          tabindex="0"
        />
      </div>
      <BaseAddDeleteLine
        class="calculation-bracket__step-action"
        :is-hidden="!showAddStep[index + 1]"
        :type="operations.ADD"
        @mouseenter="setShowAddStep(index + 1, true)"
        @mouseleave="setShowAddStep(index + 1, false)"
        @focusin="setShowAddStep(index + 1, true)"
        @focusout="setShowAddStep(index + 1, false)"
        @click="addStep(index + 1)"
        tabindex="0"
      />
    </div>
  </div>
</template>

<script>
import Select from "@/molecules/Select/Select";
import { cloneDeep } from "lodash";
import { actionKeys, operations, position } from "@/constants";
import SearchSelect from "@/molecules/SearchSelect/SearchSelect";
import BaseAddDeleteLine from "@/atoms/BaseAddDeleteLine/BaseAddDeleteLine";

export default {
  name: "CalculationBracket",
  components: {
    BaseAddDeleteLine,
    SearchSelect,
    Select
  },
  data() {
    return {
      showAddStep: [],
      showDeleteStep: [],
      position,
      operations,
      actionKeys
    };
  },
  props: {
    name: {
      type: String,
      required: true
    },
    calculation: {
      type: Array,
      default: () => []
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    calculation: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue?.length !== oldValue?.length) {
          this.setStepHoverStates();
        }
      }
    }
  },
  computed: {
    isOnlyStep() {
      return this.calculation?.length < 3;
    },
    calculationSteps() {
      const calculationCopy = cloneDeep(this.calculation);

      calculationCopy.shift();
      return calculationCopy;
    }
  },
  created() {
    this.setStepHoverStates();
  },
  methods: {
    setStepHoverStates() {
      this.showAddStep = this.calculation.map(() => false);
      this.showDeleteStep = this.calculation.map(() => false);
    },
    addStep(index) {
      this.emitChange({ type: operations.ADD, index: index + 1 });
    },
    deleteStep(index) {
      this.emitChange({ type: operations.DELETE, index });
    },
    onCalculationStepValue(index, value, property) {
      this.emitChange({ type: operations.EDIT, index, value, property });
    },
    emitChange(newValue) {
      this.$emit("change", newValue);
    },
    setShowAddStep(index, value) {
      this.showAddStep.splice(index, 1, value);
    },
    setShowDeleteStep(index, value) {
      if (!this.isOnlyStep) {
        this.showDeleteStep.splice(index, 1, value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.calculation-bracket {
  &__step {
    position: relative;

    &-calculation {
      padding-right: 40px;

      &-value {
        padding: 0;
      }

      &-operator {
        margin-left: 0;
        margin-bottom: 15px;
      }
    }

    &-calculation-value {
      margin-bottom: 0;
    }
  }
}
</style>
