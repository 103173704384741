<template>
  <div class="bi-dashboard">
    <BaseLoader v-show="isLoading" />
    <div v-show="!isLoading">
      <div class="bi-dashboard__header">
        <BaseIcon
          icon="cubes"
          :size="20"
          :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
        />
        <BaseText
          class="bi-dashboard__title"
          :text="getPageTitle"
          hasDefaultSpacingRemoved
        />
        <Link
          :anchor-options="{
            href: platformKnowledgeCentreLink,
            target: '_blank'
          }"
          :icon-options="{
            icon: icons.QUESTION,
            shape: shapes.CIRCLE,
            size: 12,
            theme: themes.ETHIXBASE_PRIMARY_INVERSE
          }"
        />
        <b-dropdown
          v-if="hasSettingsDropdown"
          dropleft
          no-caret
          size="sm"
          toggle-class="text-decoration-none"
          variant="outline"
        >
          <template #button-content>
            <font-awesome-icon icon="ellipsis-v" />
          </template>
          <b-dropdown-item-button
            v-if="hasEditUserViewPermission"
            class="text-secondary"
            @click="openEditViewModal"
          >
            View Settings
          </b-dropdown-item-button>
          <b-dropdown-item-button
            v-if="hasDeleteViewPermission"
            class="text-secondary"
            @click="showDeleteModal"
          >
            Delete View
          </b-dropdown-item-button>
        </b-dropdown>
      </div>
      <BaseInfoBox
        v-if="error"
        class="bi-dashboard__error"
        :text="error"
        :icon-options="defaultIcons.HIGH"
        :theme="themes.ERROR"
      />
      <div v-else class="dashboard__body">
        <div id="bi-dashboard__embed-container"></div>
      </div>
      <b-modal
        ref="modal-delete"
        title="Are you sure?"
        @ok="deletePlatformView"
      >
        <p class="my-4">Are you sure you want to delete view?</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import {
  actionName,
  defaultIcons,
  featurePermissionsKeys,
  getterName,
  httpStatuses,
  icons,
  shapes,
  themes,
  userPermissionKeysDTO,
  urls
} from "@/constants";
import { viewsService } from "@/services";
import { authenticatePyramidUser, hasPermission } from "@/utils";
import { mapActions, mapGetters, mapState } from "vuex";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import BaseText from "@/atoms/BaseText/BaseText";
import Link from "@/molecules/Link/Link";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader.vue";
import BaseInfoBox from "@/atoms/BaseInfoBox/BaseInfoBox.vue";

export default {
  name: "BIDashboardTemplate",
  components: {
    BaseInfoBox,
    BaseLoader,
    BaseText,
    BaseIcon,
    Link
  },
  data() {
    return {
      themes,
      icons,
      shapes,
      defaultIcons,
      isLoading: false,
      viewDetail: null,
      error: "",
      pyramid: null
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId,
      userId: (state) => state.user.userId,
      platformKnowledgeCentreLink: (state) =>
        state.platformSettings.platformKnowledgeCentreLink
    }),
    ...mapGetters({
      permittedLandingPage: getterName.USER.PERMITTED_LANDING_PAGE
    }),
    getPageTitle() {
      return this.viewDetail?.title || "";
    },
    hasDeleteViewPermission() {
      return hasPermission({
        key: featurePermissionsKeys.CREATE_AND_REMOVE_USER_VIEWS
      });
    },
    hasEditUserViewPermission() {
      return hasPermission({
        key: featurePermissionsKeys.EDIT_USER_VIEW,
        contextId: parseInt(this.$route.params.id),
        contextKey: userPermissionKeysDTO.USER_VIEW_ID
      });
    },
    hasSettingsDropdown() {
      return this.hasEditUserViewPermission || this.hasDeleteViewPermission;
    }
  },
  async created() {
    EventBus.on("refreshBiDashboard", async () => {
      await this.setupComponent();
    });

    await this.setupComponent();
  },
  watch: {
    "$route.params.id": {
      deep: true,
      async handler() {
        await this.setupComponent();
      }
    }
  },
  methods: {
    ...mapActions({
      fetchAdditionalVouchers: actionName.COMPANY.FETCH_ADDITIONAL_VOUCHERS
    }),
    async setupComponent() {
      await this.fetchPlatformView();
      await this.initializeEmbedContainer();
    },
    setError(value) {
      this.error = value;
    },
    async initializeEmbedContainer(
      _authenticatePyramidUser = authenticatePyramidUser
    ) {
      try {
        if (!this.pyramid?.isLoggedIn) {
          this.pyramid = await _authenticatePyramidUser(this.companyId);

          if (!this.pyramid?.isLoggedIn) {
            this.isLoading = false;
            this.setError(
              "Something went wrong, please try again. If the issue persists please contact support."
            );
            return;
          }
        }

        await this.pyramid.embed(
          document.querySelector("#bi-dashboard__embed-container"),
          {
            contentId: this.viewDetail?.configuration?.presentationId
          }
        );
      } catch {
        this.setError(
          "Something went wrong, please try again. If the issue persists please contact support."
        );
      } finally {
        this.isLoading = false;
      }
    },
    async fetchPlatformView(
      _fetchPlatformView = viewsService.fetchPlatformView
    ) {
      try {
        this.setError("");
        this.isLoading = true;
        const { data = {} } = await _fetchPlatformView({
          userViewId: this.$route.params.id,
          companyId: this.companyId
        });
        this.viewDetail = data;
      } catch {
        this.isLoading = false;
        this.$router.push(urls.PAGE_NOT_FOUND);
      }
    },
    showDeleteModal() {
      this.$refs["modal-delete"].show();
    },
    openEditViewModal() {
      EventBus.emit("addNewViewModal", {
        viewName: this.viewDetail?.title,
        viewType: this.viewDetail?.view_type,
        id: this.viewDetail?.id,
        permissions: this.viewDetail?.permissions,
        presentationId: this.viewDetail?.configuration?.presentationId
      });
    },
    async deletePlatformView(
      _,
      _deletePlatformView = viewsService.deletePlatformView
    ) {
      try {
        this.isLoading = true;
        const result = await _deletePlatformView(
          this.$route.params.id,
          this.companyId
        );
        if (result?.data?.success === httpStatuses.SUCCESS) {
          this.$bvToast.toast(result.data.message, {
            title: "Done",
            variant: "success",
            autoHideDelay: 2000,
            appendToast: false
          });
          await this.fetchAdditionalVouchers();
          await this.$router.push(this.permittedLandingPage);
        } else if (result?.data?.success === httpStatuses.FAIL) {
          this.$bvToast.toast(result.data.message, {
            title: "Error",
            variant: "danger",
            autoHideDelay: 2000,
            appendToast: false
          });
          this.isLoading = false;
        }
      } catch {
        this.$bvToast.toast(
          "Something went wrong, please try again. If the issue persists please contact support.",
          {
            title: "Error",
            variant: "danger",
            autoHideDelay: 2000,
            appendToast: false
          }
        );
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bi-dashboard {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }

  &__title {
    flex: 1;
    text-align: left;
  }

  &__body {
    max-height: calc(100vh - 160px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__error {
    max-width: 700px;
    margin: $spacing24 auto 0;
  }
}

#bi-dashboard__embed-container {
  width: 100%;
  margin-top: $spacing16;
  height: calc(100vh - 180px);
}
</style>
