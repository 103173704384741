import { isLessThanOrEqualToMaxLength, isRequired } from "@/utils";

const validateTaskTemplateText = (text) => {
  if (!isRequired(text)) {
    return "required";
  } else if (!isLessThanOrEqualToMaxLength(text, 255)) {
    return "maximum of 255 characters";
  } else {
    return "";
  }
};

const validateTaskTemplateBody = (userTaskTemplateEmailBody) => {
  if (!isRequired(userTaskTemplateEmailBody)) {
    return "required";
  } else {
    return "";
  }
};

const validateTriggerStepListLength = (listLength) => {
  if (listLength === 0) {
    return "minimum one task outcome required";
  } else {
    return "";
  }
};

export {
  validateTaskTemplateText,
  validateTaskTemplateBody,
  validateTriggerStepListLength
};
