import {
  actionKeys,
  actionKeysApi,
  actionStepType,
  expectedDataTypes,
  triggerDataType
} from "@/constants";
import { getSelectedOption } from "@/utils";

const makeDDQFormObj = (optionValue = {}) => ({
  [actionKeysApi.COMPANY_ID]: optionValue[actionKeys.COMPANY_ID],
  [actionKeysApi.DDQ_ID]: optionValue[actionKeys.DDQ_ID]
});

const getDDQFormObj = ({
  options = [],
  _makeDDQFormObj = makeDDQFormObj,
  _getSelectedOption = getSelectedOption
} = {}) => {
  try {
    const value = JSON.parse(_getSelectedOption(options)?.value);
    return _makeDDQFormObj(value);
  } catch (e) {
    return _makeDDQFormObj();
  }
};

const makeTriggerStepProxyAPIDTO = ({ options = [], name = "" } = {}) => ({
  [actionKeysApi.TRIGGER_DATA]: {
    [actionKeysApi.TYPE]: triggerDataType.PROXY,
    [actionKeysApi.DATA]: {
      [actionKeysApi.NAME]: getSelectedOption(options)?.value
    }
  },
  [actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]: name
});

const makeTriggerStepDDQformAPIDTO = ({ options = [], name = "" } = {}) => {
  const valueObj = getDDQFormObj({ options });

  return {
    [actionKeysApi.TRIGGER_DATA]: {
      [actionKeysApi.TYPE]: triggerDataType.IDENTIFIER,
      [actionKeysApi.DATA]: {
        [actionKeysApi.TYPE]: expectedDataTypes.DDQ_FORM,
        [actionKeysApi.VALUE]: {
          [actionKeysApi.DDQ_ID]: valueObj?.[actionKeysApi.DDQ_ID],
          [actionKeysApi.COMPANY_ID]: valueObj?.[actionKeysApi.COMPANY_ID]
        }
      }
    },
    [actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]: name
  };
};

const makeTriggerStepDDQformDTO = (expectDataObj) => ({
  [actionKeys.TRIGGER_DATA]: {
    [actionKeys.DATA]: {
      [actionKeys.VALUE]: {
        [actionKeys.COMPANY_ID]:
          expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
            actionKeysApi.VALUE
          ]?.[actionKeysApi.COMPANY_ID],
        [actionKeys.DDQ_ID]:
          expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
            actionKeysApi.VALUE
          ]?.[actionKeysApi.DDQ_ID]
      }
    },
    [actionKeys.TYPE]:
      expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.TYPE]
  },
  [actionKeys.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
    expectDataObj[actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]
});

const makeTriggerStepProxyDTO = (expectDataObj) => ({
  [actionKeys.TRIGGER_DATA]: {
    [actionKeys.DATA]: {
      [actionKeys.NAME]:
        expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
          actionKeysApi.NAME
        ]
    },
    [actionKeys.TYPE]:
      expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.TYPE]
  },
  [actionKeys.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
    expectDataObj[actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]
});

const makeTriggerStepTrainingCourseAPIDTO = ({
  expectDataObj = {},
  _getSelectedOption = getSelectedOption
}) => {
  const selectedOption = JSON.parse(
    _getSelectedOption(expectDataObj.options)?.value
  );

  return {
    [actionKeysApi.TRIGGER_DATA]: {
      [actionKeysApi.DATA]: {
        [actionKeysApi.TYPE]: expectDataObj[actionKeys.TYPE],
        [actionKeysApi.VALUE]: {
          [actionKeysApi.COMPANY_ID]: selectedOption[actionKeys.COMPANY_ID],
          [actionKeysApi.COURSE_ID]: selectedOption[actionKeys.COURSE_ID]
        }
      },
      [actionKeysApi.TYPE]: actionKeysApi.IDENTIFIER
    },
    [actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
      expectDataObj[actionKeys.NAME]
  };
};

const makeTriggerStepTrainingCourseDTO = ({ expectDataObj = {} }) => ({
  [actionKeys.TRIGGER_DATA]: {
    [actionKeys.DATA]: {
      [actionKeys.VALUE]: {
        [actionKeys.COMPANY_ID]:
          expectDataObj[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
            actionKeysApi.VALUE
          ]?.[actionKeysApi.COMPANY_ID],
        [actionKeys.COURSE_ID]:
          expectDataObj[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
            actionKeysApi.VALUE
          ]?.[actionKeysApi.COURSE_ID]
      }
    },
    [actionKeys.TYPE]:
      expectDataObj[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.TYPE]
  },
  [actionKeys.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
    expectDataObj[actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]
});

const makeTriggerStepAPIDTO = (functionStep) => ({
  [actionKeysApi.STEP_TYPE]: actionStepType.TRIGGER,
  [actionKeysApi.COMMENT]: functionStep[actionKeys.COMMENT] || "",
  [actionKeysApi.ACTION_ID]: getSelectedOption(functionStep.actionNameOptions)
    .value,
  [actionKeysApi.EXPECTED_DATA_MAPPING]: functionStep.expectDataList.map(
    (expectDataObj) => {
      if (
        expectDataObj.type === expectedDataTypes.THIRD_PARTY ||
        expectDataObj.type === expectedDataTypes.IDD_IDD_PLUS
      ) {
        return makeTriggerStepProxyAPIDTO(expectDataObj);
      }

      if (expectDataObj.type === expectedDataTypes.DDQ_FORM) {
        return makeTriggerStepDDQformAPIDTO(expectDataObj);
      }

      if (expectDataObj.type === expectedDataTypes.TRAINING_COURSE) {
        return makeTriggerStepTrainingCourseAPIDTO({ expectDataObj });
      }
    }
  )
});

const makeTriggerStepDTO = (functionStep) => ({
  [actionKeys.STEP_TYPE]: functionStep[actionKeysApi.STEP_TYPE],
  [actionKeys.COMMENT]: functionStep[actionKeysApi.COMMENT] || "",
  [actionKeys.ACTION_ID]: functionStep[actionKeysApi.ACTION_ID],
  [actionKeys.EXPECTED_DATA_MAPPING]: functionStep[
    actionKeysApi.EXPECTED_DATA_MAPPING
  ].map((expectDataObj) => {
    if (
      expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.TYPE] ===
      triggerDataType.PROXY
    ) {
      return makeTriggerStepProxyDTO(expectDataObj);
    }

    if (
      expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.TYPE] ===
      triggerDataType.IDENTIFIER
    ) {
      if (
        expectDataObj[actionKeysApi.TRIGGER_DATA][actionKeysApi.DATA][
          actionKeysApi.TYPE
        ] === triggerDataType.TRAINING_COURSE
      ) {
        return makeTriggerStepTrainingCourseDTO({ expectDataObj });
      }
      return makeTriggerStepDDQformDTO(expectDataObj);
    }
  })
});

export {
  getDDQFormObj,
  makeTriggerStepProxyAPIDTO,
  makeTriggerStepDDQformAPIDTO,
  makeTriggerStepDDQformDTO,
  makeTriggerStepProxyDTO,
  makeTriggerStepAPIDTO,
  makeTriggerStepDTO,
  makeTriggerStepTrainingCourseAPIDTO,
  makeTriggerStepTrainingCourseDTO
};
