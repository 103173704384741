<template>
  <ul
    v-bind="$attrs"
    :class="['menu-list', { 'menu-list--active': isMenuVisible }]"
    data-test-id="menu-list"
  >
    <template v-if="menuList.length">
      <li
        v-for="({ text, value }, index) in menuList"
        :key="`menuListItem-${index}`"
        class="menu-list__element"
      >
        <a
          href="javascript:void(0)"
          class="menu-list__element-cta"
          data-test-id="menu-list__element-cta"
          :title="text"
          @mousedown.stop="onSelection('mousedown', value)"
          @click.stop="onSelection('click', value)"
          @keyup.enter="onSelection('keyup', value)"
        >
          {{ text }}
        </a>
      </li>
    </template>
    <li v-else class="menu-list__element">
      <span
        class="menu-list__element-cta"
        data-test-id="menu-list__element-cta"
      >
        {{ fallbackMessage }}
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "MenuList",
  props: {
    menuList: {
      type: Array,
      default: () => []
    },
    isMenuVisible: {
      type: Boolean,
      default: false
    },
    fallbackMessage: {
      type: String,
      default: "No options available"
    }
  },
  methods: {
    onSelection(eventName, value) {
      this.$emit(eventName, value);
    }
  }
};
</script>

<style scoped lang="scss">
.menu-list {
  position: absolute;
  top: 100%;
  right: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background: $white;
  border: $element-border;
  z-index: 2;
  opacity: 0;
  border-radius: 5px;
  transition: opacity $transition-duration;
  min-width: 150px;
  @include box-shadow($box-shadow);

  &__element {
    z-index: 3;

    &-cta {
      display: block;
      width: 100%;
      padding: 5px 10px;
      border: none;
      outline: none;
      background: transparent;
      color: $black;
      cursor: pointer;

      &:hover,
      &:focus {
        background: $chinese-silver;
        color: $black;
      }
    }
  }

  &--active {
    width: auto;
    height: auto;
    opacity: 1;
  }
}
</style>
