import { errorMessages, validationKeys } from "@/constants";

export default {
  data() {
    return {
      errorMessages: {}
    };
  },
  methods: {
    validateInputModels(inputModelList) {
      inputModelList.forEach((inputModel) => {
        if (inputModel?.key) {
          this.validateList(inputModel);
        } else {
          this.validate(inputModel);
        }
      });
    },
    validateProperty(
      validationSteps,
      currentValidationKeys,
      modelItem,
      propertyToValidate
    ) {
      for (const validationStep of validationSteps) {
        if (
          currentValidationKeys.includes(validationStep) &&
          propertyToValidate[validationStep]?.$invalid
        ) {
          return errorMessages[modelItem][validationStep];
        }
      }
    },
    validateList({ key, subKeyList }) {
      const listToValidate = Object.values(this.v$[key].$each.$iter);
      listToValidate.forEach((item, index) => {
        subKeyList.forEach((modelItem) => {
          const currentValidationKeys = Object.keys(item[modelItem]);
          const validationSteps = Object.values(validationKeys);
          if (this.v$[key].$each.$iter[index][modelItem].$invalid) {
            this.errorMessages[key][index][modelItem] = this.validateProperty(
              validationSteps,
              currentValidationKeys,
              modelItem,
              this.v$[key].$each.$iter[index][modelItem]
            );
          } else {
            this.errorMessages[key][index][modelItem] = null;
          }
        });
      });
    },
    validate(property) {
      const currentValidationKeys = Object.keys(this.v$[property]);
      const validationSteps = Object.values(validationKeys);
      if (this.v$[property].$invalid) {
        this.errorMessages[property] = this.validateProperty(
          validationSteps,
          currentValidationKeys,
          property,
          this.v$[property]
        );
      } else {
        this.errorMessages[property] = null;
      }
    },
    getInputModelState(inputModel) {
      if (
        typeof this.errorMessages[inputModel] === "string" &&
        this.errorMessages[inputModel]?.length
      ) {
        return false;
      } else if (this.errorMessages[inputModel] === null) {
        return true;
      } else {
        return null;
      }
    },
    resetErrorMessages() {
      Object.keys(this.errorMessages).forEach((errorKey) => {
        if (Array.isArray(this.errorMessages[errorKey])) {
          this.errorMessages[errorKey].forEach((lisItemError) => {
            Object.keys(lisItemError).forEach((lisItemErrorKey) => {
              lisItemError[lisItemErrorKey] = undefined;
            });
          });
        } else {
          this.errorMessages[errorKey] = undefined;
        }
      });
    }
  }
};
