import { endpoints } from "@/constants";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import {
  makeCompaniesDTO,
  makeCompanyUsersDTO,
  makeDeleteCompanyUserDTO,
  makeSelectedCompanyDTO,
  makeAdditionalVouchersDTO,
  makeCompanyFeaturesDTO
} from "./dto/company.dto";

const fetchCompanies = async (userId) => {
  const result = await HTTP(
    "get",
    endpoints.GET_COMPANIES(userId),
    null,
    makeAuthorizationHeader()
  );
  result.data = makeCompaniesDTO(result.data);
  return result;
};

const selectCompany = async (payload) => {
  const result = await HTTP(
    "post",
    endpoints.SELECT_COMPANY,
    payload,
    makeAuthorizationHeader()
  );
  result.data = makeSelectedCompanyDTO(result.data);
  return result;
};

const fetchCompanyUsers = async (payload) => {
  const result = await HTTP(
    "post",
    endpoints.PLATFORM_USERS_LIST,
    payload,
    makeAuthorizationHeader()
  );
  result.data = makeCompanyUsersDTO(result.data.data);
  return result;
};

const updateCompanyUser = async (payload) =>
  HTTP(
    "post",
    endpoints.UPDATE_COMPANY_USER,
    payload,
    makeAuthorizationHeader()
  );

const addCompanyUser = (payload) =>
  HTTP("post", endpoints.ADD_COMPANY_USER, payload, makeAuthorizationHeader());

const deleteCompanyUser = async (payload) => {
  const response = await HTTP(
    "post",
    endpoints.DELETE_COMPANY_USER,
    payload,
    makeAuthorizationHeader()
  );
  response.data = makeDeleteCompanyUserDTO(response.data);
  return response;
};

const fetchAdditionalVouchers = async (companyId) => {
  const response = await HTTP(
    "post",
    endpoints.COMPANY_VOUCHERS,
    {
      companyId
    },
    makeAuthorizationHeader()
  );
  response.data = makeAdditionalVouchersDTO(response.data);
  return response;
};

const fetchCompanyFeatures = async (companyId) => {
  const result = await HTTP(
    "get",
    endpoints.COMPANY_FEATURES(companyId),
    null,
    makeAuthorizationHeader()
  );
  result.data = makeCompanyFeaturesDTO({
    companyFeatures: result.data.data
  });
  return result;
};

const updateCompanyFeature = ({ companyFeature }) =>
  HTTP(
    "post",
    endpoints.COMPANY_FEATURES(companyFeature.companyId),
    companyFeature.payload,
    makeAuthorizationHeader()
  );

export const companyService = {
  selectCompany,
  fetchCompanies,
  fetchCompanyUsers,
  updateCompanyUser,
  addCompanyUser,
  deleteCompanyUser,
  fetchAdditionalVouchers,
  fetchCompanyFeatures,
  updateCompanyFeature
};
