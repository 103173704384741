import { actions } from "./permissions.actions.store";
import { getters } from "./permissions.getters.store";
import { mutations } from "./permissions.mutations.store";
import { state } from "./permissions.state.store";

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
};
