import { state as makeState } from "@/store/expectedData/expectedData.state.store";

export const mutations = {
  setIsLoadingExpectedData(state, payload) {
    state.isLoadingExpectedData = payload;
  },
  setExpectedData(state, payload) {
    state.expectedData = payload;
  },
  setExpectedDataEntityReferences(state, { references, index }) {
    state.expectedData[index].references = references;
  },
  resetExpectedData(state) {
    Object.assign(state, makeState());
  }
};
