<template>
  <highcharts
    v-bind="highchartsComponentOptions"
    data-test-id="base__chart"
    :options="chartOptions"
  />
</template>

<script>
export default {
  name: "BaseChart",
  props: {
    chartOptions: {
      required: true,
      type: Object
    },
    highchartsComponentOptions: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
