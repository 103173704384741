import { state } from "./languages.state.store";
import { actions } from "./languages.actions.store";
import { mutations } from "./languages.mutations.store";
import { getters } from "./languages.getters.store";

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state
};
