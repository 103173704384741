<template>
  <div class="analytics-tab">
    <div class="d-flex analytics-tab__header">
      <div>
        <!-- download btn -->
        <b-button
          :disabled="isPageLoaded"
          class="mt-2 mb-2"
          size="sm"
          variant="outline-dark"
          @click="printPage"
        >
          <i aria-hidden="true" class="fa fa-print"></i>
        </b-button>
        <!-- download btn -->
      </div>
    </div>
    <div class="analytics-tab__content" id="analytics_overview">
      <b-overlay :show="savedThirdPartiesLoading" rounded="sm">
        <b-row
          v-observe-visibility="{
            callback: savedThirdPartiesApiCall,
            intersection: {
              threshold: 0.1
            }
          }"
          class="analytics-tab__cards-wrapper"
        >
          <TotalTPComponent
            :totalNumberOfThirdParties="totalNumberOfThirdParties"
            class="analytics-tab__cards-wrapper-item"
          />
          <TpOverTimeComponent
            :savedThirdPartyChartsOptions="savedThirdPartyChartsOptions"
            class="analytics-tab__cards-wrapper-item"
          />
          <div
            v-b-tooltip.hover.bottom.html="
              headerConfigData && headerConfigData.autoremiddplusmoni == 1
                ? ''
                : $store.state.disabledButtonsToolTipMsg
            "
            class="analytics-tab__cards-wrapper-item"
          >
            <!-- Ongoing Monitoring Results card -->
            <OngoingMonitoringResults
              :headerConfigData="headerConfigData"
              :totalNumberOfMonitoringAlertsAllTime="
                totalNumberOfMonitoringAlertsAllTime
              "
              :totalNumberOfMonitoringAlertsLastMonth="
                totalNumberOfMonitoringAlertsLastMonth
              "
              :totalNumberOfMonitoringAlertsThisMonth="
                totalNumberOfMonitoringAlertsThisMonth
              "
              :totalNumberOfThirdPartiesAlertsAllTime="
                totalNumberOfThirdPartiesAlertsAllTime
              "
              :totalNumberOfThirdPartiesAlertsLastMonth="
                totalNumberOfThirdPartiesAlertsLastMonth
              "
              :totalNumberOfThirdPartiesAlertsThisMonth="
                totalNumberOfThirdPartiesAlertsThisMonth
              "
            />
          </div>
        </b-row>
      </b-overlay>
      <!-- graphical representation of third parties by countries start-->
      <b-overlay
        class="analytics-tab__content-map-chart"
        :show="mapChartLoading"
        rounded="sm"
      >
        <b-row
          v-observe-visibility="{
            callback: mapChartApiCall,
            intersection: {
              threshold: 0.5
            }
          }"
          class="analytics-tab__cards-wrapper analytics-tab__content-map-chart"
        >
          <div class="analytics-tab__cards-wrapper-item">
            <TpOverCountriesComponent :mapChartOptions="mapChartOptions" />
          </div>
        </b-row>
      </b-overlay>
      <!-- graphical representation of third parties by countries end-->

      <b-overlay :show="priActiveSanctionsChartLoading" rounded="sm">
        <b-row
          v-observe-visibility="{
            callback: priActiveSanctionsChartApiCall,
            intersection: {
              threshold: 0.1
            }
          }"
          class="analytics-tab__cards-wrapper"
        >
          <!--graphical representation of PRI start -->
          <div class="analytics-tab__cards-wrapper-item">
            <PriResultsComponent :priChartOptions="priChartOptions" />
          </div>
          <!--graphical representation of PRI end -->

          <!--graphical representation of IDD start -->
          <div class="analytics-tab__cards-wrapper-item">
            <IddResultsComponent
              :SanctionsAlertsCountChartOptions="
                SanctionsAlertsCountChartOptions
              "
            />
          </div>
          <!--graphical representation of IDD end -->

          <!--graphical representation of IDD+ results pre remediation start -->
          <div class="analytics-tab__cards-wrapper-item">
            <IddPlusPreRemediationResultsComponent
              :iddPlusPreRemediationCountChartOptions="
                iddPlusPreRemediationCountChartOptions
              "
            />
          </div>
          <!--graphical representation of IDD+ results pre remediation end -->
        </b-row>
      </b-overlay>

      <b-overlay
        :show="iddPlusPostRemediationEddResultCountTpCountriesChartLoading"
        rounded="sm"
      >
        <b-row
          v-observe-visibility="{
            callback:
              iddPlusPostRemediationEddResultCountTpCountriesChartApiCall,
            intersection: {
              threshold: 0.1
            }
          }"
          class="analytics-tab__cards-wrapper"
        >
          <!--graphical representation of IDD+ start -->
          <div class="analytics-tab__cards-wrapper-item">
            <IddPlusPostRemediationResultsComponent
              :iddPlusPostRemediationCountChartOptions="
                iddPlusPostRemediationCountChartOptions
              "
            />
          </div>
          <!--graphical representation of IDD+ end -->

          <!--graphical representation of EDD start -->
          <div class="analytics-tab__cards-wrapper-item">
            <EddResultsComponent
              :eddResultCountChartOptions="eddResultCountChartOptions"
            />
          </div>
          <!--graphical representation of EDD end -->

          <!--graphical representation of Third parties by countries start -->
          <div class="analytics-tab__cards-wrapper-item">
            <TpOverCountriesResultsComponent
              :tpCountriesChartOptions="tpCountriesChartOptions"
            />
          </div>
          <!--graphical representation of Third parties by countries end -->
        </b-row>
      </b-overlay>
      <ThirdPartyAnalysisModalTable
        :company-id="companyId"
        :modal-title="mapDrillDownOptions.modalTitle"
        :query-specific-data="mapDrillDownOptions.querySpecificData"
        :table-id="mapDrillDownOptions.tableId"
        :table-title="mapDrillDownOptions.tableTitle"
        @hideModal="hideModal"
      />
    </div>
  </div>
</template>

<script>
import worldMap from "@highcharts/map-collection/custom/world.geo.json";
import {
  makeAuthorizationHeader,
  HTTP,
  makeSanctionsAlertsCountChartOptions
} from "@/utils";
import EB2ColorPallete from "@/utils/eb2ColorPallete.json";
import TotalTPComponent from "./analyticsComponents/totalTPComponent.vue";
import TpOverTimeComponent from "./analyticsComponents/TpOverTimeComponent.vue";
import OngoingMonitoringResults from "./analyticsComponents/ongoingMonitoringResults.vue";
import TpOverCountriesComponent from "./analyticsComponents/TpOverCountriesComponent.vue";
import PriResultsComponent from "./analyticsComponents/PriResultsComponent/PriResultsComponent.vue";
import IddResultsComponent from "./analyticsComponents/iddResultComponent.vue";
import IddPlusPreRemediationResultsComponent from "./analyticsComponents/iddPlusPreRemediationResultsComponent.vue";
import IddPlusPostRemediationResultsComponent from "./analyticsComponents/iddPlusPostRemediationResultsComponent.vue";
import EddResultsComponent from "./analyticsComponents/eddResultsComponent.vue";
import TpOverCountriesResultsComponent from "./analyticsComponents/TpOverCountriesResultsComponent.vue";
import ThirdPartyAnalysisModalTable from "@/organisms/ThirdPartyAnalysisModalTable/ThirdPartyAnalysisModalTable";
import {
  iddPlusPostRemediationName,
  queryObjectKeys,
  chartStyles
} from "@/constants";
import { mapState } from "vuex";

export default {
  name: "AnalyticsTabChartComponent",
  components: {
    TotalTPComponent,
    TpOverTimeComponent,
    OngoingMonitoringResults,
    TpOverCountriesComponent,
    PriResultsComponent,
    IddResultsComponent,
    IddPlusPreRemediationResultsComponent,
    IddPlusPostRemediationResultsComponent,
    EddResultsComponent,
    TpOverCountriesResultsComponent,
    ThirdPartyAnalysisModalTable
  },
  data() {
    return {
      radialBarChartOptions: {
        colors: [
          EB2ColorPallete.EB2ColorPallete.chatsAmberColor,
          EB2ColorPallete.EB2ColorPallete.chatsGrayColor,
          EB2ColorPallete.EB2ColorPallete.chatsGreenColor
        ],
        chart: {
          type: "column",
          inverted: true,
          polar: true,
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        title: {
          text: "Third Parties by Risk Action"
        },
        tooltip: {
          outside: true
        },
        credits: {
          enabled: false
        },
        pane: {
          size: "85%",
          innerSize: "20%",
          endAngle: 270
        },
        xAxis: {
          tickInterval: 1,
          labels: {
            align: "right",
            useHTML: true,
            allowOverlap: true,
            step: 1,
            y: 3,
            style: {
              fontSize: "13px"
            }
          },
          lineWidth: 0,
          categories: [
            'MSQ <span class="f16"><span id="flag" class="flag no">' +
              "</span></span>",
            'DDQ <span class="f16"><span id="flag" class="flag us">' +
              "</span></span>",
            'IDD <span class="f16"><span id="flag" class="flag de">' +
              "</span></span>",
            'IDD+ <span class="f16"><span id="flag" class="flag ca">' +
              "</span></span>",
            'EDD <span class="f16"><span id="flag" class="flag at">' +
              "</span></span>",
            'ABC Train <span class="f16"><span id="flag" class="flag at">' +
              "</span></span>"
          ]
        },
        yAxis: {
          crosshair: {
            enabled: true,
            color: "#333"
          },
          lineWidth: 0,
          tickInterval: 25,
          reversedStacks: false,
          endOnTick: true,
          showLastLabel: true
        },
        plotOptions: {
          column: {
            stacking: "normal",
            borderWidth: 0,
            pointPadding: 0,
            groupPadding: 0.15
          }
        },
        series: [
          {
            name: "In Progress",
            data: [132, 105, 92, 73, 64, 53]
          },
          {
            name: "Sent/Not Started",
            data: [125, 110, 86, 64, 81, 42]
          },
          {
            name: "Completed",
            data: [111, 90, 60, 62, 87, 88]
          }
        ]
      },
      stackedGroupedChartOptions: {
        chart: {
          type: "column",
          options3d: {
            enabled: true,
            alpha: 15,
            beta: 15,
            viewDistance: 25,
            depth: 40
          },
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        colors: [
          EB2ColorPallete.EB2ColorPallete.chatsGreenColor,
          EB2ColorPallete.EB2ColorPallete.chatsBlueColor,
          EB2ColorPallete.EB2ColorPallete.chatsPurpleColor
        ],
        credits: {
          enabled: false
        },
        title: {
          text: "Third Party Due Diligence Reports by Month"
        },

        xAxis: {
          categories: ["April", "May", "June", "July", "August", "September"],
          labels: {
            skew3d: true,
            style: {
              fontSize: "16px"
            }
          }
        },

        yAxis: {
          allowDecimals: false,
          min: 0,
          title: {
            skew3d: true
          }
        },

        tooltip: {
          headerFormat: "<b>{point.key}</b><br>",
          pointFormat:
            '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} / {point.stackTotal}'
        },

        plotOptions: {
          column: {
            stacking: "normal",
            depth: 40
          }
        },

        series: [
          {
            name: "IDD",
            data: [5, 3, 4, 7, 2, 3]
          },
          {
            name: "IDD+",
            data: [3, 4, 4, 2, 5, 6]
          },
          {
            name: "EDD",
            data: [2, 5, 6, 2, 1, 8]
          }
        ]
      },
      stackedBarChartOptions: {
        chart: {
          type: "bar",
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        title: {
          text: "Third Parties Added By Users"
        },
        xAxis: {
          categories: ["Natasha", "Nazmul", "Gaurav", "Karan", "Jason"]
        },
        credits: {
          enabled: false
        },
        yAxis: {
          min: 0,
          title: {
            text: "Total fruit consumption"
          }
        },
        colors: [EB2ColorPallete.EB2ColorPallete.chatsGreenColor],
        legend: {
          reversed: true
        },
        plotOptions: {
          series: {
            stacking: "normal"
          }
        },
        series: [
          {
            name: "Count",
            data: [5, 3, 4, 7, 2]
          }
        ]
      },
      savedThirdParties: false,
      savedThirdPartiesLoading: false,
      mapCharts: false,
      mapChartLoading: false,
      priActiveSanctionsChartLoading: false,
      priActiveSanctionsChart: false,
      iddPlusPostRemediationEddResultCountTpCountriesChart: false,
      iddPlusPostRemediationEddResultCountTpCountriesChartLoading: false,
      tpCountrySanctionsAlertsiddPlusPreRemediationChartLoading: false,
      tpCountrySanctionsAlertsiddPlusPreRemediationChart: false,
      totalNumberOfThirdParties: 0,
      totalNumberOfThirdPartiesAlertsAllTime: 0,
      totalNumberOfMonitoringAlertsAllTime: 0,
      totalNumberOfMonitoringAlertsLastMonth: 0,
      totalNumberOfThirdPartiesAlertsLastMonth: 0,
      totalNumberOfMonitoringAlertsThisMonth: 0,
      totalNumberOfThirdPartiesAlertsThisMonth: 0,
      mapData: "",
      isLoadingData: false,
      savedThirdPartyChartsOptions: {
        title: {
          text: "Third Parties Over Time",
          style: chartStyles.TITLE
        },
        subtitle: {
          text: ""
        },
        credits: {
          enabled: false
        },
        yAxis: {
          title: {
            text: "No. of Third Parties"
          }
        },
        xAxis: {
          categories: []
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true
            },
            enableMouseTracking: false
          }
        },
        series: [],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom"
                }
              }
            }
          ]
        }
      },
      priChartOptions: {
        chart: {
          type: "pie",
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: "Perceived Risk Indicator Results",
          style: chartStyles.TITLE
        },
        tooltip: {
          pointFormat: "<b>{point.percentage:.1f}%</b>"
        },
        subtitle: {
          text: ""
        },
        accessibility: {
          point: {
            valueSuffix: "%"
          }
        },
        colors: [
          EB2ColorPallete.EB2ColorPallete.chatsRedColor,
          EB2ColorPallete.EB2ColorPallete.chatsAmberColor,
          EB2ColorPallete.EB2ColorPallete.chatsGreenColor,
          EB2ColorPallete.EB2ColorPallete.chatsGrayColor
        ],
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            depth: 35,
            dataLabels: {
              enabled: true,
              format: "{point.name}"
            }
          }
        },
        series: []
      },
      associatesChartOptions: {
        chart: {
          type: "pie",
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        title: {
          text: "Third Parties Associates",
          style: chartStyles.TITLE
        },
        credits: {
          enabled: false
        },
        tooltip: {
          pointFormat: "<b>{point.percentage:.1f}%</b>"
        },
        subtitle: {
          text: ""
        },
        accessibility: {
          point: {
            valueSuffix: "%"
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            depth: 35,
            dataLabels: {
              enabled: true,
              format: "{point.name}"
            }
          }
        },
        series: []
      },
      tpCountryChartOptions: {
        chart: {
          type: "pie",
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        title: {
          text: "Third Parties Country",
          style: chartStyles.TITLE
        },
        credits: {
          enabled: false
        },
        tooltip: {
          pointFormat: "<b>{point.percentage:.1f}%</b>"
        },
        subtitle: {
          text: ""
        },
        accessibility: {
          point: {
            valueSuffix: "%"
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            depth: 35,
            dataLabels: {
              enabled: true,
              format: "{point.name}"
            }
          }
        },
        series: []
      },
      mapChartOptions: {
        chart: {
          borderWidth: 0,
          map: worldMap,
          height: 600,
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        title: {
          text: "Third Parties By Countries",
          style: chartStyles.TITLE
        },
        subtitle: {
          text: ""
        },
        credits: {
          enabled: false
        },
        mapNavigation: {
          enabled: false,
          buttonOptions: {
            verticalAlign: "bottom"
          }
        },
        series: []
      },
      AlertsCountChartOptions: {
        chart: {
          type: "pie",
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: "Sanctions & Enforcements Results (IDD)",
          style: chartStyles.TITLE
        },
        tooltip: {
          pointFormat: "<b>{point.percentage:.1f}%</b>"
        },
        subtitle: {
          text: ""
        },
        accessibility: {
          point: {
            valueSuffix: "%"
          }
        },
        colors: [
          EB2ColorPallete.EB2ColorPallete.chatsGreenColor,
          EB2ColorPallete.EB2ColorPallete.chatsRedColor,
          EB2ColorPallete.EB2ColorPallete.chatsGrayColor
        ],
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            depth: 35,
            dataLabels: {
              enabled: true,
              format: "{point.name}"
            }
          }
        },
        series: []
      },
      iddPlusPreRemediationCountChartOptions: {
        chart: {
          type: "pie",
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: "IDD+ Results Pre-Remediation",
          style: chartStyles.TITLE
        },
        tooltip: {
          pointFormat: "<b>{point.percentage:.1f}%</b>"
        },
        subtitle: {
          text: ""
        },
        accessibility: {
          point: {
            valueSuffix: "%"
          }
        },
        colors: [
          EB2ColorPallete.EB2ColorPallete.chatsGreenColor,
          EB2ColorPallete.EB2ColorPallete.chatsGrayColor,
          EB2ColorPallete.EB2ColorPallete.chatsRedColor
        ],
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            depth: 35,
            dataLabels: {
              enabled: true,
              format: "{point.name}"
            }
          }
        },
        series: []
      },
      iddPlusPostRemediationCountChartOptions: {
        chart: {
          type: "pie",
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: "Instant Due Diligence PLUS",
          style: chartStyles.TITLE
        },
        tooltip: {
          pointFormat: "<b>{point.percentage:.1f}%</b>"
        },
        subtitle: {
          text: "Results Post-Remediation"
        },
        accessibility: {
          point: {
            valueSuffix: "%"
          }
        },
        colors: [
          EB2ColorPallete.EB2ColorPallete.chatsGrayColor,
          EB2ColorPallete.EB2ColorPallete.chatsGreenColor,
          EB2ColorPallete.EB2ColorPallete.chatsDarkColor,
          EB2ColorPallete.EB2ColorPallete.chatsRedColor,
          EB2ColorPallete.EB2ColorPallete.chatsAmberColor
        ],
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            depth: 35,
            dataLabels: {
              enabled: true,
              format: "{point.name}"
            }
          }
        },
        series: []
      },
      eddResultCountChartOptions: {
        chart: {
          type: "pie",
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: "Enhanced Due Diligence Results",
          style: chartStyles.TITLE
        },
        tooltip: {
          pointFormat: "<b>{point.percentage:.1f}%</b>"
        },
        subtitle: {
          text: ""
        },
        accessibility: {
          point: {
            valueSuffix: "%"
          }
        },
        colors: [
          EB2ColorPallete.EB2ColorPallete.chatsGreenColor,
          EB2ColorPallete.EB2ColorPallete.chatsGrayColor,
          EB2ColorPallete.EB2ColorPallete.chatsRedColor
        ],
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            depth: 35,
            dataLabels: {
              enabled: true,
              format: "{point.name}"
            }
          }
        },
        series: []
      },
      tpCountriesChartOptions: {
        chart: {
          type: "pie",
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: "Third Parties by Country",
          style: chartStyles.TITLE
        },
        tooltip: {
          pointFormat: "<b>{point.percentage:.1f}%</b>"
        },
        subtitle: {
          text: ""
        },
        accessibility: {
          point: {
            valueSuffix: "%"
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            depth: 35,
            dataLabels: {
              enabled: true,
              format: "{point.name}"
            }
          }
        },
        series: []
      },
      isPageLoaded: true,
      mapDrillDownOptions: {
        tableTitle: "",
        tableId: "",
        modalTitle: "",
        querySpecificData: null
      },
      headerConfigData: null,
      SanctionsAlertsCountChartOptions: makeSanctionsAlertsCountChartOptions()
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId,
      userId: (state) => state.user.userId
    })
  },
  async mounted() {
    await this.$router.isReady();
    this.getheaderConfigData();
    this.worldMapChartsData();
  },
  methods: {
    getQueryParamExcludeSbos(isExcludeScreenedBeneficialOwners = true) {
      return {
        exclude_sbos: isExcludeScreenedBeneficialOwners
      };
    },
    async getheaderConfigData() {
      try {
        this.isLoadingData = true;
        let result = await HTTP(
          "get",
          `v1/get-header-config/${this.companyId}/${this.userId}`,
          null,
          makeAuthorizationHeader(),
          null,
          true
        );
        if (result.data && result.status == 200) {
          this.headerConfigData = result?.data?.data?.specialFeature;
        }
        this.isLoadingData = false;
      } catch {
        this.isLoadingData = false;
      }
    },
    hideModal() {
      this.mapDrillDownOptions.tableTitle = "";
      this.mapDrillDownOptions.tableId = "";
      this.mapDrillDownOptions.modalTitle = "";
      this.mapDrillDownOptions.querySpecificData = null;
    },
    printPage() {
      let originalContents = document.body.innerHTML;
      document.body.innerHTML =
        document.getElementById("analytics_overview").innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
    // change chart type functions
    onSelectPieChart(type) {
      this.priChartOptions.chart.type = type.toLowerCase();
    },
    onSelectSanctionsAlertsChart(type) {
      this.SanctionsAlertsCountChartOptions.chart.type = type.toLowerCase();
    },
    onSelectIddPlusPreRemediationChart(type) {
      this.iddPlusPreRemediationCountChartOptions.chart.type =
        type.toLowerCase();
    },
    onSelectIddPlusPostRemediationCChart(type) {
      this.iddPlusPostRemediationCountChartOptions.chart.type =
        type.toLowerCase();
    },
    onSelectEddResultChart(type) {
      this.eddResultCountChartOptions.chart.type = type.toLowerCase();
    },
    onSelectTpCountriesPieChart(type) {
      this.tpCountriesChartOptions.chart.type = type.toLowerCase();
    },
    savedThirdPartiesApiCall(isVisible) {
      if (isVisible) {
        if (this.savedThirdParties == false) {
          this.getTpStatData();
          this.getMonitoringAlertsCountThisMonth();
          this.getMonitoringAlertsCountLastMonth();
          this.getMonitoringAlertsCountAllTime();
        }
      }
    },
    mapChartApiCall(isVisible) {
      if (isVisible) {
        if (this.mapCharts == false) {
          this.worldMapChartsData();
        }
      }
    },
    // function for graph data of PRI, IDD, pre-IDD+
    priActiveSanctionsChartApiCall(isVisible) {
      if (isVisible) {
        if (this.priActiveSanctionsChart == false) {
          this.tpPriChartsData();
          this.getSanctionsAlertsCount();
          this.getIDDPlusPreRemediationCount();
        }
      }
    },
    // function for graph data of post-IDD+ ,EDD, third parties
    iddPlusPostRemediationEddResultCountTpCountriesChartApiCall(isVisible) {
      if (isVisible) {
        if (
          this.iddPlusPostRemediationEddResultCountTpCountriesChart == false
        ) {
          this.getIDDPlusPostRemediationCount();
          this.getEddResultCount();
          this.getTpCountries();
        }
      }
    },
    async worldMapChartsData() {
      try {
        this.mapChartLoading = true;
        this.isLoading = true;
        let result = await HTTP(
          "post",
          "chart/country",
          {
            companyId: this.companyId
          },
          makeAuthorizationHeader(),
          this.getQueryParamExcludeSbos()
        );

        if (result.data.status == 1 && result.status == 200) {
          const self = this;
          this.mapChartOptions.series.push(
            {
              name: "Countries",
              color: "#E0E0E0",
              enableMouseTracking: false
            },
            {
              type: "mapbubble",
              name: "Third Parties",
              point: {
                events: {
                  click() {
                    self.mapDrillDownOptions.modalTitle =
                      "Third Parties By Country/Territory of Registration";
                    self.mapDrillDownOptions.tableTitle = this.country;
                    self.mapDrillDownOptions.querySpecificData = {
                      thirdParties: this.thirdParties
                    };
                    self.mapDrillDownOptions.tableId =
                      queryObjectKeys.THIRD_PARTY_DDQ_QUESTIONS;
                  }
                }
              },
              color: "#6BDC99",
              borderColor: "black",
              borderWidth: 0.2,
              joinBy: ["iso-a2", "code"],
              data: result.data.data,
              minSize: 4,
              maxSize: "12%",
              tooltip: {
                pointFormat: "{point.country}: {point.z}"
              }
            }
          );
          this.mapCharts = true;
          this.mapChartLoading = false;
        } else {
          this.mapChartOptions.series.push(
            {
              name: "Countries",
              color: "#E0E0E0",
              enableMouseTracking: false
            },
            {
              type: "mapbubble",
              name: "Third Parties",
              color: "#6BDC99",
              joinBy: ["iso-a2", "code"],
              data: [],
              minSize: 4,
              maxSize: "12%"
            }
          );
          this.mapChartLoading = false;
        }
      } catch (err) {
        this.mapChartLoading = false;
        return [err];
      }
    },
    // third parties data function
    async getTpStatData() {
      this.totalNumberOfThirdParties = 0;
      this.savedThirdPartiesLoading = true;
      try {
        this.savedThirdPartiesLoading = true;
        let result = await HTTP(
          "get",
          `chart/tpstatdata/${this.companyId}`,
          null,
          makeAuthorizationHeader(),
          this.getQueryParamExcludeSbos()
        );
        if (result.data.status == 1 && result.status == 200) {
          let monthData = [];
          let thirdPartyData = [];
          let thirdPartyYear = null;
          this.totalNumberOfThirdParties = result.data.totalTp;
          result.data.totalTpPerMonth.map((value) => {
            thirdPartyData.push(value.data);
            monthData.push(value.month);
            thirdPartyYear = value.year;
          });
          this.savedThirdPartyChartsOptions.xAxis.categories = monthData;
          this.savedThirdPartyChartsOptions.series.push({
            name: `Year ${thirdPartyYear}`,
            data: thirdPartyData
          });
          this.savedThirdParties = true;
          this.savedThirdPartiesLoading = false;
        } else {
          this.totalNumberOfThirdParties = 0;
          this.savedThirdPartyChartsOptions.series.push({
            name: "Year",
            data: []
          });
          this.savedThirdPartiesLoading = false;
        }
      } catch (error) {
        this.savedThirdPartiesLoading = false;
      }
    },
    async getMonitoringAlertsCountThisMonth() {
      this.savedThirdPartiesLoading = true;
      const endDate = new Date();
      const startDate = new Date(
        Date.UTC(endDate.getFullYear(), endDate.getMonth(), 1)
      );
      const formData = {
        startDate,
        endDate,
        companyId: this.companyId
      };

      try {
        this.isLoading = true;
        let result = await HTTP(
          "post",
          "chart/monitoring/alerts",
          formData,
          makeAuthorizationHeader(),
          this.getQueryParamExcludeSbos(false)
        );

        if (result.data.status == 1 && result.status == 200) {
          this.totalNumberOfMonitoringAlertsThisMonth = result.data.omrTotal;
          this.totalNumberOfThirdPartiesAlertsThisMonth = result.data.tpAlerts;
          this.savedThirdParties = true;
          this.savedThirdPartiesLoading = false;
        }
      } catch (err) {
        this.savedThirdPartiesLoading = false;
        return [err];
      }
    },
    async getMonitoringAlertsCountLastMonth() {
      let currentDate = new Date();
      let lastMonthEndDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      let lastMonthStartDate = new Date(
        lastMonthEndDate.getFullYear(),
        lastMonthEndDate.getMonth(),
        -29
      );
      this.savedThirdPartiesLoading = true;
      let formData = {
        startDate: lastMonthStartDate,
        endDate: lastMonthEndDate,
        companyId: this.companyId
      };
      try {
        this.isLoading = true;
        let result = await HTTP(
          "post",
          "chart/monitoring/alerts",
          formData,
          makeAuthorizationHeader(),
          this.getQueryParamExcludeSbos(false)
        );

        if (result.data.status == 1 && result.status == 200) {
          this.totalNumberOfMonitoringAlertsLastMonth = result.data.omrTotal;
          this.totalNumberOfThirdPartiesAlertsLastMonth = result.data.tpAlerts;
          this.savedThirdParties = true;
          this.savedThirdPartiesLoading = false;
        }
      } catch (err) {
        this.savedThirdPartiesLoading = false;
        return [err];
      }
    },
    async getMonitoringAlertsCountAllTime() {
      try {
        this.savedThirdPartiesLoading = true;
        this.isLoading = true;
        let result = await HTTP(
          "post",
          "chart/monitoring/alerts",
          {
            companyId: this.companyId
          },
          makeAuthorizationHeader(),
          this.getQueryParamExcludeSbos(false)
        );

        if (result.data.status == 1 && result.status == 200) {
          this.totalNumberOfMonitoringAlertsAllTime = result.data.omrTotal;
          this.totalNumberOfThirdPartiesAlertsAllTime = result.data.tpAlerts;
          this.savedThirdParties = true;
          this.savedThirdPartiesLoading = false;
        }
      } catch (err) {
        this.savedThirdPartiesLoading = false;
        return [err];
      }
    },
    async tpPriChartsData() {
      this.priActiveSanctionsChartLoading = true;
      try {
        let result = await HTTP(
          "post",
          "chart/pri",
          {
            companyId: this.companyId
          },
          makeAuthorizationHeader()
        );
        if (result.data.status === 1 && result.status === 200) {
          const self = this;

          this.priChartOptions.series.push({
            name: "",
            point: {
              events: {
                click() {
                  let convertedName;

                  switch (this.name) {
                    case "Red":
                      convertedName = "Red PRI";
                      break;
                    case "Amber":
                      convertedName = "Amber PRI";
                      break;
                    case "Green":
                      convertedName = "Green PRI";
                      break;
                    default:
                      convertedName = this.name;
                  }

                  self.mapDrillDownOptions.tableTitle = convertedName;
                  self.mapDrillDownOptions.modalTitle =
                    "Perceived Risk Indicator Results";
                  self.mapDrillDownOptions.querySpecificData = {
                    sanctionsEnforcementsType: convertedName
                  };
                  self.mapDrillDownOptions.tableId = "perceivedRiskIndicator";
                }
              }
            },
            data: [
              {
                name: "Red",
                y: result.data.data.highPri ? result.data.data.highPri : 0
              },
              {
                name: "Amber",
                y: result.data.data.mediumPri ? result.data.data.mediumPri : 0
              },
              {
                name: "Green",
                y: result.data.data.lowPri ? result.data.data.lowPri : 0
              },
              {
                name: "No PRI",
                y: result.data.data.noPri ? result.data.data.noPri : 0
              }
            ]
          });
          this.priActiveSanctionsChart = true;
          this.priActiveSanctionsChartLoading = false;
        } else {
          this.priChartOptions.series.push({
            name: "",
            data: []
          });
          this.priActiveSanctionsChartLoading = false;
        }
      } catch (error) {
        return [error];
      }
    },
    async getSanctionsAlertsCount() {
      this.priActiveSanctionsChartLoading = true;
      try {
        this.priActiveSanctionsChartLoading = true;
        let result = await HTTP(
          "get",
          `chart/sanctions/alerts/${this.companyId}`,
          null,
          makeAuthorizationHeader(),
          this.getQueryParamExcludeSbos(false)
        );
        if (result.data.status == 1 && result.status == 200) {
          const self = this;

          this.SanctionsAlertsCountChartOptions.series.push({
            name: "",
            point: {
              events: {
                click() {
                  self.mapDrillDownOptions.tableTitle = this.name;
                  self.mapDrillDownOptions.querySpecificData = {
                    sanctionsEnforcementsType: this.name
                  };
                  self.mapDrillDownOptions.modalTitle =
                    "Sanctions & Enforcements Results (IDD)";
                  self.mapDrillDownOptions.tableId =
                    queryObjectKeys.SANCTIONS_ENFORCEMENT;
                }
              }
            },
            data: [
              {
                name: "No S&E Alert",
                y: result.data.noSanctionsAlerts
                  ? result.data.noSanctionsAlerts
                  : 0
              },
              {
                name: "S&E Alert",
                y: result.data.sanctionsAlerts ? result.data.sanctionsAlerts : 0
              },
              {
                name: "No IDD",
                y: result.data.no_idd ? result.data.no_idd : 0
              }
            ]
          });
          this.priActiveSanctionsChart = true;
          this.priActiveSanctionsChartLoading = false;
        } else {
          this.SanctionsAlertsCountChartOptions.series.push({
            name: "",
            data: []
          });
          this.priActiveSanctionsChartLoading = false;
        }
      } catch (error) {
        this.priActiveSanctionsChartLoading = false;
      }
    },
    async getIDDPlusPreRemediationCount() {
      this.priActiveSanctionsChartLoading = true;
      try {
        this.priActiveSanctionsChartLoading = true;
        let result = await HTTP(
          "get",
          `chart/iddplus/preremediation/${this.companyId}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.data.status == 1 && result.status == 200) {
          const self = this;

          this.iddPlusPreRemediationCountChartOptions.series.push({
            name: "",
            point: {
              events: {
                click() {
                  self.mapDrillDownOptions.tableTitle = this.name;
                  self.mapDrillDownOptions.querySpecificData = {
                    iddPlusPreRemediationType: this.name
                  };
                  self.mapDrillDownOptions.modalTitle =
                    "Instant Due Diligence PLUS Results Pre-Remediation";
                  self.mapDrillDownOptions.tableId = "iDDPreRemediation";
                }
              }
            },
            data: [
              {
                name: "False Positive",
                y: result.data.falsePositive_green
                  ? result.data.falsePositive_green
                  : 0
              },
              {
                name: "No IDD+ Alerts",
                y: result.data.noidd_noordered ? result.data.noidd_noordered : 0
              },
              {
                name: "Alerts",
                y: result.data.alerts_red ? result.data.alerts_red : 0
              }
            ]
          });
          this.priActiveSanctionsChart = true;
          this.priActiveSanctionsChartLoading = false;
        } else {
          this.iddPlusPreRemediationCountChartOptions.series.push({
            name: "",
            data: []
          });
          this.priActiveSanctionsChartLoading = false;
        }
      } catch (error) {
        this.priActiveSanctionsChartLoading = false;
      }
    },
    async getIDDPlusPostRemediationCount() {
      this.iddPlusPostRemediationEddResultCountTpCountriesChartLoading = true;
      try {
        this.iddPlusPostRemediationEddResultCountTpCountriesChartLoading = true;
        let result = await HTTP(
          "get",
          `chart/iddplus/postremediation/${this.companyId}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.data.status == 1 && result.status == 200) {
          const self = this;

          this.iddPlusPostRemediationCountChartOptions.series.push({
            name: "",
            point: {
              events: {
                click() {
                  self.mapDrillDownOptions.tableTitle = this.name;
                  self.mapDrillDownOptions.querySpecificData = {
                    iddPlusPostRemediationType: this.name
                  };
                  self.mapDrillDownOptions.modalTitle =
                    "Instant Due Diligence PLUS Results Post-Remediation";
                  self.mapDrillDownOptions.tableId = "iDDPostRemediation";
                }
              }
            },
            data: [
              {
                name: iddPlusPostRemediationName.NO_IDD_ORDERED,
                y: result?.data?.noidd_noordered || 0
              },
              {
                name: iddPlusPostRemediationName.REMEDIATION_GREEN,
                y: result?.data?.remediatedGreen || 0
              },
              {
                name: iddPlusPostRemediationName.REMEDIATION_IN_PROGRESS,
                y: result?.data?.remediatedInProcess || 0
              },
              {
                name: iddPlusPostRemediationName.REMEDIATION_RED,
                y: result?.data?.remediatedRed || 0
              },
              {
                name: iddPlusPostRemediationName.REMEDIATION_NOT_ORDERED,
                y: result?.data?.remediationNotOrderd || 0
              }
            ]
          });
          this.iddPlusPostRemediationEddResultCountTpCountriesChart = true;
          this.iddPlusPostRemediationEddResultCountTpCountriesChartLoading = false;
        } else {
          this.iddPlusPostRemediationCountChartOptions.series.push({
            name: "",
            data: []
          });
          this.iddPlusPostRemediationEddResultCountTpCountriesChartLoading = false;
        }
      } catch (error) {
        this.iddPlusPostRemediationEddResultCountTpCountriesChartLoading = false;
      }
    },
    async getEddResultCount() {
      this.iddPlusPostRemediationEddResultCountTpCountriesChartLoading = true;
      try {
        this.iddPlusPostRemediationEddResultCountTpCountriesChartLoading = true;
        let result = await HTTP(
          "get",
          `chart/edd/results/${this.companyId}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.data.status == 1 && result.status == 200) {
          const self = this;

          this.eddResultCountChartOptions.series.push({
            name: "",
            point: {
              events: {
                click() {
                  self.mapDrillDownOptions.tableTitle = this.name;
                  self.mapDrillDownOptions.querySpecificData = {
                    enhancedDueDiligenceType: this.name
                  };
                  self.mapDrillDownOptions.modalTitle =
                    "Enhanced Due Diligence Results";
                  self.mapDrillDownOptions.tableId = "enhancedDueDiligence";
                }
              }
            },
            data: [
              {
                name: "EDD Green",
                y: result.data.eddGreen ? result.data.eddGreen : 0
              },
              {
                name: "EDD Not Ordered",
                y: result.data.eddNotOrdered ? result.data.eddNotOrdered : 0
              },
              {
                name: "EDD Red",
                y: result.data.eddRed ? result.data.eddRed : 0
              }
            ]
          });
          this.iddPlusPostRemediationEddResultCountTpCountriesChart = true;
          this.iddPlusPostRemediationEddResultCountTpCountriesChartLoading = false;
        } else {
          this.eddResultCountChartOptions.series.push({
            name: "",
            data: []
          });
          this.iddPlusPostRemediationEddResultCountTpCountriesChartLoading = false;
        }
      } catch (error) {
        this.iddPlusPostRemediationEddResultCountTpCountriesChartLoading = false;
      }
    },
    async getTpCountries() {
      this.iddPlusPostRemediationEddResultCountTpCountriesChartLoading = true;
      try {
        this.iddPlusPostRemediationEddResultCountTpCountriesChartLoading = true;
        let result = await HTTP(
          "get",
          `chart/thirdparty/country/${this.companyId}`,
          null,
          makeAuthorizationHeader(),
          this.getQueryParamExcludeSbos()
        );
        if (result.data.status == 1 && result.status == 200) {
          const self = this;

          this.tpCountriesChartOptions.series.push({
            name: "",
            point: {
              events: {
                click() {
                  self.mapDrillDownOptions.tableTitle = this.name;
                  self.mapDrillDownOptions.querySpecificData = {
                    country_name: this.name
                  };
                  self.mapDrillDownOptions.modalTitle =
                    "Third Parties by Country/Territory of Registration";
                  self.mapDrillDownOptions.tableId = "thirdPartyCountries";
                }
              }
            },
            data: result.data.data
          });
          this.iddPlusPostRemediationEddResultCountTpCountriesChart = true;
          this.iddPlusPostRemediationEddResultCountTpCountriesChartLoading = false;
          this.isPageLoaded = false;
        } else {
          this.tpCountriesChartOptions.series.push({
            name: "",
            data: []
          });
          this.iddPlusPostRemediationEddResultCountTpCountriesChartLoading = false;
          this.isPageLoaded = false;
        }
      } catch (error) {
        this.iddPlusPostRemediationEddResultCountTpCountriesChartLoading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.analytics-tab {
  &__header {
    place-content: flex-end;
  }

  &__content {
    height: calc(100vh - 10rem);
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 32px;

    &-map-chart {
      border: none;
    }
  }

  &__cards-wrapper {
    gap: 16px;
    margin: 0 16px 16px;

    &-item {
      flex: 1;
      min-width: 380px;
    }
  }
}
.nav-tabs li.nav-item {
  margin-right: 1px !important;
}
</style>
