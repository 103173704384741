import { getAllSelectedOptionValues, getSelectedOption } from "@/utils";
import { customErrorMessages } from "@/constants";

const isFilterCriteriaValueValid = ({
  formRow,
  _getAllSelectedOptionValues = getAllSelectedOptionValues
}) => {
  if (
    formRow.availableFilterValueComponentOptions?.value ||
    _getAllSelectedOptionValues(
      formRow.availableFilterValueComponentOptions?.options
    )?.length
  ) {
    formRow.availableFilterValueComponentOptions.error = "";
    return true;
  }

  formRow.availableFilterValueComponentOptions.error =
    customErrorMessages.generic.required;
  return false;
};

const isFilterCriteriaValid = ({
  formRow = {},
  _getSelectedOption = getSelectedOption
} = {}) => {
  const { value } = _getSelectedOption(formRow.availableFilterOptions);
  if (value) {
    formRow.availableFilterError = "";
    return true;
  }

  formRow.availableFilterError = customErrorMessages.generic.required;
  return false;
};

const isFilterValid = ({
  searchFilterBarDetails,
  _isFilterCriteriaValueValid = isFilterCriteriaValueValid,
  _isFilterCriteriaValid = isFilterCriteriaValid
} = {}) =>
  !searchFilterBarDetails?.filterOptions
    ?.reduce?.((acc, formRow) => {
      acc.push(_isFilterCriteriaValueValid({ formRow }));
      acc.push(_isFilterCriteriaValid({ formRow }));
      return acc;
    }, [])
    .includes(false);

export { isFilterCriteriaValueValid, isFilterCriteriaValid, isFilterValid };
