<template>
  <th
    ref="tableHeader"
    :class="['table-header', isButtonSortable() && `table-header--sortable`]"
    data-test-id="table-header"
    :aria-sort="sortOrder"
    :style="styles"
  >
    <!-- eslint-disable-next-line vue/require-component-is -->
    <component v-if="component" v-bind="componentOptions" :is="component" />
    <template v-else>
      <button
        v-if="sortable"
        type="button"
        class="table-header__button"
        :style="{ minHeight: `${buttonHeight}px` }"
        data-test-id="table-header__button"
        @click="onSortColumn"
      >
        {{ tableHeader }}
        <font-awesome-icon
          :icon="getIcon"
          data-test-id="table-header__button-icon"
        />
      </button>
      <BaseText
        v-else
        tag="span"
        data-test-id="table-header__text"
        :text="tableHeader"
        :size="typographySize.BODY_TEXT_BOLD"
      />
    </template>
  </th>
</template>

<script>
import { sortOrder as sortOrderEnum, typographySize } from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";
import { nextTick } from "vue";

export default {
  name: "TableHeader",
  props: {
    component: {
      type: Object,
      default: () => null
    },
    componentOptions: {
      type: Object,
      default: () => null
    },
    tableHeader: {
      type: String,
      default: ""
    },
    sortable: {
      type: Boolean,
      default: false
    },
    sortOrder: {
      type: String,
      default: "none",
      validator(value) {
        return [
          sortOrderEnum.NONE,
          sortOrderEnum.ASCENDING,
          sortOrderEnum.DESCENDING
        ].includes(value);
      }
    },
    headerPosition: {
      type: Number,
      required: true
    },
    styles: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    BaseText
  },
  data() {
    return {
      buttonHeight: 0,
      sortOrderIcon: {
        [sortOrderEnum.NONE]: "sort",
        [sortOrderEnum.ASCENDING]: "sort-up",
        [sortOrderEnum.DESCENDING]: "sort-down"
      },
      typographySize
    };
  },
  computed: {
    getIcon() {
      return this.sortOrderIcon[this.sortOrder] || "";
    },
    getOppositeSortOrder() {
      return this.sortOrder === sortOrderEnum.ASCENDING
        ? sortOrderEnum.DESCENDING
        : sortOrderEnum.ASCENDING;
    }
  },
  created() {
    this.getButtonHeight();
  },
  methods: {
    isButtonSortable() {
      return !this.component && this.sortable;
    },
    async getButtonHeight() {
      await nextTick();

      this.buttonHeight = this.$refs.tableHeader?.clientHeight || 0;
    },
    onSortColumn() {
      if (this.sortable) {
        this.$emit("click", this.headerPosition, this.getOppositeSortOrder);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.table-header {
  position: sticky;
  top: 0;
  min-width: 150px;
  background-color: $athens-gray;
  border-right: none;
  padding: 10px;
  text-align: left;
  font-weight: 500;
  line-height: 1.2;
  transition: background-color 250ms ease;
  z-index: 2;

  &--sortable {
    padding: 0;
  }

  &__button {
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    background: $platinum;

    &:focus,
    &:hover {
      background: $white;
    }
  }
}
</style>
