<template>
  <div class="ubo-entity-profile">
    <BaseLoader
      v-if="UBOEntityDataIsLoading"
      class="ubo-entity-profile__loader"
      data-test-id="ubo-entity-profile__loader"
    />
    <div
      v-else-if="hasUBOEntityProfileData"
      data-test-id="ubo-entity-profile__tables"
      class="ubo-entity-profile__tables"
    >
      <div class="ubo-entity-profile__row-column">
        <StickyTable
          class="ubo-entity-profile__row-column-table"
          caption="Details"
          :table-headers="tableHeaders"
          :table-rows="detailsTableRows"
        />
        <StickyTable
          class="ubo-entity-profile__row-column-table"
          caption="Business entity details"
          :table-headers="tableHeaders"
          :table-rows="businessEntityDetailsTableRows"
        />
      </div>
      <div class="ubo-entity-profile__row-column">
        <StickyTable
          class="ubo-entity-profile__row-column-table"
          caption="Ownership details"
          :table-headers="tableHeaders"
          :table-rows="ownershipDetailsTableRows"
        />
      </div>
    </div>
    <div class="ubo-entity-profile__error-message">
      <BaseText :text="UBOEntityProfileErrorMessage" />
    </div>
  </div>
</template>

<script>
import StickyTable from "@/molecules/StickyTable/StickyTable";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader.vue";

export default {
  name: "UltimateBeneficialOwnersEntityProfile",
  components: {
    StickyTable,
    BaseText,
    BaseLoader
  },
  props: {
    UBOEntityProfileData: {
      type: Object,
      default: () => {}
    },
    UBOEntityProfileErrorMessage: {
      type: String,
      default: ""
    },
    UBOEntityDataIsLoading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    hasUBOEntityProfileData() {
      this.initialize();
    }
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      tableHeaders: [
        {
          value: "label",
          styles: {
            display: "none"
          }
        },
        {
          value: "value",
          styles: {
            display: "none"
          }
        }
      ],
      detailsTableRows: [],
      ownershipDetailsTableRows: [],
      businessEntityDetailsTableRows: []
    };
  },
  computed: {
    hasUBOEntityProfileData() {
      return !!Object.keys(this.UBOEntityProfileData)?.length;
    },
    detailsTableDataMap() {
      return [
        {
          label: "Name",
          value: this.UBOEntityProfileData?.name || "-"
        },
        {
          label: "Type",
          value: this.UBOEntityProfileData?.beneficiaryType || "-"
        },
        {
          label: "DOB",
          value: this.UBOEntityProfileData?.birthDate || "-"
        },
        {
          label: "Nationality",
          value: this.UBOEntityProfileData?.nationality || "-"
        },
        {
          label: "Country",
          value: this.UBOEntityProfileData?.addressCountry || "-"
        }
      ];
    },
    ownershipDetailsTableDataMap() {
      return [
        {
          label: "Is person of significant control",
          value: this.UBOEntityProfileData?.isPSC || "-"
        },
        {
          label: "Direct ownership %",
          value: `${
            this.UBOEntityProfileData?.directOwnershipPercentage || "-"
          }`
        },
        {
          label: "Indirect ownership %",
          value: `${
            this.UBOEntityProfileData?.indirectOwnershipPercentage || "-"
          }`
        },
        {
          label: "Beneficial ownership %",
          value: `${
            this.UBOEntityProfileData?.beneficialOwnershipPercentage || "-"
          }`
        },
        {
          label: "Out of business",
          value: this.UBOEntityProfileData?.isOutOfBusiness || "-"
        },
        {
          label: "Control ownership",
          value: this.UBOEntityProfileData?.controlOwnershipType || "-"
        },
        {
          label: "Degree of separation",
          value: `${this.UBOEntityProfileData?.degreeOfSeparation || "-"}`
        }
      ];
    },
    businessEntityDetailsTableDataMap() {
      return [
        {
          label: "Owned entity",
          value: this.UBOEntityProfileData?.ownedEntity || "-"
        },
        {
          label: "Business ownership type",
          value: this.UBOEntityProfileData?.businessEntityType || "-"
        },
        {
          label: "Address",
          value: this.getBusinessAddress()
        }
      ];
    }
  },
  methods: {
    initialize() {
      if (this.hasUBOEntityProfileData) {
        this.setTableData();
      }
    },
    setTableData() {
      this.setDetailsTableRows();
      this.setOwnershipDetailsTableRows();
      this.setBusinessEntityDetailsTableDataRows();
    },
    setDetailsTableRows() {
      this.detailsTableRows = this.makeTableRowData(this.detailsTableDataMap);
    },
    setOwnershipDetailsTableRows() {
      this.ownershipDetailsTableRows = this.makeTableRowData(
        this.ownershipDetailsTableDataMap
      );
    },
    setBusinessEntityDetailsTableDataRows() {
      this.businessEntityDetailsTableRows = this.makeTableRowData(
        this.businessEntityDetailsTableDataMap
      );
    },
    makeTableRowData(dataMap) {
      return dataMap.map(({ label, value }) => ({
        label: {
          component: BaseText,
          componentOptions: {
            tag: "span",
            text: label
          },
          styles: {
            border: "none",
            width: "50%",
            verticalAlign: "baseline",
            padding: "10px 4px 2px 15px"
          }
        },
        value: {
          component: BaseText,
          componentOptions: {
            tag: "span",
            text: value
          },
          styles: {
            border: "none",
            verticalAlign: "baseline",
            padding: "10px 4px 2px 15px"
          }
        }
      }));
    },
    getBusinessAddress() {
      if (
        this.UBOEntityProfileData?.addressCountry &&
        this.UBOEntityProfileData?.address &&
        this.UBOEntityProfileData?.addressLocality
      ) {
        return `${this.UBOEntityProfileData?.address}, ${this.UBOEntityProfileData?.addressLocality} ${this.UBOEntityProfileData?.addressCountry}`;
      }

      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.ubo-entity-profile {
  height: calc(100vh - 240px);
  overflow-y: auto;

  &__tables {
    display: flex;
  }

  &__row-column {
    width: 100%;

    &-table {
      max-width: 95%;
      margin: 30px 25px 35px 0px;
    }
  }

  &__error-message {
    text-align: center;
    font-size: 20px;
  }

  &__loader {
    margin-top: 24px;
  }

  :deep(.sticky-table__caption) {
    padding-left: 15px;
    font-size: 20px;
    font-weight: 400;
  }
}
</style>
