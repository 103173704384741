<template>
  <div>
    <AppLoader v-if="isLoading" class="text-left" />
    <b-container fluid v-else class="m-0">
      <b-row>
        <!-- language picker wrapper -->

        <!-- form card container -->
        <b-col :cols="mainCols" offset="offset" style="overflow: hidden">
          <b-alert variant="warning" show dismissible v-if="isInvalid">
            Dismissible Alert! Click the close button over there
            <b>&rArr;</b>
          </b-alert>
          <div>
            <b-row>
              <b-col class="text-center">
                <h3>
                  {{
                    form.formTitle
                      ? form.formTitle[selectedLanguage || "english"]
                      : "No Title"
                  }}
                </h3>
                <p>
                  {{
                    form.formDescription
                      ? form.formDescription[selectedLanguage || "english"]
                      : "No Description"
                  }}
                </p>
                <p v-if="!form.formSection.length && form.formType == 1">
                  Please add at least one section to preview the form
                </p>
              </b-col>
            </b-row>

            <div id="scrollspy" class="preview-scrollable-wrapper">
              <div v-if="form.formSection.length" id="element">
                <b-form>
                  <SectionContainer
                    v-for="(sectionData, index) in form.formSection"
                    :id="'section_' + index"
                    :isPreview="true"
                    :selectedLanguage="selectedLanguage"
                    :sectionData="sectionData"
                    :inputType="sectionData.inputType"
                    :key="index"
                    :formType="parseInt(form.formType)"
                  />
                </b-form>
              </div>
            </div>
            <b-row class="mt-3">
              <b-col cols="6">
                <a
                  v-if="form.formSection.length"
                  href="#"
                  class="btn btn-warning"
                  v-scroll-to="{
                    el: '#scrollspy',
                    container: '#scrollspy',
                    duration: 500,
                    easing: 'linear',
                    force: true,
                    x: false,
                    y: true
                  }"
                  >Top</a
                >
              </b-col>
              <b-col>
                <b-button
                  v-if="form.formSection.length"
                  variant="outline-success"
                  :isPreview="true"
                  class="float-right"
                  @click="onSubmit"
                  >Submit</b-button
                >
              </b-col>
            </b-row>
          </div>
        </b-col>

        <!-- end of col-9 preview area -->
        <b-col
          v-if="formType == 1"
          cols="3"
          class="mt-2"
          style="position: relative; overflow-y: auto; height: 500px"
        >
          <b-list-group v-b-scrollspy:scrollspy="50">
            <b-list-group-item
              class="text-left"
              v-for="(section, index) in form.formSection"
              :href="'#section_' + index"
              :key="section.id"
            >
              <font-awesome-icon icon="check-circle" />
              &nbsp;{{ section.sectionTitle[selectedLanguage] }}
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="modal-submit" title="Submited Data">
      <pre class="m-0">{{ userForm }}</pre>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import SectionContainer from "./SectionUserContainer";
import AppLoader from "../components/Loader/Loader";
import { EventBus } from "@/event-bus.js";

export default {
  name: "FormPreviewContainer",
  components: {
    SectionContainer,
    AppLoader
  },
  data() {
    return {
      enabled: true,
      dragging: false,
      selected: "english",
      isInvalid: false,
      formType: null
    };
  },
  computed: {
    ...mapGetters({
      form: "form1",
      selectedLanguage: "selectedLanguage",
      isLoading: "isLoading"
    }),
    mainCols() {
      return this.formType == 1 ? 9 : 12;
    },
    offset() {
      return this.formType == 1 ? 3 : 0;
    },
    ...mapState(["userForm", "form1"])
  },
  mounted() {
    this.formType = this.form.formType;

    let form = JSON.parse(localStorage.getItem("activeFormSchema"));
    this.UPDATE_FORM(form);
  },
  watch: {},

  methods: {
    ...mapActions(["setLanguage"]),
    ...mapMutations(["TOGGLE_LOADER"]),
    ...mapMutations(["UPDATE_FORM"]),
    changeLanguage() {
      let tt = this.selected.toLowerCase();
      this.setLanguage(tt);
      this.TOGGLE_LOADER(100);
    },
    onSubmit() {
      EventBus.emit("onSubmitEndUserForm", "form submit");
    }
  }
};
</script>
<style scoped>
.preview-scrollable-wrapper {
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 300px);
  /* height:400px */
}
.selectPicket {
  width: 350px;
}
</style>
