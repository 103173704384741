<template id="template-input">
  <div
    :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
    :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'"
  >
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :isHidden="isHidden"
      :label="label"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
    />
    <!-- input content -->
    <b-collapse
      v-show="JSON.parse(!fieldData.isHidden) || !isPreview"
      id="collapse-1"
      :class="isPreview ? 'mt-0' : 'mt-5'"
      v-model="collapse"
    >
      <b-row v-if="fieldData.isConditional == '1' && !isPreview">
        <b-col cols="12" class="text-right">
          <b-button
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-left">
          <b-form-group
            :id="fieldData.id"
            :class="rightAlign ? 'text-right' : 'text-left'"
            :description="fieldData.helperText[selectedLanguage]"
            :invalid-feedback="fieldData.validationMessage[selectedLanguage]"
            :title="$t('createProfile.requiredValidationMessage')"
          >
            <label>
              <span
                v-if="
                  fieldData.validation &&
                  JSON.parse(fieldData.validation.required)
                "
                class="required-span"
                >*</span
              >
              {{
                form1.enableSectionFieldEnumeration == 1
                  ? sectionIndex + 1 + "." + (fieldIndex + 1)
                  : ""
              }}
              {{ label ? label : fieldData.label[selectedLanguage] }}
            </label>
            <b-form-input
              :class="rightAlign ? 'text-right' : 'text-left'"
              :id="fieldData.id"
              :disabled="isDisabled == 1"
              trim
              v-model="name"
              @input="onInputType"
              @blur="checkValidate"
              :state="
                isFocus &&
                fieldData.validation &&
                JSON.parse(fieldData.validation.required)
                  ? !v$.name.required
                    ? false
                    : true
                  : null
              "
              @focus="(isFocus = true), $event.target.select()"
              :placeholder="
                placeholder
                  ? placeholder
                  : fieldData.placeholder[selectedLanguage]
              "
            ></b-form-input>
            <span
              v-if="msg.min"
              :class="
                rightAlign
                  ? 'float-right text-danger'
                  : 'float-left text-danger'
              "
              >{{ msg.min }}&nbsp;</span
            >
            <span
              v-if="msg.max"
              :class="
                rightAlign
                  ? 'float-right text-danger'
                  : 'float-left text-danger'
              "
              >{{ msg.max }}&nbsp;</span
            >
          </b-form-group>
        </b-col>
      </b-row>

      <!-- show advance field options -->
      <b-modal
        cancel-title="Close"
        ok-title="save"
        @ok="onSaveData"
        size="xl"
        v-model="showSettings"
        scrollable
        title="Input Advanced Settings"
      >
        <AdvanceSettings
          :fieldData="fieldData"
          v-on:toggle-field="showSettings = !showSettings"
          :sectionIndex="sectionIndex"
          :fieldIndex="fieldIndex"
        />
      </b-modal>
    </b-collapse>
    <b-alert
      v-model="showErrorAlert"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000"
      variant="danger"
      dismissible
      solid
      :change="hideAllError()"
      >One or more Errors Found, please have a look.!</b-alert
    >
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import AdvanceSettings from "../advanceSettingsCompenent.vue";
import MenuButtons from "../menuButtons";
import { mapGetters, mapState } from "vuex";
import { EventBus } from "@/event-bus.js";
import { Helper } from "@/helpers";
import _ from "lodash";
import { routeKeys, routeNames } from "@/constants";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "IputGroup",
  components: {
    AdvanceSettings,
    MenuButtons
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      isHidden: false,
      condition: {},
      showToolTip: false,
      isFocus: false,
      isError: false,
      isSuccess: false,
      label: undefined,
      placeholder: undefined,
      helperText: undefined,
      tooltipText: undefined,
      showSettings: false,
      msg: [],
      isRequired: 1,
      collapse: true,
      name: this.fieldData.value || null,
      showErrorAlert: false
    };
  },

  created() {
    EventBus.on("onSubmitEndUserForm", (value) => {
      if (value == "progress" || this.fieldData.isHidden) {
        this.$store.state.validFields = true;
        this.fieldData.isInValid = false;
      } else {
        if (
          this.fieldData.validation &&
          this.fieldData.validation.required == 1
        ) {
          if (this.v$ && !this.v$.$invalid) {
            // alert("submit success");
            this.$store.state.validFields = true;
            this.fieldData.isInValid = false;
          } else {
            this.isFocus = true;
            this.$store.state.validFields = false;
            this.fieldData.isInValid = true;
          }
        }
      }
    });

    EventBus.on("OnInvalidFieldCatch", (inValidData) => {
      document
        .getElementById(inValidData.elementId)
        .scrollIntoView({ behavior: "smooth" });
    });

    EventBus.on("onUpdateValues", () => {
      // this.setFieldValue();
    });
    EventBus.on("onUpdateInputValues", () => {
      this.setFieldValue();
    });
  },

  mounted() {
    // this.checkForConditional();
    this.isRequired = this.fieldData.validation.required;
    if (
      [routeNames[routeKeys.USER_FORM_PAGE], "MyCompanyView"].includes(
        this.$route.name
      )
    ) {
      this.setFieldValue();
    }
  },

  validations: {
    name: {
      required
      // alpha,
    }
  },

  computed: {
    ...mapGetters([
      "form1",
      "selectedLanguage",
      "userForm",
      "userFormSubmitArray",
      "rightAlign"
    ]),
    ...mapState(["toggleAllCoppalse", "rightAlign"])
  },

  watch: {
    toggleAllCoppalse(value) {
      this.collapse = value;
    },

    name() {
      this.$store.state.form1.formSection[this.sectionIndex].isInValid = this.v$
        .name.$invalid
        ? 1
        : 0;
      if (this.fieldData.validation && this.fieldData.validation.min) {
        this.checkLength(this.fieldData.validation.min, "min");
      }
      if (this.fieldData.validation && this.fieldData.validation.max) {
        this.checkLength(this.fieldData.validation.max, "max");
      }
    }
  },

  methods: {
    checkLength(length, type) {
      if (type === "min") {
        if (this.name.length < parseInt(length)) {
          this.msg.min =
            this.fieldData.minValidateMessage.english || "Not valid";
        } else {
          this.msg.min = "";
        }
      } else if (type === "max") {
        if (this.name.length > parseInt(length)) {
          this.msg.max =
            this.fieldData.maxValidateMessage.english || "Not valid";
        } else {
          this.msg.max = "";
        }
      } else {
        return true;
      }
    },

    hideAllError() {
      if (this.showErrorAlert) {
        this.showErrorAlert = false;
      }
    },

    checkValidate() {
      if (this.name?.length >= 3) {
        (this.isError = false), (this.isSuccess = true);
      } else {
        (this.isError = true), (this.isSuccess = false);
      }
    },

    setFieldValue() {
      this.$store.state.getUserFormSubmitArray.length &&
        this.$store.state.getUserFormSubmitArray.forEach((a) => {
          if (a && a.elementId && a.elements && a.elements.length) {
            if (a.elementId === this.fieldData.id) {
              a.elements.forEach((el) => {
                this.name = el.postValue || null;
                this.fieldData.isHidden = !el.postValue;
              });
            }
          }
        });
    },

    onInputType(e) {
      const registerData = _.clone(this.$store.state.registerData);
      if (
        [routeNames[routeKeys.USER_FORM_PAGE], "MyCompanyView"].includes(
          this.$route.name
        )
      ) {
        this.name = e;

        if (e && e.length > 0) {
          registerData[this.fieldData.id] = e;
        } else {
          delete registerData[this.fieldData.id];
        }
        this.$store.state.registerData = registerData;

        // main calculations :p
        let numkeys = Object.keys(registerData).length;
        this.$store.state.progressValue = numkeys;

        // let sectionLength = this.form1.formSection.length > 1 ? this.form1.formSection[this.sectionIndex].formElements.length : 1

        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId === this.fieldData.id) {
            a.elements[0] = {
              postValue: e,
              postName:
                (this.fieldData.attributes &&
                  this.fieldData.attributes.postName) ||
                "postNameInput"
            };
          }
        });
      }
    },

    onSaveData() {
      EventBus.emit("onSaveSettings", "saving settings");
      Helper.makeToast(this.$bvToast, "success", "Settings Updated");
    },

    checkForConditional() {
      if (this.fieldData.isConditional == 1) {
        this.fieldData.isHidden = false;
      }
    }
  },
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
<style lang="scss">
.required-span {
  font-size: 24px;
  margin-left: 3px;
  color: $spanish-red;
}
/* .form-control.is-valid{
	background-position: left calc(0.375em + 0.1875rem) center

}
.form-control.is-invalid{
	background-position :left calc(0.375em + 0.1875rem) center

} */
</style>
