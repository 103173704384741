<template>
  <div class="row">
    <b-col cols="12">
      <b-tabs content-class="mt-3">
        <!-- Text -->
        <b-tab title="Text" active>
          <b-tabs pills card vertical>
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab
              @click="tabLabelClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <!-- translation form wrappe -->
              <div>
                <b-form-group label="Title">
                  <b-form-input
                    @blur="addLableLanguageToField(language)"
                    v-model="label"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Description">
                  <b-form-textarea
                    @blur="addLableLanguageToField(language)"
                    v-model="description"
                  ></b-form-textarea>
                </b-form-group>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Address label">
                      <b-form-input
                        @blur="addLableLanguageToField(language)"
                        v-model="addressTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Address 2 Label">
                      <b-form-input
                        @blur="addLableLanguageToField(language)"
                        v-model="addressTwoTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Town label">
                      <b-form-input
                        @blur="addLableLanguageToField(language)"
                        v-model="townTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="City / State label">
                      <b-form-input
                        @blur="addLableLanguageToField(language)"
                        v-model="cityStateTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Zip / Postal Code Label">
                      <b-form-input
                        @blur="addLableLanguageToField(language)"
                        v-model="zipCodeTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Country Label">
                      <b-form-input
                        @blur="addLableLanguageToField(language)"
                        v-model="countryTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Free Form Label">
                      <b-form-input
                        @blur="addLableLanguageToField(language)"
                        v-model="freeFormTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Helper Text------------------- -->
        <b-tab title="Helper Text">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabHelperTextClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for Address</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="addressHelperText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Helper Text for Address 2</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="addressTwoHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for Town</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="townHelperText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Helper Text for City / State</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="cityStateHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for Zip / Postal Code</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="zipCodeHelperText"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Helper Text for country</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="countryHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for Free Form</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="freeFormHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Place Holder Text------------------- -->
        <b-tab title="Place Holder Text">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabPlaceHolderClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Place Holder for Address</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="addressPlaceHolder"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Place Holder for Address 2</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="addressTwoPlaceHolder"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Place Holder for Town</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="townPlaceHolder"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Place Holder for City / State</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="cityStatePlaceHolder"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Place Holder for Zip / Postal Code</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="zipCodePlaceHolder"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Place Holder for Free Form</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="freeFormPlaceHolder"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Validation Message------------------- -->
        <b-tab title="Validation Message">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabValidationMessageClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message for Address</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="addressValidationMessage"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Validation Message for Address 2</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="addressTwoValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message for Town</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="townValidationMessage"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Validation Message for City / State</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="cityStateValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message for Zip / Postal Code</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="zipCodeValidationMessage"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Validation Message for country</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="countryValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message for Free Form</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="freeFormValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <b-tab title="Configure">
          <b-form-group class="w-50 mb-5" label="Selec County List Type">
            <v-select
              :id="fieldData.id"
              searchable
              clearable
              :options="renderOptions"
              label="text"
              class="mb-5"
              v-model="fieldData.selectedListTypeIndex"
            />
          </b-form-group>
        </b-tab>

        <!-- ----------------Attributes------------------- -->
        <b-tab title="Attributes">
          <b-form @submit.stop.prevent class="text-left mt-2">
            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Address</label>
                <b-form-input v-model="addressPostName"></b-form-input>
              </b-col>

              <b-col cols="6">
                <label for>Post name for Address 2</label>
                <b-form-input v-model="addressTwoPostName"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Town</label>
                <b-form-input v-model="townPostName"></b-form-input>
              </b-col>

              <b-col cols="6">
                <label for>Post name for City / State</label>
                <b-form-input v-model="cityStatePostName"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Zip / Postal Code</label>
                <b-form-input v-model="zipCodePostName"></b-form-input>
              </b-col>
              <b-col cols="6">
                <label for>Post name for country</label>
                <b-form-input v-model="countryPostName"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Free Form</label>
                <b-form-input v-model="freeFormPostName"></b-form-input>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>

        <!-- ----------------Advance------------------->
        <b-tab title="Advanced">
          <b-row>
            <b-col cols="10">
              <b-form-checkbox class="text-left mb-3" v-model="addressDisabled"
                >Disable Address?</b-form-checkbox
              >

              <b-form-checkbox
                class="text-left mb-3"
                v-model="addressTwoDisabled"
                >Disable Address 2?</b-form-checkbox
              >

              <b-form-checkbox class="text-left mb-3" v-model="townDisabled"
                >Disable Town?</b-form-checkbox
              >

              <b-form-checkbox
                class="text-left mb-3"
                v-model="cityStateDisabled"
                >Disable City / State?</b-form-checkbox
              >

              <b-form-checkbox class="text-left mb-3" v-model="zipCodeDisabled"
                >Disable Zip / Postal Code</b-form-checkbox
              >

              <b-form-checkbox class="text-left mb-3" v-model="countryDisabled"
                >Disable country</b-form-checkbox
              >
              <b-form-checkbox class="text-left mb-3" v-model="freeFormDisabled"
                >Disable Free Form</b-form-checkbox
              >
            </b-col>
          </b-row>
        </b-tab>

        <!-- data validation -->
        <b-tab title="Validation">
          <div class="text-left">
            <b-col>
              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireAddress"
                >Is required Address?</b-form-checkbox
              >

              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireAddressTwo"
                >Is required Address 2?</b-form-checkbox
              >

              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireTown"
                >Is required Town?</b-form-checkbox
              >

              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireCityState"
                >Is required City / State?</b-form-checkbox
              >

              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireZipCode"
                >Is required Zip / Postal Code</b-form-checkbox
              >

              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireCountry"
                >Is required country</b-form-checkbox
              >

              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireFreeForm"
                >Is required Free Form</b-form-checkbox
              >
            </b-col>
          </div>
        </b-tab>
        <!-- --------------Conditional------------------ -->
        <b-tab title="Conditional">
          <b-tabs pills card vertical>
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab title="Simple">
              <b-row> </b-row>
              <b-col cols="12" class="mr-0">
                <b-form-checkbox
                  v-model="isConditional"
                  name="conditional"
                  value="1"
                  unchecked-value="0"
                  >Is Conditional?</b-form-checkbox
                >

                <div v-if="isConditional == 1">
                  <b-row>
                    <table class="table table-sm">
                      <thead>
                        <tr class="text-center">
                          <th>No</th>
                          <th>When Field</th>
                          <th>Has Value</th>
                          <th>Actions</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(selectedOpt, index) in fieldData.conditions"
                          :key="selectedOpt.id"
                        >
                          <td>{{ index }}</td>
                          <td>
                            <b-form-input disabled :value="selectedOpt.label" />
                          </td>
                          <td>
                            <b-form-input disabled :value="selectedOpt.value" />
                          </td>
                          <td>
                            <b-button
                              variant="outline-danger"
                              class="btn-sm ml-1"
                              @click="
                                onRemoveConditionOpt(selectedOpt.id, index)
                              "
                            >
                              <i class="fa fa-trash"></i>
                            </b-button>
                          </td>
                        </tr>

                        <tr
                          v-for="(noOfOption, index) in noOfConditions"
                          :key="index"
                        >
                          <td>{{ fieldData.conditions.length }}</td>
                          <td>
                            <v-select
                              v-model="conditionalField"
                              class="mb-2"
                              :options="conditionalSectionElementLables"
                              label="label"
                              @update:modelValue="getSelectedElement"
                            ></v-select>
                          </td>
                          <td>
                            <v-select
                              class="mb-2"
                              label="text"
                              v-model="conditionalFieldValue"
                              :options="selectedElementOption"
                            ></v-select>
                          </td>

                          <td>
                            <b-button
                              class="btn-sm"
                              variant="outline-primary"
                              @click="setFieldConditions"
                              >Apply</b-button
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-row>
                </div>
              </b-col>
            </b-tab>
          </b-tabs>
        </b-tab>
      </b-tabs>
    </b-col>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import list from "@/utils/countryListTypes.json";

export default {
  name: "CorporateShareHoldersSettings",
  props: {
    fieldData: Object,
    sectionIndex: Number,
    fieldIndex: Number
  },
  components: {},

  data() {
    return {
      transalationLanguage: "english",
      // Input General

      description: null,
      // labels
      label: null,
      addressTitle: null,
      cityStateTitle: null,
      townTitle: null,
      addressTwoTitle: null,
      zipCodeTitle: null,
      countryTitle: null,
      freeFormTitle: null,

      // Helper Text
      addressHelperText: null,
      cityStateHelperText: null,
      townHelperText: null,
      addressTwoHelperText: null,
      zipCodeHelperText: null,
      countryHelperText: null,
      freeFormHelperText: null,

      // Place Holder Text
      addressPlaceHolder: null,
      addressTwoPlaceHolder: null,
      cityStatePlaceHolder: null,
      townPlaceHolder: null,
      zipCodePlaceHolder: null,
      freeFormPlaceHolder: null,

      // Validation Message
      addressValidationMessage: null,
      cityStateValidationMessage: null,
      townValidationMessage: null,
      addressTwoValidationMessage: null,
      zipCodeValidationMessage: null,
      countryValidationMessage: null,
      freeFormValidationMessage: null,

      // hidden/diabled
      addressDisabled: false,
      cityStateDisabled: false,
      townDisabled: false,
      addressTwoDisabled: false,
      zipCodeDisabled: false,
      countryDisabled: false,
      freeFormDisabled: true,

      // validation
      isRequireAddress: true,
      isRequireAddressTwo: true,
      isRequireTown: true,
      isRequireCityState: true,
      isRequireZipCode: true,
      isRequireCountry: true,
      isRequireFreeForm: false,

      // attributes
      addressPostName: null,
      cityStatePostName: null,
      townPostName: null,
      addressTwoPostName: null,
      zipCodePostName: null,
      countryPostName: null,
      freeFormPostName: null
    };
  },

  created() {
    EventBus.on("onSaveSettings", () => {
      // this.addLableLanguageToField(this.transalationLanguage);
      // this.addHelperTextLanguageToField(this.transalationLanguage);
      // this.addPlaceHolderTextLanguageToField(this.transalationLanguage);
      // this.addValidationMessageLanguageToField(this.transalationLanguage);
      this.updateAdvancedSettings();
      this.setValidation();
      this.setAttributes();
    });
  },

  mounted() {
    this.setFiledsLabelText();
    this.setFiledsHelperText();
    this.setFiledsPlaceHolderText();
    this.setFiledsValidationMessage();
    this.setFiledsAttributes();
    this.setFiledsAdvance();
    this.setFiledsValidations();
  },

  computed: {
    ...mapGetters({ form: "form1" }),
    renderOptions() {
      return list;
    }
  },

  methods: {
    setFiledsLabelText() {
      this.description = this.fieldData.description[this.transalationLanguage];

      this.label = this.fieldData.label[this.transalationLanguage];

      this.addressTitle =
        this.fieldData.addressLabel[this.transalationLanguage];
      this.cityStateTitle =
        this.fieldData.cityStateLabel[this.transalationLanguage];
      this.townTitle = this.fieldData.townLabel[this.transalationLanguage];

      this.addressTwoTitle =
        this.fieldData.addressTwoLabel[this.transalationLanguage];

      this.zipCodeTitle =
        this.fieldData.zipCodeLabel[this.transalationLanguage];

      this.countryTitle =
        this.fieldData.countryLabel[this.transalationLanguage];

      this.freeFormTitle =
        this.fieldData.freeFormLabel[this.transalationLanguage];
    },

    setFiledsHelperText() {
      // Helper Text

      this.addressHelperText =
        this.fieldData.addressHelperText[this.transalationLanguage];

      this.addressTwoHelperText =
        this.fieldData.addressTwoHelperText[this.transalationLanguage];

      this.townHelperText =
        this.fieldData.townHelperText[this.transalationLanguage];

      this.cityStateHelperText =
        this.fieldData.cityStateHelperText[this.transalationLanguage];

      this.zipCodeHelperText =
        this.fieldData.zipCodeHelperText[this.transalationLanguage];

      this.countryHelperText =
        this.fieldData.countryHelperText[this.transalationLanguage];

      this.freeFormHelperText =
        this.fieldData.freeFormHelperText[this.transalationLanguage];
    },

    setFiledsPlaceHolderText() {
      // Place Holder Text

      this.addressPlaceHolder =
        this.fieldData.addressPlaceholderText[this.transalationLanguage];

      this.addressTwoPlaceHolder =
        this.fieldData.addressTwoPlaceholderText[this.transalationLanguage];

      this.townPlaceHolder =
        this.fieldData.townPlaceholderText[this.transalationLanguage];

      this.cityStatePlaceHolder =
        this.fieldData.cityStatePlaceholderText[this.transalationLanguage];

      this.zipCodePlaceHolder =
        this.fieldData.zipCodePlaceholderText[this.transalationLanguage];

      this.freeFormPlaceHolder =
        this.fieldData.freeFormPlaceholderText[this.transalationLanguage];
    },

    setFiledsValidationMessage() {
      // Validation Message

      this.addressValidationMessage =
        this.fieldData.addressValidationMessage[this.transalationLanguage];

      this.addressTwoValidationMessage =
        this.fieldData.addressTwoValidationMessage[this.transalationLanguage];

      this.townValidationMessage =
        this.fieldData.townValidationMessage[this.transalationLanguage];

      this.cityStateValidationMessage =
        this.fieldData.cityStateValidationMessage[this.transalationLanguage];

      this.zipCodeValidationMessage =
        this.fieldData.zipCodeValidationMessage[this.transalationLanguage];

      this.countryValidationMessage =
        this.fieldData.countryValidationMessage[this.transalationLanguage];

      this.freeFormValidationMessage =
        this.fieldData.freeFormValidationMessage[this.transalationLanguage];
    },

    setFiledsAttributes() {
      (this.addressPostName =
        this.fieldData.attributes && this.fieldData.attributes.addressPostName
          ? this.fieldData.attributes.addressPostName
          : undefined),
        (this.cityStatePostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.cityStatePostName
            ? this.fieldData.attributes.cityStatePostName
            : undefined),
        (this.townPostName =
          this.fieldData.attributes && this.fieldData.attributes.townPostName
            ? this.fieldData.attributes.townPostName
            : undefined),
        (this.addressTwoPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.addressTwoPostName
            ? this.fieldData.attributes.addressTwoPostName
            : undefined),
        (this.zipCodePostName =
          this.fieldData.attributes && this.fieldData.attributes.zipCodePostName
            ? this.fieldData.attributes.zipCodePostName
            : undefined),
        (this.countryPostName =
          this.fieldData.attributes && this.fieldData.attributes.countryPostName
            ? this.fieldData.attributes.countryPostName
            : undefined),
        (this.freeFormPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.freeFormPostName
            ? this.fieldData.attributes.freeFormPostName
            : undefined);
    },

    setFiledsAdvance() {
      (this.addressDisabled =
        this.fieldData.advance && this.fieldData.advance.addressDisabled),
        (this.cityStateDisabled =
          this.fieldData.advance && this.fieldData.advance.cityStateDisabled),
        (this.townDisabled =
          this.fieldData.advance && this.fieldData.advance.townDisabled),
        (this.addressTwoDisabled =
          this.fieldData.advance && this.fieldData.advance.addressTwoDisabled),
        (this.zipCodeDisabled =
          this.fieldData.advance && this.fieldData.advance.zipCodeDisabled),
        (this.countryDisabled =
          this.fieldData.advance && this.fieldData.advance.countryDisabled),
        (this.freeFormDisabled =
          this.fieldData.advance && this.fieldData.advance.freeFormDisabled);
    },

    setFiledsValidations() {
      (this.isRequireAddress =
        this.fieldData.validation &&
        this.fieldData.validation.isRequireAddress),
        (this.isRequireCityState =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireCityState),
        (this.isRequireTown =
          this.fieldData.validation && this.fieldData.validation.isRequireTown),
        (this.isRequireAddressTwo =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireAddressTwo),
        (this.isRequireZipCode =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireZipCode),
        (this.isRequireCountry =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireCountry),
        (this.isRequireFreeForm =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireFreeForm);
    },

    tabLabelClick(language) {
      this.transalationLanguage = language.toLowerCase();

      this.label = this.fieldData.label[this.transalationLanguage];
      this.description = this.fieldData.description[this.transalationLanguage];
      this.addressTitle =
        this.fieldData.addressLabel[this.transalationLanguage];
      this.cityStateTitle =
        this.fieldData.cityStateLabel[this.transalationLanguage];
      this.townTitle = this.fieldData.townLabel[this.transalationLanguage];
      this.addressTwoTitle =
        this.fieldData.addressTwoLabel[this.transalationLanguage];
      this.zipCodeTitle =
        this.fieldData.zipCodeLabel[this.transalationLanguage];
      this.countryTitle =
        this.fieldData.countryLabel[this.transalationLanguage];
      this.freeFormTitle =
        this.fieldData.freeFormLabel[this.transalationLanguage];
    },

    tabHelperTextClick(language) {
      this.transalationLanguage = language.toLowerCase();
      // Helper Text

      this.addressHelperText =
        this.fieldData.addressHelperText[this.transalationLanguage];

      this.addressTwoHelperText =
        this.fieldData.addressTwoHelperText[this.transalationLanguage];

      this.townHelperText =
        this.fieldData.townHelperText[this.transalationLanguage];

      this.cityStateHelperText =
        this.fieldData.cityStateHelperText[this.transalationLanguage];

      this.zipCodeHelperText =
        this.fieldData.zipCodeHelperText[this.transalationLanguage];

      this.countryHelperText =
        this.fieldData.countryHelperText[this.transalationLanguage];

      this.freeFormHelperText =
        this.fieldData.freeFormHelperText[this.transalationLanguage];
    },

    tabPlaceHolderClick(language) {
      this.transalationLanguage = language.toLowerCase();
      // Place Holder Text

      this.addressPlaceHolder =
        this.fieldData.addressPlaceholderText[this.transalationLanguage];

      this.addressTwoPlaceHolder =
        this.fieldData.addressTwoPlaceholderText[this.transalationLanguage];

      this.townPlaceHolder =
        this.fieldData.townPlaceholderText[this.transalationLanguage];

      this.cityStatePlaceHolder =
        this.fieldData.cityStatePlaceholderText[this.transalationLanguage];

      this.zipCodePlaceHolder =
        this.fieldData.zipCodePlaceholderText[this.transalationLanguage];

      this.freeFormPlaceHolder =
        this.fieldData.freeFormPlaceholderText[this.transalationLanguage];
    },

    tabValidationMessageClick(language) {
      this.transalationLanguage = language.toLowerCase();
      // Validation Message

      this.addressValidationMessage =
        this.fieldData.addressValidationMessage[this.transalationLanguage];

      this.addressTwoValidationMessage =
        this.fieldData.addressTwoValidationMessage[this.transalationLanguage];

      this.townValidationMessage =
        this.fieldData.townValidationMessage[this.transalationLanguage];

      this.cityStateValidationMessage =
        this.fieldData.cityStateValidationMessage[this.transalationLanguage];

      this.zipCodeValidationMessage =
        this.fieldData.zipCodeValidationMessage[this.transalationLanguage];

      this.countryValidationMessage =
        this.fieldData.countryValidationMessage[this.transalationLanguage];

      this.freeFormValidationMessage =
        this.fieldData.freeFormValidationMessage[this.transalationLanguage];
    },

    addLableLanguageToField(language) {
      let tempKey = language.toLowerCase();
      this.fieldData.label[tempKey] = this.label;
      this.fieldData.description[tempKey] = this.description;
      this.fieldData.addressLabel[tempKey] = this.addressTitle;
      this.fieldData.cityStateLabel[tempKey] = this.cityStateTitle;
      this.fieldData.townLabel[tempKey] = this.townTitle;
      this.fieldData.addressTwoLabel[tempKey] = this.addressTwoTitle;
      this.fieldData.zipCodeLabel[tempKey] = this.zipCodeTitle;
      this.fieldData.countryLabel[tempKey] = this.countryTitle;
      this.fieldData.freeFormLabel[tempKey] = this.freeFormTitle;
    },

    addHelperTextLanguageToField(language) {
      // Helper Text
      let tempKey = language.toLowerCase();

      this.fieldData.addressHelperText[tempKey] = this.addressHelperText;
      this.fieldData.addressTwoHelperText[tempKey] = this.addressTwoHelperText;
      this.fieldData.townHelperText[tempKey] = this.townHelperText;
      this.fieldData.cityStateHelperText[tempKey] = this.cityStateHelperText;
      this.fieldData.zipCodeHelperText[tempKey] = this.zipCodeHelperText;
      this.fieldData.countryHelperText[tempKey] = this.countryHelperText;
      this.fieldData.freeFormHelperText[tempKey] = this.freeFormHelperText;
    },

    addPlaceHolderTextLanguageToField(language) {
      // Place Holder Text
      let tempKey = language.toLowerCase();

      this.fieldData.addressPlaceholderText[tempKey] = this.addressPlaceHolder;
      this.fieldData.addressTwoPlaceholderText[tempKey] =
        this.addressTwoPlaceHolder;
      this.fieldData.townPlaceholderText[tempKey] = this.townPlaceHolder;
      this.fieldData.cityStatePlaceholderText[tempKey] =
        this.cityStatePlaceHolder;
      this.fieldData.zipCodePlaceholderText[tempKey] = this.zipCodePlaceHolder;
      this.fieldData.freeFormPlaceholderText[tempKey] =
        this.freeFormPlaceHolder;
    },

    addValidationMessageLanguageToField(language) {
      // Validation Message
      let tempKey = language.toLowerCase();

      this.fieldData.addressValidationMessage[tempKey] =
        this.addressValidationMessage;
      this.fieldData.addressTwoValidationMessage[tempKey] =
        this.addressTwoValidationMessage;
      this.fieldData.townValidationMessage[tempKey] =
        this.townValidationMessage;
      this.fieldData.cityStateValidationMessage[tempKey] =
        this.cityStateValidationMessage;
      this.fieldData.zipCodeValidationMessage[tempKey] =
        this.zipCodeValidationMessage;
      this.fieldData.countryValidationMessage[tempKey] =
        this.countryValidationMessage;
      this.fieldData.freeFormValidationMessage[tempKey] =
        this.freeFormValidationMessage;
    },

    setValidation() {
      let validation = {
        isRequireAddress: this.addressDisabled ? false : this.isRequireAddress,
        isRequireCityState: this.cityStateDisabled
          ? false
          : this.isRequireCityState,
        isRequireTown: this.townDisabled ? false : this.isRequireTown,
        isRequireAddressTwo: this.addressTwoDisabled
          ? false
          : this.isRequireAddressTwo,
        isRequireZipCode: this.zipCodeDisabled ? false : this.isRequireZipCode,
        isRequireCountry: this.countryDisabled ? false : this.isRequireCountry,
        isRequireFreeForm: this.freeFormDisabled
          ? false
          : this.isRequireFreeForm
      };
      Object.assign(this.fieldData, { validation });
    },

    updateAdvancedSettings() {
      let advance = {
        addressDisabled: this.addressDisabled,
        cityStateDisabled: this.cityStateDisabled,
        townDisabled: this.townDisabled,
        addressTwoDisabled: this.addressTwoDisabled,
        zipCodeDisabled: this.zipCodeDisabled,
        countryDisabled: this.countryDisabled,
        freeFormDisabled: this.freeFormDisabled
      };
      Object.assign(this.fieldData, { advance });
    },

    setAttributes() {
      let attributes = {
        addressPostName: this.addressPostName,
        cityStatePostName: this.cityStatePostName,
        townPostName: this.townPostName,
        addressTwoPostName: this.addressTwoPostName,
        zipCodePostName: this.zipCodePostName,
        countryPostName: this.countryPostName,
        freeFormPostName: this.freeFormPostName
      };
      Object.assign(this.fieldData, { attributes });
    }
  }
};
</script>
<style>
.cursor {
  cursor: pointer;
}
</style>
