<template>
  <div class="company-details">
    <BaseInfoBox
      v-if="isDnbDisabled"
      :text="thirdPartyInfoMessages.ENABLE_PREMIUM_CORPORATE_DATA"
      data-test-id="company-details__disable-text--dnb-disabled"
      :icon-options="defaultIcons.INFO"
      :theme="themes.INFO"
    />
    <div v-else>
      <BaseInfoBox
        data-test-id="company-details__disable-text--dnb-not-verified"
        v-if="!isDnbVerified"
        :text="thirdPartyInfoMessages.ADD_PREMIUM_CORPORATE_DATA"
        :icon-options="defaultIcons.INFO"
        :theme="themes.INFO"
      />
      <div class="company-details__row" data-test-id="company-details__row">
        <div class="company-details__row-col">
          <div class="company-details__row-col-table">
            <StickyTable
              caption="Company Overview"
              :table-headers="tableHeaders"
              :table-rows="companyOverviewTableRows"
            />
          </div>
          <div class="company-details__row-col-table">
            <StickyTable
              caption="Company Address"
              :table-headers="tableHeaders"
              :table-rows="companyAddressTableRows"
            />
          </div>
        </div>
        <div class="company-details__row-col">
          <div class="company-details__row-col-table">
            <StickyTable
              caption="Business Types"
              :table-headers="tableHeaders"
              :table-rows="businessTypeTableRows"
            />
          </div>
          <div class="company-details__row-col-table">
            <StickyTable
              caption="Corporate Linkage"
              :table-headers="tableHeaders"
              :table-rows="corporateLinkageTableRows"
            />
          </div>
          <div class="company-details__row-col-table">
            <StickyTable
              caption="Registration Numbers"
              :table-headers="tableHeaders"
              :table-rows="registrationNumbersTableRows"
            />
          </div>
          <div class="company-details__row-col-table">
            <StickyTable
              caption="Additional Company Names"
              :table-headers="tableHeaders"
              :table-rows="companyNamesTableRows"
            />
          </div>
          <div class="company-details__row-col-table">
            <StickyTable
              caption="Industry Details"
              :table-headers="tableHeaders"
              :table-rows="industryDetailsTableRows"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StickyTable from "@/molecules/StickyTable/StickyTable";
import {
  typographySize,
  defaultIcons,
  themes,
  thirdPartyInfoMessages
} from "@/constants";
import { isString } from "@/utils";
import BaseInfoBox from "@/atoms/BaseInfoBox/BaseInfoBox";
import BaseText from "@/atoms/BaseText/BaseText";

export default {
  name: "CompanyDetails",
  components: {
    StickyTable,
    BaseInfoBox
  },
  props: {
    premiumCorporateData: {
      type: Object,
      default: () => ({})
    },
    isDnbDisabled: {
      type: Boolean,
      default: false
    },
    isDnbVerified: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultIcons,
      themes,
      thirdPartyInfoMessages,
      tableHeaders: [
        {
          value: "label",
          styles: {
            display: "none"
          }
        },
        {
          value: "value",
          styles: {
            display: "none"
          }
        }
      ],
      companyOverviewTableDataMap: [
        {
          label: "Company name",
          value: this.premiumCorporateData?.primaryName || ""
        },
        {
          label: "Operating status",
          value:
            this.premiumCorporateData?.dunsControlStatus?.operatingStatus
              ?.description || ""
        },
        {
          label: "Country",
          value:
            this.premiumCorporateData?.primaryAddress?.addressCountry?.name ||
            ""
        },
        {
          label: "URL",
          value: this.premiumCorporateData?.websiteAddress?.[0]?.url || ""
        },
        {
          label: "Ownership Date",
          value: this.premiumCorporateData?.controlOwnershipDate || ""
        },
        {
          label: "Ownership Type",
          value: this.getOwnershipType()
        },
        {
          label: "No. of Employees",
          value: this.getNumberOfEmployees()
        }
      ],
      companyAddressTableDataMap: [
        {
          label: "Street Address 1",
          value:
            this.premiumCorporateData?.primaryAddress?.streetAddress?.line1 ||
            ""
        },
        {
          label: "Street Address 2",
          value:
            this.premiumCorporateData?.primaryAddress?.streetAddress?.line2 ||
            ""
        },
        {
          label: "Street Address 3",
          value:
            this.premiumCorporateData?.primaryAddress?.streetAddress?.line3 ||
            ""
        },
        {
          label: "Street Address 4",
          value:
            this.premiumCorporateData?.primaryAddress?.streetAddress?.line4 ||
            ""
        },
        {
          label: "Country",
          value:
            this.premiumCorporateData?.primaryAddress?.addressCountry?.name ||
            ""
        },
        {
          label: "State/Region",
          value:
            this.premiumCorporateData?.primaryAddress?.addressRegion?.name || ""
        },
        {
          label: "Postal Code",
          value: this.premiumCorporateData?.primaryAddress?.postalCode || ""
        }
      ],
      industryDetailsTableDataMap: [
        {
          label: "Industries",
          value: this.getIndustryNames()
        }
      ],
      businessTypeTableDataMap: [
        {
          label: "Business Types",
          value: this.premiumCorporateData?.businessEntityType?.description
        }
      ],
      corporateLinkageTableDataMap: [
        {
          label: "Corporate Linkage",
          value: this.getCorporateLinkageValues()
        }
      ],
      registrationNumbersTableDataMap: [
        {
          label: "Registration Numbers",
          value: this.getRegistrationNumbers()
        }
      ],
      companyNamesTableDataMap: [
        {
          label: "Additional Company Names",
          value: this.getCompanyNames()
        }
      ],
      companyOverviewTableRows: [],
      companyAddressTableRows: [],
      industryDetailsTableRows: [],
      businessTypeTableRows: [],
      corporateLinkageTableRows: [],
      registrationNumbersTableRows: [],
      companyNamesTableRows: [],
      typographySize
    };
  },
  created() {
    this.setTableData();
  },
  methods: {
    getOwnershipType() {
      return this.premiumCorporateData?.numberOfEmployees?.map(
        (numberOfEmployee) => numberOfEmployee?.informationScopeDescription
      );
    },
    getNumberOfEmployees() {
      return this.premiumCorporateData?.numberOfEmployees?.map(
        (numberOfEmployee) => numberOfEmployee?.value
      );
    },
    getIndustryNames() {
      return this.premiumCorporateData?.industryCodes?.map(
        (industryCode) => industryCode?.description
      );
    },
    getCorporateLinkageValues() {
      return this.premiumCorporateData?.corporateLinkage?.familytreeRolesPlayed?.map(
        (industryCode) => industryCode?.description
      );
    },
    getRegistrationNumbers() {
      return this.premiumCorporateData?.registrationNumbers?.map(
        (registrationNumber) =>
          `${registrationNumber?.typeDescription} - ${registrationNumber.registrationNumber}`
      );
    },
    getCompanyNames() {
      return this.premiumCorporateData?.tradeStyleNames?.map(
        (tradeStyleName) => tradeStyleName?.name
      );
    },
    setTableData() {
      this.setCompanyOverviewTableRows();
      this.setCompanyAddressTableRows();
      this.setIndustryDetailsTableRows();
      this.setBusinessTypeTableRows();
      this.setCorporateLinkageTableRows();
      this.setRegistrationNumbersTableRows();
      this.setCompanyNamesTableRows();
    },
    setCompanyOverviewTableRows() {
      this.companyOverviewTableRows = this.makeTableRowData(
        this.companyOverviewTableDataMap
      );
    },
    setCompanyAddressTableRows() {
      this.companyAddressTableRows = this.makeTableRowData(
        this.companyAddressTableDataMap
      );
    },
    setIndustryDetailsTableRows() {
      this.industryDetailsTableRows = this.makeTableRowData(
        this.industryDetailsTableDataMap
      );
    },
    setBusinessTypeTableRows() {
      this.businessTypeTableRows = this.makeTableRowData(
        this.businessTypeTableDataMap
      );
    },
    setCorporateLinkageTableRows() {
      this.corporateLinkageTableRows = this.makeTableRowData(
        this.corporateLinkageTableDataMap
      );
    },
    setRegistrationNumbersTableRows() {
      this.registrationNumbersTableRows = this.makeTableRowData(
        this.registrationNumbersTableDataMap
      );
    },
    setCompanyNamesTableRows() {
      this.companyNamesTableRows = this.makeTableRowData(
        this.companyNamesTableDataMap
      );
    },
    makeTableRowData(dataMap) {
      return dataMap.map(({ label, value }) => ({
        label: {
          component: BaseText,
          componentOptions: {
            tag: "span",
            text: label,
            size: typographySize.BODY_TEXT_BOLD
          },
          styles: {
            border: "none",
            width: "200px",
            verticalAlign: "baseline"
          }
        },
        value: {
          component: BaseText,
          componentOptions: {
            text: value,
            hasDefaultSpacingRemoved: isString(value)
          },
          styles: {
            border: "none"
          }
        }
      }));
    }
  }
};
</script>
<style lang="scss" scoped>
.company-details {
  &__disable-text {
    text-align: center;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    &-col {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 10px;

      &-table {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
