import {
  isRequired,
  isLessThanOrEqualToMaxLength,
  getSelectedOption,
  getAllSelectedOptionValues
} from "@/utils";
import MultiSelect from "@/molecules/MultiSelect/MultiSelect";
import { isEqual } from "lodash";
import { customErrorMessages } from "@/constants";

const validateTaskAssignee = ({
  taskInstance = {},
  _getSelectedOption = getSelectedOption,
  _isRequired = isRequired
} = {}) => {
  const { value } = _getSelectedOption(taskInstance.assigneeOptions);
  if (!_isRequired(value)) {
    taskInstance.assigneeError = customErrorMessages.generic.required;
    return false;
  }

  taskInstance.assigneeError = "";
  return true;
};

const validateTaskSelectedOutcome = ({
  taskInstance = {},
  _getSelectedOption = getSelectedOption,
  _isRequired = isRequired
} = {}) => {
  const { value } = _getSelectedOption(taskInstance.actionsOptions);
  if (!_isRequired(value)) {
    taskInstance.actionsError = customErrorMessages.generic.required;
    return false;
  }

  taskInstance.actionsError = "";
  return true;
};

const validateTaskDynamicMultiSelectOptions = ({
  dynamicComponent,
  _getAllSelectedOptionValues = getAllSelectedOptionValues,
  _isRequired = isRequired
} = {}) => {
  const value = _getAllSelectedOptionValues(
    dynamicComponent.componentOptions.options
  );
  if (!_isRequired(value)) {
    dynamicComponent.componentOptions.error =
      customErrorMessages.generic.required;
    return false;
  }

  dynamicComponent.componentOptions.error = "";
  return true;
};

const validateTaskDynamicSelectOptions = ({
  dynamicComponent = {},
  _getSelectedOption = getSelectedOption,
  _isRequired = isRequired
} = {}) => {
  const { value } = _getSelectedOption(
    dynamicComponent.componentOptions.options
  );
  if (!_isRequired(value)) {
    dynamicComponent.componentOptions.error =
      customErrorMessages.generic.required;
    return false;
  }

  dynamicComponent.componentOptions.error = "";
  return true;
};

const validateTaskDynamicComponentOptions = ({
  dynamicComponent = {},
  _validateTaskDynamicMultiSelectOptions = validateTaskDynamicMultiSelectOptions,
  _validateTaskDynamicSelectOptions = validateTaskDynamicSelectOptions,
  _isEqual = isEqual
} = {}) => {
  if (_isEqual(dynamicComponent.component, MultiSelect)) {
    return _validateTaskDynamicMultiSelectOptions({ dynamicComponent });
  }
  return _validateTaskDynamicSelectOptions({ dynamicComponent });
};

const validateTaskDynamicComponentValue = ({
  dynamicComponent,
  _isLessThanOrEqualToMaxLength = isLessThanOrEqualToMaxLength,
  _isRequired = isRequired
} = {}) => {
  if (
    !_isLessThanOrEqualToMaxLength(dynamicComponent.componentOptions.value, 255)
  ) {
    dynamicComponent.componentOptions.error =
      customErrorMessages.generic.maxLength255;
    return false;
  }

  if (!_isRequired(dynamicComponent.componentOptions.value)) {
    dynamicComponent.componentOptions.error =
      customErrorMessages.generic.required;
    return false;
  }

  dynamicComponent.componentOptions.error = "";
  return true;
};

const validateTaskDynamicComponent = ({
  dynamicComponent,
  _validateTaskDynamicComponentOptions = validateTaskDynamicComponentOptions,
  _validateTaskDynamicComponentValue = validateTaskDynamicComponentValue
}) => {
  if (dynamicComponent.componentOptions?.options) {
    return _validateTaskDynamicComponentOptions({ dynamicComponent });
  }
  return _validateTaskDynamicComponentValue({ dynamicComponent });
};

const validateTaskDynamicComponentList = ({
  taskInstance = {},
  _validateTaskDynamicComponent = validateTaskDynamicComponent
}) =>
  taskInstance?.dynamicInputsBasedOnActionUserRequest
    ?.reduce?.((acc, dynamicComponent) => {
      acc.push(_validateTaskDynamicComponent({ dynamicComponent }));
      return acc;
    }, [])
    .every?.((result) => result) || false;

const validateTaskPartially = ({
  taskInstance = {},
  _validateTaskAssignee = validateTaskAssignee,
  _validateTaskSelectedOutcome = validateTaskSelectedOutcome
} = {}) => {
  const isTaskAssigneeValid = _validateTaskAssignee({
    taskInstance
  });
  const isTaskSelectedOutcomeValid = _validateTaskSelectedOutcome({
    taskInstance
  });

  return isTaskAssigneeValid && isTaskSelectedOutcomeValid;
};

const validateTask = ({
  taskInstance = {},
  _validateTaskDynamicComponentList = validateTaskDynamicComponentList,
  _validateTaskPartially = validateTaskPartially
} = {}) => {
  const isTaskPartiallyValid = _validateTaskPartially({
    taskInstance
  });
  const isTaskDynamicComponentListValid = _validateTaskDynamicComponentList({
    taskInstance
  });

  return isTaskPartiallyValid && isTaskDynamicComponentListValid;
};

export {
  validateTaskAssignee,
  validateTaskSelectedOutcome,
  validateTaskDynamicMultiSelectOptions,
  validateTaskDynamicSelectOptions,
  validateTaskDynamicComponentOptions,
  validateTaskDynamicComponentValue,
  validateTaskDynamicComponent,
  validateTaskDynamicComponentList,
  validateTask,
  validateTaskPartially
};
