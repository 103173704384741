import { endpoints } from "@/constants";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import {
  makeTaskAPIDTO,
  makeTaskDTO,
  makeTasksFilterAPIDTO
} from "./dto/tasks.dto";
import { makePaginationAPIDTO, makePaginationDTO } from "@/services/commonDto";

const fetchTasks = async ({
  companyId = "",
  filter = [],
  pagination = {},
  excludedFields = []
} = {}) => {
  const response = await HTTP(
    "post",
    endpoints.FETCH_TASKS(companyId),
    {
      filter: makeTasksFilterAPIDTO({ filter }),
      ...makePaginationAPIDTO(pagination),
      excluded_fields: excludedFields
    },
    makeAuthorizationHeader()
  );
  response.data.data = response.data.data.map((task) => makeTaskDTO({ task }));
  response.data.meta = makePaginationDTO(response.data.meta);
  return response;
};

const fetchTask = async ({ companyId = "", taskId = "" } = {}) => {
  const response = await HTTP(
    "get",
    endpoints.TASK({ companyId, taskId }),
    null,
    makeAuthorizationHeader()
  );
  response.data = makeTaskDTO({ task: response.data });
  return response;
};

const updateTask = async ({
  companyId = "",
  task = {},
  amendedTaskData = {}
} = {}) => {
  const response = await HTTP(
    "patch",
    endpoints.TASK({ companyId, taskId: task.id }),
    makeTaskAPIDTO({ task, amendedTaskData }),
    makeAuthorizationHeader()
  );
  response.data = makeTaskDTO({ task: response.data });
  return response;
};

const completeTask = ({
  companyId = "",
  task = {},
  amendedTaskData = {}
} = {}) =>
  HTTP(
    "post",
    endpoints.COMPLETE_TASK({ companyId, taskId: task.id }),
    makeTaskAPIDTO({ task, amendedTaskData }),
    makeAuthorizationHeader()
  );

const reopenTask = async ({ companyId = "", taskId = "" } = {}) => {
  const response = await HTTP(
    "post",
    endpoints.REOPEN_TASK({ companyId, taskId }),
    null,
    makeAuthorizationHeader()
  );
  response.data = makeTaskDTO({ task: response.data });
  return response;
};

const deleteTask = ({ companyId = "", taskId = "" } = {}) =>
  HTTP(
    "delete",
    endpoints.TASK({ companyId, taskId }),
    null,
    makeAuthorizationHeader()
  );

export const tasksService = {
  fetchTasks,
  fetchTask,
  deleteTask,
  updateTask,
  completeTask,
  reopenTask
};
