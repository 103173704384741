<template>
  <b-tabs content-class="mt-3">
    <b-tab active title="Text">
      <b-tabs card pills vertical>
        <!-- Render Tabs, supply a unique `key` to each tab -->
        <b-tab
          v-for="(language, index) in form.formTranslations"
          :key="index"
          :title="language"
          @click="tabClick(language)"
        >
          <!-- translation form wrappe -->
          <div>
            <b-form-group label="Label">
              <b-form-input
                v-model="label"
                :placeholder="fieldData.label[transalationLanguage]"
                type="text"
                @blur="addLanguageToField(language)"
              ></b-form-input>
            </b-form-group>
            <b-spinner v-if="isLoadedNewKeys" small></b-spinner>
            <div v-else>
              <b-form-group
                v-if="fieldData.abbreviationLabelText"
                label="Abbreviation Label"
              >
                <b-form-input
                  v-model="
                    fieldData.abbreviationLabelText[transalationLanguage]
                  "
                  type="text"
                  @blur="addLanguageToField(language)"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                v-if="fieldData.labelForTable"
                label="Text for table"
              >
                <b-form-input
                  v-model="fieldData.labelForTable[transalationLanguage]"
                  type="text"
                  @blur="addLanguageToField(language)"
                ></b-form-input>
                <!-- maxlength="25" -->
              </b-form-group>
            </div>
            <b-form-group label="Helper Text">
              <b-form-textarea
                v-model="helperText"
                :placeholder="fieldData.helperText[transalationLanguage]"
                max-rows="6"
                rows="3"
                @blur="addLanguageToField(language)"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group label="Validation Message">
              <b-form-input
                v-model="validationMessage"
                :placeholder="fieldData.validationMessage[transalationLanguage]"
                type="text"
                @blur="addLanguageToField(language)"
              ></b-form-input>
            </b-form-group>

            <!-- advance hyper link  -->
            <b-form-checkbox v-model="fieldData.isHyperLink"
              >Enable Hyper link
            </b-form-checkbox>
            <b-collapse v-model="fieldData.isHyperLink">
              <b-form-group
                v-if="fieldData.hyperLinkUrl"
                label="Hyper Link Url"
              >
                <b-form-input
                  v-model="fieldData.hyperLinkUrl[transalationLanguage]"
                  placeholder="wwww.google.com"
                  @blur="addLanguageToField(language)"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                v-if="fieldData.hyperLinkText"
                label="Hyper Link Text"
              >
                <b-form-input
                  v-model="fieldData.hyperLinkText[transalationLanguage]"
                  placeholder="click on the link"
                  @blur="addLanguageToField(language)"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                v-if="fieldData.hyperLinkTextBefore"
                label="Text before Hyper Link"
              >
                <b-form-input
                  v-model="fieldData.hyperLinkTextBefore[transalationLanguage]"
                  placeholder=""
                  @blur="addLanguageToField(language)"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                v-if="fieldData.hyperLinkTextAfter"
                label="Text after Hyper Link"
              >
                <b-form-input
                  v-model="fieldData.hyperLinkTextAfter[transalationLanguage]"
                  placeholder=""
                  @blur="addLanguageToField(language)"
                ></b-form-input>
              </b-form-group>
            </b-collapse>

            <b-form-checkbox
              v-if="fieldData.inputType != 'checkbox' || 'radio'"
              v-model="isStacked"
              >Is Stacked?
            </b-form-checkbox>
          </div>
        </b-tab>
      </b-tabs>
    </b-tab>

    <!-- for charts enable and disable -->
    <b-tab
      v-if="
        fieldData.inputType == 'radio' ||
        fieldData.inputType == 'select' ||
        fieldData.inputType == 'countries' ||
        fieldData.inputType == 'cpi-countries' ||
        fieldData.inputType == 'checkbox' ||
        fieldData.inputType == 'currency'
      "
      title="Configure"
    >
      <b-form-group
        v-if="fieldData.inputType == 'countries'"
        label="Selec County List Type"
      >
        <v-select
          v-model="fieldData.selectedListTypeIndex"
          :options="renderOptions"
          clearable
          label="text"
          searchable
          value="text"
        ></v-select>
      </b-form-group>

      <div v-if="fieldData.inputType === 'checkbox'">
        <b-form-group
          v-if="fieldData.inputType === 'checkbox'"
          label="Disable options when"
        >
          <v-select
            searchable
            clearable
            multiple
            label="value"
            value="value"
            :options="fieldData.options"
            v-model="fieldData.disableOptions"
          />
        </b-form-group>
      </div>

      <div v-if="showDefaultHighRisk">
        <b-form-group>
          <b-form-checkbox
            v-model="fieldData.isDefaultHighRisk"
            name="highRisk"
            unchecked-value="0"
            value="1"
            @change="onChangeDefaultHighRiskValue"
            >Default Overall High Risk
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          v-if="fieldData.isDefaultHighRisk == 1"
          label="When Value is"
        >
          <v-select
            v-model="fieldData.highRiskAnsValue"
            :options="fieldData.options"
            clearable
            label="value"
            multiple
            searchable
            value="value"
          ></v-select>
        </b-form-group>
      </div>

      <div v-if="showCombinedHighRisk">
        <b-form-group>
          <b-form-checkbox
            v-model="showCombinedHighRiskQuestions"
            :unchecked-value="false"
            :value="true"
            name="highRisk"
            @change="resetCombinedHighRiskData"
            >Combined High Risk
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          v-if="showCombinedHighRiskQuestions"
          label="When Value is"
        >
          <v-select
            v-model="combinedHighRiskAnswerValues"
            :options="fieldData.options"
            clearable
            label="value"
            multiple
            searchable
            value="value"
          ></v-select>
        </b-form-group>
        <div>
          <b-form-group
            v-if="showCombinedHighRiskQuestions"
            label="AND Question"
          >
            <v-select
              v-model="selectedCombinedHighRiskQuestionAndAnswers.question"
              :options="allFormQuestionLabels"
              searchable
            ></v-select>
          </b-form-group>
          <b-form-group
            v-if="showCombinedHighRiskAnswers"
            label="Answer value is"
          >
            <v-select
              v-model="selectedCombinedHighRiskQuestionAndAnswers.answers"
              :options="selectedCombinedHighRiskAnswerOptions"
              clearable
              multiple
              searchable
            ></v-select>
          </b-form-group>
        </div>
      </div>

      <b-form-group label="Enter Weightage (in %)">
        <b-form-input
          v-model="fieldData.weightage"
          max="2"
          maxlength="2"
          no-wheel
          onkeydown="return (event.keyCode >= 46 && event.keyCode <= 57) || event.keyCode == 8"
          placeholder="0"
          trim
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Select Graphical Representation for Data">
        <b-form-radio-group
          id="checkbox-group-1"
          v-model="fieldData.selectedCharts"
          :options="optionsCharts"
          stacked
        ></b-form-radio-group>
      </b-form-group>
    </b-tab>

    <b-tab
      v-if="fieldData.enabledConditionalField == 1"
      title="Conditional Field Text"
    >
      <b-tabs card pills vertical>
        <b-tab
          v-for="(language, index) in form.formTranslations"
          :key="index"
          :title="language"
          @click="tabClick(language)"
        >
          <div>
            <b-form-group label="Conditional Field">
              <b-form-input
                v-model="conditionalFieldLabel"
                :placeholder="
                  fieldData.conditionalFieldLabel[transalationLanguage]
                "
                type="text"
                @blur="addLanguageToField(language)"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Conditional Field Placeholder Text">
              <b-form-input
                v-model="conditionalFieldPlaceHolderText"
                :placeholder="
                  fieldData.conditionalFieldPlaceHolderText[
                    transalationLanguage
                  ]
                "
                type="text"
                @blur="addLanguageToField(language)"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Conditional Field Helper Text">
              <b-form-textarea
                v-model="conditionalFieldHelperText"
                :placeholder="
                  fieldData.conditionalFieldHelperText[transalationLanguage]
                "
                max-rows="6"
                rows="3"
                @blur="addLanguageToField(language)"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </b-tab>
      </b-tabs>
    </b-tab>

    <!-- options tabb -->
    <b-tab
      v-if="
        fieldData.inputType == 'radio' ||
        fieldData.inputType == 'select' ||
        fieldData.inputType == 'checkbox' ||
        fieldData.inputType == 'currency'
      "
      title="Options"
    >
      <!-- select options tab -->
      <b-tabs card pills vertical>
        <b-tab
          v-for="(language, index) in form.formTranslations"
          :key="index"
          :title="language"
          @click="tabClick(language)"
        >
          <b-form class="active text-left mt-2" @submit.stop.prevent>
            <table class="table table-sm">
              <thead>
                <tr class="text-center">
                  <th>Option text</th>
                  <th>Value</th>
                  <th>Score</th>
                  <th>Abbrivation Text</th>
                  <th
                    v-for="(colOpt, colThIndex) in customColumnsForOptions"
                    :key="colThIndex"
                  >
                    {{ colOpt.text }}
                    <b-button
                      v-if="customColumnsForOptions.length"
                      class="btn btn-outline-danger btn-sm"
                      variant="outline-danger"
                      @click="onDeleteCol(colThIndex)"
                    >
                      <i class="fa fa-trash"></i>
                    </b-button>
                  </th>

                  <th>
                    <b-button
                      v-b-modal.modal-new-col
                      class="btn-sm"
                      variant="primary"
                    >
                      <i class="fa fa-plus"></i>
                    </b-button>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(option, optionIndex) in fieldData.options"
                  :key="optionIndex"
                >
                  <td>
                    <b-form-input
                      :value="option.text[transalationLanguage]"
                      @focus="$event.target.select()"
                      @input="setFieldOptionText($event, optionIndex)"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      :disabled="transalationLanguage !== 'english'"
                      :value="option.value"
                      @focus="$event.target.select()"
                      @input="setFieldOptionValue($event, optionIndex)"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      v-model="option.score"
                      no-wheel
                      onkeydown="return event.keyCode !== 69"
                      placeholder="0"
                      trim
                      type="number"
                      @change="updateOptionScore($event, optionIndex)"
                    ></b-form-input>
                  </td>
                  <td v-if="option.postValueAbb">
                    <b-form-input
                      v-model="option.postValueAbb[transalationLanguage]"
                      :value="option.postValueAbb[transalationLanguage]"
                      @focus="$event.target.select()"
                    ></b-form-input>
                  </td>

                  <td
                    v-for="(col, colIndex) in customColumnsForOptions"
                    :key="colIndex"
                  >
                    <b-form-input
                      v-model="option.custom_values[col.key]"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-button
                      class="btn btn-outline-danger btn-sm"
                      variant="outline-danger"
                      @click="onDeleteOption(optionIndex)"
                    >
                      <i class="fa fa-trash"></i>
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>

            <b-button variant="primary" @click="addOption">
              <i class="fa fa-plus"></i>
            </b-button>
            <br />
          </b-form>
        </b-tab>
      </b-tabs>
    </b-tab>

    <!-- attribute tab -->
    <b-tab title="Attributes">
      <b-form class="text-left mt-2" @submit.stop.prevent>
        <div class="form-group col-md-6">
          <label for>ID</label>
          <b-form-input v-model="selectId"></b-form-input>
        </div>
        <div class="form-group col-md-6">
          <label for>Class</label>
          <b-form-input v-model="selectClass"></b-form-input>
        </div>
        <div class="form-group col-md-6">
          <label for>Title</label>
          <b-form-input v-model="selectTitle"></b-form-input>
        </div>
        <div class="form-group col-md-6">
          <label for>Post Name</label>
          <b-form-input v-model="selectedPostName"></b-form-input>
        </div>
        <div class="form-group col-md-6">
          <label for>style</label>
          <b-form-textarea
            id="textarea"
            v-model="selectStyle"
            max-rows="6"
            rows="3"
          ></b-form-textarea>
        </div>
        <div class="form-check">
          <b-form-checkbox
            id="checkbox-1"
            v-model="selecReadOnly"
            name="checkbox-1"
            >Readonly
          </b-form-checkbox>
        </div>
        <div class="form-check">
          <b-form-checkbox
            id="checkbox-4"
            v-model="fieldData.enableSelfReportingForThirdPartyProfile"
            value="1"
            >Show Self Reporting Data in Third Party Profile
          </b-form-checkbox>
        </div>
        <div class="form-check">
          <b-form-checkbox
            id="checkbox-5"
            v-model="fieldData.enableSelfReportingForManageThirdParty"
            name="checkbox-5"
            unchecked-value="0"
            value="1"
            >Show Self Reporting Data in Mangage Third Party
          </b-form-checkbox>
        </div>
        <div class="form-check">
          <b-form-checkbox
            id="checkbox-6"
            v-model="fieldData.enableFilterForManageThirdParty"
            name="checkbox-5"
            unchecked-value="0"
            value="1"
            >Filter in Mangage Third Party
          </b-form-checkbox>
        </div>
      </b-form>
    </b-tab>

    <!-- data tab -->
    <b-tab title="Data">
      <table class="table table-sm">
        <thead>
          <tr>
            <th></th>
            <th>Key</th>
            <th>Value</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(attribute, index) in fieldData.dataAttributes"
            :key="index"
          >
            <td class="text-right align-middle">data-</td>
            <td>
              <b-form-input v-model="attribute.dataAttributeKey"></b-form-input>
            </td>
            <td>
              <b-form-input
                v-model="attribute.dataAttributeValue"
              ></b-form-input>
            </td>
            <td>
              <button
                class="btn btn-danger btn-sm"
                @click="onDeleteDataAttribute(index)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <button class="btn btn-primary btn-sm" @click="addDataAttribute">
        <i class="fa fa-plus"></i>
      </button>
    </b-tab>

    <!-- data validation -->
    <b-tab title="Validation">
      <div class="text-left">
        <b-col>
          <b-form-checkbox
            v-model="isRequireCheck"
            class="form-group col-md-6 ml-3"
            unchecked-value="0"
            value="1"
            >Is required?
          </b-form-checkbox>
        </b-col>
        <b-col v-if="fieldData.inputType === 'checkbox'">
          <div class="form-group col-md-6">
            <label>Minimum Selects</label>
            <b-form-input v-model="minSelect" type="number"></b-form-input>
          </div>
          <div class="form-group col-md-6">
            <label for>Maximum Selects</label>
            <b-form-input v-model="maxSelect" type="number"></b-form-input>
          </div>
        </b-col>
      </div>
    </b-tab>

    <!-- Advance for select only -->
    <b-tab
      v-if="
        fieldData.inputType == 'select' ||
        fieldData.inputType == 'countries' ||
        fieldData.inputType == 'currency' ||
        fieldData.inputType == 'titles' ||
        fieldData.inputType == 'cpi-countries'
      "
      title="Advanced"
    >
      <div class="text-left">
        <b-col cols="6">
          <b-form-group>
            <b-form-checkbox v-model="isMultipleSelect" class="mt-2"
              >Allow Multiple Selection
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-row v-if="isMultipleSelect" class="ml-1">
          <b-col cols="4">
            <b-form-group label="Minimum number of selection">
              <b-form-input
                v-model="minSelection"
                placeholder="0"
                type="number"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Maximum number of selection">
              <b-form-input
                v-model="maxSelection"
                placeholder="0"
                type="number"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-col cols="6">
          <b-form-group>
            <b-form-checkbox v-model="isLiveSearchable" class="mt-2"
              >Enable Live Search
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group>
            <b-form-checkbox v-model="isClearable" class="mt-2"
              >Clearable
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Number of options to be displayed">
            <b-form-input
              v-model="renderOptionsLinmit"
              placeholder="0"
              type="number"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </div>
    </b-tab>

    <!-- --------------Conditional------------------ -->
    <b-tab title="Conditional">
      <b-tabs>
        <!-- Render Tabs, supply a unique `key` to each tab -->
        <b-tab
          v-if="
            fieldData.inputType == 'radio' ||
            fieldData.inputType == 'select' ||
            fieldData.inputType == 'checkbox'
          "
          title="Simple"
        >
          <b-col>
            <!-- enabledConditionalField -->
            <b-form-checkbox
              v-model="enabledConditionalField"
              class="text-left"
              unchecked-value="0"
              value="1"
              @change="onToggleConditionalCheck"
              >Enable Conditional Field
            </b-form-checkbox>

            <b-collapse v-model="showCollapseRadios" class="mt-2">
              <b-form-group class="my-3">
                <b-form-radio
                  v-model="selectedShowHide"
                  value="1"
                  @change="onShowHideSelect"
                  >Show when selected option value is:
                </b-form-radio>
              </b-form-group>
              <v-select
                v-show="selectedShowHide == 1"
                v-model="selectedConditionalFieldValue"
                :options="fieldData.options"
                class="mb-2"
                clearable
                label="value"
                multiple
                @update:modelValue="onSelectConditionOption"
              ></v-select>
            </b-collapse>
          </b-col>
        </b-tab>
        <b-tab title="Advance">
          <b-col class="mr-0" cols="12">
            <b-form-checkbox
              v-model="isConditional"
              name="conditional"
              unchecked-value="0"
              value="1"
              >Is Conditional?
            </b-form-checkbox>
            <div v-if="isConditional == 1">
              <b-row>
                <table class="table table-sm">
                  <thead>
                    <tr class="text-center">
                      <th>No</th>
                      <th>When Field</th>
                      <th>Has Value</th>
                      <th>Actions</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(selectedOpt, index) in fieldData.conditions"
                      :key="selectedOpt.id"
                    >
                      <td>{{ index }}</td>
                      <td>
                        <b-form-input :value="selectedOpt.label" disabled />
                      </td>
                      <td>
                        <b-form-input :value="selectedOpt.value" disabled />
                      </td>
                      <td>
                        <b-button
                          class="btn-sm ml-1"
                          variant="outline-danger"
                          @click="onRemoveConditionOpt(selectedOpt.id, index)"
                        >
                          <i class="fa fa-trash"></i>
                        </b-button>
                      </td>
                    </tr>

                    <tr
                      v-for="(noOfOption, index) in noOfConditions"
                      :key="index"
                    >
                      <td>{{ fieldData.conditions.length }}</td>
                      <td>
                        <v-select
                          v-model="conditionalField"
                          :options="conditionalSectionElementLables"
                          class="mb-2"
                          lablel="label"
                          @update:modelValue="getSelectedElement"
                        ></v-select>
                      </td>
                      <td>
                        <v-select
                          v-model="conditionalFieldValue"
                          :options="selectedElementOption"
                          class="mb-2"
                          label="text"
                        ></v-select>
                      </td>

                      <td>
                        <b-button
                          class="btn-sm"
                          variant="outline-primary"
                          @click="setFieldConditions"
                          >Apply
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-row>
            </div>
          </b-col>
        </b-tab>

        <b-tab v-if="showDivisionalCodeCondition" title="Division codes">
          <b-form-checkbox
            v-model="fieldData.isConditionalDivisionCodes"
            name="divisionalCode"
            unchecked-value="0"
            value="1"
            @change="onCheckDivisionCode"
            >Is divisional code?
          </b-form-checkbox>
          <div v-if="fieldData.isConditionalDivisionCodes == 1">
            <b-row>
              <b-col cols="8">
                <v-select
                  v-model="fieldData.conditionalFieldDivisionCodesValue"
                  :options="divisionCodeOptions"
                  class="my-2"
                  lablel="label"
                  multiple
                  placeholder="Select Division Codes"
                ></v-select>
              </b-col>
              <b-col cols="4"> </b-col>
            </b-row>
          </div>
        </b-tab>
      </b-tabs>
    </b-tab>

    <!-- Ranges tabb -->
    <b-tab
      v-if="
        fieldData.inputType == 'select' ||
        fieldData.inputType == 'checkbox' ||
        fieldData.inputType == 'currency'
      "
      title="Ranges"
    >
      <b-form class="active text-left mt-2" @submit.stop.prevent>
        <table class="table table-sm">
          <thead>
            <tr class="text-center">
              <th>Min Count</th>
              <th>Max Count</th>
              <th>Score</th>
              <th></th>
            </tr>
          </thead>
          <b-spinner v-if="isRangeCreating"></b-spinner>
          <tbody v-else>
            <tr v-for="(attribute, index) in fieldData.ranges" :key="index">
              <td>
                <b-form-input
                  v-model="attribute.minRange"
                  type="number"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  v-model="attribute.maxRange"
                  type="number"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  v-model="attribute.score"
                  type="number"
                ></b-form-input>
              </td>
              <td>
                <button
                  class="btn btn-danger btn-sm"
                  @click="onDeleteRange(index)"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <b-button variant="primary" @click="addRangeOption">
          <i class="fa fa-plus"></i>
        </b-button>
        <br />
      </b-form>
    </b-tab>

    <!-- condirional -->
    <b-modal id="modal-new-col" title="Add New Col" @ok="addColumnToOption">
      <b-form-group label="Enter Column Text">
        <b-form-input v-model="newColData.text"></b-form-input>
      </b-form-group>
      <b-form-group label="Enter key" trim>
        <b-form-input v-model="newColData.key"></b-form-input>
      </b-form-group>
      <b-form-group label="Description">
        <b-form-input v-model="newColData.desc"></b-form-input>
      </b-form-group>
    </b-modal>
  </b-tabs>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { EventBus } from "@/event-bus";
import uid from "uid";
import { Helper } from "@/helpers";
import IndustriesRiskRatings from "@/utils/industryRiskRatingData/industries.json";
import list from "@/utils/countryListTypes.json";
import { isEqual } from "lodash";

export default {
  name: "AdvanceRadioSettings",
  data() {
    return {
      isHyperLink: false,
      isLoadedNewKeys: false,
      isRangeCreating: false,
      customColumnsForOptions: [],
      newColData: {
        text: null,
        key: null,
        desc: null,
        value: null
      },
      conditionalFieldLabel: null,
      conditionalFieldPlaceHolderText: null,
      conditionalFieldHelperText: null,
      conditionalFieldValidationMessage: null,
      conditionalFieldFirstLabel: null,
      conditionalFieldYesLabel: null,
      conditionalFieldNoLabel: null,
      isStacked: true,
      fieldCSS: null,
      fieldJS: null,
      // multi progressive
      optionsMutiInputs: [
        {
          text: "1",
          value: 1
        },
        {
          text: "2",
          value: 2
        },
        {
          text: "3",
          value: 3
        },
        {
          text: "4",
          value: 4
        },
        {
          text: "5",
          value: 5
        }
      ],
      selectedMutiInputs: 1,

      //  for charts

      selectedCharts: [],
      optionsCharts: [
        {
          text: "None",
          value: null
        },
        {
          text: "Line",
          value: "line"
        },
        {
          text: "Bar",
          value: "bar"
        },
        {
          text: "Pie",
          value: "pie"
        },
        {
          text: "Area",
          value: "area"
        },
        {
          text: "Column",
          value: "column"
        },
        {
          text: "Scatter",
          value: "scatter"
        },
        {
          text: "Pyramid",
          value: "pyramid"
        },
        {
          text: "Funnel",
          value: "funnel"
        },
        {
          text: "Columnpyramid",
          value: "columnpyramid"
        },
        {
          text: "Country Map",
          value: "countrymap"
        }
      ],
      // advance condition
      conditionalSectionElements: [],
      conditionalSectionElementLables: [],
      selectedElementOption: [],
      conditionalField: null,
      conditionalFieldValue: null,
      condition: {},
      isConditional:
        this.fieldData.isConditional && this.fieldData.conditions.length && 1,
      noOfConditions: [1],
      isConditionalDivisionCodes: this.fieldData.isConditionalDivisionCodes
        ? this.fieldData.isConditionalDivisionCodes
        : null,
      conditionalFieldDivisionCodesValue: this.fieldData
        .conditionalFieldDivisionCodesValue
        ? this.fieldData.conditionalFieldDivisionCodesValue
        : null,
      divisionCodeOptions: [
        {
          label: "A",
          code: "A"
        },
        {
          label: "B",
          code: "B"
        },
        {
          label: "C",
          code: "C"
        },
        {
          label: "D",
          code: "D"
        },
        {
          label: "E",
          code: "E"
        },
        {
          label: "F",
          code: "F"
        },
        {
          label: "G",
          code: "G"
        },
        {
          label: "H",
          code: "H"
        },
        {
          label: "I",
          code: "I"
        },
        {
          label: "J",
          code: "J"
        },
        {
          label: "K",
          code: "K"
        },
        {
          label: "L",
          code: "L"
        },
        {
          label: "M",
          code: "M"
        },
        {
          label: "N",
          code: "N"
        },
        {
          label: "O",
          code: "O"
        },
        {
          label: "P",
          code: "P"
        },
        {
          label: "Q",
          code: "Q"
        },
        {
          label: "R",
          code: "R"
        },
        {
          label: "S",
          code: "S"
        }
      ],
      isLoading: false,
      transalationLanguage: "english",
      label: undefined,
      placeholder: undefined,
      validationMessage: undefined,
      helperText: undefined,
      optiponName: undefined,
      optionValue: undefined,
      dataAttributeValue: undefined,
      dataAttributeKey: undefined,
      isRequireCheck:
        this.fieldData.validation && this.fieldData.validation.required,
      validityMessage:
        this.fieldData.validation && this.fieldData.validation.message
          ? this.fieldData.validation.message
          : "This field is required",
      selectId:
        this.fieldData.attributes && this.fieldData.attributes.newId
          ? this.fieldData.attributes.newId
          : undefined,
      selectClass:
        this.fieldData.attributes && this.fieldData.attributes.Class
          ? this.fieldData.attributes.Class
          : undefined,
      selectTitle:
        this.fieldData.attributes && this.fieldData.attributes.Title
          ? this.fieldData.attributes.Title
          : undefined,
      selectedPostName:
        this.fieldData.attributes && this.fieldData.attributes.postName
          ? this.fieldData.attributes.postName
          : undefined,

      selectStyle:
        this.fieldData.attributes && this.fieldData.attributes.Style
          ? this.fieldData.attributes.Style
          : undefined,
      selecReadOnly:
        this.fieldData.attributes && this.fieldData.attributes.readonly
          ? this.fieldData.attributes.readonly
          : false,
      showTab: "text",

      // advance tab settings for select only

      isMultipleSelect:
        (this.fieldData.configuration &&
          this.fieldData.configuration.isMultipleSelect) ||
        false,
      isClearable:
        (this.fieldData.configuration &&
          this.fieldData.configuration.isClearable) ||
        false,
      isLiveSearchable:
        (this.fieldData.configuration &&
          this.fieldData.configuration.isLiveSearchable) ||
        false,
      minSelection:
        (this.fieldData.configuration &&
          this.fieldData.configuration.minSelection) ||
        1,
      maxSelection:
        (this.fieldData.configuration &&
          this.fieldData.configuration.maxSelection) ||
        2,
      renderOptionsLinmit:
        (this.fieldData.configuration &&
          this.fieldData.configuration.renderOptionsLinmit) ||
        5,

      // advance tab settings for checkbox
      minSelect: this.fieldData.validation.minSelect,
      maxSelect: this.fieldData.validation.maxSelect,

      // for conditional filedsss
      enabledConditionalField: 0,
      showCollapseRadios: false,
      selectedShowHide: null,
      selectedConditionalFieldValue: null,

      // Combined high risk
      showCombinedHighRiskQuestions: false,
      combinedHighRiskAnswerValues: [],
      selectedCombinedHighRiskQuestionAndAnswers:
        this.makeSelectedCombinedHighRiskQuestionAndAnswers(),
      selectedCombinedHighRiskAnswerOptions: []
    };
  },
  watch: {
    "selectedCombinedHighRiskQuestionAndAnswers.question": {
      deep: true,
      handler(newValue, oldValue) {
        if (oldValue) {
          this.selectedCombinedHighRiskQuestionAndAnswers.answers = [];
        }
        this.setSelectedCombinedHighRiskAnswerOptions(newValue);
      }
    },
    selectedCombinedHighRiskAnswer: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          this.fieldData.combinedHighRiskQuestionsAndAnswers = newValue;
        }
      }
    },
    isConditional(newValue) {
      if (newValue === "0") {
        this.fieldData.conditions = [];
        this.fieldData.isConditional = newValue;
        this.fieldData.isHidden =
          this.fieldData.isConditionalDivisionCodes === "1";
      }
    }
  },
  computed: {
    ...mapGetters({
      form: "form1",
      allFormQuestions: "allFormQuestions"
    }),
    renderOptions() {
      return list;
    },
    showDivisionalCodeCondition() {
      return (
        this.form.formTitle.english
          .toLowerCase()
          .includes("modern slavery questionnaire v 3") ||
        this.form.formTitle.english
          .toLowerCase()
          .includes("lieferkettengesetz") ||
        this.form.formTitle.english
          .toLowerCase()
          .includes("human rights questionnaire") ||
        this.form.formTitle.english
          .toLowerCase()
          .includes("modern slavery questionnaire v 4")
      );
    },
    showDefaultHighRisk() {
      return (
        this.form.formTitle.english
          .toLowerCase()
          .includes("modern slavery questionnaire v 3") ||
        this.form.formTitle.english
          .toLowerCase()
          .includes("lieferkettengesetz") ||
        this.form.formTitle.english
          .toLowerCase()
          .includes("human rights questionnaire") ||
        this.form.formTitle.english
          .toLowerCase()
          .includes("modern slavery questionnaire v 4")
      );
    },
    showCombinedHighRisk() {
      return (
        this.form.formTitle.english
          .toLowerCase()
          .includes("lieferkettengesetz") ||
        this.form.formTitle.english
          .toLowerCase()
          .includes("human rights questionnaire")
      );
    },
    allFormQuestionLabels() {
      return this.allFormQuestions.map((question) => question.label);
    },
    showCombinedHighRiskAnswers() {
      return !!this.selectedCombinedHighRiskQuestionAndAnswers?.question;
    }
  },

  created() {
    EventBus.on("onSaveSettings", () => {
      this.addLanguageToField(this.transalationLanguage);
      this.addAttributes();
      this.setValidation();
      this.setAdvanceSettingsForSelect();
      this.setCustomColOptionsToSchema();
      this.setCombinedHighRiskData();
    });

    if (
      this.fieldData.combinedHighRiskAnswerValues?.length &&
      this.fieldData.combinedHighRiskQuestionsAndAnswers?.[0]?.question &&
      this.fieldData.combinedHighRiskQuestionsAndAnswers?.[0]?.answers?.length
    ) {
      this.combinedHighRiskAnswerValues =
        this.fieldData.combinedHighRiskAnswerValues;
      this.selectedCombinedHighRiskQuestionAndAnswers =
        this.fieldData.combinedHighRiskQuestionsAndAnswers[0];
    }

    if (this.fieldData?.combinedHighRiskAnswerValues?.length) {
      this.showCombinedHighRiskQuestions = true;
    }
  },

  mounted() {
    (this.isStacked = this.fieldData.configuration.isStacked),
      (this.label = this.fieldData.label[this.transalationLanguage]),
      (this.validationMessage =
        this.fieldData.validationMessage[this.transalationLanguage]),
      (this.helperText = this.fieldData.helperText[this.transalationLanguage]);
    this.helperText = this.fieldData.helperText[this.transalationLanguage];

    // Conditional Field
    if (
      this.fieldData.inputType == "radio" ||
      this.fieldData.inputType == "select" ||
      this.fieldData.inputType == "checkbox"
    ) {
      this.addNewKeyToOptions(); // to add weightage
      this.enabledConditionalField = this.fieldData.enabledConditionalField;
      this.selectedShowHide = this.fieldData.conditionalShowHiddenOption;
      this.showCollapseRadios = this.enabledConditionalField == 1;
      this.selectedConditionalFieldValue =
        this.fieldData.selectedConditionalFieldValue;
      this.selectedMutiInputs = this.fieldData.selectedMutiInputs;
      this.conditionalFieldLabel =
        this.fieldData.conditionalFieldLabel[this.transalationLanguage];
      this.conditionalFieldPlaceHolderText =
        this.fieldData.conditionalFieldPlaceHolderText[
          this.transalationLanguage
        ];

      this.conditionalFieldValidationMessage =
        this.fieldData.conditionalFieldValidationMessage[
          this.transalationLanguage
        ];
      this.conditionalFieldHelperText =
        this.fieldData.conditionalFieldHelperText[this.transalationLanguage];
    }
    this.addExtras();
    this.addExtrasForHyperLink();
    this.getCurrentSectionList();
    if (!this.fieldData.ranges) {
      this.addRangesToSchema();
    }
    this.getCustomColOptionsFromSchema();
  },

  methods: {
    ...mapMutations(["TOGGLE_LOADER"]),
    makeSelectedCombinedHighRiskQuestionAndAnswers() {
      return {
        question: null,
        answers: []
      };
    },
    addExtras() {
      this.isLoadedNewKeys = true;
      let self = this;
      if (!this.fieldData.abbreviationLabelText) {
        let abbreviationLabelText = { english: " " };
        Object.assign(this.fieldData, { abbreviationLabelText });
      }
      if (!this.fieldData.labelForTable) {
        let labelForTable = { english: "" };
        Object.assign(this.fieldData, { labelForTable });
      }
      setTimeout(() => {
        self.isLoadedNewKeys = false;
      }, 1000);
    },

    addExtrasForHyperLink() {
      this.isLoadedNewKeys = true;
      let self = this;
      if (!this.fieldData.hyperLinkUrl) {
        let hyperLinkUrl = { english: " " };
        Object.assign(this.fieldData, { hyperLinkUrl });
      }
      if (!this.fieldData.hyperLinkText) {
        let hyperLinkText = { english: "" };
        Object.assign(this.fieldData, { hyperLinkText });
      }
      if (!this.fieldData.hyperLinkTextBefore) {
        let hyperLinkTextBefore = { english: "" };
        Object.assign(this.fieldData, { hyperLinkTextBefore });
      }
      if (!this.fieldData.hyperLinkTextAfter) {
        let hyperLinkTextAfter = { english: "" };
        Object.assign(this.fieldData, { hyperLinkTextAfter });
      }
      setTimeout(() => {
        self.isLoadedNewKeys = false;
      }, 1000);
    },

    tabClick(language) {
      let tempKey = language.toLowerCase();
      this.transalationLanguage = tempKey;
      this.label = this.fieldData.label[this.transalationLanguage];

      this.validationMessage =
        this.fieldData.validationMessage[this.transalationLanguage];
      this.helperText = this.fieldData.helperText[this.transalationLanguage];

      // Conditional Field

      if (
        this.fieldData.inputType == "radio" ||
        this.fieldData.inputType == "select" ||
        this.fieldData.inputType == "checkbox"
      ) {
        this.conditionalFieldLabel =
          this.fieldData.conditionalFieldLabel[this.transalationLanguage];
        this.conditionalFieldPlaceHolderText =
          this.fieldData.conditionalFieldPlaceHolderText[
            this.transalationLanguage
          ];

        this.conditionalFieldValidationMessage =
          this.fieldData.conditionalFieldValidationMessage[
            this.transalationLanguage
          ];
        this.conditionalFieldHelperText =
          this.fieldData.conditionalFieldHelperText[this.transalationLanguage];
      }
    },

    onkeydown() {
      // onkeydown = "return event.keyCode => 48 && event.keyCode <= 57";
      return event.keyCode >= 48 && event.keyCode <= 57;
    },

    addNewKeyToOptions() {
      let self = this;
      this.isLoadedNewKeys = true;

      let options = [...this.fieldData.options];
      options = options.map((opt) => ({
        ...opt,
        weightage: opt.weightage ? opt.weightage : null,
        postValueAbb: opt.postValueAbb ? opt.postValueAbb : { english: "" },
        range: opt.range ? opt.range : null
      }));
      Object.assign(this.fieldData, { options });
      setTimeout(() => {
        self.isLoadedNewKeys = false;
      }, 1000);
    },

    addLanguageToField(language) {
      let tempKey = language.toLowerCase();
      this.fieldData.label[tempKey] = this.label;
      this.fieldData.helperText[tempKey] = this.helperText;
      //  this.fieldData.validationMessage[tempKey] = this.langugeValidateMessage;
      this.fieldData.validationMessage[tempKey] = this.validationMessage;

      // Conditional Field
      if (
        this.fieldData.inputType == "radio" ||
        this.fieldData.inputType == "select" ||
        this.fieldData.inputType == "checkbox"
      ) {
        this.fieldData.conditionalFieldLabel[tempKey] =
          this.conditionalFieldLabel;
        this.fieldData.conditionalFieldHelperText[tempKey] =
          this.conditionalFieldHelperText;
        this.fieldData.conditionalFieldValidationMessage[tempKey] =
          this.conditionalFieldValidationMessage;
        this.fieldData.conditionalFieldPlaceHolderText[tempKey] =
          this.conditionalFieldPlaceHolderText;
      }
    },

    addAttributes() {
      let attributes = {
        newId: this.selectId ? this.selectId : undefined,
        Class: this.selectClass ? this.selectClass : undefined,
        Title: this.selectTitle ? this.selectTitle : undefined,
        Style: this.selectStyle ? this.selectStyle : undefined,
        readonly: this.selecReadOnly ? this.selecReadOnly : false,
        postName: this.selectedPostName ? this.selectedPostName : undefined
      };
      Object.assign(this.fieldData, { attributes });
    },

    addRangesToSchema() {
      let ranges = [];
      Object.assign(this.fieldData, { ranges });
    },

    addOption() {
      let newOptiopn = {
        value: this.optionValue
          ? this.optionValue
          : `${this.fieldData.inputType} value${
              this.fieldData.options.length
            }${1}`,
        text: {
          english: this.optiponName
            ? this.optionName
            : `${this.fieldData.inputType}
              text
              ${this.fieldData.options.length}${1}`
        },
        score: 0
      };

      this.fieldData.options.push(newOptiopn);
    },

    addRangeOption() {
      this.isRangeCreating = true;
      let data = {
        minRange: null,
        maxRange: null,
        score: null,
        id: null
      };
      this.fieldData.ranges.push(data);
      this.isRangeCreating = false;
    },
    addCustomKey() {
      let options = [...this.fieldData.options];
      options = options.map((opt) =>
        // if(opt.)
        ({
          ...opt,
          custom_values: {}
        })
      );
      Object.assign(this.fieldData, { options });
    },
    addColumnToOption() {
      if (this.newColData.key && this.newColData.key) {
        this.customColumnsForOptions.push(this.newColData);
        let options = [...this.fieldData.options];
        options = options.map((opt) => ({
          ...opt,
          ...opt.custom_values,
          custom_values: {
            ...opt.custom_values,
            [this.newColData.key]: null
          }
        }));
        Object.assign(this.fieldData, { options });
      }

      this.newColData = {
        text: null,
        key: null,
        desc: null
      };
    },

    onDeleteCol(index) {
      this.customColumnsForOptions.splice(
        this.customColumnsForOptions.indexOf(index),
        1
      );
    },

    setCustomColOptionsToSchema() {
      let customColumnsForOptions = this.customColumnsForOptions;
      Object.assign(this.fieldData, { customColumnsForOptions });
    },

    getCustomColOptionsFromSchema() {
      if (
        this.fieldData.customColumnsForOptions &&
        this.fieldData.customColumnsForOptions.length
      ) {
        this.customColumnsForOptions = this.fieldData.customColumnsForOptions;
      }
    },

    addDataAttribute() {
      let newDataAttribute = {
        value: this.dataAttributeValue ? this.dataAttributeValue : undefined,
        key: this.dataAttributeKey ? `data-${this.dataAttributeKey}` : undefined
      };
      this.fieldData.dataAttributes.push(newDataAttribute);
    },

    onDeleteDataAttribute(index) {
      this.fieldData.dataAttributes.splice(
        this.fieldData.dataAttributes.indexOf(index),
        1
      );
    },

    onDeleteRange(index) {
      this.isRangeCreating = true;
      this.fieldData.ranges.splice(this.fieldData.ranges.indexOf(index), 1);
      this.isRangeCreating = false;
    },

    onDeleteOption(index) {
      this.fieldData.options.splice(this.fieldData.options.indexOf(index), 1);
    },

    // methods for radio options settings
    setFieldOptionValue(value, optionIndex) {
      this.fieldData.options[optionIndex].value;
      this.optionValue = value;
      this.fieldData.options[optionIndex].value = value;
    },

    setFieldOptionText(value, optionIndex) {
      // this.form.formTitle[this.translationLanguage] = value;
      this.optiponName = value;
      this.fieldData.options[optionIndex].text[this.transalationLanguage] =
        value;
    },

    /**
     * Required for validation on
     */
    setValidation() {
      let validation = {
        required: this.isRequireCheck,
        minSelect: this.minSelect,
        maxSelect: this.maxSelect
      };
      Object.assign(this.fieldData, { validation });
    },

    setAdvanceSettingsForSelect() {
      this.fieldData.configuration = {
        isStacked: this.isStacked,
        isMultipleSelect: this.isMultipleSelect,
        isClearable: this.isClearable,
        isLiveSearchable: this.isLiveSearchable,
        minSelection: this.minSelection,
        maxSelection: this.maxSelection,
        renderOptionsLinmit: this.renderOptionsLinmit
      };
    },

    // for conditional
    onToggleConditionalCheck(e) {
      if (e == 0) {
        this.fieldData.selectedConditionalFieldValue = null;
        this.selectedConditionalFieldValue = null;
        this.selectedShowHide = null;
        this.selectedMutiInputs = 1;
      }
      this.showCollapseRadios = e == 1;
      this.enabledConditionalField = parseInt(e);
      this.fieldData.enabledConditionalField = parseInt(e);
    },

    onShowHideSelect(e) {
      this.fieldData.conditionalShowHiddenOption = parseInt(e); // 0 -> hide, 1 - > show
    },

    onSelectConditionOption() {
      this.fieldData.selectedConditionalFieldValue =
        this.selectedConditionalFieldValue;
      this.fieldData.selectedMutiInputs = this.selectedMutiInputs;
    },
    // for advance conditional
    getCurrentSectionList() {
      let conditionalSectionElements =
        this.form.formSection[this.sectionIndex].formElements;
      conditionalSectionElements = conditionalSectionElements.filter(
        (element) => {
          if (
            element.inputType === "radio" ||
            element.inputType === "checkbox" ||
            element.inputType === "select"
          ) {
            // return element
            let dd = {
              label: element.label[this.transalationLanguage],
              element
            };
            return this.conditionalSectionElementLables.push(dd);
          }
        }
      );
      this.conditionalSectionElements = conditionalSectionElements;
    },

    getSelectedElement(selectedElement) {
      this.selectedElementOption = [];
      this.conditionalFieldValue = null;
      selectedElement.element.options.map((opt) => {
        this.selectedElementOption.push(opt.value);
      });
    },

    setFieldConditions() {
      if (this.conditionalField && this.conditionalFieldValue) {
        let condition = {
          id: uid(),
          sourceFieldId: this.conditionalField.element.id,
          targetFieldId: this.fieldData.id,
          value: this.conditionalFieldValue,
          label: this.conditionalField.label
        };

        let elements = [
          ...this.form.formSection[this.sectionIndex].formElements
        ];
        elements.map((element) => {
          if (element.options) {
            element.options.map((opt) => {
              if (
                opt.value === this.conditionalFieldValue &&
                this.conditionalField.element.id === element.id
              ) {
                element.conditionalFields.push(condition);
              }
            });
          }
        });
        this.fieldData.isConditional = this.isConditional;
        this.fieldData.isHidden = true;
        // Object.assign(this.fieldData, { condition });
        this.fieldData.conditions.push(condition);
        this.conditionalFieldValue = null;
        this.conditionalField = null;
        Helper.makeToast(this.$bvToast, "success", "Conditional  Updated");
      } else {
        Helper.makeToast(this.$bvToast, "danger", "No Condition selected");
      }
    },
    onCheckDivisionCode(value) {
      if (value == 1) {
        this.fieldData.isHidden = true;
      } else {
        this.fieldData.isHidden = false;
      }
    },
    onChangeDefaultHighRiskValue(value) {
      if (value == 0) {
        this.fieldData.highRiskAnsValue = null;
      }
    },
    resetCombinedHighRiskData() {
      this.combinedHighRiskAnswerValues = null;
      this.selectedCombinedHighRiskQuestionAndAnswers =
        this.makeSelectedCombinedHighRiskQuestionAndAnswers();
    },
    onRemoveConditionOpt(optId, index) {
      if (optId && index !== -1) {
        this.fieldData.conditions.splice(index, 1);
      }
    },

    getIndustryGroups() {
      return IndustriesRiskRatings.industries.reduce(
        (totalGroups, industry) => {
          const groups = industry.subDivisions.reduce(
            (subdivisionGroups, subdivision) => {
              for (let index = 0; index < subdivision.groups?.length; index++) {
                subdivisionGroups.push(
                  subdivision.groups[index].groupName?.english
                );
              }

              return subdivisionGroups;
            },
            []
          );

          if (groups?.length) {
            totalGroups.push(...groups);
          }

          return totalGroups;
        },
        []
      );
    },

    setSelectedCombinedHighRiskAnswerOptions(value) {
      if (value) {
        const selectedQuestion = this.allFormQuestions.find(
          (question) => question?.label === value
        );

        if (selectedQuestion?.element?.inputType === "comboSelectProgressive") {
          const industryGroups = this.getIndustryGroups();

          this.selectedCombinedHighRiskAnswerOptions = industryGroups.map(
            (groupName) => ({
              label: groupName,
              value: {
                sourceFieldId: selectedQuestion?.element?.id,
                targetFieldId: this.fieldData?.id,
                value: groupName,
                label: selectedQuestion?.label
              }
            })
          );
        } else {
          this.selectedCombinedHighRiskAnswerOptions =
            selectedQuestion?.element?.options?.map((option) => ({
              label: option?.text?.english,
              value: {
                sourceFieldId: selectedQuestion?.element?.id,
                targetFieldId: this.fieldData?.id,
                value: option?.text?.english,
                label: selectedQuestion?.label
              }
            }));
        }

        this.selectedCombinedHighRiskAnswerOptions =
          this.selectedCombinedHighRiskAnswerOptions?.sort((a, b) =>
            a?.label.localeCompare(b?.label)
          );
      } else {
        this.selectedCombinedHighRiskAnswerOptions = [];
      }
    },
    setCombinedHighRiskData() {
      if (
        this.combinedHighRiskAnswerValues?.length &&
        this.selectedCombinedHighRiskQuestionAndAnswers?.question &&
        this.selectedCombinedHighRiskQuestionAndAnswers?.answers?.length
      ) {
        this.fieldData.combinedHighRiskAnswerValues =
          this.combinedHighRiskAnswerValues;
        this.fieldData.combinedHighRiskQuestionsAndAnswers = [
          this.selectedCombinedHighRiskQuestionAndAnswers
        ];
      }
    },
    updateOptionScore(event, optionIndex) {
      this.fieldData.options[optionIndex].score = event;
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number
  }
};
</script>
