import { breadcrumbRouteNames, routeNames, urls, templates } from "@/constants";
import UserEmailWrapperTemplate from "@/templates/UserEmailWrapperTemplate/UserEmailWrapperTemplate";
import AddEditUserEmailTemplate from "@/organisms/AddEditUserEmailTemplate/AddEditUserEmailTemplate";
import UserEmailTemplates from "@/organisms/UserEmailTemplates/UserEmailTemplates";

export default {
  path: urls.USER_EMAIL_TEMPLATES,
  name: routeNames.USER_EMAIL_TEMPLATE_WRAPPER,
  component: UserEmailWrapperTemplate,
  children: [
    {
      path: "",
      name: routeNames.USER_EMAIL_TEMPLATES,
      component: UserEmailTemplates,
      meta: {
        requiresAuth: true,
        template: templates.EB360,
        isEb360HeaderRequired: true,
        isEB360SideBarRequired: true,
        breadcrumbs: () => [
          { name: breadcrumbRouteNames.HOME, link: urls.HOME },
          {
            name: breadcrumbRouteNames.USER_EMAIL_TEMPLATES,
            link: urls.USER_EMAIL_TEMPLATES
          }
        ]
      }
    },
    {
      path: urls.EDIT_USER_EMAIL_TEMPLATE,
      name: routeNames.EDIT_USER_EMAIL_TEMPLATE,
      redirect: () => urls.USER_EMAIL_TEMPLATES,
      meta: {
        requiresAuth: true,
        template: templates.EB360,
        isEb360HeaderRequired: true,
        isEB360SideBarRequired: true
      }
    },
    {
      path: urls.EDIT_USER_EMAIL_TEMPLATE_ID(),
      name: routeNames.EDIT_USER_EMAIL_TEMPLATE_ID,
      component: AddEditUserEmailTemplate,
      meta: {
        requiresAuth: true,
        template: templates.EB360,
        isEb360HeaderRequired: true,
        isEB360SideBarRequired: true,
        breadcrumbs: () => [
          { name: breadcrumbRouteNames.HOME, link: urls.HOME },
          {
            name: breadcrumbRouteNames.USER_EMAIL_TEMPLATES,
            link: urls.USER_EMAIL_TEMPLATES
          },
          {
            name: breadcrumbRouteNames.EDIT_USER_EMAIL_TEMPLATE,
            link: urls.EDIT_USER_EMAIL_TEMPLATE
          }
        ]
      }
    },
    {
      path: urls.ADD_USER_EMAIL_TEMPLATE,
      name: routeNames.ADD_USER_EMAIL_TEMPLATE,
      component: AddEditUserEmailTemplate,
      meta: {
        requiresAuth: true,
        template: templates.EB360,
        isEb360HeaderRequired: true,
        isEB360SideBarRequired: true,
        breadcrumbs: () => [
          { name: breadcrumbRouteNames.HOME, link: urls.HOME },
          {
            name: breadcrumbRouteNames.USER_EMAIL_TEMPLATES,
            link: urls.USER_EMAIL_TEMPLATES
          },
          {
            name: breadcrumbRouteNames.ADD_USER_EMAIL_TEMPLATE
          }
        ]
      }
    }
  ]
};
