<template>
  <section>
    <BaseLoader v-if="isLoadingThirdPartyTrainingList" />
    <template v-else>
      <InputErrorMessage :error="errorMessage" />
      <BaseText :text="feedbackMessage" />
      <StickyTable
        :table-headers="tableHeaders"
        :table-rows="tableRows"
        data-test-id="training__table"
        @table-row-click="onTableRowClick"
      />
    </template>
  </section>
</template>

<script>
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import StickyTable from "@/molecules/StickyTable/StickyTable";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseBadge from "@/atoms/BaseBadge/BaseBadge";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import {
  trainingsTableHeaders,
  actionName,
  trainingsKeysDTO,
  icons,
  themes,
  shapes,
  views,
  DOMEvents
} from "@/constants";
import { mapActions, mapState } from "vuex";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import { getTrainingStatusTheme } from "@/utils";

export default {
  name: "ThirdPartyTrainingList",
  components: {
    StickyTable,
    BaseLoader,
    InputErrorMessage,
    BaseText
  },
  data() {
    return {
      errorMessage: "",
      feedbackMessage: "",
      themes
    };
  },
  async created() {
    if (!this.thirdPartyTrainingList?.length) {
      await this.fetchThirdPartyTrainingListWrapper();
    }
  },
  props: {
    thirdPartyId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState({
      thirdPartyTrainingList: (state) =>
        state.thirdParty.thirdPartyTrainingList,
      isLoadingThirdPartyTrainingList: (state) =>
        state.thirdParty.isLoadingThirdPartyTrainingList
    }),
    tableHeaders() {
      return this.thirdPartyTrainingList.length
        ? Object.keys(trainingsTableHeaders).reduce((acc, key) => {
            if (trainingsTableHeaders[key]) {
              acc.push({
                value: key,
                text: trainingsTableHeaders[key]
              });
            }
            return acc;
          }, [])
        : [];
    },
    tableRows() {
      return this.thirdPartyTrainingList?.length
        ? this.thirdPartyTrainingList.map((training) =>
            Object.keys(trainingsTableHeaders).reduce((acc, trainingKey) => {
              if (trainingKey === trainingsKeysDTO.STATUS) {
                acc[trainingKey] = {
                  component: BaseBadge,
                  componentOptions: {
                    theme: getTrainingStatusTheme(training[trainingKey]),
                    text: training[trainingKey]
                  }
                };
              } else if (trainingKey === trainingsKeysDTO.REMINDER_SENT) {
                acc[trainingKey] = {
                  component: BaseIcon,
                  componentOptions: {
                    icon: training[trainingKey] ? icons.CHECK : icons.MINUS,
                    size: 16,
                    theme: themes.LIGHT_GREY_INVERSE
                  },
                  styles: {
                    textAlign: "-webkit-center"
                  }
                };
              } else if (trainingKey === trainingsKeysDTO.DETAILS) {
                acc[trainingKey] = {
                  component: CallToAction,
                  componentOptions: {
                    icon: "file",
                    iconSize: 16,
                    theme: themes.SECONDARY,
                    shape: shapes.NONE
                  }
                };
              } else {
                acc[trainingKey] = {
                  component: BaseText,
                  componentOptions: {
                    tag: "span",
                    text: training[trainingKey]
                  }
                };
              }
              return acc;
            }, {})
          )
        : [];
    }
  },
  methods: {
    ...mapActions({
      fetchThirdPartyTrainingList:
        actionName.THIRD_PARTY.FETCH_THIRD_PARTY_TRAINING_LIST
    }),
    async fetchThirdPartyTrainingListWrapper() {
      try {
        this.errorMessage = "";
        this.feedbackMessage = "";
        await this.fetchThirdPartyTrainingList(this.thirdPartyId);
      } catch ({ message = "", isAPIFailing = true }) {
        if (isAPIFailing) {
          this.errorMessage = message;
        } else {
          this.feedbackMessage = message;
        }
      }
    },
    onTableRowClick(_, tableRowLevel) {
      this.$emit(DOMEvents.CLICK, {
        view: views.DETAILS,
        selectedCourse: this.thirdPartyTrainingList[tableRowLevel]
      });
    }
  }
};
</script>
