import { onboardingTasksService } from "@/services";
import { mutationName } from "@/constants";

export const actions = {
  async fetchOnboardingTasks({ commit, rootState }) {
    const { userId = "" } = rootState.user;
    const { data } = await onboardingTasksService.fetchOnboardingTasks(userId);
    commit(mutationName.ONBOARDING_TASK.SET_ONBOARDING_TASKS, data, {
      root: true
    });
  },
  async fetchOnboardingTask({ commit }, { taskId, taskType, userId }) {
    const { data } = await onboardingTasksService.fetchOnboardingTask({
      userId,
      taskId,
      taskType
    });
    commit(mutationName.ONBOARDING_TASK.SET_ONBOARDING_TASK, data, {
      root: true
    });
  }
};
