<template>
  <div class="error-page-template">
    <BaseIcon class="error-page-template__icon" v-bind="combinedIconOptions" />
    <BaseText v-bind="combinedHeadingOptions" />
    <BaseText v-bind="combinedContentOptions" />
    <component :is="linkComponent" v-bind="combinedLinkOptions">
      <CallToAction
        class="error-page-template__cta"
        v-bind="combinedCTAOptions"
      />
    </component>
  </div>
</template>

<script>
import { urls, typographySize, themes } from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import CallToAction from "@/atoms/CallToAction/CallToAction";

export default {
  name: "ErrorPageTemplate",
  components: {
    BaseText,
    BaseIcon,
    CallToAction
  },
  props: {
    iconOptions: {
      type: Object,
      default: () => ({})
    },
    headingOptions: {
      type: Object,
      default: () => ({})
    },
    contentOptions: {
      type: Object,
      default: () => ({})
    },
    CTAOptions: {
      type: Object,
      default: () => ({})
    },
    linkComponent: {
      type: String,
      default: "router-link"
    },
    linkOptions: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      urls,
      typographySize,
      themes
    };
  },
  computed: {
    combinedIconOptions() {
      return {
        shape: "circle",
        size: 40,
        theme: themes.ETHIXBASE_PRIMARY_INVERSE,
        ...this.iconOptions
      };
    },
    combinedHeadingOptions() {
      return {
        tag: "h2",
        size: typographySize.HEADING_TWO,
        ...this.headingOptions
      };
    },
    combinedContentOptions() {
      return {
        tag: "p",
        ...this.contentOptions
      };
    },
    combinedCTAOptions() {
      return {
        value: "Return to home",
        theme: themes.PRIMARY,
        ...this.CTAOptions
      };
    },
    combinedLinkOptions() {
      return {
        to: urls.HOME,
        ...this.linkOptions
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.error-page-template {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  &__icon {
    border: 4px solid !important;
  }

  &__cta {
    margin-top: 24px;
  }
}
</style>
