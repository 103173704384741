import {
  userMember,
  userPassword,
  userRegistration,
  roles,
  loginType
} from "@/constants";
import { getPermittedLandingPage } from "@/utils";

export const getters = {
  isUserThirdParty: (state) => state.memberGroupId === userMember.THIRD_PARTY,
  isUserNotUpdated: (state) =>
    state.registrationStatus === userRegistration.UPDATE_PROFILE,
  isResetPasswordNeeded: (state) =>
    state.passwordCreated === userPassword.RESET_PASSWORD,
  isUserSuperAdmin: (state) =>
    !!state.roles?.find(({ id }) => id === roles.SUPER_ADMIN),
  isUserAdmin: (state) =>
    !!state?.roles?.find(({ id = "" }) => id === roles.ADMIN),
  isUserSuperAdminOrAdmin: (_, getters) =>
    getters.isUserSuperAdmin || getters.isUserAdmin,
  hasNoUserData: (state) =>
    !state.passwordCreated &&
    !state.registrationStatus &&
    !state.memberGroupId &&
    !state.landingPage &&
    !state.companyId,
  isLoggedInViaThePlatform: (state) =>
    state.loginType === loginType.PLATFORM_LOGIN,
  getCompanyName: (_, __, rootState) =>
    rootState.company.companyList.find(
      ({ id }) => id === rootState.company.companyId
    )?.companyName || "",
  getFullName: (state) => {
    if (state.userFullName) {
      return state.userFullName;
    } else if (state.userFirstName && state.userLastName) {
      return `${state.userFirstName} ${state.userLastName}`;
    } else {
      return "";
    }
  },
  permittedLandingPage: (state) =>
    getPermittedLandingPage({ landingPage: state.landingPage })
};
