<template>
  <section>
    <BaseLoader v-show="isLoading" />
    <div v-show="!isLoading">
      <ErrorPageTemplate v-if="isErrorPresent" v-bind="errorPageOptions" />
      <router-view v-else v-slot="{ Component }">
        <component
          :is="Component"
          :automations="automations"
          :automation="getAutomation"
          :automationConditions="automationConditions"
          @fetchAutomations="fetchAutomations"
          @editAutomation="onEditAutomation"
        />
      </router-view>
    </div>
  </section>
</template>

<script>
import { automationsService } from "@/services";
import { mapState, mapActions, mapMutations } from "vuex";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import { cloneDeep } from "lodash";
import { actionName, mutationName } from "@/constants";
import ErrorPageTemplate from "@/templates/ErrorPageTemplate/ErrorPageTemplate";

export default {
  name: "AutomationsTemplate",
  data() {
    return {
      isLoadingAutomationConditions: false,
      isLoadingAutomation: false,
      errorMessages: [],
      automationId: "",
      automations: [],
      automationConditions: []
    };
  },
  components: {
    BaseLoader,
    ErrorPageTemplate
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId,
      platformRequestSupportLink: (state) =>
        state.platformSettings.platformRequestSupportLink,
      isLoadingRoles: (state) => state.roles.isLoadingRoles,
      isLoadingActions: (state) => state.actions.isLoadingActions
    }),
    isLoading() {
      return (
        this.isLoadingAutomationConditions ||
        this.isLoadingRoles ||
        this.isLoadingActions ||
        this.isLoadingAutomation
      );
    },
    isErrorPresent() {
      return !!this.errorMessages.length;
    },
    errorPageOptions() {
      return {
        headingOptions: {
          text: "Incomplete data"
        },
        contentOptions: {
          text: this.errorMessages
        },
        CTAOptions: {
          value: "Please contact support"
        },
        linkComponent: "a",
        linkOptions: {
          href: this.platformRequestSupportLink,
          target: "_blank"
        }
      };
    },
    getAutomation() {
      const automation = this.automations.find(
        ({ id }) => id === this.automationId
      );
      return cloneDeep(automation);
    }
  },
  methods: {
    ...mapActions({
      fetchActions: actionName.ACTIONS.FETCH_ACTIONS,
      fetchRoles: actionName.ROLES.FETCH_ROLES
    }),
    ...mapMutations({
      resetActions: mutationName.ACTIONS.RESET_ACTIONS,
      resetRoles: mutationName.ROLES.RESET_ROLES
    }),
    onEditAutomation(id) {
      this.automationId = id;
    },
    async fetchAutomations() {
      try {
        this.isLoadingAutomation = true;
        const { data = [] } = await automationsService.fetchAutomations(
          this.companyId
        );
        this.automations = data;
      } catch {
        this.errorMessages.push("Failed to retrieve the automations.");
      } finally {
        this.isLoadingAutomation = false;
      }
    },
    async fetchAutomationConditions() {
      try {
        this.isLoadingAutomationConditions = true;
        const { data = [] } =
          await automationsService.fetchAutomationConditions(this.companyId);
        this.automationConditions = data;
      } catch (error) {
        this.errorMessages.push(
          "Failed to retrieve the automation conditions."
        );
      } finally {
        this.isLoadingAutomationConditions = false;
      }
    },
    async fetchActionsWrapper() {
      try {
        await this.fetchActions();
      } catch {
        this.errorMessages.push("Failed to retrieve the actions.");
      }
    },
    async fetchRolesWrapper() {
      try {
        await this.fetchRoles();
      } catch {
        this.errorMessages.push("Failed to retrieve the roles.");
      }
    }
  },
  async created() {
    this.automationId = this.$route?.params?.id;

    await Promise.allSettled([
      this.fetchAutomations(),
      this.fetchAutomationConditions(),
      this.fetchActionsWrapper(),
      this.fetchRolesWrapper()
    ]);
  },
  beforeRouteLeave(_, __, next) {
    this.resetActions();
    this.resetRoles();
    next();
  }
};
</script>
