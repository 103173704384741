<template>
  <div
    :class="[
      'dropdown-menu-heading',
      `dropdown-menu-heading--align-${getTextAlignValue()}`
    ]"
    data-test-id="dropdown-menu-heading"
  >
    <BaseText
      v-bind="getCombinedTextOptions(textOptions)"
      class="dropdown-menu-heading__text"
      data-test-id="dropdown-menu-heading__text"
    />
  </div>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import { typographySize } from "@/constants";
import { textAlignMixin } from "@/mixins";

export default {
  name: "DropdownMenuHeading",
  components: { BaseText },
  mixins: [textAlignMixin],
  props: {
    textOptions: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    getCombinedTextOptions(options = {}) {
      return {
        tag: "h3",
        size: typographySize.BODY_TEXT_BOLD,
        ...options
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown-menu-heading {
  margin: 8px 0 4px;
  padding: 0 10px;

  &__text {
    margin: 0;
  }

  &--align {
    &-left {
      text-align: left;
    }

    &-right {
      text-align: right;
    }

    &-center {
      text-align: center;
    }
  }
}
</style>
