/**
 * Converts a string to camelCase
 * @param {string} string - string to convert
 * @returns {string} - converted string
 */
const toCamel = (string = "") =>
  string?.replace(/([-_\s][a-z])/gi, (firstCharacter) =>
    firstCharacter
      .toUpperCase()
      .replace("-", "")
      .replace("_", "")
      .replace(" ", "")
      .replace(" ", "")
  ) || string;

/**
 * Converts a string to kebab-case
 * @param {string} string - string to convert
 * @returns {string} - converted string
 */
const toKebab = (string = "") => string?.replace(/([_\s])/gi, "-") || string;

/**
 * Returns true if provided string is JSON
 * @param {string} stringToParse - string to test
 * @returns {boolean} - result of test
 */
const isJsonString = (stringToParse) => {
  try {
    return !!JSON.parse(stringToParse);
  } catch {
    return false;
  }
};

/**
 * Converts a camelCase string to snake_case
 * @param {string} string - string to convert
 * @returns {string} - converted string
 */
const makeStringCamelToSnakeCase = (string) =>
  string.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

/**
 * Returns the provided string with whitespace replaced with provided character
 * @param {object} parameter - provided object
 * @param {string} parameter.value - string value
 * @param {string} parameter.separator - character to replace whitespace with
 * @returns {string} - replaced string
 */
const makeStringWithSeparator = ({ value, separator = "_" }) =>
  value?.replaceAll(/ /g, separator)?.toLowerCase();

/**
 * Returns positive number if `valueA` is comes before `valueB`
 * Returns negative number if `valueA` comes after `valueB`
 * Returns `0` if both `valueA` and `valueB` are the same
 * @param {object} parameter - provided object
 * @param {string} parameter.valueA - string value to compare
 * @param {string} parameter.valueB - second string value to compare first against
 * @returns {number} - result of compare
 */
const compareStringAlphabeticallyIgnoringCase = ({ valueA, valueB }) =>
  valueA.toLowerCase().localeCompare(valueB.toLowerCase());

export {
  toCamel,
  toKebab,
  isJsonString,
  makeStringCamelToSnakeCase,
  makeStringWithSeparator,
  compareStringAlphabeticallyIgnoringCase
};
