import { getSelectedOption, makeUUID } from "@/utils";

export class IfStatementClass {
  id = makeUUID();

  left = {
    value: "",
    options: [],
    error: ""
  };

  comparator = {
    options: [],
    error: ""
  };

  right = {};

  operator = {
    options: [],
    error: ""
  };

  constructor({
    leftOptions = [],
    selectedLeftValue = "",
    leftErrorMessage = "",
    comparatorOptions = [],
    comparatorErrorMessage = "",
    operatorOptions = [],
    operatorErrorMessage = "",
    rightComponent = {},
    rightComponentOptions = {}
  }) {
    this.setLeftValue(selectedLeftValue);
    this.setLeftOptions(leftOptions);
    this.setLeftErrorMessage(leftErrorMessage);
    this.setComparatorOptions(leftOptions, comparatorOptions);
    this.setComparatorErrorMessage(comparatorErrorMessage);
    this.setRightComponent(rightComponent);
    this.setRightComponentOptions(rightComponentOptions);
    this.updateOperatorOptions(operatorOptions);
    this.setOperatorErrorMessage(operatorErrorMessage);
  }

  setComparatorOptions(leftOptions, comparatorOptions) {
    const { value, type, isArray } = getSelectedOption(leftOptions);

    if (this.left.value === value && type) {
      this.comparator.options = comparatorOptions?.filter(
        (comparatorOption) =>
          !!comparatorOption?.allowedTypes?.find((allowedType) => {
            if (allowedType?.type) {
              return (
                allowedType.type === type && allowedType.isArray === isArray
              );
            } else {
              return allowedType === type;
            }
          })
      );
    } else {
      this.comparator.options = comparatorOptions || [];
    }

    if (!getSelectedOption(this.comparator.options)?.selected) {
      this.comparator.options[0].selected = true;
    }
  }

  getSelectedComparator() {
    return getSelectedOption(this.comparator.options);
  }

  setLeftOptions(leftOptions) {
    this.left.options = leftOptions;
  }

  getLeftOptions() {
    return this.left.options;
  }

  setLeftValue(leftValue) {
    this.left.value = leftValue;
  }

  setRightComponent(rightComponent) {
    this.right.component = rightComponent;
  }

  setRightComponentOptions(rightComponentOptions) {
    this.right.componentOptions = rightComponentOptions;
  }

  updateOperatorOptions(operatorOptions) {
    this.operator.options = operatorOptions;
  }

  getSelectedLeftOption() {
    return getSelectedOption(this.left.options) || null;
  }

  getSelectedLeftValueType() {
    return getSelectedOption(this.left.options)?.type || "";
  }

  setLeftErrorMessage(errorMessage) {
    this.left.error = errorMessage;
  }

  setComparatorErrorMessage(errorMessage) {
    this.comparator.error = errorMessage;
  }

  setOperatorErrorMessage(errorMessage) {
    this.operator.error = errorMessage;
  }
}
