export default {
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irányítópult"])},
  "dashboardTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feladat Irányítópult"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otthon"])},
  "my_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saját feladatok"])},
  "shared_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megosztott feladatok"])},
  "loadingSpinnerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betöltés..."])},
  "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az határideje"])},
  "nav": {
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sablonok"])},
    "dandi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D&I"])}
  },
  "questionnaires": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérdőívek"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltse ki az átvilágítási kérdőíveket és nyújtsa be a dokumentációt"])},
    "submitModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyújtsa be a magatartási kódexet"])},
    "formSubmittedModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentumok benyújtása"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzájárulok"])},
    "backToDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza az irányítópultra"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytatás"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áttekintés"])},
    "dateOfAssigned:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzárendelés dátuma:"])},
    "dateOfCompleted:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befejezés dátuma:"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letöltés"])},
    "batchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elvégezve"])},
    "batchInProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folyamatban"])},
    "batchInProgressDateSetText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folyamatban - Kitűzött dátum"])},
    "batchInProgressNoDateSetText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folyamatban - nincs beállítva dátum"])},
    "batchNotStartedext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs elkezdve"])},
    "deleteRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sor törlése"])},
    "editRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sor szerkesztése"])},
    "noSearchOptionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sajnáljuk, nincs megfelelő lehetőség."])},
    "requestedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kért időpont"])}
  },
  "formLeaveAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ön elhagyja az oldalt. A nem mentett módosítások elvesznek. Biztosan kilép?"])},
  "formSubmitingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ön elküldi kérdőívét. Ezt a műveletet nem lehet visszavonni. Biztosan elküldi?"])},
  "formSubmittedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az űrlapot sikeresen elküldtük."])},
  "formValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy vagy több hiányzó mezőt vagy hibát találtunk. Kérjük, ellenőrizze és szükség szerint frissítse."])},
  "toast": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figyelmeztetés"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siker mentése"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A folyamat nem menthető, próbálja újra"])},
    "updateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frissítési siker"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
    "generatePDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Létrehozjuk a PDF -jét ..."])},
    "successPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A PDF letöltése sikeres"])},
    "errorPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A PDF nincs letöltve, próbálja újra"])},
    "logoutSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelentkezés sikere"])}
  },
  "createProfile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regisztráció befejezése"])},
    "titleUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Profil frissítése"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "contactHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat adatai"])},
    "passwordHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új jelszó megadása"])},
    "fNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utónév"])},
    "lNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vezetéknév"])},
    "contactLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonszám"])},
    "jobTitleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beosztás"])},
    "currentPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi jelszó"])},
    "newPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új jelszó"])},
    "confirmPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó megerősítése"])},
    "fNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg keresztnevét"])},
    "lNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg vezetéknevét/vezetéknevét"])},
    "contactPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg kapcsolattartási számát"])},
    "jobTitlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írja be munkakörét vagy szerepét"])},
    "currentPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írja be az aktuális jelszót"])},
    "newPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írja be az új jelszót"])},
    "confirmPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írja be a jelszó megerősítését"])},
    "requiredValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a mező kötelező"])},
    "passwordValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A jelszavaknak azonosnak kell lenniük"])},
    "loaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kérés feldolgozása folyamatban van ..."])}
  },
  "upload": {
    "dragDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tartalom feltöltéséhez húzza ide a fájlt!"])},
    "dragDropContnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" vagy kattintson ide. és válassza ki a fájlt a számítógépről..."])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fájlnév"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fájltípus"])},
    "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fájlméret (bájt)"])},
    "fileAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Művelet"])}
  },
  "signature": {
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törlés"])},
    "require": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kívánt"])}
  },
  "workflow": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáadás"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törlés"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frissítés"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töröl"])}
  },
  "languages": {
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
    "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
    "chinesetr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chineset Traditional"])},
    "chinesesm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Simplified"])},
    "dutch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "gujarati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gujrati"])},
    "hebrew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebrew"])},
    "hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
    "indonesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesian"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
    "kannada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kannada"])},
    "marathi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marathi"])},
    "polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
    "portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
    "romanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romanian"])},
    "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
    "swedish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
    "tamil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamil"])},
    "telugu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telugu"])},
    "thai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai"])},
    "turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "ukrainian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrainian"])},
    "vietnamese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamese"])},
    "greek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greek"])},
    "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
    "czech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech"])},
    "hungarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungarian"])},
    "slovenian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenian"])}
  },
  "validations": {
    "invalidFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érvénytelen formátum"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az e -mail cím érvénytelen"])},
    "wrongPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érvénytelen jelenlegi jelszó."])}
  },
  "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
  "savingProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés..."])},
  "startBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rajt"])},
  "saveBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés"])},
  "confirmBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megerősítés"])},
  "continueBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytatás"])},
  "cancelBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mégsem"])},
  "closeBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezárás"])},
  "deleteBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törlés"])},
  "editBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerkesztés"])},
  "submitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldés"])},
  "saveProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folyamat mentése"])},
  "loginBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés"])},
  "logoutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelentkezés"])},
  "signInBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés"])},
  "signOutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelentkezés"])},
  "updateBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frissítés"])},
  "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "helpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súgó"])},
  "guideText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Útmutató"])}
}