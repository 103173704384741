import { actionKeys, actionKeysApi } from "@/constants";
import {
  makeDestinationDTO,
  makeDestinationAPIDTO,
  makeExpectedDataMappingDTO,
  makeExpectedDataMappingAPIDTO
} from "@/services/commonDto";
import { getSelectedOption } from "@/utils";

const makeTaskStepDTO = ({
  functionStep = {},
  _makeExpectedDataMappingDTO = makeExpectedDataMappingDTO,
  _makeDestinationDTO = makeDestinationDTO
} = {}) => ({
  [actionKeys.STEP_TYPE]: functionStep[actionKeysApi.STEP_TYPE],
  [actionKeys.TEMPLATE_ID]: functionStep[actionKeysApi.TEMPLATE_ID],
  [actionKeys.EXPECTED_DATA_MAPPING]: functionStep[
    actionKeysApi.EXPECTED_DATA_MAPPING
  ].map((expectedData) => _makeExpectedDataMappingDTO({ expectedData })),
  [actionKeys.ASSIGNEE]: _makeDestinationDTO({
    emailDestinations: [functionStep[actionKeysApi.ASSIGNEE]]
  })[0]
});

const makeTaskStepAPIDTO = ({
  functionStep = {},
  entities = [],
  _makeExpectedDataMappingAPIDTO = makeExpectedDataMappingAPIDTO,
  _makeDestinationAPIDTO = makeDestinationAPIDTO,
  _getSelectedOption = getSelectedOption
} = {}) => ({
  [actionKeysApi.STEP_TYPE]: functionStep.stepType,
  [actionKeysApi.TEMPLATE_ID]: _getSelectedOption(
    functionStep.taskTemplatesOptions
  )?.value,
  [actionKeysApi.EXPECTED_DATA_MAPPING]:
    functionStep.taskTemplateExpectedData.map((expectedData) =>
      _makeExpectedDataMappingAPIDTO({ expectedData, entities })
    ),
  [actionKeysApi.ASSIGNEE]: _makeDestinationAPIDTO({
    emailDestinations: functionStep.taskTemplateAssigneeOptions
  })[0]
});

export { makeTaskStepDTO, makeTaskStepAPIDTO };
