export default {
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πίνακας ελέγχου"])},
  "dashboardTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πίνακας ελέγχου εργασιών"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σπίτι"])},
  "my_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι εργασίες μου"])},
  "shared_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κοινές εργασίες"])},
  "loadingSpinnerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φόρτωση..."])},
  "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η προθεσμία είναι"])},
  "nav": {
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρότυπα"])},
    "dandi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D&I"])}
  },
  "questionnaires": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ερωτηματολόγια"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμπληρώστε ερωτηματολόγια δέουσας επιμέλειας και προσκομίστε τεκμηρίωση"])},
    "submitModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβολή ερωτηματολογίου"])},
    "formSubmittedModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβολή εγγράφων"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συναινώ"])},
    "backToDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιστροφή στον πίνακα ελέγχου"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνεχίστε"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισκόπηση"])},
    "dateOfAssigned:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία ανάθεσης"])},
    "dateOfCompleted:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία ολοκλήρωσης:"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήψη"])},
    "batchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ολοκλήρωση"])},
    "batchInProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σε εξέλιξη"])},
    "batchInProgressDateSetText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΣΕ ΠΡΟΟΔΟ - ΡΥΘΜΙΣΗ ΗΜΕΡΟΜΗΝΙΑΣ"])},
    "batchInProgressNoDateSetText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΣΕ ΠΡΟΟΔΟ - ΧΩΡΙΣ ΡΥΘΜΙΣΗ ΗΜΕΡΟΜΗΝΙΑΣ"])},
    "batchNotStartedext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΔΕΝ ΞΕΚΙΝΗΣΕ"])},
    "deleteRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή σειράς"])},
    "editRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία σειράς"])},
    "noSearchOptionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λυπούμαστε, δεν υπάρχουν επιλογές που να ταιριάζουν."])},
    "requestedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία που ζητήθηκε"])}
  },
  "formLeaveAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόκειται να φύγετε από τη σελίδα. Τυχόν μη αποθηκευμένες αλλαγές θα χαθούν. Είστε βέβαιοι ότι θέλετε να φύγετε;"])},
  "formSubmitingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόκειται να υποβάλετε το ερωτηματολόγιό σας. Αυτή η ενέργεια δεν μπορεί να αναιρεθεί. Είστε βέβαιοι ότι θέλετε να υποβάλετε;"])},
  "formSubmittedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η φόρμα σας έχει υποβληθεί επιτυχώς"])},
  "formValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βρέθηκαν ένα ή περισσότερα πεδία που λείπουν ή σφάλματα. Ελέγξτε και ενημερώστε όπως απαιτείται."])},
  "toast": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προειδοποίηση"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση επιτυχίας"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η πρόοδος δεν αποθηκεύεται, δοκιμάστε ξανά"])},
    "updateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενημέρωση επιτυχίας"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
    "generatePDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργούμε το PDF σας ..."])},
    "successPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχής λήψη PDF"])},
    "errorPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έγινε λήψη του PDF, δοκιμάστε ξανά"])},
    "logoutSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχία αποσύνδεσης"])}
  },
  "createProfile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμπληρώστε την εγγραφή"])},
    "titleUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενημέρωση προφίλ"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ"])},
    "contactHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στοιχεία Επικοινωνίας"])},
    "passwordHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενημέρωση κωδικού πρόσβασης"])},
    "fNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα"])},
    "lNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επώνυμο"])},
    "contactLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τηλέφωνο Επικοινωνίας"])},
    "jobTitleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εργασιακή θέση"])},
    "currentPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρέχον κωδικός πρόσβασης"])},
    "newPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νέος κωδικός πρόσβασης"])},
    "confirmPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση κωδικού πρόσβασης"])},
    "fNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε το Όνομά σας"])},
    "lNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε το επώνυμο/επώνυμό σας"])},
    "contactPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε τον αριθμό επικοινωνίας σας"])},
    "jobTitlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε τον τίτλο ή τον ρόλο της εργασίας σας"])},
    "currentPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγάγετε τον τρέχοντα κωδικό πρόσβασης"])},
    "newPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγωγή νέου κωδικού πρόσβασης"])},
    "confirmPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγωγή επιβεβαίωσης κωδικού πρόσβασης"])},
    "requiredValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το πεδίο είναι υποχρεωτικό"])},
    "passwordValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι κωδικοί πρόσβασης πρέπει να είναι ίδιοι"])},
    "loaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το αίτημά σας βρίσκεται υπό επεξεργασία ..."])}
  },
  "upload": {
    "dragDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεταφέρετε και αποθέστε για να ανεβάσετε περιεχόμενο!"])},
    "dragDropContnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["... ή κάνετε κλικ για να επιλέξετε ένα αρχείο από τον υπολογιστή σας"])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα αρχείου"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος αρχείου"])},
    "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέγεθος αρχείου (bytes)"])},
    "fileAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενέργεια"])}
  },
  "signature": {
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση"])},
    "require": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το πεδίο είναι υποχρεωτικό"])}
  },
  "workflow": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθαρισμός"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναφορά"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενημέρωση"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή"])}
  },
  "languages": {
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
    "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
    "chinesetr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Traditional"])},
    "chinesesm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Simplified"])},
    "dutch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "gujarati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gujrati"])},
    "hebrew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebrew"])},
    "hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
    "indonesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesian"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
    "kannada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kannada"])},
    "marathi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marathi"])},
    "polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
    "portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
    "romanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romanian"])},
    "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
    "swedish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
    "tamil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamil"])},
    "telugu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telugu"])},
    "thai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai"])},
    "turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "ukrainian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrainian"])},
    "vietnamese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamese"])},
    "greek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greek"])},
    "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
    "czech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech"])},
    "hungarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungarian"])},
    "slovenian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenian"])}
  },
  "validations": {
    "invalidFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μη έγκυρη μορφή"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η διεύθυνση ηλεκτρονικού ταχυδρομείου δεν είναι έγκυρη"])},
    "wrongPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μη έγκυρος τρέχων κωδικός πρόσβασης."])}
  },
  "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δ/Υ"])},
  "savingProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση ..."])},
  "startBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έναρξη"])},
  "saveBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση"])},
  "confirmBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση"])},
  "continueBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνέχεια"])},
  "cancelBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακύρωση"])},
  "closeBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κλείσιμο"])},
  "deleteBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή"])},
  "editBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία"])},
  "submitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβολή"])},
  "saveProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση προόδου"])},
  "loginBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεση"])},
  "logoutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποσύνδεση"])},
  "signInBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεση"])},
  "signOutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έξοδος"])},
  "updateBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενημέρωση"])},
  "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προφίλ"])},
  "helpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βοήθεια"])},
  "guideText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οδηγός"])}
}