<template>
  <div
    :class="[
      'base-radio',
      `base-radio__direction--${getDirection}`,
      ...(getSize && [`base-radio__size--${getSize}`])
    ]"
    data-test-id="base-radio"
  >
    <div
      v-for="({ label, checked }, index) in options"
      :key="index"
      class="base-radio__option"
      data-test-id="base-radio__option"
    >
      <input
        type="radio"
        :id="`${id}-${index}`"
        :name="name"
        :value="label"
        class="base-radio__input"
        data-test-id="base-radio__input"
        :checked="checked"
        @change="handleChange({ event: $event, index })"
      />
      <label :for="`${id}-${index}`" class="base-radio__label">{{
        label
      }}</label>
    </div>
  </div>
</template>

<script>
import { makeUUID } from "@/utils";
import { direction, sizes, themes, typographySize } from "@/constants";
import { isValidDirection } from "@/utils/validation/validation.utils";

export default {
  name: "BaseRadio",
  props: {
    options: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      required: true
    },
    direction: {
      type: String,
      default: direction.VERTICAL,
      validator(value) {
        return isValidDirection(value);
      }
    },
    changeMethod: {
      type: Function,
      default: null
    },
    size: {
      type: String,
      default: sizes.DEFAULT,
      validator(value) {
        return [sizes.DEFAULT, sizes.SMALL].includes(value);
      }
    }
  },
  data() {
    return {
      id: `base-radio_${makeUUID()}`,
      sizes,
      themes,
      typographySize
    };
  },
  computed: {
    getDirection() {
      return isValidDirection(this.direction)
        ? this.direction
        : direction.VERTICAL;
    },
    getSize() {
      return [sizes.DEFAULT, sizes.SMALL].includes(this.size)
        ? this.size
        : sizes.DEFAULT;
    }
  },
  methods: {
    handleChange({ event, index }) {
      if (this.changeMethod) {
        this.changeMethod({ event, index });
      } else {
        this.$emit("change", { event, index });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.base-radio {
  $width-and-height: $spacing20;
  $checked-width-and-height: $spacing8;
  $label-padding: $spacing24;
  $checked-label-padding: calc(
    $width-and-height / 2 - $checked-width-and-height / 2
  );
  display: flex;

  &__label {
    cursor: pointer;
    position: relative;
    padding-left: $label-padding;

    &:before {
      content: "";
      position: absolute;
      width: $width-and-height;
      height: $width-and-height;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      border: 1px solid $geyser-gray;
    }
  }

  &__input {
    @include screen-reader-only;

    &:checked {
      & + .base-radio__label {
        &:before {
          border-color: $picton-blue;
          background: $portal-blue;
        }

        &:after {
          content: "";
          position: absolute;
          width: $checked-width-and-height;
          height: $checked-width-and-height;
          left: $checked-label-padding;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 50%;
          background: $trace-blue;
        }
      }
    }

    &:focus {
      & + .base-radio__label {
        &:before {
          border-color: rgba($picton-blue, 0.7);
          background: $portal-blue;
          @include box-shadow-center($portal-blue);
        }
      }
    }
  }

  &__size--small {
    $width-and-height: $spacing16;
    $checked-width-and-height: $spacing6;
    $label-padding: $spacing20;
    $checked-label-padding: calc(
      $width-and-height / 2 - $checked-width-and-height / 2
    );

    .base-radio__label {
      padding-left: $label-padding;

      &:before {
        width: $width-and-height;
        height: $width-and-height;
      }
    }

    .base-radio__input {
      &:checked {
        & + .base-radio__label {
          &:after {
            width: $checked-width-and-height;
            height: $checked-width-and-height;
            left: $checked-label-padding;
          }
        }
      }
    }
  }

  &__direction {
    &--vertical {
      flex-direction: column;
    }

    &--horizontal {
      align-items: center;
      gap: $spacing12;
    }
  }
}
</style>
