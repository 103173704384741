<template>
  <div class="permissions">
    <BaseText
      class="permissions__heading"
      text="Permissions"
      :size="typographySize.DISPLAY_TEXT"
      hasDefaultSpacingRemoved
    />
    <Menu :itemOptions="itemOptions" @click="handleMenuClick" />
    <router-view v-slot="{ Component }">
      <component
        :is="Component"
        :roles="roles"
        :is-loading-roles="isLoadingRoles"
        :get-all-roles-as-multiselect-options="getAllRolesAsMultiselectOptions"
        :feature-permissions="featurePermissions"
        :is-feature-permissions-loading="isFeaturePermissionsLoading"
        :permissions-data-updated-successfully="
          permissionsDataUpdatedSuccessfully
        "
        :permissions-data-update-error="permissionsDataUpdateError"
        :is-updating-feature-permissions="isUpdatingFeaturePermissions"
        @save-click="handlePlatformFeaturesSaveClick"
      />
    </router-view>
  </div>
</template>

<script>
import Menu from "@/molecules/Menu/Menu";
import BaseText from "@/atoms/BaseText/BaseText";
import { isRouteNameMatched } from "@/utils";
import {
  urls,
  routeKeys,
  routeNames,
  typographySize,
  actionName,
  timers,
  getterName
} from "@/constants";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Permissions",
  components: {
    BaseText,
    Menu
  },
  data() {
    return {
      typographySize,
      permissionsDataUpdatedSuccessfully: false,
      permissionsDataUpdateError: ""
    };
  },
  created() {
    this.initializeDataFetching();
  },
  computed: {
    ...mapState({
      roles: (state) => state.roles.roles,
      isLoadingRoles: (state) => state.roles.isLoadingRoles,
      featurePermissions: (state) => state.permissions.featurePermissions,
      isFeaturePermissionsLoading: (state) =>
        state.permissions.isFeaturePermissionsLoading,
      isUpdatingFeaturePermissions: (state) =>
        state.permissions.isUpdatingFeaturePermissions
    }),
    ...mapGetters({
      getAllRolesAsMultiselectOptions:
        getterName.ROLES.GET_ALL_ROLES_AS_MULTISELECT_OPTIONS
    }),
    itemOptions() {
      return [
        {
          textOptions: {
            text: "Platform Features"
          },
          path: urls.PERMISSIONS_PLATFORM_FEATURES,
          isActive: isRouteNameMatched({
            matchedRoutes: this.$route?.matched,
            name: routeNames[routeKeys.PERMISSIONS_PLATFORM_FEATURES]
          })
        }
      ];
    }
  },
  methods: {
    ...mapActions({
      fetchRoles: actionName.ROLES.FETCH_ROLES,
      fetchFeaturePermissionsData:
        actionName.PERMISSIONS.FETCH_FEATURE_PERMISSIONS_DATA,
      updateFeaturePermissionsData:
        actionName.PERMISSIONS.UPDATE_FEATURE_PERMISSIONS_DATA
    }),
    handleMenuClick(id) {
      const path = this.itemOptions?.[id]?.path;

      this.$router?.push(path)?.catch((error) => error);
    },
    async handlePlatformFeaturesSaveClick(permissions) {
      const result = await this.updateFeaturePermissionsData(permissions);

      if (result) {
        this.permissionsDataUpdatedSuccessfully = true;
      } else {
        this.permissionsDataUpdateError =
          "Failed to update permissions, please try again or contact support if the issue persists.";
      }

      setTimeout(() => {
        this.permissionsDataUpdatedSuccessfully = false;
        this.permissionsDataUpdateError = "";
      }, timers.MODERATE_READABLE);
    },
    initializeDataFetching() {
      if (!this.roles?.length) {
        this.fetchRoles();
      }

      this.fetchFeaturePermissionsData();
    }
  }
};
</script>

<style lang="scss" scoped>
.permissions {
  text-align: left;

  &__heading {
    padding: 12px 16px;
  }
}
</style>
