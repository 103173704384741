const taskTemplateKeysDTO = {
  ID: "id",
  COMPANY_ID: "companyId",
  TEMPLATE_NAME: "templateName",
  TEMPLATE_DESCRIPTION: "templateDescription",
  TEMPLATE_TASK_ID: "templateTaskId",
  TASK_SUBJECT: "taskSubject",
  TASK_DESCRIPTION: "taskDescription",
  EXPECTED_DATA_DEFINITION: "expectedDataDefinition",
  TASK_DEFAULT_ASSIGNEE: "taskDefaultAssignee",
  EDIT_PERMISSIONS: "editPermissions",
  REOPEN_PERMISSIONS: "reopenPermissions",
  OUTCOME_ACTIONS: "outcomeActions",
  OUTCOMES_PERMISSIONS: "outcomePermissions",
  OUTCOME: "outcome",
  TRIGGERED_EXPECTED_DATA_VARIABLE_NAME: "triggeredExpectedDataVariableName",
  TRIGGERING_DATA: "triggeringData",
  DATA: "data",
  TYPE: "type",
  NAME: "name",
  MESSAGE: "message",
  ACTION: "action",
  ACTION_ID: "actionId",
  EXPECTED_DATA_MAPPING: "expectedDataMapping",
  OUTCOME_PERMISSIONS: "outcomePermissions",
  TASK_TEMPLATE_EXPECTED_DATA: "taskTemplateExpectedData",
  VARIABLES: "variables"
};

const taskTemplateKeysAPIDTO = {
  ID: "id",
  COMPANY_ID: "company_id",
  TEMPLATE_NAME: "template_name",
  TEMPLATE_DESCRIPTION: "template_description",
  TEMPLATE_TASK_ID: "template_task_id",
  TASK_SUBJECT: "task_subject",
  TASK_DESCRIPTION: "task_description",
  EXPECTED_DATA_DEFINITION: "expected_data_definition",
  TASK_DEFAULT_ASSIGNEE: "task_default_assignee",
  EDIT_PERMISSIONS: "edit_permissions",
  REOPEN_PERMISSIONS: "reopen_permissions",
  OUTCOME_ACTIONS: "outcome_actions",
  OUTCOMES_PERMISSIONS: "outcome_permissions",
  OUTCOME: "outcome",
  TRIGGERED_EXPECTED_DATA_VARIABLE_NAME:
    "triggered_expected_data_variable_name",
  TRIGGERING_DATA: "triggering_data",
  DATA: "data",
  TYPE: "type",
  NAME: "name",
  MESSAGE: "message",
  ACTION: "action",
  ACTION_ID: "action_id",
  EXPECTED_DATA_MAPPING: "expected_data_mapping",
  OUTCOME_PERMISSIONS: "outcome_permissions",
  VARIABLES: "variables"
};

export { taskTemplateKeysAPIDTO, taskTemplateKeysDTO };
