const mutationName = {
  USER: {
    RESET_USER: "user/resetUser",
    SET_REGISTRATION_STATUS: "user/setRegistrationStatus",
    SET_MEMBER_GROUP_ID: "user/setMemberGroupId",
    SET_LANDING_PAGE: "user/setLandingPage",
    SET_PASSWORD_CREATED: "user/setPasswordCreated",
    SET_ROLES: "user/setRoles",
    SET_LOGIN_TYPE: "user/setLoginType",
    SET_USER_ID: "user/setUserId",

    SET_USER_FULL_NAME: "user/setUserFullName",
    SET_USER_FIRST_NAME: "user/setUserFirstName",
    SET_USER_LAST_NAME: "user/setUserLastName",
    SET_EMAIL: "user/setEmail",
    SET_USER_TOUR_STATUS: "user/setUserTourStatus",
    SET_PLATFORM_VERSION: "user/setPlatformVersion",
    SET_FORM_DEFAULT_LANGUAGE: "user/setFormDefaultLanguage",
    SET_THIRD_PARTY_ID: "user/setThirdPartyId"
  },
  PLATFORM_SETTINGS: {
    SET_PLATFORM_LOGO: "platformSettings/setPlatformLogo",
    SET_PLATFORM_LOGO_FALLBACK: "platformSettings/setPlatformLogoFallback",
    SET_TERMS_AND_CONDITIONS_LINK: "platformSettings/setTermsAndConditionsLink",
    SET_PRIVACY_POLICY_LINK: "platformSettings/setPrivacyPolicyLink",
    SET_DISABLE_PRIVACY_POLICY: "platformSettings/setDisablePrivacyPolicy",
    SET_PLATFORM_PRIMARY_COLOR: "platformSettings/setPlatformPrimaryColor",
    SET_PLATFORM_SECONDARY_COLOR: "platformSettings/setPlatformSecondaryColor",
    SET_POSTHOG_API_KEY: "platformSettings/setPosthogAPIKey",
    SET_SSO_URL: "platformSettings/setSSOURL",
    SET_PLATFORM_BUSINESS_ADDRESS:
      "platformSettings/setPlatformBusinessAddress",
    SET_PLATFORM_COPYRIGHT_NOTICE:
      "platformSettings/setPlatformCopyrightNotice",
    SET_PLATFORM_FAQ_LINK: "platformSettings/setPlatformFaqLink",
    SET_PLATFORM_FAV_ICON: "platformSettings/setPlatformFavIcon",
    SET_PLATFORM_KNOWLEDGE_CENTRE_LINK:
      "platformSettings/setPlatformKnowledgeCentreLink",
    SET_PLATFORM_LEGAL_DISCLAIMER:
      "platformSettings/setPlatformLegalDisclaimer",
    SET_PLATFORM_MAIN_HEADER_LOGO: "platformSettings/setPlatformMainHeaderLogo",
    SET_PLATFORM_NAME: "platformSettings/setPlatformName",
    SET_PLATFORM_REGISTERED_BUSINESS_NAME:
      "platformSettings/setPlatformRegisteredBusinessName",
    SET_PLATFORM_RELEASE_NOTE_LINK:
      "platformSettings/setPlatformReleaseNoteLink",
    SET_PLATFORM_REQUEST_SUPPORT_LINK:
      "platformSettings/setPlatformRequestSupportLink",
    SET_PLATFORM_SUPPORT_EMAIL: "platformSettings/setPlatformSupportEmail",
    SET_PLATFORM_WEB_URL: "platformSettings/setPlatformWebUrl",
    RESET_PLATFORM_SETTINGS: "platformSettings/resetPlatformSettings",
    SET_PLATFORM_USER_LIST: "platformSettings/setPlatformUserList",
    SET_IS_LOADING_PLATFORM_SETTINGS:
      "platformSettings/setIsLoadingPlatformSettings",
    SET_PRODUCT_VERSIONS_LIST: "platformSettings/setProductVersionsList",
    SET_SINGLE_TP_MODAL_WORDING: "platformSettings/setSingleTpModalWording"
  },
  USER_SETTINGS: {
    RESET_USER_SETTINGS: "userSettings/resetUserSettings"
  },
  FORM: {
    SET_FORM_TRANSLATIONS: "SET_FORM_TRANSLATIONS"
  },
  COMPANY: {
    SET_COMPANY_LIST: "company/setCompanyList",
    SET_COMPANY_USERS: "company/setCompanyUsers",
    SET_TRIGGER_FETCH_ADDITIONAL_VOUCHERS:
      "company/setTriggerFetchAdditionalVouchers",
    SET_COMPANY_VIEWS: "company/setCompanyViews",
    RESET_COMPANY: "company/resetCompany",
    SET_REMAINING_VOUCHERS: "company/setRemainingVouchers",
    SET_COMPANY_DETAILS: "company/setCompanyDetails",
    SET_COMPANY_ID: "company/setCompanyId",
    SET_COMPANY_NAME: "company/setCompanyName",
    SET_COMPANY_FEATURES: "setCompanyFeatures",
    SET_TENANT_ID: "company/setTenantId"
  },
  AUTH: {
    SET_TOKEN: "auth/setToken",
    RESET_AUTH: "auth/resetAuth"
  },
  ROLES: {
    SET_ROLES: "roles/setRoles",
    SET_IS_LOADING_ROLES: "roles/setIsLoadingRoles",
    RESET_ROLES: "roles/resetRoles"
  },
  ACTIONS: {
    SET_ACTIONS: "actions/setActions",
    SET_IS_LOADING_ACTIONS: "actions/setIsLoadingActions",
    SET_IS_LOADING_ACTION: "actions/setIsLoadingAction",
    SET_ACTION: "actions/setAction",
    RESET_ACTION: "actions/resetAction",
    RESET_ACTIONS: "actions/resetActions"
  },
  EXPECTED_DATA: {
    SET_IS_LOADING_EXPECTED_DATA: "expectedData/setIsLoadingExpectedData",
    SET_EXPECTED_DATA: "expectedData/setExpectedData",
    SET_EXPECTED_DATA_ENTITY_REFERENCES:
      "expectedData/setExpectedDataEntityReferences",
    RESET_EXPECTED_DATA: "expectedData/resetExpectedData"
  },
  CUSTOM_ATTRIBUTES: {
    SET_CUSTOM_ATTRIBUTE_LIST_IS_LOADING:
      "customAttributes/setCustomAttributeListIsLoading",
    SET_CUSTOM_ATTRIBUTE_LIST: "customAttributes/setCustomAttributesList"
  },
  COUNTRY: {
    SET_COUNTRIES: "country/setCountries",
    RESET_COUNTRIES: "country/resetCountries"
  },
  NO_NAME_SPACE: {
    SET_SIDE_BAR_MENU: "setSidebarMenu",
    SET_IS_SIDE_BAR_OPENED: "setIsSidebarOpened"
  },
  THIRD_PARTY: {
    RESET_THIRD_PARTY: "thirdParty/resetThirdParty",
    SET_FORMS_LANGUAGES_LIST: "setFormsLanguagesList",
    SET_IS_THIRD_PARTY_ADD_MODAL_VISIBLE:
      "thirdParty/setIsThirdPartyAddModalVisible",
    SET_IS_THIRD_PARTY_NEW_MODAL_VISIBLE:
      "thirdParty/setIsThirdPartyNewModalVisible",
    SET_THIRD_PARTY_DATA: "thirdParty/setThirdPartyData",
    RESET_THIRD_PARTY_DATA: "thirdParty/resetThirdPartyData",
    SET_OC_STATES_LIST: "setOCStatesList",
    SET_HEADER_CONFIG_DATA: "setHeaderConfigData",
    SET_EDD_CLIENT_ALL_PACKAGES: "setEddClientAllPackages",
    SET_CUSTOM_ATTRIBUTES: "setCustomAttributes",
    SET_IS_UBO_DATA_LOADING: "thirdParty/setIsUBODataLoading",
    SET_THIRD_PARTY_UBO_LIST: "thirdParty/setThirdPartyUBOList",
    SET_THIRD_PARTY_UBO_ERROR: "thirdParty/setThirdPartyUBOError",
    SET_CREATE_THIRD_PARTY_ERROR: "thirdParty/setCreateThirdPartyError",
    SET_IS_CREATING_THIRD_PARTY: "thirdParty/setIsCreatingThirdParty",
    SET_IS_CREATING_THIRD_PARTY_SUCCESSFUL:
      "thirdParty/setIsCreatingThirdPartySuccessful",
    SET_IS_THIRD_PARTY_BULK_UPLOAD_MODAL_VISIBLE:
      "thirdParty/setIsBulkUploadModalVisible",
    SET_THIRD_PARTY_TRAINING_LIST: "setThirdPartyTrainingList",
    RESET_THIRD_PARTY_TRAINING_LIST: "thirdParty/resetThirdPartyTrainingList",
    SET_IS_LOADING_THIRD_PARTY_TRAINING_LIST:
      "thirdParty/setIsLoadingThirdPartyTrainingList",
    SET_THIRD_PARTY_TRAINING_DETAILS: "setThirdPartyTrainingDetails",
    RESET_THIRD_PARTY_TRAINING: "thirdParty/resetThirdPartyTraining",
    SET_IS_LOADING_THIRD_PARTY_TRAINING_DETAILS:
      "thirdParty/setIsLoadingThirdPartyTrainingDetails",
    SET_IS_LOADING_SEND_EMAIL_FOR_THIRD_PARTY_UPDATE_EXCEL_SHEET:
      "thirdParty/setIsLoadingSendEmailForThirdPartyUpdateExcelSheet",
    SET_IS_LOADING_THIRD_PARTY_BULK_UPLOAD_SAMPLE_FILE:
      "thirdParty/setIsLoadingThirdPartyBulkUploadSampleFile"
  },
  ESG: {
    RESET_ESG_STATE: "esg/resetESGState",
    SET_ESG_REPORTS: "esg/setESGReports",
    SET_LATEST_ESG_REPORT: "esg/setLatestESGReport"
  },
  VERSION: {
    SET_LAST_RECORDED_UI_VERSION: "version/setLastRecordedUIVersion"
  },
  USER_EMAIL_TEMPLATES: {
    SET_USER_EMAIL_TEMPLATE: "userEmailTemplates/setUserEmailTemplate",
    SET_USER_EMAIL_TEMPLATES: "userEmailTemplates/setUserEmailTemplates",
    SET_IS_LOADING_USER_EMAIL_TEMPLATES:
      "userEmailTemplates/setIsLoadingUserEmailTemplates",
    RESET_USER_EMAIL_TEMPLATES: "userEmailTemplates/resetUserEmailTemplates",
    RESET_USER_EMAIL_TEMPLATE: "userEmailTemplates/resetUserEmailTemplate"
  },
  TASK_TEMPLATES: {
    SET_IS_LOADING_TASK_TEMPLATE: "taskTemplates/setIsLoadingTaskTemplate",
    SET_TASK_TEMPLATE: "taskTemplates/setTaskTemplate",
    SET_IS_LOADING_TASK_TEMPLATES: "taskTemplates/setIsLoadingTaskTemplates",
    SET_TASK_TEMPLATES: "taskTemplates/setTaskTemplates",
    RESET_TASK_TEMPLATES: "taskTemplates/resetTaskTemplates",
    RESET_TASK_TEMPLATE: "taskTemplates/resetTaskTemplate"
  },
  UBO_ENTITY: {
    SET_UBO_ENTITY_DATA: "uboEntity/setUBOEntityData",
    SET_UBO_ENTITY_DATA_IS_LOADING: "uboEntity/setUBOEntityDataIsLoading",
    SET_UBO_ENTITY_ERROR_MESSAGE: "uboEntity/setUBOEntityErrorMessage",
    SET_UBO_DUE_DILIGENCE_REPORT_DATA_IS_LOADING:
      "uboEntity/setUBODueDiligenceReportDataIsLoading",
    SET_UBO_DUE_DILIGENCE_REPORT_DATA: "uboEntity/setUBODueDiligenceReportData",
    SET_UBO_DUE_DILIGENCE_REPORT_ERROR_MESSAGE:
      "uboEntity/setUBODueDiligenceErrorMessage",
    RESET_UBO_ENTITY: "uboEntity/resetUboEntity"
  },
  LOADER: {
    SET_IS_APP_LOADING: "loader/setIsAppLoading"
  },
  POSTHOG: {
    SET_DEFAULT_PROPERTIES: "posthog/setDefaultProperties",
    RESET_POSTHOG_STORE: "posthog/resetPosthogStore"
  },
  ONBOARDING_TASK: {
    SET_ONBOARDING_TASKS: "onboardingTasks/setOnboardingTasks",
    SET_ONBOARDING_TASK: "onboardingTasks/setOnboardingTask",
    RESET_ONBOARDING_TASKS: "onboardingTasks/resetOnboardingTasks",
    RESET_ONBOARDING_TASK: "onboardingTasks/resetOnboardingTask"
  },
  PERMISSIONS: {
    SET_PERMISSIONS: "permissions/setPermissions",
    SET_FEATURE_PERMISSIONS: "permissions/setFeaturePermissions",
    SET_IS_FEATURE_PERMISSIONS_LOADING:
      "permissions/setIsFeaturePermissionsLoading",
    SET_IS_UPDATING_FEATURE_PERMISSIONS:
      "permissions/setIsUpdatingFeaturePermissions",
    SET_IS_USER_PERMISSIONS_LOADING: "permissions/setIsUserPermissionsLoading",
    RESET_PERMISSIONS: "permissions/resetPermissions"
  },
  TASKS: {
    SET_IS_LOADING_TASKS: "tasks/setIsLoadingTasks",
    SET_IS_LOADING_TASK: "tasks/setIsLoadingTask",
    SET_TASK: "tasks/setTask",
    SET_TASKS: "tasks/setTasks",
    SET_TASKS_FILTER: "tasks/setTasksFilter",
    SET_TASKS_PAGINATION: "tasks/setTasksPagination",
    RESET_TASKS: "tasks/resetTasks",
    RESET_TASK: "tasks/resetTask"
  },
  LANGUAGES: {
    SET_LANGUAGES: "setLanguages"
  }
};

export { mutationName };
