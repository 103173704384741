import { isEmpty } from "lodash";
import { HTTP, makeAuthorizationHeader, downloadCSVData } from "@/utils";
import { endpoints, queryObjectKeys } from "@/constants";
import store from "@/store";

/**
 * @namespace ThirdPartyAPI
 */

/**
 * @typedef TableFields
 * @property {string} label - label of table
 * @property {boolean} sortable - if the table is sortable
 * @property {string} key - key of field
 */

/**
 * @typedef DefaultTableOptions
 * @property {TableFields} tableFields - table fields object
 * @property {string} defaultSortBy - sort by key
 * @property {string} method - method
 */

/**
 * @typedef FormDataObject
 * @property {number | undefined} comapnyId - id
 * @property {string | undefined} company_name - name of company
 * @property {string | undefined} email - email of company
 * @property {string | undefined} memberId - member id
 * @property {Array | undefined} thirdParties - list of third parties
 */

/**
 * @typedef {object} QueryObjectData
 * @property {string} filter - filter to be used in API query
 * @property {string} sort - sort to be used in API query
 * @property {string} per_page - per_page to be used in API query
 * @property {string} page_number - page_number to be used in API query
 * @property {string} page - page to be used in API query
 * @property {string} company_id - company_id to be used in API query
 * @property {object} querySpecificData - querySpecificData to be used in API query
 */

/**
 * Returns data object for third party API call
 * @memberof ThirdPartyAPI
 * @param {object} thirdPartyData - third party data
 * @returns {FormDataObject} - form data
 */
const makeFormDataObject = (thirdPartyData) => ({
  companyId: store.state.company.companyId,
  memberId: store.state.user.userId,
  email: store.state.user.email,
  thirdParties: thirdPartyData?.thirdParties,
  company_name: store.state.company.companyName
});

/**
 * Returns an object of present parameters
 * @memberof ThirdPartyAPI
 * @param {QueryObjectData} parameters - provided object
 * @returns {object} - constructed request parameters
 */
const createParameterObject = ({
  name,
  filter,
  sort,
  per_page,
  page_number,
  page,
  company_id,
  querySpecificData
}) => ({
  ...(name && { name }),
  ...(per_page && { per_page }),
  ...(page_number && { page_number }),
  ...(page && { page }),
  ...(company_id && { company_id }),
  ...(filter && { filter }),
  ...(sort && { sort }),
  ...(querySpecificData && querySpecificData)
});

/**
 * Returns default options for third party modal table
 * @memberof ThirdPartyAPI
 * @returns {DefaultTableOptions} - default options for a drill-down table query
 */
const makeDefaultTableOptions = () => ({
  method: "post",
  defaultSortBy: "comp_name_eng",
  tableFields: [
    {
      key: "comp_name_eng",
      label: "Third Party Name",
      sortable: true
    },
    {
      key: "country",
      label: "Country",
      sortable: true
    },
    {
      key: "created_date",
      label: "Submission date",
      sortable: false
    }
  ]
});

const queryObjectMap = {
  [queryObjectKeys.THIRD_PARTY_COUNTRIES]: {
    ...makeDefaultTableOptions(),
    uri: endpoints.CHART_THIRD_PARTY_COUNTRY_TABLE
  },
  [queryObjectKeys.CUSTOM_ATTRIBUTES]: {
    method: "get",
    uri: "",
    defaultSortBy: "name",
    tableFields: [
      {
        key: "comp_name_eng",
        label: "Third Party Name",
        sortable: false
      }
    ]
  },
  [queryObjectKeys.ENHANCE_DUE_DILIGENCE]: {
    ...makeDefaultTableOptions(),
    uri: endpoints.CHART_EDD_RESULTS_TABLE
  },
  [queryObjectKeys.IDD_POST_REMEDIATION]: {
    ...makeDefaultTableOptions(),
    uri: endpoints.CHART_IDD_PLUS_POST_REMEDIATION_TABLE
  },
  [queryObjectKeys.IDD_PRE_REMEDIATION]: {
    ...makeDefaultTableOptions(),
    uri: endpoints.CHART_IDD_PLUS_PRE_REMEDIATION_TABLE
  },
  [queryObjectKeys.SANCTIONS_ENFORCEMENT]: {
    ...makeDefaultTableOptions(),
    uri: endpoints.CHART_SANCTIONS_ALERT_TABLE
  },
  [queryObjectKeys.PERCEIVED_RISK_INDICATOR]: {
    ...makeDefaultTableOptions(),
    uri: endpoints.CHART_SANCTIONS_ALERT_TABLE
  },
  [queryObjectKeys.SANCTIONS_ENFORCEMENT_TABLE]: {
    ...makeDefaultTableOptions(),
    uri: endpoints.CHART_SANCTIONS_ALERT_TABLE
  },
  [queryObjectKeys.THIRD_PARTY_DDQ_QUESTIONS]: {
    ...makeDefaultTableOptions(),
    uri: endpoints.CHART_GET_THIRD_PARTY_IDD_QUESTION,
    downloadCSVFunction: async (thirdPartyData) => {
      const { data = {} } = await HTTP(
        "post",
        endpoints.CHART_GET_THIRD_PARTY_IDD_QUESTION_EXCEL,
        makeFormDataObject(thirdPartyData),
        makeAuthorizationHeader(),
        null,
        null,
        "blob"
      );

      return downloadCSVData(data);
    }
  },
  [queryObjectKeys.MSQ_RISK_SCORE_TP_LIST]: {
    uri: endpoints.DASHBOARD_RISK_SCORE_TABLE,
    method: "get",
    defaultSortBy: "comp_name_eng",
    tableFields: [
      {
        key: "comp_name_eng",
        label: "Third Party Name",
        sortable: true
      },
      {
        key: "country",
        label: "Country",
        sortable: true
      },
      {
        key: "created_at",
        label: "Submission date",
        sortable: false
      }
    ]
  },
  [queryObjectKeys.ESG_THIRD_PARTY]: {
    uri: endpoints.ESG_THIRD_PARTIES(store?.state?.company?.companyId),
    method: "get",
    defaultSortBy: "name",
    tableFields: [
      {
        key: "comp_name_eng",
        label: "Third Party Name",
        sortable: false
      }
    ]
  }
};

/**
 * @typedef queryTypeNameProps
 * @property {string} queryTypeName - query name
 */

/**
 * @typedef {QueryObjectData | queryTypeNameProps} QueryObjectDataWithType
 */

/**
 * Builds properties for use with an API based on the method.
 *
 * GET method will return the parameters as part of the URI.
 *
 * Other methods will return the parameters as a request object.
 * @memberof ThirdPartyAPI
 * @param {QueryObjectDataWithType} parameters - provided object
 * @returns {{formData: object, uri: string, method: string}} - request object
 */
const getThirdPartyQueryObject = ({
  name,
  queryTypeName,
  filter,
  sort,
  per_page,
  page_number,
  page,
  company_id,
  querySpecificData,
  uriData
}) => {
  if (isEmpty(queryObjectMap[queryTypeName])) {
    throw new Error("Incorrect third party data");
  }
  const parameterObject = createParameterObject({
    name,
    filter,
    sort,
    per_page,
    page_number,
    page,
    company_id,
    querySpecificData
  });
  let { uri, method } = queryObjectMap[queryTypeName];
  let URIQueryParameters;

  // on platform change company id not change in queryObject for esgThirdParty
  if (queryTypeName === queryObjectKeys.ESG_THIRD_PARTY) {
    uri = endpoints.ESG_THIRD_PARTIES(company_id);
  }

  // If query type is custom attributes and attributeId is present, set the uri.
  if (
    queryTypeName === queryObjectKeys.CUSTOM_ATTRIBUTES &&
    uriData?.attribute_id
  ) {
    uri = endpoints.CUSTOM_ATTRIBUTES_THIRD_PARTIES_LIST({
      companyId: uriData?.company_id,
      customAttributeId: uriData?.attribute_id,
      value: uriData?.value
    });
  }

  // If the method is a GET create query parameters and add them to the URI
  if (method.toLowerCase() === "get") {
    URIQueryParameters = new URLSearchParams(parameterObject);

    uri = `${uri}${
      URIQueryParameters?.toString() && `?${URIQueryParameters.toString()}`
    }`;
  }

  return {
    formData: parameterObject,
    uri,
    method
  };
};

export {
  getThirdPartyQueryObject,
  makeDefaultTableOptions,
  queryObjectMap,
  createParameterObject,
  makeFormDataObject
};
