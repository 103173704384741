<!-- MSQ Filter component -->
<template>
  <div>
    <div v-if="checkKeyLatestDdqSentFrom(col.label)">
      <b-form-group>
        <b-form-datepicker
          v-model="advanceFilter.lastMSQSentUploadedDateFrom"
          @input="setFilterUploadDateFrom"
          :date-format-options="{
            year: 'numeric',
            month: 'long',
            day: '2-digit'
          }"
          placeholder=""
        ></b-form-datepicker>
      </b-form-group>
    </div>
    <div v-else-if="checkKeyLatestDdqSentTo(col.label)">
      <b-form-group>
        <b-form-datepicker
          v-model="advanceFilter.lastMSQSentUploadedDateTo"
          @input="setFilterUploadDateTo"
          :date-format-options="{
            year: 'numeric',
            month: 'long',
            day: '2-digit'
          }"
          placeholder=""
        ></b-form-datepicker>
      </b-form-group>
    </div>
    <div v-else-if="checkKeyLatestCompletedMsqResult(columnKey)">
      <!-- {{data[columnKey]}} -->
      <b-form-group>
        <v-select
          label="text"
          v-model="advanceFilter.latestCompletedMSQResult"
          :options="optionsFilterLatestCompletedMSQResult"
          :clearable="true"
          :multiple="true"
          @update:modelValue="selectFilterLatestCompletedMSQResult"
        ></v-select>
      </b-form-group>
    </div>
    <div v-else-if="checkKeyLatestDdqStatus(columnKey)">
      <!-- {{data[columnKey]}} -->
      <!-- :label="col.label+':'" -->
      <b-form-group>
        <v-select
          label="text"
          v-model="advanceFilter.latestMSQTaskStatus"
          :options="optionsFilterLatestMSQTaskStatus"
          :clearable="true"
          :multiple="true"
          @update:modelValue="selectFilterLatestMSQTaskStatus"
        ></v-select>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/es";
import { EventBus } from "@/event-bus.js";

export default {
  name: "MsqStatusComponent",
  props: {
    columnKey: Array,
    col: Object,
    advanceFilterFromDate: String,
    advanceFilterToDate: String,
    advanceFilterLatestCompletedMSQResult: Array,
    filterAdvanceLatestCompletedMSQResult: Array,
    advanceFilterLatestMSQTaskStatus: Array,
    filterAdvanceLatestMSQTaskStatus: Array,
    filterItemsIndex: Number
  },
  data() {
    return {
      optionsFilterLatestCompletedMSQResult: [
        //        { text: "Not Sent", value: "-1" },
        { text: "High", value: "1" },
        { text: "Low", value: "2" },
        { text: "Moderate", value: "3" }
      ],
      optionsFilterLatestMSQTaskStatus: [
        //        { text: "Not Sent", value: "-1" },
        { text: "Not Started", value: "0" },
        { text: "In Progress", value: "1" },
        { text: "Completed", value: "4" },
        { text: "Cancelled", value: "8" },
        { text: "Declined / Refused", value: "9" },
        { text: "Self Evidenced", value: "10" },
        { text: "Never Sent", value: "-1" },
        { text: "In Progress - Pause Reminders", value: "14" }
      ],
      advanceFilter: {
        lastMSQSentUploadedDateFrom: this.advanceFilterFromDate,
        lastMSQSentUploadedDateTo: this.advanceFilterToDate,
        latestCompletedMSQResult: this.advanceFilterLatestCompletedMSQResult,
        latestMSQTaskStatus: this.advanceFilterLatestMSQTaskStatus
      },
      filterAdvance: {
        latestCompletedMSQResult: this.filterAdvanceLatestCompletedMSQResult,
        latestMSQTaskStatus: this.filterAdvanceLatestMSQTaskStatus
      }
    };
  },
  created() {
    // React on event resetAdvanceFilter
    EventBus.on("resetAdvanceFilter", () => {
      this.filterAdvance = {
        latestCompletedMSQResult: [],
        latestMSQTaskStatus: []
      };
      this.advanceFilter = {
        lastMSQSentUploadedDateFrom: null,
        lastMSQSentUploadedDateTo: null,
        latestCompletedMSQResult: [],
        latestMSQTaskStatus: []
      };
    });
  },
  mounted() {
    this.optionsFilterLatestCompletedMSQResult.sort(this.dynamicSort("text"));
    this.optionsFilterLatestMSQTaskStatus.sort(this.dynamicSort("text"));
  },
  methods: {
    // Sort filter Options
    dynamicSort(property) {
      return function (a, b) {
        return a[property].localeCompare(b[property]);
      };
    },
    formatDate(date) {
      if (date) {
        return moment.utc(date).local().locale("en").fromNow();
      } else {
        return false;
      }
    },
    checkKeyLatestDdqSentFrom(text) {
      return text.includes("From");
    },
    checkKeyLatestDdqSentTo(text) {
      return text.includes("To");
    },
    checkKeyLatestCompletedMsqResult(text) {
      return text[0].includes("latest_completed_ddq_status");
    },
    checkKeyLatestDdqStatus(text) {
      return text[0].includes("latest_ddq_status");
    },
    setFilterUploadDateFrom() {
      // Emit event setFilterUploadDateFrom
      EventBus.emit("setFilterUploadDateFrom", {
        filterIndex: this.filterItemsIndex,
        lastMSQSentUploadedDateFrom:
          this.advanceFilter.lastMSQSentUploadedDateFrom,
        index: this.col.index
      });
    },
    setFilterUploadDateTo() {
      // Emit event setFilterUploadDateTo
      EventBus.emit("setFilterUploadDateTo", {
        filterIndex: this.filterItemsIndex,
        lastMSQSentUploadedDateTo: this.advanceFilter.lastMSQSentUploadedDateTo,
        index: this.col.index
      });
    },
    selectFilterLatestCompletedMSQResult(value) {
      this.filterAdvance.latestCompletedMSQResult = [];
      let filterValue = [];
      value.map((filterQuestionnaire) => {
        filterValue.push({
          text: filterQuestionnaire.text,
          value: filterQuestionnaire.value
        });
        this.filterAdvance.latestCompletedMSQResult.push(
          filterQuestionnaire.value
        );
      });
      // Emit event selectFilterLatestCompletedMSQResult
      EventBus.emit("selectFilterLatestCompletedMSQResult", {
        filterIndex: this.filterItemsIndex,
        filterTypeValue: filterValue,
        latestCompletedMSQResult: this.filterAdvance.latestCompletedMSQResult,
        index: this.col.index
      });
      this.filterAdvance = [];
    },
    selectFilterLatestMSQTaskStatus(value) {
      this.filterAdvance.latestMSQTaskStatus = [];
      let filterValue = [];
      value.map((filterQuestionnaire) => {
        filterValue.push({
          text: filterQuestionnaire.text,
          value: filterQuestionnaire.value
        });
        this.filterAdvance.latestMSQTaskStatus.push(filterQuestionnaire.value);
      });
      // Emit event selectFilterLatestMSQTaskStatus
      EventBus.emit("selectFilterLatestMSQTaskStatus", {
        filterIndex: this.filterItemsIndex,
        filterTypeValue: filterValue,
        latestMSQTaskStatus: this.filterAdvance.latestMSQTaskStatus,
        index: this.col.index
      });
    }
  }
};
</script>
