<template>
  <b-row>
    <b-col cols="12">
      <b-tabs content-class="mt-3">
        <b-tab title="Text" active>
          <b-tabs pills card vertical>
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab
              @click="tabClick(data)"
              v-for="(data, index) in form.formTranslations"
              :key="index"
              :title="data"
            >
              <!-- translation form wrappe -->
              <div>
                <div class="form-group col-md-12">
                  <label for>Label</label>
                  <b-form-input @blur="addLanguageToField(data)" v-model="label" type="text"></b-form-input>
                </div>

                <div class="form-group col-md-12">
                  <label for>Label for button</label>
                  <b-form-input @blur="addLanguageToField(data)" v-model="buttonLabel" type="text"></b-form-input>
                </div>

                <div class="form-group col-md-12">
                  <label for>Label for checkbox</label>
                  <b-form-textarea
                    @blur="addLanguageToField(data)"
                    v-model="checkboxLabel"
                    rows="6"
                    max-rows="9"
                  >{{fieldData.checkboxLabel[transalationLanguage]}}</b-form-textarea>
                </div>

                <div class="form-group col-md-12">
                  <label for>Validation Message</label>
                  <b-form-input
                    @blur="addLanguageToField(data)"
                    v-model="validationMessage"
                    type="text"
                  ></b-form-input>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>
        <b-tab title="Uploads">
          <div>
            <!-- check for shw pdf form -->
            <b-form-checkbox
              v-if="form.formType == 1"
              v-model="linkPDF"
              @change="onLinkPDFFormClick"
              class="mb-3"
            >Link PDF Downloads</b-form-checkbox>

            <b-form-group label="Select PDF Form" v-if="linkPDF">
              <v-select
                label="title"
                v-model="selectedPDFForm"
                @update:modelValue="onPDFFormSelect"
                :options="$store.state.pdfForms"
                searchable
                clearable
              ></v-select>
            </b-form-group>
          </div>
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AdvancePDFSettings",
  components: {},
  data() {
    return {
      transalationLanguage: "english",
      label: undefined,
      checkboxLabel: undefined,
      buttonLabel: undefined,
      validationMessage: undefined,
      isLoading: false,
      linkPDF: false,
      selectedPDFForm: null
    };
  },

  mounted() {
    this.label = this.fieldData.label[this.transalationLanguage];
    this.checkboxLabel = this.fieldData.checkboxLabel[
      this.transalationLanguage
    ];
    this.buttonLabel = this.fieldData.buttonLabel[this.transalationLanguage];
    this.validationMessage = this.fieldData.validationMessage[
      this.transalationLanguage
    ];
    this.linkPDF = this.fieldData.linkPDF || false;
    this.selectedPDFForm = this.fieldData.selectedPdfForm || null;
  },
  computed: {
    ...mapGetters({ form: "form1" })
  },
  methods: {
    ...mapActions(["updateField"]),

    tabClick(key) {
      this.transalationLanguage = key.toLowerCase();
      this.label = this.fieldData.label[this.transalationLanguage];
      this.checkboxLabel = this.fieldData.checkboxLabel[
        this.transalationLanguage
      ];
      this.buttonLabel = this.fieldData.buttonLabel[this.transalationLanguage];
      this.validationMessage = this.fieldData.validationMessage[
        this.transalationLanguage
      ];
    },

    addLanguageToField(language) {
      let tempKey = language.toLowerCase();
      this.fieldData.label[tempKey] = this.label;
      this.fieldData.checkboxLabel[tempKey] = this.checkboxLabel;
      this.fieldData.buttonLabel[tempKey] = this.buttonLabel;
      this.fieldData.validationMessage[tempKey] = this.validationMessage;
    },

    onLinkPDFFormClick(e) {
      this.linkPDF = e;
      if(e == false){
        this.fieldData.selectedPdfForm = null
        this.selectedPDFForm = null
      }
      Object.assign(this.fieldData, (this.fieldData.linkPDF = e));
    },

    onPDFFormSelect(e) {
      let schema = JSON.parse(e.schema);
      if (schema && schema.formSection.length) {
        let selectedForm = {
          id: e.id,
          title: e.title,
          company_id: e.company_id,
          pdfFiles: schema.formSection[0].formElements[0]
        };
        Object.assign(
          this.fieldData,
          (this.fieldData.selectedPdfForm = selectedForm)
        );
      }
    }
  },
  props: {
    fieldData: Object,
    sectionIndex: Number,
    fieldIndex: Number
  }
};
</script>
<style>
.cursor {
  cursor: pointer;
}
</style>

