<template id="template-select">
  <div
    :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
    class="text-left"
    :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'"
  >
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :label="label"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
    <!-- preview tab -->
    <b-collapse
      :class="isPreview ? 'mt-0 mb-3' : 'mt-5'"
      id="collapse-1"
      v-model="collapse"
    >
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1' && !isPreview"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <label class="ml-2">
          <span
            v-if="
              fieldData.validation &&
              fieldData.validation.requiredFirstSelect == 1
            "
            class="required-span"
            >*</span
          >
          {{
            form1.enableSectionFieldEnumeration == 1
              ? sectionIndex + 1 + "." + (fieldIndex + 1)
              : ""
          }}
          {{ fieldData.label[selectedLanguage] }}
        </label>
        <b-col cols="12" class="text-left">
          <b-form-group
            :id="fieldData.id"
            :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
            :description="fieldData.helperTextFirst[selectedLanguage]"
            :invalid-feedback="
              fieldData.validationMessageFirstSelect[selectedLanguage]
            "
          >
            <label>
              <span
                v-if="
                  fieldData.validation &&
                  fieldData.validation.requiredFirstSelect == 1
                "
                class="required-span"
                >*</span
              >
              {{ fieldData.labelFirst[selectedLanguage] }}
            </label>

            <v-select
              :disabled="isDisabled == 1"
              :searchable="fieldData.configuration.isLiveSearchable"
              :clearable="fieldData.configuration.isClearable"
              :multiple="fieldData.configuration.isMultipleSelect"
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
              :options="firstSelect"
              label="text"
              value="index"
              v-model="selected"
              @update:modelValue="selectOption"
              @option:deselected="filterFirstOption"
            >
              <template #no-options>
                {{ $t("questionnaires.noSearchOptionText") }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="text-left">
          <b-form-group
            :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
            :description="fieldData.helperTextSeconed[selectedLanguage]"
            :invalid-feedback="
              fieldData.validationMessageSeconedSelect[selectedLanguage]
            "
          >
            <label>
              <span
                v-if="
                  fieldData.validation &&
                  fieldData.validation.requiredSeconedSelect == 1
                "
                class="required-span"
                >*</span
              >
              {{ fieldData.labelSeconed[selectedLanguage] }}
            </label>
            <v-select
              :disabled="isDisabled == 1"
              :searchable="fieldData.configuration.isLiveSearchableSecond"
              :clearable="fieldData.configuration.isClearableSecond"
              :multiple="fieldData.configuration.isMultipleSelectSecond"
              :options="secondSelect"
              v-model="secondSelected"
              @update:modelValue="selectOptionTwo"
              label="text"
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
              @option:deselected="filterSecondOptions"
            >
              <template #no-options>
                {{ $t("questionnaires.noSearchOptionText") }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="text-left">
          <b-form-group
            :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
            :description="fieldData.helperTextThird[selectedLanguage]"
            :invalid-feedback="
              fieldData.validationMessageThirdSelect[selectedLanguage]
            "
          >
            <label>
              <span
                v-if="
                  fieldData.validation &&
                  fieldData.validation.requiredThirdSelect == 1
                "
                class="required-span"
                >*</span
              >
              {{ fieldData.labelThird[selectedLanguage] }}
            </label>
            <v-select
              :disabled="isDisabled == 1"
              label="text"
              :searchable="fieldData.configuration.isLiveSearchableThird"
              :clearable="fieldData.configuration.isClearableThird"
              :multiple="fieldData.configuration.isMultipleSelectThird"
              :options="thirdSelect"
              v-model="thirdSelected"
              @update:modelValue="selectOptionThird"
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
            >
              <template #no-options>
                {{ $t("questionnaires.noSearchOptionText") }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- advance settings tab -->

      <b-modal
        cancel-title="Close"
        ok-title="Save"
        @ok="onSaveData"
        size="xl"
        v-model="showSettings"
        title="Combo select settings"
        no-close-on-esc
        no-close-on-backdrop
      >
        <AdvanceSelectSettings
          v-if="showSettings"
          :fieldData="fieldData"
          v-on:toggle-field="showSettings = !showSettings"
          v-on:update-form-settings="getData"
          @label-text="label = $event"
          @helper-text="helperText = $event"
          :isPreview="isPreview"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import IndustriesRiskRatings from "@/utils/industryRiskRatingData/industries.json";
import vSelect from "vue-select";
import MenuButtons from "@/components/menuButtons";
import AdvanceSelectSettings from "@/components/advanceComboSelectSettingsComponent";
import { mapGetters, mapState } from "vuex";
import { EventBus } from "@/event-bus.js";
import { Helper } from "@/helpers";
import _ from "lodash";
import { routeKeys, routeNames } from "@/constants";

export default {
  name: "ComboSelectProgressiveGroup",
  components: {
    MenuButtons,
    AdvanceSelectSettings,
    vSelect
  },
  data() {
    return {
      firstSelect: [],
      isHidden: false,
      isFocus: false,
      condition: {},
      showSettings: false,
      collapse: true,
      label: undefined,
      labelFirst: undefined,
      labelSeconed: undefined,
      helperTextFirst: undefined,
      helperTextSeconed: undefined,
      isRequireFirstSelect: 1,
      isRequireSeconedSelect: 1,
      name: "",
      Selected: [],
      secondSelect: [],
      secondSelected: [],
      thirdSelect: [],
      thirdSelected: [],
      selected: null,
      firstSelectSelected: null
    };
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage", "userFormSubmitArray"]),
    ...mapState(["toggleAllCoppalse"]),
    isMSQv3OrSupplyChainDDQ() {
      return (
        this.form1.formTitle.english
          .toLowerCase()
          .includes("modern slavery questionnaire v 3") ||
        this.form1.formTitle.english
          .toLowerCase()
          .includes("lieferkettengesetz") ||
        this.form1.formTitle.english
          .toLowerCase()
          .includes("human rights questionnaire") ||
        this.form1.formTitle.english
          .toLowerCase()
          .includes("modern slavery questionnaire v 4")
      );
    }
  },
  created() {
    EventBus.on("onSubmitEndUserForm", (value) => {
      if (value == "progress") {
        this.$store.state.validFields = true;
        this.fieldData.isInValid = false;
      } else {
        if (
          (this.fieldData.validation &&
            this.fieldData.validation.requiredFirstSelect == 1,
          this.fieldData.validation.requiredSeconedSelect == 1,
          this.fieldData.validation.requiredThirdSelect == 1)
        ) {
          if (this.fieldData.configuration.isMultipleSelectSecond) {
            if (
              this.selected &&
              this.secondSelected.length > 0 &&
              this.thirdSelected.length > 0
            ) {
              this.$store.state.validFields = true;
              this.fieldData.isInValid = false;
            } else {
              this.isFocus = true;
              this.$store.state.validFields = false;
              this.fieldData.isInValid = true;
            }
          } else if (!this.fieldData.configuration.isMultipleSelectSecond) {
            if (this.selected && this.secondSelected && this.thirdSelected) {
              this.$store.state.validFields = true;
              this.fieldData.isInValid = false;
            } else {
              this.isFocus = true;
              this.$store.state.validFields = false;
              this.fieldData.isInValid = true;
            }
          }
        }
      }
    });

    EventBus.on("OnInvalidFieldCatch", (inValidData) => {
      document
        .getElementById(inValidData.elementId)
        .scrollIntoView({ behavior: "smooth" });
    });
  },

  watch: {
    toggleAllCoppalse(value) {
      this.collapse = value;
    },

    selectedLanguage() {
      this.setValueForRenderOptions();
    }
  },

  mounted() {
    this.setValueForRenderOptions();
    this.isRequireFirstSelect = this.fieldData.validation.requiredFirstSelect;
    this.isRequireSeconedSelect =
      this.fieldData.validation.requiredSeconedSelect;
    this.requiredThirdSelect = this.fieldData.validation.requiredThirdSelect;
    this.selected = this.fieldData.selected || null;
    this.secondSelected = this.fieldData.selectedTwo || null;
    this.thirdSelected = this.fieldData.selectedThird || null;

    if (this.$route.name === routeNames[routeKeys.USER_FORM_PAGE]) {
      this.setFieldValue();
    }
  },

  methods: {
    setValueForRenderOptions() {
      this.firstSelect = [];
      this.selected = [];
      this.secondSelected = [];
      this.thirdSelected = [];
      this.secondSelect = [];
      this.thirdSelect = [];
      let industries = IndustriesRiskRatings.industries;
      industries.map((ind) => {
        let newOption = {
          value: ind,
          text: ind.divisionName[this.selectedLanguage] || "No Text Set"
        };
        this.firstSelect.push(newOption);
      });
    },
    getData(data) {
      this.$emit("update-form-settings", data);
    },

    filterFirstOption(selectedDiv) {
      if (this.isMSQv3OrSupplyChainDDQ) {
        // to hide
        this.onHideDivisionalConditionQuestion(selectedDiv);
        this.onShowDivisionalConditionQuestion();
      }
      // else{
      let secondArray = _.clone(this.secondSelected);
      let thirdArray = _.clone(this.thirdSelected);
      let filteredSubDivs = [];
      let filteredGroups = [];

      // extract division name from dropdown select
      // const selectedDiv = _.find(value, "divisionName");
      if (selectedDiv) {
        // filtering sub divisions
        if (secondArray && secondArray.length) {
          filteredSubDivs = _.remove(
            secondArray,
            (n) => n.divisionName !== selectedDiv.divisionName
          );
          this.secondSelected = filteredSubDivs;
        }
        // filtering groups
        if (thirdArray && thirdArray.length) {
          filteredGroups = _.remove(
            thirdArray,
            (n) => n.divisionName !== selectedDiv.divisionName
          );
        }
        this.thirdSelected = filteredGroups;
      }

      // }
    },

    filterSecondOptions(selectedDiv) {
      let thirdArray = _.clone(this.thirdSelected);
      let filteredGroups = [];

      // extract division name from dropdown select
      // const selectedDiv = _.find(value, "divisionName");
      if (selectedDiv) {
        // filtering groups
        if (thirdArray && thirdArray.length) {
          filteredGroups = _.remove(
            thirdArray,
            (n) => n.subDivisionName !== selectedDiv.subDivisionName
          );
        }
        this.thirdSelected = filteredGroups;
      }
    },

    setFieldValue() {
      this.setFieldLabel();
      this.$store.state.getUserFormSubmitArray.length &&
        this.$store.state.getUserFormSubmitArray.map((a) => {
          if (a && a.elementId && a.elements && a.elements.length) {
            if (a.elementId === this.fieldData.id) {
              a.elements.map((el) => {
                if (_.isArray(el.postValue.comboSelectOne)) {
                  this.selected = [];
                  el.postValue.comboSelectOne.map((first) => {
                    if (first.text == undefined) {
                      let index = this.firstSelect.findIndex(
                        (x) => x.value.code == first.code
                      );
                      if (index != -1) {
                        this.selected.push(this.firstSelect[index]);
                      }
                    } else {
                      this.selected.push(first);
                    }
                  });
                  this.selectOption(this.selected);
                }
                if (_.isArray(el.postValue.comboSelectTwo)) {
                  this.secondSelected = [];

                  let comb2 = this.selected.find((first) => first);
                  el.postValue.comboSelectTwo.map((second) => {
                    if (second.text == undefined) {
                      let index = comb2.value.subDivisions.findIndex(
                        (x) => x.code == second.code
                      );
                      if (index != -1) {
                        let option = {
                          value: comb2.value.subDivisions[index],
                          text:
                            comb2.value.subDivisions[index].subDivisionName[
                              this.selectedLanguage
                            ] || "No Text Set"
                        };
                        this.secondSelected.push(option);
                      }
                    } else {
                      this.secondSelected.push(second);
                    }
                  });
                  this.selectOptionTwo(this.secondSelected);
                }
                this.thirdSelected = [];
                let comb3 = this.secondSelected.find((second) => second);
                el.postValue.comboSelectThird.map((third) => {
                  if (third.text == undefined) {
                    let index = comb3.value.groups.findIndex(
                      (x) => x.code == third.code
                    );
                    if (index != -1) {
                      let option = {
                        value: comb3.value.groups[index],
                        text:
                          comb3.value.groups[index].groupName[
                            this.selectedLanguage
                          ] || "No Text Set"
                      };
                      this.thirdSelected.push(option);
                    }
                  } else {
                    this.thirdSelected.push(third);
                  }
                });
                this.selectOptionThird(this.thirdSelected);

                // this.selected = el.postValue.comboSelectOne;
                // this.secondSelected = el.postValue.comboSelectTwo;
                // this.thirdSelected = el.postValue.comboSelectThird ? el.postValue.comboSelectThird : [];
              });
            }
          }
        });
      this.setValue(this.fieldData.id);
    },
    setFieldLabel() {
      this.$store.state.userFormSubmitArray.map((field) => {
        if (field.elementId === this.fieldData.id) {
          field.elements.map((element) => {
            element.labels.comboSelectOneLabel = this.fieldData.labelFirst;
            element.labels.comboSelectTwoLabel = this.fieldData.labelSeconed;
            element.labels.comboSelectThirdLabel = this.fieldData.labelThird;
          });
        }
      });
    },

    selectOption(value) {
      this.secondSelect = [];

      let isArray = _.isArray(value);
      if (isArray) {
        if (this.isMSQv3OrSupplyChainDDQ) {
          this.onShowDivisionalConditionQuestion();
        }
        // this.secondSelect = [];
        if (value.length) {
          this.selected = value;
          for (let division of value) {
            if (division && division.value.subDivisions.length) {
              for (let SubDivision of division.value.subDivisions) {
                if (SubDivision) {
                  let newOption = {
                    value: SubDivision,
                    text:
                      SubDivision.subDivisionName[this.selectedLanguage] ||
                      "No Text Set"
                  };
                  this.secondSelect.push(newOption);
                }
              }
            }
          }
        } else {
          this.resetSelections();
        }
      } else {
        this.selected = [];
        if (value) {
          this.selected.push(value);
          // this.firstSelectSelected = value;
          for (let SubDivision of value.value.subDivisions) {
            if (SubDivision) {
              let newOption = {
                value: SubDivision,
                text:
                  SubDivision.subDivisionName[this.selectedLanguage] ||
                  "No Text Set"
              };
              this.secondSelect.push(newOption);
            }
          }
          EventBus.emit("refreshProductList", "singleSelect");
        } else {
          this.resetSelections();
        }
      }
    },
    checkForSubdivisions(val) {
      let oo = _.findIndex(
        this.secondSelected,
        (t) => t.divisionName == val.divisionName
      );
      if (oo != -1) {
        // let tt = _.find(this.secondSelected, function(o) { return o.divisionName == val.divisionName; });
        // let y = [];
        // y.push(this.secondSelected[oo])
        // let evens = _.remove(this.secondSelected, function(n) {
        //   return n.divisionName != val.divisionName;
        // });
      }
    },

    selectOptionTwo(value) {
      this.thirdSelect = [];
      let isArray = _.isArray(value);
      if (isArray) {
        if (value.length) {
          this.secondSelected = value;
          for (let subDivision of value) {
            if (subDivision && subDivision.value.groups.length) {
              for (let group of subDivision.value.groups) {
                if (group) {
                  let newOption = {
                    value: group,
                    text:
                      group.groupName[this.selectedLanguage] || "No Text Set"
                  };
                  this.thirdSelect.push(newOption);
                }
              }
            }
          }
          this.setValue();
          EventBus.emit("refreshProductList", value.length);
        } else {
          this.thirdSelected = null;
          EventBus.emit("refreshProductList", value.length);
        }
      } else {
        this.secondSelected = [];

        this.secondSelected.push(value);
        for (let group of value.value.groups) {
          if (group) {
            let newOption = {
              value: group,
              text: group.groupName[this.selectedLanguage] || "No Text Set"
            };
            this.thirdSelect.push(newOption);
          }
        }
        this.setValue();
        EventBus.emit("refreshProductList", "singleSelect");
      }
    },

    selectOptionThird(value) {
      this.thirdSelected = value;
      this.setValue();
      EventBus.emit("refreshProductList", value.length);
    },
    onShowDivisionalConditionQuestion() {
      this.$store.state.form1.formSection.map((section) => {
        section.formElements.map((element) => {
          if (
            element.isConditionalDivisionCodes &&
            element.isConditionalDivisionCodes == 1 &&
            element.conditionalFieldDivisionCodesValue.length
          ) {
            if (this.selected) {
              this.selected.map((item) => {
                let foundCode =
                  element.conditionalFieldDivisionCodesValue.findIndex(
                    (ele) => ele.code == item.value.code
                  );

                // != -1 means code found
                if (foundCode != -1) {
                  element.isHidden = false;
                  this.updateGetAndSubmitUserArr(element.id);
                }
              });
            }
          }
        });
      });
    },
    // update isHiddenElement key to to render into ddqonboarding page
    updateGetAndSubmitUserArr(id) {
      //  this.$store.state.getUserFormSubmitArray.length &&
      //  if(this.$store.state.getUserFormSubmitArray.length){
      this.$store.state.getUserFormSubmitArray.length &&
        this.$store.state.getUserFormSubmitArray.map((a) => {
          if (a.elementId == id) {
            a.isHiddenElement = 0;
            // a.elements.postValue = null
          }
        });
      this.$store.state.userFormSubmitArray.length &&
        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId == id) {
            a.isHiddenElement = 0;
            // a.elements.postValue = null
          }
        });
      //  }
    },
    onHideDivisionalConditionQuestion(selected) {
      this.$store.state.form1.formSection.map((section) => {
        section.formElements.map((element) => {
          if (
            element.isConditionalDivisionCodes &&
            element.isConditionalDivisionCodes == 1 &&
            element.conditionalFieldDivisionCodesValue.length
          ) {
            if (selected && selected.length) {
              element.conditionalFieldDivisionCodesValue.map((item) => {
                let foundCode = selected.findIndex(
                  (ele) => ele.value.code == item.code
                );
                if (foundCode == -1) {
                  element.isHidden = true;
                  EventBus.emit("onClearSelectedValue", element);
                  // EventBus.emit("onClearSelectedRadioValue", element);
                  this.onClearSelectInputValue(element.id);
                }
                // to remove sub groups second level
                // if(this.secondSelected){
                //   this.secondSelected.map((secondItem,index) =>{
                //   if(secondItem.value.divisionCode == item.code){
                //     this.secondSelected.splice(index);
                //   }
                // })
                // }
                // // to remove sub groups third level
                // if(this.thirdSelected){
                //   this.thirdSelected.map((thirdItem,index) =>{
                //   if(thirdItem.value.divisionCode == item.code){
                //     this.thirdSelected.splice(index);
                //   }
                // })
                // }
              });
            } else {
              element.isHidden = true;
              EventBus.emit("onClearSelectedValue", element);
              this.onClearSelectInputValue(element.id);
            }
          }
        });
      });
    },
    onClearSelectInputValue(id) {
      this.$store.state.getUserFormSubmitArray.length &&
        this.$store.state.getUserFormSubmitArray.map((a) => {
          if (a.elementId == id) {
            a.elements = [];
            a.isHiddenElement = 1;
            // a.elements.postValue = null
          }
        });
      this.$store.state.userFormSubmitArray.length &&
        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId == id) {
            a.elements = [];
            a.isHiddenElement = 1;
            // a.elements.postValue = null
          }
        });
    },
    resetSelections() {
      this.selected = null;
      this.secondSelected = null;
      this.thirdSelected = null;
      this.secondSelect = [];
      this.thirdSelect = [];
      EventBus.emit("refreshProductList", 0);
      this.onClearComboFields();
    },
    onClearComboFields() {
      this.$store.state.getUserFormSubmitArray.length &&
        this.$store.state.getUserFormSubmitArray.map((a) => {
          if (a.elementId == this.fieldData.id) {
            a.elements = [];
          }
        });
      this.$store.state.userFormSubmitArray.length &&
        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId == this.fieldData.id) {
            a.elements = [];
          }
        });
    },
    sectionCondition() {
      // for section conditions
      let sections = this.form1.formSection;
      let sectionSeven = sections.find(
        (sec) =>
          sec.sectionTitle.english == "Services/Goods (Additional Question)"
      );

      if (sectionSeven) {
        let sec = this.form1.formSection[sectionSeven.index];
        Object.assign(sec, (sec.isHidden = false));
        EventBus.emit(
          "sectionCheck",
          this.form1.formSection[sectionSeven.index]
        );
        this.form1.formSection[
          sectionSeven.index
        ].formElements[0].isHidden = false;
      }
    },

    setValue(e) {
      const registerData = _.clone(this.$store.state.registerData);

      if (this.$route.name === routeNames[routeKeys.USER_FORM_PAGE]) {
        if (this.secondSelected) {
          registerData[this.fieldData.id] = e;
        } else {
          delete registerData[this.fieldData.id];
        }
        this.$store.state.registerData = registerData;

        // main calculations :p
        const numkeys = Object.keys(registerData).length;
        this.$store.state.progressValue = numkeys;

        if (this.thirdSelected && this.thirdSelected.length) {
          this.userFormSubmitArray.find((a) => {
            if (a.elementId === this.fieldData.id) {
              let xMaxScore = Math.max(
                ...Array.from(
                  this.thirdSelected,
                  (o) => o.riskRating || o.value.riskRating
                )
              ); // to calculate max risk score
              a.elements[0] = {
                isMultipleSelectFirst:
                  this.fieldData.configuration.isMultipleSelect,
                isMultipleSelectSecond:
                  this.fieldData.configuration.isMultipleSelectSecond,
                isMultipleSelectThird:
                  this.fieldData.configuration.isMultipleSelectThird,
                postValue: {
                  comboSelectOne: this.selected,
                  comboSelectTwo: this.secondSelected,
                  comboSelectThird: this.thirdSelected
                },
                labels: {
                  comboSelectOneLabel: this.fieldData.labelFirst,
                  comboSelectTwoLabel: this.fieldData.labelSeconed,
                  comboSelectThirdLabel: this.fieldData.labelThird
                },
                score: xMaxScore || 0,
                postName: {
                  postNameComboSelectOne:
                    (this.fieldData.attributes &&
                      this.fieldData.attributes.postName) ||
                    "postNameComboSelectOne",
                  postNameComboSelectTwo:
                    (this.fieldData.attributes &&
                      this.fieldData.attributes.postName) ||
                    "comboSelectTwo",
                  postNameComboSelectThird:
                    (this.fieldData.attributes &&
                      this.fieldData.attributes.postName) ||
                    "comboSelectThird"
                }
              };
              this.sectionCondition(this.thirdSelected);
            }
          });
        }
      }
    },

    onSaveData() {
      EventBus.emit("onSaveSettings", "saving settings");
      Helper.makeToast(this.$bvToast, "success", "Settings Updated");
    }
  },

  validations: {
    selected: {
      required
    },
    secondSelected: {
      required
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
<style lang="scss">
.v-select.disabled .v-select-toggle[data-v-138dff1d] {
  background-color: $white !important;
}
</style>
