import { endpoints } from "@/constants";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import {
  makePlatformUserDTO,
  makePyramidTokenDTO
} from "@/services/views/dto/views.dto";

const fetchPlatformView = ({ userViewId = "", companyId = "" } = {}) =>
  HTTP(
    "get",
    endpoints.PLATFORM_VIEW_DETAILS({ userViewId, companyId }),
    null,
    makeAuthorizationHeader()
  );

const deletePlatformView = (id, companyId) =>
  HTTP(
    "post",
    `${endpoints.PLATFORM_VIEW_DELETE}/${id}`,
    {
      company_id: companyId
    },
    makeAuthorizationHeader()
  );

const fetchPlatformUsers = async (formData) => {
  const response = await HTTP(
    "post",
    endpoints.PLATFORM_USERS_LIST_ALL,
    formData,
    makeAuthorizationHeader()
  );
  return makePlatformUserDTO(response);
};

const fetchPlatformPyramidToken = async ({ companyId, domain }) => {
  const response = await HTTP(
    "get",
    endpoints.PLATFORM_PYRAMID_TOKEN({ companyId, domain }),
    null,
    makeAuthorizationHeader()
  );

  response.data = makePyramidTokenDTO(response?.data);

  return response;
};

export const viewsService = {
  fetchPlatformView,
  deletePlatformView,
  fetchPlatformUsers,
  fetchPlatformPyramidToken
};
