import {
  actionStatus,
  actionStatusValue,
  defaultIcons,
  iconSizes,
  ratingTypes,
  ratingTypeValue,
  riskIcons
} from "@/constants";

const getDefaultIconByStatus = (status) => {
  switch (status) {
    case actionStatusValue.OK:
    case actionStatus.OK:
      return defaultIcons.CHECK;
    case actionStatusValue.HIGH_ALERT:
    case actionStatus.HIGH_ALERT:
      return defaultIcons.HIGH_ALERT;
    case actionStatusValue.REMEDIATED_OK:
    case actionStatus.REMEDIATED_OK:
      return defaultIcons.REMEDIATED_OK;
    case actionStatusValue.REMEDIATED_HIGH_ALERT:
    case actionStatus.REMEDIATED_HIGH_ALERT:
      return defaultIcons.REMEDIATED_HIGH_ALERT;
    case actionStatusValue.IN_PROGRESS:
    case actionStatus.IN_PROGRESS:
      return defaultIcons.IN_PROGRESS;
    case actionStatusValue.WARNING_ALERT:
    case actionStatus.WARNING_ALERT:
      return defaultIcons.WARNING_ALERT;
    default:
      return defaultIcons.CHECK;
  }
};

const getIcon = (status, iconSize = iconSizes.DEFAULT) => {
  const defaultIcon = getDefaultIconByStatus(status);
  return {
    ...defaultIcon,
    size: iconSize
  };
};

const getRiskRatingIconByStatus = (status) => {
  switch (parseInt(status)) {
    case ratingTypeValue.UNDEFINED:
      return defaultIcons.SQUARE_DASHED;
    case ratingTypeValue.LOW:
      return defaultIcons.CHECK;
    case ratingTypeValue.MODERATE:
      return defaultIcons.WARNING_ALERT;
    case ratingTypeValue.HIGH:
      return defaultIcons.HIGH_ALERT;
    case ratingTypeValue.DO_NOT_ENGAGE:
      return defaultIcons.DO_NOT_ENGAGE;
    default:
      return defaultIcons.SQUARE_DASHED;
  }
};
const getRiskRatingIcon = (status, iconSize = iconSizes.DEFAULT) => {
  const riskRatingIcon = getRiskRatingIconByStatus(status);
  return {
    ...riskRatingIcon,
    size: iconSize
  };
};

const getIconWithTextByStatus = (status) => {
  switch (status) {
    case actionStatusValue.OK:
    case actionStatus.OK:
      return riskIcons.NO_RISK_ALERT;
    case actionStatusValue.HIGH_ALERT:
    case actionStatus.HIGH_ALERT:
      return riskIcons.RISK_ALERT;
    case actionStatusValue.REMEDIATED_OK:
    case actionStatus.REMEDIATED_OK:
      return riskIcons.NO_RISK_ALERT_REMEDIATED;
    case actionStatusValue.REMEDIATED_HIGH_ALERT:
    case actionStatus.REMEDIATED_HIGH_ALERT:
      return riskIcons.RISK_ALERT_REMEDIATED;
    case actionStatusValue.IN_PROGRESS:
    case actionStatus.IN_PROGRESS:
      return riskIcons.IN_PROGRESS;
    case actionStatusValue.WARNING_ALERT:
    case actionStatus.WARNING_ALERT:
      return riskIcons.MODERATE_RISK_ALERT;
    default:
      return riskIcons.NO_RISK_ALERT;
  }
};

const getIconWithText = (status, iconSize = iconSizes.DEFAULT) => {
  const defaultIcon = getIconWithTextByStatus(status);
  return {
    iconOptions: { ...defaultIcon.iconOptions, size: iconSize },
    textOptions: defaultIcon.textOptions
  };
};

const getRiskRatingIconWithTextByStatus = (status) => {
  const statusValue = !Number.isNaN(parseInt(status))
    ? parseInt(status)
    : status;

  switch (statusValue) {
    case ratingTypeValue.UNDEFINED:
      return riskIcons.NOT_AVAILABLE;
    case ratingTypeValue.LOW:
      return riskIcons.NO_RISK_ALERT;
    case ratingTypeValue.MODERATE:
      return riskIcons.MODERATE_RISK_ALERT;
    case ratingTypeValue.HIGH:
      return riskIcons.RISK_ALERT;
    case ratingTypeValue.DO_NOT_ENGAGE:
      return riskIcons.DO_NOT_ENGAGE;
    case ratingTypes.UNDEFINED:
      return riskIcons.UNDEFINED;
    case ratingTypes.LOW:
      return riskIcons.LOW;
    case ratingTypes.MODERATE:
      return riskIcons.MODERATE;
    case ratingTypes.HIGH:
      return riskIcons.HIGH;
    case ratingTypes.DO_NOT_ENGAGE:
      return riskIcons.DO_NOT_ENGAGE;
    default:
      return riskIcons.NOT_AVAILABLE;
  }
};

const getRiskRatingIconWithText = (status, iconSize = iconSizes.DEFAULT) => {
  const riskRatingIcon = getRiskRatingIconWithTextByStatus(status);
  return {
    iconOptions: { ...riskRatingIcon.iconOptions, size: iconSize },
    textOptions: riskRatingIcon.textOptions
  };
};

export {
  getDefaultIconByStatus,
  getRiskRatingIconByStatus,
  getIcon,
  getRiskRatingIcon,
  getIconWithTextByStatus,
  getIconWithText,
  getRiskRatingIconWithTextByStatus,
  getRiskRatingIconWithText
};
