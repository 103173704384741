<template>
  <div class="tp-over-time">
    <!-- graphical representation of third parties yearly start-->
    <b-card style="height: 29.1rem; border: none">
      <highcharts :options="savedThirdPartyChartsOptions" />
    </b-card>
    <!-- graphical representation of third parties yearly end-->
  </div>
</template>

<script>
export default {
  name: "TpOverTimeComponent",
  props: {
    savedThirdPartyChartsOptions: Object
  }
};
</script>

<style lang="scss" scoped>
.tp-over-time {
  border: $element-border;
}
</style>
