import { getSelectedOption, keysToCamel } from "@/utils";
import {
  actionKeys,
  actionKeysApi,
  actionStepType,
  comparatorOptionsMap,
  operations,
  validity,
  variableAssignmentType,
  variableDisplayTextToTypeMap,
  variableType,
  actionStepSubType
} from "@/constants";
import { cloneDeep, isEmpty } from "lodash";
import { makeStepDTO } from "@/services/actions/dto/actionsDTO/actions.dto";
import {
  makeFlatSetStepDTO,
  makeSetStepAPIDTO,
  makeValueAPIDTO
} from "@/services/actions/dto/setStepDTO/setStep.dto";
import { makeEndStepAPIDTO } from "@/services/actions/dto/endStepDTO/endStep.dto";
import { makeTriggerStepAPIDTO } from "@/services/actions/dto/triggerStepDTO/triggerStep.dto";
import { makeNotificationEmailStepAPIDTO } from "@/services/actions/dto/notificationEmailDTO/notificationEmail.dto";
import { makeTaskStepAPIDTO } from "@/services/actions/dto/taskStepDTO/taskStep.dto";

const makeValueForIfStatementAPIDTO = ({
  value = "",
  valueDataType = "",
  variableType = "",
  validity,
  comparator = "",
  entities = [],
  selectedLeftValue = ""
} = {}) => {
  if (
    comparator === operations.HAS_NO_VALUE ||
    comparator === operations.HAS_VALUE
  ) {
    const entity = entities.find(
      ({ data }) => data?.data?.name === selectedLeftValue
    );
    if (entity?.data?.data?.is_array) {
      return [];
    } else {
      return null;
    }
  } else {
    return makeValueAPIDTO({ value, valueDataType, variableType, validity });
  }
};

const makeValueForIfStatementDTO = ({ comparator, value }) => {
  if (
    comparator === operations.EQUALS &&
    Array.isArray(value) &&
    !value?.length
  ) {
    return null;
  } else {
    return value;
  }
};

const makeValueForIfStatementConditionsDTO = ({
  condition = {},
  _makeValueForIfStatementDTO = makeValueForIfStatementDTO
}) => {
  const tempCondition = cloneDeep(condition);
  if (tempCondition?.isGroup) {
    tempCondition.conditions = tempCondition?.conditions?.map((condition) =>
      makeValueForIfStatementConditionsDTO({
        condition,
        _makeValueForIfStatementDTO
      })
    );
  } else if (condition?.right?.data?.data?.hasOwnProperty("value")) {
    tempCondition.right.data.data.value = _makeValueForIfStatementDTO({
      comparator: tempCondition?.comparator,
      value: tempCondition?.right?.data?.data?.value
    });
  }

  return tempCondition;
};

const makeIfStatementDTO = ({
  functionStep,
  _makeValueForIfStatementConditionsDTO = makeValueForIfStatementConditionsDTO
}) => {
  const ifSuccessSteps = functionStep.if_block?.success_steps;
  const elseBlock = functionStep.else_block?.success_steps;
  delete functionStep.if_block?.success_steps;
  delete functionStep.else_block;

  let elseIfSuccessSteps = [];

  for (
    let elseIfIndex = 0;
    elseIfIndex < functionStep.else_if_blocks?.length;
    elseIfIndex++
  ) {
    elseIfSuccessSteps.push(
      functionStep.else_if_blocks?.[elseIfIndex]?.success_steps || []
    );
    delete functionStep.else_if_blocks?.[elseIfIndex]?.success_steps;
  }

  const mappedFunctionStep = keysToCamel(functionStep);
  mappedFunctionStep.ifBlock.successSteps =
    ifSuccessSteps.map((successStep) => makeStepDTO(successStep)) || [];
  mappedFunctionStep.ifBlock.condition = _makeValueForIfStatementConditionsDTO({
    condition: mappedFunctionStep.ifBlock.condition
  });

  for (let index = 0; index < functionStep.else_if_blocks?.length; index++) {
    mappedFunctionStep.elseIfBlocks[index].successSteps =
      elseIfSuccessSteps[index].map((successStep) =>
        makeStepDTO(successStep)
      ) || [];
    mappedFunctionStep.elseIfBlocks[index].condition =
      _makeValueForIfStatementConditionsDTO({
        condition: mappedFunctionStep.elseIfBlocks[index].condition
      });
  }
  mappedFunctionStep.elseBlock =
    elseBlock?.map((successStep) => makeStepDTO(successStep)) || [];
  mappedFunctionStep.comment = functionStep[actionKeysApi.COMMENT] || "";

  return mappedFunctionStep;
};

const makeConditionValueAPIDTO = (entityData, valueDataType, selectedValue) => {
  if (entityData) {
    return {
      [actionKeysApi.TYPE]: actionKeysApi.VARIABLE,
      [actionKeysApi.DATA]: entityData
    };
  } else {
    return {
      [actionKeysApi.TYPE]: actionKeysApi.VARIABLE,
      [actionKeysApi.DATA]: {
        [actionKeysApi.TYPE]: actionKeysApi.LOCAL,
        [actionKeysApi.DATA]: {
          [actionKeysApi.TYPE]: valueDataType,
          [actionKeysApi.DATA]: {
            [actionKeysApi.NAME]: selectedValue
          }
        }
      }
    };
  }
};

const makeLeftSideIfStatementAPIDTO = (leftOptions, entities) => {
  if (!leftOptions?.length) {
    return {};
  }
  const {
    value: selectedValue,
    subType: selectedOptionSubType,
    type: selectedOptionType
  } = getSelectedOption(leftOptions);
  const selectedType = selectedOptionSubType || selectedOptionType;
  const entityData = entities.find(
    ({ data }) => data.data.name === selectedValue
  );

  return makeConditionValueAPIDTO(entityData, selectedType, selectedValue);
};

const makeRightSideIfStatementAPIDTO = ({
  rightData,
  entities,
  comparator,
  selectedLeftValue,
  _makeValueForIfStatementAPIDTO = makeValueForIfStatementAPIDTO,
  _makeConditionValueAPIDTO = makeConditionValueAPIDTO
} = {}) => {
  if (isEmpty(rightData)) {
    return {};
  }
  const { value: entityType } = getSelectedOption(rightData.valueTypeOptions);
  const { value } = rightData.componentOptions;
  const valueDataType =
    variableDisplayTextToTypeMap[rightData.valueDataTypeText];

  if (entityType === variableAssignmentType.LITERAL) {
    return {
      [actionKeysApi.TYPE]: entityType,
      [actionKeysApi.DATA]: {
        [actionKeysApi.TYPE]: valueDataType,
        [actionKeysApi.DATA]: {
          [actionKeysApi.VALUE]: _makeValueForIfStatementAPIDTO({
            value,
            valueDataType,
            variableType,
            validity,
            comparator,
            entities,
            selectedLeftValue
          })
        }
      }
    };
  } else {
    const { value: selectedValue } = getSelectedOption(
      rightData.componentOptions.options
    );
    const entityData = entities.find(
      ({ data }) => data.data.name === selectedValue
    );
    return _makeConditionValueAPIDTO(entityData, valueDataType, selectedValue);
  }
};

const makeIfStatementConditionAPIDTO = ({
  dataValue = {},
  entities = [],
  comparator = "",
  selectedLeftValue = "",
  _makeRightSideIfStatementAPIDTO = makeRightSideIfStatementAPIDTO,
  _makeLeftSideIfStatementAPIDTO = makeLeftSideIfStatementAPIDTO
} = {}) => {
  if (isEmpty(dataValue)) {
    return null;
  }

  if (dataValue.component) {
    return _makeRightSideIfStatementAPIDTO({
      rightData: dataValue.componentOptions,
      entities,
      comparator,
      selectedLeftValue
    });
  } else {
    return _makeLeftSideIfStatementAPIDTO(dataValue.options, entities);
  }
};

const makeSingleIfStatementConditionAPIDTO = (ifStatement, entities) => {
  const { value: comparatorValue } = getSelectedOption(
    ifStatement.comparator.options
  );
  const { value: mappedComparatorValue, isInverse } =
    comparatorOptionsMap[comparatorValue];
  const left = makeIfStatementConditionAPIDTO({
    dataValue: ifStatement.left,
    entities,
    comparator: comparatorValue
  });
  const right = makeIfStatementConditionAPIDTO({
    dataValue: ifStatement.right,
    entities,
    comparator: comparatorValue,
    selectedLeftValue: ifStatement.left.value
  });
  return {
    [actionKeysApi.COMPARATOR]: mappedComparatorValue,
    [actionKeysApi.IS_INVERSE]: isInverse,
    [actionKeysApi.IS_GROUP]: false,
    [actionKeysApi.LEFT]: left,
    [actionKeysApi.RIGHT]: right
  };
};

const makeMultipleIfStatementAPIDTO = (ifStatementList, entities) => {
  const conditions = ifStatementList.map((statement) =>
    makeSingleIfStatementConditionAPIDTO(statement, entities)
  );

  return {
    [actionKeysApi.IS_GROUP]: true,
    [actionKeysApi.OPERATOR]: operations.AND,
    [actionKeysApi.IS_INVERSE]: false,
    [actionKeysApi.CONDITIONS]: conditions
  };
};

const makeGroupedIfStatementAPIDTO = (groupConditions, entities) => {
  let operator;
  let conditions = [];

  if (groupConditions.length > 1) {
    operator = operations.OR;
  } else {
    operator = operations.AND;
  }

  for (
    let groupConditionsIndex = 0;
    groupConditionsIndex < groupConditions.length;
    groupConditionsIndex++
  ) {
    const { bracket } = groupConditions[groupConditionsIndex];

    if (bracket.componentOptions.ifStatements.length > 1) {
      conditions.push(
        makeMultipleIfStatementAPIDTO(
          bracket.componentOptions.ifStatements,
          entities
        )
      );
    } else {
      conditions.push(
        makeSingleIfStatementConditionAPIDTO(
          bracket.componentOptions.ifStatements[0],
          entities
        )
      );
    }
  }

  if (operator === operations.OR) {
    return {
      [actionKeysApi.IS_GROUP]: true,
      [actionKeysApi.OPERATOR]: operator,
      [actionKeysApi.IS_INVERSE]: false,
      [actionKeysApi.CONDITIONS]: conditions
    };
  }

  return conditions[0];
};

const makeFirstLevelIfStatementAPIDTO = (groupConditions, entities) => {
  const firstBracketIfStatementList =
    groupConditions[0].bracket.componentOptions.ifStatements;
  const isGroup =
    firstBracketIfStatementList.length > 1 || groupConditions.length > 1;

  if (isGroup) {
    return makeGroupedIfStatementAPIDTO(groupConditions, entities);
  } else {
    return makeSingleIfStatementConditionAPIDTO(
      firstBracketIfStatementList[0],
      entities
    );
  }
};

const makeIfStatementSuccessStepsAPIDTO = (
  successSteps,
  entities,
  functionSteps
) =>
  successSteps.map(({ stepType, componentOptions }) => {
    if (stepType === actionStepType.SET) {
      return makeSetStepAPIDTO({
        functionStep: makeFlatSetStepDTO(componentOptions),
        entities,
        functionSteps
      });
    } else if (stepType === actionStepType.END) {
      return makeEndStepAPIDTO({ stepType });
    } else if (stepType === actionStepType.TRIGGER) {
      return makeTriggerStepAPIDTO(componentOptions);
    } else if (stepType === actionStepSubType.EMAIL) {
      return makeNotificationEmailStepAPIDTO({
        functionStep: componentOptions,
        entities
      });
    } else if (stepType === actionStepType.TASK) {
      return makeTaskStepAPIDTO({
        functionStep: componentOptions,
        entities
      });
    }
  });

const makeIfStatementBlockAPIDTO = (block, entities, functionSteps) => {
  const condition = makeFirstLevelIfStatementAPIDTO(block.condition, entities);
  const successSteps = makeIfStatementSuccessStepsAPIDTO(
    block.successSteps,
    entities,
    functionSteps
  );

  return {
    [actionKeysApi.CONDITION]: condition,
    [actionKeysApi.SUCCESS_STEPS]: successSteps
  };
};

const makeIfStepAPIDTO = (step, entities, functionSteps) => {
  let elseBlock;
  let elseIfBlocks;
  const ifBlock = makeIfStatementBlockAPIDTO(
    step.ifBlock[0],
    entities,
    functionSteps
  );

  if (step.elseIfBlocks) {
    elseIfBlocks = step.elseIfBlocks.map((block) =>
      makeIfStatementBlockAPIDTO(block, entities, functionSteps)
    );
  }

  if (step.elseBlock) {
    elseBlock = {
      success_steps: makeIfStatementSuccessStepsAPIDTO(
        step.elseBlock,
        entities,
        functionSteps
      )
    };
  }

  return {
    [actionKeysApi.STEP_TYPE]: actionStepType.IF,
    [actionKeysApi.COMMENT]: step[actionKeys.COMMENT] || "",
    [actionKeysApi.IF_BLOCK]: ifBlock,
    ...(elseIfBlocks && { [actionKeysApi.ELSE_IF_BLOCKS]: elseIfBlocks }),
    ...(elseBlock && { [actionKeysApi.ELSE_BLOCK]: elseBlock })
  };
};

export {
  makeIfStatementDTO,
  makeValueForIfStatementDTO,
  makeValueForIfStatementAPIDTO,
  makeConditionValueAPIDTO,
  makeLeftSideIfStatementAPIDTO,
  makeRightSideIfStatementAPIDTO,
  makeIfStatementConditionAPIDTO,
  makeSingleIfStatementConditionAPIDTO,
  makeMultipleIfStatementAPIDTO,
  makeFirstLevelIfStatementAPIDTO,
  makeIfStatementSuccessStepsAPIDTO,
  makeIfStatementBlockAPIDTO,
  makeIfStepAPIDTO,
  makeGroupedIfStatementAPIDTO,
  makeValueForIfStatementConditionsDTO
};
