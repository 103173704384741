<template>
  <div class="base-pagination" data-test-id="base-pagination">
    <button
      title="First page"
      type="button"
      :class="[
        'base-pagination__button',
        disableFirstPageLink && 'base-pagination__button--disabled'
      ]"
      :aria-disabled="disableFirstPageLink"
      :tabindex="disableFirstPageLink ? -1 : 0"
      @click="updatePageNumberEvent(1)"
    >
      <font-awesome-icon icon="angle-double-left" />
    </button>
    <button
      title="Previous page"
      type="button"
      :class="[
        'base-pagination__button',
        disablePreviousPageLink && 'base-pagination__button--disabled'
      ]"
      :aria-disabled="disablePreviousPageLink"
      :tabindex="disablePreviousPageLink ? -1 : 0"
      @click="updatePageNumberEvent(currentPage - 1)"
    >
      <font-awesome-icon icon="angle-left" />
    </button>
    <div
      v-if="!disableFirstPageLink"
      :class="[
        'base-pagination__button',
        'base-pagination__button--display-only'
      ]"
    >
      ...
    </div>
    <button
      v-for="pageNumber in getPagesToShow"
      type="button"
      :class="[
        'base-pagination__button',
        isCurrentPage(pageNumber) && `base-pagination__button--active`
      ]"
      :key="`paginated-page-number-${pageNumber}`"
      :title="`Page number ${pageNumber}`"
      @click="updatePageNumberEvent(pageNumber)"
    >
      {{ pageNumber }}
    </button>
    <div
      v-if="!disableLastPageLink"
      :class="[
        'base-pagination__button',
        'base-pagination__button--display-only'
      ]"
    >
      ...
    </div>
    <button
      type="button"
      title="Next page"
      :class="[
        'base-pagination__button',
        disableNextPageLink && 'base-pagination__button--disabled'
      ]"
      :aria-disabled="disableNextPageLink"
      :tabindex="disableNextPageLink ? -1 : 0"
      @click="updatePageNumberEvent(currentPage + 1)"
    >
      <font-awesome-icon icon="angle-right" />
    </button>
    <button
      type="button"
      title="Last page"
      :class="[
        'base-pagination__button',
        disableLastPageLink && 'base-pagination__button--disabled'
      ]"
      :aria-disabled="disableLastPageLink"
      :tabindex="disableLastPageLink ? -1 : 0"
      @click="updatePageNumberEvent(getTotalPages)"
    >
      <font-awesome-icon icon="angle-double-right" />
    </button>
  </div>
</template>

<script>
export default {
  name: "BasePagination",
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },
    totalItems: {
      type: Number,
      default: 0
    },
    pageRange: {
      type: Number,
      default: 3
    }
  },
  computed: {
    getTotalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    getPagesToShow() {
      const maxTotalPages =
        this.pageRange > this.getTotalPages
          ? this.getTotalPages
          : this.pageRange;

      let start = this.currentPage - Math.floor(maxTotalPages / 2);
      start = Math.max(start, 1);
      start = Math.min(start, 1 + this.getTotalPages - maxTotalPages);

      return Array.from({ length: maxTotalPages }, (_, i) => start + i);
    },
    disablePreviousPageLink() {
      return this.currentPage === 1;
    },
    disableNextPageLink() {
      return this.currentPage === this.getTotalPages;
    },
    disableFirstPageLink() {
      return this.getPagesToShow.includes(1);
    },
    disableLastPageLink() {
      return this.getPagesToShow.includes(this.getTotalPages);
    }
  },
  methods: {
    updatePageNumberEvent(pageNumber) {
      this.$emit("click", pageNumber);
    },
    isCurrentPage(pageNumber) {
      return pageNumber === this.currentPage;
    }
  }
};
</script>

<style lang="scss" scoped>
.base-pagination {
  border: 1px solid $black;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  width: fit-content;
  overflow: hidden;

  &:deep(.svg-inline--fa) {
    vertical-align: middle;
  }

  &__button {
    background: transparent;
    padding: 5px 5px;
    @include body-text;
    border: none;
    border-right: 1px solid $black;
    min-width: 35px;
    text-align: center;
    color: $black;
    transition: background-color 250ms ease;

    &:last-child {
      border: none;
    }

    &:hover,
    &:focus {
      background-color: $chinese-silver;
    }

    &--active {
      color: $white;
      background: $granite-gray;
    }

    &--disabled {
      color: $spanish-gray;
    }

    &--active,
    &--disabled,
    &--display-only {
      pointer-events: none;
    }
  }
}
</style>
