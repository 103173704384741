<template id="template-input">
  <div
    :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
    :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'"
  >
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :isHidden="isHidden"
      :label="label"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
    />

    <!-- input content -->
    <b-collapse
      v-show="JSON.parse(!fieldData.isHidden) || !isPreview"
      id="collapse-1"
      :class="isPreview ? 'mt-0' : 'mt-5'"
      v-model="collapse"
    >
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1' && !isPreview"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-left">
          <b-form-group
            :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
            :id="fieldData.id"
            :description="fieldData.helperText[selectedLanguage]"
            :invalid-feedback="fieldData.validationMessage[selectedLanguage]"
            :title="$t('validations.invalidEmail')"
          >
            <label>
              <span
                v-if="
                  fieldData.validation &&
                  JSON.parse(fieldData.validation.required)
                "
                class="required-span"
                >*</span
              >
              {{
                form1.enableSectionFieldEnumeration == 1
                  ? sectionIndex + 1 + "." + (fieldIndex + 1)
                  : ""
              }}
              {{ label ? label : fieldData.label[selectedLanguage] }}
            </label>

            <b-form-input
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
              :id="fieldData.id"
              :disabled="isDisabled == 1"
              type="email"
              v-model="name"
              @input="setValue"
              @blur="checkValidate"
              :state="
                isFocus &&
                fieldData.validation &&
                JSON.parse(fieldData.validation.required)
                  ? !v$.name.required || !v$.name.email
                    ? false
                    : true
                  : null
              "
              @focus="isFocus = true"
              :placeholder="
                placeholder
                  ? placeholder
                  : fieldData.placeholder[selectedLanguage]
              "
            ></b-form-input>
            <span
              v-if="msg.min"
              :class="
                $store.state.rightAlign
                  ? 'text-right text-danger'
                  : 'text-left text-danger'
              "
              >{{ msg.min }}&nbsp;</span
            >
            <span
              v-if="msg.max"
              :class="
                $store.state.rightAlign
                  ? 'text-right text-danger'
                  : 'text-left text-danger'
              "
              >{{ msg.max }}&nbsp;</span
            >
            <span
              :class="
                $store.state.rightAlign
                  ? 'text-right text-danger'
                  : 'text-left text-danger'
              "
              v-if="!v$.name.email"
              >{{ $t("validations.invalidEmail") }}&nbsp;</span
            >
          </b-form-group>
        </b-col>
      </b-row>

      <!-- show advance field options -->
      <b-modal
        cancel-title="Close"
        ok-title="save"
        @ok="onSaveData"
        size="xl"
        v-model="showSettings"
        scrollable
        title="Input Advanced Settings"
      >
        <AdvanceSettings
          :fieldData="fieldData"
          v-on:toggle-field="showSettings = !showSettings"
          :sectionIndex="sectionIndex"
          :fieldIndex="fieldIndex"
        />
      </b-modal>
    </b-collapse>
    <b-alert
      v-model="showErrorAlert"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000"
      variant="danger"
      dismissible
      solid
      :change="hideAllError()"
      >One or more Errors Found, please have a look.!</b-alert
    >
  </div>
</template>

<script>
import { required, email } from "@vuelidate/validators";
import AdvanceSettings from "../advanceSettingsCompenent";
import MenuButtons from "../menuButtons";
import { mapGetters, mapState } from "vuex";
import { EventBus } from "@/event-bus.js";
import _ from "lodash";
import { routeKeys, routeNames } from "@/constants";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "EmailGroup",
  components: {
    AdvanceSettings,
    MenuButtons
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      isHidden: false,
      condition: {},
      showToolTip: false,
      isFocus: false,
      isError: false,
      isSuccess: false,
      label: undefined,
      placeholder: undefined,
      helperText: undefined,
      tooltipText: undefined,
      showSettings: false,
      msg: [],
      isRequired: 1,
      collapse: true,
      name: this.fieldData.value || null,
      showErrorAlert: false
    };
  },
  created() {
    EventBus.on("onSubmitEndUserForm", (value) => {
      if (value == "progress") {
        this.$store.state.validFields = true;
        this.fieldData.isInValid = false;
      } else {
        if (
          this.fieldData.validation &&
          this.fieldData.validation.required == 1
        ) {
          if (!this.v$.$invalid) {
            this.$store.state.validFields = true;
            this.fieldData.isInValid = false;

            // alert("submit success");
          } else {
            this.$store.state.validFields = false;
            this.fieldData.isInValid = true;

            this.isFocus = true;
          }
        }
      }
    });

    EventBus.on("OnInvalidFieldCatch", (inValidData) => {
      document
        .getElementById(inValidData.elementId)
        .scrollIntoView({ behavior: "smooth" });
    });
  },
  mounted() {
    this.isRequired = this.fieldData.validation.required;
    if (
      this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
      this.$route.name == "MyCompanyView"
    ) {
      this.setFieldValue();
    }
    // this.isHidden = this.isPreview ? JSON.parse(this.fieldData.isHidden) : false ;
  },
  validations: {
    name: {
      required,
      email
    }
  },
  computed: {
    ...mapGetters([
      "form1",
      "selectedLanguage",
      "userForm",
      "userFormSubmitArray"
    ]),
    ...mapState(["toggleAllCoppalse"])
  },
  watch: {
    toggleAllCoppalse(value) {
      this.collapse = value;
    },
    name() {
      if (this.fieldData.validation && this.fieldData.validation.min) {
        this.checkLength(this.fieldData.validation.min, "min");
      }
      if (this.fieldData.validation && this.fieldData.validation.max) {
        this.checkLength(this.fieldData.validation.max, "max");
      }
    }
  },

  methods: {
    checkLength(length, type) {
      if (type === "min") {
        if (this.name.length < parseInt(length)) {
          this.msg.min =
            this.fieldData.minValidateMessage[this.selectedLanguage] ||
            "Not valid";
        } else {
          this.msg.min = "";
        }
      } else if (type === "max") {
        if (this.name.length > parseInt(length)) {
          this.msg.max =
            this.fieldData.maxValidateMessage[this.selectedLanguage] ||
            "Not valid";
        } else {
          this.msg.max = "";
        }
      } else {
        return true;
      }
    },

    hideAllError() {
      if (this.showErrorAlert) {
        this.showErrorAlert = false;
      }
    },

    checkValidate() {
      if (this.name?.length >= 3) {
        (this.isError = false), (this.isSuccess = true);
      } else {
        (this.isError = true), (this.isSuccess = false);
      }
    },

    setFieldValue() {
      this.$store.state.getUserFormSubmitArray.length &&
        this.$store.state.getUserFormSubmitArray.forEach((a) => {
          if (a.elementId === this.fieldData.id) {
            a.elements.forEach((el) => {
              this.name = el.postValue || null;
            });
          }
        });
    },

    setValue(e) {
      const registerData = _.clone(this.$store.state.registerData);

      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        this.$route.name == "MyCompanyView"
      ) {
        this.name = e;
        // this.fieldData.value = this.name;
        if (e && e.length > 0 && !this.v$.$invalid) {
          registerData[this.fieldData.id] = e;
        } else {
          delete registerData[this.fieldData.id];
        }
        this.$store.state.registerData = registerData;

        // main calculations :p
        const numkeys = Object.keys(registerData).length;
        // const sectionLength = this.form1.formSection.length > 1 ? this.form1.formSection[this.sectionIndex].formElements.length : 1
        this.$store.state.progressValue = numkeys;

        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId === this.fieldData.id) {
            a.elements[0] = {
              postValue: e,
              postName:
                (this.fieldData.attributes &&
                  this.fieldData.attributes.postName) ||
                "postNameEmail"
            };
          }
        });
      }
    },
    onSaveData() {
      EventBus.emit("onSaveSettings", "saving settings");
    }
  },
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
<style lang="scss">
.required-span {
  font-size: 24px;
  margin-left: 3px;
  color: $spanish-red;
}
</style>
