<template>
  <div class="search-filter-bar" data-test-id="search-filter-bar">
    <TransitionExpand>
      <FilterSection
        v-if="showFilterSection"
        :filter-options="filterOptions"
        class="search-filter-bar__filter-section"
        @click="
          $emit(DOMEvents.CLICK, { id: genericEvents.FILTER, event: $event })
        "
        @change="
          $emit(DOMEvents.CHANGE, { id: genericEvents.FILTER, event: $event })
        "
      />
    </TransitionExpand>
    <div class="search-filter-bar__bar" data-test-id="search-filter-bar__bar">
      <div
        class="search-filter-bar__action-buttons"
        data-test-id="search-filter-bar__action-buttons"
      >
        <CallToAction
          v-for="buttonOptions in actionButtons"
          :key="buttonOptions.id"
          v-bind="buttonOptions"
          class="search-filter-bar__action-button"
          data-test-id="search-filter-bar__action-button"
          @click="
            $emit(DOMEvents.CLICK, { id: buttonOptions.id, event: $event })
          "
        />
      </div>
      <div class="search-filter-bar__search-and-filter">
        <SearchBar
          v-if="hasSearchBar"
          v-bind="searchBarOptions"
          class="search-filter-bar__search-and-filter-search-input"
          @click="
            $emit(DOMEvents.CLICK, { id: genericEvents.SEARCH, event: $event })
          "
          @input="
            $emit(DOMEvents.INPUT, { id: genericEvents.SEARCH, event: $event })
          "
        />
        <CallToAction
          v-if="hasFilters"
          :icon="icons.FILTER"
          value="Filter"
          :icon-size="14"
          :icon-space="space.NONE"
          has-icon-with-text
          :icon-with-text-direction="direction.HORIZONTAL"
          @click="
            $emit(DOMEvents.CLICK, { id: genericEvents.TOGGLE, event: $event })
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import CallToAction from "@/atoms/CallToAction/CallToAction";
import { isValidActionButton } from "./SearchFilterBar.logic";
import { direction, DOMEvents, genericEvents, icons, space } from "@/constants";
import SearchBar from "@/molecules/SearchBar/SearchBar";
import FilterSection from "@/organisms/FilterSection/FilterSection";
import TransitionExpand from "@/atoms/TransitionExpand/TransitionExpand";

export default {
  name: "SearchFilterBar",
  components: { TransitionExpand, CallToAction, FilterSection, SearchBar },
  props: {
    actionButtons: {
      type: Array,
      default: () => [],
      validator: (prop) => isValidActionButton(prop)
    },
    hasSearchBar: {
      type: Boolean,
      default: false
    },
    searchBarOptions: {
      type: Object,
      default: () => ({})
    },
    hasFilters: {
      type: Boolean,
      default: false
    },
    showFilterSection: {
      type: Boolean,
      default: false
    },
    filterOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      direction,
      genericEvents,
      DOMEvents,
      space,
      icons
    };
  }
};
</script>

<style lang="scss" scoped>
.search-filter-bar {
  &__bar {
    display: flex;
    background: $grey;
    justify-content: space-between;
    padding: $spacing8 $spacing16;
  }

  &__filter-section {
    transition: height 0.5s ease;
  }

  &__action-buttons {
    display: flex;
    width: 50%;
    gap: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__search-and-filter {
    display: flex;
    gap: 10px;
    width: 50%;
    justify-content: flex-end;

    &-search-input {
      max-width: 250px;
    }
  }
}
</style>
