import { makeAuthorizationHeader, HTTP } from "@/utils";
import { endpoints } from "@/constants";
import {
  makeTaskTemplatesDTO,
  makeTaskTemplateDTO,
  makeTaskTemplateAPIDTO
} from "./dto/taskTemplates.dto";

const fetchTaskTemplates = async ({ companyId = "", params = null } = {}) => {
  const response = await HTTP(
    "get",
    endpoints.TASK_TEMPLATES(companyId),
    null,
    makeAuthorizationHeader(),
    params
  );
  response.data.data = makeTaskTemplatesDTO({
    taskTemplates: response.data.data
  });
  return response;
};

const fetchTaskTemplate = async ({ companyId, id }) => {
  const response = await HTTP(
    "get",
    endpoints.TASK_TEMPLATE({ companyId, id }),
    null,
    makeAuthorizationHeader()
  );

  response.data = makeTaskTemplateDTO({
    taskTemplate: response.data
  });
  return response;
};

const makeTaskTemplate = async ({
  companyId,
  taskTemplate,
  storeExpectedData
}) => {
  const response = await HTTP(
    "post",
    endpoints.TASK_TEMPLATES(companyId),
    makeTaskTemplateAPIDTO({ taskTemplate, storeExpectedData }),
    makeAuthorizationHeader()
  );
  response.data = makeTaskTemplateDTO({
    taskTemplate: response.data
  });
  return response;
};

const updateTaskTemplate = async ({
  companyId,
  taskTemplate,
  storeExpectedData
}) => {
  const response = await HTTP(
    "patch",
    endpoints.TASK_TEMPLATE({ companyId, id: taskTemplate.id }),
    makeTaskTemplateAPIDTO({ taskTemplate, storeExpectedData }),
    makeAuthorizationHeader()
  );
  response.data = makeTaskTemplateDTO({
    taskTemplate: response.data
  });
  return response;
};

const cloneTaskTemplate = async ({ companyId, id }) => {
  const response = await HTTP(
    "post",
    endpoints.CLONE_TASK_TEMPLATE({ companyId, id }),
    null,
    makeAuthorizationHeader()
  );
  response.data = makeTaskTemplateDTO({
    taskTemplate: response.data
  });
  return response;
};

const deleteTaskTemplate = async ({ companyId, id }) => {
  const response = await HTTP(
    "delete",
    endpoints.TASK_TEMPLATE({ companyId, id }),
    null,
    makeAuthorizationHeader()
  );
  return response;
};

export const taskTemplatesService = {
  fetchTaskTemplates,
  fetchTaskTemplate,
  makeTaskTemplate,
  updateTaskTemplate,
  cloneTaskTemplate,
  deleteTaskTemplate
};
