<template>
  <div>
    <b-card no-body class="mt-3" header="Form Details">
      <b-tabs pills card vertical nav-wrapper-class="over-flow-tabs">
        <!-- Render Tabs, supply a unique `key` to each tab -->
        <b-tab
          @click="tabClick(language)"
          v-for="(language, index) in form.formTranslations"
          :key="index"
          :title="language"
        >
          <!-- translation form wrappe -->
          <div class="col-md-6 offset-3">
            <b-form-group label="Form Title">
              <b-form-input
                v-model="formTitle"
                type="text"
                @input="setFormTitle"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Form Description">
              <b-form-textarea
                v-model="formDescription"
                rows="3"
                max-rows="6"
                @input="setFormDescription"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>

    <b-navbar
      toggleable="lg"
      type="dark"
      variant="dark"
      class="m-0"
      fixed="bottom"
    >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <b-button
              size="sm"
              variant="light"
              class="my-2 my-sm-0 mr-2"
              type="button"
              to="/form/configuration"
              >Back</b-button
            >
            <b-button
              size="sm"
              variant="light"
              class="my-2 my-sm-0"
              type="button"
              to="/form/intro"
              >Next</b-button
            >
          </b-nav-form>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "FormDetailsContainer",
  data() {
    return {
      isLoading: false,
      placeholderVersion: "e.g. 1.0.2",
      formTitle: undefined,
      formDescription: undefined,
      translationLanguage: "english"
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      form: "form1",
      selectedLanguage: "selectedLanguage"
    })
  },
  mounted() {
    this.tabClick("English");
  },
  async beforeDestroy() {
    await EventBus.emit("onAutoSaveJsonForm", true);
  },
  methods: {
    tabClick(key) {
      let tempKey = key.toLowerCase();
      this.translationLanguage = tempKey;
      this.formTitle = this.form.formTitle[this.translationLanguage];
      this.formDescription =
        this.form.formDescription[this.translationLanguage];
    },

    makeToast(variant = null, message) {
      this.$bvToast.toast(message, {
        // title: `Clone ${variant || "default"}`,
        title: "Alert",
        variant,
        solid: true
      });
    },
    setFormTitle(value) {
      this.form.formTitle[this.translationLanguage] = value;
    },
    setFormDescription(value) {
      this.form.formDescription[this.translationLanguage] = value;
    }
  }
};
</script>
<style>
.over-flow-tabs {
  display: grid;
  overflow-y: scroll;
  /* max-height: 500px !important */
  max-height: calc(100vh - 306px);
}
</style>
