<template>
  <div class="textarea">
    <template v-if="label">
      <Label
        :id="id"
        :label="label"
        :isRequired="isRequired"
        :class="[{ 'textarea__label--hidden': isLabelHidden }]"
      >
        <template v-for="(_, slot) in $slots">
          <template :slot="slot">
            <slot :name="slot" />
          </template>
        </template>
      </Label>
    </template>
    <div class="textarea__container">
      <textarea
        v-bind="$attrs"
        class="textarea__input"
        data-test-id="textarea"
        :aria-label="label"
      />
      <InputErrorMessage :error="error" />
    </div>
  </div>
</template>

<script>
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import Label from "@/atoms/Label/Label";

export default {
  name: "BaseTextArea",
  props: {
    id: {
      required: true,
      type: String
    },
    label: {
      default: "",
      type: String
    },
    error: {
      default: "",
      type: [String, Array]
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    isLabelHidden: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Label,
    InputErrorMessage
  }
};
</script>

<style lang="scss" scoped>
.textarea {
  &__input {
    width: 100%;
    border-radius: 5px;
    border-color: $chinese-silver;
    padding: 6px 12px;
  }

  & .label {
    width: 100%;
  }

  &__label--hidden {
    @include screen-reader-only;
  }
}
</style>
