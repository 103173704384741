<template>
  <b-container fluid>
    <b-row>
      <b-col cols="9" class="mt-1 text-left">
        <b-button to="/form/builder" variant="outline-primary">
        <font-awesome-icon icon="angle-left" />&nbsp;Builder
      </b-button>


    </b-col>
    <b-col class="mt-1 text-right" cols="3">
      <v-select v-if="form.formType == 1"
            :searchable="false"
            :clearable="false"
            v-model="selected"
            :options="form.formTranslations"
            @update:modelValue="changeLanguage"
          ></v-select>
    </b-col>

    </b-row>
    <b-row>
      <b-col cols="12">
        <form class="text-left">
          <FormPreview />
        </form>
      </b-col>

    </b-row>



  </b-container>
</template>

<script>

import FormPreview from "../containers/FormPreviewContainer";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "PreviewPage",
  components: {
    FormPreview
  },
  data() {
    return {
      selected:'english',
    };
  },
  computed: {
    ...mapGetters({
      form: "form1",
      isLoading: "isLoading"
    })
  },
  methods: {
     ...mapActions(["setLanguage"]),
    ...mapMutations(["TOGGLE_LOADER"]),
    changeLanguage() {
      let tt = this.selected.toLowerCase();
      this.setLanguage(tt);
      this.TOGGLE_LOADER(100);
    },
    onBack() {
      if (this.$route.params && this.$route.params.from) {
        this.$router.push(this.$route.params.from)
      } else {
        this.$router.go(-1);
      }
    }
  }
};
</script>

<style>
</style>