const expectedDataMappingKeyDTO = {
  ID: "id",
  TRIGGER_DATA: "triggeringData",
  DATA: "data",
  VALUE: "value",
  TYPE: "type",
  TRIGGERED_EXPECTED_DATA_VARIABLE_NAME: "triggeredExpectedDataVariableName",
  NAME: "name",
  SUB_TYPE: "subType",
  IS_ENUM: "isEnum",
  IS_ARRAY: "isArray",
  IS_READ_ONLY: "isReadOnly",
  IS_CUSTOM_ATTRIBUTE: "isCustomAttribute",
  IDENTIFIER: "identifier",
  ENTITY: "entity",
  ENTITY_NAME: "entityName",
  CONTEXT: "context",
  FORM_ID: "formId",
  PROXY_NAME: "proxyName",
  ATTRIBUTE_NAME: "attributeName"
};

const expectedDataMappingKeyAPIDTO = {
  ID: "id",
  TRIGGER_DATA: "triggering_data",
  DATA: "data",
  VALUE: "value",
  TYPE: "type",
  SUB_TYPE: "sub_type",
  TRIGGERED_EXPECTED_DATA_VARIABLE_NAME:
    "triggered_expected_data_variable_name",
  NAME: "name",
  IS_ENUM: "is_enum",
  IS_ARRAY: "is_array",
  IS_READ_ONLY: "is_read_only",
  IS_CUSTOM_ATTRIBUTE: "is_custom_attribute",
  IDENTIFIER: "identifier",
  ENTITY: "entity",
  ENTITY_NAME: "entity_name",
  CONTEXT: "context",
  FORM_ID: "form_id",
  PROXY_NAME: "proxy_name",
  ATTRIBUTE_NAME: "attribute_name"
};

export { expectedDataMappingKeyDTO, expectedDataMappingKeyAPIDTO };
