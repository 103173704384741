import { paginationKeysDTO, paginationKeysAPIDTO } from "@/constants";

const makePaginationAPIDTO = (pagination = {}) => ({
  [paginationKeysAPIDTO.ITEMS_PER_PAGE]:
    pagination?.[paginationKeysDTO.ITEMS_PER_PAGE],
  [paginationKeysAPIDTO.PAGE]: pagination?.[paginationKeysDTO.CURRENT_PAGE],
  [paginationKeysAPIDTO.TOTAL_ITEMS]:
    pagination?.[paginationKeysDTO.TOTAL_ITEMS]
});

const makePaginationDTO = (pagination = {}) => ({
  [paginationKeysDTO.ITEMS_PER_PAGE]:
    pagination?.[paginationKeysAPIDTO.ITEMS_PER_PAGE],
  [paginationKeysDTO.CURRENT_PAGE]:
    pagination?.[paginationKeysAPIDTO.CURRENT_PAGE],
  [paginationKeysDTO.TOTAL_ITEMS]:
    pagination?.[paginationKeysAPIDTO.TOTAL_ITEMS]
});

export { makePaginationAPIDTO, makePaginationDTO };
