<template>
  <div class="tooltip-wrapper" :style="{ maxWidth: `${maximumWidth}px` }">
    <BaseText
      class="tooltip-wrapper__text"
      data-test-id="tooltip-text"
      :text="baseText"
      :theme="themes.INVERSE"
      :size="typographySize.BODY_TEXT_SMALL"
    />
  </div>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import { themes, typographySize } from "@/constants";

export default {
  name: "TooltipTextBox",
  components: {
    BaseText
  },
  data() {
    return {
      themes,
      typographySize
    };
  },
  props: {
    baseText: {
      type: String,
      required: true
    },
    maximumWidth: {
      type: Number,
      default: 250
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip-wrapper {
  background-color: $black-olive;
  border-radius: 5px;
  width: max-content;
  z-index: 9999;
  padding: 5px;
  line-height: 0.5em;
  box-shadow: $box-shadow;

  :deep(.tooltip-wrapper__text .base-text) {
    margin-bottom: 0;
  }
}
</style>
