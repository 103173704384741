import { makeAuthorizationHeader, HTTP } from "@/utils";
import { endpoints } from "@/constants";
import {
  makeOnboardingTasksDTO,
  makeOnboardingTaskDTO
} from "./onboardingTasks.dto";

const fetchOnboardingTasks = async (userId) => {
  const response = await HTTP(
    "post",
    endpoints.ONBOARDING_TASKS,
    { userId },
    makeAuthorizationHeader()
  );
  response.data = makeOnboardingTasksDTO({
    onboardingTasks: response.data.questionnaires
  });
  return response;
};

const fetchOnboardingTask = async ({ taskId, taskType, userId }) => {
  const response = await HTTP(
    "post",
    endpoints.ONBOARDING_TASK(taskId),
    { userId, formType: taskType },
    makeAuthorizationHeader()
  );
  response.data = makeOnboardingTaskDTO({
    onboardingTask: response.data.task
  });
  return response;
};

export const onboardingTasksService = {
  fetchOnboardingTasks,
  fetchOnboardingTask
};
