const gradeRating = {
  AAA: "AAA",
  AA: "AA",
  A: "A",
  BBB: "BBB",
  BB: "BB",
  B: "B",
  C: "C"
};

const strengthScale = {
  NONE: "None",
  WEAK: "Weak",
  MODERATE: "Moderate",
  STRONG: "Strong"
};

const riskScale = {
  NONE: "None",
  NO_MATCH: "No match",
  MODERATE: "Moderate",
  SERIOUS: "Serious",
  CRITICAL: "Critical"
};

const riskAlertText = {
  UNDEFINED: "Undefined",
  NOT_AVAILABLE: "Not available",
  NOT_RUN: "Not run",
  IN_PROGRESS: "In progress",
  NO_RISK_ALERT: "No risk alert",
  RISK_ALERT: "Risk alert",
  NO_RISK_ALERT_REMEDIATED: "No risk alert (Remediated)",
  RISK_ALERT_REMEDIATED: "Risk alert (Remediated)",
  NO_RISK_ALERT_REMEDIATED_LIST: ["No risk alert", "(Remediated)"],
  RISK_ALERT_REMEDIATED_LIST: ["Risk alert", "(Remediated)"],
  MODERATE: "Moderate",
  DO_NOT_ENGAGE: "Do not engage",
  LOW: "Low",
  HIGH: "High"
};

const incomingRiskStatus = {
  NOT_REQUESTED: "not_requested",
  IN_PROGRESS: "in_progress",
  GREEN: "green",
  RED: "red",
  RED_REMEDIATED: "red_remediated",
  GREEN_REMEDIATED: "green_remediated"
};

const riskStatus = {
  NOT_AVAILABLE: "notAvailable",
  NOT_RUN: "notRun",
  IN_PROGRESS: "inProgress",
  NO_RISK_ALERT: "noRiskAlert",
  RISK_ALERT: "riskAlert",
  NO_RISK_ALERT_REMEDIATED: "noRiskAlertRemediated",
  RISK_ALERT_REMEDIATED: "riskAlertRemediated"
};

const customAttributeRiskScalesKeys = {
  ETHIXBASE_RISK_SCALE: "ETHIXBASE_RISK_SCALE",
  FOUR_POINT_SCALE: "FOUR_POINT_SCALE",
  ELEVEN_POINT_SCALE: "ELEVEN_POINT_SCALE",
  TEN_POINT_SCALE: "TEN_POINT_SCALE"
};

const customAttributeRiskScales = {
  [customAttributeRiskScalesKeys.ETHIXBASE_RISK_SCALE]: "ethiXbase Risk Scale",
  [customAttributeRiskScalesKeys.FOUR_POINT_SCALE]: "4 Point scale",
  [customAttributeRiskScalesKeys.ELEVEN_POINT_SCALE]: "11 point numeric scale",
  [customAttributeRiskScalesKeys.TEN_POINT_SCALE]: "10 point numeric scale"
};

const customAttributeRiskScalesTextMap = {
  [customAttributeRiskScalesKeys.ETHIXBASE_RISK_SCALE]: "EthiXbase risk Scale",
  [customAttributeRiskScalesKeys.FOUR_POINT_SCALE]: "4 point scale",
  [customAttributeRiskScalesKeys.ELEVEN_POINT_SCALE]: "11 point numeric scale",
  [customAttributeRiskScalesKeys.TEN_POINT_SCALE]: "10 point numeric scale"
};

const riskStatusAsValues = {
  [riskStatus.NO_RISK_ALERT]: ["0", "5"],
  [riskStatus.RISK_ALERT]: ["1", "6"]
};

const riskStatusMap = {
  [incomingRiskStatus.NOT_REQUESTED]: riskStatus.NOT_RUN,
  [incomingRiskStatus.IN_PROGRESS]: riskStatus.IN_PROGRESS,
  [incomingRiskStatus.GREEN]: riskStatus.NO_RISK_ALERT,
  [incomingRiskStatus.RED]: riskStatus.RISK_ALERT,
  [incomingRiskStatus.GREEN_REMEDIATED]: riskStatus.NO_RISK_ALERT_REMEDIATED,
  [incomingRiskStatus.RED_REMEDIATED]: riskStatus.RISK_ALERT_REMEDIATED
};

export {
  riskScale,
  strengthScale,
  gradeRating,
  riskStatus,
  riskStatusMap,
  customAttributeRiskScales,
  customAttributeRiskScalesTextMap,
  incomingRiskStatus,
  riskStatusAsValues,
  riskAlertText
};
