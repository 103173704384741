import { makeUUID } from "@/utils";
import { SetToBlockClass } from "@/molecules/SetToBlock/SetToBlock.class";

export class ExpectedDataMappingClass extends SetToBlockClass {
  id = makeUUID();

  expectedDataName = "";

  expectedDataType = "";

  expectedDataSubType = "";

  isExpectedDataArray = false;

  constructor({
    valueDataTypeText = "",
    valueDataTypeOptions = [],
    valueDataTypeErrorMessage = "",
    valueDataType = "",
    valueTypeOptions = [],
    valueTypeErrorMessage = "",
    isReadOnly = true,
    isMiddleSelectHidden = false,
    component = {},
    componentOptions = {},
    componentValue = "",
    isHidden = false,
    expectedDataName = "",
    expectedDataType = "",
    expectedDataSubType = "",
    isExpectedDataArray = false
  }) {
    super({
      valueDataTypeOptions,
      valueDataType,
      isReadOnly,
      isMiddleSelectHidden,
      valueTypeOptions,
      component,
      componentOptions,
      valueDataTypeText,
      componentValue,
      isHidden,
      valueDataTypeErrorMessage,
      valueTypeErrorMessage
    });
    this.setExpectedDataName(expectedDataName);
    this.setExpectedDataType(expectedDataType);
    this.setExpectedDataSubType(expectedDataSubType);
    this.setIsExpectedDataArray(isExpectedDataArray);
  }

  setExpectedDataName(expectedDataName) {
    this.expectedDataName = expectedDataName;
  }

  setExpectedDataType(expectedDataType) {
    this.expectedDataType = expectedDataType;
  }

  setExpectedDataSubType(expectedDataSubType) {
    this.expectedDataSubType = expectedDataSubType;
  }

  setIsExpectedDataArray(isExpectedDataArray) {
    this.isExpectedDataArray = isExpectedDataArray;
  }
}
