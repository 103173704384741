import { isRequired, hasCorrectEmailFormat } from "@/utils";
import { customErrorMessages, errorMessages } from "@/constants";

const validateName = (name) => {
  if (!isRequired(name)) {
    return customErrorMessages.generic.required;
  }
  return "";
};

const validateEmail = (email) => {
  if (!isRequired(email)) {
    return customErrorMessages.generic.required;
  } else if (!hasCorrectEmailFormat(email)) {
    return errorMessages.forgotPasswordEmail.isValidEmail;
  }
  return "";
};

const validateLanguage = (language) => {
  if (!isRequired(language)) {
    return customErrorMessages.generic.required;
  }
  return "";
};

export { validateName, validateEmail, validateLanguage };
