const tasksKeysDTO = {
  ID: "id",
  COMPANY_ID: "companyId",
  COMPANY_TASK_ID: "companyTaskId",
  TEMPLATE_ID: "templateId",
  SUBJECT: "subject",
  DESCRIPTION: "description",
  EXPECTED_DATA_DEFINITION: "expectedDataDefinition",
  STATUS: "status",
  ASSIGNEE: "assignee",
  EDIT_PERMISSIONS: "editPermissions",
  REOPEN_PERMISSIONS: "reopenPermissions",
  SELECTED_OUTCOME_ID: "selectedOutcomeId",
  OUTCOMES_DATA: "outcomesData",
  OUTCOME_ACTIONS: "outcomeActions",
  PROVIDED_DATA: "providedData",
  ACTION_ID: "actionId",
  EXPECTED_DATA_MAPPING: "expectedDataMapping",
  OUTCOME_PERMISSIONS: "outcomePermissions",
  OUTCOME: "outcome",
  ACTION: "action",
  UPDATED_AT: "updatedAt",
  CREATED_AT: "createdAt"
};

const tasksKeysAPIDTO = {
  ID: "id",
  COMPANY_ID: "company_id",
  COMPANY_TASK_ID: "company_task_id",
  TEMPLATE_ID: "template_id",
  SUBJECT: "subject",
  DESCRIPTION: "description",
  EXPECTED_DATA_DEFINITION: "expected_data_definition",
  STATUS: "status",
  ASSIGNEE: "assignee",
  EDIT_PERMISSIONS: "edit_permissions",
  REOPEN_PERMISSIONS: "reopen_permissions",
  SELECTED_OUTCOME_ID: "selected_outcome_id",
  OUTCOMES_DATA: "outcomes_data",
  OUTCOME_ACTIONS: "outcome_actions",
  PROVIDED_DATA: "provided_data",
  ACTION_ID: "action_id",
  EXPECTED_DATA_MAPPING: "expected_data_mapping",
  OUTCOME_PERMISSIONS: "outcome_permissions",
  OUTCOME: "outcome",
  TRIGGERED_EXPECTED_DATA_VARIABLE_NAME:
    "triggered_expected_data_variable_name",
  ACTION: "action",
  UPDATED_AT: "updated_at",
  CREATED_AT: "created_at",
  DATA: "data",
  TYPE: "type",
  VALUE: "value",
  TRIGGERING_DATA: "triggering_data"
};

const tasksFilterKeysAPIDTO = {
  ASSIGNEE: "assignee",
  EDIT_PERMISSIONS: "edit_permissions",
  TEMPLATE_ID: "template_id",
  COMPANY_TASK_ID: "company_task_id",
  STATUS: "status",
  SUBJECT: "subject",
  DESCRIPTION: "description",
  SUBJECT_OR_DESCRIPTION: "subject_or_description",
  CREATED_DATE: "created_date",
  CREATED_DATE_AFTER: "created_date_after",
  CREATED_DATE_BEFORE: "created_date_before",
  UPDATED_DATE: "updated_date",
  UPDATED_DATE_AFTER: "updated_date_after",
  UPDATED_DATE_BEFORE: "updated_date_before"
};

const tasksFilterKeysDTO = {
  ASSIGNEE: "assignee",
  EDIT_PERMISSIONS: "editPermissions",
  TEMPLATE_ID: "templateId",
  COMPANY_TASK_ID: "companyTaskId",
  STATUS: "status",
  SUBJECT: "subject",
  DESCRIPTION: "description",
  SUBJECT_OR_DESCRIPTION: "subjectOrDescription",
  CREATED_DATE: "createdDate",
  CREATED_DATE_AFTER: "createdDateAfter",
  CREATED_DATE_BEFORE: "createdDateBefore",
  UPDATED_DATE: "updatedDate",
  UPDATED_DATE_AFTER: "updatedDateAfter",
  UPDATED_DATE_BEFORE: "updatedDateBefore"
};

export {
  tasksKeysDTO,
  tasksKeysAPIDTO,
  tasksFilterKeysAPIDTO,
  tasksFilterKeysDTO
};
