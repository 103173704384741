<template>
  <div class="input">
    <template v-if="label">
      <Label
        :id="id"
        :label="label"
        :isRequired="isRequired"
        :class="[{ 'input__label--hidden': isLabelHidden }]"
      >
        <template v-for="(_, slot) in $slots">
          <template :slot="slot">
            <slot :name="slot" />
          </template>
        </template>
      </Label>
    </template>
    <div class="input__input-container">
      <b-form-input
        :id="id"
        data-test-id="input"
        ref="input"
        :no-wheel="true"
        :value="value"
        :placeholder="placeholder"
        :state="validationState"
        :type="type"
        :step="step"
        @change="onChangeHandler"
        :disabled="isDisabled"
      />
      <InputErrorMessage :error="error" />
    </div>
  </div>
</template>

<script>
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import Label from "@/atoms/Label/Label";

export default {
  name: "Input",
  props: {
    id: {
      required: true,
      type: String
    },
    label: {
      default: "",
      type: String
    },
    name: {
      type: String,
      default: null
    },
    error: {
      default: "",
      type: [String, Array]
    },
    validationState: {
      type: [Boolean, Object],
      default: null
    },
    value: {
      type: [String, Number],
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: "text"
    },
    isLabelHidden: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      default: 1
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Label,
    InputErrorMessage
  },
  mounted() {
    if (this.focus) {
      this.focusInput();
    }
  },
  watch: {
    focus(newValue) {
      if (newValue) {
        this.focusInput();
      }
    }
  },
  methods: {
    onChangeHandler(value) {
      this.$emit("change", value);
    },
    focusInput() {
      this.$refs.input.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.input {
  padding: 0 0 $spacing20 0;

  &__input-container {
    width: 100%;
  }

  & .label {
    width: 100%;
  }

  &__label--hidden {
    @include screen-reader-only;
  }
}
</style>
