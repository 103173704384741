import {
  border,
  iconSizes,
  themes,
  typographySize
} from "@/constants/design.constant";
import { icons, shapes } from "@/constants/shapes.constant";
import { riskAlertText } from "@/constants/ratings.constant";

const defaultIcons = {
  SQUARE_DASHED: {
    shape: shapes.SQUARE,
    theme: themes.NONE,
    border: border.DASHED,
    size: iconSizes.DEFAULT
  },
  DO_NOT_ENGAGE: {
    icon: icons.EXCLAMATION,
    shape: shapes.OCTAGON,
    theme: themes.INVERSE,
    size: iconSizes.DEFAULT
  },
  CHECK: {
    icon: icons.CHECK,
    shape: shapes.CIRCLE,
    theme: themes.SUCCESS,
    size: iconSizes.DEFAULT
  },
  HIGH_ALERT: {
    icon: icons.EXCLAMATION,
    shape: shapes.TRIANGLE,
    theme: themes.ERROR,
    size: iconSizes.DEFAULT
  },
  REMEDIATED_OK: {
    icon: icons.CHECK,
    shape: shapes.CIRCLE,
    theme: themes.SUCCESS,
    overlayText: "R",
    size: iconSizes.DEFAULT
  },
  REMEDIATED_HIGH_ALERT: {
    icon: icons.EXCLAMATION,
    shape: shapes.TRIANGLE,
    theme: themes.ERROR,
    overlayText: "R",
    size: iconSizes.DEFAULT
  },
  IN_PROGRESS: {
    icon: icons.HISTORY,
    shape: shapes.SQUARE,
    theme: themes.NONE,
    size: iconSizes.DEFAULT
  },
  WARNING_ALERT: {
    icon: icons.EXCLAMATION,
    shape: shapes.CIRCLE,
    theme: themes.WARNING,
    size: iconSizes.DEFAULT
  },
  NOT_RUN: {
    icon: icons.MINUS,
    shape: shapes.SQUARE,
    border: border.SOLID,
    theme: themes.LIGHT_GREY_INVERSE,
    size: iconSizes.DEFAULT
  },
  MODERATE: {
    icon: icons.EXCLAMATION,
    shape: shapes.CIRCLE,
    theme: themes.WARNING,
    size: iconSizes.DEFAULT
  },
  HIGH: {
    icon: icons.EXCLAMATION,
    shape: shapes.TRIANGLE,
    theme: themes.ERROR,
    size: iconSizes.DEFAULT
  },
  EDIT: {
    icon: icons.EDIT,
    shape: shapes.NONE,
    theme: themes.ETHIXBASE_PRIMARY,
    size: iconSizes.DEFAULT
  },
  INFO: {
    icon: icons.INFO,
    shape: shapes.CIRCLE,
    theme: themes.INFO,
    size: iconSizes.DEFAULT
  },
  DEFAULT: {
    icon: icons.DEFAULT,
    shape: shapes.NONE,
    theme: themes.NONE,
    size: iconSizes.DEFAULT
  }
};

const riskIcons = {
  UNDEFINED: {
    iconOptions: defaultIcons.SQUARE_DASHED,
    textOptions: {
      tag: "p",
      text: riskAlertText.UNDEFINED,
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  NOT_AVAILABLE: {
    iconOptions: defaultIcons.SQUARE_DASHED,
    textOptions: {
      tag: "p",
      text: riskAlertText.NOT_AVAILABLE,
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  NOT_RUN: {
    iconOptions: defaultIcons.NOT_RUN,
    textOptions: {
      tag: "p",
      text: riskAlertText.NOT_RUN,
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  IN_PROGRESS: {
    iconOptions: defaultIcons.IN_PROGRESS,
    textOptions: {
      tag: "p",
      text: riskAlertText.IN_PROGRESS,
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  NO_RISK_ALERT: {
    iconOptions: defaultIcons.CHECK,
    textOptions: {
      tag: "p",
      text: riskAlertText.NO_RISK_ALERT,
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  MODERATE_RISK_ALERT: {
    iconOptions: defaultIcons.WARNING_ALERT,
    textOptions: {
      tag: "p",
      text: riskAlertText.MODERATE,
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  RISK_ALERT: {
    iconOptions: defaultIcons.HIGH_ALERT,
    textOptions: {
      tag: "p",
      text: riskAlertText.RISK_ALERT,
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  NO_RISK_ALERT_REMEDIATED: {
    iconOptions: defaultIcons.REMEDIATED_OK,
    textOptions: {
      tag: "p",
      text: riskAlertText.NO_RISK_ALERT_REMEDIATED_LIST,
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  RISK_ALERT_REMEDIATED: {
    iconOptions: defaultIcons.REMEDIATED_HIGH_ALERT,
    textOptions: {
      tag: "p",
      text: riskAlertText.RISK_ALERT_REMEDIATED_LIST,
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  DO_NOT_ENGAGE: {
    iconOptions: defaultIcons.DO_NOT_ENGAGE,
    textOptions: {
      tag: "p",
      text: riskAlertText.DO_NOT_ENGAGE,
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  LOW: {
    iconOptions: defaultIcons.CHECK,
    textOptions: {
      tag: "p",
      text: riskAlertText.LOW,
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  MODERATE: {
    iconOptions: defaultIcons.MODERATE,
    textOptions: {
      tag: "p",
      text: riskAlertText.MODERATE,
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  HIGH: {
    iconOptions: defaultIcons.HIGH,
    textOptions: {
      tag: "p",
      text: riskAlertText.HIGH,
      size: typographySize.BODY_TEXT_SMALL
    }
  }
};

const pillIcons = {
  VERIFIED_PILL: {
    text: "verified data",
    theme: themes.SUCCESS_PILL_INVERSE,
    iconOptions: {
      ...defaultIcons.CHECK,
      theme: themes.SUCCESS_PILL,
      size: 7
    }
  }
};

export { defaultIcons, riskIcons, pillIcons };
