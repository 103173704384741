<template id="template-radio">
  <div
    :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
    :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'"
  >
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :isHidden="isHidden"
      :label="label"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <b-collapse
      v-show="JSON.parse(!fieldData.isHidden) || !isPreview"
      :class="isPreview ? 'mt-2' : 'mt-5'"
      id="collapse-1"
      v-model="collapse"
    >
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1' && !isPreview"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-left">
          <b-form-group
            :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
            :id="fieldData.id"
            :description="fieldData.helperText[selectedLanguage]"
            :invalid-feedback="fieldData.validationMessage[selectedLanguage]"
          >
            <label>
              <span
                v-if="
                  fieldData.validation && fieldData.validation.required == 1
                "
                class="required-span"
                >*</span
              >
              {{
                form1.enableSectionFieldEnumeration == 1
                  ? sectionIndex + 1 + "." + (fieldIndex + 1)
                  : ""
              }}
              {{ label ? label : fieldData.label[selectedLanguage] }}
            </label>
            <div class="clearfix"></div>
            <b-form-radio-group
              :stacked="fieldData.configuration.isStacked"
              :disabled="isDisabled == 1"
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
              v-model="selected"
              :options="radioOptions"
              @change="checkCondition"
            ></b-form-radio-group>
            <label
              v-if="
                fieldData.validation &&
                fieldData.validation.required == 1 &&
                !selected &&
                isFocus
              "
              class="text-danger"
              >{{ fieldData.validationMessage[selectedLanguage] }}</label
            >
          </b-form-group>

          <!-- conditional input field ------------------------------->
          <b-collapse v-model="showCollapseField" class="mt-2">
            <div
              v-for="(input, index) in fieldData.progressiveOptions"
              :key="index"
            >
              <b-form>
                <b-form-group
                  v-if="input.type == 'radio'"
                  :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                  id="fieldset-1"
                >
                  <label>
                    <span
                      v-if="fieldData.validation && input.isRequired"
                      class="required-span"
                      >*</span
                    >
                    {{
                      form1.enableSectionFieldEnumeration == 1
                        ? sectionIndex + 1 + "." + (fieldIndex + 1)
                        : ""
                    }}
                    {{ label ? label : input.label[selectedLanguage] }}
                  </label>
                  <div class="clearfix"></div>
                  <b-form-radio-group
                    stacked
                    :disabled="isDisabled == 1"
                    :class="
                      $store.state.rightAlign ? 'text-right' : 'text-left'
                    "
                    v-model="input.fieldRadioValue"
                    :options="input.options"
                    text-field="value"
                    @change="checkProgressiveCondition($event, index)"
                  ></b-form-radio-group>
                  <label
                    v-if="
                      fieldData.validation &&
                      input.isRequired &&
                      !selected &&
                      isFocus
                    "
                    class="text-danger"
                    >{{ $t("createProfile.requiredValidationMessage") }}</label
                  >
                </b-form-group>
                <b-collapse v-model="input.isConditional" class="mt-2">
                  <b-form-group
                    :class="
                      $store.state.rightAlign
                        ? 'text-right mr-5 '
                        : 'text-left ml-5'
                    "
                    :label="input.conditionalFieldLabel[selectedLanguage]"
                    :invalid-feedback="
                      $t('createProfile.requiredValidationMessage')
                    "
                  >
                    <b-form-textarea
                      :v-model="input.conditionalFieldInputValue"
                      :class="
                        $store.state.rightAlign ? 'text-right' : 'text-left'
                      "
                      @input="onInputProgressiveType($event, index)"
                      :disabled="isDisabled == 1"
                      :rows="fieldData.conditionalFieldMinRows || 3"
                      :max-rows="fieldData.conditionalFieldMaxRows || 6"
                      :state="
                        isFocus &&
                        input.conditionalFieldInputValue &&
                        isMatchProgressiveCondition &&
                        fieldData.validation.required == 1
                      "
                      @focus="isFocus = true"
                    ></b-form-textarea>
                  </b-form-group>
                </b-collapse>
              </b-form>

              <b-form-group
                v-if="input.type == 'date'"
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                id="fieldset-1"
              >
                <label>
                  <span
                    v-if="fieldData.validation && input.isRequired"
                    class="required-span"
                    >*</span
                  >
                  {{
                    form1.enableSectionFieldEnumeration == 1
                      ? sectionIndex + 1 + "." + (fieldIndex + 1)
                      : ""
                  }}
                  {{ label ? label : input.label[selectedLanguage] }}
                </label>

                <b-input-group
                  :class="
                    $store.state.rightAlign
                      ? 'text-right mb-3'
                      : 'text-left mb-3'
                  "
                >
                  <Datepicker
                    :v-model="conditionalFieldInputValue"
                    :disabled="isDisabled == 1"
                    input-class="custom-date-picker"
                    calendar-class="date-calender"
                    class="form-control"
                    @input="onInputType($event, input.type)"
                    @focus="isFocus = true"
                  ></Datepicker>
                  <b-input-group-append>
                    <span class="input-group-text" id="basic-addon2">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </b-input-group-append>
                </b-input-group>
                <span
                  v-if="isFocus && fieldData.validation"
                  class="text-danger"
                  >{{ fieldData.validationMessage[selectedLanguage] }}</span
                >
              </b-form-group>

              <b-form-group
                v-if="input.type == 'text'"
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                :label="input.label[selectedLanguage]"
                :invalid-feedback="
                  $t('createProfile.requiredValidationMessage')
                "
              >
                <b-form-textarea
                  :v-model="conditionalFieldInputValue"
                  :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                  @input="onInputType($event, input.type)"
                  :disabled="isDisabled == 1"
                  :rows="fieldData.conditionalFieldMinRows || 3"
                  :max-rows="fieldData.conditionalFieldMaxRows || 6"
                  :state="
                    isFocus &&
                    conditionalFieldInputValue &&
                    isMatchCondition &&
                    fieldData.validation.required == 1
                  "
                  @focus="isFocus = true"
                ></b-form-textarea>
              </b-form-group>
            </div>
          </b-collapse>
        </b-col>
      </b-row>
      <!-- advance settings tab -->
      <b-modal
        cancel-title="Close"
        ok-title="save"
        @ok="onSaveData"
        no-close-on-backdrop
        @hidden="onUpdateSettings"
        size="xl"
        v-model="showSettings"
        scrollable
        title="Radio button Advanced Settings"
      >
        <AdvanceRadioSettings
          v-if="showSettings"
          :fieldData="fieldData"
          :sectionIndex="sectionIndex"
          v-on:toggle-field="showSettings = !showSettings"
          :fieldIndex="fieldIndex"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import AdvanceRadioSettings from "../advanceRadioProgressiveSettingsComponent.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import MenuButtons from "../menuButtons";
import { EventBus } from "@/event-bus.js";
import { Helper } from "@/helpers";
import Datepicker from "vuejs-datepicker";
import { routeKeys, routeNames } from "@/constants";
import { clone } from "lodash";

export default {
  name: "RadioGroupProgressive",
  components: {
    AdvanceRadioSettings,
    MenuButtons,
    Datepicker
  },
  data() {
    return {
      isHidden: false,
      isFocus: false,
      condition: {},
      showSettings: false,
      collapse: true,
      label: undefined,
      placeholder: undefined,
      helperText: undefined,
      isRequired: 1,
      selected: this.fieldData.selected || "",
      radioOptions: [],
      conditionalFieldLabel: null,
      conditionalFieldHelperText: null,
      conditionalFieldPlaceHolderText: null,
      conditionalFieldValidationMessage: null,
      conditionalFieldIsRequired: 0,
      conditionalFieldMaxRows: 1,
      conditionalFieldInputValue: null,
      conditionalFieldPostName: null,
      conditionalFieldIsVisible: 0,
      showCollapseField: false,
      isMatchCondition: false,
      isMatchProgressiveCondition: false,
      radioOptionsProgressive: []
    };
  },
  created() {
    EventBus.on("onSubmitEndUserForm", (value) => {
      if (value == "progress") {
        this.$store.state.validFields = true;
        this.fieldData.isInValid = false;
      } else {
        if (
          this.fieldData.validation &&
          this.fieldData.validation.required == 1
        ) {
          if (!this.selected) {
            this.isFocus = true;
            this.$store.state.validFields = false;
            this.fieldData.isInValid = true;
          } else if (this.selected) {
            if (this.isMatchCondition) {
              if (!this.conditionalFieldInputValue) {
                this.isFocus = true;
                this.$store.state.validFields = false;
                this.fieldData.isInValid = true;
              } else {
                this.$store.state.validFields = true;
                this.fieldData.isInValid = false;
              }
            }
          } else {
            this.$store.state.validFields = true;
            this.fieldData.isInValid = false;
          }
        }
      }
    });
    EventBus.on("OnInvalidFieldCatch", (inValidData) => {
      document
        .getElementById(inValidData.elementId)
        .scrollIntoView({ behavior: "smooth" });
    });
  },

  mounted() {
    this.isRequired = this.fieldData.validation.required;
    if (
      this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
      this.$route.name == "MyCompanyView"
    ) {
      this.setFieldValue();
      this.setConditionInput();
    }

    let radios = this.fieldData.options;
    radios.map((radio) => {
      let pp = {
        value: radio.value,
        text: radio.text[this.selectedLanguage] || "No Text Set"
      };
      this.radioOptions.push(pp);
    });
  },

  computed: {
    ...mapGetters([
      "form1",
      "selectedLanguage",
      "userForm",
      "userFormSubmitArray"
    ]),
    ...mapState(["toggleAllCoppalse"])
  },

  watch: {
    toggleAllCoppalse(value) {
      this.collapse = value;
    },

    selectedLanguage() {
      this.radioOptions = [];
      let radios = this.fieldData.options;
      radios.map((radio) => {
        let pp = {
          value: radio.value,
          text: radio.text[this.selectedLanguage] || "No Text Set"
        };
        this.radioOptions.push(pp);
      });
    }
  },

  methods: {
    ...mapMutations(["TOGGLE_LOADER"]),

    onUpdateSettings() {
      this.TOGGLE_LOADER(2);
    },

    checkCondition(value) {
      this.isFocus = false;
      this.$store.state.validFields = true;
      this.fieldData.isInValid = false;

      let elements = this.form1.formSection[this.sectionIndex].formElements;
      elements.find((ff) => {
        if (ff.sectionConditions && ff.sectionConditions.length) {
          ff.sectionConditions.map((aa) => {
            if (aa.forSection) {
              if (this.form1.formSection[aa.selectedSection]) {
                if (
                  aa.id ===
                  this.form1.formSection[aa.selectedSection].condition.id
                ) {
                  if (
                    aa.sourceSectionId === this.fieldData.id &&
                    aa.value === value
                  ) {
                    this.form1.formSection[aa.selectedSection].isHidden = true;
                  } else {
                    this.form1.formSection[aa.selectedSection].isHidden = false;
                  }
                }
              }
            }
          });
        }
      });

      elements.find((ff, index) => {
        if (ff.conditions && ff.conditions.length) {
          ff.conditions.map((aa) => {
            if (
              aa.sourceFieldId === this.fieldData.id &&
              aa.targetFieldId !== this.fieldData.id &&
              aa.value === value &&
              aa.label === this.fieldData.label[this.selectedLanguage]
            ) {
              this.form1.formSection[this.sectionIndex].formElements[
                index
              ].isHidden = false;
            } else if (
              aa.sourceFieldId === this.fieldData.id &&
              aa.targetFieldId !== this.fieldData.id &&
              aa.value !== value &&
              aa.label === this.fieldData.label[this.selectedLanguage]
            ) {
              this.form1.formSection[this.sectionIndex].formElements[
                index
              ].isHidden = true;
            } else {
              // this.form1.formSection[this.sectionIndex].formElements[index].isHidden = true;
            }
          });
        } else {
          // this.form1.formSection[this.sectionIndex].formElements[index].isHidden = false;
        }
      });

      // for new conditional fields..

      if (
        this.fieldData.selectedConditionalFieldValue &&
        this.fieldData.selectedConditionalFieldValue == value
      ) {
        // if(this.fieldData.conditionalShowHiddenOption == 1){
        this.showCollapseField = true;
        this.conditionalFieldIsVisible = 1;
        this.isMatchCondition = true;
        this.setRadioOptionsProgressive();
        // }
      } else {
        this.showCollapseField = false;
        this.conditionalFieldIsVisible = 0;
        this.isMatchCondition = false;
      }

      this.setValue(value);
    },

    setRadioOptionsProgressive() {
      this.radioOptionsProgressive = [];

      let options = this.fieldData.progressiveOptions;
      options.map(() => {
        // opt.push(radios.options)
      });
      // opt.map((radio, index) => {
      //   let pp = {
      //     value: radio[index].value,
      //     text:
      //       radio[index].value == "Yes"
      //         ? options[index].yesLabel[this.selectedLanguage]
      //         : radio[index].value == "No"
      //         ? options[index].noLabel[this.selectedLanguage]
      //         : "No Text Set"
      //   };
      //   this.radioOptionsProgressive.push(pp);
      // });
    },
    checkProgressiveCondition(e, index) {
      this.isFocus = false;
      this.$store.state.validFields = true;
      this.fieldData.isInValid = false;
      if (
        this.fieldData.progressiveOptions[index]
          .selectedConditionalFieldValue &&
        this.fieldData.progressiveOptions[index]
          .selectedConditionalFieldValue == e
      ) {
        (this.fieldData.progressiveOptions[index].isConditional = true),
          (this.isMatchProgressiveCondition = true);
        // }
      } else {
        (this.fieldData.progressiveOptions[index].isConditional = false),
          (this.isMatchProgressiveCondition = true);
      }
    },

    setConditionInput() {
      if (
        this.fieldData.selectedConditionalFieldValue &&
        this.fieldData.selectedConditionalFieldValue == this.selected
      ) {
        // if(this.fieldData.conditionalShowHiddenOption == 1){
        this.showCollapseField = true;
        this.conditionalFieldIsVisible = 1;
        this.isMatchCondition = true;
        this.isFocus = true;

        // }
      } else {
        this.showCollapseField = false;
        this.conditionalFieldIsVisible = 0;
        this.isMatchCondition = false;
        this.isFocus = false;
      }
    },

    setFieldValue() {
      this.$store.state.getUserFormSubmitArray.length &&
        this.$store.state.getUserFormSubmitArray.map((a) => {
          if (a.elementId === this.fieldData.id) {
            a.elements.map((el) => {
              if (el.key == "radio") {
                this.selected = el.postValue;
              } else if (el.key == "progressive") {
                this.conditionalFieldInputValue = el.postValue;
              }
            });
          }
        });
    },

    setValue(e) {
      let pos = this.radioOptions.map((r) => r.value).indexOf(e);
      let languageValue = this.radioOptions[pos].text;
      const registerData = clone(this.$store.state.registerData);

      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        this.$route.name == "MyCompanyView"
      ) {
        this.selected = e;
        if (e && e.length > 0 && !this.conditionalFieldIsVisible) {
          this.conditionalFieldInputValue = null;
          registerData[this.fieldData.id] = e;
        } else {
          delete registerData[this.fieldData.id];
        }
        this.$store.state.registerData = registerData;

        // main calculations :p
        const numkeys = Object.keys(registerData).length;
        this.$store.state.progressValue = numkeys;

        if (this.selected) {
          this.$store.state.userFormSubmitArray.map((a) => {
            if (a.elementId === this.fieldData.id) {
              a.elements[0] = {
                language: this.selectedLanguage,
                valueForPDF: languageValue,
                postValue: this.selected,
                key: "radio",
                postName:
                  (this.fieldData.attributes &&
                    this.fieldData.attributes.postName) ||
                  "postNameRadio"
              };
            }
          });
        }
      }
    },

    onInputType(e, type) {
      const registerData = clone(this.$store.state.registerData);

      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        (this.$route.name == "MyCompanyView" && this.showCollapseField)
      ) {
        if (e && e.length > 0) {
          registerData[this.fieldData.id] = e;
        } else {
          delete registerData[this.fieldData.id];
        }
        this.$store.state.registerData = registerData;

        // main calculations :p
        let numkeys = Object.keys(registerData).length;
        this.$store.state.progressValue = numkeys;

        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId === this.fieldData.id) {
            a.elements[1] = {
              postValue: e,
              key: "progressive",
              postNameProgressive:
                this.fieldData.conditionalFieldLabel || "postNameProgressive"
            };
            if (type && type == "text") {
              a.elements[2] = {
                postValue: e,
                key: "multiProgressiveText",
                postNameProgressive: "postNameMultiProgressiveText"
              };
            }
            if (type && type == "date") {
              a.elements[3] = {
                postValue: e,
                key: "multiProgressiveDate",
                postNameProgressive: "postNameMultiProgressiveDate"
              };
            }
          }
        });
      }
    },

    onInputProgressiveType(e) {
      const registerData = clone(this.$store.state.registerData);

      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        (this.$route.name == "MyCompanyView" && this.showCollapseField)
      ) {
        if (e && e.length > 0) {
          registerData[this.fieldData.id] = e;
        } else {
          delete registerData[this.fieldData.id];
        }
        this.$store.state.registerData = registerData;

        // main calculations :p
        let numkeys = Object.keys(registerData).length;
        this.$store.state.progressValue = numkeys;

        this.$store.state.userFormSubmitArray.map((a, index) => {
          if (a.elementId === this.fieldData.id) {
            a.elements[2] = {
              postValue: e,
              key: "progressiveInner",
              postNameProgressiveInner:
                this.fieldData.progressiveOptions[index]
                  .conditionalFieldLabel || "postNameProgressiveInner"
            };
          }
        });
      }
    },

    onSaveData() {
      EventBus.emit("onSaveSettings", "saving settings");
      Helper.makeToast(this.$bvToast, "success", "Settings Updated");
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
<style>
.custom-date-picker {
  width: 100%;
  border: none;
}
.custom-date-picker:focus {
  border: none;
}
.date-calender {
  width: 500px !important;
  background: #dee2e6 !important;
}
</style>
