<template>
  <div
    :class="['drop-down-list', { 'drop-down-list--active': isDropDownVisible }]"
    data-test-id="drop-down-list"
  >
    <CallToAction
      class="drop-down-list__cta"
      data-test-id="drop-down-list__cta"
      :theme="themes.GREY"
      :icon="icon"
      :aria-expanded="isDropDownVisible"
      @click="onToggleDropDown"
      @keyup.enter="onToggleDropDown"
      @blur="hideDropDown"
    />
    <MenuList
      data-test-id="drop-down-list__list"
      :isMenuVisible="isDropDownVisible"
      :menuList="dropDownList"
      @mouseleave="onToggleUserPosition"
      @mouseenter="onToggleUserPosition"
      @focusin="onToggleUserPosition"
      @focusout="onToggleUserPosition"
      @click="(value) => onSelection('click', value)"
      @keyUp="(value) => onSelection('keyup', value)"
      tabindex="0"
    />
  </div>
</template>

<script>
import CallToAction from "@/atoms/CallToAction/CallToAction";
import MenuList from "@/atoms/MenuList/MenuList";
import { themes } from "@/constants";

export default {
  name: "DropDownList",
  components: {
    CallToAction,
    MenuList
  },
  data() {
    return {
      isDropDownVisible: false,
      isUserOnDropDown: false,
      themes
    };
  },
  props: {
    icon: {
      type: String,
      default: "ellipsis-h"
    },
    dropDownList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onToggleDropDown() {
      this.isDropDownVisible = !this.isDropDownVisible;
    },
    hideDropDown() {
      if (!this.isUserOnDropDown) {
        this.isDropDownVisible = false;
      }
    },
    onToggleUserPosition() {
      this.isUserOnDropDown = !this.isUserOnDropDown;
    },
    onSelection(eventName, value) {
      this.$emit(eventName, value);
      this.onToggleDropDown();
    }
  }
};
</script>

<style lang="scss" scoped>
.drop-down-list {
  position: relative;
  display: inline-block;
  z-index: 1;
  transition: display $transition-duration;

  &--active {
    z-index: 2;
  }
}
</style>
