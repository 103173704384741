<template>
  <section>
    <BaseLoader v-if="isLoadingRoles" />
    <template v-else>
      <a v-if="errorMessage" target="_blank" :href="platformRequestSupportLink">
        {{ errorMessage }}
      </a>
      <router-view v-else v-slot="{ Component }">
        <component
          :is="Component"
          :role="getSelectedRole"
          @editRole="onEditRole"
        />
      </router-view>
    </template>
  </section>
</template>

<script>
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import { actionName, routeNames } from "@/constants";
import { mapActions, mapState } from "vuex";

export default {
  name: "RolesTemplate",
  components: { BaseLoader },
  data() {
    return {
      errorMessage: "",
      selectedRoleId: ""
    };
  },
  computed: {
    ...mapState({
      roles: (state) => state.roles.roles,
      isLoadingRoles: (state) => state.roles.isLoadingRoles,
      platformRequestSupportLink: (state) =>
        state.platformSettings.platformRequestSupportLink
    }),
    getSelectedRole() {
      return this.roles.find(({ id }) => id === this.selectedRoleId);
    }
  },
  created() {
    this.fetchRoles();
    this.setInitialSelectedRoleId();
  },
  beforeRouteUpdate(to, _, next) {
    if (to.name === routeNames.ADD_ROLE) {
      this.onEditRole("");
    }

    next();
  },
  methods: {
    ...mapActions({
      fetchRolesAction: actionName.ROLES.FETCH_ROLES
    }),
    setErrorMessage() {
      this.errorMessage = "Please contact support.";
    },
    resetErrorMessage() {
      this.errorMessage = "";
    },
    async fetchRoles() {
      this.resetErrorMessage();
      try {
        await this.fetchRolesAction();
      } catch {
        this.setErrorMessage();
      }
    },
    onEditRole(id) {
      this.selectedRoleId = id;
    },
    setInitialSelectedRoleId() {
      this.selectedRoleId = this.$route.params?.id || "";
    }
  }
};
</script>
