<!-- clients configuartion profile page sidebar menu -->
<template>
  <div>
    <InputErrorMessage v-if="errorMessage" :error="errorMessage" />
    <b-row style="background-color: #f4f5fd; height: 100%">
      <b-col cols="2" style="max-width: 20%">
        <sidebar-menu
          class="bg-light"
          :menu="menu"
          :relative="true"
          :hideToggle="true"
          theme="white-theme"
        >
          <span slot="dropdown-icon">
            <font-awesome-icon icon="caret-down" />
          </span>
          <span slot="toggle-icon">
            <font-awesome-icon icon="caret-up" />
          </span>
        </sidebar-menu>
      </b-col>
      <b-col cols="9">
        <transition name="fade">
          <router-view />
        </transition>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SidebarMenu } from "vue-sidebar-menu";
import { EventBus } from "@/event-bus.js";
import { localStorageItem, actionName } from "@/constants";
import { mapActions, mapState } from "vuex";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";

export default {
  name: "ClientsConfigurationProfilePage",
  components: {
    SidebarMenu,
    InputErrorMessage
  },
  data() {
    return {
      menu: [
        {
          href: `/client-configuration-profile/${this.$route.params.id}/platform-settings`,
          title: "Platform Settings"
        },
        {
          href: `/client-configuration-profile/${this.$route.params.id}/settings`,
          title: "Settings"
        },
        {
          href: `/client-configuration-profile/${this.$route.params.id}/email-templates`,
          title: "Emails Templates"
        },
        {
          title: "Third Party",
          child: [
            {
              href: `/client-configuration-profile/${this.$route.params.id}/dynamic-actions`,
              title: "Configure Actions"
            },
            {
              href: `/client-configuration-profile/${this.$route.params.id}/dynamic-columns`,
              title: "Configure Columns"
            },
            {
              href: `/client-configuration-profile/${this.$route.params.id}/dynamic-bulk-order-actions`,
              title: "Configure Bulk Order Actions"
            },
            {
              href: `/client-configuration-profile/${this.$route.params.id}/dynamic-edd-configuration`,
              title: "Configure EDD"
            },
            {
              href: `/client-configuration-profile/${this.$route.params.id}/dynamic-analytics`,
              title: "Configure Analytics"
            }
          ]
        },
        {
          title: "Account",
          child: [
            {
              href: `/client-configuration-profile/${this.$route.params.id}/action-details`,
              title: "Action Details"
            },
            {
              href: `/client-configuration-profile/${this.$route.params.id}/action-settings`,
              title: "Action Settings"
            }
          ]
        }
      ],
      isSubmiting: false,
      errorMessage: ""
    };
  },
  computed: {
    ...mapState({
      companyDetails: (state) => state.company.companyDetails
    })
  },
  beforeRouteUpdate({ name }, _, next) {
    if (name === "ActionSettings") {
      this.getAdditionalVouchersWrapper();
    }
    next();
  },
  mounted() {
    this.getAdditionalVouchersWrapper();
  },
  methods: {
    ...mapActions({
      fetchAdditionalVouchers: actionName.COMPANY.FETCH_ADDITIONAL_VOUCHERS
    }),
    getAdditionalVouchersWrapperFailure({ status = "" } = {}) {
      const description = status ? ` with status ${status}` : "";
      this.errorMessage = `There was an error${description}. Pleasy try again or contact support.`;
    },
    async getAdditionalVouchersWrapper() {
      try {
        this.errorMessage = "";
        this.isSubmiting = true;
        const { data = {}, status } = await this.fetchAdditionalVouchers(
          this.$route.params.id
        );

        if (data.fakeHttpStatus === 1 && status === 200) {
          localStorage.setItem(
            localStorageItem.ACTIONS_SCHEMA,
            JSON.stringify(this.companyDetails.additionalData)
          );

          localStorage.setItem(
            "openedCompanyProfile",
            this.companyDetails.companyName
          );
          localStorage.setItem(
            "autoFillMsqV2",
            this.companyDetails.autoFillMsqV2
          );
          EventBus.emit("callgetAdditionalVouchers");
        } else {
          this.getAdditionalVouchersWrapperFailure();
        }
      } catch ({ response }) {
        this.getAdditionalVouchersWrapperFailure(response);
      } finally {
        this.isSubmiting = false;
      }
    }
  }
};
</script>
