/**
 * Calls `sizeColumnsToFit()` on gridAPI if the columns do not fill the width of the grid.
 *
 * See https://www.ag-grid.com/javascript-data-grid/column-sizing/#size-columns-to-fit
 * @param {object} parameter - provided object
 * @param {object} parameter.gridAPI - AGGrid GridAPI
 * @param {object} parameter.sizingElement - The grid element
 * @param {Function} parameter.makeColumnLimits - Optional function to provide column limits to `sizeColumnsToFit()`
 */
const sizeColumnsToGridWidth = ({
  gridAPI = {},
  sizingElement = {},
  makeColumnLimits = () => []
}) => {
  const columnState = gridAPI.getColumnState();
  const totalColumnWidth = columnState.reduce((accumulate, { width = 0 }) => {
    accumulate += width;

    return accumulate;
  }, 0);
  const gridWidth = sizingElement?.getBoundingClientRect()?.width || 0;

  if (totalColumnWidth < gridWidth) {
    gridAPI.sizeColumnsToFit({
      columnLimits: makeColumnLimits()
    });
  }
};

export { sizeColumnsToGridWidth };
