<template>
  <div>
    <b-modal
      v-model="isResetPasswordNeeded"
      scrollable
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      :title="$t('eb360.changePasswordModal.title')"
    >
      <b-form autocomplete="off">
        <template v-if="!submitting && !responseSuccess">
          <b-form-group class="mb-2">
            <b-row>
              <b-col cols="4">
                <label class="text-left control-label">
                  {{ $t("eb360.changePasswordModal.fieldPassOld") }}
                </label>
              </b-col>
              <b-col cols="8">
                <b-form-input
                  size="sm"
                  type="password"
                  v-model="v$.form.oldPassword.$model"
                  :state="
                    v$.form.oldPassword.$dirty
                      ? !v$.form.oldPassword.$error
                      : null
                  "
                />
                <div v-if="v$.form.oldPassword.$error" class="invalid-feedback">
                  <span v-if="!v$.form.oldPassword.required">
                    {{ $t("eb360.changePasswordModal.oldPassErr") }}
                  </span>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row>
              <b-col cols="4">
                <label class="text-left control-label">
                  {{ $t("eb360.changePasswordModal.fieldPassNew") }}
                </label>
              </b-col>
              <b-col cols="8">
                <b-form-input
                  size="sm"
                  type="password"
                  class="mb-3"
                  @input="password_check"
                  v-model="v$.form.newPassword.$model"
                  :state="
                    v$.form.newPassword.$dirty
                      ? !v$.form.newPassword.$error
                      : null
                  "
                />
                <div v-if="v$.form.newPassword.$error" class="invalid-feedback">
                  <span v-if="!v$.form.newPassword.required">
                    {{ $t("eb360.changePasswordModal.newPassErr") }}
                  </span>
                </div>
                <p
                  v-if="form.newPassword.length < 10"
                  class="frmValidation"
                  :class="{
                    'frmValidation--passed': form.newPassword.length > 10
                  }"
                >
                  <i
                    class="frmIcon fas"
                    :class="
                      form.newPassword.length > 10 ? 'fa-check' : 'fa-times'
                    "
                  />
                  {{ $t("eb360.changePasswordModal.errLeast") }}
                </p>
                <p
                  v-if="!hasUpperCase"
                  class="frmValidation"
                  :class="{ 'frmValidation--passed': hasUpperCase }"
                >
                  <i
                    class="frmIcon fas"
                    :class="hasUpperCase ? 'fa-check' : 'fa-times'"
                  />
                  {{ $t("eb360.changePasswordModal.errCapital") }}
                </p>
                <p
                  v-if="!hasLowerCase"
                  class="frmValidation"
                  :class="{ 'frmValidation--passed': hasLowerCase }"
                >
                  <i
                    class="frmIcon fas"
                    :class="hasLowerCase ? 'fa-check' : 'fa-times'"
                  />
                  {{ $t("eb360.changePasswordModal.errLower") }}
                </p>
                <p
                  v-if="!hasNumber"
                  class="frmValidation"
                  :class="{ 'frmValidation--passed': hasNumber }"
                >
                  <i
                    class="frmIcon fas"
                    :class="hasNumber ? 'fa-check' : 'fa-times'"
                  />
                  {{ $t("eb360.changePasswordModal.errNumber") }}
                </p>
                <p
                  v-if="!hasSpecialCharacters"
                  class="frmValidation"
                  :class="{ 'frmValidation--passed': hasSpecialCharacters }"
                >
                  <i
                    class="frmIcon fas"
                    :class="hasSpecialCharacters ? 'fa-check' : 'fa-times'"
                  />
                  {{ $t("eb360.changePasswordModal.errSpecial") }}
                </p>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row>
              <b-col cols="4">
                <label class="text-left control-label">
                  {{ $t("eb360.changePasswordModal.fieldConfirmPass") }}
                </label>
              </b-col>
              <b-col cols="8">
                <b-form-input
                  size="sm"
                  type="password"
                  v-model="v$.form.confirmNewPassword.$model"
                  :state="
                    v$.form.confirmNewPassword.$dirty
                      ? !v$.form.confirmNewPassword.$error
                      : null
                  "
                />
                <div
                  v-if="v$.form.confirmNewPassword.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!v$.form.confirmNewPassword.required">
                    {{ $t("eb360.changePasswordModal.errConfirm") }}
                  </span>
                  <span v-else-if="!v$.form.confirmNewPassword.sameAsPassword">
                    {{ $t("eb360.changePasswordModal.errMatch") }}
                  </span>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
        </template>
        <template v-else-if="submitting && !responseSuccess">
          <p>{{ $t("eb360.changePasswordModal.loadingMsg") }}</p>
          <b-spinner type="grow" label="Loading..."></b-spinner>
        </template>
        <template v-else>
          <p class="my-2 text-center">
            {{ $t("eb360.changePasswordModal.successPass") }}
            <br />
          </p>
        </template>
      </b-form>
      <template v-slot:modal-footer>
        <b-button
          size="md"
          v-if="!submitting"
          variant="outline-danger"
          @click="resetModal"
        >
          {{ responseSuccess ? $t("closeBtnText") : $t("cancelBtnText") }}
        </b-button>
        <b-button
          size="md"
          v-if="!submitting && !responseSuccess"
          variant="outline-success"
          @click="onSubmit"
        >
          {{ $t("submitText") }}
        </b-button>
      </template>
    </b-modal>
    <b-toast
      id="password-toaster"
      :title="$t('eb360.changePasswordModal.title')"
      :variant="toasterVariant"
    >
      <span v-html="toasterMessage" />
    </b-toast>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";
import { mapGetters, mapMutations, mapState } from "vuex";
import { authService } from "@/services";
import { userPasswordUpdated, userPassword } from "@/constants";
import {
  hasNumber,
  hasLowerCase,
  hasUpperCase,
  hasSpecialCharacters
} from "@/utils";

export default {
  name: "ChangePasswordModal",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      toasterMessage: null,
      toasterVariant: "primary",
      form: this.makeFormObj(),
      submitting: false,
      responseSuccess: false,
      hasNumber: false,
      hasLowerCase: false,
      hasUpperCase: false,
      hasSpecialCharacters: false,
      passwordCreated: null
    };
  },
  validations: {
    form: {
      oldPassword: {
        required
      },
      newPassword: {
        required,
        minLength: minLength(10)
      },
      confirmNewPassword: {
        required,
        sameAsPassword: sameAs("newPassword")
      }
    }
  },
  computed: {
    ...mapGetters("user", ["isResetPasswordNeeded"]),
    ...mapState({
      userId: (state) => state.user.userId
    })
  },
  methods: {
    ...mapMutations("user", ["setPasswordCreated"]),
    makeFormObj() {
      return {
        oldPassword: null,
        newPassword: "",
        confirmNewPassword: null
      };
    },
    password_check() {
      this.hasNumber = hasNumber(this.form.newPassword);
      this.hasLowerCase = hasLowerCase(this.form.newPassword);
      this.hasUpperCase = hasUpperCase(this.form.newPassword);
      this.hasSpecialCharacters = hasSpecialCharacters(this.form.newPassword);
    },
    onSubmit() {
      this.v$.form.$touch();
      if (!this.v$.form.$anyError) {
        this.changePassword();
      }
    },
    setToasterValues(toasterVariant, toasterMessage) {
      this.toasterVariant = toasterVariant;
      this.toasterMessage = toasterMessage;
    },
    displayToaster() {
      this.$bvToast.show("password-toaster");
      this.resetModal();
    },
    changePasswordSuccessfully({ data }) {
      if (
        parseInt(data?.success) === userPasswordUpdated.SUCCESS_PASSWORD_UPDATE
      ) {
        this.setToasterValues("success", data.message);
        this.displayToaster();
      } else if (
        parseInt(data?.success) === userPasswordUpdated.FAIL_PASSWORD_UPDATE
      ) {
        this.setToasterValues("danger", data.message);
        this.displayToaster();
      }
    },
    async changePassword() {
      try {
        this.submitting = true;
        const result = await authService.changePassword(
          this.makeChangePasswordPostObj()
        );
        this.changePasswordSuccessfully(result);
      } catch (error) {
        return error;
      } finally {
        this.submitting = false;
        this.responseSuccess = false;
      }
    },
    makeChangePasswordPostObj() {
      return {
        memberId: this.userId,
        old_password: this.form.oldPassword,
        new_password: this.form.confirmNewPassword
      };
    },
    resetModal() {
      this.setPasswordCreated(userPassword.DO_NOT_RESET_PASSWORD);
      this.form = this.makeFormObj();
      this.submitting = false;
      this.responseSuccess = false;
      this.hasNumber = false;
      this.hasLowerCase = false;
      this.hasUpperCase = false;
      this.hasSpecialCharacters = false;
      this.v$.$reset();
    }
  }
};
</script>

<style lang="scss" scoped>
.control-label:after {
  content: "*";
  color: $spanish-red;
  margin-left: 5px;
  font-size: 20px;
}

.frmValidation {
  font-size: 13px;
}
.frmValidation--passed {
  color: $black-olive;
}
.frmIcon {
  color: $spanish-red;
}
.frmValidation--passed .frmIcon {
  color: $robin-egg-blue;
}
</style>
