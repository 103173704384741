<template>
  <div>
    <b-overlay :show="isLoadingData" rounded="sm">
      <b-row>
        <b-col cols="12">
          <b-card header-tag="header" class="border-0">
            <b-row>
              <b-col cols="2">
                <b-card-title style="font-size: 18px">{{
                  $t("eb360.auditTrailTabComponent.title")
                }}</b-card-title>
              </b-col>
              <b-col>
                <div class="mb-0 float-right d-flex">
                  <!-- Search bar start -->
                  <b-form-group
                    style="margin: 8px"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="filterInput"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        @input="onEmptyFilter($event)"
                        v-model="filterValue"
                        @keyup.enter="onSearchAudit"
                        type="search"
                        id="filterInput"
                        :placeholder="
                          $t('eb360.manageThirdParties.searchPlaceholder')
                        "
                      ></b-form-input>
                      <b-input-group-append>
                        <b-btn
                          :disabled="!filterValue"
                          variant="outline-success"
                          @click="onSearchAudit"
                          >Search</b-btn
                        >
                        <b-button
                          :disabled="!filterValue"
                          @click="setFIlterClear"
                          >Clear</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <!-- Search bar end -->

                  <!-- Reload button start -->
                  <b-button
                    class="m-2"
                    variant="outline-info"
                    size="sm"
                    @click="getTpAuditTrails"
                  >
                    <font-awesome-icon icon="sync" />
                  </b-button>
                  <!-- Reload button end -->

                  <!-- Add comment button start -->
                  <b-button
                    class="m-2"
                    variant="outline-warning"
                    size="sm"
                    :disabled="isDisabled"
                    @click="showAddCommentModal = !showAddCommentModal"
                    :hidden="auditTrailType != 1"
                  >
                    <font-awesome-icon icon="comments" />
                  </b-button>
                  <!-- Add comment button end -->

                  <!-- Filter button start -->
                  <b-dropdown
                    menu-class="menu-dropdown"
                    size="sm"
                    no-caret
                    class="m-2"
                    variant="outline-info"
                    :hidden="auditTrailType != 1"
                  >
                    <template v-slot:button-content>
                      <font-awesome-icon icon="filter" />
                    </template>
                    <b-dropdown-form>
                      <b-form-checkbox
                        :disabled="showTpLogs == 0"
                        v-model="showUserComments"
                        value="1"
                        unchecked-value="0"
                        @input="getTpAuditTrails"
                        >{{
                          $t("eb360.auditTrailTabComponent.activityChanges")
                        }}</b-form-checkbox
                      >
                      <b-form-checkbox
                        :disabled="showUserComments == 0"
                        v-model="showTpLogs"
                        value="1"
                        unchecked-value="0"
                        @input="getTpAuditTrails"
                        >{{
                          $t("eb360.auditTrailTabComponent.tpComments")
                        }}</b-form-checkbox
                      >
                    </b-dropdown-form>
                  </b-dropdown>
                  <!-- Filter button end -->

                  <!-- Print button start -->
                  <b-button
                    class="m-2"
                    variant="outline-primary"
                    size="sm"
                    :hidden="auditTrailType != 1"
                    @click="onPrintAuditTrail"
                  >
                    <font-awesome-icon icon="print" />
                  </b-button>
                  <!-- Print button end -->
                </div>
              </b-col>
            </b-row>
            <!-- Audit trail data start -->
            <b-table
              responsive="sm"
              :sort-by.sync="sortBy"
              borderless
              hover
              ref="table"
              id="audit-trail-table"
              :per-page="perPage"
              :current-page="currentPage"
              :items="getTpAuditTrails"
              :fields="fields"
              :filter="filterTable ? filterInputValue : null"
            >
              <template v-slot:cell(inserted_date)="data">
                <p>{{ setDateFormat(data.item.inserted_date) }}</p>
              </template>
              <template v-slot:cell(title)="data">
                <p class="m-0" v-html="data.item.title"></p>
                <p
                  style="
                    overflow-wrap: anywhere;
                    font-style: italic;
                    font-size: 15px;
                  "
                  v-if="data.item.comment"
                >
                  Reason of Change:
                  <span v-html="data.item.comment"></span>
                </p>
              </template>
            </b-table>
            <!-- Audit trail data end -->
            <b-row>
              <b-col cols="2">
                <b-form-select
                  v-model="perPage"
                  id="perPageSelect"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select>
              </b-col>
              <b-col>
                <b-pagination
                  class="float-right"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-text="First"
                  prev-text="Prev"
                  next-text="Next"
                  last-text="Last"
                  aria-controls="audit-trail-table"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

    <!-- Modal for add comment -->
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      title="Add Comment"
      v-model="showAddCommentModal"
    >
      <div>
        <div v-if="!submiting && !responseSuccess">
          <b-form-group>
            <b-form-textarea
              id="textarea"
              v-model="v$.form.commentInput.$model"
              :state="
                v$.form.commentInput.$dirty
                  ? !v$.form.commentInput.$error
                  : null
              "
              :placeholder="$t('eb360.auditTrailTabComponent.enterComment')"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
        </div>
        <div v-else-if="submiting && !responseSuccess">
          <p>{{ $t("eb360.auditTrailTabComponent.commentAdding") }}</p>
          <b-spinner type="grow" :label="$t('loadingSpinnerText')"></b-spinner>
        </div>
        <div v-else>
          <p class="my-2 text-center">
            {{ $t("eb360.auditTrailTabComponent.commentAudit") }}
            <br />
          </p>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button v-if="!submiting" variant="outline-danger" @click="resetModal"
          >Close</b-button
        >
        <b-button
          size="md"
          v-if="!submiting && !responseSuccess"
          variant="outline-success"
          @click="checkValidation()"
          >Add</b-button
        >
      </template>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";
import "moment/locale/es";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { config } from "@/configs";
import { mapState } from "vuex";

export default {
  name: "AuditTrailTabComponent",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      eb2AppUrl: process.env.VUE_APP_BASE_URL,
      sortBy: "event",
      perPage: 5,
      currentPage: 1,
      filterInputValue: null,
      totalRows: 0,
      pageOptions: [5, 10, 20, 30, 50],
      showUserComments: "1",
      showTpLogs: "1",
      isLoadingData: false,

      fields: [
        { key: "inserted_date", label: "Date", sortable: true },
        { key: "title", label: "Event", sortable: false },
        {
          key: "user",
          label: "User",
          sortable: false
        }
      ],
      auditTrailsData: [],
      form: {
        commentInput: null
      },
      showAddCommentModal: false,
      submiting: false,
      responseSuccess: false,
      filter: null,
      filterTable: false,
      filterValue: null
    };
  },
  validations: {
    form: {
      commentInput: { required }
    }
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId
    })
  },
  methods: {
    onSearchAudit() {
      if (this.filterValue) {
        this.filterInputValue = this.filterValue;
        this.filterTable = true;
        this.currentPage = 1;
        this.auditTrailsData = [];
      }
    },
    onEmptyFilter(value) {
      if (value) {
        return false;
      } else {
        this.setFIlterClear();
      }
    },
    setFIlterClear() {
      this.filterValue = "";
      this.filterInputValue = null;
      this.filterTable = false;
    },
    reloadTable() {
      this.currentPage = 1;
      this.auditTrailsData = [];
      this.$root.$emit("bv::refresh::table", "audit-trail-table");
    },
    setDateFormat(value) {
      moment.locale("en");
      let date = value || null;
      return moment.utc(date).local().format("DD dddd MMMM YYYY HH:mm");
    },

    // get TP audit trails
    async getTpAuditTrails() {
      this.auditTrailsData = [];
      let formData = {
        showUserComments: this.showUserComments,
        showTpLogs: this.showTpLogs,
        thirdPartyId: this.thirdPartyId,
        companyId: this.companyId,
        auditTrailType: this.auditTrailType,
        limit: this.perPage,
        page: this.currentPage,
        filter: this.filterValue
      };
      try {
        this.isLoadingData = true;
        let result = await HTTP(
          "post",
          "thirdparty/audittrails",
          formData,
          makeAuthorizationHeader()
        );
        if (result && result.data.status == 1 && result.status == 200) {
          this.totalRows = result.data.total;
          this.isLoadingData = false;
          this.auditTrailsData = result.data.response;
          this.$store.state.auditTrailTabLazyLoad = false;
          return this.auditTrailsData;
        } else {
          this.auditTrailsData = [];
          this.isLoadingData = false;
          this.$store.state.auditTrailTabLazyLoad = false;
        }
      } catch (error) {
        this.isLoadingData = false;
      }
    },

    // check validation before adding audit trail comment
    checkValidation() {
      this.v$.form.$touch();
      if (this.v$.form.$anyError) {
        return false;
      } else {
        this.addTpAuditTrailComment();
      }
    },

    // call add audit trail comment API
    async addTpAuditTrailComment() {
      let formData = {
        thirdPartyId: this.thirdPartyId,
        companyId: this.companyId,
        comment: this.form.commentInput,
        memberId: this.userId
      };
      try {
        this.submiting = true;
        this.isLoadingData = true;
        let result = await HTTP(
          "post",
          "thirdparty/add/audittrailcomment",
          formData,
          makeAuthorizationHeader()
        );
        if (result.status == 200) {
          this.$root.$emit("bv::refresh::table", "audit-trail-table");
        }
        this.submiting = false;
        this.isLoadingData = false;
        this.responseSuccess = true;
      } catch (err) {
        return [err];
      }
    },
    resetModal() {
      this.form.commentInput = null;
      this.submiting = false;
      this.filterTable = false;
      this.filterValue = null;
      this.isLoadingData = false;
      this.responseSuccess = false;
      this.showAddCommentModal = false;
      this.v$.form.$reset();
    },
    onPrintAuditTrail() {
      window.open(
        `${config.BASE_URL}audit_trail_get.php?vid=${this.thirdPartyId}&filter_1=${this.showUserComments}&filter_2=${this.showTpLogs}&comp_id=${this.companyId}&member_id=${this.userId}`
      );
    }
  },
  props: {
    companyId: Number,
    thirdPartyId: Number,
    isDisabled: Boolean,
    auditTrailType: Number
  }
};
</script>
<style>
ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #6bdc99;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}
.menu-dropdown {
  min-width: 15rem;
}
</style>
