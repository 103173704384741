const languagesKeysDTO = {
  ID: "id",
  LANGUAGE_CODE: "languageCode",
  TEXT: "text",
  VALUE: "value"
};

const languagesAPIDTO = {
  ID: "id",
  LANGUAGE_CODE: "language_code",
  TEXT: "languages",
  VALUE: "languages"
};

export { languagesKeysDTO, languagesAPIDTO };
