const timers = {
  NONE: 0,
  FAST: 300,
  MEDIUM: 600,
  MODERATE: 1500,
  MODERATE_READABLE: 3500,
  MODERATE_SLOW: 10000,
  VERY_SLOW: 15000
};

export { timers };
