import { actions } from "./uboEntity.actions.store";
import { mutations } from "./uboEntity.mutations.store";
import { state } from "./uboEntity.state.store";

export default {
  namespaced: true,
  actions,
  mutations,
  state
};
