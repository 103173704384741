import { breadcrumbRouteNames, routeNames, urls, templates } from "@/constants";
import UserTrainingTemplate from "@/templates/UserTrainingTemplate/UserTrainingTemplate";
import UserTraining from "@/organisms/UserTraining/UserTraining";
import UserTrainingLearner from "@/organisms/UserTrainingLearner/UserTrainingLearner";

export default {
  path: urls.DASHBOARD_TRAINING,
  name: routeNames.DASHBOARD_TRAINING_TEMPLATE,
  component: UserTrainingTemplate,
  children: [
    {
      path: "",
      name: routeNames.DASHBOARD_TRAINING,
      component: UserTraining,
      props: true,
      meta: {
        requiresAuth: true,
        template: templates.THIRD_PARTY,
        breadcrumbs: () => [
          { name: breadcrumbRouteNames.HOME, link: urls.HOME },
          {
            name: breadcrumbRouteNames.DASHBOARD_TRAINING,
            link: urls.DASHBOARD_TRAINING
          }
        ]
      }
    },
    {
      path: urls.TRAINING_DETAILS(),
      name: routeNames.TRAINING_DETAILS,
      component: UserTrainingLearner,
      meta: {
        requiresAuth: true,
        template: templates.THIRD_PARTY,
        breadcrumbs: () => [
          { name: breadcrumbRouteNames.HOME, link: urls.HOME },
          {
            name: breadcrumbRouteNames.DASHBOARD_TRAINING,
            link: urls.DASHBOARD_TRAINING
          },
          {
            name: breadcrumbRouteNames.TRAINING_DETAILS,
            link: urls.TRAINING_DETAILS
          }
        ]
      }
    }
  ]
};
