import { thirdPartyService } from "@/services";
import { isEmpty } from "lodash";
import { mutationName, ultimateBeneficialOwnersKeysAPIDTO } from "@/constants";

export const actions = {
  async fetchUBOEntityData({ commit, rootState }, payload) {
    try {
      commit(mutationName.UBO_ENTITY.SET_UBO_ENTITY_DATA_IS_LOADING, true, {
        root: true
      });

      const data = await thirdPartyService.fetchUBOEntityData({
        [ultimateBeneficialOwnersKeysAPIDTO.COMPANY_ID]:
          rootState.company.companyId,
        ...payload
      });

      if (!isEmpty(data)) {
        commit(mutationName.UBO_ENTITY.SET_UBO_ENTITY_DATA, data, {
          root: true
        });
      } else {
        commit(
          mutationName.UBO_ENTITY.SET_UBO_ENTITY_ERROR_MESSAGE,
          "No results found",
          { root: true }
        );
      }

      return data;
    } catch ({ status }) {
      const errorMessage = `Something went wrong, please try again.${
        status ? ` Error with status of ${status}.` : ""
      } If the issue persists please contact support`;

      commit(
        mutationName.UBO_ENTITY.SET_UBO_ENTITY_ERROR_MESSAGE,
        errorMessage,
        {
          root: true
        }
      );
    } finally {
      commit(mutationName.UBO_ENTITY.SET_UBO_ENTITY_DATA_IS_LOADING, false, {
        root: true
      });
    }
  },
  async fetchUBODueDiligenceReportData({ commit, rootState }, payload) {
    try {
      commit(
        mutationName.UBO_ENTITY.SET_UBO_DUE_DILIGENCE_REPORT_DATA_IS_LOADING,
        true,
        {
          root: true
        }
      );

      const { data } = await thirdPartyService.fetchUBODueDiligenceReportData({
        companyId: rootState.company.companyId,
        ...payload
      });

      if (!isEmpty(data)) {
        commit(
          mutationName.UBO_ENTITY.SET_UBO_DUE_DILIGENCE_REPORT_DATA,
          data,
          {
            root: true
          }
        );
      } else {
        commit(
          mutationName.UBO_ENTITY.SET_UBO_DUE_DILIGENCE_REPORT_ERROR_MESSAGE,
          "No results found",
          { root: true }
        );
      }

      return data;
    } catch ({ status }) {
      const errorMessage = `Something went wrong, please try again.${
        status ? ` Error with status of ${status}.` : ""
      } If the issue persists please contact support`;

      commit(
        mutationName.UBO_ENTITY.SET_UBO_DUE_DILIGENCE_REPORT_ERROR_MESSAGE,
        errorMessage,
        {
          root: true
        }
      );
    } finally {
      commit(
        mutationName.UBO_ENTITY.SET_UBO_DUE_DILIGENCE_REPORT_DATA_IS_LOADING,
        false,
        {
          root: true
        }
      );
    }
  }
};
