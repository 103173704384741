import {
  userPermissionKeysDTO,
  userPermissionKeysAPIDTO,
  featurePermissionKeysDTO,
  featurePermissionKeysAPIDTO
} from "@/constants";

const makePermissionsDTO = (permission = {}) => ({
  [userPermissionKeysDTO.ID]: permission?.[userPermissionKeysAPIDTO.ID] || "",
  [userPermissionKeysDTO.NAME]:
    permission?.[userPermissionKeysAPIDTO.NAME] || "",
  [userPermissionKeysDTO.ACTION]:
    permission?.[userPermissionKeysAPIDTO.ACTION] || "",
  [userPermissionKeysDTO.SUBJECT]:
    permission?.[userPermissionKeysAPIDTO.SUBJECT] || "",
  [userPermissionKeysDTO.DESCRIPTION]:
    permission?.[userPermissionKeysAPIDTO.DESCRIPTION] || "",
  [userPermissionKeysDTO.CONTEXTS]:
    permission?.[userPermissionKeysAPIDTO.CONTEXTS]?.map((context) => {
      const contexts = {
        [userPermissionKeysDTO.COMPANY_ID]:
          context?.[userPermissionKeysAPIDTO.COMPANY_ID] || ""
      };

      if (
        context.hasOwnProperty(userPermissionKeysAPIDTO.CUSTOM_ATTRIBUTE_ID)
      ) {
        contexts[userPermissionKeysDTO.CUSTOM_ATTRIBUTE_ID] =
          context?.[userPermissionKeysAPIDTO.CUSTOM_ATTRIBUTE_ID] || "";
      }

      if (context.hasOwnProperty(userPermissionKeysAPIDTO.USER_VIEW_ID)) {
        contexts[userPermissionKeysDTO.USER_VIEW_ID] =
          context?.[userPermissionKeysAPIDTO.USER_VIEW_ID] || "";
      }

      if (context.hasOwnProperty(userPermissionKeysAPIDTO.TASK_ID)) {
        contexts[userPermissionKeysDTO.TASK_ID] =
          context?.[userPermissionKeysAPIDTO.TASK_ID] || "";
      }

      if (context.hasOwnProperty(userPermissionKeysAPIDTO.ACTION_ID)) {
        contexts[userPermissionKeysDTO.ACTION_ID] =
          context?.[userPermissionKeysAPIDTO.ACTION_ID] || "";
      }

      return contexts;
    }) || []
});

const makePermissionsListDTO = (permissions = []) =>
  permissions?.map((permission) => ({
    [featurePermissionKeysDTO.ID]:
      permission?.[featurePermissionKeysAPIDTO.ID] || "",
    [featurePermissionKeysDTO.NAME]:
      permission?.[featurePermissionKeysAPIDTO.NAME] || "",
    [featurePermissionKeysDTO.DESCRIPTION]:
      permission?.[featurePermissionKeysAPIDTO.DESCRIPTION] || "",
    [featurePermissionKeysDTO.ROLE_IDS]:
      permission?.[featurePermissionKeysAPIDTO.ROLE_IDS] || []
  })) || [];

const makePermissionsListAPIDTO = (permissions = []) =>
  permissions?.map((permission) => ({
    [featurePermissionKeysAPIDTO.PERMISSION]:
      permission?.[featurePermissionKeysDTO.ID] || "",
    [featurePermissionKeysAPIDTO.ROLE_IDS]:
      permission?.[featurePermissionKeysDTO.ROLE_IDS] || []
  })) || [];

const makeUserPermissionsDTO = ({
  permissions = [],
  _makePermissionsDTO = makePermissionsDTO
} = {}) => ({
  permissions: permissions?.map(_makePermissionsDTO) || []
});

export {
  makePermissionsDTO,
  makePermissionsListDTO,
  makePermissionsListAPIDTO,
  makeUserPermissionsDTO
};
