import { state as makeState } from "./userEmailTemplates.state.store";

export const mutations = {
  setUserEmailTemplates(state, payload) {
    state.userEmailTemplates = payload;
  },
  setUserEmailTemplate(state, payload) {
    state.userEmailTemplate = payload;
  },
  resetUserEmailTemplates(state) {
    Object.assign(state, makeState());
  },
  resetUserEmailTemplate(state) {
    state.userEmailTemplate = {};
  },
  setIsLoadingUserEmailTemplates(state, payload) {
    state.isLoadingUserEmailTemplates = payload;
  }
};
