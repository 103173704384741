<template>
  <div class="corporate-linkage">
    <BaseInfoBox
      v-if="isDnbDisabled"
      :text="thirdPartyInfoMessages.ENABLE_PREMIUM_CORPORATE_DATA"
      data-test-id="corporate-linkage__disable-text--dnb-disabled"
      :icon-options="defaultIcons.INFO"
      :theme="themes.INFO"
    />
    <div v-else>
      <BaseInfoBox
        v-if="!isDnbVerified"
        data-test-id="corporate-linkage__disable-text--dnb-not-verified"
        :text="thirdPartyInfoMessages.ADD_PREMIUM_CORPORATE_DATA"
        :icon-options="defaultIcons.INFO"
        :theme="themes.INFO"
      />
      <div class="corporate-linkage__row" data-test-id="corporate-linkage__row">
        <div class="corporate-linkage__row-col">
          <StickyTable
            class="corporate-linkage__row-col-table"
            caption="Global ultimate organization"
            :table-headers="tableHeaders"
            :table-rows="globalOrganizationTableRows"
          />
          <StickyTable
            class="corporate-linkage__row-col-table"
            caption="Parent Organization"
            :table-headers="tableHeaders"
            :table-rows="parentOrganizationTableRows"
          />
        </div>
        <div class="corporate-linkage__row-col">
          <StickyTable
            class="corporate-linkage__row-col-table"
            caption="Domestic ultimate organization"
            :table-headers="tableHeaders"
            :table-rows="domesticOrganizationTableRows"
          />
          <StickyTable
            class="corporate-linkage__row-col-table"
            caption="Headquarters"
            :table-headers="tableHeaders"
            :table-rows="headQuarterTableRows"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StickyTable from "@/molecules/StickyTable/StickyTable";
import {
  typographySize,
  defaultIcons,
  themes,
  thirdPartyInfoMessages
} from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";
import { isString } from "@/utils";
import BaseInfoBox from "@/atoms/BaseInfoBox/BaseInfoBox";

export default {
  name: "CorporateLinkage",
  components: {
    StickyTable,
    BaseInfoBox
  },
  props: {
    corporateLinkageData: {
      type: Object,
      default: () => ({})
    },
    isDnbDisabled: {
      type: Boolean,
      default: false
    },
    isDnbVerified: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      typographySize,
      defaultIcons,
      themes,
      thirdPartyInfoMessages,
      tableHeaders: [
        {
          value: "label",
          styles: {
            display: "none"
          }
        },
        {
          value: "value",
          styles: {
            display: "none"
          }
        }
      ]
    };
  },
  computed: {
    globalOrganizationTableDataMap() {
      return this.generateDataMap(this.corporateLinkageData?.globalUltimate);
    },
    parentOrganizationTableDataMap() {
      return this.generateDataMap(this.corporateLinkageData?.parent);
    },
    domesticOrganizationTableDataMap() {
      return this.generateDataMap(this.corporateLinkageData?.domesticUltimate);
    },
    headQuarterTableDataMap() {
      return this.generateDataMap(this.corporateLinkageData?.headQuarter);
    },
    globalOrganizationTableRows() {
      return this.makeTableRowData(this.globalOrganizationTableDataMap);
    },
    parentOrganizationTableRows() {
      return this.makeTableRowData(this.parentOrganizationTableDataMap);
    },
    domesticOrganizationTableRows() {
      return this.makeTableRowData(this.domesticOrganizationTableDataMap);
    },
    headQuarterTableRows() {
      return this.makeTableRowData(this.headQuarterTableDataMap);
    }
  },
  methods: {
    generateDataMap(data) {
      return [
        { label: "Primary Name", value: data?.primaryName || "" },
        {
          label: "Street Address 1",
          value: data?.primaryAddress?.streetAddress?.line1 || ""
        },
        {
          label: "Street Address 2",
          value: data?.primaryAddress?.streetAddress?.line2 || ""
        },
        {
          label: "Street Address 3",
          value: data?.primaryAddress?.streetAddress?.line3 || ""
        },
        {
          label: "Street Address 4",
          value: data?.primaryAddress?.streetAddress?.line4 || ""
        },
        {
          label: "Country",
          value: data?.primaryAddress?.addressCountry?.name || ""
        },
        {
          label: "State/Region",
          value: data?.primaryAddress?.addressLocality?.name || ""
        },
        { label: "Postal Code", value: data?.primaryAddress?.postalCode || "" },
        {
          label: "Continent",
          value: data?.primaryAddress?.continentalRegion?.name || ""
        }
      ];
    },
    makeTableRowData(dataMap) {
      if (!dataMap?.length) {
        return [];
      }
      return dataMap.map(({ label, value }) => ({
        label: {
          component: BaseText,
          componentOptions: {
            tag: "span",
            text: label,
            size: typographySize.BODY_TEXT_BOLD
          },
          styles: {
            border: "none",
            width: "200px",
            verticalAlign: "baseline"
          }
        },
        value: {
          component: BaseText,
          componentOptions: {
            text: value,
            hasDefaultSpacingRemoved: isString(value)
          },
          styles: {
            border: "none"
          }
        }
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
.corporate-linkage {
  &__disable-text {
    text-align: center;
  }
  &__row {
    margin-top: 10px;
    &-col {
      display: flex;

      &-table {
        margin: 0 $spacing12 $spacing12;
      }
    }
  }
}
</style>
