import { tasksKeysDTO } from "@/constants/keys-constant/tasks.keys.constant";

const taskKeyMapper = {
  [tasksKeysDTO.COMPANY_TASK_ID]: "Id",
  [tasksKeysDTO.TEMPLATE_ID]: "Created from template",
  [tasksKeysDTO.CREATED_AT]: "Date created",
  [tasksKeysDTO.UPDATED_AT]: "Date updated",
  [tasksKeysDTO.DESCRIPTION]: "Description"
};

const taskStateKeys = {
  CLOSED: "CLOSED",
  TO_DO: "TO_DO",
  IN_PROGRESS: "IN_PROGRESS"
};

const taskStateValue = {
  [taskStateKeys.CLOSED]: "closed",
  [taskStateKeys.TO_DO]: "todo",
  [taskStateKeys.IN_PROGRESS]: "in progress"
};

const taskStateText = {
  [taskStateKeys.CLOSED]: "closed",
  [taskStateKeys.TO_DO]: "to do",
  [taskStateKeys.IN_PROGRESS]: "in progress"
};

export { taskKeyMapper, taskStateValue, taskStateText, taskStateKeys };
