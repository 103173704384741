<template>
  <div :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'">
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :isHidden="isHidden"
      :label="label"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
    />

    <!-- input content -->
    <b-collapse
      v-show="JSON.parse(!fieldData.isHidden)"
      id="collapse-1"
      :class="isPreview ? 'mt-0' : 'mt-5'"
      v-model="collapse"
    >
      <b-col>
        <h3 class="text-left">{{ fieldData.label[selectedLanguage] }}</h3>
        <ul
          :class="fieldData.selectedListStyle"
          v-if="fieldData.selectedListType === 1"
        >
          <li v-for="(item, index) in fieldData.items" :key="index">
            {{ item?.message?.[selectedLanguage] }}
          </li>
        </ul>
        <ol class="text-left" v-else>
          <li v-for="(item, index) in fieldData.items" :key="index">
            {{ item?.message?.[selectedLanguage] }}
          </li>
        </ol>
        <p class="text-muted text-left">
          {{ fieldData.description[selectedLanguage] }}
        </p>
      </b-col>
    </b-collapse>

    <b-modal
      cancel-title="Close"
      ok-title="save"
      @ok="onSaveData"
      size="lg"
      v-model="showSettings"
      @hidden="onUpdateSettings"
      scrollable
      title="Heading Settings"
    >
      <AdvanceSettings
        :fieldData="fieldData"
        v-on:toggle-field="showSettings = !showSettings"
        :sectionIndex="sectionIndex"
        :fieldIndex="fieldIndex"
      />
    </b-modal>
  </div>
</template>

<script>
import AdvanceSettings from "../advanceListItemSettingsComponent.vue";
import MenuButtons from "../menuButtons";
import { mapGetters, mapState, mapMutations } from "vuex";
import { EventBus } from "@/event-bus.js";
import { Helper } from "@/helpers";

export default {
  name: "ListItemGroup",
  components: {
    AdvanceSettings,
    MenuButtons
  },
  data() {
    return {
      isHidden: false,
      condition: {},
      showToolTip: false,
      label: undefined,
      placeholder: undefined,
      tooltipText: undefined,
      showSettings: false,
      collapse: true,
      liName: this.fieldData.label[this.selectedLanguage]
    };
  },
  mounted() {
    this.liName = this.fieldData.label[this.selectedLanguage];
  },
  created() {
    EventBus.on("onSubmitEndUserForm", () => {
      this.setFieldValue();
    });
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage"]),
    ...mapState(["toggleAllCoppalse"])
  },
  watch: {
    toggleAllCoppalse(value) {
      this.collapse = value;
    }
  },
  methods: {
    ...mapMutations(["TOGGLE_LOADER"]),

    onUpdateSettings() {
      this.TOGGLE_LOADER(2);
    },

    setFieldValue() {
      this.$store.state.userFormSubmitArray.forEach((submission) => {
        if (
          submission?.elements &&
          submission?.elementId === this.fieldData.id
        ) {
          submission.elementTitle = this.fieldData.label;
          submission.elementDescription = this.fieldData.description;
          submission.selectedListType = this.fieldData.selectedListType;
          submission.selectedListStyle = this.fieldData.selectedListStyle;
          submission.elements[0] = {
            postValue: this.fieldData.items
          };
        }
      });
    },

    setValue(e) {
      this.liName = e;
      this.fieldData.label[this.selectedLanguage] = this.liName;
    },

    onSaveData() {
      EventBus.emit("onSaveSettings", "saving settings");
      Helper.makeToast(this.$bvToast, "success", "Settings Updated");
    }
  },
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number
  }
};
</script>

<style scoped>
ul.a {
  text-align: left;
  list-style-type: decimal-leading-zero;
}

ul.b {
  text-align: left;
  list-style-type: upper-alpha;
}

ul.c {
  text-align: left;
  list-style-type: circle;
}

ul.d {
  text-align: left;
  list-style-type: square;
}
</style>
