<template>
  <div class="risk-alert-table" data-test-id="risk-alert-table">
    <BaseLoader
      v-if="loadingTableData"
      data-test-id="risk-alert-table__loader"
    />
    <template v-else>
      <StickyTable
        :table-headers="makeRiskAlertTableHeaders"
        :table-rows="riskAlertTableRows"
        @table-header-click="onTableHeaderClick"
      />
      <BaseText
        class="risk-alert-table__not-activated"
        v-if="!hasAlerts"
        text="There are no risks to display for this third party."
        tag="p"
        data-test-id="risk-alert-table__not-activated"
      />
    </template>
  </div>
</template>

<script>
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import BaseText from "@/atoms/BaseText/BaseText";
import {
  HTTP,
  makeAuthorizationHeader,
  makeStringCamelToSnakeCase
} from "@/utils";
import {
  endpoints,
  externalUrls,
  RISK_ALERT_HEADERS,
  sortOrder
} from "@/constants";
import StickyTable from "@/molecules/StickyTable/StickyTable";
import { makeRiskAlertDTO } from "@/organisms/RiskAlertTable/RiskAlertTable.dto";

export default {
  name: "RiskAlertTable",
  components: {
    StickyTable,
    BaseLoader,
    BaseText
  },
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    thirdPartyId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      greenliteLink: externalUrls.GREENLITE,
      loadingTableData: false,
      riskAlertTableRows: [],
      riskAlertTableHeaders: RISK_ALERT_HEADERS,
      currentPage: 1,
      totalRiskAlerts: 0,
      itemsPerPage: 50,
      columnPosition: 0,
      columnName: "",
      columnSortOrder: sortOrder.DESCENDING,
      activeSortColumn: false
    };
  },
  watch: {
    currentPage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchRiskAlerts();
      }
    }
  },
  computed: {
    hasAlerts() {
      return !!this.riskAlertTableRows?.length;
    },
    makeRiskAlertTableHeaders() {
      return this.riskAlertTableHeaders?.reduce((acc, header, index) => {
        const headerObj = {
          ...header,
          styles: {
            verticalAlign: "top"
          },
          sortable: true
        };
        if (this.activeSortColumn && index === this.columnPosition) {
          headerObj.sortOrder = this.columnSortOrder;
        }
        acc.push(headerObj);
        return acc;
      }, []);
    }
  },
  created() {
    this.fetchRiskAlerts();
  },
  methods: {
    makeQueryForFetchRiskAlerts() {
      return {
        sort: `${this.columnName}|${this.columnSortOrder}`
      };
    },
    makeRiskAlertParams() {
      return {
        companyId: this.companyId,
        thirdPartyId: this.thirdPartyId
      };
    },
    async fetchRiskAlerts() {
      this.loadingTableData = true;
      try {
        const response = await HTTP(
          "get",
          endpoints.ESG_RISK_ALERTS(this.makeRiskAlertParams()),
          null,
          makeAuthorizationHeader(),
          this.makeQueryForFetchRiskAlerts()
        );

        if (response?.data?.length) {
          this.makeRiskAlertTableRows(response.data);
        } else {
          this.resetTableData();
        }
      } catch (error) {
        this.resetTableData();
      }
    },
    makeRiskAlertTableRows(data) {
      this.riskAlertTableRows = data.map((risk) => ({
        styles: {
          verticalAlign: "top"
        },
        ...makeRiskAlertDTO(risk)
      }));
      this.loadingTableData = false;
    },
    resetTableData() {
      this.loadingTableData = false;
      this.riskAlertTableRows = [];
    },
    onChangeCurrentPage(value) {
      this.currentPage = parseInt(value);
    },
    onChangeItems(value) {
      this.itemsPerPage = parseInt(value);
    },
    onTableHeaderClick(columnIndex, columnSortOrder) {
      this.activeSortColumn = true;
      this.columnPosition = columnIndex;
      this.columnName = makeStringCamelToSnakeCase(
        this.riskAlertTableHeaders[columnIndex]?.value
      );
      this.columnSortOrder = columnSortOrder;
      this.fetchRiskAlerts();
    }
  }
};
</script>

<style lang="scss" scoped>
.risk-alert-table {
  @include page-container;

  &__table {
    margin-bottom: 20px;
    min-width: 500px;
  }

  &:deep(.risk-alert-table__not-activated) {
    text-align: center;
    margin-top: 15px;
  }
}
</style>
