import store from "@/store";
import {
  getterName,
  urls,
  featurePermissionsKeys,
  userPermissionKeysDTO,
  userViewType
} from "@/constants";

/**
 * @namespace Permissions
 */

/**
 * Assert user has permission for feature
 * @memberof Permissions
 * @typedef {function(object): Permissions} HasPermission
 * @param {object} parameter - provided object
 * @param {{string}} parameter.key - permission key
 * @param {{string}} parameter.contextId - permission context id to be matched
 * @param {{string}} parameter.contextKey - permission context key to be matched
 */

const hasPermission = ({
  permissions = store.state.permissions.permissions,
  key = "",
  contextId = "",
  contextKey = ""
}) => {
  if (store.getters[getterName.USER.IS_USER_SUPER_ADMIN]) return true;

  const permission = permissions?.find((permission) => permission?.id === key);

  if (permission && contextKey) {
    return !!permission.contexts.find(
      (context) => context?.[contextKey] === contextId
    );
  }

  return !!permission;
};

/**
 * Determine default landing page based on the available permissions
 * @memberof Permissions
 * @typedef {function(object): Permissions} GetDefaultLandingPageBasedOnPermissions
 * @param {object} parameter - provided object
 * @param {{HasPermission}} parameter._hasPermission - hasPermission method
 * @returns {string} - default url based on available permissions
 */

const getDefaultLandingPageBasedOnPermissions = ({
  _hasPermission = hasPermission
} = {}) => {
  if (
    _hasPermission({
      key: featurePermissionsKeys.VIEW_ANALYTICS
    })
  ) {
    return urls.ANALYTICS_OVERVIEW;
  }

  if (
    _hasPermission({
      key: featurePermissionsKeys.VIEW_MANAGE_THIRD_PARTIES
    })
  ) {
    return urls.MANAGE_THIRD_PARTY;
  }

  if (
    _hasPermission({
      key: featurePermissionsKeys.VIEW_EVENT_LOG
    })
  ) {
    return urls.EVENT_LOG;
  }

  const companyViews =
    store.getters[getterName.COMPANY.GET_COMPANY_VIEWS_WITH_PERMISSION];

  if (companyViews?.length) {
    const viewType =
      companyViews?.[0]?.viewType || companyViews?.[0]?.view_type;

    switch (parseInt(viewType)) {
      case userViewType.THIRD_PARTY:
        return urls.MANAGE_THIRD_PARTY_ID(companyViews?.[0]?.id);
      case userViewType.EVENT_LOG:
        return urls.EVENT_LOG_ID(companyViews?.[0]?.id);
      case userViewType.USER_VIEW_ANALYTICS:
        return urls.ANALYTICS_USER_VIEW_ID(companyViews?.[0]?.id);
    }
  }

  return urls.ACCESS_RESTRICTED;
};

/**
 * Validate that user has permission to access the provided landing page
 * @memberof Permissions
 * @typedef {function(object): Permissions} GetDynamicUserLandingPage
 * @param {object} parameter - provided object
 * @param {{string}} parameter.landingPage - landing page URL
 * @param {{HasPermission}} parameter._hasPermission - hasPermission method
 * @returns {string} - landing page url or empty string
 */

const getDynamicUserLandingPage = ({
  landingPage = "",
  _hasPermission = hasPermission
}) => {
  let dynamicLandingPage = "";
  const id = landingPage?.replace(/\D/g, "");
  const features = [
    {
      urls: [
        urls.ANALYTICS_OVERVIEW,
        urls.ANALYTICS_QUESTIONNAIRES_ID(id),
        urls.ANALYTICS_QUESTIONNAIRES_COMPARATIVE(id)
      ],
      key: featurePermissionsKeys.VIEW_ANALYTICS
    },
    {
      urls: [urls.MANAGE_THIRD_PARTY],
      key: featurePermissionsKeys.VIEW_MANAGE_THIRD_PARTIES
    },
    {
      urls: [urls.EVENT_LOG],
      key: featurePermissionsKeys.VIEW_EVENT_LOG
    },
    {
      urls: [
        urls.MANAGE_THIRD_PARTY_ID(id),
        urls.EVENT_LOG_ID(id),
        urls.ANALYTICS_USER_VIEW_ID(id)
      ],
      key: featurePermissionsKeys.VIEW_USER_VIEW,
      contextId: parseInt(id),
      contextKey: userPermissionKeysDTO.USER_VIEW_ID
    }
  ];

  for (let index = 0; index < features.length; index++) {
    const isMatchedURL = features[index].urls.some(
      (url) => url === landingPage
    );
    const hasURLPermission = _hasPermission({
      key: features[index].key,
      contextId: features[index].contextId,
      contextKey: features[index].contextKey
    });

    if (isMatchedURL && hasURLPermission) {
      dynamicLandingPage = landingPage;
      break;
    }
  }

  return dynamicLandingPage;
};

/**
 * Returns url for the determined page which user has permission to access
 * @memberof Permissions
 * @param {object} parameter - provided object
 * @param {{string}} parameter.landingPage - landing page URL
 * @param {{GetDynamicUserLandingPage }} parameter._getDynamicUserLandingPage  - getDynamicUserLandingPage method
 * @param {{GetDefaultLandingPageBasedOnPermissions}} parameter._getDefaultLandingPageBasedOnPermissions - getDefaultLandingPageBasedOnPermissions method
 * @returns {string} - determined landing page url.
 */

const getPermittedLandingPage = ({
  landingPage = "",
  _getDynamicUserLandingPage = getDynamicUserLandingPage,
  _getDefaultLandingPageBasedOnPermissions = getDefaultLandingPageBasedOnPermissions
}) =>
  _getDynamicUserLandingPage({
    landingPage
  }) || _getDefaultLandingPageBasedOnPermissions();

const hasValidCurrentCompanyIdInUrl = (companyId) =>
  store.state.company?.companyId === companyId || false;

export {
  hasPermission,
  hasValidCurrentCompanyIdInUrl,
  getPermittedLandingPage,
  getDynamicUserLandingPage,
  getDefaultLandingPageBasedOnPermissions
};
