<template>
  <div
    class="tab-pane fade show active"
    id="htmlinputs"
    role="tabpanel"
    aria-labelledby="home-tab"
  >
    <draggable
      :sort="false"
      :list="hmtlElements"
      :group="{ name: 'sections', pull: 'clone', put: false }"
      :clone="onClone"
      tag="ul"
    >
      <template #item="{ element }">
        <li
          class="list-group-item text-left pointer"
          :data-elementtype="element.inputType"
        >
          <font-awesome-icon
            :icon="element.icon"
            class="mr-2"
            v-if="element.icon"
          />
          {{ element.name }}
        </li>
      </template>
    </draggable>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { FormData } from "@/helpers";

draggable.compatConfig = { MODE: 3 };

export default {
  name: "HtmlFormComponents",
  components: {
    draggable
  },
  data() {
    return {
      hmtlElements: [
        // Video
        {
          name: "Video",
          inputType: "video",
          icon: "video"
        },
        // Image
        {
          name: "Image",
          inputType: "image",
          icon: "image"
        },
        // Paragraph
        {
          name: "Paragraph",
          inputType: "paragraph",
          icon: "pen"
        },
        // H1
        {
          name: "H1",
          inputType: "h1",
          icon: "pen"
        },
        {
          name: "H2",
          inputType: "h2",
          icon: "pen"
        },
        {
          name: "H3",
          inputType: "h3",
          icon: "pen"
        },
        {
          name: "Hyperlink",
          inputType: "link",
          icon: "pen"
        },
        {
          name: "List item",
          inputType: "ulli",
          icon: "list"
        },
        {
          name: "Divider",
          inputType: "hr",
          icon: "edit"
        }
      ]
    };
  },
  computed: {},
  methods: {
    onClone(e) {
      let data = FormData.htmlData(e.inputType);
      return {
        ...data
      };
    }
  },

  props: {}
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
</style>
