import {
  destinationTypes,
  destinationKeysDTO,
  destinationKeysAPIDTO,
  variableScope
} from "@/constants";

const makeDestinationEmailDTO = (destination) => ({
  [destinationKeysDTO.TYPE]: destination?.[destinationKeysAPIDTO.TYPE],
  [destinationKeysDTO.VALUE]: {
    [destinationKeysDTO.EMAIL]:
      destination?.[destinationKeysAPIDTO.VALUE]?.[destinationKeysAPIDTO.EMAIL]
  }
});
const makeDestinationEmailAPIDTO = (destination) => ({
  [destinationKeysAPIDTO.TYPE]: destination?.[destinationKeysDTO.TYPE],
  [destinationKeysAPIDTO.VALUE]: {
    [destinationKeysAPIDTO.EMAIL]:
      destination?.[destinationKeysDTO.VALUE]?.[destinationKeysDTO.EMAIL]
  }
});

const makeDestinationRoleDTO = (destinationsRole) => ({
  [destinationKeysDTO.TYPE]: destinationsRole?.[destinationKeysAPIDTO.TYPE],
  [destinationKeysDTO.VALUE]: {
    [destinationKeysDTO.COMPANY_ID]:
      destinationsRole?.[destinationKeysAPIDTO.VALUE]?.[
        destinationKeysAPIDTO.COMPANY_ID
      ],
    [destinationKeysDTO.ROLE_ID]:
      destinationsRole?.[destinationKeysAPIDTO.VALUE]?.[
        destinationKeysAPIDTO.ROLE_ID
      ]
  }
});
const makeDestinationRoleAPIDTO = (destinationsRole) => ({
  [destinationKeysAPIDTO.TYPE]: destinationsRole?.[destinationKeysDTO.TYPE],
  [destinationKeysAPIDTO.VALUE]: {
    [destinationKeysAPIDTO.COMPANY_ID]:
      destinationsRole?.[destinationKeysDTO.VALUE]?.[
        destinationKeysDTO.COMPANY_ID
      ],
    [destinationKeysAPIDTO.ROLE_ID]:
      destinationsRole?.[destinationKeysDTO.VALUE]?.[destinationKeysDTO.ROLE_ID]
  }
});

const makeDestinationUserDTO = (destinationsUser) => ({
  [destinationKeysDTO.TYPE]: destinationsUser?.[destinationKeysAPIDTO.TYPE],
  [destinationKeysDTO.VALUE]: {
    [destinationKeysDTO.USER_ID]:
      destinationsUser?.[destinationKeysAPIDTO.VALUE]?.[
        destinationKeysAPIDTO.USER_ID
      ]
  }
});
const makeDestinationUserAPIDTO = (destinationsUser) => ({
  [destinationKeysAPIDTO.TYPE]: destinationsUser?.[destinationKeysDTO.TYPE],
  [destinationKeysAPIDTO.VALUE]: {
    [destinationKeysAPIDTO.USER_ID]:
      destinationsUser?.[destinationKeysDTO.VALUE]?.[destinationKeysDTO.USER_ID]
  }
});

const makeDestinationLocalVariableAPIDTO = ({ valueDataType, value }) => ({
  [destinationKeysAPIDTO.DATA]: {
    [destinationKeysAPIDTO.DATA]: {
      [destinationKeysAPIDTO.DATA]: {
        [destinationKeysAPIDTO.NAME]: value
      },
      [destinationKeysAPIDTO.TYPE]: valueDataType
    },
    [destinationKeysAPIDTO.TYPE]: variableScope.LOCAL
  },
  [destinationKeysAPIDTO.TYPE]: destinationTypes.VARIABLE
});
const makeDestinationEntityVariableAPIDTO = ({
  value = "",
  entities = []
} = {}) => {
  const entity = entities?.find(({ data }) => data.data.name === value) || {};
  return {
    [destinationKeysAPIDTO.DATA]: {
      [destinationKeysAPIDTO.DATA]: {
        [destinationKeysAPIDTO.DATA]: {
          ...(!!entity && entity?.data?.data)
        },
        [destinationKeysAPIDTO.TYPE]: entity?.data?.type
      },
      [destinationKeysAPIDTO.TYPE]: entity?.type
    },
    [destinationKeysAPIDTO.TYPE]: destinationTypes.VARIABLE
  };
};
const makeDestinationVariableDTO = (emailDestination) => ({
  [destinationKeysDTO.TYPE]: emailDestination?.[destinationKeysDTO.TYPE],
  [destinationKeysDTO.DATA]: {
    [destinationKeysDTO.DATA]: {
      [destinationKeysDTO.DATA]: {
        [destinationKeysDTO.NAME]:
          emailDestination?.[destinationKeysAPIDTO.DATA]?.[
            destinationKeysAPIDTO.DATA
          ]?.[destinationKeysAPIDTO.DATA]?.[destinationKeysAPIDTO.NAME]
      },
      [destinationKeysDTO.TYPE]:
        emailDestination?.[destinationKeysAPIDTO.DATA]?.[
          destinationKeysAPIDTO.DATA
        ]?.[destinationKeysAPIDTO.TYPE]
    },
    [destinationKeysDTO.TYPE]:
      emailDestination?.[destinationKeysAPIDTO.DATA]?.[
        destinationKeysAPIDTO.TYPE
      ]
  }
});

const makeBaseDestinationDTO = ({
  destinations = [],
  _makeDestinationEmailDTO = makeDestinationEmailDTO,
  _makeDestinationRoleDTO = makeDestinationRoleDTO,
  _makeDestinationUserDTO = makeDestinationUserDTO
} = {}) =>
  destinations?.map((destination) => {
    if (destination[destinationKeysAPIDTO.TYPE] === destinationTypes.EMAIL) {
      return _makeDestinationEmailDTO(destination);
    } else if (
      destination[destinationKeysAPIDTO.TYPE] === destinationTypes.ROLE
    ) {
      return _makeDestinationRoleDTO(destination);
    } else if (
      destination[destinationKeysAPIDTO.TYPE] === destinationTypes.USER
    ) {
      return _makeDestinationUserDTO(destination);
    }
  }) || [];
const makeBaseDestinationAPIDTO = ({
  destinations = [],
  _makeDestinationEmailAPIDTO = makeDestinationEmailAPIDTO,
  _makeDestinationRoleAPIDTO = makeDestinationRoleAPIDTO,
  _makeDestinationUserAPIDTO = makeDestinationUserAPIDTO
} = {}) =>
  destinations?.map((destination) => {
    if (destination[destinationKeysDTO.TYPE] === destinationTypes.EMAIL) {
      return _makeDestinationEmailAPIDTO(destination);
    } else if (destination[destinationKeysDTO.TYPE] === destinationTypes.ROLE) {
      return _makeDestinationRoleAPIDTO(destination);
    } else if (destination[destinationKeysDTO.TYPE] === destinationTypes.USER) {
      return _makeDestinationUserAPIDTO(destination);
    }
  }) || [];

const makeDestinationAPIDTO = ({
  emailDestinations = [],
  entities = [],
  _makeDestinationEmailAPIDTO = makeDestinationEmailAPIDTO,
  _makeDestinationRoleAPIDTO = makeDestinationRoleAPIDTO,
  _makeDestinationUserAPIDTO = makeDestinationUserAPIDTO,
  _makeDestinationEntityVariableAPIDTO = makeDestinationEntityVariableAPIDTO,
  _makeDestinationLocalVariableAPIDTO = makeDestinationLocalVariableAPIDTO
} = {}) =>
  emailDestinations?.reduce((acc, emailDestination) => {
    const { type, isEntity, isVariable, companyId, selected, value } =
      emailDestination;
    if (selected) {
      if (type === destinationTypes.EMAIL) {
        acc.push(
          _makeDestinationEmailAPIDTO({
            [destinationKeysDTO.TYPE]: destinationTypes.EMAIL,
            [destinationKeysDTO.VALUE]: {
              [destinationKeysDTO.EMAIL]: value
            }
          })
        );
      } else if (type === destinationTypes.ROLE) {
        acc.push(
          _makeDestinationRoleAPIDTO({
            [destinationKeysDTO.TYPE]: destinationTypes.ROLE,
            [destinationKeysDTO.VALUE]: {
              [destinationKeysDTO.COMPANY_ID]: companyId,
              [destinationKeysDTO.ROLE_ID]: value
            }
          })
        );
      } else if (type === destinationTypes.USER) {
        acc.push(
          _makeDestinationUserAPIDTO({
            [destinationKeysDTO.TYPE]: destinationTypes.USER,
            [destinationKeysDTO.VALUE]: {
              [destinationKeysDTO.USER_ID]: value
            }
          })
        );
      } else if (isVariable) {
        if (isEntity) {
          acc.push(
            _makeDestinationEntityVariableAPIDTO({
              value,
              entities
            })
          );
        } else {
          acc.push(
            _makeDestinationLocalVariableAPIDTO({
              value,
              valueDataType: type
            })
          );
        }
      }
    }

    return acc;
  }, []) || [];
const makeDestinationDTO = ({
  emailDestinations = [],
  _makeDestinationEmailDTO = makeDestinationEmailDTO,
  _makeDestinationRoleDTO = makeDestinationRoleDTO,
  _makeDestinationUserDTO = makeDestinationUserDTO,
  _makeDestinationVariableDTO = makeDestinationVariableDTO
} = {}) =>
  emailDestinations?.map((emailDestination) => {
    if (
      emailDestination[destinationKeysAPIDTO.TYPE] === destinationTypes.EMAIL
    ) {
      return _makeDestinationEmailDTO(emailDestination);
    } else if (
      emailDestination[destinationKeysAPIDTO.TYPE] === destinationTypes.ROLE
    ) {
      return _makeDestinationRoleDTO(emailDestination);
    } else if (
      emailDestination[destinationKeysAPIDTO.TYPE] === destinationTypes.USER
    ) {
      return _makeDestinationUserDTO(emailDestination);
    } else if (
      emailDestination[destinationKeysAPIDTO.TYPE] === destinationTypes.VARIABLE
    ) {
      return _makeDestinationVariableDTO(emailDestination);
    }
  }) || [];

export {
  makeDestinationEmailDTO,
  makeDestinationEmailAPIDTO,
  makeDestinationRoleDTO,
  makeDestinationRoleAPIDTO,
  makeDestinationUserDTO,
  makeDestinationUserAPIDTO,
  makeDestinationLocalVariableAPIDTO,
  makeDestinationEntityVariableAPIDTO,
  makeDestinationVariableDTO,
  makeBaseDestinationDTO,
  makeBaseDestinationAPIDTO,
  makeDestinationAPIDTO,
  makeDestinationDTO
};
