<template>
  <div class="profile-header">
    <BaseIcon
      class="profile-header__logo"
      v-if="iconOptions.icon"
      v-bind="iconOptions"
    />
    <div class="profile-header__heading-wrapper">
      <BaseText
        class="profile-header__heading-wrapper--heading"
        :size="typographySize.DISPLAY_TEXT"
        tag="h2"
        :text="heading"
      />
      <BaseText
        class="profile-header__heading-wrapper--heading"
        :text="subheading"
        hasDefaultSpacingRemoved
      />
    </div>
    <CallToAction
      v-if="isCTAVisible"
      class="profile-header__button"
      v-bind="combinedCTAOptions"
      @click="handleClick"
    />
  </div>
</template>

<script>
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import BaseText from "@/atoms/BaseText/BaseText";
import { themes, typographySize, icons, CTAEvents } from "@/constants";

export default {
  name: "ProfileHeader",
  components: {
    BaseIcon,
    BaseText,
    CallToAction
  },
  props: {
    heading: {
      type: String,
      default: ""
    },
    subheading: {
      type: String,
      default: ""
    },
    CTAOptions: {
      type: Object,
      default: () => ({})
    },
    iconOptions: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      typographySize,
      themes,
      icons
    };
  },
  computed: {
    isCTAVisible() {
      return !!(this.CTAOptions.value || this.CTAOptions.icon);
    },
    combinedCTAOptions() {
      return {
        iconSpace: "none",
        hasIconWithText: true,
        ...this.CTAOptions
      };
    }
  },
  methods: {
    handleClick(event) {
      this.$emit(CTAEvents.CLICK_CTA, event);
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-header {
  display: flex;
  margin: 30px 0px;
  align-items: baseline;

  &__button {
    margin-right: 30px;
    margin-left: auto;
    height: fit-content;
    padding: 3px 8px;
  }

  &__heading-wrapper {
    text-align: left;

    &--heading {
      margin-left: 15px;
    }
  }
}
</style>
