<template>
  <div class="bracket-section">
    <div
      v-for="({ bracket, operator }, index) in bracketSection"
      :key="`bracket-${index}`"
    >
      <Select
        v-if="isOperatorVisible(index, operator)"
        :id="`bracket-section-operator-${index}`"
        :key="`bracket-section-operator-${index}`"
        :options="operator.options"
        :error="operator.error"
        @change="
          $emit('change', {
            index,
            property: actionKeys.OPERATOR,
            event: $event
          })
        "
        class="bracket-section__bracket-operator"
        data-test-id="bracket-section__bracket-operator"
      />
      <div
        :key="`bracket-section-${index}`"
        class="bracket-section__bracket"
        data-test-id="bracket-section__bracket"
        @mouseenter="setShowDeleteStep(index, true)"
        @mouseleave="setShowDeleteStep(index, false)"
        @focusin="setShowDeleteStep(index, true)"
        @focusout="setShowDeleteStep(index, false)"
        tabindex="0"
      >
        <BaseAddDeleteLine
          v-if="showDeleteStep[index]"
          class="
            bracket-section__bracket-action
            bracket-section__bracket-action--remove
          "
          data-test-id="bracket-section__bracket-action--remove"
          :position="position.LEFT"
          :type="operations.DELETE"
          @click="deleteBracket(index)"
          tabindex="0"
        />
        <!-- eslint-disable-next-line vue/require-component-is -->
        <component
          v-if="bracket"
          v-bind="bracket.componentOptions"
          data-test-id="bracket-section__bracket-component"
          :is="bracket.component"
          @change="
            $emit('change', {
              index,
              property: actionKeys.COMPONENT,
              event: $event
            })
          "
        />
      </div>
      <BaseAddDeleteLine
        class="
          bracket-section__bracket-action bracket-section__bracket-action--add
        "
        data-test-id="bracket-section__bracket-action--add"
        :is-hidden="!showAddStep[index]"
        :type="operations.ADD"
        @mouseenter="setShowAddStep(index, true)"
        @mouseleave="setShowAddStep(index, false)"
        @focusin="setShowAddStep(index, true)"
        @focusout="setShowAddStep(index, false)"
        @click="
          $emit('change', {
            eventType: operations.ADD_BRACKET,
            index: index + 1
          })
        "
        tabindex="0"
      />
    </div>
  </div>
</template>

<script>
import BaseAddDeleteLine from "@/atoms/BaseAddDeleteLine/BaseAddDeleteLine";
import Select from "@/molecules/Select/Select";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import CalculationBracket from "@/molecules/CalculationBracket/CalculationBracket";
import { actionKeys, operations, position } from "@/constants";

export default {
  name: "BracketSection",
  components: { CalculationBracket, BaseIcon, BaseAddDeleteLine, Select },
  props: {
    bracketSection: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      position,
      operations,
      actionKeys,
      showAddStep: [],
      showDeleteStep: []
    };
  },
  watch: {
    bracketSection: {
      deep: true,
      handler() {
        this.setStepHoverStates();
      }
    }
  },
  computed: {
    isOnlyBracket() {
      return this.bracketSection?.length === 1;
    }
  },
  created() {
    this.setStepHoverStates();
  },
  methods: {
    isOperatorVisible(index, operator) {
      return !!(operator?.options?.length && index !== 0);
    },
    setStepHoverStates() {
      this.showAddStep = this.bracketSection.map(() => false);
      this.showDeleteStep = this.bracketSection.map(() => false);
    },
    setShowAddStep(index, value) {
      this.showAddStep.splice(index, 1, value);
    },
    setShowDeleteStep(index, value) {
      if (!this.isOnlyBracket) {
        this.showDeleteStep.splice(index, 1, value);
      }
    },
    deleteBracket(index) {
      if (!this.isOnlyBracket) {
        this.$emit("change", { eventType: operations.DELETE_BRACKET, index });
      } else {
        this.showDeleteStep = [false];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bracket-section {
  &__bracket {
    position: relative;
    padding: 17px 0 0 15px;
    border-left: 2px dashed $black;
    margin: 25px 0;

    &:before,
    &:after {
      position: absolute;
      display: block;
      content: "(";
      top: -22px;
      left: -5px;
      @include body-text;
      line-height: 1em;
    }

    &:after {
      content: ")";
      top: initial;
      bottom: -22px;
      left: -2px;
    }

    &-operator {
      padding-bottom: 15px;
      margin-bottom: 0;
    }

    &-action--add {
      margin-bottom: 5px;
    }
  }
}
</style>
