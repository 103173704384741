const posthogEvents = {
  PAGEVIEW: "$pageview",
  PAGEVIEW_MODAL: "$pageview-modal"
};

const defaultRegisterProperties = {
  PLATFORM_NAME: "platformName",
  PLATFORM_VERSION: "platformVersion"
};

export { posthogEvents, defaultRegisterProperties };
