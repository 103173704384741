import { EventBus } from "@/event-bus.js";

class Helper {
  validateSection(state, sectionFour, sectionSix, sectionSeven) {
    let foundScore;
    let groupCountr;
    let eleNewCombo;
    let foundCombo = sectionSix.formElements.find(
      (a) => a.inputType == "comboSelectProgressive"
    );
    eleNewCombo = state.userFormSubmitArray.find(
      (ele) => ele.elementId == foundCombo.id
    );
    if (eleNewCombo && eleNewCombo.elements) {
      groupCountr = eleNewCombo.elements.find((ctr) => ctr.score == 16);
    }
    let eleNew = state.userFormSubmitArray.find(
      (ele) => ele.elementId == sectionFour.formElements[0].id
    );
    if (eleNew && eleNew.elements.length) {
      foundScore = eleNew.elements.find((ele) => ele.score == 13);
    }
    if (groupCountr && foundScore) {
      let sec = state.form1.formSection[sectionSeven.index];
      Object.assign(sec, (sec.isHidden = false));
      EventBus.emit(
        "sectionCheck",
        state.form1.formSection[sectionSeven.index]
      );
      state.form1.formSection[sectionSeven.index].formElements.map(() => {
        // ele.validation.required = 1;
        // ele.isInValid = true;
      });
    } else {
      let sec = state.form1.formSection[sectionSeven.index];
      Object.assign(sec, (sec.isHidden = true));
      EventBus.emit(
        "sectionCheck",
        state.form1.formSection[sectionSeven.index]
      );
      state.form1.formSection[sectionSeven.index].formElements.map((ele) => {
        ele.validation.required = 0;
        ele.isInValid = false;
        let foundEle = state.userFormSubmitArray.find(
          (ele1) => ele1.elementId == ele.id
        );
        if (foundEle) {
          foundEle.elements = [];
        }
      });
    }
  }
}

export default new Helper();
