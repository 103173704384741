import { isLessThanOrEqualToMaxLength, isRequired } from "@/utils";
import { destinationTypes } from "@/constants";
import { makeLiteralOptionForMultiSelect } from "@/molecules/MultiSelect/MultiSelect.dto";

const validateUserEmailTemplateText = (text) => {
  if (!isRequired(text)) {
    return "required";
  } else if (!isLessThanOrEqualToMaxLength(text, 255)) {
    return "maximum of 255 characters";
  } else {
    return "";
  }
};

const validateUserEmailTemplateBody = (userEmailTemplateEmailBody) => {
  if (!isRequired(userEmailTemplateEmailBody)) {
    return "required";
  } else {
    return "";
  }
};

const makeDestinationList = ({ options = [] } = {}) =>
  options
    ?.filter(({ selected }) => selected)
    ?.reduce((acc, option) => {
      if (option.type === destinationTypes.ROLE) {
        acc.push({
          type: destinationTypes.ROLE,
          value: {
            roleId: option.value,
            companyId: option.companyId
          }
        });
      } else if (option.type === destinationTypes.USER) {
        acc.push({
          type: destinationTypes.USER,
          value: {
            userId: option.value
          }
        });
      } else if (option.type === destinationTypes.EMAIL) {
        acc.push({
          type: destinationTypes.EMAIL,
          value: {
            email: option.value
          }
        });
      }

      return acc;
    }, []);

const makeErrorMessagesApi = (error = {}) => {
  const description = error?.response?.status
    ? ` with status ${error?.response?.status}`
    : "";
  return `An error has occurred${description}, when trying to submit. Please contact support.`;
};

const getStepCount = (index) => `${index + 1}.`;

const getSelectedValuesFromDestinationKey = (list = []) => {
  const destinationEmails = {
    selectedValues: [],
    literalEmailOptions: []
  };
  if (Array.isArray(list)) {
    return list.reduce((acc, { type, value, data }) => {
      if (type === destinationTypes.EMAIL) {
        acc.selectedValues.push(value?.email);
        acc.literalEmailOptions.push(
          makeLiteralOptionForMultiSelect({
            text: value?.email,
            value: value?.email,
            type
          })
        );
      } else if (type === destinationTypes.USER) {
        acc.selectedValues.push(value?.userId);
      } else if (type === destinationTypes.ROLE) {
        acc.selectedValues.push(value?.roleId);
      } else if (type === destinationTypes.VARIABLE) {
        acc.selectedValues.push(data?.data?.data?.name);
      }

      return acc;
    }, destinationEmails);
  } else {
    return destinationEmails;
  }
};

export {
  validateUserEmailTemplateText,
  validateUserEmailTemplateBody,
  makeDestinationList,
  makeErrorMessagesApi,
  getStepCount,
  getSelectedValuesFromDestinationKey
};
