<template>
  <div class="form-row-wrapper">
    <template v-if="formRowList.length">
      <ul class="form-row-wrapper__list">
        <li
          v-for="{ component, id, componentOptions, customText } in formRowList"
          :key="id"
          class="form-row-wrapper__list-item"
        >
          <component v-bind="componentOptions" :is="component">
            {{ customText }}
          </component>
        </li>
      </ul>
      <div v-if="editCtaList.length" class="form-row-wrapper__edit">
        <component
          v-for="{ component, id, componentOptions } in editCtaList"
          v-bind="componentOptions"
          :key="id"
          class="form-row-wrapper__edit--cta"
          :is="component"
        />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "FormRow",
  props: {
    formRowList: {
      type: Array,
      default: () => []
    },
    editCtaList: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.form-row-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &__list {
    display: inline-flex;
    max-width: 900px;
    width: 100%;
    padding: 0;

    &-item {
      display: inline-flex;
      max-width: 250px;
      width: 100%;
      margin: 0 25px 0 0;
      vertical-align: top;

      &--center-vertically {
        display: flex;
        align-items: center;
      }
    }
  }

  &__edit {
    align-self: center;
  }
}
</style>
