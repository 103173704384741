import { themes, thirdPartyTrainingStatuses } from "@/constants";

const getTrainingStatusTheme = (status) => {
  switch (status) {
    case thirdPartyTrainingStatuses.NOT_STARTED:
      return themes.LIGHT_GREY;

    case thirdPartyTrainingStatuses.IN_PROGRESS:
      return themes.WEAK;

    case thirdPartyTrainingStatuses.CANCELLED:
      return themes.LIGHT_GREY;

    case thirdPartyTrainingStatuses.COMPLETED:
      return themes.SUCCESS;

    case thirdPartyTrainingStatuses.OVERDUE:
      return themes.ERROR;

    case thirdPartyTrainingStatuses.ARCHIVED:
      return themes.LIGHT_GREY;

    default:
      return themes.NONE;
  }
};

export { getTrainingStatusTheme };
