<template>
  <div class="base-grid-component-wrapper" :style="getParentStyles()">
    <component
      v-if="getComponent()"
      v-bind="getComponentOptions()"
      :is="getComponent()"
      data-test-id="base-grid-component-wrapper__component"
    >
      {{ getSlotData() }}
    </component>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseGridComponentWrapper",
  methods: {
    getComponentOptions() {
      return this.params?.value.componentOptions || null;
    },
    getComponent() {
      return this.params?.value?.component || null;
    },
    getSlotData() {
      return this.params?.value?.slotData || null;
    },
    getParentStyles() {
      return this.params?.value?.parentStyles || null;
    }
  }
});
</script>

<style lang="scss" scoped>
.base-grid-component-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  position: relative;
}
</style>
