<template>
  <div class="user-training-template">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "UserTrainingTemplate"
};
</script>

<style scoped lang="scss">
.user-training-template {
  @include page-container;
  max-width: 1400px;
  text-align: left;
}
</style>
