import { productVersionsKeysDTO } from "@/constants";

const serviceVersionMapper = {
  [productVersionsKeysDTO.EB360]: "EB360",
  [productVersionsKeysDTO.ERAF_ACTIONS]: "ERAF Actions",
  [productVersionsKeysDTO.ERAF_AUTOMATIONS]: "ERAF Automations",
  [productVersionsKeysDTO.ERAF_TASKS]: "ERAF Tasks",
  [productVersionsKeysDTO.CORPORATE_DATA]: "Corporate Data",
  [productVersionsKeysDTO.GREENLITE_ANALYTICS]: "Greenlite Analytics",
  [productVersionsKeysDTO.GREENLITE_SCORING]: "Greenlite Scoring",
  [productVersionsKeysDTO.ROLES_AND_PERMISSIONS]: "Roles and Permissions",
  [productVersionsKeysDTO.TRAINING]: "Training",
  [productVersionsKeysDTO.NOTIFICATIONS]: "Notifications",
  [productVersionsKeysDTO.PRODUCT_AND_PLATFORM]: "Product and platform"
};

export { serviceVersionMapper };
