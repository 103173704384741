import { viewsService } from "@/services";
import { PyramidEmbedClient } from "@pyramid-embed/embed-js";

const authenticatePyramidUser = async (companyId) => {
  try {
    const domain = window.location.hostname;
    const { data = {} } = await viewsService.fetchPlatformPyramidToken({
      companyId,
      domain
    });
    const { token = "", domain: serviceDomain = "" } = data;
    const pyramid = new PyramidEmbedClient(serviceDomain);

    if (token) {
      pyramid.setAuthToken(token);
    }

    return pyramid;
  } catch {
    return null;
  }
};

export { authenticatePyramidUser };
