<template>
  <div
    :class="[
      'rating-custom-attribute',
      `rating-custom-attribute--text-align-${textAlign}`
    ]"
    data-test-id="rating-custom-attribute"
  >
    <IconWithText
      v-if="isIconSubtype"
      v-bind="getRiskRatingIconWithText(value)"
      class="rating-custom-attribute__icon"
      data-test-id="rating-custom-attribute__icon"
    />
    <div
      v-else
      :class="[
        'rating-custom-attribute__numeric',
        `rating-custom-attribute__numeric-theme--${getTheme()}`
      ]"
      data-test-id="rating-custom-attribute__numeric"
    >
      <BaseText
        :theme="getTheme()"
        :text="value"
        :size="typographySize.LARGE_TEXT_BOLD"
        tag="span"
        data-test-id="rating-custom-attribute__numeric_text"
      />
    </div>
  </div>
</template>

<script>
import {
  customAttributeRiskScales,
  numericScale,
  ratingTypes,
  textAlign,
  themes,
  typographySize
} from "@/constants";
import { getRiskRatingIconWithText } from "@/utils";

import BaseText from "@/atoms/BaseText/BaseText";
import IconWithText from "@/molecules/IconWithText/IconWithText";

export default {
  name: "RatingCustomAttribute",
  components: {
    BaseText,
    IconWithText
  },
  props: {
    value: {
      type: String,
      required: true
    },
    textAlign: {
      type: String,
      default: textAlign.CENTER
    },
    scaleType: {
      type: String,
      default: customAttributeRiskScales.ELEVEN_POINT_SCALE
    }
  },
  data() {
    return {
      typographySize,
      getRiskRatingIconWithText
    };
  },
  computed: {
    isIconSubtype() {
      return Number.isNaN(parseInt(this.value));
    }
  },
  methods: {
    getElevenPointScaleTheme() {
      if (parseInt(this.value) === numericScale.NUMERIC_VALUE_0) {
        return themes.GREY;
      } else if (parseInt(this.value) < numericScale.NUMERIC_VALUE_7) {
        return themes.SUCCESS;
      } else if (parseInt(this.value) < numericScale.NUMERIC_VALUE_9) {
        return themes.WARNING;
      } else {
        return themes.ERROR;
      }
    },
    getTenPointScaleTheme() {
      if (parseInt(this.value) < numericScale.NUMERIC_VALUE_5) {
        return themes.SUCCESS;
      } else if (parseInt(this.value) < numericScale.NUMERIC_VALUE_8) {
        return themes.WARNING;
      } else {
        return themes.ERROR;
      }
    },
    getEthixbaseRiskScaleTheme() {
      if (this.value === ratingTypes.LOW) {
        return themes.SUCCESS;
      } else if (this.value === ratingTypes.MODERATE) {
        return themes.WARNING;
      } else if (this.value === ratingTypes.HIGH) {
        return themes.ERROR;
      } else if (this.value === ratingTypes.DO_NOT_ENGAGE) {
        return themes.INVERSE;
      }
      return themes.GREY;
    },
    getFourPointRiskScaleTheme() {
      if (this.value === ratingTypes.LOW) {
        return themes.SUCCESS;
      } else if (this.value === ratingTypes.MODERATE) {
        return themes.WARNING;
      } else if (this.value === ratingTypes.HIGH) {
        return themes.ERROR;
      }
      return themes.GREY;
    },
    getTheme() {
      switch (this.scaleType) {
        case customAttributeRiskScales.TEN_POINT_SCALE:
          return this.getTenPointScaleTheme();
        case customAttributeRiskScales.ELEVEN_POINT_SCALE:
          return this.getElevenPointScaleTheme();
        case customAttributeRiskScales.ETHIXBASE_RISK_SCALE:
          return this.getEthixbaseRiskScaleTheme();
        case customAttributeRiskScales.FOUR_POINT_SCALE:
          return this.getFourPointRiskScaleTheme();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.rating-custom-attribute {
  &__numeric {
    display: inline-flex;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    padding: 0;

    @each $theme, $primary-color, $secondary-color, $has-border in $themes {
      &-theme--#{$theme} {
        background-color: $primary-color;

        @if $has-border {
          border: $element-border;
        }
      }
    }
  }

  &--text-align-center {
    text-align: center;
  }

  &--text-align-left {
    text-align: left;
  }

  &--text-align-right {
    text-align: right;
  }
}
</style>
