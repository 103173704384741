import { makeUUID } from "@/utils";
import { actionStepSubType } from "@/constants";

export class EmailNotificationClass {
  id = makeUUID();

  stepType = actionStepSubType.EMAIL;

  comment = "";

  hasComment = false;

  emailNotificationOptions = [];

  emailNotificationError = "";

  emailNotificationSearchValue = "";

  emailNotificationToDestinationOptions = [];

  emailNotificationToAmendable = [];

  emailNotificationToSelectedValues = [];

  userEmailTemplateToValues = [];

  emailDestinationToError = "";

  emailNotificationCcDestinationOptions = [];

  emailNotificationCcAmendable = [];

  emailNotificationCcSelectedValues = [];

  userEmailTemplateCcValues = [];

  emailDestinationCcError = "";

  emailNotificationBccDestinationOptions = [];

  emailNotificationBccAmendable = [];

  emailNotificationBccSelectedValues = [];

  userEmailTemplateBccValues = [];

  emailDestinationBccError = "";

  constructor({
    comment = "",
    hasComment = false,
    emailNotificationOptions = [],
    emailNotificationError = "",
    emailNotificationSearchValue = "",
    emailNotificationExpectedData = [],
    emailNotificationToDestinationOptions = [],
    emailNotificationToAmendable = [],
    emailNotificationToSelectedValues = [],
    userEmailTemplateToValues = [],
    emailDestinationToError = "",
    emailNotificationCcDestinationOptions = [],
    emailNotificationCcAmendable = [],
    emailNotificationCcSelectedValues = [],
    userEmailTemplateCcValues = [],
    emailDestinationCcError = "",
    emailNotificationBccDestinationOptions = [],
    emailNotificationBccAmendable = [],
    emailNotificationBccSelectedValues = [],
    userEmailTemplateBccValues = [],
    emailDestinationBccError = ""
  }) {
    this.setComment(comment);
    this.setHasComment(hasComment);
    this.setEmailNotificationOptions(emailNotificationOptions);
    this.setEmailNotificationError(emailNotificationError);
    this.setEmailNotificationSearchValue(emailNotificationSearchValue);
    this.setEmailNotificationExpectedDataList(emailNotificationExpectedData);
    this.setEmailNotificationToDestinationOptions(
      emailNotificationToDestinationOptions
    );
    this.setEmailNotificationToAmendable(emailNotificationToAmendable);
    this.setEmailNotificationToSelectedValues(
      emailNotificationToSelectedValues
    );
    this.setUserEmailTemplateToValues(userEmailTemplateToValues);
    this.setEmailDestinationToError(emailDestinationToError);
    this.setEmailNotificationCcDestinationOptions(
      emailNotificationCcDestinationOptions
    );
    this.setEmailNotificationCcAmendable(emailNotificationCcAmendable);
    this.setEmailNotificationCcSelectedValues(
      emailNotificationCcSelectedValues
    );
    this.setUserEmailTemplateCcValues(userEmailTemplateCcValues);
    this.setEmailDestinationCcError(emailDestinationCcError);
    this.setEmailNotificationBccDestinationOptions(
      emailNotificationBccDestinationOptions
    );
    this.setEmailNotificationBccAmendable(emailNotificationBccAmendable);
    this.setEmailNotificationBccSelectedValues(
      emailNotificationBccSelectedValues
    );
    this.setUserEmailTemplateBccValues(userEmailTemplateBccValues);
    this.setEmailDestinationBccError(emailDestinationBccError);
  }

  setComment(comment) {
    this.comment = comment;
  }

  setHasComment(hasComment) {
    this.hasComment = hasComment;
  }

  setEmailNotificationOptions(emailNotificationOptions) {
    this.emailNotificationOptions = emailNotificationOptions;
  }

  setEmailNotificationToSelectedValues(emailNotificationToSelectedValues) {
    this.emailNotificationToSelectedValues = emailNotificationToSelectedValues;
  }

  setEmailNotificationError(emailNotificationError) {
    this.emailNotificationError = emailNotificationError;
  }

  setEmailNotificationSearchValue(emailNotificationSearchValue) {
    this.emailNotificationSearchValue = emailNotificationSearchValue;
  }

  setEmailNotificationExpectedDataList(emailNotificationExpectedData) {
    this.emailNotificationExpectedData = emailNotificationExpectedData;
  }

  setEmailNotificationToDestinationOptions(
    emailNotificationToDestinationOptions
  ) {
    this.emailNotificationToDestinationOptions =
      emailNotificationToDestinationOptions;
  }

  setEmailNotificationToAmendable(emailNotificationToAmendable) {
    this.emailNotificationToAmendable = emailNotificationToAmendable;
  }

  setUserEmailTemplateToValues(userEmailTemplateToValues) {
    this.userEmailTemplateToValues = userEmailTemplateToValues;
  }

  setEmailDestinationToError(emailDestinationToError) {
    this.emailDestinationToError = emailDestinationToError;
  }

  setEmailNotificationCcDestinationOptions(
    emailNotificationCcDestinationOptions
  ) {
    this.emailNotificationCcDestinationOptions =
      emailNotificationCcDestinationOptions;
  }

  setEmailNotificationCcAmendable(emailNotificationCcAmendable) {
    this.emailNotificationCcAmendable = emailNotificationCcAmendable;
  }

  setEmailNotificationCcSelectedValues(emailNotificationCcSelectedValues) {
    this.emailNotificationCcSelectedValues = emailNotificationCcSelectedValues;
  }

  setUserEmailTemplateCcValues(userEmailTemplateCcValues) {
    this.userEmailTemplateCcValues = userEmailTemplateCcValues;
  }

  setEmailDestinationCcError(emailDestinationCcError) {
    this.emailDestinationCcError = emailDestinationCcError;
  }

  setEmailNotificationBccDestinationOptions(
    emailNotificationBccDestinationOptions
  ) {
    this.emailNotificationBccDestinationOptions =
      emailNotificationBccDestinationOptions;
  }

  setEmailNotificationBccAmendable(emailNotificationBccAmendable) {
    this.emailNotificationBccAmendable = emailNotificationBccAmendable;
  }

  setEmailNotificationBccSelectedValues(emailNotificationBccSelectedValues) {
    this.emailNotificationBccSelectedValues =
      emailNotificationBccSelectedValues;
  }

  setUserEmailTemplateBccValues(userEmailTemplateBccValues) {
    this.userEmailTemplateBccValues = userEmailTemplateBccValues;
  }

  setEmailDestinationBccError(emailDestinationBccError) {
    this.emailDestinationBccError = emailDestinationBccError;
  }
}
