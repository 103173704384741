<template>
  <div :class="['base-info-box', `base-info-box__theme--${getTheme}`]">
    <BaseIcon v-if="hasIcon" class="base-info-box__icon" v-bind="iconOptions" />
    <span class="base-info-box__text" v-html="text"></span>
  </div>
</template>

<script>
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import { themes } from "@/constants";
import { isValidTheme } from "@/utils/typography/typography.utils";

export default {
  name: "BaseInfoBox",
  components: {
    BaseIcon
  },
  props: {
    theme: {
      type: String,
      default: themes.INFO,
      validator: (value) => Object.values(themes).includes(value)
    },
    text: {
      type: String,
      required: true
    },
    iconOptions: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    getTheme() {
      return isValidTheme(this.theme) ? this.theme : themes.INFO;
    },
    hasIcon() {
      return !!Object.keys(this.iconOptions)?.length;
    }
  }
};
</script>

<style lang="scss" scoped>
.base-info-box {
  display: flex;
  align-items: center;
  padding: $spacing20;
  gap: $spacing12;
  border-radius: $border-radius8;

  &__icon {
    flex: none;
  }

  &__text {
    color: $deep-sea;
    width: fit-content;
  }

  @each $theme, $primary-color, $secondary-color in $themes {
    &__theme--#{$theme} {
      background-color: $secondary-color;
      border: 1px solid $primary-color;
    }
  }
}
</style>
