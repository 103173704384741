import { state as makeState } from "./tasks.state.store";

export const mutations = {
  setIsLoadingTasks(state, payload) {
    state.isLoadingTasks = payload;
  },
  setIsLoadingTask(state, payload) {
    state.isLoadingTask = payload;
  },
  setTasks(state, payload) {
    state.tasks = payload;
  },
  setTasksFilter(state, payload) {
    state.tasksFilter = payload;
  },
  setTasksPagination(state, payload) {
    state.tasksPagination = payload;
  },
  setTask(state, payload) {
    state.task = payload;
  },
  resetTasks(state) {
    Object.assign(state, makeState());
  },
  resetTask(state) {
    state.task = {};
  }
};
