import {
  customAttributeRiskScales,
  expectedDataEntitiesTypes,
  expectedDataPrimitiveTypes,
  expectedDataInstanceTypes
} from "@/constants";

const isCustomAttributeRiskScale = (value = "") =>
  Object.values(customAttributeRiskScales).includes(value);

const isCustomAttributeStringList = (value = "") =>
  value.startsWith(expectedDataInstanceTypes.STRING_LIST);

const isCustomAttributeMultiSelectStringList = (value = "") =>
  value.startsWith(expectedDataInstanceTypes.MULTI_SELECT_STRING_LIST);

const isExpectedDataEntityType = (value = "") =>
  Object.values(expectedDataEntitiesTypes).includes(value);

const isExpectedDataPrimitiveType = (value = "") =>
  Object.values(expectedDataPrimitiveTypes).includes(value);

const isExpectedDataCountryPrimitiveType = (value = "") =>
  value === expectedDataPrimitiveTypes.COUNTRY;

const isExpectedDataRiskScaleInstanceType = (value = "") =>
  value === expectedDataInstanceTypes.RISK_RATING;

const isExpectedDataInstanceSubType = ({
  value = "",
  _isCustomAttributeRiskScale = isCustomAttributeRiskScale,
  _isCustomAttributeStringList = isCustomAttributeStringList,
  _isCustomAttributeMultiSelectStringList = isCustomAttributeMultiSelectStringList
} = {}) =>
  _isCustomAttributeRiskScale(value) ||
  _isCustomAttributeStringList(value) ||
  _isCustomAttributeMultiSelectStringList(value);

const isExpectedDataInstanceType = ({
  value = "",
  _isExpectedDataRiskScaleInstanceType = isExpectedDataRiskScaleInstanceType,
  _isCustomAttributeStringList = isCustomAttributeStringList,
  _isCustomAttributeMultiSelectStringList = isCustomAttributeMultiSelectStringList
} = {}) =>
  _isExpectedDataRiskScaleInstanceType(value) ||
  _isCustomAttributeStringList(value) ||
  _isCustomAttributeMultiSelectStringList(value);

const makeMultiSelectType = (value = "type") => `multi-select-${value}`;

export {
  isCustomAttributeRiskScale,
  isCustomAttributeStringList,
  isExpectedDataEntityType,
  isExpectedDataPrimitiveType,
  isExpectedDataInstanceSubType,
  makeMultiSelectType,
  isExpectedDataRiskScaleInstanceType,
  isExpectedDataInstanceType,
  isCustomAttributeMultiSelectStringList,
  isExpectedDataCountryPrimitiveType
};
