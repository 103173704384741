<template>
  <div>
    <b-modal
      :title="'Edit' + ' ' + templateName + ' ' + 'Template'"
      v-model="showAddAdminEmailSettingModal"
      size="xl"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
      <div>
        <div v-if="!submiting && !responseSuccess">
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <label>Email Type<span class="required-span">*</span></label>
                <multiselect
                  class="emailTypeSelect"
                  :disabled="true"
                  placeholder=""
                  :maxHeight="220"
                  v-model="v$.form.selectedEmailType.$model"
                  :state="
                    v$.form.selectedEmailType.$dirty
                      ? !v$.form.selectedEmailType.$error
                      : null
                  "
                  :class="{
                    'form-control is-invalid p-0':
                      v$.form.selectedEmailType.$error
                  }"
                  label="title"
                  track-by="title"
                  :options="emailTypesData"
                >
                </multiselect>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group style="margin-top: 12px">
                <label>Email Interval (In Days)</label>
                <b-form-input
                  disabled
                  type="number"
                  v-model="form.emailInterval"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                description="Please select atleast one Email Language"
              >
                <label
                  >Email Language<span class="required-span">*</span></label
                >
                <multiselect
                  placeholder=""
                  :maxHeight="220"
                  v-model="v$.form.selectedEmailLanguage.$model"
                  :state="
                    v$.form.selectedEmailLanguage.$dirty
                      ? !v$.form.selectedEmailLanguage.$error
                      : null
                  "
                  :class="{
                    'form-control is-invalid p-0':
                      v$.form.selectedEmailLanguage.$error
                  }"
                  label="languages"
                  track-by="languages"
                  :multiple="true"
                  :options="emailLanguagesData"
                  @update:modelValue="setEmailLanguages"
                >
                </multiselect>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Notify Additional Emails (CC)"
                :state="tagValidatorState"
              >
                <b-form-tags
                  input-id="tags-basic"
                  tag-variant="warning"
                  tag-pills
                  :input-attrs="{ 'aria-describedby': 'tags-validation-help' }"
                  :tag-validator="tagValidator"
                  :state="tagValidatorState"
                  v-model="form.emailTagsValue"
                  invalidTagText="Please enter a valid email"
                  @input="onInputEmailTag"
                  placeholder="Add Email..."
                ></b-form-tags>
                <template #invalid-feedback>
                  <p>Please enter a valid email</p>
                </template>
              </b-form-group>
            </b-col>
          </b-row>
          <b-tabs card v-if="form.selectedEmailLanguage.length">
            <b-tab
              v-for="(languages, languagesIndex) in form.selectedEmailLanguage"
              :key="languagesIndex"
              :title="languages.languages"
            >
              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <label>Email Templates Subject</label>
                    <b-form-input
                      placeholder="Please enter Email Templates subject"
                      type="text"
                      size="sm"
                      :id="
                        languages.language_code +
                        languagesIndex +
                        'emailSubject'
                      "
                      @click="setEmailSubject"
                      v-model="
                        form.emailTemplates[languages.language_code].subject
                      "
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-spinner
                  v-if="renderEmailMessage"
                  label="Spinning"
                ></b-spinner>
                <b-col cols="12" v-else>
                  <b-form-group>
                    <label>Email Templates Message</label>
                    <quill-editor
                      style="font-size: 14px !important"
                      v-model="
                        form.emailTemplates[languages.language_code].message
                      "
                    />
                  </b-form-group>
                  <b-card>
                    <div class="d-flex" style="justify-content: space-between">
                      <div>
                        <div class="d-flex" style="align-items: flex-start">
                          <h5>Email Placeholders</h5>
                          <b-btn
                            style="margin-left: 10px"
                            @click="listCollapse = !listCollapse"
                            variant="outline-primary"
                            size="sm"
                          >
                            <font-awesome-icon
                              :icon="listCollapse ? 'caret-up' : 'caret-down'"
                            />
                          </b-btn>
                        </div>
                        <i style="font-size: 14px"
                          >(Copy and paste email function into email body and
                          subject line)</i
                        >
                      </div>
                      <div class="d-flex">
                        <div class="mr-2">
                          <a
                            :href="contextHelpLinks.EmailTemplates.url"
                            target="_blank"
                          >
                            <BaseIcon
                              :icon="icons.QUESTION"
                              :shape="shapes.CIRCLE"
                              :size="12"
                              :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <b-collapse v-model="listCollapse">
                      <div
                        class="mt-3"
                        v-for="(item, index) in renderMergedList"
                        :key="index"
                      >
                        <p
                          style="
                            font-size: 14px !important;
                            font-family: Helvetica;
                          "
                        >
                          {{ item }}
                        </p>
                      </div>
                    </b-collapse>
                  </b-card>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </div>
        <div v-else-if="submiting && !responseSuccess">
          <div style="display: grid; justify-content: center">
            <p>
              {{
                isDuplicateEmailTemplate
                  ? "Email Templates is now being duplicating......"
                  : isEditEmailTemplate
                  ? "Email Templates is now being updating......"
                  : "Email Templates is now being Saved......"
              }}
            </p>
            <div style="display: flex; justify-content: center">
              <b-spinner type="grow" label="Loading..."></b-spinner>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="my-2 text-center">
            {{
              isDuplicateEmailTemplate
                ? "Email Templates has been duplicated successfully."
                : isEditEmailTemplate
                ? "Email Templates has been updated successfully."
                : "Email Templates has been saved successfully."
            }}
            <br />
          </p>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button v-if="!submiting" variant="outline-danger" @click="resetForm"
          >Close</b-button
        >
        <b-button
          v-if="!submiting && !responseSuccess"
          variant="outline-success"
          @click="checkValidation"
          >{{
            isDuplicateEmailTemplate
              ? "Duplicate"
              : isEditEmailTemplate
              ? "Update"
              : "Save"
          }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { EventBus } from "@/event-bus.js";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { Helper } from "@/helpers";
import contextHelpLinks from "@/utils/contextHelpLinks.json";
import { mapGetters, mapState } from "vuex";
import { getterName, themes, icons, shapes } from "@/constants";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";

export default {
  name: "EmailTemplateFormModal",
  components: {
    BaseIcon
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      themes,
      icons,
      shapes,
      contextHelpLinks,
      clientsOptions: [],
      showAddAdminEmailSettingModal: false,
      platformVariablesData: [],
      emailTagsData: [],
      emailLanguagesData: [],
      emailTypesData: [],
      form: {
        emailTemplateName: null,
        emailInterval: null,
        selectedEmailLanguage: [],
        selectedEmailType: null,
        emailTemplates: {},
        attributes: [{ key: null, value: null }],
        selectedClient: null,
        emailTagsValue: []
      },
      tagValidatorDirty: false,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"]
          ]
        }
      },
      emailTemplateId: null,
      categoriesOption: [],
      submiting: false,
      responseSuccess: false,
      isLoading: false,
      renderEmailMessage: false,
      isActiveEmailSubject: false,
      setupEmails: {},
      isEditEmailTemplate: false,
      isDuplicateEmailTemplate: false,
      emaiTemplateMsg:
        "<p><center><table style='padding: 10px;width: 550px;background: white;border: 3px solid #eeeeee;'><tbody><tr><td style='width: 800px;padding:5px;'></td></tr></tbody></table></center></p>",
      listCollapse: false,
      renderMergedList: null
    };
  },
  validations: {
    form: {
      selectedEmailLanguage: {
        required
      },
      selectedEmailType: {
        required
      }
    }
  },
  mounted() {
    this.getEmailTagList();
    this.getPlatformSettingsList();
    this.getEmailLanguagesList();
  },
  computed: {
    ...mapGetters({
      fullName: getterName.USER.GET_FULL_NAME
    }),
    ...mapState({
      companyId: (state) => state.company.companyId,
      companyName: (state) => state.company.companyName,
      userId: (state) => state.user.userId
    }),
    tagValidatorState() {
      let mailformat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let tempEmailTag =
        this.form.emailTagsValue[this.form.emailTagsValue.length - 1];
      return this.tagValidatorDirty
        ? mailformat.test(String(tempEmailTag).toLowerCase())
        : null;
    }
  },
  created() {
    EventBus.on("onShowAddModal", () => {
      this.emailTemplateId = null;
      this.showAddModal();
    });
    EventBus.on("onShowEditModal", (data) => {
      this.emailTemplateId = null;
      this.showEditModal(data);
    });
    EventBus.on("onShowDuplicateModal", (data) => {
      this.emailTemplateId = null;
      this.showDulicateModal(data);
    });
  },
  methods: {
    tagValidator(value) {
      let mailformat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // Individual tag validator function

      if (mailformat.test(String(value).toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    onInputEmailTag() {
      if (this.form.emailTagsValue.length) {
        this.tagValidatorDirty = true;
      } else {
        this.tagValidatorDirty = false;
      }
    },
    addDataAttribute() {
      this.isLoading = true;
      this.form.attributes.push({ key: null, value: null });
      this.isLoading = false;
      // this.onSaveJsonData();
    },

    async getClientsList() {
      this.isLoading = true;
      try {
        let result = await HTTP(
          "get",
          "v1/getclientslist",
          null,
          makeAuthorizationHeader()
        );

        this.clientsOptions = result.data.clients;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },

    onDeleteData(index) {
      this.isLoading = true;
      this.form.attributes.splice(index, 1);
      this.isLoading = false;
      // this.onSaveJsonData();
    },
    setEmailLanguages() {
      this.setupEmails = {};
      this.form.selectedEmailLanguage.map((languages) => {
        let languageValue = languages.language_code;
        if (this.form.emailTemplates.hasOwnProperty(languageValue)) {
          this.setupEmails[languageValue] = {
            subject: this.form.emailTemplates[languageValue].subject,
            message: this.form.emailTemplates[languageValue].message
          };
        } else {
          this.setupEmails[languageValue] = {
            subject: null,
            message: this.emaiTemplateMsg
          };
          this.form.emailTemplates = this.setupEmails;
        }
      });
    },
    setEmailSubject() {
      this.isActiveEmailSubject = true;
    },
    setEmailMessage() {
      this.isActiveEmailSubject = false;
    },
    dataInActiveField(newText, emailFormId) {
      let item = document.getElementById(emailFormId);
      const start = item.selectionStart;
      const end = item.selectionEnd;
      const text = item.value;
      const before = text.substring(0, start);
      const after = text.substring(end, text.length);
      item.value = before + newText + after;
      item.selectionStart = item.selectionEnd = start + newText.length;
      item.focus();
    },
    setTagValue(languages, selectedValue, emailSubjectId, type) {
      navigator.clipboard.writeText(selectedValue);
      this.$bvToast.toast(`Placeholder is copied to clipboard`, {
        title: "Copied to clipboard",
        variant: "success",
        autoHideDelay: 1000,
        appendToast: false
      });
      if (this.isActiveEmailSubject) {
        if (type == "text") {
          this.dataInActiveField(selectedValue, emailSubjectId);
        }
      }
      this.renderEmailMessage = true;
      setTimeout(() => {
        this.renderEmailMessage = false;
      }, 10);
    },
    getCaretPosition(editableDiv) {
      let caretPos = 0;
      let sel;
      let range;
      if (window.getSelection) {
        sel = window.getSelection();
        if (sel.rangeCount) {
          range = sel.getRangeAt(0);
          if (range.commonAncestorContainer.parentNode == editableDiv) {
            caretPos = range.endOffset;
          }
        }
      } else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange();
        if (range.parentElement() == editableDiv) {
          let tempEl = document.createElement("span");
          editableDiv.insertBefore(tempEl, editableDiv.firstChild);
          let tempRange = range.duplicate();
          tempRange.moveToElementText(tempEl);
          tempRange.setEndPoint("EndToEnd", range);
          caretPos = tempRange.text.length;
        }
      }
      return caretPos;
    },
    addDataInEmailMessage(languages, selectedValue, tasksEmail) {
      if (this.form.emailTemplates[languages.language_code].message == null) {
        this.form.emailTemplates[languages.language_code].message =
          selectedValue;
      } else {
        tasksEmail = `${
          this.form.emailTemplates[languages.language_code].message
        }${selectedValue}`;
        this.form.emailTemplates[languages.language_code].message = tasksEmail;
      }
    },
    showAddModal() {
      this.showAddAdminEmailSettingModal = true;
    },
    assignFieldsValue(data) {
      let openedCompanyProfile = localStorage.getItem("openedCompanyProfile");
      this.emailTemplateId = data.item.id;
      let templateData = data.item.template_data
        ? JSON.parse(data.item.template_data)
        : null;
      this.form = {
        emailInterval: data.item.email_interval,
        emailTemplateName: data.item.template_name,
        selectedEmailLanguage: templateData.languages
          ? templateData.languages
          : [],
        selectedEmailType: {
          id: data.item.email_type_id,
          title: data.item.email_type_title
        },
        emailTemplates: templateData.emails ? templateData.emails : {},
        attributes: templateData.attributes
          ? templateData.attributes
          : [{ key: null, value: null }],
        emailTagsValue: templateData.additional_emails
          ? templateData.additional_emails
          : [],
        selectedClient: {
          value: this.companyId,
          text: openedCompanyProfile
        }
      };
    },
    showEditModal(data) {
      this.isEditEmailTemplate = true;
      this.assignFieldsValue(data);
      this.showAddAdminEmailSettingModal = true;
    },
    showDulicateModal(data) {
      this.isDuplicateEmailTemplate = true;
      this.assignFieldsValue(data);
      this.showAddAdminEmailSettingModal = true;
    },
    reloadTable() {
      EventBus.emit("onReloadTable", "");
    },
    resetForm() {
      this.form = {
        emailInterval: null,
        emailTemplateName: null,
        selectedEmailLanguage: [],
        selectedEmailType: null,
        emailTemplates: {},
        attributes: [{ key: null, value: null }],
        selectedClient: null,
        emailTagsValue: []
      };
      this.submiting = false;
      this.responseSuccess = false;
      this.isLoading = false;
      this.isEditEmailTemplate = false;
      this.isDuplicateEmailTemplate = false;
      this.showAddAdminEmailSettingModal = false;
      this.tagValidatorDirty = false;
      this.listCollapse = false;
      this.v$.$reset();
    },
    checkValidation() {
      this.v$.form.$touch();
      if (this.v$.form.$anyError) {
        return false;
      } else {
        if (this.isEditEmailTemplate) {
          this.editEmailTemplate();
        }
      }
    },
    makeToster(tosterVarient, tosterMsg) {
      this.resetForm();
      Helper.makeToast(this.$bvToast, tosterVarient, tosterMsg);
    },
    async getEmailTagList() {
      this.emailTagsData = [];
      this.isLoading = true;
      let response = await HTTP(
        "get",
        "v1/tag/enableclientlist",
        null,
        makeAuthorizationHeader()
      );
      try {
        let emailTypedata = response.data.EmailTags.filter(
          (x) => x.is_enable_for_client == 1
        );
        this.emailTagsData = emailTypedata;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        return [err];
      }
    },
    async getPlatformSettingsList() {
      this.platformVariablesData = [];
      this.isLoading = true;
      let response = await HTTP(
        "get",
        `v1/platform-variables/list/${this.companyId}`,
        null,
        makeAuthorizationHeader()
      );
      try {
        this.platformVariablesData = response.data.PlatformVariable;
        this.isLoading = false;
        this.renderTemplatesList();
      } catch (err) {
        this.isLoading = false;
        return [err];
      }
    },
    async getEmailLanguagesList() {
      this.emailLanguagesData = [];
      this.isLoading = true;
      let response = await HTTP(
        "get",
        `v1/getformslanguageslist/${this.companyId}`,
        null,
        makeAuthorizationHeader()
      );
      try {
        this.emailLanguagesData = response.data.languages;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        return [err];
      }
    },
    async getEmailTypeList() {
      this.emailTypesData = [];
      this.isLoading = true;
      let response = await HTTP(
        "get",
        "v1/types/list",
        null,
        makeAuthorizationHeader()
      );

      try {
        this.emailTypesData = response.data.EmailTypes;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        return [err];
      }
    },
    async editEmailTemplate() {
      let formData = {
        email_type_id: this.form.selectedEmailType.id,
        email_interval: this.form.emailInterval,
        template_name: this.form.emailTemplateName,
        template_data: {
          languages: this.form.selectedEmailLanguage,
          emails: this.form.emailTemplates,
          attributes: this.form.attributes,
          additional_emails: this.form.emailTagsValue
        },
        company_id: this.companyId,
        user_name: this.fullName,
        user_id: this.userId,
        comany_name: this.companyName
      };
      try {
        this.submiting = true;
        this.isLoading = true;
        let result = await HTTP(
          "put",
          `v1/dynamic-templates/update/${this.emailTemplateId}`,
          formData,
          makeAuthorizationHeader()
        );
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = true;
        if (result && result.data && result.data.success == 1) {
          this.makeToster("success", result.data.message);
        } else if (result && result.data && result.data.success == 0) {
          this.makeToster("danger", result.data.message);
        } else {
          this.makeToster("danger", "Oops! Something Went Wrong.");
        }
        this.reloadTable();
      } catch (err) {
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = false;
        this.makeToster("warning", "Oops!! something went wrong!! ");
        return [err];
      }
    },
    renderTemplatesList() {
      let emailTags = this.emailTagsData.map((item) => `[[${item.tags}]]`);
      let platformVariables = this.platformVariablesData.map(
        (item) => `{{${item.platform_variable_key}}}`
      );
      this.renderMergedList = [...emailTags, ...platformVariables];
    }
  },
  props: {
    templateName: String
  }
};
</script>
<style>
.modal-body-style {
  overflow-x: hidden;
}
.emailTypeSelect {
  opacity: 0.9 !important;
}
</style>
