import {
  taskTemplateKeysDTO,
  taskTemplateKeysAPIDTO,
  taskTemplatesExpectedDataTriggerType
} from "@/constants";
import {
  makeExpectedDataDTO,
  makeExpectedDataAPIDTO
} from "@/services/expectedData/dto/expectedData.dto";
import {
  makeDestinationDTO,
  makeDestinationAPIDTO
} from "@/services/commonDto/destinationDto/destination.dto";
import { makeAutomationActionTriggerDataAPIDTO } from "@/services/automations/dto/automations.dto";
import { getSelectedOption } from "@/utils";

const makeTaskOutcomeUserInputDTO = (expectedData = {}) => ({
  [taskTemplateKeysDTO.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
    expectedData[taskTemplateKeysAPIDTO.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME],
  [taskTemplateKeysDTO.TRIGGERING_DATA]: {
    [taskTemplateKeysDTO.DATA]:
      expectedData?.[taskTemplateKeysAPIDTO.TRIGGERING_DATA]?.[
        taskTemplateKeysAPIDTO.DATA
      ],
    [taskTemplateKeysDTO.MESSAGE]:
      expectedData?.[taskTemplateKeysAPIDTO.TRIGGERING_DATA]?.[
        taskTemplateKeysAPIDTO.MESSAGE
      ],
    [taskTemplateKeysDTO.TYPE]:
      expectedData?.[taskTemplateKeysAPIDTO.TRIGGERING_DATA]?.[
        taskTemplateKeysAPIDTO.TYPE
      ]
  }
});

const makeTaskOutcomeExpectedDataDTO = (expectedData = {}) => ({
  [taskTemplateKeysDTO.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
    expectedData[taskTemplateKeysAPIDTO.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME],
  [taskTemplateKeysDTO.TRIGGERING_DATA]: {
    [taskTemplateKeysDTO.DATA]: {
      [taskTemplateKeysDTO.NAME]:
        expectedData?.[taskTemplateKeysAPIDTO.TRIGGERING_DATA]?.[
          taskTemplateKeysAPIDTO.DATA
        ]?.[taskTemplateKeysAPIDTO.NAME]
    },
    [taskTemplateKeysDTO.TYPE]:
      expectedData?.[taskTemplateKeysAPIDTO.TRIGGERING_DATA]?.[
        taskTemplateKeysAPIDTO.TYPE
      ]
  }
});

const makeTaskOutcomeDTO = ({
  taskOutcome = {},
  _makeTaskOutcomeUserInputDTO = makeTaskOutcomeUserInputDTO,
  _makeTaskOutcomeExpectedDataDTO = makeTaskOutcomeExpectedDataDTO
} = {}) => ({
  [taskTemplateKeysDTO.ACTION_ID]:
    taskOutcome?.[taskTemplateKeysAPIDTO.ACTION_ID],
  [taskTemplateKeysDTO.EXPECTED_DATA_MAPPING]:
    taskOutcome?.[taskTemplateKeysAPIDTO.EXPECTED_DATA_MAPPING]?.map(
      (expectedData) => {
        if (
          expectedData?.[taskTemplateKeysAPIDTO.TRIGGERING_DATA]?.[
            taskTemplateKeysAPIDTO.TYPE
          ] === taskTemplatesExpectedDataTriggerType.USER_INPUT
        ) {
          return _makeTaskOutcomeUserInputDTO(expectedData);
        } else {
          return _makeTaskOutcomeExpectedDataDTO(expectedData);
        }
      }
    ) || []
});

const makeTaskOutcomesDTO = ({
  outcomeActions = [],
  _makeTaskOutcomeDTO = makeTaskOutcomeDTO,
  _makeDestinationDTO = makeDestinationDTO
} = {}) =>
  outcomeActions?.map((taskOutcome) => ({
    [taskTemplateKeysDTO.OUTCOME_PERMISSIONS]: _makeDestinationDTO({
      emailDestinations: taskOutcome[taskTemplateKeysAPIDTO.OUTCOME_PERMISSIONS]
    }),
    [taskTemplateKeysDTO.OUTCOME]: _makeTaskOutcomeDTO({
      taskOutcome: taskOutcome?.[taskTemplateKeysDTO.OUTCOME]
    })
  })) || [];

const makeTaskTemplateDTO = ({
  taskTemplate = {},
  _makeTaskOutcomesDTO = makeTaskOutcomesDTO,
  _makeExpectedDataDTO = makeExpectedDataDTO,
  _makeDestinationDTO = makeDestinationDTO
} = {}) => ({
  [taskTemplateKeysDTO.ID]: taskTemplate[taskTemplateKeysAPIDTO.ID],
  [taskTemplateKeysDTO.COMPANY_ID]:
    taskTemplate[taskTemplateKeysAPIDTO.COMPANY_ID],
  [taskTemplateKeysDTO.TEMPLATE_NAME]:
    taskTemplate[taskTemplateKeysAPIDTO.TEMPLATE_NAME],
  [taskTemplateKeysDTO.TEMPLATE_DESCRIPTION]:
    taskTemplate[taskTemplateKeysAPIDTO.TEMPLATE_DESCRIPTION],
  [taskTemplateKeysDTO.TASK_SUBJECT]:
    taskTemplate[taskTemplateKeysAPIDTO.TASK_SUBJECT],
  [taskTemplateKeysDTO.TASK_DESCRIPTION]:
    taskTemplate[taskTemplateKeysAPIDTO.TASK_DESCRIPTION],
  [taskTemplateKeysDTO.EXPECTED_DATA_DEFINITION]: taskTemplate[
    taskTemplateKeysAPIDTO.EXPECTED_DATA_DEFINITION
  ]?.[taskTemplateKeysAPIDTO.VARIABLES]?.map?.(
    (expectedData) =>
      _makeExpectedDataDTO({
        expectedData
      }) || []
  ),
  [taskTemplateKeysDTO.TASK_DEFAULT_ASSIGNEE]: _makeDestinationDTO({
    emailDestinations: [
      taskTemplate[taskTemplateKeysAPIDTO.TASK_DEFAULT_ASSIGNEE]
    ]
  }),
  [taskTemplateKeysDTO.EDIT_PERMISSIONS]: _makeDestinationDTO({
    emailDestinations: taskTemplate[taskTemplateKeysAPIDTO.EDIT_PERMISSIONS]
  }),
  [taskTemplateKeysDTO.REOPEN_PERMISSIONS]: _makeDestinationDTO({
    emailDestinations: taskTemplate[taskTemplateKeysAPIDTO.REOPEN_PERMISSIONS]
  }),
  [taskTemplateKeysDTO.OUTCOME_ACTIONS]: _makeTaskOutcomesDTO({
    outcomeActions: taskTemplate[taskTemplateKeysAPIDTO.OUTCOME_ACTIONS]
  })
});

const makeTaskTemplatesDTO = ({
  taskTemplates = [],
  _makeTaskTemplateDTO = makeTaskTemplateDTO
} = {}) =>
  taskTemplates.map((taskTemplate) => _makeTaskTemplateDTO({ taskTemplate }));

const makeTaskOutcomesAPIDTO = ({
  outcomeActions = [],
  _getSelectedOption = getSelectedOption,
  _makeAutomationActionTriggerDataAPIDTO = makeAutomationActionTriggerDataAPIDTO,
  _makeDestinationAPIDTO = makeDestinationAPIDTO
} = {}) =>
  outcomeActions?.map((taskOutcome) => ({
    [taskTemplateKeysAPIDTO.OUTCOME_PERMISSIONS]:
      _makeDestinationAPIDTO({
        emailDestinations: taskOutcome.submissionPermissionsOptions
      }) || [],
    [taskTemplateKeysAPIDTO.OUTCOME]: {
      [taskTemplateKeysAPIDTO.ACTION_ID]: _getSelectedOption(
        taskOutcome.actionNameOptions
      )?.value,
      [taskTemplateKeysAPIDTO.EXPECTED_DATA_MAPPING]:
        taskOutcome.expectDataList?.map((expectData) =>
          _makeAutomationActionTriggerDataAPIDTO({
            ...expectData,
            dataSource: taskTemplatesExpectedDataTriggerType.EXPECTED_DATA
          })
        ) || []
    }
  })) || [];

const makeTaskTemplateAPIDTO = ({
  taskTemplate,
  storeExpectedData,
  _makeExpectedDataAPIDTO = makeExpectedDataAPIDTO,
  _makeDestinationAPIDTO = makeDestinationAPIDTO,
  _makeTaskOutcomeAPIDTO = makeTaskOutcomesAPIDTO
} = {}) => ({
  [taskTemplateKeysAPIDTO.ID]: taskTemplate[taskTemplateKeysDTO.ID],
  [taskTemplateKeysAPIDTO.COMPANY_ID]:
    taskTemplate[taskTemplateKeysDTO.COMPANY_ID],
  [taskTemplateKeysAPIDTO.TEMPLATE_NAME]:
    taskTemplate[taskTemplateKeysDTO.TEMPLATE_NAME],
  [taskTemplateKeysAPIDTO.TEMPLATE_DESCRIPTION]:
    taskTemplate[taskTemplateKeysDTO.TEMPLATE_DESCRIPTION],
  [taskTemplateKeysAPIDTO.TASK_SUBJECT]:
    taskTemplate[taskTemplateKeysDTO.TASK_SUBJECT],
  [taskTemplateKeysAPIDTO.TASK_DESCRIPTION]:
    taskTemplate[taskTemplateKeysDTO.TASK_DESCRIPTION],
  [taskTemplateKeysAPIDTO.EXPECTED_DATA_DEFINITION]: {
    [taskTemplateKeysAPIDTO.COMPANY_ID]:
      taskTemplate[taskTemplateKeysDTO.COMPANY_ID],
    [taskTemplateKeysDTO.VARIABLES]: taskTemplate[
      taskTemplateKeysDTO.EXPECTED_DATA_DEFINITION
    ].map((expectedData) =>
      _makeExpectedDataAPIDTO({ expectedData, storeExpectedData })
    )
  },
  [taskTemplateKeysAPIDTO.TASK_DEFAULT_ASSIGNEE]:
    _makeDestinationAPIDTO({
      emailDestinations: taskTemplate[taskTemplateKeysDTO.TASK_DEFAULT_ASSIGNEE]
    })[0] || {},
  [taskTemplateKeysAPIDTO.EDIT_PERMISSIONS]:
    _makeDestinationAPIDTO({
      emailDestinations: taskTemplate[taskTemplateKeysDTO.EDIT_PERMISSIONS]
    }) || [],
  [taskTemplateKeysAPIDTO.REOPEN_PERMISSIONS]:
    _makeDestinationAPIDTO({
      emailDestinations: taskTemplate[taskTemplateKeysDTO.REOPEN_PERMISSIONS]
    }) || [],
  [taskTemplateKeysAPIDTO.OUTCOME_ACTIONS]: _makeTaskOutcomeAPIDTO({
    outcomeActions: taskTemplate[taskTemplateKeysDTO.OUTCOME_ACTIONS]
  })
});

export {
  makeTaskTemplatesDTO,
  makeTaskTemplateDTO,
  makeTaskOutcomesDTO,
  makeTaskOutcomeDTO,
  makeTaskOutcomeExpectedDataDTO,
  makeTaskOutcomeUserInputDTO,
  makeTaskOutcomesAPIDTO,
  makeTaskTemplateAPIDTO
};
