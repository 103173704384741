import { direction, regex } from "@/constants";
import { isObject } from "@/utils";

/**
 * @namespace Validation
 */

/**
 * @memberof Validation
 * @param {string} link - link to test
 * @returns {boolean} - result
 */
const hasMailto = (link) => regex.MAIL_TO.test(link);
const hasHttp = (link) => regex.HTTP.test(link);
const isValidUrl = (link) => hasMailto(link) || hasHttp(link);
const isValidMailToLink = (link) => {
  if (hasMailto(link)) {
    return regex.VALID_MAIL_TO_LINK.test(link);
  } else {
    return true;
  }
};
const isValidLink = (link) => {
  if (hasHttp(link)) {
    return regex.VALID_LINK.test(link);
  } else {
    return true;
  }
};
const isValidEmail = (link) => regex.VALID_MAIL_TO_LINK.test(link);
const hasCorrectEmailFormat = (email) => regex.VALID_EMAIL.test(email);
const hasNumber = (value) => /\d/.test(value);
const hasLowerCase = (value) => /[a-z]/.test(value);
const hasUpperCase = (value) => /[A-Z]/.test(value);
const hasSpecialCharacters = (value) => /[!@#$%^&*()+=._-]/.test(value);

/**
 * Returns false if the value does not exist
 * @memberof Validation
 * @param {string | Array | Date | object} value - value that is required
 * @returns {boolean} - result
 */
const isRequired = (value) => {
  if (typeof value === "string") {
    value = value.trim();
  }

  if (Array.isArray(value)) {
    return !!value.length;
  }

  if (value === undefined || value === null) {
    return false;
  }

  if (value instanceof Date) {
    return !isNaN(value.getTime());
  }

  if (typeof value === "object") {
    for (let _ in value) {
      return true;
    }

    return false;
  }

  return !!String(value).length;
};

/**
 * returns true if type of value is number
 * @memberof Validation
 * @param {number | string} value - value to test
 * @returns {boolean} - result
 */
const isNumber = (value) => typeof value === "number";

/**
 * returns false if value is greater than threshold
 * @memberof Validation
 * @param {Array | string} value - value to test
 * @param {number} threshold - threshold of test
 * @returns {boolean} - result
 */
const isLessThanOrEqualToMaxLength = (value, threshold) => {
  if (Array.isArray(value)) {
    return value.length <= threshold;
  }

  return String(value).length <= threshold;
};

/**
 * returns true if type of item is string
 * @memberof Validation
 * @param {*} value - value to test
 * @returns {boolean} - result
 */
const isString = (value) => typeof value === "string";

/**
 * returns true if type of item is Function
 * @memberof Validation
 * @param {*} value - value to test
 * @returns {boolean} - result
 */
const isFunction = (value) =>
  Object.prototype.toString.call(value) === "[object Function]";

/**
 * returns true if type of item is Date
 * @memberof Validation
 * @param {*} value - value to test
 * @returns {boolean} - result
 */
const isDate = (value) =>
  Object.prototype.toString.call(value) === "[object Date]";

/**
 * returns false if type of item is Function or Object or Array
 * @memberof Validation
 * @param {*} value - value to test
 * @returns {boolean} - result
 */
const isValue = (value) =>
  !isObject(value) && !Array.isArray(value) && !isFunction(value);

const isAlphaNumeric = (value) => !regex.ALPHA_NUMERIC.test(value);

/**
 * Returns false if provided string does not match a direction constant
 * @memberof Validation
 * @param {*} value - value to test
 * @returns {boolean} - result
 */
const isValidDirection = (value) => {
  if (!isString(value)) {
    return false;
  }

  return Object.values(direction).includes(value);
};

export {
  hasMailto,
  hasHttp,
  isValidUrl,
  isValidMailToLink,
  isValidLink,
  isValidEmail,
  hasCorrectEmailFormat,
  hasNumber,
  hasLowerCase,
  hasUpperCase,
  hasSpecialCharacters,
  isRequired,
  isNumber,
  isLessThanOrEqualToMaxLength,
  isString,
  isFunction,
  isDate,
  isValue,
  isAlphaNumeric,
  isValidDirection
};
