<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay :show="isLoading" rounded="sm">
          <b-card no-body class="shadow-sm pt-0 pl-0 pr-0 m-4 mb-0">
            <b-navbar toggleable="lg">
              <b-navbar-brand href="#" class="text-dark">
                <strong style="color: #3b3e66"
                  >Emails Templates for {{ companyName }}</strong
                >
              </b-navbar-brand>

              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto">
                <b-nav-form>
                  <!-- Add btn -->
                  <b-button
                    @click="onAddEmailSettingClick"
                    class="mr-2"
                    variant="outline-primary"
                    size="sm"
                  >
                    <font-awesome-icon icon="plus" />
                  </b-button>

                  <!-- reload btn -->
                  <b-button
                    variant="outline-info"
                    size="sm"
                    class="mr-4"
                    @click="reloadTable"
                  >
                    <font-awesome-icon icon="sync" />
                  </b-button>

                  <!-- search bar -->
                  <b-form-group>
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="filter"
                        type="search"
                        id="filterInput"
                        placeholder="Type to Search"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Clear</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-nav-form>
              </b-navbar-nav>
            </b-navbar>

            <!-- Email templates table data start -->
            <b-table
              sticky-header="calc(100vh - 295px)"
              :busy.sync="isLoading"
              bordered
              ref="table"
              id="email-template-table"
              :fields="staticFields"
              :items="getEmailTemplates"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              no-sort-reset
            >
              <template v-slot:cell(status)="data">
                <b-badge
                  :variant="data.item.is_active == 1 ? 'success' : 'danger'"
                >
                  {{ data.item.is_active == 1 ? "Active" : "Deactive" }}
                </b-badge>
              </template>
              <template v-slot:cell(actions)="data">
                <b-btn
                  @click="editEmailTemplate(data)"
                  class="mr-2"
                  size="sm"
                  variant="outline-info"
                >
                  <font-awesome-icon icon="edit" />
                </b-btn>
                <b-btn
                  @click="dulicateEmailTemplate(data)"
                  class="mr-2"
                  size="sm"
                  variant="outline-warning"
                >
                  <font-awesome-icon icon="copy" />
                </b-btn>
                <b-btn
                  @click="triggerEmailTemplate(data)"
                  class="text-success pointer mr-2"
                  size="sm"
                  variant="outline-success"
                >
                  <font-awesome-icon icon="eye" />
                </b-btn>
                <b-btn
                  v-if="data.item.is_active == 1"
                  @click="deativeEmailTemplate(data)"
                  class="mr-2"
                  size="sm"
                  variant="outline-danger"
                  >Deactivate</b-btn
                >
                <b-btn
                  v-if="data.item.is_active == 0"
                  @click="activeEmailTemplate(data)"
                  class="mr-2"
                  size="sm"
                  variant="outline-success"
                  >Activate</b-btn
                >
              </template>
            </b-table>
            <!-- Email templates table data end -->

            <template v-slot:footer>
              <b-row>
                <b-col cols="2">
                  <b-form-select
                    v-model="perPage"
                    id="perPageSelect"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                </b-col>
                <b-col cols="6">
                  <p class="text-center">
                    Showing Result From
                    <b>{{ fromRows }}</b> to
                    <b>{{ toRows }}</b>
                  </p>
                </b-col>
                <b-col cols="4">
                  <b-pagination
                    class="float-right"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                    aria-controls="email-template-table"
                  ></b-pagination>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

    <!-- overlay for deactivate email type -->
    <b-overlay :show="showDeativeEmailTemplate" no-wrap>
      <template v-slot:overlay>
        <div
          v-if="processing"
          class="text-center p-4 bg-primary text-light rounded"
        >
          <font-awesome-icon size="lg" icon="cloud-upload-alt" />
          <div class="mb-3">Deactivating...</div>
          <b-progress
            min="1"
            max="20"
            :value="counter"
            variant="success"
            height="3px"
            class="mx-n4 rounded-0"
          ></b-progress>
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <p>
            <strong id="form-confirm-label">Are you sure?</strong>
          </p>
          <div class="d-flex">
            <b-button
              variant="outline-success"
              class="mr-3"
              @click="resetDeativeModal"
              >Cancel</b-button
            >
            <b-button variant="outline-danger" @click="deativeEmailTemplateCall"
              >Yes</b-button
            >
          </div>
        </div>
      </template>
    </b-overlay>

    <!-- overlay for activate email type -->
    <b-overlay :show="showActiveEmailTemplate" no-wrap>
      <template v-slot:overlay>
        <div
          v-if="processing"
          class="text-center p-4 bg-primary text-light rounded"
        >
          <font-awesome-icon size="lg" icon="cloud-upload-alt" />
          <div class="mb-3">Activating...</div>
          <b-progress
            min="1"
            max="20"
            :value="counter"
            variant="success"
            height="3px"
            class="mx-n4 rounded-0"
          ></b-progress>
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <p>
            <strong id="form-confirm-label">Are you sure?</strong>
          </p>
          <div class="d-flex">
            <b-button
              variant="outline-success"
              class="mr-3"
              @click="resetActiveModal"
              >Cancel</b-button
            >
            <b-button variant="outline-danger" @click="activeEmailTemplateCall"
              >Yes</b-button
            >
          </div>
        </div>
      </template>
    </b-overlay>
    <EmailTemplateFormModal />
    <TriggerEmailModal />
  </div>
</template>
<script>
import { EventBus } from "@/event-bus.js";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import EmailTemplateFormModal from "../../../components/eb2/emailTemplateFormModal.vue";
import TriggerEmailModal from "../../../components/eb2/triggerEmailModal.vue";
import { nextTick } from "vue";

export default {
  name: "PlatformsSetting",
  components: {
    EmailTemplateFormModal,
    TriggerEmailModal
  },
  data() {
    return {
      companyName: null,
      showActiveEmailTemplate: false,
      showDeativeEmailTemplate: false,
      emailTemplateId: null,
      processing: false,
      counter: 1,
      interval: null,
      isLoading: false,
      totalRows: 0,
      filter: null,
      perPage: 10,
      toRows: 0,
      fromRows: 0,
      currentPage: 1,
      sortBy: "id",
      sortDesc: true,
      pageOptions: [5, 10, 25, 50, 75, 100],
      staticFields: [
        { key: "template_name", label: "Template Name", sortable: true },
        { key: "email_interval", label: "Email Interval", sortable: true },
        { key: "created_by", label: "Created By", sortable: true },
        { key: "email_type_title", label: "Email Type", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "actions", label: "Action", sortable: false }
      ]
    };
  },
  computed: {
    rows() {
      return this.totalRows;
    }
  },
  created() {
    EventBus.on("onReloadTable", () => {
      this.reloadTable();
    });
  },
  mounted() {
    this.companyName = localStorage.getItem("openedCompanyProfile");
  },
  beforeDestroy() {
    this.clearInterval();
  },
  methods: {
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    reloadTable() {
      this.$root.$emit("bv::refresh::table", "email-template-table");
    },
    onAddEmailSettingClick() {
      EventBus.emit("onShowAddModal", "");
    },
    editEmailTemplate(data) {
      EventBus.emit("onShowEditModal", data);
    },
    dulicateEmailTemplate(data) {
      EventBus.emit("onShowDuplicateModal", data);
    },
    triggerEmailTemplate(data) {
      EventBus.emit("triggerEmailModal", data);
    },

    // get email templates list
    async getEmailTemplates() {
      let formData = {
        company_id: this.$route.params.id,
        filter: this.filter,
        sort: `${this.sortBy}|${this.sortDesc ? "desc" : "asc"}`,
        per_page: this.perPage,
        page: this.currentPage
      };
      this.isLoading = true;
      let result = HTTP(
        "post",
        "v1/dynamic-templates/get",
        formData,
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          const items = response.data.data;
          this.isLoading = false;
          this.fromRows = response.data.from;
          this.toRows = response.data.to;
          this.totalRows = response.data.total;
          return items;
        })
        .catch((error) => {
          this.isLoading = false;
          return [error];
        });
    },
    deativeEmailTemplate(data) {
      this.emailTemplateId = data.item.id;
      this.processing = false;
      this.showDeativeEmailTemplate = true;
    },
    deativeEmailTemplateCall() {
      this.counter = 1;
      this.processing = true;
      this.deativeEmailTemplateApiCall().then(this.reloadTable);
      this.clearInterval();
      this.interval = setInterval(() => {
        if (this.counter < 20) {
          this.counter += 1;
        } else {
          this.clearInterval();
          nextTick(() => {
            this.showDeativeEmailTemplate = this.processing = false;
          });
        }
      }, 200);
    },
    async deativeEmailTemplateApiCall() {
      let formData = {
        is_active: 0
      };
      try {
        await HTTP(
          "put",
          `v1/dynamic-templates/update/${this.emailTemplateId}`,
          formData,
          makeAuthorizationHeader()
        );
        this.emailTemplateId = null;
      } catch (err) {
        this.emailTemplateId = null;
        return [err];
      }
    },
    resetDeativeModal() {
      this.emailTemplateId = null;
      this.showDeativeEmailTemplate = false;
    },
    activeEmailTemplate(data) {
      this.emailTemplateId = data.item.id;
      this.showActiveEmailTemplate = true;
    },
    activeEmailTemplateCall() {
      this.counter = 1;
      this.processing = true;
      this.activeEmailTemplateApiCall().then(this.reloadTable);
      this.clearInterval();
      this.interval = setInterval(() => {
        if (this.counter < 20) {
          this.counter += 1;
        } else {
          this.clearInterval();
          nextTick(() => {
            this.showActiveEmailTemplate = this.processing = false;
          });
        }
      }, 200);
    },
    async activeEmailTemplateApiCall() {
      let formData = {
        is_active: 1
      };
      try {
        await HTTP(
          "put",
          `v1/dynamic-templates/update/${this.emailTemplateId}`,
          formData,
          makeAuthorizationHeader()
        );
        this.emailTemplateId = null;
      } catch (err) {
        this.emailTemplateId = null;
        return [err];
      }
    },
    resetActiveModal() {
      this.emailTemplateId = null;
      this.showActiveEmailTemplate = false;
    }
  }
};
</script>

<style></style>
