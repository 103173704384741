import { mutations } from "./country.mutations.store";
import { getters } from "./country.getters.store";
import { state } from "./country.state.store";

export default {
  namespaced: true,
  mutations,
  getters,
  state
};
