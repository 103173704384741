import { state as makeState } from "@/store/actions/actions.state.store";

export const mutations = {
  setActions(state, payload) {
    state.actions = payload;
  },
  setAction(state, payload) {
    state.action = payload;
  },
  setIsLoadingActions(state, payload) {
    state.isLoadingActions = payload;
  },
  setIsLoadingAction(state, payload) {
    state.isLoadingAction = payload;
  },
  resetAction(state) {
    state.action = {};
  },
  resetActions(state) {
    Object.assign(state, makeState());
  }
};
