<template>
  <div class="task-step">
    <SearchSelect
      label="Task templates"
      placeholder="Select..."
      class="task-step__template-name"
      name="task-step-template-name"
      :id="`task-step__template-name-${id}`"
      :options="taskTemplatesOptions"
      :error="taskTemplateError"
      :searchValue="taskTemplateSearchValue"
      data-test-id="task-step__template-name"
      @change="
        $emit('change', {
          name: taskTemplateKeysDTO.TEMPLATE_TASK_ID,
          id,
          event: $event
        })
      "
      @create="
        $emit('create', {
          name: taskTemplateKeysDTO.TEMPLATE_TASK_ID,
          id,
          event: $event
        })
      "
      @reset="
        $emit('reset', {
          name: taskTemplateKeysDTO.TEMPLATE_TASK_ID,
          id,
          event: $event
        })
      "
    />
    <BaseText
      v-if="taskTemplateInformation"
      data-test-id="task-step__template-name--information"
      :text="taskTemplateInformation"
      :theme="themes.SUCCESS_INVERSE"
    />
    <template v-if="isTaskTemplateSelected">
      <div
        v-if="isExpectedDataAvailable"
        class="task-step__expected-data"
        data-test-id="task-step__expected-data"
      >
        <BaseText
          tag="h3"
          :size="typographySize.BODY_TEXT_BOLD"
          text="Expected data"
        />
        <ul
          class="task-step__expected-data-list"
          data-test-id="task-step__expected-data-list"
        >
          <li
            v-for="expectedData in taskTemplateExpectedData"
            :key="expectedData.id"
            class="task-step__expected-data-list-element"
            data-test-id="task-step__expected-data-list-element"
          >
            <ExpectedDataMapping
              :expectedData="expectedData"
              @change="
                $emit('change', {
                  ...$event,
                  name: taskTemplateKeysDTO.TASK_TEMPLATE_EXPECTED_DATA
                })
              "
            />
          </li>
        </ul>
      </div>
      <div
        class="task-step__email-destination"
        data-test-id="task-step__email-destination"
      >
        <Select
          class="task-step__assignee"
          data-test-id="task-step__assignee"
          label="Assignee"
          placeholder="Select assignee"
          name="assignee"
          id="assignee"
          :options="taskTemplateAssigneeOptions"
          :error="taskTemplateAssigneeError"
          @change="
            $emit('change', {
              name: taskTemplateKeysDTO.TASK_DEFAULT_ASSIGNEE,
              id,
              event: $event
            })
          "
        />
      </div>
    </template>
  </div>
</template>

<script>
import { taskTemplateKeysDTO, typographySize, themes } from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";
import SearchSelect from "@/molecules/SearchSelect/SearchSelect";
import Select from "@/molecules/Select/Select";
import ExpectedDataMapping from "@/molecules/ExpectedDataMapping/ExpectedDataMapping";

export default {
  name: "TaskStep",
  props: {
    taskTemplatesOptions: {
      type: Array,
      default: () => []
    },
    taskTemplateInformation: {
      type: String,
      default: ""
    },
    taskTemplateError: {
      type: String,
      default: ""
    },
    taskTemplateSearchValue: {
      type: String,
      default: ""
    },
    taskTemplateExpectedData: {
      type: Array,
      default: () => []
    },
    taskTemplateAssigneeOptions: {
      type: Array,
      default: () => []
    },
    taskTemplateAssigneeError: {
      type: String,
      default: ""
    },
    id: {
      type: Number,
      require: true
    }
  },
  components: {
    SearchSelect,
    BaseText,
    ExpectedDataMapping,
    Select
  },
  data() {
    return {
      taskTemplateKeysDTO,
      typographySize,
      themes
    };
  },
  computed: {
    isTaskTemplateSelected() {
      return (
        !!this.taskTemplateSearchValue &&
        !!this.taskTemplatesOptions.find(({ selected }) => selected)
      );
    },
    isExpectedDataAvailable() {
      return !!this.taskTemplateExpectedData.length;
    }
  }
};
</script>

<style lang="scss" scoped>
.task-step {
  &__assignee {
    display: block;
  }

  &__expected-data,
  &__email-destination {
    padding-top: 40px;
  }

  &__expected-data-list {
    list-style-type: none;
    padding: 0;
  }
}
</style>
