<template>
  <section class="add-edit-task-template">
    <form
      class="add-edit-task-template__form"
      data-test-id="add-edit-task-template__form"
      @submit.prevent
    >
      <InputErrorMessage :error="errorMessagesApi" />
      <div class="add-edit-task-template__section">
        <BaseText
          data-test-id="add-edit-task-template__title"
          text="Task template details"
          tag="h4"
          :size="typographySize.HEADING_FIVE"
        />
        <Input
          id="templateName"
          label="Template name"
          name="templateName"
          placeholder="Template name"
          data-test-id="add-edit-task-template__name"
          :value="templateName"
          :error="errorMessages.templateName"
          :focus="true"
          @change="onChangeInput({ key: 'templateName', event: $event })"
        />
        <Textarea
          id="templateDescription"
          label="Template description"
          name="templateDescription"
          placeholder="Template description"
          data-test-id="add-edit-task-template__description"
          :value="templateDescription"
          :error="errorMessages.templateDescription"
          @change="
            (_, $event) =>
              onChangeInput({ key: 'templateDescription', event: $event })
          "
        />
      </div>
      <div class="add-edit-task-template__section">
        <AccordionSection
          title="Expected data"
          class="add-edit-task-template__section-accordion"
          data-test-id="add-edit-task-template__section-accordion--expected-data"
          isCollapsable
        >
          <FormRowsList
            :form-rows-list="makeExpectedDataListComponents.formRowsList"
            :form-cta-list="makeExpectedDataListComponents.formCtaList"
          />
        </AccordionSection>
      </div>
      <div class="add-edit-task-template__section">
        <BaseText
          data-test-id="add-edit-task-template__title"
          text="Task content"
          tag="h4"
          :size="typographySize.HEADING_FIVE"
        />
        <MultiSelect
          id="add-edit-task-template__view-permissions"
          class="add-edit-task-template__view-permissions"
          label="View permissions"
          :options="editPermissionsOptions"
          name="task-templates-view-permissions"
          description="View permissions"
          placeholder="Select view permissions"
          :showAllSelectedOptions="true"
          :error="errorMessages.editPermissionsSelection"
          @change="
            onUpdateEmailDestinationOptions({
              event: $event,
              optionKey: 'editPermissionsOptions',
              selectionKey: 'editPermissionsSelection'
            })
          "
        />
        <MultiSelect
          id="add-edit-task-template__reopen-permissions"
          class="add-edit-task-template__reopen-permissions"
          label="Reopen permissions"
          :options="reopenPermissionsOptions"
          name="task-templates-reopen-permissions"
          description="Reopen permissions"
          placeholder="Select reopen permissions"
          :showAllSelectedOptions="true"
          :error="errorMessages.reopenPermissionsSelection"
          @change="
            onUpdateEmailDestinationOptions({
              event: $event,
              optionKey: 'reopenPermissionsOptions',
              selectionKey: 'reopenPermissionsSelection'
            })
          "
        />
        <Select
          class="add-edit-task-template__default-assignment"
          data-test-id="add-edit-task-template__default-assignment"
          label="Default assignment"
          placeholder="Select default assignment"
          name="default-assignment"
          id="defaultAssignment"
          :options="defaultAssignmentOptions"
          :error="errorMessages.defaultAssignment"
          @change="
            onChangeSelect({
              key: 'defaultAssignment',
              optionKey: 'defaultAssignmentOptions',
              event: $event
            })
          "
        />
        <Input
          id="taskSubject"
          label="Task subject"
          name="taskSubject"
          placeholder="Task subject"
          data-test-id="add-edit-task-template__subject"
          :value="taskSubject"
          :error="errorMessages.taskSubject"
          :focus="true"
          @change="onChangeInput({ key: 'taskSubject', event: $event })"
        />
        <Textarea
          id="taskTemplateDetails"
          label="Task details"
          name="taskTemplateDetails"
          placeholder="Task details"
          data-test-id="add-edit-task-template__details"
          :value="taskDescription"
          :error="errorMessages.taskDescription"
          :rows="10"
          @change="
            (_, $event) =>
              onChangeInput({
                key: 'taskDescription',
                event: $event
              })
          "
        />
      </div>
      <div class="add-edit-task-template__section">
        <BaseText
          data-test-id="add-edit-task-template__section-title"
          text="Task outcomes"
          tag="h4"
          :size="typographySize.HEADING_FIVE"
        />
        <AccordionSection
          v-for="(triggerStep, index) in triggerStepList"
          :key="triggerStep.id"
          :menuItems="stepBaseAccordionOptions"
          :title="
            makeAccordionTitle({
              index,
              title: actionStepTitleMap[triggerStep.stepType]
            })
          "
          class="add-edit-task-template__section-accordion"
          data-test-id="`add-edit-task-template__section-accordion--${index}`"
          isCollapsable
          @click="onAccordionDropDownSelection({ index, selection: $event })"
        >
          <TriggerStep
            v-bind="triggerStep"
            @change="onTriggerStepChange"
            @create="onTriggerStepChange"
            @reset="onTriggerStepChange"
          />
        </AccordionSection>
        <InputErrorMessage :error="errorMessages.triggerStepList" />
      </div>
      <div class="add-edit-task-template__form-cta-holder">
        <BaseFooter>
          <template #left>
            <CallToAction
              type="button"
              value="Add step"
              data-test-id="add-edit-task-template__form-cta-holder--add-step"
              @click="onAddEmptyTriggerStep"
            />
          </template>
          <template #right>
            <CallToAction
              type="button"
              theme="secondary"
              value="Close"
              class="add-edit-task-template__form-cta-holder--close"
              data-test-id="add-edit-task-template__form-cta-holder--close"
              @click="onCancel"
            />
            <CallToAction
              type="button"
              value="Submit"
              :is-loading="isSavingAction"
              :is-error="hasActionError"
              :is-success="isSavingSuccessful"
              data-test-id="add-edit-task-template__form-cta-holder--submit"
              @click="onSubmit"
            />
          </template>
        </BaseFooter>
      </div>
    </form>
  </section>
</template>

<script>
import {
  typographySize,
  taskTemplateKeysDTO,
  operations,
  getterName,
  urls,
  actionStepTitleMap,
  emptyOption,
  triggerStepKeys,
  stepBaseAccordionOptions,
  stepAccordionOptionValue,
  requestFromUserOption,
  actionName,
  timers,
  routeNames
} from "@/constants";
import FormRowsList from "@/molecules/FormRowsList/FormRowsList";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import Input from "@/molecules/Input/Input";
import BaseText from "@/atoms/BaseText/BaseText";
import Textarea from "@/molecules/Textarea/Textarea";
import MultiSelect from "@/molecules/MultiSelect/MultiSelect";
import Select from "@/molecules/Select/Select";
import TriggerStep from "@/organisms/TriggerStep/TriggerStep";
import AccordionSection from "@/molecules/AccordionSection/AccordionSection";
import BaseFooter from "@/atoms/BaseFooter/BaseFooter";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import { expectedDataMixin } from "@/mixins";
import { getSelectedValuesFromDestinationKey } from "@/organisms/AddEditUserEmailTemplate/AddEditUserEmailTemplate.logic";
import { makeOptionsForMultiSelect } from "@/molecules/MultiSelect/MultiSelect.dto";
import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";
import { mapGetters, mapActions } from "vuex";
import {
  makeUUID,
  getSelectedOption,
  getAllSelectedOptionValues,
  isExpectedDataEntityType
} from "@/utils";
import triggerStepMixin from "@/organisms/TriggerStep/TriggerStep.mixin";
import { makeAccordionTitle } from "@/organisms/AddEditAction/AddEditAction.logic";
import {
  validateTaskTemplateText,
  validateTaskTemplateBody,
  validateTriggerStepListLength
} from "@/organisms/AddEditTaskTemplate/AddEditTaskTemplate.validation";
import triggerStepValidationMixin from "@/organisms/TriggerStep/TriggerStepValidation.mixin";
import { cloneDeep } from "lodash";

export default {
  name: "AddEditTaskTemplate",
  components: {
    FormRowsList,
    InputErrorMessage,
    Input,
    BaseText,
    Textarea,
    MultiSelect,
    Select,
    TriggerStep,
    AccordionSection,
    BaseFooter,
    CallToAction
  },
  mixins: [expectedDataMixin, triggerStepMixin, triggerStepValidationMixin],
  props: {
    taskTemplate: {
      type: Object,
      default: () => ({})
    },
    destinationBaseOptions: {
      type: Array,
      default: () => []
    },
    rolesDestinationOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      actionStepTitleMap,
      stepBaseAccordionOptions,
      editPermissionsOptions: [],
      editPermissionsSelection: [],
      reopenPermissionsOptions: [],
      reopenPermissionsSelection: [],
      defaultAssignmentOptions: [],
      defaultAssignment: "",
      errorMessagesApi: "",
      templateName: "",
      templateDescription: "",
      taskSubject: "",
      taskDescription: "",
      errorMessages: {
        templateName: "",
        templateDescription: "",
        editPermissionsSelection: "",
        reopenPermissionsSelection: "",
        defaultAssignment: "",
        taskSubject: "",
        taskDescription: "",
        triggerStepList: ""
      },
      typographySize,
      taskTemplateKeysDTO,
      isSavingAction: false,
      hasActionError: false,
      isSavingSuccessful: false,
      submissionPermissionIndex: 0
    };
  },
  computed: {
    ...mapGetters({
      getCustomActionOptions: getterName.ACTIONS.GET_CUSTOM_ACTION_OPTIONS,
      getActionBasedOnId: getterName.ACTIONS.GET_ACTION_BASED_ON_ID
    }),
    isEditTaskTemplate() {
      return !!Object.keys(this.taskTemplate).length;
    }
  },
  watch: {
    hasActionError(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.hasActionError = false;
        }, timers.MODERATE);
      }
    },
    expectedData: {
      deep: true,
      handler() {
        this.updateTriggerStepOptions();
      }
    }
  },
  created() {
    this.setTaskTemplateData();
  },
  methods: {
    getSelectedValuesFromDestinationKey,
    makeOptionsForMultiSelect,
    makeOptionsForSelect,
    makeAccordionTitle,
    makeUUID,
    validateTaskTemplateText,
    validateTaskTemplateBody,
    validateTriggerStepListLength,
    getSelectedOption,
    getAllSelectedOptionValues,
    cloneDeep,
    isExpectedDataEntityType,
    ...mapActions({
      makeTaskTemplate: actionName.TASK_TEMPLATES.MAKE_TASK_TEMPLATE,
      updateTaskTemplate: actionName.TASK_TEMPLATES.UPDATE_TASK_TEMPLATE
    }),
    updateTriggerStepOptions() {
      this.triggerStepList.forEach((triggerStep) => {
        const updatedExpectedDataList =
          triggerStep?.expectDataList?.map((expectData) => {
            const { value = "", message = "" } = this.getSelectedOption(
              expectData.options
            );
            const options = this.makeOptionsForSelect(
              value,
              this.makeOptionsForExpectedData({
                type: expectData.type,
                message
              })
            );
            return {
              ...expectData,
              options,
              searchValue: this.getSelectedOption(options)?.value || ""
            };
          }) || [];

        triggerStep?.setExpectDataList(updatedExpectedDataList);
      });
    },
    onChangeInput({ key, event }) {
      this[key] = parseInt(event) ? event : event?.trim();
    },
    onChangeSelect({ key, optionKey, event }) {
      this.onChangeInput({ key, event });
      this[optionKey] = this.makeOptionsForSelect(
        this[key],
        this.destinationBaseOptions
      );
    },
    onUpdateEmailDestinationOptions({
      event = {},
      optionKey = "",
      selectionKey = ""
    } = {}) {
      const { eventType, value } = event;
      this.errorMessages[selectionKey] = "";

      if (eventType === operations.ADD) {
        this.updateEmailDestinationOptionsAdd({
          value,
          optionKey,
          selectionKey
        });
      } else if (eventType === operations.DELETE) {
        this.updateEmailDestinationOptionsDelete({
          value,
          optionKey,
          selectionKey
        });
      }
    },
    updateEmailDestinationOptionsAdd({ value, optionKey, selectionKey }) {
      this[selectionKey].push(value);
      this[optionKey] = this.makeOptionsForMultiSelect(
        this[selectionKey],
        this[selectionKey],
        this.rolesDestinationOptions
      );
    },
    updateEmailDestinationOptionsDelete({ value, optionKey, selectionKey }) {
      this[selectionKey] = this[selectionKey].filter(
        (selectedValue) => value !== selectedValue
      );
      this[optionKey] = this.makeOptionsForMultiSelect(
        this[selectionKey],
        this[selectionKey],
        this.rolesDestinationOptions
      );
    },
    makeTaskTemplateOutcomeExpectedDataCommonObject({
      triggeredExpectedDataVariableName,
      existingExpectedDataInAction,
      triggeringData,
      expectedDataIndex,
      taskOutcomeIndex
    }) {
      return {
        name: triggeredExpectedDataVariableName,
        options: this.makeOptionsForSelect(
          this.getSelectedValueFromTriggerData(triggeringData),
          this.makeOptionsForExpectedData({
            type: existingExpectedDataInAction?.expectedVariableType,
            message: triggeringData?.message
          })
        ),
        type: existingExpectedDataInAction?.expectedVariableType,
        searchValue: triggeringData?.data?.name,
        errorMessage: "",
        components: this.makeComponentsForRequestByUser({
          message: triggeringData?.message,
          expectedDataIndex,
          taskOutcomeIndex
        })
      };
    },
    makeTaskTemplateOutcomeExpectedDataCommon({
      expectedDataMapping = [],
      existingActionExpectedDataList = [],
      taskOutcomeIndex = 0
    } = {}) {
      return (
        expectedDataMapping?.reduce(
          (
            acc,
            { triggeredExpectedDataVariableName, triggeringData } = {},
            expectedDataIndex
          ) => {
            const existingExpectedDataInAction =
              existingActionExpectedDataList.find(
                ({ expectedVariableName } = {}) =>
                  expectedVariableName === triggeredExpectedDataVariableName
              );
            if (existingExpectedDataInAction) {
              acc.push(
                this.makeTaskTemplateOutcomeExpectedDataCommonObject({
                  triggeredExpectedDataVariableName,
                  existingExpectedDataInAction,
                  triggeringData,
                  expectedDataIndex,
                  taskOutcomeIndex
                })
              );
            }
            return acc;
          },
          []
        ) || []
      );
    },
    makeComponentsForRequestByUser({
      message = "",
      expectedDataIndex = 0,
      taskOutcomeIndex = 0
    } = {}) {
      return message
        ? [
            this.makeRequestByUserInput({
              expectedDataIndex,
              triggerStepIndex: taskOutcomeIndex,
              componentIndex: 0,
              requestByUser: message
            })
          ]
        : [];
    },
    makeTaskTemplateOutcomeExpectedDataNewObject({
      expectedVariableName,
      expectedVariableType
    }) {
      return {
        name: expectedVariableName,
        options: this.makeOptionsForSelect(
          "",
          this.makeOptionsForExpectedData({ type: expectedVariableType })
        ),
        type: expectedVariableType,
        searchValue: "",
        errorMessage: "",
        components: []
      };
    },
    makeTaskTemplateOutcomeExpectedDataNew({
      existingActionExpectedDataList = [],
      taskOutcome = {}
    } = {}) {
      return (
        existingActionExpectedDataList?.reduce(
          (acc, { expectedVariableName, expectedVariableType } = {}) => {
            const existingExpectedDataInOutcome =
              taskOutcome?.outcome?.expectedDataMapping.find(
                ({ triggeredExpectedDataVariableName }) =>
                  triggeredExpectedDataVariableName === expectedVariableName
              );
            if (!existingExpectedDataInOutcome) {
              acc.push(
                this.makeTaskTemplateOutcomeExpectedDataNewObject({
                  expectedVariableName,
                  expectedVariableType
                })
              );
            }
            return acc;
          },
          []
        ) || []
      );
    },
    makeTaskTemplateOutcomeExpectedData({ taskOutcome, taskOutcomeIndex }) {
      this.expectedDataActionsSynchronization[
        taskOutcome.outcome.actionId
      ] = false;
      const existingActionExpectedDataList =
        this.getActionBasedOnId(
          taskOutcome.outcome.actionId
        )?.expectedDataDefinition?.variables?.map(
          (expectedDataDefinitionVariable) =>
            this.makeExpectedDataObj(expectedDataDefinitionVariable)
        ) || [];
      const commonExpectedDataInActionAndOutcome =
        this.makeTaskTemplateOutcomeExpectedDataCommon({
          expectedDataMapping: taskOutcome?.outcome?.expectedDataMapping,
          existingActionExpectedDataList,
          taskOutcomeIndex
        });
      const newExpectedDataFromActionList =
        this.makeTaskTemplateOutcomeExpectedDataNew({
          existingActionExpectedDataList,
          taskOutcome
        });
      this.checkActionsSynchronizationOnLoad({
        existingActionExpectedDataList: existingActionExpectedDataList.map(
          ({ expectedVariableName } = {}) => expectedVariableName
        ),
        currentlySavedExpectedDataList:
          taskOutcome?.outcome?.expectedDataMapping.map(
            ({ triggeredExpectedDataVariableName }) =>
              triggeredExpectedDataVariableName
          ),
        actionId: taskOutcome.outcome.actionId
      });
      return [
        ...commonExpectedDataInActionAndOutcome,
        ...newExpectedDataFromActionList
      ];
    },
    submissionPermissionsComponent({
      outcomePermissionsSelection = [],
      id = 0
    } = {}) {
      return {
        component: MultiSelect,
        componentOptions: {
          label: "Submission permissions",
          placeholder: "Select...",
          name: "trigger-step-permissions-submission",
          id: `"trigger-step-permissions-submission-${this.submissionPermissionIndex}"`,
          "data-test-id": `"trigger-step-permissions-submission-${this.submissionPermissionIndex}"`,
          description: "Submissions permission",
          showAllSelectedOptions: true,
          options: this.makeOptionsForMultiSelect(
            outcomePermissionsSelection,
            outcomePermissionsSelection,
            this.rolesDestinationOptions
          ),
          error: "",
          onChange: ({ value = "", eventType = "" } = {}) => {
            this.changeDynamicMultiSelectOptionsInTriggerStep({
              id,
              value,
              operation: eventType,
              dynamicComponentIndex: this.submissionPermissionIndex
            });
          }
        },
        id: this.makeUUID()
      };
    },
    makeTaskTemplateOutcome({
      outcomePermissionsSelection,
      taskOutcome,
      taskOutcomeIndex
    }) {
      const triggerStep = this.onAddEmptyTriggerStep();
      triggerStep.setAdditionalComponentsList([
        this.submissionPermissionsComponent({
          outcomePermissionsSelection,
          id: triggerStep.id
        })
      ]);
      triggerStep.setActionNameOptions(
        this.makeOptionsForSelect(taskOutcome?.outcome?.actionId, [
          {
            ...emptyOption,
            text: "Select action"
          },
          ...this.getCustomActionOptions
        ])
      );
      triggerStep.setActionNameErrorMessage("");
      triggerStep.setExpectDataList(
        this.makeTaskTemplateOutcomeExpectedData({
          taskOutcome,
          taskOutcomeIndex
        })
      );
    },
    getSelectedValueFromTriggerData({
      message = "",
      data = {},
      requestFromUserValue = requestFromUserOption.value
    } = {}) {
      return message ? requestFromUserValue : data?.name;
    },
    makeRequestByUserInput({
      expectedDataIndex,
      triggerStepIndex,
      componentIndex,
      requestByUser
    }) {
      return {
        component: Input,
        componentOptions: {
          label: "Request from user",
          placeholder: "Request from user",
          isLabelHidden: true,
          id: `task-outcome-request-by-user-${triggerStepIndex}-${expectedDataIndex}`,
          name: `request-by-user-${triggerStepIndex}-${expectedDataIndex}`,
          "data-test-id": `request-by-user-${triggerStepIndex}-${expectedDataIndex}`,
          value: requestByUser,
          error: "",
          onChange: (event) =>
            this.changeTaskOutcomeRequestByUser({
              expectedDataIndex,
              triggerStepIndex,
              componentIndex,
              event
            })
        },
        id: this.makeUUID()
      };
    },
    changeTaskOutcomeRequestByUser({
      expectedDataIndex = 0,
      triggerStepIndex = 0,
      componentIndex = 0,
      event = ""
    }) {
      this.triggerStepList[triggerStepIndex].expectDataList[
        expectedDataIndex
      ].components[componentIndex].componentOptions.value = event;
      const requestFromUserOptionObj = this.triggerStepList[
        triggerStepIndex
      ].expectDataList[expectedDataIndex].options.find(
        ({ value }) => value === requestFromUserOption.value
      );
      requestFromUserOptionObj.message = event;
    },
    makeOptionsForExpectedData({ type = "", message = "" } = {}) {
      return this.expectedData?.reduce(
        (acc, { expectedVariableName, expectedVariableType } = {}) => {
          if (expectedVariableType === type) {
            acc.push({
              text: expectedVariableName,
              value: expectedVariableName
            });
          }
          return acc;
        },
        [
          ...(this.isExpectedDataEntityType(type)
            ? []
            : [
                {
                  ...requestFromUserOption,
                  message
                }
              ])
        ]
      );
    },
    setTaskTemplateFlatData() {
      this.templateName = this.taskTemplate?.templateName;
      this.templateDescription = this.taskTemplate?.templateDescription;
      this.taskSubject = this.taskTemplate.taskSubject;
      this.taskDescription = this.taskTemplate.taskDescription;
    },
    setTaskTemplateOptionsData() {
      this.expectedData =
        this.taskTemplate?.expectedDataDefinition?.map((expectedData) =>
          this.makeExpectedDataObj(expectedData)
        ) || [];
      this.editPermissionsSelection =
        this.getSelectedValuesFromDestinationKey(
          this.taskTemplate?.editPermissions
        )?.selectedValues || [];
      this.editPermissionsOptions = this.makeOptionsForMultiSelect(
        this.editPermissionsSelection,
        this.editPermissionsSelection,
        this.rolesDestinationOptions
      );
      this.reopenPermissionsSelection =
        this.getSelectedValuesFromDestinationKey(
          this.taskTemplate?.reopenPermissions
        )?.selectedValues || [];
      this.reopenPermissionsOptions = this.makeOptionsForMultiSelect(
        this.reopenPermissionsSelection,
        this.reopenPermissionsSelection,
        this.rolesDestinationOptions
      );
      this.defaultAssignment =
        this.getSelectedValuesFromDestinationKey(
          this.taskTemplate?.taskDefaultAssignee
        )?.selectedValues[0] || "";
      this.defaultAssignmentOptions = this.makeOptionsForSelect(
        this.defaultAssignment,
        [
          {
            ...emptyOption,
            text: "Select default assignment"
          },
          ...this.destinationBaseOptions
        ]
      );
    },
    setTaskTemplateOutcomeActionsData() {
      this.taskTemplate.outcomeActions?.forEach(
        (taskOutcome, taskOutcomeIndex) => {
          this.makeTaskTemplateOutcome({
            outcomePermissionsSelection:
              this.getSelectedValuesFromDestinationKey(
                taskOutcome.outcomePermissions
              )?.selectedValues || [],
            taskOutcome,
            taskOutcomeIndex
          });
        }
      );
    },
    setTaskTemplateData() {
      this.setTaskTemplateFlatData();
      this.setTaskTemplateOptionsData();
      this.setTaskTemplateOutcomeActionsData();
      this.validateTriggerStepListActionInformation();
    },
    makeTaskTemplatePayload() {
      return {
        [taskTemplateKeysDTO.TEMPLATE_NAME]: this.templateName,
        [taskTemplateKeysDTO.TEMPLATE_DESCRIPTION]: this.templateDescription,
        [taskTemplateKeysDTO.EXPECTED_DATA_DEFINITION]: this.expectedData,
        [taskTemplateKeysDTO.EDIT_PERMISSIONS]: this.editPermissionsOptions,
        [taskTemplateKeysDTO.REOPEN_PERMISSIONS]: this.reopenPermissionsOptions,
        [taskTemplateKeysDTO.TASK_DEFAULT_ASSIGNEE]:
          this.defaultAssignmentOptions,
        [taskTemplateKeysDTO.TASK_SUBJECT]: this.taskSubject,
        [taskTemplateKeysDTO.TASK_DESCRIPTION]: this.taskDescription,
        [taskTemplateKeysDTO.OUTCOME_ACTIONS]: this.triggerStepList.map(
          (triggerStep) => ({
            ...triggerStep,
            submissionPermissionsOptions:
              triggerStep.additionalComponentsList[
                this.submissionPermissionIndex
              ].componentOptions.options
          })
        )
      };
    },
    makeUpdateTaskTemplatePayload() {
      return {
        [taskTemplateKeysDTO.ID]: this.taskTemplate?.id,
        [taskTemplateKeysDTO.COMPANY_ID]: this.taskTemplate?.companyId,
        ...this.makeTaskTemplatePayload()
      };
    },
    async onSubmit() {
      this.isSavingAction = true;
      this.hasActionError = false;
      const isValidData = !this.isInvalidTaskTemplate();
      try {
        if (isValidData) {
          await this.submitTaskTemplate();
        }
      } catch {
        this.onSubmitFailure();
      } finally {
        this.isSavingAction = false;
      }
    },
    onSubmitFailure() {
      this.hasActionError = true;
      if (this.isEditTaskTemplate) {
        this.errorMessagesApi = "Failed to updated the task template.";
      } else {
        this.errorMessagesApi = "Failed to create a task template.";
      }
    },
    async submitTaskTemplate() {
      if (this.isEditTaskTemplate) {
        await this.updateTaskTemplate(this.makeUpdateTaskTemplatePayload());
        this.submitTaskTemplateSuccess("updated");
      } else {
        await this.makeTaskTemplate(this.makeTaskTemplatePayload());
        this.submitTaskTemplateSuccess("created");
      }
    },
    submitTaskTemplateSuccess(operation) {
      this.isSavingSuccessful = true;
      this.$router.push({
        name: routeNames.TASK_TEMPLATES,
        state: { message: `Task template ${operation} successfully` }
      });
    },
    isInvalidTaskTemplate() {
      this.errorMessages.templateName = this.validateTaskTemplateText(
        this.templateName
      );
      this.errorMessages.templateDescription = this.validateTaskTemplateText(
        this.templateDescription
      );
      this.errorMessages.editPermissionsSelection =
        this.validateTaskTemplateText(this.editPermissionsSelection);
      this.errorMessages.reopenPermissionsSelection =
        this.validateTaskTemplateBody(this.reopenPermissionsSelection);
      this.errorMessages.defaultAssignment = this.validateTaskTemplateBody(
        this.defaultAssignment
      );
      this.errorMessages.taskSubject = this.validateTaskTemplateText(
        this.taskSubject
      );
      this.errorMessages.taskDescription = this.validateTaskTemplateBody(
        this.taskDescription
      );
      this.errorMessages.triggerStepList = this.validateTriggerStepListLength(
        this.triggerStepList.length
      );
      const isTriggerStepListValid = this.validateTriggerStepList();
      const isExpectedDataValid = this.validateExpectedData({
        expectedData: this.expectedData
      });

      return (
        !!this.errorMessages.templateName ||
        !!this.errorMessages.templateDescription ||
        !!this.errorMessages.editPermissionsSelection ||
        !!this.errorMessages.reopenPermissionsSelection ||
        !!this.errorMessages.defaultAssignment ||
        !!this.errorMessages.taskSubject ||
        !!this.errorMessages.taskDescription ||
        !!this.errorMessages.triggerStepList ||
        isTriggerStepListValid ||
        isExpectedDataValid
      );
    },
    onCancel() {
      this.$router.push(urls.TASK_TEMPLATES);
    },
    makeEmptyTriggerStep() {
      return this.makeTriggerStep({
        actionNameOptions: this.makeOptionsForSelect("", [
          { ...emptyOption, text: "Select action" },
          ...this.getCustomActionOptions
        ])
      });
    },
    onAddEmptyTriggerStep() {
      const triggerStep = this.makeEmptyTriggerStep();
      triggerStep.setAdditionalComponentsList([
        this.submissionPermissionsComponent({
          outcomePermissionsSelection: [],
          id: triggerStep.id
        })
      ]);
      this.triggerStepList.push(triggerStep);
      return triggerStep;
    },
    makeTriggerStepExpectedData(actionId) {
      const selectedAction = this.getActionBasedOnId(actionId);
      const expectedDataForSelectedAction =
        selectedAction?.expectedDataDefinition?.variables?.map(
          (expectedDataDefinitionVariable) =>
            this.makeExpectedDataObj(expectedDataDefinitionVariable)
        ) || [];
      return (
        expectedDataForSelectedAction?.map(
          ({ expectedVariableName, expectedVariableType } = {}) => ({
            name: expectedVariableName,
            options: this.makeOptionsForSelect(
              "",
              this.makeOptionsForExpectedData({ type: expectedVariableType })
            ),
            type: expectedVariableType,
            searchValue: "",
            errorMessage: "",
            components: []
          })
        ) || []
      );
    },
    triggerStepChangeAction({ triggeringId, actionId }) {
      const triggerStep =
        this.triggerStepList.find(({ id }) => id === triggeringId) || {};
      triggerStep?.setActionNameOptions(
        this.makeOptionsForSelect(actionId, triggerStep?.actionNameOptions)
      );
      triggerStep?.setActionNameErrorMessage("");
      triggerStep?.setExpectDataList(
        this.makeTriggerStepExpectedData(actionId)
      );
      triggerStep.setAdditionalComponentsList([
        this.submissionPermissionsComponent({
          outcomePermissionsSelection: [],
          id: triggerStep.id
        })
      ]);
    },
    onTriggerStepChange($event) {
      if ($event.property === triggerStepKeys.ACTIONS) {
        this.triggerStepChangeAction({
          triggeringId: $event.id,
          actionId: $event.event
        });
      } else if ($event.property === triggerStepKeys.EXPECTED_DATA_MAPPING) {
        this.triggerStepChangeExpectedData({
          triggeringId: $event.id,
          expectedDataName: $event.name,
          expectedDataNameNewValue: $event.event
        });
      }
    },
    changeDynamicMultiSelectOptionsInTriggerStep({
      id,
      value,
      operation,
      dynamicComponentIndex
    }) {
      const triggerStep =
        this.triggerStepList.find((triggerStep) => triggerStep?.id === id) ||
        {};
      const additionalComponentsList = this.cloneDeep(
        triggerStep.additionalComponentsList
      );
      let selectedOptionValues = this.getAllSelectedOptionValues(
        additionalComponentsList[dynamicComponentIndex].componentOptions.options
      );

      if (operation === operations.DELETE) {
        selectedOptionValues = selectedOptionValues.filter(
          (currentValue) => currentValue !== value
        );
      } else if (operation === operations.ADD) {
        selectedOptionValues = [...selectedOptionValues, value];
      }

      additionalComponentsList[dynamicComponentIndex].componentOptions.options =
        this.makeOptionsForMultiSelect(
          selectedOptionValues,
          selectedOptionValues,
          this.rolesDestinationOptions
        );
      triggerStep.setAdditionalComponentsList(additionalComponentsList);
    },
    triggerStepChangeExpectedData({
      triggeringId,
      expectedDataName,
      expectedDataNameNewValue
    }) {
      let triggerStepIndex;
      const triggerStep =
        this.triggerStepList.find((triggerStep, index) => {
          if (triggerStep?.id === triggeringId) {
            triggerStepIndex = index;
            return triggerStep;
          } else {
            return false;
          }
        }) || {};
      let expectedDataIndex;
      const expectedData = triggerStep.expectDataList.find(
        (expectedDataElement, index) => {
          if (expectedDataElement.name === expectedDataName) {
            expectedDataIndex = index;
            return expectedDataElement;
          } else {
            return false;
          }
        }
      );
      expectedData.options = this.makeOptionsForSelect(
        expectedDataNameNewValue,
        expectedData.options
      );
      expectedData.components = this.makeExpectedDataComponents({
        expectedDataNameNewValue,
        expectedDataIndex,
        triggerStepIndex
      });
    },
    makeExpectedDataComponents({
      expectedDataNameNewValue = "",
      expectedDataIndex = 0,
      triggerStepIndex = 0
    } = {}) {
      if (expectedDataNameNewValue === requestFromUserOption.value) {
        return [
          this.makeRequestByUserInput({
            expectedDataIndex,
            triggerStepIndex,
            componentIndex: 0,
            requestByUser: ""
          })
        ];
      } else {
        return [];
      }
    },
    insertTriggerStepAtPosition(index) {
      const triggerStep = this.makeEmptyTriggerStep();
      this.triggerStepList.splice(index, 0, triggerStep);
    },
    onAccordionDropDownSelection({ index, selection }) {
      if (selection === stepAccordionOptionValue.ADD_STEP_ABOVE) {
        this.insertTriggerStepAtPosition(index);
      } else if (selection === stepAccordionOptionValue.ADD_STEP_BELOW) {
        this.insertTriggerStepAtPosition(index + 1);
      } else if (selection === stepAccordionOptionValue.DELETE_STEP) {
        this.removeTriggerStep(index);
      }
    },
    removeTriggerStep(removeIndex) {
      this.triggerStepList = this.triggerStepList.filter(
        (_, index) => index !== removeIndex
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.add-edit-task-template {
  height: calc(100vh - 130px);
  overflow-y: scroll;
  padding: 0 20px 0 0;
  text-align: left;

  &__reopen-permissions,
  &__view-permissions {
    margin-bottom: 20px;
  }

  &__section {
    &-accordion {
      margin-bottom: 30px;
    }
  }

  &__default-assignment {
    display: block;
  }

  &__form-cta-holder {
    padding-bottom: 15px;

    &--close {
      margin-right: 20px;
    }
  }
}
</style>
