<template>
  <div>
    <BaseText
      v-if="isReadOnly"
      class="set-to-block--read-only"
      data-test-id="set-value-data-type--read-only"
      :text="valueDataTypeText"
    />
    <Select
      v-else
      isLabelHidden
      label="Set value type"
      name="set-value-data-type"
      data-test-id="set-value-data-type"
      id="set-value-data-type"
      :options="valueDataTypeOptions"
      :error="valueDataTypeErrorMessage"
      @change="$emit('change', { name: 'valueDataType', id, event: $event })"
    />
    <!-- eslint-disable-next-line vue/require-component-is -->
    <Select
      v-if="!isMiddleSelectHidden"
      isLabelHidden
      label="Set value data type"
      name="set-value-type"
      data-test-id="set-value-type"
      id="set-value-type"
      :options="valueTypeOptions"
      :error="valueTypeErrorMessage"
      @change="$emit('change', { name: 'valueType', id, event: $event })"
    />
    <!-- eslint-disable-next-line vue/require-component-is -->
    <component
      v-if="isComponentVisible()"
      v-bind="componentOptions"
      :is="component"
      data-test-id="set-value-value"
      @change="$emit('change', { name: 'value', id, event: $event })"
    />
  </div>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import Select from "@/molecules/Select/Select";
import { isEmpty } from "lodash";

export default {
  name: "SetToBlock",
  props: {
    isReadOnly: {
      type: Boolean,
      default: false
    },
    valueDataTypeText: {
      type: String,
      default: ""
    },
    valueDataTypeOptions: {
      type: Array,
      default: () => []
    },
    valueDataTypeErrorMessage: {
      type: String,
      default: ""
    },
    valueTypeOptions: {
      type: Array,
      default: () => []
    },
    valueTypeErrorMessage: {
      type: String,
      default: ""
    },
    isMiddleSelectHidden: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      require: true
    },
    component: {
      type: Object,
      default: () => ({})
    },
    componentOptions: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    BaseText,
    Select
  },
  methods: {
    isComponentVisible() {
      return !isEmpty(this.component);
    }
  }
};
</script>

<style scoped lang="scss">
:deep(.set-to-block--read-only) {
  padding-top: 9px;
}
</style>
