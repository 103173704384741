import { endpoints } from "@/constants";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { makeCustomAttributeChartDataDTO } from "./dto/charts.dto";

const fetchPriChartData = (companyId) =>
  HTTP(
    "post",
    endpoints.CHART_PRI,
    {
      companyId
    },
    makeAuthorizationHeader()
  );

const fetchDdqRiskScoreData = (formId, companyId) =>
  HTTP(
    "get",
    `${endpoints.DASHBOARD_CHART_RISK_SCORE}?formId=${formId}&companyId=${companyId}`,
    null,
    makeAuthorizationHeader()
  );

const fetchTpStatData = (companyId, queryParams) =>
  HTTP(
    "get",
    `${endpoints.CHART_TP_STATE_DATA}/${companyId}`,
    null,
    makeAuthorizationHeader(),
    queryParams
  );

const fetchWorldMapChartsData = (companyId, queryParams) =>
  HTTP(
    "post",
    endpoints.CHART_COUNTRY,
    {
      companyId
    },
    makeAuthorizationHeader(),
    queryParams
  );

const fetchMonitoringAlerts = (postObj, queryParams) =>
  HTTP(
    "post",
    endpoints.CHART_MONITORING_ALERTS,
    postObj,
    makeAuthorizationHeader(),
    queryParams
  );

const fetchSanctionsAlertsCount = (companyId, queryParams) =>
  HTTP(
    "get",
    `${endpoints.CHART_SANCTIONS_ALERT}/${companyId}`,
    null,
    makeAuthorizationHeader(),
    queryParams
  );

const fetchIddPlusPostRemediationCount = (companyId) =>
  HTTP(
    "get",
    `${endpoints.CHART_IDD_PLUS_POST_REMEDIATION}/${companyId}`,
    null,
    makeAuthorizationHeader()
  );

const fetchIddPlusPreRemediationCount = (companyId) =>
  HTTP(
    "get",
    `${endpoints.CHART_IDD_PLUS_PRE_REMEDIATION}/${companyId}`,
    null,
    makeAuthorizationHeader()
  );

const fetchEddResultCount = (companyId) =>
  HTTP(
    "get",
    `${endpoints.CHART_EDD_RESULTS}/${companyId}`,
    null,
    makeAuthorizationHeader()
  );

const fetchTpCountries = (companyId, queryParams) =>
  HTTP(
    "get",
    `${endpoints.CHART_THIRD_PARTY_COUNTRY}/${companyId}`,
    null,
    makeAuthorizationHeader(),
    queryParams
  );

const fetchDashboardDdq = (postObj) =>
  HTTP(
    "post",
    endpoints.DASHBOARD_GET_DDQ_STATE,
    postObj,
    makeAuthorizationHeader()
  );

const fetchCustomAttributeChartData = async (companyId, customAttributeId) => {
  const { data } = await HTTP(
    "get",
    endpoints.CHART_CUSTOM_ATTRIBUTE_DATA(companyId, customAttributeId),
    null,
    makeAuthorizationHeader()
  );

  return makeCustomAttributeChartDataDTO(data?.data);
};

export const chartsService = {
  fetchPriChartData,
  fetchDdqRiskScoreData,
  fetchTpStatData,
  fetchWorldMapChartsData,
  fetchMonitoringAlerts,
  fetchSanctionsAlertsCount,
  fetchIddPlusPostRemediationCount,
  fetchIddPlusPreRemediationCount,
  fetchEddResultCount,
  fetchTpCountries,
  fetchDashboardDdq,
  fetchCustomAttributeChartData
};
