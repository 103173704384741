<template>
  <div class="search-bar" data-test-id="search-bar">
    <label
      :for="getInputId"
      class="search-bar__label"
      data-test-id="search-bar__label"
      >Search</label
    >
    <input
      :id="getInputId"
      :value="value"
      class="search-bar__input"
      data-test-id="search-bar__input"
      type="search"
      placeholder="Search"
      @input="$emit('input', $event)"
      @keydown.enter="
        $emit(DOMEvents.CLICK, { type: genericEvents.SEARCH, event: $event })
      "
    />
    <CallToAction
      v-if="showClearTextButton"
      icon="times"
      :icon-shape="shapes.CIRCLE"
      :theme="getClearTextTheme"
      :icon-size="8"
      value="Clear text"
      class="search-bar__button--clear"
      data-test-id="search-bar__button--clear"
      @mouseenter="setIsClearTextHovered(true)"
      @mouseleave="setIsClearTextHovered(false)"
      @focusin="setIsClearTextHovered(true)"
      @focusout="setIsClearTextHovered(false)"
      @click="
        $emit(DOMEvents.CLICK, { type: genericEvents.CLEAR, event: $event })
      "
    />
    <CallToAction
      icon="search"
      value="Search"
      button-type="submit"
      class="search-bar__button--search"
      no-border-styles
      :icon-space="space.LARGE"
      data-test-id="search-bar__button--search"
      :theme="themes.NONE"
      @click="
        $emit(DOMEvents.CLICK, { type: genericEvents.SEARCH, event: $event })
      "
    />
  </div>
</template>

<script>
import { makeUUID } from "@/utils";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import { DOMEvents, genericEvents, shapes, space, themes } from "@/constants";

export default {
  name: "SearchBar",
  components: { CallToAction },
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isClearTextHovered: false,
      shapes,
      themes,
      DOMEvents,
      genericEvents,
      space
    };
  },
  computed: {
    showClearTextButton() {
      return !!this.value;
    },
    getClearTextTheme() {
      return this.isClearTextHovered ? themes.INVERSE : themes.NONE;
    },
    getInputId() {
      return `search-bar__${makeUUID()}`;
    }
  },
  methods: {
    setIsClearTextHovered(value) {
      this.isClearTextHovered = value;
    }
  }
};
</script>

<style scoped lang="scss">
.search-bar {
  display: flex;
  border-radius: $border-radius8;
  overflow: hidden;
  border: $element-border;
  background: $white;
  align-items: center;
  position: relative;

  &__label {
    @include screen-reader-only;
  }

  &__input {
    height: 100%;
    width: 100%;
    padding: 0 25px 0 5px;
    border: 0;
    border-right: $element-border;

    &:focus {
      @include input-focus;
      border-bottom-left-radius: $border-radius8;
      border-top-left-radius: $border-radius8;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button {
      appearance: none;
    }
  }

  &__button {
    &--clear {
      position: absolute;
      right: 35px;
    }

    &--search {
      height: 100%;
    }
  }
}
</style>
