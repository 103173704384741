import { operations, predefinedLiteralOptions } from "@/constants";

const getSelectedOption = (options = []) =>
  options?.find((option) => option?.selected) || {};

const getAllSelectedOptions = (options = []) =>
  options?.filter((option) => option?.selected);

const getTextBasedOnValueFromOptions = ({ options = [], value = "" }) =>
  options?.find((option) => option?.value === value)?.text || "";

const getAllSelectedOptionValues = (options = []) =>
  options?.reduce((acc, { selected, value }) => {
    if (selected) {
      acc.push(value);
    }
    return acc;
  }, []) || [];

const getSelectedComparatorOption = (value, isInverse, isHasOrHasNoValue) => {
  if (isHasOrHasNoValue) {
    if (isInverse) {
      return operations.HAS_VALUE;
    }

    return operations.HAS_NO_VALUE;
  }

  if (isInverse) {
    return `NOT_${value}`;
  }

  return value;
};

const isPredefinedLiteralOption = ({ valueDataType, value }) =>
  !!predefinedLiteralOptions[valueDataType]?.find(
    (predefinedLiteralOption) => predefinedLiteralOption.value === value
  ) || false;

export {
  getSelectedOption,
  getAllSelectedOptions,
  getAllSelectedOptionValues,
  getSelectedComparatorOption,
  isPredefinedLiteralOption,
  getTextBasedOnValueFromOptions
};
