<template id="template-rating">
  <div class="bg-light p-3 pb-5 mb-2">
    <!-- menu buttons -->
    <MenuButtons
      :fieldData="fieldData"
      :collapse="collapse"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <b-collapse class="mt-5" id="collapse-1" v-model="collapse">
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1' && !isPreview"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <label>Your question here</label>
      <br />
      <span class="fa fa-star checked"></span>
      <span class="fa fa-star checked"></span>
      <span class="fa fa-star checked"></span>
      <span class="fa fa-star"></span>
      <span class="fa fa-star"></span>
    </b-collapse>
  </div>
</template>

<script>
import MenuButtons from "../menuButtons";
import { EventBus } from "@/event-bus.js";

export default {
  name: "RatingGroup",
  components: {
    MenuButtons
  },
  data() {
    return {
      isHidden: false,
      condition: {},
      showSettings: false,
      collapse: true
    };
  },
  methods: {
    onSaveData() {
      EventBus.emit("onSaveSettings", "saving settings");
    }
  },
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number
  }
};
</script>
