import {
  breadcrumbRouteNames,
  routeKeys,
  routeNames,
  templates,
  urls
} from "@/constants";
import UBOProfileTemplate from "@/templates/UBOProfileTemplate/UBOProfileTemplate";
import EntityDueDiligenceTemplate from "@/templates/EntityDueDiligenceTemplate/EntityDueDiligenceTemplate";
import EntityTableWithHeadingTemplate from "@/templates/EntityTableWithHeadingTemplate/EntityTableWithHeadingTemplate";
import UltimateBeneficialOwnersEntityProfile from "@/organisms/UltimateBeneficialOwnersEntityProfile/UltimateBeneficialOwnersEntityProfile";
import store from "@/store";

export default [
  {
    path: urls.ULTIMATE_BENEFICIAL_OWNERS_PROFILE(),
    name: routeNames.ULTIMATE_BENEFICIAL_OWNERS_PROFILE,
    component: UBOProfileTemplate,
    redirect: {
      name: routeNames[routeKeys.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DETAILS]
    },
    children: [
      {
        path: urls.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DETAILS(),
        name: routeNames[routeKeys.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DETAILS],
        component: UltimateBeneficialOwnersEntityProfile,
        props: true,
        meta: {
          requiresAuth: true,
          template: templates.EB360,
          isEb360HeaderRequired: true,
          isEB360SideBarRequired: true,
          breadcrumbs: (route) => [
            { name: breadcrumbRouteNames.HOME, link: urls.HOME },
            {
              name: breadcrumbRouteNames.THIRD_PARTY_PROFILE,
              link: urls.THIRD_PARTY_PROFILE_COMPANY(
                route.params.thirdPartyId,
                store.state.company.companyId
              )
            },
            {
              name: breadcrumbRouteNames.ULTIMATE_BENEFICIAL_OWNERS_PROFILE
            }
          ]
        }
      },
      {
        path: urls.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DUE_DILIGENCE(),
        name: routeNames[
          routeKeys.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DUE_DILIGENCE
        ],
        component: EntityDueDiligenceTemplate,
        props: true,
        redirect: {
          name: routeNames[
            routeKeys.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DUE_DILIGENCE_REPORTS
          ]
        },
        children: [
          {
            path: urls.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DUE_DILIGENCE_REPORTS(),
            name: routeNames[
              routeKeys.ULTIMATE_BENEFICIAL_OWNERS_PROFILE_DUE_DILIGENCE_REPORTS
            ],
            component: EntityTableWithHeadingTemplate,
            props: true,
            meta: {
              requiresAuth: true,
              template: templates.EB360,
              isEb360HeaderRequired: true,
              isEB360SideBarRequired: true,
              breadcrumbs: (route) => [
                { name: breadcrumbRouteNames.HOME, link: urls.HOME },
                {
                  name: breadcrumbRouteNames.THIRD_PARTY_PROFILE,
                  link: urls.THIRD_PARTY_PROFILE_COMPANY(
                    route.params.thirdPartyId,
                    store.state.company.companyId
                  )
                },
                {
                  name: breadcrumbRouteNames.ULTIMATE_BENEFICIAL_OWNERS_PROFILE
                }
              ]
            }
          }
        ]
      }
    ]
  }
];
