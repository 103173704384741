const regex = {
  MAIL_TO: /^((mailto):)/,
  HTTP: /^((http|https):\/\/)/,
  VALID_MAIL_TO_LINK:
    /^(mailto):{1}(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  VALID_EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  VALID_LINK:
    /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)?$/,
  CHECK_URL: /:\/\/(www[0-9]?\.)?(.[^/:]+)/i,
  ALPHA_NUMERIC: /[^0-9a-zA-Z]/
};

export { regex };
