<template>
  <span>
    <span v-if="tableFieldData.meta_json && JSON.parse(tableFieldData.meta_json).action_taken != undefined">
      <span v-if="checkActionTakkenCondition()">
        <span>Weak</span>
      </span>
      <span v-else>
        <span v-if="mediaScoring.score">
          <span v-if="type">
            <span v-if="mediaScoring.score == '1'"> {{type}} : <b>Weak ({{mediaScoring.value}})</b> </span>
            <span v-else-if="mediaScoring.score == '2'"> {{type}} : <b>Moderate ({{mediaScoring.value}})</b> </span>
            <span v-else-if="mediaScoring.score == '3'">{{type}} : <b>Strong ({{mediaScoring.value}})</b> </span>
            <span v-else>Weak</span>
          </span>
           <span v-else>
            <span v-if="mediaScoring.score == '1'">Weak : <b>{{mediaScoring.value}}</b> </span>
            <span v-else-if="mediaScoring.score == '2'">Moderate : <b>{{mediaScoring.value}}</b> </span>
            <span v-else-if="mediaScoring.score == '3'">Strong : <b>{{mediaScoring.value}}</b> </span>
            <span v-else>Weak</span>
          </span>
        </span>
        <span
          style="float:right"
          v-else
        ><span>Weak</span></span>
      </span>
    </span>
    <span v-else>
      <span>
        <BaseIcon
          :shape="shapes.SQUARE"
          style="float: right"
          :border="border.DASHED"
          :size="25"
        />
      </span>
    </span>
  </span>
</template>
<script>
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import { themes, icons, shapes, border } from "@/constants";

export default {
  name: "ApriScoringResult",
  components: {
    BaseIcon
  },
  data() {
    return {
      themes,
      icons,
      shapes,
      border
    };
  },
  props: {
    tableFieldData: Object,
    mediaScoring: Object,
    columnName: String,
    type: String
  },
  methods: {
    checkActionTakkenCondition () {
      let index = null;
      index = this.tableFieldData.meta_json && JSON.parse( this.tableFieldData.meta_json ).action_taken.findIndex( ( x ) => x.title == this.columnName );
      if ( index == -1 ) {
        return true;
      } else {
        return false;
      }
    },
  }
}
</script>