import { userEmailTemplatesService } from "@/services";
import { actionName, mutationName } from "@/constants";

export const actions = {
  async fetchUserEmailTemplates({ commit, rootState }) {
    try {
      commit(
        mutationName.USER_EMAIL_TEMPLATES.SET_IS_LOADING_USER_EMAIL_TEMPLATES,
        true,
        {
          root: true
        }
      );
      const { data } = await userEmailTemplatesService.fetchUserEmailTemplates(
        rootState.company.companyId
      );
      commit(mutationName.USER_EMAIL_TEMPLATES.SET_USER_EMAIL_TEMPLATES, data, {
        root: true
      });
    } finally {
      commit(
        mutationName.USER_EMAIL_TEMPLATES.SET_IS_LOADING_USER_EMAIL_TEMPLATES,
        false,
        {
          root: true
        }
      );
    }
  },
  async fetchUserEmailTemplate({ commit, rootState }, templateId) {
    const { data } = await userEmailTemplatesService.fetchUserEmailTemplate({
      companyId: rootState.company.companyId,
      templateId
    });
    commit(mutationName.USER_EMAIL_TEMPLATES.SET_USER_EMAIL_TEMPLATE, data, {
      root: true
    });
  },
  async makeUserEmailTemplate({ dispatch, rootState }, userEmailTemplate) {
    await userEmailTemplatesService.makeUserEmailTemplate({
      companyId: rootState.company.companyId,
      userEmailTemplate: {
        ...userEmailTemplate,
        companyId: rootState.company.companyId
      }
    });
    await dispatch(
      actionName.USER_EMAIL_TEMPLATES.FETCH_USER_EMAIL_TEMPLATES,
      null,
      { root: true }
    );
  },
  async updateUserEmailTemplate({ dispatch, rootState }, userEmailTemplate) {
    await userEmailTemplatesService.updateUserEmailTemplate({
      companyId: rootState.company.companyId,
      userEmailTemplate
    });
    await dispatch(
      actionName.USER_EMAIL_TEMPLATES.FETCH_USER_EMAIL_TEMPLATES,
      null,
      { root: true }
    );
  },
  async cloneUserEmailTemplate({ dispatch, rootState }, templateId) {
    await userEmailTemplatesService.cloneUserEmailTemplate({
      companyId: rootState.company.companyId,
      templateId
    });
    await dispatch(
      actionName.USER_EMAIL_TEMPLATES.FETCH_USER_EMAIL_TEMPLATES,
      null,
      { root: true }
    );
  },
  async deleteUserEmailTemplate({ dispatch, rootState }, templateId) {
    await userEmailTemplatesService.deleteUserEmailTemplate({
      companyId: rootState.company.companyId,
      templateId
    });
    await dispatch(
      actionName.USER_EMAIL_TEMPLATES.FETCH_USER_EMAIL_TEMPLATES,
      null,
      { root: true }
    );
  }
};
