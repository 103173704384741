import { state as makeState } from "./user.state.store";

export const mutations = {
  setRegistrationStatus(state, payload) {
    state.registrationStatus = parseInt(payload);
  },
  setMemberGroupId(state, payload) {
    state.memberGroupId = parseInt(payload);
  },
  setLandingPage(state, payload) {
    state.landingPage = payload;
  },
  setPasswordCreated(state, payload) {
    state.passwordCreated = parseInt(payload);
  },
  setUserId(state, payload) {
    state.userId = payload;
  },
  setRoles(state, payload) {
    state.roles = payload;
  },
  setLoginType(state, payload) {
    state.loginType = payload;
  },
  setUserFullName(state, payload) {
    state.userFullName = payload;
  },
  setUserFirstName(state, payload) {
    state.userFirstName = payload;
  },
  setUserLastName(state, payload) {
    state.userLastName = payload;
  },
  setEmail(state, payload) {
    state.email = payload;
  },
  setUserTourStatus(state, payload) {
    state.userTourStatus = parseInt(payload);
  },
  setPlatformVersion(state, payload) {
    state.platformVersion = payload;
  },
  setFormDefaultLanguage(state, payload) {
    state.formDefaultLanguage = payload;
  },
  resetUser(state) {
    Object.assign(state, makeState());
  },
  setThirdPartyId(state, payload) {
    state.thirdPartyId = payload;
  }
};
