<template>
  <div class="text-display-list">
    <div
      v-for="(item, index) in list"
      :key="index"
      class="text-display-list__container"
      :style="item.styles"
    >
      <BaseText
        class="text-display-list__label"
        v-bind="makeDefaultLabelOptions(item.labelOptions)"
      />
      <BaseText v-bind="makeDefaultValueOptions(item.valueOptions)" />
    </div>
  </div>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import { typographySize } from "@/constants";

export default {
  name: "TextDisplayList",
  components: { BaseText },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    makeDefaultLabelOptions(options = {}) {
      return {
        size: typographySize.BODY_TEXT_BOLD,
        hasDefaultSpacingRemoved: true,
        ...options
      };
    },
    makeDefaultValueOptions(options = {}) {
      return {
        hasDefaultSpacingRemoved: true,
        ...options
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.text-display-list {
  display: flex;
  flex-wrap: wrap;
  padding: $spacing12 $spacing48;
  gap: $spacing24;

  &__container {
    gap: 4px;
    display: flex;
    flex-direction: column;
  }
}
</style>
