<template id="template-input">
  <b-form-group class="mt-2">
    <label>
        {{
          attributeData.tp_alias
            ? attributeData.tp_alias
            : attributeData.tp_customatt
        }}
        <Tooltip
          class="ml-2"
          v-if="getAttribute(attributeData)"
          :text="attributeData.description"
        />
    </label>

    <!-- if custom attribute is required -->
    <div v-if="parseInt(attributeData.tp_is_required)" :ref="attributeData.id">
      <b-form-textarea
        id="textarea"
        v-model="v$.form.value.$model"
        rows="3"
        max-rows="6"
        @blur="saveValue()"
        placeholder="---Required---"
        :state="v$.form.value.$dirty ? !v$.form.value.$error : null"
      ></b-form-textarea>
      <b-form-invalid-feedback v-if="v$.form.value.$error"
        >A Required field has no value.</b-form-invalid-feedback
      >
    </div>
    <!-- if custom attribute is required -->

    <!-- if custom attribute is not required -->
    <div v-else>
      <b-form-textarea
        v-model="form.value"
        rows="3"
        max-rows="6"
        @blur="saveValue()"
      ></b-form-textarea>
    </div>
    <!-- if custom attribute is not required -->
  </b-form-group>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Tooltip from "@/molecules/Tooltip/Tooltip";

export default {
  name: "Textarea",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {
    Tooltip
  },
  data() {
    return {
      form: {
        value: null
      }
    };
  },
  validations() {
    if (parseInt(this.attributeData.tp_is_required)) {
      return {
        form: {
          value: {
            required
          }
        }
      };
    }
  },

  created() {
    // react on event textAreaValidationError
    EventBus.on("textAreaValidationError", (inValidObj) => {
      const element = this.$refs[inValidObj.id];
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      this.v$.form.$touch();
    });
  },

  mounted() {
    if (this.values.value) {
      this.form.value = this.values.value;
    }
  },

  methods: {
    getAttribute(attributeData) {
      return attributeData?.description || "";
    },
    consoleData() {},
    // save custom attribute value
    saveValue() {
      if (parseInt(this.attributeData.tp_is_required)) {
        this.v$.form.$touch();
        // emit event saveValue when custom attribute is required
        EventBus.emit(
          "saveValue",
          {
            value: this.form.value,
            id: this.attributeData.id,
            error: this.v$.form.$anyError,
            type: this.attributeData.tp_type
          }
        );
      } else {
        // emit event saveValue when custom attribute is not required
        EventBus.emit(
          "saveValue",
          {
            value: this.form.value,
            id: this.attributeData.id,
            error: false,
            type: this.attributeData.tp_type
          }
        );
      }
    }
  },
  props: {
    attributeData: Object,
    values: Object
  }
};
</script>
<style>
/* .form-control.is-valid{
	background-position: left calc(0.375em + 0.1875rem) center

}
.form-control.is-invalid{
	background-position :left calc(0.375em + 0.1875rem) center

} */
</style>
