import { getCurrentVersion } from "@/utils";
import { productVersionsKeysDTO } from "@/constants";

export const getters = {
  hasPublicPlatformSettings(state) {
    return (
      state.disablePrivacyPolicy !== null &&
      state.platformFavIcon !== null &&
      state.platformPrimaryColor !== null &&
      state.platformPrivacyPolicyLink !== null &&
      state.platformRegisteredBusinessName !== null &&
      state.platformSecondaryColor !== null &&
      state.platformSSOURL !== null &&
      state.platformTermsAndConditionsLink !== null
    );
  },
  hasPlatformSettings(state) {
    return (
      state.platformSSOURL !== null,
      state.disablePrivacyPolicy !== null,
      state.platformLogo !== null,
      state.platformPrimaryColor !== null,
      state.platformSecondaryColor !== null,
      state.platformTermsAndConditionsLink !== null,
      state.platformPrivacyPolicyLink !== null,
      state.platformBusinessAddress !== null,
      state.platformCopyrightNotice !== null,
      state.platformFaqLink !== null,
      state.platformFavIcon !== null,
      state.platformKnowledgeCentreLink !== null,
      state.platformLegalDisclaimer !== null,
      state.platformMainHeaderLogo !== null,
      state.platformName !== null,
      state.platformRegisteredBusinessName !== null,
      state.platformReleaseNoteLink !== null,
      state.platformRequestSupportLink !== null,
      state.platformSupportEmail !== null,
      state.platformWebUrl !== null,
      state.singleTpModalWording !== null
    );
  },
  getPlatformUserListOptions(state) {
    return state.platformUserList.map(({ memberId, name }) => ({
      text: name,
      value: memberId
    }));
  },
  getProductVersionsList(state) {
    return Object.keys(state.productVersions).reduce(
      (acc, productName) => {
        acc.push({
          [productVersionsKeysDTO.VERSION]: state.productVersions[productName],
          [productVersionsKeysDTO.SERVICE]: productName
        });
        return acc;
      },
      [
        {
          service: "UI Application",
          version: getCurrentVersion()
        }
      ]
    );
  }
};
