const orbiaSpecificConstants = {
  MANDATORY_SELECTION_DESCRIPTION: "[mandatory selection]",
  MANDATORY_ATTRIBUTE_DESCRIPTION: "[mandatory attribute]",
  OPTIONS_VALUES: {
    CUSTOMER_END_USER: "Customer - End User",
    EXCEPTIONS: "Exceptions"
  }
};

export { orbiaSpecificConstants };
