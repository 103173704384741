const thirdPartyListOfExcelDownloadKeysDTO = {
  NUMBER_OF_EMAILS: "numberOfEmails"
};

const thirdPartyListOfExcelDownloadKeysAPIDTO = {
  NUMBER_OF_EMAILS: "numberOfEmails"
};

export {
  thirdPartyListOfExcelDownloadKeysDTO,
  thirdPartyListOfExcelDownloadKeysAPIDTO
};
