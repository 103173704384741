<template>
  <div class="tp-over-countries-results">
    <b-card style="border: none">
      <b-card-title>
        <div class="text-right">
          <b-dropdown size="sm" variant="outline-info" class="text-capitalize">
            <template v-slot:button-content>
              <font-awesome-icon icon="cogs" />
            </template>
            <b-dropdown-item @click="onSelectTpCountriesPieChart('Pie')">{{
              $t("eb360.analyticsTabChart.selectChart.pie")
            }}</b-dropdown-item>
            <b-dropdown-item @click="onSelectTpCountriesPieChart('Pyramid')">{{
              $t("eb360.analyticsTabChart.selectChart.pyramid")
            }}</b-dropdown-item>
            <b-dropdown-item @click="onSelectTpCountriesPieChart('Funnel')">{{
              $t("eb360.analyticsTabChart.selectChart.funnel")
            }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-card-title>
      <highcharts :options="tpCountriesChartOptions" />
    </b-card>
  </div>
</template>

<script>
export default {
  name: "TpOverCountriesResultsComponent",
  props: {
    tpCountriesChartOptions: Object
  },
  methods: {
    // change chart type functions
    onSelectTpCountriesPieChart(type) {
      this.tpCountriesChartOptions.chart.type = type.toLowerCase();
    }
  }
};
</script>

<style lang="scss" scoped>
.tp-over-countries-results {
  border: $element-border;
}
</style>
