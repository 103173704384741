<template id="template-personal-details">
  <div
    :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
    :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'"
  >
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :isHidden="isHidden"
      :label="label"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
    />
    <!-- input content -->

    <b-collapse class="card mt-5" id="collapse-1" v-model="collapse">
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1'"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <div class="mt-3" :id="fieldData.id">
        <h4>{{ fieldData.label[selectedLanguage] }}</h4>
        <p>{{ fieldData.description[selectedLanguage] }}</p>
        <b-form-checkbox
          v-show="!fieldData.isNaFeature"
          id="na-principa-contact"
          v-model="fieldData.isNa"
          value="1"
          unchecked-value="0"
          @input="onToggleNa"
          >{{ $t("na") }}</b-form-checkbox
        >
      </div>
      <b-modal
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        v-model="showAddRowModal"
        :title="
          isUpdtating
            ? $t('workflow.update') + ' ' + fieldData.label[selectedLanguage]
            : $t('workflow.add') + ' ' + fieldData.label[selectedLanguage]
        "
        size="xl"
      >
        <div
          :class="
            $store.state.rightAlign
              ? 'card-body text-right'
              : 'card-body text-left'
          "
        >
          <div class="form-row">
            <div
              class="col-md-6"
              v-show="!fieldData.advance || !fieldData.advance.forenameDisabled"
            >
              <b-form-group
                id="fieldset-1"
                :description="fieldData.foreNameHelperText[selectedLanguage]"
                :invalid-feedback="
                  fieldData.foreNameValidationMessage[selectedLanguage]
                "
              >
                <label>
                  <span
                    v-if="
                      fieldData.validation &&
                      JSON.parse(fieldData.validation.isRequireForename)
                    "
                    class="required-span"
                    >*</span
                  >
                  {{ fieldData.foreNameLabel[selectedLanguage] }}
                </label>
                <b-form-input
                  :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                  :disabled="isDisabled == 1"
                  type="text"
                  v-model="v$.form.foreName.$model"
                  :placeholder="
                    fieldData.foreNamePlaceholderText[selectedLanguage]
                  "
                  :state="
                    v$.form && v$.form.foreName.$dirty
                      ? !v$.form.foreName.$error
                      : null
                  "
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="col-md-6"
              v-show="!fieldData.advance || !fieldData.advance.lastnameDisabled"
            >
              <b-form-group
                id="fieldset-2"
                :description="fieldData.lastNameHelperText[selectedLanguage]"
                :invalid-feedback="
                  fieldData.lastNameValidationMessage[selectedLanguage]
                "
              >
                <label>
                  <span
                    v-if="
                      fieldData.validation &&
                      JSON.parse(fieldData.validation.isRequireLastname)
                    "
                    class="required-span"
                    >*</span
                  >
                  {{ fieldData.lastNameLabel[selectedLanguage] }}
                </label>
                <b-form-input
                  :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                  :disabled="isDisabled == 1"
                  type="text"
                  v-model="v$.form.lastName.$model"
                  :placeholder="
                    fieldData.lastNamePlaceholderText[selectedLanguage]
                  "
                  :state="
                    v$.form && v$.form.lastName.$dirty
                      ? !v$.form.lastName.$error
                      : null
                  "
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="form-row">
            <div
              class="col-md-6"
              v-show="!fieldData.advance || !fieldData.advance.contactDisabled"
            >
              <b-form-group
                id="fieldset-3"
                :description="
                  fieldData.contactNumberHelperText[selectedLanguage]
                "
                :invalid-feedback="
                  fieldData.contactNumberValidationMessage[selectedLanguage]
                "
              >
                <label>
                  <span
                    v-if="
                      fieldData.validation &&
                      JSON.parse(fieldData.validation.isRequireContact)
                    "
                    class="required-span"
                    >*</span
                  >
                  {{ fieldData.contactNumberLabel[selectedLanguage] }}
                </label>
                <b-form-input
                  :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                  onkeydown="return event.keyCode !== 69"
                  :disabled="isDisabled == 1"
                  type="number"
                  onpaste="return false;"
                  v-model="v$.form.contactNumber.$model"
                  :placeholder="
                    fieldData.contactNumberPlaceholderText[selectedLanguage]
                  "
                  :state="
                    v$.form && v$.form.contactNumber.$dirty
                      ? !v$.form.contactNumber.$error
                      : null
                  "
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="col-md-6"
              v-show="!fieldData.advance || !fieldData.advance.emailDisabled"
            >
              <b-form-group
                id="fieldset-4"
                :description="
                  fieldData.emailAddressHelperText[selectedLanguage]
                "
                :invalid-feedback="
                  !v$.form.emailAddress.email
                    ? $t('validations.invalidFormat')
                    : fieldData.emailAddressValidationMessage[selectedLanguage]
                "
              >
                <label>
                  <span
                    v-if="
                      fieldData.validation &&
                      JSON.parse(fieldData.validation.isRequireEmail)
                    "
                    class="required-span"
                    >*</span
                  >
                  {{ fieldData.emailAddressLabel[selectedLanguage] }}
                </label>
                <b-form-input
                  :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                  :disabled="isDisabled == 1"
                  type="email"
                  v-model="v$.form.emailAddress.$model"
                  :placeholder="
                    fieldData.emailAddressPlaceholderText[selectedLanguage]
                  "
                  :state="
                    v$.form && v$.form.emailAddress.$dirty
                      ? !v$.form.emailAddress.$error
                      : null
                  "
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="form-row">
            <div
              class="col-md-6"
              v-show="!fieldData.advance || !fieldData.advance.roleDisabled"
            >
              <b-form-group
                id="fieldset-5"
                :description="
                  fieldData.rolePositionHelperText[selectedLanguage]
                "
                :invalid-feedback="
                  fieldData.rolePositionValidationMessage[selectedLanguage]
                "
              >
                <label>
                  <span
                    v-if="
                      fieldData.validation &&
                      JSON.parse(fieldData.validation.isRequireRole)
                    "
                    class="required-span"
                    >*</span
                  >
                  {{ fieldData.rolePositionLabel[selectedLanguage] }}
                </label>
                <b-form-input
                  :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                  :disabled="isDisabled == 1"
                  type="text"
                  v-model="v$.form.rolePositionLabel.$model"
                  :placeholder="
                    fieldData.rolePositionPlaceholderText[selectedLanguage]
                  "
                  :state="
                    v$.form && v$.form.rolePositionLabel.$dirty
                      ? !v$.form.rolePositionLabel.$error
                      : null
                  "
                ></b-form-input>
              </b-form-group>
            </div>
            <div
              class="col-md-6"
              v-show="!fieldData.advance || !fieldData.advance.linkedinDisabled"
            >
              <b-form-group
                id="fieldset-6"
                :description="
                  fieldData.linkedInProfileHelperText[selectedLanguage]
                "
                :invalid-feedback="
                  fieldData.linkedInProfileValidationMessage[selectedLanguage]
                "
              >
                <label>
                  <span
                    v-if="JSON.parse(fieldData.validation.isRequireLinkedin)"
                    class="required-span"
                    >*</span
                  >
                  {{ fieldData.linkedInProfileLabel[selectedLanguage] }}
                </label>
                <b-form-input
                  :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                  :disabled="isDisabled == 1"
                  type="text"
                  :placeholder="
                    fieldData.linkedInProfilePlaceholderText[selectedLanguage]
                  "
                  v-model="v$.form.linkedInProfile.$model"
                  :state="
                    v$.form && v$.form.linkedInProfile.$dirty
                      ? !v$.form.linkedInProfile.$error
                      : null
                  "
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="form-row">
            <div
              class="col-md-6"
              v-show="!fieldData.advance || !fieldData.advance.freeFormDisabled"
            >
              <div v-if="fieldData.freeFormLabel">
                <b-form-group
                  id="fieldset-6"
                  :description="
                    fieldData.freeFormHelperText[selectedLanguage] || ''
                  "
                  :invalid-feedback="
                    fieldData.freeFormValidationMessage[selectedLanguage] || ''
                  "
                >
                  <label>
                    <span
                      v-if="
                        fieldData.validation &&
                        JSON.parse(fieldData.validation.isRequireFreeForm)
                      "
                      class="required-span"
                      >*</span
                    >
                    {{ fieldData.freeFormLabel[selectedLanguage] || "" }}
                  </label>
                  <b-form-input
                    :class="
                      $store.state.rightAlign ? 'text-right' : 'text-left'
                    "
                    :disabled="isDisabled == 1"
                    type="text"
                    :placeholder="
                      fieldData.freeFormPlaceholderText[selectedLanguage] || ''
                    "
                    v-model="v$.form.freeForm.$model"
                    :state="
                      v$.form && v$.form.freeForm.$dirty
                        ? !v$.form.freeForm.$error
                        : null
                    "
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <template v-slot:modal-footer>
          <b-button variant="danger" @click="resetModal">{{
            $t("closeBtnText")
          }}</b-button>
          <b-button
            variant="success"
            @click="isUpdtating ? updateRow() : addRow()"
            >{{ isUpdtating ? $t("updateBtn") : $t("saveBtnText") }}</b-button
          >
        </template>
      </b-modal>

      <b-row v-if="taskStatus != 4" :id="fieldData.id">
        <b-col cols="12">
          <b-button
            :disabled="isDisabled == 1 || fieldData.isNa == 1"
            class="mr-2 mt-2"
            variant="outline-success"
            size="lg"
            @click="showAddRowModal = !showAddRowModal"
          >
            <font-awesome-icon icon="plus" />
            {{ $t("workflow.add") }}
          </b-button>
        </b-col>
        <b-col cols="12">
          <label v-if="isValidationRequired(fieldData)" class="text-danger">{{
            $t("createProfile.requiredValidationMessage")
          }}</label>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col cols="12" class="p-5">
          <b-table-simple striped hover>
            <b-thead>
              <b-tr>
                <b-th
                  v-if="
                    !fieldData.advance || !fieldData.advance.forenameDisabled
                  "
                  >{{ fieldData.foreNameLabel[selectedLanguage] }}</b-th
                >
                <b-th
                  v-if="
                    !fieldData.advance || !fieldData.advance.lastnameDisabled
                  "
                  >{{ fieldData.lastNameLabel[selectedLanguage] }}</b-th
                >
                <b-th
                  v-if="
                    !fieldData.advance || !fieldData.advance.contactDisabled
                  "
                  >{{ fieldData.contactNumberLabel[selectedLanguage] }}</b-th
                >
                <b-th
                  v-if="!fieldData.advance || !fieldData.advance.emailDisabled"
                  >{{ fieldData.emailAddressLabel[selectedLanguage] }}</b-th
                >
                <b-th
                  v-if="!fieldData.advance || !fieldData.advance.roleDisabled"
                  >{{ fieldData.rolePositionLabel[selectedLanguage] }}</b-th
                >
                <b-th
                  v-if="
                    !fieldData.advance || !fieldData.advance.linkedinDisabled
                  "
                  >{{ fieldData.linkedInProfileLabel[selectedLanguage] }}</b-th
                >
                <b-th
                  v-if="
                    !fieldData.advance || !fieldData.advance.freeFormDisabled
                  "
                  >{{
                    fieldData.freeFormLabel &&
                    fieldData.freeFormLabel[selectedLanguage]
                  }}</b-th
                >
                <b-th>{{ $t("upload.fileAction") }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-for="(item, index) in items" :key="index">
              <b-tr>
                <b-td
                  v-if="
                    !fieldData.advance || !fieldData.advance.forenameDisabled
                  "
                  >{{ item.forename }}</b-td
                >
                <b-td
                  v-if="
                    !fieldData.advance || !fieldData.advance.lastnameDisabled
                  "
                  >{{ item.lastName }}</b-td
                >
                <b-td
                  v-if="
                    !fieldData.advance || !fieldData.advance.contactDisabled
                  "
                  >{{ item.contact_number }}</b-td
                >
                <b-td
                  v-if="!fieldData.advance || !fieldData.advance.emailDisabled"
                  >{{ item.email_address }}</b-td
                >
                <b-td
                  v-if="!fieldData.advance || !fieldData.advance.roleDisabled"
                  >{{ item.role_or_positon }}</b-td
                >
                <b-td
                  v-if="
                    !fieldData.advance || !fieldData.advance.linkedinDisabled
                  "
                  >{{ item.linked_in_profile }}</b-td
                >
                <b-td
                  v-if="
                    !fieldData.advance || !fieldData.advance.freeFormDisabled
                  "
                  >{{ item.free_form }}</b-td
                >
                <b-td>
                  <b-button
                    @click="onEditRow(item, index)"
                    size="sm"
                    class="mr-1"
                  >
                    <font-awesome-icon
                      :icon="taskStatus != 4 ? 'edit' : 'eye'"
                    />
                  </b-button>
                  <b-button
                    @click="onDeleteRow(index)"
                    variant="danger"
                    size="sm"
                    v-if="taskStatus != 4"
                  >
                    <font-awesome-icon icon="trash" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>

      <!-- show advance field options -->
      <b-modal
        cancel-title="Close"
        ok-title="save"
        @ok="onSaveData"
        size="xl"
        v-model="showSettings"
        scrollable
        title="Advanced Settings"
      >
        <AdvanceSettings
          :fieldData="fieldData"
          v-on:toggle-field="showSettings = !showSettings"
          :sectionIndex="sectionIndex"
          :fieldIndex="fieldIndex"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import MenuButtons from "../menuButtons";
import AdvanceSettings from "../workflowSettings/advancePrincipalContactSettings.vue";
import { Helper } from "@/helpers";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { EventBus } from "@/event-bus.js";
import { routeKeys, routeNames } from "@/constants";
import { clone } from "lodash";

export default {
  name: "PrincipalContactGroup",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {
    MenuButtons,
    AdvanceSettings
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage"])
  },
  data() {
    return {
      showFreeForm: false,
      showAddRowModal: false,
      items: [],
      isHidden: false,
      condition: {},
      title: null,
      form: {
        foreName: null,
        lastName: null,
        contactNumber: null,
        emailAddress: null,
        rolePositionLabel: null,
        linkedInProfile: null,
        freeForm: null
      },
      label: null,
      showSettings: false,
      collapse: true,
      isUpdtating: false,
      selectedRowIndex: null,
      postData: [],
      taskStatus: 0
    };
  },
  mounted() {
    this.setNewConditions();
    this.setFreeFormFields();
    if (this.$route.name === routeNames[routeKeys.USER_FORM_PAGE]) {
      this.getDataForUser();
    }
    let st = parseInt(localStorage.getItem("activeTaskStatus"));
    this.taskStatus = st;
  },

  validations() {
    return {
      form: {
        foreName: {
          [this.forename]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireForename
              ? required
              : !required
        },
        lastName: {
          [this.lastName]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireLastname
              ? required
              : !required
        },
        contactNumber: {
          [this.contactNumber]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireContact
              ? required
              : !required
        },
        emailAddress: {
          required:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireEmail
              ? required
              : !required,
          email
        },
        rolePositionLabel: {
          [this.rolePositionLabel]:
            this.fieldData.validation && this.fieldData.validation.isRequireRole
              ? required
              : !required
        },

        linkedInProfile: {
          [this.linkedInProfile]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireLinkedin
              ? required
              : !required
        },

        freeForm: {
          [this.freeForm]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireFreeForm
              ? required
              : !required
        }
      }
    };
  },

  created() {
    EventBus.on("onSubmitEndUserForm", (value) => {
      if (value == "progress" || this.fieldData.isNa == 1) {
        this.$store.state.validFields = true;
        this.fieldData.isInValid = false;
        this.setDataToUser();
      } else {
        if (this.items && this.items.length > 0) {
          this.setDataToUser();
          this.$store.state.validFields = true;
          this.fieldData.isInValid = false;
        } else {
          this.fieldData.isInValid = true;
          this.$store.state.validFields = false;
        }
      }
    });
    EventBus.on("OnInvalidFieldCatch", (inValidData) => {
      document
        .getElementById(inValidData.elementId)
        .scrollIntoView({ behavior: "smooth" });
    });
  },

  methods: {
    onToggleNa() {
      this.items = [];
      this.postData = [];
    },
    isValidationRequired(fieldData) {
      let condition = Object.values(fieldData.validation);
      return !!condition.includes(true) && fieldData.isInValid;
    },
    setFreeFormFields() {
      if (this.fieldData.freeFormLabel == undefined) {
        Object.assign(this.fieldData, {
          freeFormLabel: {
            english: "Free Form"
          },
          freeFormPlaceholderText: {
            english: ""
          },
          freeFormHelperText: {
            english: ""
          },
          freeFormValidationMessage: {
            english: "This field is required"
          }
        });
        let validation = {
          ...this.fieldData.validation,
          isRequireFreeForm:
            this.fieldData.validation.isRequireFreeForm || false
        };
        Object.assign(this.fieldData, { validation });
        let advance = {
          ...this.fieldData.advance,
          freeFormDisabled: this.fieldData.advance.freeFormDisabled || true
        };
        Object.assign(this.fieldData, { advance });
      }

      // this.showFreeForm = true;
    },

    setNewConditions() {
      Object.assign(this.fieldData, {
        isInValid: this.fieldData.isInValid || null
      });
      Object.assign(this.fieldData, {
        isHidden: this.fieldData.isHidden || false
      });
      Object.assign(this.fieldData, {
        isConditional: this.fieldData.isConditional || 0
      });
      Object.assign(this.fieldData, {
        conditions:
          this.fieldData.conditions && this.fieldData.conditions.length
            ? this.fieldData.conditions
            : []
      });
      Object.assign(this.fieldData, {
        conditionalFields:
          this.fieldData.conditionalFields &&
          this.fieldData.conditionalFields.length
            ? this.fieldData.conditionalFields
            : []
      });
      Object.assign(this.fieldData, {
        sectionConditions:
          this.fieldData.sectionConditions &&
          this.fieldData.sectionConditions.length
            ? this.fieldData.sectionConditions
            : []
      });
    },
    addRow() {
      const registerData = clone(this.$store.state.registerData);
      this.v$.form.$touch();
      if (this.v$.form.$anyError && this.v$.form.$invalid) {
        return false;
      }

      registerData[this.fieldData.id] = "principat-contact";
      this.$store.state.registerData = registerData;

      // main calculations :p
      const numkeys = Object.keys(registerData).length;
      // const sectionLength = this.form1.formSection.length > 1 ? this.form1.formSection[this.sectionIndex].formElements.length : 1
      this.$store.state.progressValue = numkeys;

      let row = {
        forename: this.form.foreName,
        lastName: this.form.lastName,
        contact_number: this.form.contactNumber,
        email_address: this.form.emailAddress,
        role_or_positon: this.form.rolePositionLabel,
        linked_in_profile: this.form.linkedInProfile,
        free_form: this.form.freeForm,

        actions: "&nbsp;"
      };
      // if (this.$route.name === routeNames[routeKeys.USER_FORM_PAGE]) {
      let aa = [
        {
          postValue: row.forename,
          postName:
            (this.fieldData.attributes &&
              this.fieldData.attributes.forenamePostName) ||
            "forenamePostName",
          key: "forename",
          label: this.fieldData.foreNameLabel
        },
        {
          postValue: row.lastName,
          postName:
            (this.fieldData.attributes &&
              this.fieldData.attributes.lastnamePostName) ||
            "lastnamePostName",
          key: "lastName",
          label: this.fieldData.lastNameLabel
        },
        {
          postValue: row.contact_number,
          postName:
            (this.fieldData.attributes &&
              this.fieldData.attributes.contactPostName) ||
            "contactPostName",
          key: "contact_number",
          label: this.fieldData.contactNumberLabel
        },
        {
          postValue: row.email_address,
          postName:
            (this.fieldData.attributes &&
              this.fieldData.attributes.emailPostName) ||
            "emailPostName",
          key: "email_address",
          label: this.fieldData.emailAddressLabel
        },
        {
          postValue: row.role_or_positon,
          postName:
            (this.fieldData.attributes &&
              this.fieldData.attributes.rolePostName) ||
            "rolePostName",
          key: "role_or_positon",
          label: this.fieldData.rolePositionLabel
        },
        {
          postValue: row.linked_in_profile,
          postName:
            (this.fieldData.attributes &&
              this.fieldData.attributes.linkedinPostName) ||
            "linkedinPostName",
          key: "linked_in_profile",
          label: this.fieldData.linkedInProfileLabel
        },
        {
          postValue: row.free_form,
          postName:
            (this.fieldData.attributes &&
              this.fieldData.attributes.freeFormPostName) ||
            "freeFormPostName",
          key: "free_form",
          label: this.fieldData.freeFormLabel
        }
      ];
      this.postData.push(aa);
      this.items.push(row);
      // }
      this.resetModal(row);
    },

    getDataForUser() {
      this.setFieldLabel();
      this.$store.state.getUserFormSubmitArray.map((data) => {
        if (data.elementId === this.fieldData.id) {
          this.fieldData.isNa = data.isWorkFlowNa;
          this.fieldData.isHidden = parseInt(data.isHiddenElement) === 1;
        }
        if (data.elements && data.elements.length > 0) {
          data.elements.map((element) => {
            if (data.elementId === this.fieldData.id) {
              let row = {
                forename:
                  element[element.findIndex((x) => x.key == "forename")]
                    .postValue,
                lastName:
                  element[element.findIndex((x) => x.key == "lastName")]
                    .postValue,
                contact_number:
                  element[element.findIndex((x) => x.key == "contact_number")]
                    .postValue,
                email_address:
                  element[element.findIndex((x) => x.key == "email_address")]
                    .postValue,
                role_or_positon:
                  element[element.findIndex((x) => x.key == "role_or_positon")]
                    .postValue,
                linked_in_profile:
                  element[
                    element.findIndex((x) => x.key == "linked_in_profile")
                  ].postValue,
                free_form:
                  element[element.findIndex((x) => x.key == "free_form")]
                    .postValue,
                actions: "&nbsp;"
              };
              this.items.push(row);
              this.postData = data.elements;
            }
          });
        }
      });
    },

    setFieldLabel() {
      this.$store.state.userFormSubmitArray.map((field) => {
        if (field.elementId === this.fieldData.id) {
          field.elements.map((element) => {
            element.map((data) => {
              if (data.key == "forename") {
                data.label = this.fieldData.foreNameLabel;
              }
              if (data.key == "lastName") {
                data.label = this.fieldData.lastNameLabel;
              }
              if (data.key == "contact_number") {
                data.label = this.fieldData.contactNumberLabel;
              }
              if (data.key == "email_address") {
                data.label = this.fieldData.emailAddressLabel;
              }
              if (data.key == "role_or_positon") {
                data.label = this.fieldData.rolePositionLabel;
              }
              if (data.key == "linked_in_profile") {
                data.label = this.fieldData.linkedInProfileLabel;
              }
              if (data.key == "free_form") {
                data.label = this.fieldData.freeFormLabel;
              }
            });
          });
        }
      });
    },

    setDataToUser() {
      if (this.$route.name === routeNames[routeKeys.USER_FORM_PAGE]) {
        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId === this.fieldData.id) {
            a.isWorkFlowNa = this.fieldData.isNa;
            a.isHiddenElement = this.fieldData.isHidden ? 1 : 0;
            a.elements = this.postData;
          }
        });
      }
    },

    updateRow() {
      this.v$.form.$touch();
      if (this.v$.form.$anyError && this.v$.form.$invalid) {
        return false;
      }

      this.items[this.selectedRowIndex].forename = this.form.foreName;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "forename"
        )
      ].postValue = this.form.foreName;

      this.items[this.selectedRowIndex].lastName = this.form.lastName;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "lastName"
        )
      ].postValue = this.form.lastName;

      this.items[this.selectedRowIndex].contact_number =
        this.form.contactNumber;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "contact_number"
        )
      ].postValue = this.form.contactNumber;

      this.items[this.selectedRowIndex].email_address = this.form.emailAddress;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "email_address"
        )
      ].postValue = this.form.emailAddress;

      this.items[this.selectedRowIndex].role_or_positon =
        this.form.rolePositionLabel;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "role_or_positon"
        )
      ].postValue = this.form.rolePositionLabel;

      this.items[this.selectedRowIndex].linked_in_profile =
        this.form.linkedInProfile;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "linked_in_profile"
        )
      ].postValue = this.form.linkedInProfile;

      this.items[this.selectedRowIndex].free_form = this.form.freeForm;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "free_form"
        )
      ].postValue = this.form.freeForm;

      this.resetModal();
    },

    onDeleteRow(index) {
      const registerData = clone(this.$store.state.registerData);
      if (this.items.length == 1 && this.postData.length == 1) {
        delete registerData[this.fieldData.id];
        this.$store.state.registerData = registerData;

        // main calculations :p
        const numkeys = Object.keys(registerData).length;
        // const sectionLength = this.form1.formSection.length > 1 ? this.form1.formSection[this.sectionIndex].formElements.length : 1
        this.$store.state.progressValue = numkeys;
      }

      this.items.splice(index, 1);
      this.postData.splice(index, 1);
    },

    onEditRow(item, index) {
      this.isUpdtating = true;
      this.showAddRowModal = true;
      this.selectedRowIndex = index;
      this.form = {
        foreName: item.forename,
        lastName: item.lastName,
        contactNumber: item.contact_number,
        emailAddress: item.email_address,
        rolePositionLabel: item.role_or_positon,
        linkedInProfile: item.linked_in_profile,
        freeForm: item.free_form
      };
    },

    resetModal() {
      (this.form.foreName = null),
        (this.form.lastName = null),
        (this.form.contactNumber = null),
        (this.form.emailAddress = null),
        (this.form.rolePositionLabel = null),
        (this.form.linkedInProfile = null),
        (this.form.freeForm = null);
      this.v$.$reset();
      this.showAddRowModal = false;
      this.isUpdtating = false;
    },

    setValidation() {
      let validation = {
        isRequireForename: true,
        isRequireLastname: true,
        isRequireContact: true,
        isRequireEmail: true,
        isRequireRole: true,
        isRequireLinkedin: true
      };
      Object.assign(this.fieldData, { validation });
    },

    onSaveData() {
      EventBus.emit("onSaveSettings", "saving settings");
      Helper.makeToast(this.$bvToast, "success", "Settings updated");
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
<style></style>
