export const state = () => ({
  companyUsers: [],
  companyList: [],
  companyViews: [],
  triggerFetchAdditionalVouchers: false,
  remainingVouchers: 0,
  companyDetails: {},
  companyId: null,
  companyName: null,
  companyFeatures: [],
  tenantId: null
});
