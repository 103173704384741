import {
  platformUserDTO,
  platformUserAPIDTO,
  pyramidTokenAPIDTO,
  pyramidTokenDTO
} from "@/constants";

const makePlatformUserDTO = ({ data = {} } = {}) =>
  data?.data?.map((user) => ({
    [platformUserDTO.MEMBER_ID]: user?.[platformUserAPIDTO.MEMBER_ID] || "",
    [platformUserDTO.EMAIL]: user?.[platformUserAPIDTO.EMAIL] || "",
    [platformUserDTO.FIRST_NAME]: user?.[platformUserAPIDTO.FIRST_NAME] || "",
    [platformUserDTO.LAST_NAME]: user?.[platformUserAPIDTO.LAST_NAME] || "",
    [platformUserDTO.NAME]: user?.[platformUserAPIDTO.NAME] || "",
    [platformUserDTO.MEMBER_GROUP_ID]:
      user?.[platformUserAPIDTO.MEMBER_GROUP_ID] || "",
    [platformUserDTO.JOB_TITLE]: user?.[platformUserAPIDTO.JOB_TITLE] || "",
    [platformUserDTO.COUNTRY_ID]: user?.[platformUserAPIDTO.COUNTRY_ID] || ""
  })) || [];

const makePyramidTokenDTO = (data) => ({
  [pyramidTokenDTO.TOKEN]: data?.[pyramidTokenAPIDTO.TOKEN] || "",
  [pyramidTokenDTO.DOMAIN]: data?.[pyramidTokenAPIDTO.DOMAIN] || ""
});

export { makePlatformUserDTO, makePyramidTokenDTO };
