export default {
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대시보드"])},
  "dashboardTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작업 대시보드"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홈 페이지"])},
  "my_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나의 과제"])},
  "shared_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유된 과제"])},
  "loadingSpinnerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로딩중..."])},
  "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마감일"])},
  "nav": {
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["템플릿"])},
    "dandi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D&I"])}
  },
  "questionnaires": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문지"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실사 설문지를 작성하고 문서를 제공하십시오"])},
    "submitModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["질문 제출"])},
    "formSubmittedModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제출된 양식"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동의합니다"])},
    "backToDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게시판으로 돌아가기"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개요"])},
    "dateOfAssigned:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["할당 날짜:"])},
    "dateOfCompleted:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료 날짜:"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다운받기"])},
    "batchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료"])},
    "batchInProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행 중"])},
    "batchInProgressDateSetText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행 중 - 날짜 설정"])},
    "batchInProgressNoDateSetText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행 중 - 날짜 설정 없음"])},
    "batchNotStartedext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시작하지 않았습니다"])},
    "deleteRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["행 삭제"])},
    "editRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["행 편집"])},
    "noSearchOptionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["죄송합니다. 일치하는 옵션이 없습니다.."])},
    "requestedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["요청 날짜"])}
  },
  "formLeaveAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 페이지를 지금 나가시면 저장하지 않은 변경을 잃으실 겁니다. 지금 나가시겠습니까?"])},
  "formSubmitingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["질문지를제출하려합니다. 한번제출후엔취소할수없습니다. 제출하시겠습니까?"])},
  "formSubmittedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["귀하의양식이성공적으로제출되었습니다."])},
  "formValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하나 이상의 누락된 필드 또는 오류가 발견되었습니다. 검토하고 필요에 따라 업데이트하십시오."])},
  "toast": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경보"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성공 저장"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행 상황을 저장하지 마십시오. 다시 시도하십시오."])},
    "updateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업데이트 성공"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
    "generatePDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF를 생성하는 중입니다..."])},
    "successPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF 다운로드 성공"])},
    "errorPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF가 다운로드되지 않았습니다. 다시 시도하십시오."])},
    "logoutSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃 성공"])}
  },
  "createProfile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설문지"])},
    "titleUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 프로필 업데이트"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
    "contactHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연락처"])},
    "passwordHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 업데이트"])},
    "fNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성"])},
    "lNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
    "contactLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호"])},
    "jobTitleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["직함"])},
    "currentPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 비밀번호"])},
    "newPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 비밀번호"])},
    "confirmPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 확인"])},
    "fNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름을 입력하십시오"])},
    "lNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성/성을 입력하십시오"])},
    "contactPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연락처 번호를 입력하십시오"])},
    "jobTitlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["직위 또는 역할 입력"])},
    "currentPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 암호 입력"])},
    "newPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 암호 입력"])},
    "confirmPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["암호 확인 입력"])},
    "requiredValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 필드는 필수입니다"])},
    "passwordValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호는 동일해야 합니다"])},
    "loaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["요청을 처리하는 중입니다..."])}
  },
  "upload": {
    "dragDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠를 업로드하려면 파일을 끌어 놓기!"])},
    "dragDropContnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...또는 컴퓨터에서 파일을 클릭하여 선택하기"])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일명"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일 유형"])},
    "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일 크기(바이트)"])},
    "fileAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활동"])}
  },
  "signature": {
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지우기"])},
    "require": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필수의"])}
  },
  "workflow": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가하기"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지우기"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초기화"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업데이트"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])}
  },
  "languages": {
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
    "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
    "chinesetr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Traditional"])},
    "chinesesm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Simplified"])},
    "dutch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "gujarati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gujrati"])},
    "hebrew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebrew"])},
    "hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
    "indonesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesian"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
    "kannada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kannada"])},
    "marathi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marathi"])},
    "polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
    "portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
    "romanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romanian"])},
    "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
    "swedish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
    "tamil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamil"])},
    "telugu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telugu"])},
    "thai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai"])},
    "turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "ukrainian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrainian"])},
    "vietnamese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamese"])},
    "greek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greek"])},
    "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
    "czech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech"])},
    "hungarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungarian"])},
    "slovenian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenian"])}
  },
  "validations": {
    "invalidFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잘못된 형식"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일이 잘못되었습니다."])},
    "wrongPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["올바르지 않은 암호"])}
  },
  "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당없음"])},
  "savingProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장 중..."])},
  "startBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시작"])},
  "saveBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장"])},
  "confirmBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인"])},
  "continueBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계속"])},
  "cancelBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
  "closeBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닫기"])},
  "deleteBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])},
  "editBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["편집"])},
  "submitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제출"])},
  "saveProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행 상황 저장"])},
  "loginBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
  "logoutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃"])},
  "signInBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
  "signOutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃"])},
  "updateBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업데이트"])},
  "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로필"])},
  "helpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영어"])},
  "guideText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가이드"])}
}