<template>
  <div
    :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
    :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'"
  >
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
      :label="fieldData.label[selectedLanguage]"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
    />

    <b-collapse
      class="card mt-5"
      id="collapse-1"
      v-show="JSON.parse(!fieldData.isHidden) || !isPreview"
      v-model="collapse"
    >
      <div
        :class="$store.state.rightAlign ? 'p-4 text-right' : 'p-4 text-left'"
        :id="fieldData.id"
      >
        <div class="text-center" style="display: inline-flex">
          <p v-if="JSON.parse(fieldData.uploadTitleTextIsDisabled) === 0">
            <span
              v-if="fieldData.validation && fieldData.validation.required == 1"
              class="required-span"
              >*</span
            >
            {{
              form1.enableSectionFieldEnumeration == 1
                ? sectionIndex + 1 + "." + (fieldIndex + 1)
                : ""
            }}
            {{ fieldData.label[selectedLanguage] }}
          </p>
        </div>
        <p
          v-if="JSON.parse(fieldData.uploadDescriptionTextIsDisabled) === 0"
          style="font: italic"
        >
          {{ fieldData.fileDescription[selectedLanguage] }}
        </p>
        <span
          v-if="JSON.parse(fieldData.validation.required) && isFocus"
          class="text-danger"
          >{{ $t("createProfile.requiredValidationMessage") }}</span
        >
        <vue-dropzone
          v-if="taskStatus != 4"
          ref="myVueDropzone"
          @vdropzone-success="vsuccess"
          @vdropzone-error="verror"
          id="dropzone"
          :options="dropzoneOptions"
          v-on:vdropzone-sending="sendingEvent"
          :useCustomSlot="true"
          class="m-5 p-3"
          style="border: dashed 1px"
          type="file"
        >
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">{{ $t("upload.dragDrop") }}</h3>
            <div class="subtitle">{{ $t("upload.dragDropContnt") }}</div>
          </div>
        </vue-dropzone>
        <b-row>
          <b-col cols="12">
            <b-table-simple striped hover>
              <b-thead>
                <b-tr>
                  <b-th>{{ $t("upload.fileName") }}</b-th>
                  <b-th>{{ $t("upload.fileType") }}</b-th>
                  <b-th>{{ $t("upload.fileSize") }}</b-th>
                  <b-th v-if="taskStatus != 4">{{
                    $t("upload.fileAction")
                  }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody v-for="(item, index) in files" :key="index">
                <b-tr>
                  <b-td>{{ item.filename }}</b-td>
                  <b-td>{{ item.type }}</b-td>
                  <b-td>{{ item.size }}</b-td>
                  <b-td v-if="taskStatus != 4">
                    <b-button
                      @click="onDeleteFile(index)"
                      variant="danger"
                      size="sm"
                    >
                      <font-awesome-icon icon="trash" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>

      <!-- show advance field options -->
      <b-modal
        ok-only
        ok-title="Close"
        @ok="onSaveData"
        size="lg"
        v-model="showSettings"
        scrollable
        title="Advanced Settings"
      >
        <AdvanceSettings
          :fieldData="fieldData"
          v-on:toggle-field="showSettings = !showSettings"
          :sectionIndex="sectionIndex"
          :fieldIndex="fieldIndex"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AdvanceSettings from "../advanceHtmlSettingsCompenent.vue";
import MenuButtons from "../menuButtons";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import VueDropzone from "../Dropzone/VueDropzone";
import { EventBus } from "@/event-bus.js";
import { Helper } from "@/helpers";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { routeKeys, routeNames } from "@/constants";
import { clone } from "lodash";

export default {
  name: "UploadGroup",
  components: {
    AdvanceSettings,
    MenuButtons,
    VueDropzone
  },
  data() {
    return {
      files: [],
      fields: ["filename", "type", "size", ""],
      isHidden: false,
      condition: {},
      showSettings: false,
      collapse: true,
      isFocus: false,
      isRequired: 0,
      fileTitle: null,
      fileDescription: null,
      taskStatus: 0,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL}v1/uploads/file/ddq`,
        thumbnailWidth: 150,
        maxFilesize: 25,
        addRemoveLinks: true,
        acceptedFiles: this.fieldData.configuration.selectedFormats.join(),
        headers: makeAuthorizationHeader()
      }
    };
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage"]),
    ...mapState({
      toggleAllCoppalse: (state) => state.toggleAllCoppalse,
      userId: (state) => state.user.userId,
      taskId: (state) => state.onboardingTasks.onboardingTask.taskId
    })
  },
  created() {
    EventBus.on("onSubmitEndUserForm", (value) => {
      if (value == "progress" || this.fieldData.isHidden) {
        this.setDataToUser();
        this.$store.state.validFields = true;
        this.fieldData.isInValid = false;
      } else {
        if (
          this.fieldData.validation &&
          this.fieldData.validation.required == 1
        ) {
          if (this.files && this.files.length > 0) {
            this.$store.state.validFields = true;
            this.fieldData.isInValid = false;

            this.setDataToUser();
          } else {
            this.isFocus = true;
            this.$store.state.validFields = false;
            this.fieldData.isInValid = true;
          }
        } else {
          this.setDataToUser();
        }
      }
    });

    EventBus.on("OnInvalidFieldCatch", (inValidData) => {
      document
        .getElementById(inValidData.elementId)
        .scrollIntoView({ behavior: "smooth" });
    });
  },
  mounted() {
    this.isRequired = this.fieldData.validation.required;
    if (
      this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
      this.$route.name == "MyCompanyView"
    ) {
      this.setDataFromUser();
    }
    let st = parseInt(localStorage.getItem("activeTaskStatus"));
    this.taskStatus = st;
  },
  watch: {
    toggleAllCoppalse(vlue) {
      this.collapse = vlue;
    }
  },
  methods: {
    setDataFromUser() {
      this.$store.state.getUserFormSubmitArray.length &&
        this.$store.state.getUserFormSubmitArray.forEach((a) => {
          if (a.elementId === this.fieldData.id) {
            this.fieldData.isHidden =
              a.isHiddenElement === 1 || this.fieldData.isHidden;
            a.elements.forEach((el) => {
              this.files = el.postValue || [];
            });
          }
        });
    },
    readableBytes(bytes) {
      let i = Math.floor(Math.log(bytes) / Math.log(1024));
      let sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      return `${(bytes / Math.pow(1024, i)).toFixed(2) * 1} ${sizes[i]}`;
    },
    verror(file) {
      Helper.makeToast(
        this.$bvToast,
        "danger",
        this.$t("validations.invalidFormat")
      );
      this.$refs.myVueDropzone.removeFile(file);
    },
    vsuccess(file, response) {
      const registerData = clone(this.$store.state.registerData);
      registerData[this.fieldData.id] = file.name;
      this.$store.state.registerData = registerData;

      this.isFocus = false;
      this.$store.state.validFields = true;
      this.fieldData.isInValid = false;
      const numkeys = Object.keys(registerData).length;
      this.$store.state.progressValue = numkeys;

      let uploadedFile = {
        filename: file.name,
        size: file.size,
        type: file.type,
        actions: "&nbsp;",
        fileId: response.fileId || null
      };
      this.files.push(uploadedFile);
    },
    onDeleteFile(index) {
      const registerData = clone(this.$store.state.registerData);
      if (this.files.length == 1) {
        delete registerData[this.fieldData.id];
        this.$store.state.registerData = registerData;
        const numkeys = Object.keys(registerData).length;
        this.$store.state.progressValue = numkeys;
      }
      this.onDeleteApi(this.files[index], index);
    },
    async onDeleteApi(file, index) {
      if (file && file.fileId) {
        let result = await HTTP(
          "post",
          "v1/thirdparty/delete_document",
          {
            doc_delete_type: "ddq-form",
            doc_id: file.fileId,
            third_party_id: 0,
            company_id: 0,
            user_id: this.userId
          },
          makeAuthorizationHeader(),
          null,
          false
        );
        if (result.data) {
          this.files.splice(index, 1);
        }
      }
    },
    onSaveData() {
      EventBus.emit("onSaveSettings", "saving settings");
      Helper.makeToast(this.$bvToast, "success", "Settings Updated");
    },
    setDataToUser(e) {
      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        this.$route.name == "MyCompanyView"
      ) {
        this.name = e;
        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId === this.fieldData.id) {
            if (this.files.length) {
              a.elements[0] = {
                postValue: this.files,
                postName: "file"
              };
            } else {
              a.elements = [];
            }
          }
        });
      }
    },
    sendingEvent(file, xhr, formData) {
      formData.append("formId", this.form1.formId);
      formData.append("fieldId", this.fieldData.id);
      formData.append("fieldIndex", this.fieldIndex);
      formData.append("sectionIndex", this.fieldIndex);
      formData.append("sectionId", this.fieldIndex);
      formData.append("taskId", this.taskId);
    }
  },
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>

<style lang="scss">
.required-span {
  font-size: 24px;
  margin-left: 3px;
  color: $spanish-red;
}
</style>
