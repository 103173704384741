import {
  makeBaseDestinationDTO,
  makeBaseDestinationAPIDTO
} from "@/services/commonDto";
import {
  userEmailTemplateKeysDTO,
  userEmailTemplateKeysAPIDTO
} from "@/constants";
import { makeUUID } from "@/utils";

const makeUserEmailTemplateExpectedDataDTO = (expectedData) => ({
  [userEmailTemplateKeysDTO.VARIABLE]:
    expectedData?.[userEmailTemplateKeysAPIDTO.VARIABLE],
  [userEmailTemplateKeysDTO.TYPE]:
    expectedData?.[userEmailTemplateKeysAPIDTO.TYPE],
  [userEmailTemplateKeysDTO.ID]: makeUUID()
});

const makeUserEmailTemplateExpectedDataAPIDTO = (expectedData) => ({
  [userEmailTemplateKeysAPIDTO.VARIABLE]:
    expectedData?.[userEmailTemplateKeysDTO.VARIABLE],
  [userEmailTemplateKeysAPIDTO.TYPE]:
    expectedData?.[userEmailTemplateKeysDTO.TYPE]
});

const makeUserEmailTemplateDTO = ({
  userEmailTemplate = {},
  _makeUserEmailTemplateExpectedDataDTO = makeUserEmailTemplateExpectedDataDTO,
  _makeBaseDestinationDTO = makeBaseDestinationDTO
} = {}) => ({
  [userEmailTemplateKeysDTO.ID]:
    userEmailTemplate?.[userEmailTemplateKeysAPIDTO.ID],
  [userEmailTemplateKeysDTO.COMPANY_ID]:
    userEmailTemplate?.[userEmailTemplateKeysAPIDTO.COMPANY_ID],
  [userEmailTemplateKeysDTO.NAME]:
    userEmailTemplate?.[userEmailTemplateKeysAPIDTO.NAME],
  [userEmailTemplateKeysDTO.DESCRIPTION]:
    userEmailTemplate?.[userEmailTemplateKeysAPIDTO.DESCRIPTION],
  [userEmailTemplateKeysDTO.SUBJECT]:
    userEmailTemplate?.[userEmailTemplateKeysAPIDTO.SUBJECT],
  [userEmailTemplateKeysDTO.BODY]:
    userEmailTemplate?.[userEmailTemplateKeysAPIDTO.BODY],
  [userEmailTemplateKeysDTO.EXPECTED_DATA]: userEmailTemplate?.[
    userEmailTemplateKeysAPIDTO.EXPECTED_DATA
  ]?.map((userEmailTemplate) =>
    _makeUserEmailTemplateExpectedDataDTO(userEmailTemplate)
  ),
  [userEmailTemplateKeysDTO.TO]: _makeBaseDestinationDTO({
    destinations: userEmailTemplate?.[userEmailTemplateKeysAPIDTO.TO]
  }),
  [userEmailTemplateKeysDTO.CC]: _makeBaseDestinationDTO({
    destinations: userEmailTemplate?.[userEmailTemplateKeysAPIDTO.CC]
  }),
  [userEmailTemplateKeysDTO.BCC]: _makeBaseDestinationDTO({
    destinations: userEmailTemplate?.[userEmailTemplateKeysAPIDTO.BCC]
  })
});

const makeUserEmailTemplateAPIDTO = ({
  userEmailTemplate = {},
  _makeUserEmailTemplateExpectedDataAPIDTO = makeUserEmailTemplateExpectedDataAPIDTO,
  _makeBaseDestinationAPIDTO = makeBaseDestinationAPIDTO
} = {}) => ({
  [userEmailTemplateKeysAPIDTO.COMPANY_ID]:
    userEmailTemplate?.[userEmailTemplateKeysDTO.COMPANY_ID],
  [userEmailTemplateKeysAPIDTO.ID]:
    userEmailTemplate?.[userEmailTemplateKeysDTO.ID],
  [userEmailTemplateKeysAPIDTO.NAME]:
    userEmailTemplate?.[userEmailTemplateKeysDTO.NAME],
  [userEmailTemplateKeysAPIDTO.DESCRIPTION]:
    userEmailTemplate?.[userEmailTemplateKeysDTO.DESCRIPTION],
  [userEmailTemplateKeysAPIDTO.SUBJECT]:
    userEmailTemplate?.[userEmailTemplateKeysDTO.SUBJECT],
  [userEmailTemplateKeysAPIDTO.BODY]:
    userEmailTemplate?.[userEmailTemplateKeysDTO.BODY],
  [userEmailTemplateKeysAPIDTO.EXPECTED_DATA]: userEmailTemplate?.[
    userEmailTemplateKeysDTO.EXPECTED_DATA
  ]?.map((userEmailTemplate) =>
    _makeUserEmailTemplateExpectedDataAPIDTO(userEmailTemplate)
  ),
  [userEmailTemplateKeysAPIDTO.TO]: _makeBaseDestinationAPIDTO({
    destinations: userEmailTemplate?.[userEmailTemplateKeysDTO.TO]
  }),
  [userEmailTemplateKeysAPIDTO.CC]: _makeBaseDestinationAPIDTO({
    destinations: userEmailTemplate?.[userEmailTemplateKeysDTO.CC]
  }),
  [userEmailTemplateKeysAPIDTO.BCC]: _makeBaseDestinationAPIDTO({
    destinations: userEmailTemplate?.[userEmailTemplateKeysDTO.BCC]
  })
});

const makeUserEmailTemplatesDTO = ({
  userEmailTemplates = [],
  _makeUserEmailTemplateDTO = makeUserEmailTemplateDTO
} = {}) =>
  userEmailTemplates?.map((userEmailTemplate) =>
    _makeUserEmailTemplateDTO({ userEmailTemplate })
  ) || [];

export {
  makeUserEmailTemplateExpectedDataDTO,
  makeUserEmailTemplateExpectedDataAPIDTO,
  makeUserEmailTemplateDTO,
  makeUserEmailTemplateAPIDTO,
  makeUserEmailTemplatesDTO
};
