import axios from "axios";
import { config } from "@/configs";
import store from "@/store";
import { httpStatuses, actionName, getterName } from "@/constants";

const makeAuthorizationHeader = () => ({
  Authorization: `Bearer ${store.state.auth?.token}`
});

const hasStatus200 = (httpResponse) => httpResponse?.status === 200;

const isValidResponse = (httpResponse, successProperty = "status") =>
  hasStatus200(httpResponse) &&
  httpResponse?.data[successProperty] === httpStatuses.SUCCESS;

const isNotAuthorized = (httpResponse) => httpResponse?.status === 401;
const hasStatus500 = (httpResponse) => httpResponse?.status === 500;
const hasStatus404 = (httpResponse) => httpResponse?.status === 404;
const hasStatus412 = (httpResponse) => httpResponse?.status === 412;
const hasStatus422 = (httpResponse) => httpResponse?.status === 422;

const makeAxiosQuery = ({
  method,
  url,
  data,
  headers,
  params = null,
  responseType = null,
  _makeAuthorizationHeader = makeAuthorizationHeader
} = {}) => {
  let query = {
    url: config.API_URL + url,
    method,
    headers: {
      ..._makeAuthorizationHeader(),
      ...headers
    }
  };

  if (params !== null) {
    query.params = params;
  }

  if (
    method === "post" ||
    method === "put" ||
    method === "delete" ||
    method === "patch" ||
    method === "options"
  ) {
    query.data = data;
  }

  if (responseType !== null) {
    query.responseType = responseType;
  }

  return query;
};

let HTTP = async (
  method,
  url,
  data,
  headers,
  params = null,
  showLoader = false,
  responseType = null
) => {
  try {
    store.state.isLoading = showLoader;
    // Assigned before returning as it does not catch an error - weird edge case
    const response = await axios(
      makeAxiosQuery({ method, url, data, headers, params, responseType })
    );
    return response;
  } catch (error) {
    if (
      store.getters[getterName.AUTH.IS_USER_AUTHENTICATED] &&
      isNotAuthorized(error.response)
    ) {
      await store.dispatch(actionName.AUTH.LOG_OUT);
    }

    throw error;
  } finally {
    store.state.isLoading = false;
  }
};

export {
  HTTP,
  makeAxiosQuery,
  isNotAuthorized,
  hasStatus500,
  hasStatus404,
  hasStatus412,
  hasStatus422,
  hasStatus200,
  isValidResponse,
  makeAuthorizationHeader
};
