import { makeUUID } from "@/utils";
import { actionStepType } from "@/constants";

export class TaskStepClass {
  id = makeUUID();

  stepType = actionStepType.TASK;

  comment = "";

  hasComment = false;

  taskTemplatesOptions = [];

  taskTemplateError = "";

  taskTemplateSearchValue = "";

  taskTemplateExpectedData = [];

  taskTemplateAssigneeOptions = [];

  taskTemplateAssigneeError = "";

  taskTemplateInformation = "";

  constructor({
    comment = "",
    hasComment = false,
    taskTemplatesOptions = [],
    taskTemplateError = "",
    taskTemplateSearchValue = "",
    taskTemplateExpectedData = [],
    taskTemplateAssigneeOptions = [],
    taskTemplateAssigneeError = "",
    taskTemplateInformation = ""
  }) {
    this.setComment(comment);
    this.setHasComment(hasComment);
    this.setTaskTemplatesOptions(taskTemplatesOptions);
    this.setTaskTemplateInformation(taskTemplateInformation);
    this.setTaskTemplateError(taskTemplateError);
    this.setTaskTemplateSearchValue(taskTemplateSearchValue);
    this.setTaskTemplateExpectedData(taskTemplateExpectedData);
    this.setTaskTemplateAssigneeOptions(taskTemplateAssigneeOptions);
    this.setTaskTemplateAssigneeError(taskTemplateAssigneeError);
  }

  setComment(comment) {
    this.comment = comment;
  }

  setHasComment(hasComment) {
    this.hasComment = hasComment;
  }

  setTaskTemplatesOptions(taskTemplatesOptions) {
    this.taskTemplatesOptions = taskTemplatesOptions;
  }

  setTaskTemplateError(taskTemplateError) {
    this.taskTemplateError = taskTemplateError;
  }

  setTaskTemplateSearchValue(taskTemplateSearchValue) {
    this.taskTemplateSearchValue = taskTemplateSearchValue;
  }

  setTaskTemplateExpectedData(taskTemplateExpectedData) {
    this.taskTemplateExpectedData = taskTemplateExpectedData;
  }

  setTaskTemplateAssigneeOptions(taskTemplateAssigneeOptions) {
    this.taskTemplateAssigneeOptions = taskTemplateAssigneeOptions;
  }

  setTaskTemplateAssigneeError(taskTemplateAssigneeError) {
    this.taskTemplateAssigneeError = taskTemplateAssigneeError;
  }

  setTaskTemplateInformation(taskTemplateInformation) {
    this.taskTemplateInformation = taskTemplateInformation;
  }
}
