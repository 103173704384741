<!-- eslint-disable vue-a11y/no-onchange -->
<template>
  <div v-bind="$attrs" class="select">
    <template v-if="label">
      <Label
        :id="id"
        :label="label"
        :isRequired="isRequired"
        :class="[{ 'select__label--hidden': isLabelHidden }]"
      >
        <template v-for="(_, slot) in $slots">
          <template :slot="slot">
            <slot :name="slot" />
          </template>
        </template>
      </Label>
    </template>

    <div
      :class="[
        'select__input-wrapper',
        fullWidth && 'select__input-wrapper--full-width'
      ]"
      data-test-id="select__input-wrapper"
    >
      <select
        v-if="isDataSettled"
        :name="name"
        :id="id"
        class="select__items-container"
        data-test-id="select__items-container"
        @change="changeItems"
        :placeholder="placeholder"
        :disabled="isDisabled"
      >
        <option
          v-for="(option, index) in availableOption"
          v-bind="option"
          class="select__item"
          data-test-id="select__item"
          :key="`select-item-${index}`"
        >
          {{ option.text }}
        </option>
      </select>
      <InputErrorMessage :error="error" />
    </div>
  </div>
</template>

<script>
import Label from "@/atoms/Label/Label";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import { nextTick } from "vue";

export default {
  name: "Select",
  components: { Label, InputErrorMessage },
  props: {
    name: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ""
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    id: {
      required: true,
      type: String
    },
    options: {
      type: Array,
      default: () => []
    },
    error: {
      default: "",
      type: String
    },
    placeholder: {
      type: String,
      default: ""
    },
    isLabelHidden: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      availableOption: this.options,
      isDataSettled: true
    };
  },
  watch: {
    options() {
      this.resetSelect();
    }
  },
  methods: {
    changeItems({ target }) {
      let value = isNaN(target.value) ? target.value : Number(target.value);
      this.$emit("change", value);
    },
    async resetSelect() {
      this.isDataSettled = false;
      this.availableOption = [];
      await nextTick();
      this.availableOption = this.options;
      this.isDataSettled = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.select {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;

  &__items-container {
    min-width: 100px;
    max-width: 100%;
    width: 100%;
    padding: 6px;
    border-radius: $border-radius8;
    border: $element-border;
  }

  &__label--hidden {
    @include screen-reader-only;
  }

  &__input-wrapper--full-width {
    width: 100%;
  }
}
</style>
