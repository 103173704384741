import { userRolesKeysDTO, userRolesKeysAPIDTO } from "@/constants";

const makeRoleDTO = (role = {}) => ({
  [userRolesKeysDTO.ID]: role?.[userRolesKeysAPIDTO.ID] || "",
  [userRolesKeysDTO.NAME]: role?.[userRolesKeysAPIDTO.NAME] || "",
  [userRolesKeysDTO.DESCRIPTION]: role?.[userRolesKeysAPIDTO.DESCRIPTION] || "",
  [userRolesKeysDTO.IS_SYSTEM]: role?.[userRolesKeysAPIDTO.IS_SYSTEM] || ""
});

const makeRoleAPIDTO = (role = {}) => ({
  [userRolesKeysAPIDTO.NAME]: role[userRolesKeysDTO.NAME] || "",
  [userRolesKeysAPIDTO.DESCRIPTION]: role[userRolesKeysDTO.DESCRIPTION] || ""
});

const makeRolesDTO = (rolesData = []) => rolesData?.map(makeRoleDTO);

export { makeRoleDTO, makeRoleAPIDTO, makeRolesDTO };
