<template>
  <div class="greenlite-third-parties" data-test-id="greenlite-third-parties">
    <BaseText
      v-if="errorMessage"
      data-test-id="greenlite-third-parties__error-message"
      :theme="themes.ERROR_INVERSE"
      :text="[errorMessage]"
    />
    <BaseLoader v-if="isFirstLoadingOfTableData" />
    <DataTable
      v-else
      :searchFilterBarOptions="searchFilterBarOptions"
      :ag-grid-options="tableData"
      :pagination-options="paginationOptions"
      data-test-id="greenlite-third-parties__table"
      ref="data-table"
      grid-height="calc(100vh - 310px)"
      @grid-ready="onGridReady"
      @sort-changed="onSortOrderChange"
      @click="onTriggerTableEvent(DOMEvents.CLICK, $event)"
      @input="onTriggerTableEvent(DOMEvents.INPUT, $event)"
      @change="onTriggerTableEvent(DOMEvents.CHANGE, $event)"
    />
    <Modal
      v-if="isModalVisible"
      title="Export Third Party"
      @close-modal="setModalVisibility(false)"
    >
      <template>
        <BaseText class="esg-export-modal__text" :text="modalMessage" />
      </template>
      <template v-slot:right>
        <CallToAction value="Close" @click="setModalVisibility(false)" />
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { esgService } from "@/services";
import {
  dataTableEvents,
  DOMEvents,
  emptyOption,
  esgExportReportKeys,
  featurePermissionsKeys,
  filterEvents,
  genericEvents,
  getterName,
  gradeRating,
  greenliteColumnIdKeys,
  greenliteKeySections,
  greenliteThirdPartiesTableHeaders,
  greenliteThirdPartyKeys,
  operations,
  riskScale,
  sortOrder,
  strengthScale,
  themes,
  thirdPartyEvents,
  thirdPartyTabHash,
  timers,
  typographySize,
  urls
} from "@/constants";
import {
  getRatingTheme,
  hasPermission,
  hasStatus200,
  makeStringCamelToSnakeCase,
  makeUUID
} from "@/utils";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import BaseBadge from "@/atoms/BaseBadge/BaseBadge";
import BaseText from "@/atoms/BaseText/BaseText";
import DataTable from "@/organisms/DataTable/DataTable";
import { RouterLink } from "vue-router";
import Modal from "@/molecules/Modal/Modal";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import { cloneDeep, debounce, isEqual } from "lodash";
import { FilterSectionClass } from "@/organisms/FilterSection/FilterSection.class";
import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";
import MultiSelect from "@/molecules/MultiSelect/MultiSelect";
import { makeOptionsForMultiSelect } from "@/molecules/MultiSelect/MultiSelect.dto";

export default {
  name: "GreenliteThirdParties",
  components: {
    CallToAction,
    Modal,
    DataTable,
    BaseText,
    BaseLoader
  },
  data() {
    return {
      DOMEvents,
      themes,
      errorMessage: "",
      notWorkingErrorMessage:
        "Something went wrong, please try again. If the issue persists please contact support.",
      isFirstLoadingOfTableData: false,
      columnPosition: 0,
      sortBy: "name",
      sortOrder: sortOrder.ASCENDING,
      filterQueryParameters: {},
      isModalVisible: false,
      modalMessage: "",
      tableData: {
        gridOptions: {
          suppressRowClickSelection: true,
          overlayNoRowsTemplate: "<span>No results found</span>",
          suppressMultiSort: true,
          defaultColDef: {
            sortable: true,
            unSortIcon: true,
            sortingOrder: ["asc", "desc"]
          }
        },
        rowData: [],
        columnDefs: [],
        getRowId: (params) => `${params.data.id}`
      },
      paginationOptions: {
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 50,
        options: [10, 20, 50, 100]
      },
      searchFilterBarOptions: {
        hasFilters: true,
        showFilterSection: false,
        hasSearchBar: true,
        searchBarOptions: {
          value: ""
        },
        filterOptions: []
      },
      filterValues: {
        strength: Object.values(strengthScale),
        rating: Object.values(gradeRating),
        severity: Object.values(riskScale)
      },
      debounceFetchThirdParties: debounce(() => {
        this.fetchESGThirdParties();
      }, timers.FAST)
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId,
      companyName: (state) => state.company.companyName,
      email: (state) => state.user.email,
      userId: (state) => state.user.userId
    }),
    ...mapGetters({
      isUserSuperAdmin: getterName.USER.IS_USER_SUPER_ADMIN
    }),
    hasESGExportPermission() {
      return hasPermission({
        key: featurePermissionsKeys.ESG_EXPORT
      });
    },
    defaultFilterOptions() {
      const headerKeys = Object.values(greenliteColumnIdKeys).filter(
        (key) => key !== greenliteColumnIdKeys.NAME
      );

      return headerKeys.map((key) => ({
        value: key,
        text: greenliteThirdPartiesTableHeaders[key]
      }));
    }
  },
  watch: {
    "paginationOptions.currentPage": {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.debounceFetchThirdParties();
        }
      }
    },
    "paginationOptions.itemsPerPage": {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.debounceFetchThirdParties();
        }
      }
    },
    filterQueryParameters: {
      deep: true,
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          this.debounceFetchThirdParties();
        }
      }
    },
    "searchFilterBarOptions.searchBarOptions.value": {
      handler() {
        debounce(() => {
          this.fetchESGThirdParties();
        }, timers.MEDIUM)();
      }
    }
  },
  async created() {
    this.setSearchFilterBarOptions();
    this.makeFilterOptions();
    await this.fetchESGThirdParties(true);
  },
  methods: {
    makeNameColumnHeader(key) {
      return {
        field: key,
        headerName: greenliteThirdPartiesTableHeaders[key],
        cellRenderer: "BaseGridComponentWrapper",
        sort: sortOrder.ASCENDING,
        comparator: () => 0
      };
    },
    makeDefaultColumnHeader(key) {
      return {
        field: key,
        headerName: greenliteThirdPartiesTableHeaders[key],
        cellRenderer: "BaseGridComponentWrapper",
        headerClass: "ag-cell-center",
        cellClass: "ag-cell-center",
        width:
          greenliteThirdPartiesTableHeaders[key] ===
            greenliteThirdPartiesTableHeaders[
              greenliteThirdPartyKeys.HUMAN_RIGHTS_AND_MODERN_SLAVERY_STRENGTH
            ] ||
          greenliteThirdPartiesTableHeaders[key] ===
            greenliteThirdPartiesTableHeaders[
              greenliteThirdPartyKeys.WHISTLEBLOWING_STRENGTH
            ]
            ? 200
            : 160,
        valueFormatter: (params) => params.value?.componentOptions?.text || "",
        comparator: () => 0
      };
    },
    makeColumnHeaders(greenliteData) {
      const headers = Object.keys(greenliteData[0]).filter(
        (key) => key !== greenliteThirdPartyKeys.ID
      );

      this.tableData.columnDefs = headers.map((key) => {
        if (greenliteThirdPartiesTableHeaders[key]) {
          if (key === greenliteColumnIdKeys.NAME) {
            return this.makeNameColumnHeader(key);
          }
          return this.makeDefaultColumnHeader(key);
        }
      });
    },
    makeRowNameCell(data, key) {
      return {
        component: RouterLink,
        componentOptions: {
          to: `${urls.THIRD_PARTY_PROFILE(data[greenliteThirdPartyKeys.ID])}${
            thirdPartyTabHash.ESG
          }`
        },
        slotData: data[greenliteColumnIdKeys[key]],
        valueFormatter: () => data[greenliteColumnIdKeys[key]]
      };
    },
    makeDefaultRowCell(data, key) {
      return {
        component: BaseBadge,
        componentOptions: {
          tag: "span",
          theme: getRatingTheme(
            data[greenliteColumnIdKeys[key]],
            greenliteColumnIdKeys[key]
          ),
          text: data[greenliteColumnIdKeys[key]],
          size: typographySize.BODY_TEXT_BOLD,
          style: {
            width: "125px",
            display: data[greenliteColumnIdKeys[key]] ? "static" : "none"
          }
        },
        valueFormatter: (params) => params.value?.componentOptions?.text || ""
      };
    },
    makeRows(tableData = []) {
      this.tableData.rowData = tableData.map((data) => {
        const row = Object.keys(greenliteColumnIdKeys).reduce(
          (accumulation, key) => {
            if (greenliteColumnIdKeys[key] === greenliteColumnIdKeys.NAME) {
              accumulation[greenliteColumnIdKeys[key]] = this.makeRowNameCell(
                data,
                key
              );
            } else {
              accumulation[greenliteColumnIdKeys[key]] =
                this.makeDefaultRowCell(data, key);
            }

            return accumulation;
          },
          {}
        );

        return {
          ...row,
          id: data[greenliteThirdPartyKeys.ID]
        };
      });
    },
    setSearchFilterBarOptions() {
      this.searchFilterBarOptions = {
        ...this.searchFilterBarOptions,
        ...(this.hasESGExportPermission && {
          actionButtons: [
            {
              id: thirdPartyEvents.ESG_EXPORT,
              value: "ESG Export",
              isLoading: false,
              isSuccess: false,
              isError: false,
              isDisabled: false
            }
          ]
        })
      };
    },

    /* ******************
     ** Event handling **
     ****************** */
    onGridReady({ api }) {
      api.autoSizeColumns([greenliteColumnIdKeys.NAME]);
    },
    onTriggerTableEvent(eventType, { type, event }) {
      if (eventType === DOMEvents.CHANGE) {
        if (type === dataTableEvents.PER_PAGE_PAGINATION) {
          this.handlePaginationChange(event);
        } else if (type === dataTableEvents.SEARCH_FILTER_BAR) {
          this.handleFilterOptionChange(event.event);
        }
      } else if (eventType === DOMEvents.INPUT) {
        this.setSearchInput(event.event.target.value);
      } else {
        if (type === dataTableEvents.PER_PAGE_PAGINATION) {
          this.handlePaginationClick(event);
        } else if (type === dataTableEvents.SEARCH_FILTER_BAR) {
          this.handleSearchFilterBarClicks(event);
        }
      }
    },
    setSearchInput(value) {
      this.searchFilterBarOptions.searchBarOptions.value = value;
    },
    toggleFilterVisibility(visibility) {
      this.searchFilterBarOptions.showFilterSection = visibility;
    },
    handleSearchFilterBarClicks({ id, event }) {
      if (id === genericEvents.SEARCH) {
        this.handleSearchBarEvents(event);
      } else if (id === thirdPartyEvents.ESG_EXPORT) {
        this.fetchESGExportReportWrapper();
      } else if (id === genericEvents.FILTER) {
        this.handleFilterEvents(event);
      } else if (id === genericEvents.TOGGLE) {
        this.toggleFilterVisibility(
          !this.searchFilterBarOptions.showFilterSection
        );
      }
    },
    handleSearchBarEvents({ type }) {
      if (type === genericEvents.CLEAR) {
        this.setSearchInput("");
      } else if (type === genericEvents.SEARCH) {
        this.fetchESGThirdParties();
      }
    },
    handlePaginationClick(event) {
      this.paginationOptions.currentPage = event;
    },
    handlePaginationChange(event) {
      this.paginationOptions.itemsPerPage = event;
      this.paginationOptions.currentPage = 1;
    },
    handleFilterEvents({ type, id }) {
      if (type === genericEvents.REMOVE) {
        this.removeFilter(id);
      } else if (type === genericEvents.ADD) {
        this.makeFilterOptions();
      } else if (type === genericEvents.CLEAR_ALL) {
        this.removeAllFilters();
      } else if (type === genericEvents.APPLY) {
        this.updateFilterQueryParameters();
      }
    },

    /* ***************************
     ** Fetch ESG Export Report **
     *************************** */
    onSortOrderChange({ api }) {
      const { colId, sort } =
        api.getColumnState().find(({ sort }) => !!sort) || {};

      this.sortBy = makeStringCamelToSnakeCase(colId);
      this.sortOrder = sort;
      this.fetchESGThirdParties();
    },
    async fetchESGExportReportWrapper() {
      try {
        this.setErrorMessage();
        this.setIsLoadingESGReport(true);
        const result = await esgService.exportESGReports(
          this.makePostObjectForESGExport()
        );
        if (hasStatus200(result)) {
          this.setModalMessage(result?.data?.message);
          this.setModalVisibility(true);
        }
      } catch {
        this.setErrorMessage(this.notWorkingErrorMessage);
      } finally {
        this.setIsLoadingESGReport(false);
      }
    },
    makePostObjectForESGExport() {
      return {
        [esgExportReportKeys.COMPANY_ID]: this.companyId,
        [esgExportReportKeys.USER_ID]: this.userId,
        [esgExportReportKeys.COMPANY_NAME]: this.companyName,
        [esgExportReportKeys.EMAIL]: this.email
      };
    },
    setIsLoadingESGReport(isLoading) {
      this.tableData.gridOptions.showLoader = isLoading;
      this.searchFilterBarOptions.actionButtons[0].isLoading = isLoading;
    },
    setModalVisibility(value) {
      this.isModalVisible = value;
    },
    setModalMessage(value) {
      this.modalMessage = value || "";
    },

    /* ***********************
     ** Fetch Third Parties **
     *********************** */
    fetchESGThirdPartiesSuccessfully({ isFirstLoad, data }) {
      this.paginationOptions.totalItems = data?.total;
      if (data?.data?.length) {
        if (isFirstLoad) {
          this.makeColumnHeaders(data?.data);
        }
        this.makeRows(data?.data);
      } else {
        this.makeRows();
      }
    },
    makeQueryForESGThirdParties() {
      return {
        per_page: this.paginationOptions.itemsPerPage,
        page_number: this.paginationOptions.currentPage,
        sort: `${this.sortBy}|${this.sortOrder}`,
        third_party_name_search:
          this.searchFilterBarOptions.searchBarOptions.value || null,
        ...this.filterQueryParameters
      };
    },
    setIsFirstLoadingOfTableData(value) {
      this.isFirstLoadingOfTableData = value;
    },
    setGridOptionsShowLoader(value) {
      this.tableData.gridOptions.showLoader = value;
    },
    async fetchESGThirdParties(isFirstLoad = false) {
      try {
        this.setErrorMessage();
        if (isFirstLoad) {
          this.setIsFirstLoadingOfTableData(true);
        } else {
          this.setGridOptionsShowLoader(true);
        }
        const { data = {} } =
          (await esgService.fetchEsgThirdParties(
            this.companyId,
            this.makeQueryForESGThirdParties()
          )) || {};
        this.fetchESGThirdPartiesSuccessfully({ isFirstLoad, data });
      } catch {
        this.setErrorMessage(this.notWorkingErrorMessage);
      } finally {
        if (isFirstLoad) {
          this.setIsFirstLoadingOfTableData(false);
        } else {
          this.setGridOptionsShowLoader(false);
        }
      }
    },
    setErrorMessage(value = "") {
      this.errorMessage = value;
    },

    /* ******************
     ** Filter Options **
     ****************** */

    makeFilterOptions() {
      const searchFilterBarOptionsClone = cloneDeep(
        this.searchFilterBarOptions
      );
      const filterSectionId = makeUUID();
      const filterSection = new FilterSectionClass({
        id: filterSectionId,
        availableFilterOptions: [emptyOption, ...this.defaultFilterOptions],
        availableFilterValueComponentOptions: {
          label: "Select value",
          placeholder: "Select value",
          isLabelHidden: true,
          id: `value-${filterSectionId}`,
          onChange: (event) =>
            this.handleFilterOptionChange({
              type: filterEvents.OPTIONS_UPDATED,
              id: filterSectionId,
              event
            })
        },
        availableFilterValueOptionsComponent: MultiSelect
      });

      searchFilterBarOptionsClone?.filterOptions.push(filterSection);
      this.searchFilterBarOptions = searchFilterBarOptionsClone;
    },
    makeOptionSetForFilterValues(options) {
      const keys = Object.values(options);

      return keys.map((key) => ({
        value: key,
        text: key
      }));
    },
    getOptionsForSelectedFilter(filterValue) {
      if (greenliteKeySections.ratingKeys.includes(filterValue)) {
        return this.makeOptionSetForFilterValues(gradeRating);
      } else if (greenliteKeySections.severityKeys.includes(filterValue)) {
        return this.makeOptionSetForFilterValues(riskScale);
      } else if (greenliteKeySections.strengthKeys.includes(filterValue)) {
        return this.makeOptionSetForFilterValues(strengthScale);
      }
      return [];
    },
    makeFilterValueOptions({ index, value }) {
      const valuesForSelectedFilter = this.getOptionsForSelectedFilter(value);

      this.searchFilterBarOptions.filterOptions[
        index
      ].availableFilterValueComponentOptions.options =
        makeOptionsForMultiSelect(
          [],
          [],
          [emptyOption, ...valuesForSelectedFilter]
        );
    },
    removeFilter(filterId) {
      const searchFilterBarOptionsClone = cloneDeep(
        this.searchFilterBarOptions
      );

      searchFilterBarOptionsClone.filterOptions =
        searchFilterBarOptionsClone.filterOptions.filter(
          ({ id }) => id !== filterId
        );
      this.searchFilterBarOptions = searchFilterBarOptionsClone;
    },
    removeAllFilters() {
      if (this.searchFilterBarOptions.filterOptions?.length) {
        this.searchFilterBarOptions.filterOptions = [];
        this.updateFilterQueryParameters();
      }
    },
    updateFilterOptions({ index, value }) {
      this.searchFilterBarOptions.filterOptions[index].availableFilterOptions =
        makeOptionsForSelect(
          value,
          this.searchFilterBarOptions.filterOptions[index]
            .availableFilterOptions
        );
      this.makeFilterValueOptions({
        index,
        value
      });
    },
    addFilterOptionsValue({ index, value }) {
      const selectedValues = [
        ...this.searchFilterBarOptions.filterOptions[
          index
        ].getSelectedFilterOptionValues(),
        value
      ];
      const newOptions = makeOptionsForMultiSelect(
        selectedValues,
        selectedValues,
        this.searchFilterBarOptions.filterOptions[index]
          .availableFilterValueComponentOptions.options
      );
      const newComponentOptions = {
        ...this.searchFilterBarOptions.filterOptions[index]
          .availableFilterValueComponentOptions,
        options: newOptions
      };

      this.searchFilterBarOptions.filterOptions[
        index
      ].availableFilterValueComponentOptions = newComponentOptions;
    },
    removeFilterOptionValue({ index, value }) {
      const selectedValues = this.searchFilterBarOptions.filterOptions[index]
        .getSelectedFilterOptionValues()
        .filter((filterValue) => filterValue !== value);
      const newOptions = makeOptionsForMultiSelect(
        selectedValues,
        selectedValues,
        this.searchFilterBarOptions.filterOptions[index]
          .availableFilterValueComponentOptions.options
      );

      const newComponentOptions = {
        ...this.searchFilterBarOptions.filterOptions[index]
          .availableFilterValueComponentOptions,
        options: newOptions
      };

      this.searchFilterBarOptions.filterOptions[
        index
      ].availableFilterValueComponentOptions = newComponentOptions;
    },
    updateFilterOptionsValue({ index, value }) {
      if (value.eventType === operations.ADD) {
        this.addFilterOptionsValue({ index, value: value.value });
      } else {
        this.removeFilterOptionValue({ index, value: value.value });
      }
    },
    getIndexOfFilterToUpdateById(id) {
      return this.searchFilterBarOptions.filterOptions.findIndex(
        ({ id: filterId }) => id === filterId
      );
    },
    handleFilterOptionChange({ type, id, event }) {
      const indexOfFilterToUpdate = this.getIndexOfFilterToUpdateById(id);

      if (type === filterEvents.VALUES_UPDATED) {
        this.updateFilterOptions({
          index: indexOfFilterToUpdate,
          value: event
        });
      } else if (type === filterEvents.OPTIONS_UPDATED) {
        this.updateFilterOptionsValue({
          index: indexOfFilterToUpdate,
          value: event
        });
      }
    },
    getSelectedFilters() {
      return (
        this.searchFilterBarOptions.filterOptions.reduce(
          (
            accumulation,
            { availableFilterOptions, availableFilterValueComponentOptions }
          ) => {
            if (
              !availableFilterOptions?.length &&
              !availableFilterValueComponentOptions?.length
            ) {
              return;
            }

            const { value: selectedFilter = "" } =
              availableFilterOptions.find(({ selected }) => selected) || {};
            const selectedOptionList =
              availableFilterValueComponentOptions.options.filter(
                ({ selected }) => selected
              ) || [];

            if (!selectedFilter || !selectedOptionList.length) {
              return;
            }

            const selectedValues = selectedOptionList.map(({ value }) =>
              value.toLowerCase()
            );

            accumulation[makeStringCamelToSnakeCase(selectedFilter)] =
              JSON.stringify(selectedValues);

            return accumulation;
          },
          {}
        ) || {}
      );
    },
    removeEmptyFilters() {
      this.searchFilterBarOptions.filterOptions =
        this.searchFilterBarOptions.filterOptions.reduce(
          (accumulation, filterSection) => {
            const selectedFilterValue = filterSection.getSelectedFilterValue();
            const selectedFilterOptionValues =
              filterSection.getSelectedFilterOptionValues();

            if (selectedFilterValue && selectedFilterOptionValues?.length) {
              accumulation.push(filterSection);
            }

            return accumulation;
          },
          []
        );
    },
    updateFilterQueryParameters() {
      this.removeEmptyFilters();
      this.filterQueryParameters = this.getSelectedFilters();
    }
  }
};
</script>
