import { tasksService } from "@/services";
import { actionName, mutationName, tasksKeysAPIDTO } from "@/constants";

export const actions = {
  async fetchTasks({ commit, rootState, state }, payload = {}) {
    try {
      commit(mutationName.TASKS.SET_IS_LOADING_TASKS, true, {
        root: true
      });
      const response = await tasksService.fetchTasks({
        companyId: rootState.company.companyId,
        filter: state?.tasksFilter,
        pagination: state.tasksPagination,
        excludedFields: [
          tasksKeysAPIDTO.OUTCOME_ACTIONS,
          tasksKeysAPIDTO.OUTCOMES_DATA,
          tasksKeysAPIDTO.EXPECTED_DATA_DEFINITION,
          tasksKeysAPIDTO.PROVIDED_DATA,
          tasksKeysAPIDTO.EXPECTED_DATA_MAPPING
        ],
        ...payload
      });
      commit(mutationName.TASKS.SET_TASKS, response.data.data, {
        root: true
      });
      commit(
        mutationName.TASKS.SET_TASKS_PAGINATION,
        {
          ...(payload?.pagination || state.tasksPagination),
          totalItems: response.data.meta.totalItems
        },
        {
          root: true
        }
      );
      commit(
        mutationName.TASKS.SET_TASKS_FILTER,
        [...(payload?.filter || state?.tasksFilter)],
        {
          root: true
        }
      );
      return response;
    } finally {
      commit(mutationName.TASKS.SET_IS_LOADING_TASKS, false, {
        root: true
      });
    }
  },
  async fetchTask({ commit, rootState }, taskId) {
    try {
      commit(mutationName.TASKS.SET_IS_LOADING_TASK, true, {
        root: true
      });
      const { data } = await tasksService.fetchTask({
        companyId: rootState.company.companyId,
        taskId
      });
      commit(mutationName.TASKS.SET_TASK, data, {
        root: true
      });
    } finally {
      commit(mutationName.TASKS.SET_IS_LOADING_TASK, false, {
        root: true
      });
    }
  },
  async completeTask({ dispatch, commit, rootState, state }, amendedTaskData) {
    try {
      commit(mutationName.TASKS.SET_IS_LOADING_TASK, true, {
        root: true
      });
      await tasksService.completeTask({
        companyId: rootState.company.companyId,
        task: state.task,
        amendedTaskData
      });
      await dispatch(actionName.TASKS.FETCH_TASKS, {}, { root: true });
      await dispatch(actionName.TASKS.FETCH_TASK, state.task.id, {
        root: true
      });
    } finally {
      commit(mutationName.TASKS.SET_IS_LOADING_TASK, false, {
        root: true
      });
    }
  },
  async updateTask({ dispatch, commit, rootState, state }, amendedTaskData) {
    try {
      commit(mutationName.TASKS.SET_IS_LOADING_TASK, true, {
        root: true
      });
      const { data } = await tasksService.updateTask({
        companyId: rootState.company.companyId,
        task: state.task,
        amendedTaskData
      });
      commit(mutationName.TASKS.RESET_TASK, {}, { root: true });
      dispatch(actionName.TASKS.FETCH_TASKS, {}, { root: true });
      commit(mutationName.TASKS.SET_TASK, data, { root: true });
    } finally {
      commit(mutationName.TASKS.SET_IS_LOADING_TASK, false, {
        root: true
      });
    }
  },
  async reopenTask({ dispatch, commit, rootState }, taskId) {
    try {
      commit(mutationName.TASKS.SET_IS_LOADING_TASK, true, {
        root: true
      });
      const { data } = await tasksService.reopenTask({
        companyId: rootState.company.companyId,
        taskId
      });
      commit(mutationName.TASKS.SET_TASK, data, {
        root: true
      });
      dispatch(actionName.TASKS.FETCH_TASKS, {}, { root: true });
    } finally {
      commit(mutationName.TASKS.SET_IS_LOADING_TASK, false, {
        root: true
      });
    }
  },
  async deleteTask({ dispatch, commit, rootState }, taskId) {
    try {
      commit(mutationName.TASKS.SET_IS_LOADING_TASK, true, {
        root: true
      });
      await tasksService.deleteTask({
        companyId: rootState.company.companyId,
        taskId
      });
      commit(mutationName.TASKS.RESET_TASK, {}, { root: true });
      dispatch(actionName.TASKS.FETCH_TASKS, {}, { root: true });
    } finally {
      commit(mutationName.TASKS.SET_IS_LOADING_TASK, false, {
        root: true
      });
    }
  }
};
