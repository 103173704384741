<template>
  <div class="ddq-onboarding-view ml-4">
    <div v-if="isLoadingFormData"><b-spinner large></b-spinner></div>
    <div v-else>
      <template v-if="taskData">
        <b-container fluid style="height: calc(100vh - 185px)">
          <div>
            <b-overlay rounded="sm">
              <b-row>
                <b-col cols="12">
                  <div class="flex-column vs__no-options card-container">
                    <h2>{{ taskData.task_title }}</h2>
                    <p class="vs__no-options">
                      Completed At:
                      <b class="ddq-onboarding-view__completed-at"
                        >{{ getCompletedAtDate }}
                      </b>
                      Completed By:
                      <b>{{ taskData.ibf_member.name }}</b>
                    </p>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <!-- Sections col -->
                <b-col
                  style="
                    height: calc(100vh - 265px);
                    overflow-y: auto;
                    text-align: left;
                  "
                >
                  <b-card
                    header="Sections"
                    no-body
                    class="width-class ddq-onboarding-view__card-spacing"
                  >
                    <div
                      class="panel-body"
                      v-for="(sectionTitle, sectionIndex) in Object.keys(
                        this.sectionData
                      )"
                      :key="`sectionTitle-${sectionIndex}`"
                      style="font-size: 100%"
                    >
                      <b-card-text>
                        <b-link
                          :href="`#${sectionTitle
                            .replace(/ /g, '-')
                            .toLowerCase()}`"
                          style="color: #4d9de0"
                        >
                          {{ sectionTitle }}
                        </b-link>
                      </b-card-text>
                    </div>
                  </b-card>
                </b-col>

                <!-- MSQ col -->
                <b-col
                  cols="7"
                  style="
                    height: calc(100vh - 265px);
                    overflow-y: auto;
                    text-align: left;
                  "
                >
                  <b-card
                    :header="taskData.task_title"
                    no-body
                    class="width-class ddq-onboarding-view__card-spacing"
                  >
                    <b-list-group flush>
                      <QuestionnaireTemplate
                        v-for="(
                          section, sectionKey, sectionIndex
                        ) in sectionData"
                        :key="`sectionFormElements-${sectionIndex}`"
                        :section-fields="section.fields"
                        :section-title="sectionKey"
                        :section-index="sectionIndex"
                        :task-type="parseInt(taskData.task_type)"
                        :form-default-language="formDefaultLanguage"
                      />
                    </b-list-group>
                  </b-card>
                </b-col>

                <!-- Details col -->
                <b-col
                  style="
                    height: calc(100vh - 265px);
                    overflow-y: auto;
                    text-align: left;
                  "
                >
                  <b-row class="card-container">
                    <b-card header="Details" no-body class="width-class">
                      <div
                        style="font-size: 14px !important"
                        class="panel-body"
                      >
                        <b-card-text
                          >Third Party Name:
                          {{ taskData.comp_name_eng }}</b-card-text
                        >
                        <b-card-text>Entity Type: Company</b-card-text>
                        <b-card-text
                          >Country:
                          {{
                            taskData.country ? taskData.country : "N/A"
                          }}</b-card-text
                        >
                        <b-card-text
                          >Contact Name:
                          {{ taskData.ibf_member.name }}</b-card-text
                        >
                        <b-card-text
                          >Contact Email:
                          {{ taskData.ibf_member.email }}</b-card-text
                        >
                        <b-card-text
                          >Last Update: {{ getLastUpdateDateTime }}</b-card-text
                        >
                        <b-card-text>Last Login Date: N/A</b-card-text>
                      </div>
                    </b-card>
                  </b-row>
                  <b-row class="card-container">
                    <b-card header="Activity" no-body class="width-class">
                      <div
                        style="font-size: 14px !important"
                        class="panel-body"
                      >
                        <b-card-text
                          >Requested On:
                          {{ getRequestedOnDateTime }}</b-card-text
                        >
                        <b-card-text
                          >Submitted On:
                          {{ getSubmittedOnDateTime }}</b-card-text
                        >
                      </div>
                    </b-card>
                  </b-row>
                  <b-row class="card-container">
                    <b-card
                      header="Documents"
                      no-body
                      class="width-class ddq-onboarding-view__card-spacing"
                    >
                      <div
                        class="panel-body"
                        v-for="(
                          onboardingFile, onboardingFileIndex
                        ) in taskData.onboarding_files"
                        :key="onboardingFileIndex"
                      >
                        <span>
                          <a
                            target="_blank"
                            :href="onboardingFile?.url || ''"
                            >{{
                              onboardingFile.filename
                                ? onboardingFile.filename
                                : ""
                            }}</a
                          >
                        </span>
                      </div>
                    </b-card>
                  </b-row>
                </b-col>
              </b-row>
            </b-overlay>
          </div>
        </b-container>
        <div
          class="text-right footer"
          style="background-color: black; position: relative"
        >
          <div class="div-padding">
            <b-button class="btn-style div-margin" @click="downloadTask"
              >Download PDF</b-button
            >
          </div>
        </div>
      </template>
      <InputErrorMessage v-else :error="errorMessage" />
    </div>
  </div>
</template>

<script>
import { HTTP, makeAuthorizationHeader, makeDateToMatchFormat } from "@/utils";
import { config } from "@/configs";
import { eb360DateFormat } from "@/constants";
import QuestionnaireTemplate from "@/components/eb2/ddqOnboarding/questionnaireTemplate";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";

export default {
  name: "DDQOnboardingView",
  components: {
    QuestionnaireTemplate,
    InputErrorMessage
  },
  data() {
    return {
      sectionData: {},
      sectionTitles: [],
      taskData: null,
      isLoadingFormData: false,
      taskId: null,
      formDefaultLanguage: "",
      errorMessage: "",
      elementNumber: 0,
      eb360DateFormat
    };
  },
  mounted() {
    this.taskId = parseInt(this.$route.params.taskId);
    this.getTaskDetail();
  },
  computed: {
    getCompletedAtDate() {
      return this.formatDate(
        this.taskData?.created_at,
        eb360DateFormat.WORDED_LONG_FORM_DATE
      );
    },
    getLastUpdateDateTime() {
      return this.formatDate(this.taskData?.updated_at);
    },
    getRequestedOnDateTime() {
      return this.formatDate(this.taskData?.onboarding_stage_tasks_created_at);
    },
    getSubmittedOnDateTime() {
      return this.formatDate(this.taskData?.created_at);
    }
  },
  methods: {
    formatDate(
      stringifiedDate,
      dateFormat = eb360DateFormat.WORDED_LONG_FORM_DATE_WITH_TIME
    ) {
      return stringifiedDate
        ? makeDateToMatchFormat({ stringifiedDate, dateFormat })
        : "-";
    },
    async getTaskDetail() {
      this.isLoadingFormData = true;
      this.errorMessage = "";
      try {
        let result = await HTTP(
          "get",
          `v1/task/detail/${this.taskId}`,
          null,
          makeAuthorizationHeader()
        );
        if (result && result.status === 200) {
          await this.setTaskData(result);
        }
      } catch ({ status }) {
        const description = status
          ? `There is an error with status ${status}. `
          : "";
        this.errorMessage = `Failed to retrieve data. ${description}Please contact support.`;
      } finally {
        this.isLoadingFormData = false;
      }
    },
    mapFormSectionElements(formData) {
      return formData?.user_data?.reduce((mappedSections, currentElement) => {
        const currentElementSectionTitle =
          currentElement.sectionTitle[this.formDefaultLanguage];

        if (mappedSections[currentElementSectionTitle]) {
          mappedSections[currentElementSectionTitle].fields.push(
            currentElement
          );
        } else {
          mappedSections[currentElementSectionTitle] = {
            fields: [currentElement]
          };
        }

        return mappedSections;
      }, {});
    },
    filterHiddenSections(allSections) {
      return Object.keys(allSections).reduce(
        (filteredSections, currentSectionTitle) => {
          const unhiddenElements = allSections[
            currentSectionTitle
          ].fields.filter((element) => element.isHiddenElement === 0);

          if (unhiddenElements.length) {
            filteredSections[currentSectionTitle] = {
              fields: unhiddenElements
            };
          }

          return filteredSections;
        },
        {}
      );
    },
    async setTaskData({ data = {} }) {
      this.setFormLanguage(data.task?.language);
      const formData = JSON.parse(data.task?.form_data);
      const formSections = this.mapFormSectionElements(formData);

      this.sectionData = this.filterHiddenSections(formSections);
      this.taskData = data.task;
      this.sectionTitles = data.sections;
    },
    setFormLanguage(userDefault) {
      if (this.formDefaultLanguage === "") {
        this.formDefaultLanguage = userDefault;
      }
      if (this.formDefaultLanguage === "chinese") {
        this.formDefaultLanguage = "chinese simplified";
      }
    },
    downloadTask() {
      window.open(
        `${config.API_URL}v1/mytpp/download/questionnaire/${this.taskId}`
      );
    }
  }
};
</script>

<style scoped lang="scss">
.ddq-onboarding-view {
  &__completed-at {
    margin-right: 4px;
  }

  &__card-spacing {
    margin-bottom: 32px;
  }
}
</style>

<style>
.main-container {
  margin-left: 5px;
}
.width-class {
  width: 100%;
}

.panel-body {
  font-size: 70%;
  font-weight: 600;
  padding: 15px;
}
.card-container {
  padding: 10px;
}
.col-container {
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - 135px);
  /* margin: 10px; */
}
.div-padding {
  padding-top: 5px;
  padding-bottom: 5px;
}
.div-margin {
  margin-right: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.btn-style {
  color: #fff;
  background-color: #17c3dd;
  border-color: #17a2b8;
}
</style>
