import { actionsService } from "@/services";
import { actionName, actionKeysApi, mutationName } from "@/constants";

export const actions = {
  async fetchActions(
    { commit, rootState },
    { isLoadingActionsSuppressed = false, ...payload } = {}
  ) {
    try {
      if (!isLoadingActionsSuppressed) {
        commit(mutationName.ACTIONS.SET_IS_LOADING_ACTIONS, true, {
          root: true
        });
      }
      const { data = {} } = await actionsService.fetchActions(
        rootState.company.companyId,
        {
          excluded_fields: [actionKeysApi.FUNCTION_BODY],
          with_options: true,
          ...payload
        }
      );
      commit(mutationName.ACTIONS.SET_ACTIONS, data, { root: true });
      return data;
    } catch (error) {
      commit(mutationName.ACTIONS.SET_ACTIONS, [], { root: true });
      throw error;
    } finally {
      commit(mutationName.ACTIONS.SET_IS_LOADING_ACTIONS, false, {
        root: true
      });
    }
  },
  async fetchAction({ commit, rootState }, id) {
    try {
      commit(mutationName.ACTIONS.SET_IS_LOADING_ACTION, true, {
        root: true
      });
      const { data = {} } = await actionsService.fetchAction(
        rootState.company.companyId,
        id
      );
      commit(mutationName.ACTIONS.SET_ACTION, data, { root: true });
      return data;
    } catch (error) {
      commit(mutationName.ACTIONS.SET_ACTION, {}, { root: true });
      throw error;
    } finally {
      commit(mutationName.ACTIONS.SET_IS_LOADING_ACTION, false, {
        root: true
      });
    }
  },
  async saveAction({ rootState, dispatch }, payload) {
    await actionsService.saveAction({
      companyId: rootState.company.companyId,
      expectedData: rootState.expectedData.expectedData,
      ...payload
    });
    await dispatch(actionName.ACTIONS.FETCH_ACTIONS, undefined, { root: true });
  },
  async amendAction({ rootState, dispatch }, { action, entities, actionId }) {
    await actionsService.amendAction({
      companyId: rootState.company.companyId,
      expectedData: rootState.expectedData.expectedData,
      action,
      entities,
      actionId
    });
    await dispatch(actionName.ACTIONS.FETCH_ACTIONS, undefined, { root: true });
  },
  async deleteAction(
    { rootState, dispatch },
    { actionId, isLoadingActionsSuppressed = false } = {}
  ) {
    await actionsService.deleteAction(rootState.company.companyId, actionId);
    await dispatch(
      actionName.ACTIONS.FETCH_ACTIONS,
      { isLoadingActionsSuppressed },
      { root: true }
    );
  },
  async cloneAction(
    { rootState, dispatch },
    { actionId, isLoadingActionsSuppressed = false } = {}
  ) {
    await actionsService.cloneAction(rootState.company.companyId, actionId);
    await dispatch(
      actionName.ACTIONS.FETCH_ACTIONS,
      { isLoadingActionsSuppressed },
      { root: true }
    );
  }
};
