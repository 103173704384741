import {
  emptyOption,
  actionKeys,
  actionStepType,
  variableScope,
  variableAssignmentType,
  stepAccordionOptions,
  variableType
} from "@/constants";
import {
  makeBracketDTO,
  makeFlatSetStepDTO
} from "@/services/actions/dto/setStepDTO/setStep.dto";
import { cloneDeep } from "lodash";
import { SetStepClass } from "@/molecules/SetStep/SetStep.class";
import { isExpectedDataEntityType } from "@/utils";

const makeAccordionTitle = ({ index = 0, title = "" } = {}) => {
  const accordionTitle = title || "Unknown step type";
  return `${index + 1}. ${accordionTitle}`;
};

const makeActionOption = ({
  text = "",
  value = "",
  type = "",
  subType = "",
  isArray = false,
  castTo = ""
} = {}) => ({
  text,
  value,
  type,
  subType,
  isArray,
  ...(castTo && { castTo })
});

const isExpectedDataSubTypeSelected = (subType = "") => subType !== "";

const makeExpectedDataBaseLists = ({
  expectedData = [],
  _makeActionOption = makeActionOption
} = {}) =>
  expectedData?.reduce(
    (acc, { attributes }) => {
      attributes?.forEach((attribute) => {
        const { data } = attribute;
        if (!data.data.isReadOnly) {
          acc.settableOptions.push({
            text: data.data.name,
            value: data.data.name
          });
        }
        acc.allOptions.push(
          _makeActionOption({
            text: data.data.name,
            value: data.data.name,
            type: data.type,
            isArray: data.data.isArray || false,
            subType: data.data.subType || data.type,
            castTo: data.data.entity
          })
        );
        acc.raw[data.data.name] = attribute;
      });
      return acc;
    },
    {
      settableOptions: [],
      allOptions: [],
      raw: {}
    }
  );

const makeFirstOptionForSelect = (isEmptyOptionRequired = true) =>
  isEmptyOptionRequired ? [{ ...emptyOption, text: "Select..." }] : [];

const makeSetStepFlatObj = (dataObj = {}) => ({
  [actionKeys.STEP_TYPE]: dataObj[actionKeys.STEP_TYPE] || actionStepType.SET,
  [actionKeys.COMMENT]: dataObj[actionKeys.COMMENT],
  [actionKeys.VARIABLE_SCOPE]:
    dataObj[actionKeys.VARIABLE_SCOPE] || variableScope.LOCAL,
  [actionKeys.VARIABLE_NAME]: dataObj[actionKeys.VARIABLE_NAME],
  [actionKeys.VARIABLE_TYPE]: dataObj[actionKeys.VARIABLE_TYPE],
  [actionKeys.VALUE]: dataObj[actionKeys.VALUE],
  [actionKeys.VALUE_DATA_TYPE]: dataObj[actionKeys.VALUE_DATA_TYPE],
  [actionKeys.VALUE_TYPE]:
    dataObj[actionKeys.VALUE_TYPE] || variableAssignmentType.LITERAL,
  [actionKeys.DISPLAY_VALUE_TYPE]: dataObj[actionKeys.DISPLAY_VALUE_TYPE]
});

const getStepAccordionOptions = (hasComment = false) => {
  const clonedOptions = cloneDeep(stepAccordionOptions);

  if (hasComment) {
    clonedOptions.shift();
  }

  return clonedOptions;
};

const makeListOfStrings = (value) => {
  if (value) {
    return Array.isArray(value) ? value : [value];
  } else {
    return value;
  }
};

const makeCalculationValueObj = (calculationList = []) =>
  calculationList?.reduce((acc, { bracket }) => {
    acc.push({
      operator: undefined,
      bracket: bracket.map(() => ({
        operator: undefined,
        value: undefined
      }))
    });
    return acc;
  }, []);

const addBracket = ({
  index,
  calculationList,
  _makeBracketDTO = makeBracketDTO
} = {}) => {
  calculationList.splice(index, 0, _makeBracketDTO());
};

const deleteBracket = ({ index, calculationList }) => {
  calculationList.splice(index, 1);
};

const makeFunctionSetStepErrorObj = ({
  _makeCalculationValueObj = makeCalculationValueObj,
  functionStep = {}
} = {}) => ({
  [actionKeys.VARIABLE_NAME]: undefined,
  [actionKeys.VALUE_DATA_TYPE]: undefined,
  [actionKeys.VALUE]:
    functionStep[actionKeys.VALUE_DATA_TYPE] === variableType.EXPRESSION
      ? _makeCalculationValueObj(functionStep[actionKeys.VALUE])
      : undefined,
  [actionKeys.VALUE_TYPE]: undefined
});

const makeSaveActionObject = ({
  _makeFlatSetStepDTO = makeFlatSetStepDTO,
  functionSteps = [],
  actionName = "",
  actionDescription = "",
  companyId = 0,
  expectedData = []
} = {}) => ({
  [actionKeys.NAME]: actionName,
  [actionKeys.DESCRIPTION]: actionDescription,
  [actionKeys.COMPANY_ID]: companyId,
  [actionKeys.VARIABLES]: expectedData,
  [actionKeys.FUNCTION_BODY]: functionSteps.map((functionStep) => {
    if (functionStep instanceof SetStepClass) {
      return _makeFlatSetStepDTO(functionStep);
    } else {
      return functionStep;
    }
  }),
  [actionKeys.IS_ACTIVE]: true
});

const isSetStepType = (stepType) => stepType === actionStepType.SET;

const makeExpectedDataUnparsedProxyList = ({
  expectedData = [],
  _isExpectedDataEntityType = isExpectedDataEntityType,
  _makeActionOption = makeActionOption
} = {}) =>
  expectedData.reduce((acc, expectedDataObj) => {
    if (_isExpectedDataEntityType(expectedDataObj?.expectedVariableType)) {
      acc.push(
        _makeActionOption({
          text: expectedDataObj.expectedVariableName,
          value: expectedDataObj.expectedVariableName,
          type: variableType.PROXY,
          subType: variableType.PROXY,
          isArray: false,
          castTo: expectedDataObj.expectedVariableType
        })
      );
    }
    return acc;
  }, []);

const isCountryValueDataType = (valueDataType) =>
  valueDataType === variableType.COUNTRY;

export {
  makeAccordionTitle,
  isExpectedDataSubTypeSelected,
  makeExpectedDataBaseLists,
  makeFirstOptionForSelect,
  makeSetStepFlatObj,
  getStepAccordionOptions,
  makeListOfStrings,
  makeCalculationValueObj,
  addBracket,
  deleteBracket,
  makeFunctionSetStepErrorObj,
  makeSaveActionObject,
  isSetStepType,
  makeActionOption,
  makeExpectedDataUnparsedProxyList,
  isCountryValueDataType
};
