<template id="template-radio">
  <div :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'">
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :label="label"
      :helperText="helperText"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <b-collapse
      :class="isPreview ? 'mt-2' : 'mt-5'"
      id="collapse-1"
      v-model="collapse"
    >
      <label class="float-left">
        <strong>{{ label ? label : fieldData.label[selectedLanguage] }}</strong>
      </label>
      <b-input-group
        :prepend="fieldData.min.toString()"
        :append="fieldData.max.toString()"
        class="mt-3"
      >
        <b-form-input
          :disabled="isDisabled == 1"
          type="range"
          :min="fieldData.min.toString()"
          v-model="rangeValue"
          :max="fieldData.max.toString()"
        ></b-form-input>
      </b-input-group>
      <b-form-text class="text-left">{{ rangeValue }}</b-form-text>
      <b-form-text class="text-left">{{
        helperText ? helperText : fieldData.helperText[selectedLanguage]
      }}</b-form-text>
      <!-- advance settings tab -->
      <b-modal
        cancel-title="Close"
        ok-title="save"
        @ok="onSaveData"
        no-close-on-backdrop
        @hidden="onUpdateSettings"
        size="lg"
        v-model="showSettings"
        title="Range Advanced Settings"
      >
        <AdvanceRangeSettings
          v-if="showSettings"
          :fieldData="fieldData"
          v-on:toggle-field="showSettings = !showSettings"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import AdvanceRangeSettings from "../advanceRangeSettingsComponent";
import { mapGetters, mapMutations, mapState } from "vuex";
import MenuButtons from "../menuButtons";
import { EventBus } from "@/event-bus.js";

export default {
  name: "RangeGroup",
  components: {
    AdvanceRangeSettings,
    MenuButtons
  },
  data() {
    return {
      condition: {},
      showSettings: false,
      collapse: true,
      label: undefined,
      helperText: undefined,
      rangeValue: 50
    };
  },
  validations: {
    name: {
      required
    }
  },
  mounted() {},
  computed: {
    ...mapGetters(["form1", "selectedLanguage"]),
    ...mapState(["toggleAllCoppalse"])
  },
  watch: {
    toggleAllCoppalse(value) {
      this.collapse = value;
    }
  },
  methods: {
    ...mapMutations(["TOGGLE_LOADER"]),

    onUpdateSettings() {
      this.TOGGLE_LOADER(50);
    },
    onSaveData() {
      EventBus.emit("onSaveSettings", "saving settings");
    }
  },
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
