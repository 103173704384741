import {
  getSelectedOption,
  isRequired,
  getAllSelectedOptionValues
} from "@/utils";
import { cloneDeep } from "lodash";

export default {
  methods: {
    validateTriggerStepActionName(triggerStep) {
      const selectedValueOption = triggerStep.getSelectedActionOption();
      if (
        !isRequired(selectedValueOption) ||
        !isRequired(selectedValueOption.value)
      ) {
        triggerStep.setActionNameErrorMessage("required");
        return true;
      } else {
        triggerStep.setActionNameErrorMessage("");
        return false;
      }
    },
    validateTriggerStepDynamicComponentList(triggerStep) {
      const validateDynamicComponentList = [];
      triggerStep.additionalComponentsList.forEach(
        ({ componentOptions }, index) => {
          if (componentOptions.options) {
            const selectedValues = getAllSelectedOptionValues(
              componentOptions.options
            );
            if (isRequired(selectedValues)) {
              triggerStep.additionalComponentsList[
                index
              ].componentOptions.error = "";
              validateDynamicComponentList.push(false);
            } else {
              triggerStep.additionalComponentsList[
                index
              ].componentOptions.error = "required";
              validateDynamicComponentList.push(true);
            }
          }
        }
      );
      return validateDynamicComponentList.includes(true);
    },
    validateTriggerExpectedData(triggerStep, index) {
      let isInvalid = false;
      const expectedDataFieldClone = cloneDeep(
        triggerStep.expectDataList[index]
      );
      const selectedValueOption = getSelectedOption(
        expectedDataFieldClone?.options
      );

      if (
        !isRequired(selectedValueOption) ||
        !isRequired(selectedValueOption.value)
      ) {
        expectedDataFieldClone.errorMessage = "required";
        isInvalid = true;
      } else {
        expectedDataFieldClone.errorMessage = "";
      }

      triggerStep.setExpectedDataOptionByIndex(expectedDataFieldClone, index);

      return isInvalid;
    },
    validateTriggerExpectedDataList(triggerStep) {
      const isFieldInvalid = [];

      for (let index = 0; index < triggerStep.expectDataList.length; index++) {
        isFieldInvalid.push(
          this.validateTriggerExpectedData(triggerStep, index)
        );
      }

      return isFieldInvalid.includes(true);
    },
    validateTriggerStep(triggerStep) {
      const isTriggerActionNameInvalid =
        this.validateTriggerStepActionName(triggerStep);
      const isTriggerExpectDataListInvalid =
        this.validateTriggerExpectedDataList(triggerStep);
      const isTriggerDynamicComponentListInvalid =
        this.validateTriggerStepDynamicComponentList(triggerStep);

      return (
        isTriggerActionNameInvalid ||
        isTriggerExpectDataListInvalid ||
        isTriggerDynamicComponentListInvalid
      );
    },
    validateTriggerStepList() {
      const isTriggerStepInvalid = [];

      for (const triggerStep of this.triggerStepList) {
        isTriggerStepInvalid.push(this.validateTriggerStep(triggerStep));
      }

      return isTriggerStepInvalid.includes(true);
    },
    validateTriggerStepListActionInformation() {
      const isTriggerStepInvalid = [];

      for (const triggerStep of this.triggerStepList) {
        isTriggerStepInvalid.push(
          this.validateTriggerStepActionInformation(triggerStep)
        );
      }

      return isTriggerStepInvalid.includes(true);
    },
    validateTriggerStepActionInformation(triggerStep) {
      const selectedValueOption = triggerStep.getSelectedActionOption();

      if (this.expectedDataActionsSynchronization[selectedValueOption.value]) {
        triggerStep.setActionInformation(
          "The Action Expected data has changed, please save this configuration to align this and the action config"
        );
        return true;
      } else {
        triggerStep.setActionInformation("");
        return false;
      }
    }
  }
};
