<!-- eslint-disable vue/require-v-for-key -->
<!-- eslint-disable vue/valid-v-for -->
<template>
  <table v-bind="$attrs" class="sticky-table" data-test-id="sticky-table">
    <caption
      v-if="caption"
      class="sticky-table__caption"
      data-test-id="sticky-table__caption"
    >
      {{
        caption
      }}
    </caption>
    <thead class="sticky-table__head">
      <TableHeader
        v-for="(
          {
            text,
            value,
            sortable,
            sortOrder,
            componentOptions,
            component,
            styles
          },
          index
        ) in tableHeaders"
        data-test-id="sticky-table__header"
        :key="makeStringWithSeparatorWrapper(value)"
        :table-header="text"
        :sortable="sortable"
        :sort-order="sortOrder"
        :header-position="index"
        :component="component"
        :component-options="componentOptions"
        :styles="styles"
        @click="onColumnHeaderClick"
      />
    </thead>
    <tbody class="sticky-table__body">
      <template v-for="(tableRow, tableRowLevel) in tableRows">
        <TableRow
          class="sticky-table__row"
          data-test-id="sticky-table__row"
          :table-row="tableRow"
          :table-headers="tableHeaders"
          :table-row-level="tableRow.tableRowLevel"
          :styles="tableRow.styles"
          @click="onTableRow($event, tableRowLevel)"
        />
        <FullWidthTableRow
          v-if="isAccordionVisible(tableRow)"
          :colspan="colspan"
          v-bind="tableRow.accordionOptions"
        />
      </template>
    </tbody>
  </table>
</template>

<script>
import TableHeader from "@/molecules/TableHeader/TableHeader";
import TableRow from "@/molecules/TableRow/TableRow";
import FullWidthTableRow from "@/molecules/FullWidthTableRow/FullWidthTableRow";
import { makeStringWithSeparator } from "@/utils";
import { tableOptions } from "@/constants";

export default {
  name: "StickyTable",
  components: {
    TableRow,
    TableHeader,
    FullWidthTableRow
  },
  props: {
    tableHeaders: {
      type: Array,
      default: () => []
    },
    tableRows: {
      type: Array,
      default: () => []
    },
    caption: {
      type: String,
      default: ""
    }
  },
  computed: {
    colspan() {
      return this.tableHeaders?.length;
    }
  },
  methods: {
    makeStringWithSeparator,
    makeStringWithSeparatorWrapper(value) {
      return `sticky-table-header-row-${this.makeStringWithSeparator({
        value,
        separator: "-"
      })}`;
    },
    onColumnHeaderClick(columnPosition, columnSortOrder) {
      this.$emit("table-header-click", columnPosition, columnSortOrder);
    },
    onTableRow(value, tableRowLevel) {
      this.$emit("table-row-click", value, tableRowLevel);
    },
    isAccordionVisible(tableRow) {
      return !!tableRow?.accordionOptions?.[tableOptions.IS_ACCORDION_VISIBLE];
    }
  }
};
</script>

<style lang="scss" scoped>
.sticky-table {
  width: 100%;
  border: 1px solid $grey;

  &__row {
    &:hover {
      background-color: $portal-blue-light;
    }
  }

  &__caption {
    @include body-text-bold;

    caption-side: top;
    padding: 5px;
    background-color: $ghost-white;
    color: $black;

    & + .sticky-table__head {
      border-top: 1px;
    }
  }
}
</style>
