<template>
  <section>
    <BaseLoader v-if="isLoading" />
    <template v-else>
      <ErrorPageTemplate v-if="isErrorPresent" v-bind="errorPageOptions" />
      <router-view v-else />
    </template>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { actionName, mutationName, actionKeysApi } from "@/constants";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import ErrorPageTemplate from "@/templates/ErrorPageTemplate/ErrorPageTemplate";

export default {
  name: "TaskWrapperTemplate",
  data() {
    return {
      errorMessages: []
    };
  },
  components: {
    BaseLoader,
    ErrorPageTemplate
  },
  computed: {
    ...mapState({
      platformRequestSupportLink: (state) =>
        state.platformSettings.platformRequestSupportLink,
      isLoadingRoles: (state) => state.roles.isLoadingRoles,
      isLoadingExpectedData: (state) =>
        state.expectedData.isLoadingExpectedData,
      isLoadingActions: (state) => state.actions.isLoadingActions,
      isLoadingTaskTemplates: (state) =>
        state.taskTemplates.isLoadingTaskTemplates
    }),
    isLoading() {
      return (
        this.isLoadingRoles ||
        this.isLoadingExpectedData ||
        this.isLoadingActions ||
        this.isLoadingTaskTemplates
      );
    },
    isErrorPresent() {
      return !!this.errorMessages.length;
    },
    errorPageOptions() {
      return {
        headingOptions: {
          text: "Incomplete data"
        },
        contentOptions: {
          text: this.errorMessages
        },
        CTAOptions: {
          value: "Please contact support"
        },
        linkComponent: "a",
        linkOptions: {
          href: this.platformRequestSupportLink,
          target: "_blank"
        }
      };
    }
  },
  methods: {
    ...mapActions({
      fetchExpectedData: actionName.EXPECTED_DATA.FETCH_EXPECTED_DATA,
      fetchRoles: actionName.ROLES.FETCH_ROLES,
      fetchActions: actionName.ACTIONS.FETCH_ACTIONS,
      fetchTaskTemplates: actionName.TASK_TEMPLATES.FETCH_TASK_TEMPLATES
    }),
    ...mapMutations({
      resetRoles: mutationName.ROLES.RESET_ROLES,
      resetExpectedData: mutationName.EXPECTED_DATA.RESET_EXPECTED_DATA,
      resetActions: mutationName.ACTIONS.RESET_ACTIONS,
      resetTasks: mutationName.TASKS.RESET_TASKS,
      resetTaskTemplates: mutationName.TASK_TEMPLATES.RESET_TASK_TEMPLATES
    }),
    async fetchTaskTemplatesWrapper() {
      try {
        await this.fetchTaskTemplates();
      } catch {
        this.errorMessages.push(
          "Failed to retrieve the available task templates."
        );
      }
    },
    async fetchExpectedDataWrapper() {
      try {
        await this.fetchExpectedData();
      } catch {
        this.errorMessages.push(
          "Failed to retrieve the available expected data."
        );
      }
    },
    async fetchRolesWrapper() {
      try {
        await this.fetchRoles();
      } catch {
        this.errorMessages.push("Failed to retrieve the roles.");
      }
    },
    async fetchActionsWrapper() {
      try {
        await this.fetchActions({
          excluded_fields: [actionKeysApi.FUNCTION_BODY],
          with_options: true
        });
      } catch {
        this.errorMessages.push("Failed to retrieve the actions.");
      }
    }
  },
  async created() {
    await Promise.allSettled([
      this.fetchExpectedDataWrapper(),
      this.fetchActionsWrapper(),
      this.fetchRolesWrapper(),
      this.fetchTaskTemplatesWrapper()
    ]);
  },
  beforeRouteLeave(_, __, next) {
    this.resetExpectedData();
    this.resetRoles();
    this.resetActions();
    this.resetTasks();
    this.resetTaskTemplates();
    next();
  }
};
</script>
