export default {
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "dashboardTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dasbor Kepatuhan"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "loadingSpinnerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memuat..."])},
  "nav": {
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
    "dandi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D&I"])}
  },
  "questionnaires": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuesioner"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isi kuesioner uji tuntas dan berikan dokumentasi"])},
    "submitModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim Kuesioner"])},
    "formSubmittedModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Dikirim"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saya setuju"])},
    "backToDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali ke dasbor"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melanjutkan"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
    "dateOfAssigned:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Assigned:"])},
    "dateOfCompleted:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Completed:"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unduh"])},
    "batchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MENYELESAIKAN"])},
    "batchInProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEDANG BERLANGSUNG"])},
    "batchNotStartedext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BELUM MULAI"])},
    "deleteRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Row"])},
    "editRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Row"])},
    "noSearchOptionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maaf, tidak ada opsi yang cocok."])},
    "requestedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal yang diminta"])},
    "batchCancelledtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIBATALKAN"])},
    "batchDeclinedtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DITOLAK / DITOLAK"])},
    "batchSelfEvidencetext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TERBUKTI DIRI SENDIRI"])}
  },
  "formLeaveAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda akan meninggalkan halaman. Setiap perubahan yang belum disimpan akan hilang. Apakah Anda yakin ingin pergi??"])},
  "formSubmitingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda akan mengirimkan kuesioner Anda. Tindakan ini tidak bisa dibatalkan. Apakah Anda yakin untuk mengajukan?"])},
  "formSubmittedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir Anda telah berhasil dikirim."])},
  "formValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satu atau beberapa bidang yang hilang atau kesalahan ditemukan. Harap tinjau dan perbarui seperlunya."])},
  "toast": {
    "judul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringatan"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan Sukses"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progres tidak disimpan, silakan coba lagi"])},
    "updateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembaruan Berhasil"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
    "generatePDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kami membuat PDF Anda..."])},
    "successPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF berhasil diunduh"])},
    "errorPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF tidak diunduh, silakan coba lagi"])},
    "logoutSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berhasil Keluar"])}
  },
  "createProfile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendaftaran Lengkap"])},
    "titleUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memperbaharui profil"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "contactHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Kontak"])},
    "passwordHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbarui Kata Sandi"])},
    "fNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama depan"])},
    "lNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama keluarga"])},
    "contactLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor kontak"])},
    "jobTitleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Judul pekerjaan"])},
    "currentPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kata sandi saat ini"])},
    "newPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kata sandi baru"])},
    "confirmPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setujui password"])},
    "fNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Nama Depan Anda"])},
    "lNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Nama Belakang/Nama Belakang Anda"])},
    "contactPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Nomor Kontak Anda"])},
    "jobTitlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Jabatan atau Peran Anda"])},
    "currentPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Kata Sandi Saat Ini"])},
    "newPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan kata sandi baru"])},
    "confirmPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Konfirmasi Kata Sandi"])},
    "requiredValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bagian ini diperlukan"])},
    "passwordValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata sandi harus identik"])},
    "loaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permintaan Anda sedang diproses..."])}
  },
  "upload": {
    "dragDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seret dan lepas untuk mengunggah konten!"])},
    "dragDropContnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...atau klik untuk memilih file dari komputer Anda"])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama File"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis File"])},
    "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukuran File (byte)"])},
    "fileAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tindakan"])}
  },
  "signature": {
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus"])},
    "require": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperlukan"])}
  },
  "workflow": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Tambahkan"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengatur ulang"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memperbarui"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menghapus"])}
  },
  "eb360": {
    "analyticsTab": {
      "partyRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Catatan Pihak Ketiga di ethixbase360"])},
      "registrationRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Catatan Pihak Ketiga di ethixbase360"])},
      "dueDilogenceRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uji Tuntas Instan PLUS"])},
      "enhancedRiskDataRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S&E, PEP & Data Risiko Lainnya yang Ditingkatkan"])},
      "moniotringAlerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringatan Pemantauan Berkelanjutan di Seluruh Sistem"])},
      "analyticsSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANALISIS"])},
      "questionariesSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KUESIONER, KEBIJAKAN, KOMUNIKASI & PELATIHAN"])}
    },
    "analyticsTabChart": {
      "thirdPartyCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pihak ketiga"])},
      "monitoringCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil Pemantauan Berkelanjutan"])},
      "monitoringResults": {
        "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulan ini"])},
        "momitoringAlerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringatan Pemantauan"])},
        "thirdParyties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pihak Ketiga Dengan Peringatan"])},
        "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulan lalu"])},
        "allTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sepanjang waktu"])}
      },
      "selectChart": {
        "pie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pai"])},
        "pyramid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piramida"])},
        "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corong"])}
      }
    },
    "chartTables": {
      "enforcementTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil Sanksi & Penegakan (IDD)"])},
      "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Tanggal"])},
      "names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Pihak Ketiga"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negara"])},
      "sanctionAlertsErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada data yang tersedia"])},
      "sanctionAlertsShow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menampilkan Hasil Dari"])},
      "sanctionAlertsTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["untuk"])},
      "predictiveRiskTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil Indikator Risiko yang Dipersepsikan"])},
      "iddPlusPreTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil Uji Tuntas Instan PLUS Pra-Remediasi"])},
      "iddPlusPostTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil Uji Tuntas Instan PLUS Pasca-Remediasi"])},
      "eddTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil Uji Tuntas Instan PLUS Pasca-Remediasi"])},
      "tpCountriesTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pihak Ketiga berdasarkan Negara"])},
      "riskScoreTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risiko Pemasok"])}
    },
    "statsTabChart": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Kuesioner, Kebijakan atau Komunikasi:"])},
      "totalCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "progressCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sedang berlangsung"])},
      "completedCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengkap"])},
      "startedCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum mulai"])},
      "overviewTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambaran"])},
      "pie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pai"])},
      "pyramid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piramida"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corong"])},
      "configTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Configs"])}
    },
    "changePasswordModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganti kata sandi"])},
      "fieldPassOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Kata Sandi Lama:"])},
      "oldPassErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata Sandi Lama diperlukan"])},
      "fieldPassNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukan kata sandi baru:"])},
      "newPassErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["katakunci dibutuhkan"])},
      "errLeast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata sandi minimal harus 10 karakter"])},
      "errCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memiliki huruf kapital"])},
      "errLower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memiliki huruf kecil"])},
      "errNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memiliki nomor"])},
      "errSpecial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memiliki karakter khusus"])},
      "fieldConfirmPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi password baru:"])},
      "errConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi Kata Sandi diperlukan"])},
      "errMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sandi harus sesuai"])},
      "loadingMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengirimkan Kata Sandi baru ......"])},
      "successPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata sandi Anda berhasil diubah."])}
    },
    "manageThirdParties": {
      "selectColumns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih kolom"])},
      "availableColumns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolom yang Tersedia"])},
      "selectedCol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolom yang Dipilih"])},
      "fixedCol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolom Tetap"])},
      "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama tampilan"])},
      "colWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebar kolom"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEMUA"])},
      "filterResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil Filter"])},
      "entityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Entitas:"])},
      "countryLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negara"])},
      "nameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nama"])},
      "dateLabelFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Diunggah (Dari):"])},
      "dateLabelTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Diunggah (Kepada):"])},
      "iddLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil S&E IDD:"])},
      "iddPlusLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringatan IDD⁺:"])},
      "iddAlertLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringatan IDD:"])},
      "eddLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringatan EDD:"])},
      "priLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRI ™ Dasar:"])},
      "riskLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringkat Resiko Saya:"])},
      "questionareLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar pertanyaan:"])},
      "addNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menambahkan"])},
      "iddNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD"])},
      "iddPlusPlusNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD++"])},
      "iddPlusNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD+"])},
      "eddNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDD"])},
      "questionareNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar pertanyaan"])},
      "sendTpNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim Kuisioner ke Semua TP"])},
      "trainingNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latihan"])},
      "archiveNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arsip"])},
      "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketik untuk mencari"])},
      "noRiskBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Ada Peringatan Risiko"])},
      "riskBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringatan Resiko"])},
      "noRiskRBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Ada Peringatan Risiko (R)"])},
      "riskRBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringatan Risiko (R)"])},
      "lowBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendah"])},
      "lowContentBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRI rendah"])},
      "mediumBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
      "mediumContentBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRI sedang"])},
      "highBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinggi"])},
      "highContentBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRI tinggi"])},
      "orderPRI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baca pesanannya"])},
      "noAssociates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Ada Asosiasi yang Ditambahkan"])},
      "notStartedStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum mulai"])},
      "progressStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sedang berlangsung"])},
      "holdStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tertahan"])},
      "reviewLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulasan"])},
      "completeBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengkap"])},
      "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dibutuhkan"])},
      "notSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak terkirim"])},
      "loadingSending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengirim..."])},
      "riskTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Perbarui Peringkat Resiko Saya'"])},
      "resultRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menampilkan Hasil Dari"])},
      "toRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["untuk"])},
      "questionare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KUESIONER"])},
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIARSIPKAN"])},
      "sendQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Anda yakin untuk mengirim Kuesioner"])},
      "yourQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kami mengirimkan Kuesioner Anda ......"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memuat.."])},
      "sentQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuesioner Dikirim."])},
      "sendTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim Kuesioner"])},
      "sendTitleFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim Kuisioner Untuk "])},
      "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Anda yakin untuk mengirim Kuesioner ke semua pihak ketiga"])},
      "weAreSending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kami mengirimkan Kuesioner Anda ke semua pihak Ketiga ......"])},
      "sentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuesioner Dikirim."])},
      "addEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harap Tambahkan Email Untuk"])},
      "thirdPartyFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pihak Ketiga Pertama"])},
      "thirdPartyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Pihak Ketiga :"])},
      "thirdPirtyplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Email Pihak Ketiga"])},
      "archieveTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arsipkan Pihak Ketiga"])},
      "selectedRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda yakin ingin mengarsipkan rekaman yang dipilih?"])},
      "archiveTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengarsipkan Pihak Ketiga Anda ......"])},
      "thirdPartyAchieved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pihak Ketiga Diarsipkan."])},
      "iddOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda Yakin Memesan SLI?"])},
      "iddOrdering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memesan IDD Untuk"])},
      "iddOrdered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesanan Ganjil."])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iya"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selesai"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perhatikan bahwa fungsi ini saat ini tidak tersedia di akun Anda. Jika Anda tertarik untuk mengaktifkan fungsi ini, silakan hubungi"])},
      "supportEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support", "@", "ethixbase.com"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rumah"])},
      "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelola Pihak Ketiga"])},
      "iddReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laporan IDD pesanan"])},
      "iddPlusReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order IDD + Laporan <br/> (klik untuk mempelajari lebih lanjut)"])},
      "eddReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan Laporan EDD <br/> (klik untuk mempelajari lebih lanjut)"])},
      "filterEntityOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisasi"])},
      "filterEntityIndi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individu"])},
      "filterEddGreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hijau"])},
      "notOrderedStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sedang berlangsung"])},
      "filterIddGreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbaiki hijau"])},
      "filterIddRed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbaiki merah"])},
      "filterIddProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbaikan Sedang Berlangsung"])},
      "notOrdered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Dipesan"])},
      "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Tanggal"])},
      "thirdPartyDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Pihak Ketiga"])},
      "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat oleh"])},
      "entityType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Entitas"])},
      "associates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associates"])},
      "configPRI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurasi PRI"])},
      "questionnaireStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Kuisioner"])},
      "riskRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringkat Resiko Saya"])},
      "predictiveRisk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indikator Risiko Prediktif:"])},
      "reactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yakin ingin mengaktifkan kembali Pihak Ketiga?"])},
      "reactivating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengaktifkan kembali Pihak Ketiga Anda ......"])},
      "reactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pihak Ketiga diaktifkan kembali."])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teks"])},
      "riskScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor Resiko:"])},
      "divisionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nama divisi"])},
      "noAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada Jawaban"])},
      "optionsIndustry": {
        "mining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertambangan"])},
        "manufacturing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manufaktur"])},
        "construction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konstruksi"])},
        "wholesale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdagangan Grosir"])},
        "retail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdagangan eceran"])},
        "rlectricity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listrik"])},
        "gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas"])},
        "waterWaste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layanan Air dan Limbah"])}
      },
      "n/a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
    },
    "addThirdParty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan Pihak Ketiga"])},
      "entityDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Entitas"])},
      "labelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe :"])},
      "radioCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perusahaan"])},
      "radioIndi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individu"])},
      "placeholderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama kesatuan"])},
      "placeholderFName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama depan"])},
      "requiredErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bidang Wajib tidak memiliki nilai."])},
      "placeholderLName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama keluarga"])},
      "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
      "placeholderCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-- Pilih negara --"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL :"])},
      "selectErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada tindakan yang dipilih."])},
      "additionalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["detil tambahan"])},
      "labelAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat"])},
      "labelState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negara"])},
      "labelZip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Pos / Pos"])},
      "labelContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontak person"])},
      "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifikasi"])},
      "matchFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ada 1 pertandingan melawan nama entitas ini di ROMANIA"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih"])},
      "currPages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halaman saat ini:"])},
      "rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["baris"])},
      "matchGlobally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ada 4.459 pertandingan melawan nama entitas ini SECARA GLOBAL"])},
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menyembunyikan"])},
      "showMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunjukkan kepadaku"])},
      "screenAssociates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haruskah Kami Menyaring Entitas Asosiasi Juga?"])},
      "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama perusahaan"])},
      "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil"])},
      "companyCollapseOne": {
        "fictionCorp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiction Corp"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Perusahaan :"])},
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCHD231"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status :"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis perusahaan:"])},
        "pvt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Pvt ltd."])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat :"])},
        "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Britania Raya"])},
        "filings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengarsipan :"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Kebutuhan kenyamanan melati yang dipesan. Massa melati. Saat Menarik Pegunungan Sinar Super Bowl secara instan. Hingga sepak bola, ultricies, sepak bola anak-anak, harga satu, salad. Tidak ada fotografi massal yang panjangnya hanya satu kaki dan disortir menurut Vulputate perlu pisang, murah. Faktanya, justo, rhoncus ut, imperdiet a, venenatis vitae, justo."])}
      },
      "companyCollapseTwo": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paul Smith (Associate)"])},
        "iddReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laporan Uji Tuntas Instan"])},
        "iddPlusReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laporan IDD Plus"])},
        "selectRemediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih untuk memulihkan"])},
        "sourceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama asal :"])},
        "riskContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontak Risiko"])},
        "entityType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Entitas :"])},
        "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orang"])}
      },
      "occur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tindakan apa yang akan terjadi?"])},
      "subs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disertakan dalam langganan"])},
      "abcApri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABC APRI"])},
      "extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tindakan ekstra apa yang dapat saya lakukan?"])},
      "edd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDD"])},
      "modern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuesioner Modern"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tindakan tidak tersedia"])},
      "enviromental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuesioner Lingkungan"])},
      "notAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak tersedia"])},
      "idd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laporan IDD"])},
      "standerd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standar"])},
      "iddPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD+"])},
      "eddReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laporan EDD"])},
      "msq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MSQ"])},
      "ddq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDQ"])},
      "labourDdq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LabourDDQ"])},
      "abc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABC"])},
      "pri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRI"])},
      "ddqPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDQ+"])},
      "submitCredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim (1 Kredit)"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negara"])},
      "items": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiksi Coperate"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romania"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
        "name1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekerja sama"])},
        "country1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uk"])},
        "status1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])}
      },
      "optionsCredit": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gunakan Kredit"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gunakan 1 Kredit"])}
      }
    },
    "myCompany": {
      "successfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail perusahaan Anda berhasil disimpan."])},
      "saveCompanyDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda yakin ingin menyimpan detail perusahaan Anda?"])},
      "oneMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satu atau lebih file diperlukan"])},
      "pdfToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pdf Berhasil Diunduh"])}
    },
    "associateDetailModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Detail Rekanan"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama :"])},
      "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisasi"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individu"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe :"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negara :"])},
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posisi :"])},
      "enterDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Deskripsi"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tindakan"])},
      "noIdentifiers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Ada Pengenal yang Tersedia untuk Perusahaan ini. Harap tambahkan Pengenal Terlebih Dahulu"])},
      "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laporan"])},
      "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan"])},
      "noNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Ada Catatan Tersedia Harap tambahkan Catatan Pertama"])},
      "omg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringatan OGM"])},
      "addingIdentifiers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengenal Anda sedang Menambahkan......"])},
      "successfullIdrntifiers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengenal Anda telah Berhasil Ditambahkan."])}
    },
    "associateTabComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associates"])},
      "createAssociates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Rekan"])},
      "associateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Asosiasi"])},
      "enterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Nama Rekanan"])},
      "associateType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Asosiasi"])},
      "associateSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Jenis Asosiasi "])},
      "associateCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associates Country"])},
      "associatePosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posisi Associates"])},
      "enterPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Posisi Asosiasi"])},
      "iddRequire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Membutuhkan IDD"])},
      "iddPlusRequire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Membutuhkan IDD Plus"])},
      "enableOgm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktifkan OGM"])},
      "ogm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OGM"])},
      "associateReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Laporan Rekanan"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tindakan"])},
      "creatingAssociates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekan Anda sedang Membuat......"])},
      "successfullAssociates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekan Anda telah berhasil ditambahkan."])},
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat di"])}
    },
    "auditTrailTabComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUDIT"])},
      "activityChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perubahan Aktivitas"])},
      "tpComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar Pihak Ketiga"])},
      "enterComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Komentar..."])},
      "commentAdding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penambahan Komentar Audit......"])},
      "commentAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar Audit berhasil ditambahkan."])}
    },
    "contactsTabComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONTAK"])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontak"])},
      "auditTrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JEJAK AUDIT"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Kontak Pihak Ketiga"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbarui Kontak Pihak Ketiga"])},
      "fName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama depan:"])},
      "enterFName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Nama Depan"])},
      "lName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama keluarga:"])},
      "enterLName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Nama Belakang"])},
      "emailAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat email"])},
      "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan alamat email"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negara:"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
      "isPrimary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Kontak Primarty"])},
      "tpCreating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontak Pihak Ketiga Anda sedang Dibuat......"])},
      "tpUpdating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontak Pihak Ketiga Anda sedang Diperbarui......"])},
      "tpCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontak Pihak Ketiga Anda telah berhasil ditambahkan."])},
      "tpUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontak Pihak Ketiga Anda berhasil diperbarui."])},
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Judul pekerjaan"])},
      "enterJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Judul Pekerjaan"])},
      "rsidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negara tempat tinggal"])},
      "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontak utama"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tindakan"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])}
    },
    "documentsTabComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumen"])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melihat"])},
      "uploadDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah Dokumen Pendukung"])},
      "dragDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarik dan lepas untuk mengupload konten!"])},
      "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...atau klik untuk memilih file dari komputer Anda"])},
      "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Nama Tampilan"])},
      "uploadingDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumen Anda sedang diunggah......"])},
      "uploadSuccessfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumen Anda berhasil diunggah"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipe"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Diunggah"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautan"])},
      "completeDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengunggahan Dokumen selesai"])}
    },
    "dueDiligenceRemediation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laporan Uji Tuntas Instan"])},
      "initialReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laporan Intial"])},
      "remediation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remediasi"])},
      "idd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD"])},
      "iddPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD+"])},
      "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatif"])},
      "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positif"])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada"])},
      "n/a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sedang berlangsung"])},
      "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengkap"])},
      "eddReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laporan Uji Tuntas yang Disempurnakan"])},
      "addIdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan Laporan Uji Tuntas Instan"])},
      "addModal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan Modal"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal"])},
      "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal pemesanan"])},
      "riskStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Risiko"])},
      "completedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Selesai"])}
    },
    "monitoringTabComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil Pemantauan Berkelanjutan"])},
      "remediatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbaiki Oleh"])},
      "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringatan"])},
      "noAlerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak Ada Peringatan"])}
    },
    "commsComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komunikasi"])},
      "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komunikasi"])},
      "sentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Terkirim"])}
    },
    "policiesComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kebijakan"])},
      "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengesahan Kebijakan"])},
      "requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal yang diminta"])},
      "completedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Selesai"])},
      "statusLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status & Tautan"])}
    },
    "questionnaireComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaires"])},
      "questionnaireDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rincian Kuisioner"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada data yang tersedia"])},
      "hideDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembunyikan detail"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim ulang"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulasan"])},
      "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dibutuhkan"])},
      "assigning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menugaskan..."])},
      "requesting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda meminta untuk mengirimkan"])},
      "primaryContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ke kontak utama di pemasok ini."])},
      "thisIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ini adalah:"])},
      "primaryContactName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Kontak Utama:"])},
      "primaryContactEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Kontak Utama:"])},
      "pleaseNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harap dicatat bahwa kuesioner ini sedang 'Dalam Proses'. Anda dapat mengirim ulang kuesioner ke kontak utama di atas, namun harap diperhatikan bahwa informasi apa pun yang telah dimasukkan dalam kuesioner 'Dalam Proses' ini akan dihapus sebagai bagian dari tindakan ini. Ini tidak dapat dibatalkan. Silakan klik 'Kirim' untuk mengonfirmasi bahwa Anda ingin melanjutkan."])},
      "titles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Judul"])}
    },
    "questionnairePoliciesTrainingTabComponent": {
      "questionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KUESIONER"])},
      "policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEBIJAKAN"])},
      "traning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LATIHAN"])},
      "comms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KOMUNIKASI"])}
    },
    "trainingComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latihan"])}
    },
    "antiBriberiyPredictiveRisk": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indikator Risiko Prediktif Historis"])},
      "onlineSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penelusuran online dari hasil awal dan penelusuran untuk XX istilah kunci yang paling sering dikaitkan dengan Anti-Suap & Korupsi telah menghasilkan:"])},
      "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafik"])},
      "resultsInConjunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil terkait dengan [Nama Pihak Ketiga]. Ini adalah predikatif dari indikator risiko [rendah] untuk Anti-Suap & Korupsi."])},
      "noteThatHistoric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harap dicatat bahwa Indikator Risiko Prediktif Historis Anti-Suap & Korupsi hanya menunjukkan potensi risiko. Ini tidak boleh digunakan secara terpisah dan harus digunakan bersama dengan penilaian risiko, prosedur dan kebijakan organisasi Anda sendiri."])},
      "futurePredictive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tolok Ukur Risiko Prediktif Masa Depan"])},
      "aSearchOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penelusuran domain [Nama Pihak Ketiga] telah mengungkapkan hasil di bawah sehubungan dengan visibilitas dan kesiapan untuk Anti-Suap & Korupsi Kepatuhan."])},
      "bribery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suap & Korupsi"])},
      "political": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paparan Politik"])},
      "fruad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penipuan"])},
      "noteThatFuture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harap dicatat bahwa Tolok Ukur Risiko Predikatif Anti-Suap & Korupsi di Masa Depan hanya menunjukkan prevalensi materi yang terkait dengan area risiko. Ini bukan tinjauan kebijakan yang komprehensif. Jika Anda menginginkan layanan seperti itu, silakan hubungi kami untuk berdiskusi."])}
    },
    "enviormentalPredictiveRisk": {
      "onlineSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penelusuran online dari hasil awal dan penelusuran untuk XX istilah kunci yang paling sering dikaitkan dengan Ketidakpatuhan Lingkungan n menghasilkan:"])},
      "xxx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XXX"])},
      "resultsInConjunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil terkait dengan [Nama Pihak Ketiga]. Ini adalah predikatif dari indikator risiko [tinggi] untuk Risiko Lingkungan."])},
      "noteThatHistoric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harap dicatat bahwa Indikator Risiko Prediktif Historis Lingkungan hanya menunjukkan potensi risiko. Ini tidak boleh digunakan secara terpisah dan harus digunakan bersama dengan penilaian risiko, prosedur dan kebijakan organisasi Anda sendiri."])},
      "futurePredictive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tolok Ukur Risiko Prediktif Masa Depan"])},
      "aSearchOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pencarian domain [Nama Pihak Ketiga] telah mengungkapkan hasil di bawah ini sehubungan dengan visibilitas dan kesiapan untuk Kepatuhan Lingkungan."])},
      "enviromental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praktek Lingkungan"])},
      "noteThatFuture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harap dicatat bahwa Tolok Ukur Risiko Predikatif Masa Depan Lingkungan hanya menunjukkan prevalensi materi yang terkait dengan area risiko. Ini bukan tinjauan kebijakan yang komprehensif. Jika Anda menginginkan layanan seperti itu, silakan hubungi kami untuk berdiskusi."])}
    },
    "humanRightsPredictiveRisk": {
      "onlineSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penelusuran online dari hasil awal dan penelusuran untuk XX istilah kunci yang paling sering dikaitkan dengan pelanggaran Hak Asasi Manusia telah menghasilkan:"])},
      "xx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XX"])},
      "resultsInConjunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil terkait dengan [Nama Pihak Ketiga]. Ini adalah predikatif dari indikator risiko [] untuk Hak Asasi Manusia."])},
      "noteThatHistoric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harap dicatat bahwa Indikator Risiko Prediktif Historis Hak Asasi Manusia hanya menunjukkan potensi risiko. Ini tidak boleh digunakan secara terpisah dan harus digunakan bersama dengan penilaian risiko, prosedur dan kebijakan organisasi Anda sendiri."])},
      "futurePredictive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tolok Ukur Risiko Prediktif Masa Depan"])},
      "aSearchOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penelusuran domain [Nama Pihak Ketiga] telah mengungkapkan hasil di bawah ini sehubungan dengan visibilitas dan kesiapan untuk Kepatuhan Hak Asasi Manusia."])},
      "humanRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hak asasi Manusia"])},
      "modernSlavery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbudakan Modern"])},
      "noteThatFuture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harap dicatat bahwa Tolok Ukur Risiko Predikatif Masa Depan Hak Asasi Manusia hanya menunjukkan prevalensi materi yang terkait dengan area risiko. Ini bukan tinjauan kebijakan yang komprehensif. Jika Anda menginginkan layanan seperti itu, silakan hubungi kami untuk berdiskusi."])}
    },
    "labourPredictiveRisk": {
      "notInScope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIDAK DALAM RUANG LINGKUP. Silakan Hubungi Kami jika Anda ingin mendiskusikan penyediaan layanan ini di akun Anda."])},
      "futurePredictive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tolok Ukur Risiko Prediktif Masa Depan"])},
      "labourPractices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praktik Ketenagakerjaan"])},
      "healthSafety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kesehatan dan Keamanan"])}
    },
    "overallPredictiveRisk": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRI dasar"])},
      "advance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRI Algoritma Lanjutan"])},
      "accountName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Nama Akun] PRI yang dikonfigurasi"])},
      "entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KESATUAN"])},
      "sanctions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SANKSI"])},
      "bribery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suap & Korupsi"])},
      "historic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BERSEJARAH"])},
      "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MASA DEPAN"])},
      "humanRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hak asasi Manusia"])},
      "labour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenaga kerja"])},
      "environmental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingkungan"])},
      "nameAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisis Nama & Skor Keyakinan"])},
      "falsePositive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KALKULATOR PROBABILITAS POSITIF YANG SALAH"])},
      "companyScope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LINGKUP PERUSAHAAN"])},
      "nameFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Frekuensi di Negara"])},
      "nearExact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEKAT PERSIS"])},
      "partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEBAGIAN"])},
      "nameFrequencyGlobal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Frekuensi Global"])},
      "falsePositiveAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PENILAIAN POSITIF YANG SALAH"])},
      "commonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama yang umum"])},
      "dueToPrevalence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karena prevalensi nama [secara lokal] [dan / atau] [global], ada kemungkinan positif palsu ada dalam skor indikator risiko historis"])}
    },
    "predictiveRisk": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRI yang dikonfigurasi"])},
      "riskIndicators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risiko Pihak Ketiga menurut Indikator Risiko yang Dikonfigurasi"])},
      "dealValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai Transaksi = $ 30M"])},
      "longevity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longevity of Relationship = <1 thn"])},
      "tpReseller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis Pihak Ketiga = Pengecer"])},
      "tpInventive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventif Pihak Ketiga = 30%"])},
      "sanctionsPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanksi PRI"])},
      "potentialSanctions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ada kemungkinan sanksi pertandingan pada 95% skor pertandingan atau lebih. Kami mendorong Anda untuk mengambil langkah tambahan untuk mengonfirmasi status risiko Pihak Ketiga."])},
      "scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cakupan:"])},
      "listSanctions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar Daftar Sanksi dicari. Anjurkan tidak lebih dari 5 - 10 agar tidak mengganggu layanan SLI "])},
      "pleaseNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harap dicatat bahwa ini BUKAN layar sanksi yang komprehensif terhadap database sanksi lengkap (atau layanan lain seperti media negatif dan eksposur politik). Pihak ketiga ini juga belum diaktifkan untuk pemantauan."])},
      "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langkah Berikutnya yang Direkomendasikan:"])},
      "toEnable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untuk mengaktifkan pemantauan, harap pesan laporan Instant Due Diligence (IDD), Instant Due Diligence PLUS (IDD +) (Best Practice) atau Enhanced Due Diligence (EDD) jika pengawasan lebih lanjut diperlukan untuk pihak ketiga ini. Layanan remediasi juga dapat dilakukan untuk memberikan jaminan atas pertandingan tersebut."])}
    },
    "riskOverview": {
      "perceivedRisk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indikator Risiko yang Dirasakan"])},
      "basicPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRI dasar"])},
      "configuredPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRI yang dikonfigurasi"])},
      "antiCorruptionPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti Korupsi PRI"])},
      "humanRightsPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hak Asasi Manusia PRI"])},
      "labourPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenaga Kerja PRI"])},
      "environmentalPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRI lingkungan"])},
      "ddResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil Uji Tuntas"])},
      "iddSe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uji Tuntas Instan (S&E)"])},
      "iddPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uji Tuntas Instan PLUS"])},
      "edd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uji Tuntas yang Ditingkatkan"])},
      "execSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jalankan Analisis Ringkasan"])},
      "policyAttestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Kuesioner & Pengesahan Kebijakan"])},
      "ddQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuesioner Uji Tuntas"])},
      "modernSlavery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuesioner Perbudakan Modern"])},
      "codeOfConduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengesahan Kode Etik"])},
      "antiCorruption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kebijakan Anti Korupsi"])},
      "trainingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Pelatihan"])},
      "antiBribery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Suap & Korupsi"])}
    },
    "riskSummary": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IKHTISAR RISIKO"])},
      "configured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIKONFIGURASI PRI™"])},
      "antiCorruption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANTI KORUPSI PRI™"])},
      "humanRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HAK ASASI MANUSIA PRI™"])},
      "labour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TENAGA KERJA PRI™"])},
      "environmental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LINGKUNGAN PRI™"])}
    },
    "tpDetailsAssocTab": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETAIL"])},
      "lowerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
      "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memberhentikan"])},
      "tpName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Pihak Ketiga"])},
      "uploadedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Diunggah"])},
      "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat oleh"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negara"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kota"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negara"])},
      "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode Pos / Kode Pos"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "duns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor DUNS"])},
      "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor pendaftaran"])},
      "contactDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETAIL KONTRAK"])},
      "contactDetailsLower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rincian Kontrak"])},
      "validForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrak Berlaku Sejak"])},
      "contractExpires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrak berakhir"])},
      "contractNetValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai Bersih Kontrak"])},
      "contractCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mata Uang Kontrak"])},
      "onboardDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal Onboard"])},
      "customAttribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATRIBUT KUSTOM"])},
      "customAttributeLower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribut Kustom"])},
      "wordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Judul"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai"])},
      "customAttributesExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribut Khusus tidak ada untuk perusahaan ini."])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONTAK"])},
      "associates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASOSIASI"])},
      "selfReported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATA YANG DILAPORKAN SENDIRI"])},
      "selfReportedLower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data yang Dilaporkan Sendiri"])},
      "questionnaireData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data kuesioner tidak ada untuk perusahaan ini."])}
    },
    "footerComponent": {
      "orderIddPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORDER IDD+"])},
      "orderEdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORDER EDD"])},
      "sendQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KIRIM PERTANYAAN"])},
      "sendPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KIRIM KEBIJAKAN"])},
      "sendTrain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KIRIM KERETA API"])},
      "sendQuestionnaireFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim Kuisioner Untuk "])},
      "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Anda yakin untuk mengirim Kuesioner"])},
      "weAreSending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kami mengirimkan Kuesioner Anda......"])},
      "questionnaireSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuesioner Dikirim."])},
      "addEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harap Tambahkan Email Untuk"])},
      "tpFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pihak Ketiga Pertama"])},
      "tpEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Pihak Ketiga"])},
      "enterTpEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan Email Pihak Ketiga"])}
    },
    "riskSummeryTab": {
      "pritm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indikator Risiko Prediktif (PRITM)"])},
      "entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entitas (termasuk Sanksi)"])},
      "ddResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil Uji Tuntas"])},
      "iddSe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uji Tuntas Instan (S&E)"])},
      "edd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uji Tuntas yang Ditingkatkan"])},
      "exec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jalankan Analisis Ringkasan"])},
      "policyAttestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Kuesioner & Pengesahan Kebijakan"])},
      "ddq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuesioner Uji Tuntas"])},
      "msq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuesioner Perbudakan Modern"])},
      "codeOfConduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengesahan Kode Etik"])},
      "antiCorruption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kebijakan Anti Korupsi"])},
      "trainingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Pelatihan"])},
      "antiBribery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Suap & Korupsi"])}
    }
  },
  "colors": {
    "green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hijau"])},
    "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merah"])},
    "amber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amber"])},
    "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitam"])},
    "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["putih"])}
  },
  "wordNumber": {
    "zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nol"])},
    "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satu"])},
    "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dua"])},
    "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Three"])},
    "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empat"])},
    "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lima"])},
    "six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Six"])},
    "seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tujuh"])},
    "eight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delapan"])},
    "nine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembilan"])},
    "ten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sepuluh"])}
  },
  "languages": {
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
    "bulgarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarian"])},
    "burmese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burmese"])},
    "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
    "chinesetw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Taiwan"])},
    "chinesesm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Simplified"])},
    "chinesetr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Traditional"])},
    "croatian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatian"])},
    "czech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech"])},
    "dutch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "greek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greek"])},
    "gujarati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gujarati"])},
    "haitian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haitian Creole"])},
    "hebrew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebrew"])},
    "hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
    "hungarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungarian"])},
    "indonesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesian"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
    "kannada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kannada"])},
    "khmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khmer"])},
    "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
    "laos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "marathi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marathi"])},
    "polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
    "portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
    "portuguesebr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Brazilian"])},
    "portuguesemz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Mozambique"])},
    "portuguesepo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Portugal"])},
    "romanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romanian"])},
    "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
    "serbian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbian"])},
    "sinhalese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinhalese"])},
    "slovak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovak"])},
    "slovenian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenian"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
    "spanishmx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish Mexican"])},
    "swedish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
    "tamil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamil"])},
    "telugu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telugu"])},
    "thai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai"])},
    "turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "ukrainian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrainian"])},
    "vietnamese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamese"])}
  },
  "validations": {
    "invalidFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format yang tidak valid"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email tidak valid"])},
    "wrongPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata sandi saat ini tidak valid."])}
  },
  "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
  "savingProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penghematan..."])},
  "startBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulai"])},
  "saveBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menyimpan"])},
  "continueBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melanjutkan"])},
  "cancelBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membatalkan"])},
  "closeBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menutup"])},
  "deleteBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menghapus"])},
  "editBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "submitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
  "saveProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan Progres"])},
  "loginBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk"])},
  "logoutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluar"])},
  "signInBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk"])},
  "signOutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluar"])},
  "updateBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbarui"])},
  "perbaruiProfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])}
}