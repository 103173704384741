<template>
  <div class="accordion">
    <div class="accordion__header">
      <div class="accordion__header--title">
        <BaseText
          :text="title"
          :size="typographySize.BODY_TEXT_BOLD"
          hasDefaultSpacingRemoved
        />
        <CallToAction
          v-if="hasIcon"
          v-bind="iconOptions"
          @click="handleClick"
        />
      </div>
      <div class="accordion__header--actions">
        <CallToAction v-bind="getToggleIcon" @click="onToggleAccordion" />
      </div>
    </div>
    <div class="accordion__content" v-if="isAccordionCollapsed">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import { themes, icons, shapes, typographySize, CTAEvents } from "@/constants";

export default {
  name: "Accordion",
  components: {
    BaseText,
    CallToAction
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    iconOptions: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isAccordionCollapsed: false,
      typographySize
    };
  },
  created() {
    this.isAccordionCollapsed = this.collapsed;
  },
  watch: {
    collapsed(value) {
      this.isAccordionCollapsed = value;
    }
  },
  computed: {
    hasIcon() {
      return !!this.iconOptions?.icon;
    },
    getToggleIcon() {
      return {
        icon: this.isAccordionCollapsed ? icons.ANGLE_UP : icons.ANGLE_DOWN,
        iconShape: shapes.CIRCLE,
        theme: themes.INFO,
        iconSize: 16
      };
    }
  },
  methods: {
    onToggleAccordion() {
      this.isAccordionCollapsed = !this.isAccordionCollapsed;
    },
    handleClick(event) {
      this.$emit(CTAEvents.CLICK_CTA, event);
    }
  }
};
</script>

<style lang="scss" scoped>
.accordion {
  border-radius: $border-radius30;
  box-shadow: 0px 2px 4px 0px #00000036;
  border: 1px solid $grey;

  &__header {
    padding: $spacing16 $spacing24;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--title {
      place-items: center;
      display: flex;
      gap: 8px;
    }

    &--actions {
      display: flex;
      cursor: pointer;
      gap: $spacing24;
    }
  }

  &__content {
    padding: $spacing24;
    border-top: 1px solid $non-photo-blue;
  }
}
</style>
