<template>
  <div class="user-training-learner">
    <ThirdPartyTrainingDetails
      :course-id="courseId"
      :third-party-id="thirdPartyId"
      isSupplierUser
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { sizes, typographySize } from "@/constants";
import ThirdPartyTrainingDetails from "@/organisms/ThirdPartyTrainingDetails/ThirdPartyTrainingDetails";

export default {
  name: "UserTrainingLearner",
  components: { ThirdPartyTrainingDetails },
  data() {
    return {
      sizes,
      typographySize
    };
  },
  computed: {
    ...mapState({
      thirdPartyId: (state) => state.user.thirdPartyId
    }),
    courseId() {
      return this.$route.params?.id;
    }
  }
};
</script>
