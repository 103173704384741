<template>
  <div class="questionares-page">
    <BaseText
      :text="$t('questionnaires.title')"
      :size="typographySize.BODY_TEXT_BOLD"
      class="questionares-page__title"
    />
    <AppLoader v-if="isLoading" class="text-center" small />
    <b-row
      class="ml-2 mt-2 mr-2"
      style="height: calc(100vh - 200px); overflow: auto"
    >
      <b-col
        cols="3"
        v-for="(questionnaire, index) in onboardingTasks"
        :key="index"
      >
        <b-card bg-variant="default" class="mb-2" style="height: 350px">
          <b-card-text class="my-3">
            <font-awesome-icon
              icon="file-signature"
              size="5x"
              class="supplier__icon"
            />
          </b-card-text>
          <b-card-text class="mb-1">{{
            getFormTitle(questionnaire)
          }}</b-card-text>
          <b-card-text
            class="mb-0 text-capitalize"
            v-if="questionnaire.companyEnglishName && questionnaire.country"
            >({{ questionnaire.companyEnglishName }} -
            {{ questionnaire.country }})</b-card-text
          >
          <b-card-text class="mb-0 text-capitalize"
            >{{ $t("questionnaires.requestedDate") }}:
            {{
              dateFormat(questionnaire.onboardingStageTasksCreatedAt)
            }}</b-card-text
          >
          <b-card-text
            class="mb-2"
            v-html="getQuestionnaireStatus(questionnaire.taskStatus)"
          ></b-card-text>
          <b-button
            v-if="
              questionnaire.taskStatus < 4 || questionnaire.taskStatus == 14
            "
            class="mr-2"
            variant="outline-primary"
            @click="getTaskForm(questionnaire)"
          >
            {{ getQuestionnaireButtonLabel(questionnaire.taskStatus) }}
          </b-button>
          <b-button
            v-else-if="questionnaire.taskStatus == 4"
            variant="outline-dark"
            @click="downloadPdf(questionnaire, '/download/questionnaire')"
          >
            <font-awesome-icon icon="file-pdf" />
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppLoader from "@/components/Loader/Loader";
import { Helper } from "@/helpers";
import { mapGetters, mapState, mapActions } from "vuex";
import moment from "moment";
import { makeAuthorizationHeader, HTTP } from "@/utils";
import BaseText from "@/atoms/BaseText/BaseText";
import {
  typographySize,
  actionName,
  localStorageItem,
  urls
} from "@/constants";

export default {
  name: "MyTPPQuestionannires",
  components: {
    AppLoader,
    BaseText
  },
  data() {
    return {
      typographySize
    };
  },
  computed: {
    ...mapGetters({
      form: "form1",
      selectedLanguage: "selectedLanguage",
      isLoading: "isLoading"
    }),
    ...mapState({
      userId: (state) => state.user.userId,
      onboardingTasks: (state) => state.onboardingTasks.onboardingTasks
    })
  },
  watch: {
    selectedLanguage() {
      this.breadcrumbs = [
        {
          text: this.$t("dashboard"),
          href: "/onboarding/dashboard"
        },

        {
          text: this.$t("questionnaires.title"),
          active: true
        }
      ];
    }
  },
  mounted() {
    if (!this.onboardingTasks.length) {
      this.getTasks();
    }
  },
  methods: {
    ...mapActions({
      fetchOnboardingTasks: actionName.ONBOARDING_TASKS.FETCH_ONBOARDING_TASKS
    }),
    async getTasks() {
      try {
        await this.fetchOnboardingTasks();
      } catch ({ status }) {
        Helper.makeToast(
          this.$bvToast,
          "danger",
          `There is an unknown error with status of ${status}. Please contact support`
        );
      }
    },

    dateFormat(data) {
      moment.locale("en");
      return moment.utc(data).local().format("LLLL");
    },
    getFormTitle(form) {
      let formSchema = JSON.parse(form.schema || form.form_schema);
      return formSchema.formTitle[this.selectedLanguage];
    },
    makeToast() {
      const h = this.$createElement;
      const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
        h("b-spinner", { props: { type: "grow", small: true } }),
        this.$t("toast.generatePDF"),
        h("b-spinner", { props: { type: "grow", small: true } })
      ]);
      this.$bvToast.toast([vNodesMsg], {
        variant: "warning",
        title: "PDF",
        autoHideDelay: 5000
      });
    },

    async downloadPdf(questionnaire, route) {
      let formDefaultLanguage = localStorage.getItem(
        localStorageItem.FORM_DEFAULT_LANGUAGE
      );
      let taskId = questionnaire.taskId;
      let title = `${questionnaire.taskTitle}.pdf`;
      this.makeToast();
      HTTP(
        "post",
        `v1/mytpp${route}/${taskId}`,
        { formDefaultLanguage },
        makeAuthorizationHeader(),
        null,
        false,
        "blob"
      )
        .then((response) => {
          if (window.navigator.msSaveOrOpenBlob) {
            let blob = new Blob([response.data]);
            // IE11
            window.navigator.msSaveOrOpenBlob(blob, title);
            Helper.makeToast(
              this.$bvToast,
              "success",
              this.$t("toast.successPDF")
            );
            return false;
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", title); // or any other extension
            document.body.appendChild(link);
            link.click();
            if (response) {
              Helper.makeToast(
                this.$bvToast,
                "success",
                this.$t("toast.successPDF")
              );
            }
          }
        })
        .catch(() => {
          Helper.makeToast(
            this.$bvToast,
            "danger",
            this.$t("toast.errorPDF")
            // "PDF not downloaded please try again"
          );
        });
    },

    getTaskFormType(value) {
      let type = "Questionniare";
      if (value == 2) {
        type = "Survey";
      } else if (value == 3) {
        type = "Training";
      } else if (value == 4) {
        type = "Exam";
      }
      return type;
    },

    getQuestionnaireStatus(taskStatus) {
      let status = "";
      if (taskStatus == 4) {
        status = `<span class="badge badge-success">${this.$t(
          "questionnaires.batchText"
        )}</span>`;
      } else if (taskStatus == 1) {
        status = `<span class="badge badge-warning">${this.$t(
          "questionnaires.batchInProgressText"
        )}</span>`;
      } else if (taskStatus == 0) {
        status = `<span class="badge badge-primary">${this.$t(
          "questionnaires.batchNotStartedext"
        )}</span>`;
      } else if (taskStatus == 8) {
        status = `<span class="badge badge-danger">${this.$t(
          "questionnaires.batchCancelledtext"
        )}</span>`;
      } else if (taskStatus == 9) {
        status = `<span class="badge badge-danger">${this.$t(
          "questionnaires.batchDeclinedtext"
        )}</span>`;
      } else if (taskStatus == 10) {
        status = `<span class="badge badge-danger">${this.$t(
          "questionnaires.batchSelfEvidencetext"
        )}</span>`;
      } else if (taskStatus == 14) {
        status = `<span class="badge badge-warning">${this.$t(
          "questionnaires.batchInProgressPauseRemindersText"
        )}</span>`;
      }
      return status;
    },

    getQuestionnaireButtonLabel(taskStatus) {
      let status = "";

      if (taskStatus == 0) {
        status = this.$t("startBtnText");
      } else if (taskStatus == 1 || taskStatus == 14) {
        status = this.$t("continueBtnText");
      }
      return status;
    },

    age(value) {
      return moment(value).fromNow();
    },

    async getTaskForm(task) {
      this.removeLocalStorageItems();
      this.$router.push(urls.USER_FORM(task.taskId));
    },

    removeLocalStorageItems() {
      localStorage.removeItem(localStorageItem.TASK_ID);
      localStorage.removeItem(localStorageItem.ACTIVE_FORM_ID);
      localStorage.removeItem(localStorageItem.ACTIVE_FORM_SCHEMA);
      localStorage.removeItem(localStorageItem.TASK_FORM_DATA);
      localStorage.removeItem(localStorageItem.ACTIVE_TASK_STATUS);
    }
  }
};
</script>

<style scoped lang="scss">
.supplier__icon {
  color: $bright-gray;
}
.questionares-page {
  &:deep(.questionares-page__title) {
    font-size: 30px;
  }
}
</style>
