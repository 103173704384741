const paginationKeysDTO = {
  ITEMS_PER_PAGE: "itemsPerPage",
  CURRENT_PAGE: "currentPage",
  PAGE: "page",
  TOTAL_ITEMS: "totalItems"
};

const paginationKeysAPIDTO = {
  ITEMS_PER_PAGE: "per_page",
  CURRENT_PAGE: "current_page",
  PAGE: "page",
  TOTAL_ITEMS: "total"
};

export { paginationKeysDTO, paginationKeysAPIDTO };
