<template>
  <div class="form-rows-wrapper">
    <ul class="form-rows-wrapper-list">
      <li
        v-for="({ formRowList, editCtaList, id }, index) in formRowsList"
        :key="id"
        class="form-rows-wrapper-list-item"
      >
        <FormRow
          class="form-rows-wrapper-list-item__form-row"
          :data-test-id="`form-rows-wrapper-list-item__form-row--${index}`"
          :form-row-list="formRowList"
          :edit-cta-list="editCtaList"
        />
      </li>
    </ul>
    <div v-if="formCtaList.length" class="form-rows-wrapper__edit">
      <component
        v-for="({ component, id, componentOptions }, index) in formCtaList"
        v-bind="componentOptions"
        :key="id"
        :is="component"
        class="form-rows-wrapper__edit-cta"
        :data-test-id="`form-rows-wrapper__edit-cta--${index}`"
      />
    </div>
  </div>
</template>

<script>
import FormRow from "@/molecules/FormRow/FormRow";

export default {
  name: "FormRowsList",
  props: {
    formRowsList: {
      type: Array,
      default: () => []
    },
    formCtaList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    FormRow
  }
};
</script>

<style lang="scss" scoped>
.form-rows-wrapper {
  &-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
}
</style>
