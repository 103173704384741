const thirdPartyType = {
  INDIVIDUAL: "P",
  ORGANIZATION: "O"
};

const attributeType = {
  TEXT: "Text",
  INTEGER: "integer",
  DOUBLE: "double",
  COLOR: "color",
  EMAIL: "email",
  URL: "url",
  DATE: "Date",
  BOOLEAN: "boolean",
  FILE: "File",
  IMAGE: "image",
  TEXTAREA: "Textarea",
  DROPDOWN: "Dropdown",
  RATING: "Rating",
  MULTISELECT: "Multiselect",
  COUNTRY_LIST: "Country List",
  HTML_STRING: "htmlString",
  RADIO: "Radio",
  USER: "User",
  MULTISELECT_COUNTRIES: "Multiselect Country"
};

const ratingTypes = {
  UNDEFINED: "Undefined",
  LOW: "Low",
  MODERATE: "Moderate",
  HIGH: "High",
  DO_NOT_ENGAGE: "Do not Engage"
};

const ratingTypeValue = {
  UNDEFINED: 0,
  LOW: 1,
  MODERATE: 2,
  HIGH: 3,
  DO_NOT_ENGAGE: 4
};

const numericScale = {
  NUMERIC_VALUE_0: 0,
  NUMERIC_VALUE_1: 1,
  NUMERIC_VALUE_2: 2,
  NUMERIC_VALUE_3: 3,
  NUMERIC_VALUE_4: 4,
  NUMERIC_VALUE_5: 5,
  NUMERIC_VALUE_6: 6,
  NUMERIC_VALUE_7: 7,
  NUMERIC_VALUE_8: 8,
  NUMERIC_VALUE_9: 9,
  NUMERIC_VALUE_10: 10
};

const actionStatus = {
  OK: "ok",
  HIGH_ALERT: "HighAlert",
  REMEDIATED_OK: "RemediatedOK",
  REMEDIATED_HIGH_ALERT: "remediatedHighAlert",
  IN_PROGRESS: "inprogress",
  WARNING_ALERT: "WarningAlert"
};

const actionStatusValue = {
  OK: "1",
  WARNING_ALERT: "2",
  HIGH_ALERT: "3",
  REMEDIATED_OK: "5",
  IN_PROGRESS: "4",
  REMEDIATED_HIGH_ALERT: "6"
};

const msqActionStatusValue = {
  LOW: 2,
  MEDIUM: 3,
  HIGH: 1
};

const searchStatus = {
  SEARCHABLE: 1
};

const editStatus = {
  EDIT_ON: 1
};

const loginType = {
  PLATFORM_LOGIN: 0,
  SSO_LOGIN: 1
};

const privacyPolicy = {
  IS_DISABLED: 0
};

const eventTypeId = {
  ADD_ASSOCIATE: 33,
  REMOVE_ASSOCIATE: 34,
  EDIT_ASSOCIATE: 35,
  SCREENING_NOT_RERUN: 36
};

const queryObjectKeys = {
  THIRD_PARTY_COUNTRIES: "thirdPartyCountries",
  CUSTOM_ATTRIBUTES: "customAttributes",
  ENHANCE_DUE_DILIGENCE: "enhancedDueDiligence",
  IDD_POST_REMEDIATION: "iDDPostRemediation",
  IDD_PRE_REMEDIATION: "iDDPreRemediation",
  SANCTIONS_ENFORCEMENT: "sanctionsEnforcement",
  PERCEIVED_RISK_INDICATOR: "perceivedRiskIndicator",
  SANCTIONS_ENFORCEMENT_TABLE: "sanctionsEnforcementTable",
  THIRD_PARTY_DDQ_QUESTIONS: "thirdPartyDDQQuestions",
  MSQ_RISK_SCORE_TP_LIST: "MSQRiskScoreTPList",
  ESG_THIRD_PARTY: "esgThirdParty"
};

const thirdPartyFilters = {
  FILTER: "filter",
  SE_FILTER: "se_filter",
  EDD_FILTER: "edd_filter",
  IDD_FILTER: "idd_filter",
  PRI_FILTER: "pri_filter",
  ENTITY_FILTER: "entity_filter",
  COUNTRY_FILTER: "country_filter",
  CUSTOM_ATTRIBUTES: "custom_attributes",
  RISK_RATING_FILTER: "risk_rating_filter",
  ADD_BY_USERS_FILTER: "add_by_users_filter",
  FORM_QUESTIONNAIRES: "form_questionnaires",
  UPLOAD_DATE_TO_FILTERS: "upload_date_to_filter",
  UPLOAD_DATE_FROM_FILTER: "upload_date_from_filter",
  LATEST_QUESTIONNAIRE_STATUS_FILTER: "latest_questionnaire_status_filter",
  LAST_COMPLETED_QUESTIONNAIRE_FILTER: "last_completed_questionnaire_filter",
  LATEST_QUESTIONNAIRE_UPLOAD_DATE_TO_FILTER:
    "latest_questionnaire_upload_date_to_filter",
  LATEST_QUESTIONNAIRE_UPLOAD_DATE_FROM_FILTER:
    "latest_questionnaire_upload_date_from_filter",
  IS_ARCHIVED: "is_archived",
  IS_UBO: "is_ubo"
};

const thirdPartyActionType = {
  ESG: "esg",
  T_CERTIFICATION: "tcertification",
  EDD: "edd",
  MSQ: "msq",
  ESG_METRIC: "esgmetrics"
};

const thirdPartyActionIds = {
  IDD: "IDD",
  IDD_PLUS: "IDD_PLUS"
};

const thirdPartyActionImageType = {
  IN_PROGRESS: "inprogress"
};

const thirdPartyActionProductType = {
  T_CERTIFICATION: 1,
  ESG_METRIC: 2
};

const thirdPartyActionProductStatus = {
  IN_PROGRESS: "1",
  COMPLETED: "2"
};

const THIRD_PARTY_LIST_FILTERS = [
  thirdPartyFilters.FILTER,
  thirdPartyFilters.SE_FILTER,
  thirdPartyFilters.EDD_FILTER,
  thirdPartyFilters.IDD_FILTER,
  thirdPartyFilters.PRI_FILTER,
  thirdPartyFilters.ENTITY_FILTER,
  thirdPartyFilters.COUNTRY_FILTER,
  thirdPartyFilters.CUSTOM_ATTRIBUTES,
  thirdPartyFilters.RISK_RATING_FILTER,
  thirdPartyFilters.ADD_BY_USERS_FILTER,
  thirdPartyFilters.FORM_QUESTIONNAIRES,
  thirdPartyFilters.UPLOAD_DATE_TO_FILTERS,
  thirdPartyFilters.UPLOAD_DATE_FROM_FILTER,
  thirdPartyFilters.LATEST_QUESTIONNAIRE_STATUS_FILTER,
  thirdPartyFilters.LAST_COMPLETED_QUESTIONNAIRE_FILTER,
  thirdPartyFilters.LATEST_QUESTIONNAIRE_UPLOAD_DATE_TO_FILTER,
  thirdPartyFilters.LATEST_QUESTIONNAIRE_UPLOAD_DATE_FROM_FILTER,
  thirdPartyFilters.IS_ARCHIVED,
  thirdPartyFilters.IS_UBO
];

const thirdPartyActionTaskRisk = {
  LOW: "Low",
  MODERATE: "Moderate",
  HIGH: "High"
};

const thirdPartyActionTaskStatus = {
  NOT_STARTED: "0",
  IN_PROGRESS: "1",
  ON_HOLD: "2",
  REVIEW: "3",
  COMPLETED: "4",
  NOT_REQUIRED: "5",
  CANCELLED: "8",
  DECLINED_OR_REFUSED: "9",
  SELF_EVIDENCED: "10",
  IN_PROGRESS_PAUSE_REMINDERS: "14"
};

const thirdPartyActionTaskStatusValue = {
  NOT_STARTED: "Not Started",
  IN_PROGRESS: "In Progress",
  ON_HOLD: "On Hold",
  NOT_REQUIRED: "Not Required",
  CANCELLED: "Cancelled",
  DECLINED_OR_REFUSED: "Declined / Refused",
  SELF_EVIDENCED: "Self Evidenced",
  IN_PROGRESS_PAUSE_REMINDERS: "In Progress - Pause Reminders",
  COMPLETED: "Completed",
  REVIEW: "Review",
  NEVER_SENT: "Never Sent"
};

const riskRangeValue = {
  0: "Zero",
  1: "One",
  2: "Two",
  3: "Three",
  4: "Four",
  5: "Five",
  6: "Six",
  7: "Seven",
  8: "Eight",
  9: "Nine",
  10: "Ten"
};

const thirdPartyProductColumnKeys = {
  LATEST_DDQ_SENT: "latest_ddq_sent",
  LATEST_COMPLETED_DDQ_STATUS: "latest_completed_ddq_status",
  LATEST_DDQ_STATUS: "latest_ddq_status",
  COMPLETED_DATE: "completed_date",
  CREATED_DATE: "created_date",
  PRODUCT_STATUS: "product_status",
  PAYMENT_OPTION: "payment_option",
  LATEST_TRAINING_STATUS: "latest_training_status",
  LATEST_TRAINING_SENT: "latest_training_sent"
};

const reportType = {
  EDD: "EDD",
  PRODUCT: "PRODUCT"
};

const productType = {
  IDD: 1,
  IDD_PLUS: 2
};

const tpRiskLevel = {
  LOW: 1,
  MODERATE: 2,
  HIGH: 3
};

const remediationText = {
  UNREMEDIATED_RED: "Unremediated Red",
  REMEDIATED_RED: "Remediated Red"
};

const settingsType = {
  STRING: "String",
  INTEGER: "Integer",
  BOOLEAN: "Boolean",
  DROPDOWN: "Dropdown",
  MULTISELECT: "Multiselect"
};

const thirdPartyFormLanguage = {
  ENGLISH: "English"
};

const notAvailableText = {
  NA: "N/A"
};

const filterOptions = {
  NO_VALUE: "No Value"
};

const thirdPartyInfoMessages = {
  ENTITY_DETAILS:
    "The more details you share with us, the better we can match you with the right companies.",
  VERIFY_ENTITY_OPTIONAL:
    "An instant global search for registered companies matching this third party, to help you confirm that you are dealing with the correct legal entity.",
  SUCCESS_SEARCH:
    "We have found these potential matches for your third party. If one of these is the correct legal entity, please select it and confirm. This will enable you to access a wider range of services.",
  SUBSCRIPTION_MATCH:
    "<b>Note:</b> If you are subscribed to IDD or IDD+ screening services, you can run these with or without selecting a match.",
  NO_MATCHES_FOUND:
    "<b>No Matches Found.</b> For Premium Corporate Data or Ultimate Beneficial Owner services, a match with the correct legal entity is required. If you want to use these services, please amend the third party details and try again.<br><br><b>Note:</b> If you are subscribed to IDD or IDD+, you can still run these screening services. A match with a legal entity is not required.",
  ADD_PREMIUM_CORPORATE_DATA:
    "If you want to view this information, you must add Premium Corporate Data to this third party.",
  ENABLE_PREMIUM_CORPORATE_DATA:
    "To enable please upgrade to Premium Corporate Data.",
  VERIFIED_DETAILS:
    "These company details were captured at the time of verification. They are static, and will not update to reflect real-world changes. If you require ongoing monitoring to keep these details up to date, you can add Premium Corporate Data to this third party.",
  NO_TRAINING_LIST_FEEDBACK:
    "This third party doesn't have any Training present and will appear once Training product has been ordered. If this product has not been activated on your account, please speak with your Account Manager."
};

const entityVerificationMessages = {
  USE_ENTITY:
    "Use the entity verification function to enhance your third party data.",
  NOT_FOUND:
    "We couldn't find a match for this entity with our Premium Corporate Data service."
};

const thirdPartyTrainingStatuses = {
  NOT_STARTED: "NOT STARTED",
  IN_PROGRESS: "IN PROGRESS",
  CANCELLED: "CANCELLED",
  COMPLETED: "COMPLETED",
  OVERDUE: "OVERDUE",
  ARCHIVED: "ARCHIVED"
};

const views = {
  LIST: "list",
  DETAILS: "details"
};

export {
  thirdPartyType,
  attributeType,
  ratingTypes,
  ratingTypeValue,
  actionStatus,
  actionStatusValue,
  msqActionStatusValue,
  numericScale,
  searchStatus,
  editStatus,
  loginType,
  privacyPolicy,
  eventTypeId,
  queryObjectKeys,
  thirdPartyFilters,
  thirdPartyActionType,
  thirdPartyActionImageType,
  thirdPartyActionProductType,
  thirdPartyActionProductStatus,
  THIRD_PARTY_LIST_FILTERS,
  thirdPartyActionTaskRisk,
  thirdPartyActionTaskStatus,
  thirdPartyActionTaskStatusValue,
  riskRangeValue,
  thirdPartyProductColumnKeys,
  reportType,
  productType,
  tpRiskLevel,
  remediationText,
  settingsType,
  thirdPartyFormLanguage,
  notAvailableText,
  thirdPartyActionIds,
  filterOptions,
  thirdPartyInfoMessages,
  entityVerificationMessages,
  thirdPartyTrainingStatuses,
  views
};
