<template>
  <div data-test-id="modal__overlay" class="modal__overlay">
    <div data-test-id="modal" class="modal">
      <ModalHeader :title="title" @click="$emit('close-modal')" />
      <div class="modal__body">
        <slot></slot>
      </div>
      <BaseFooter v-if="showLeftSlot || showRightSlot">
        <template v-slot:left>
          <slot name="left"></slot>
        </template>
        <template v-slot:right>
          <slot name="right"></slot>
        </template>
      </BaseFooter>
    </div>
  </div>
</template>

<script>
import ModalHeader from "@/molecules/ModalHeader/ModalHeader";
import BaseFooter from "@/atoms/BaseFooter/BaseFooter";

export default {
  name: "Modal",
  components: {
    BaseFooter,
    ModalHeader
  },
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  computed: {
    showLeftSlot() {
      return !!this.$slots.left;
    },
    showRightSlot() {
      return !!this.$slots.right;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  background: $white;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 100%;
  max-width: 500px;
  height: auto;
  padding: 0;
  border-radius: $spacing32;

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($black, 0.5);
    z-index: 10000;
  }

  &__body {
    overflow: auto;
    padding: $spacing24;
    max-height: 45vh;
    height: auto;
    align-content: center;
    text-align: left;
  }
  :deep(.base-footer),
  :deep(.modal-header) {
    padding: $spacing16 $spacing24;
  }
}
</style>
