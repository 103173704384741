<template>
  <div
    class="third-party-ESG-table"
    data-test-id="third-party-ESG-table"
    ref="third-party-ESG-table"
  >
    <BaseLoader
      v-if="loadingTableData"
      data-test-id="third-party-ESG-table__loader"
    />
    <template v-else-if="showTables">
      <MessageBanner
        v-if="!latestESGReport.url"
        class="third-party-ESG-table__message--no-url"
        :theme="themes.ETHIXBASE_PRIMARY"
        message='Note: This ESG assessment took place without a URL being provided therefore Resilience Strength rated as "None"'
      />
      <StickyTable
        v-if="showOverallTable"
        :table-headers="overallESGTableHeaders"
        :table-rows="overallESGTableRows"
        class="third-party-ESG-table__table"
        data-test-id="third-party-ESG-table__table--overall"
      />
      <StickyTable
        v-if="showRiskTable"
        :table-headers="riskESGTableHeaders"
        :table-rows="riskESGTableRows"
        class="third-party-ESG-table__table"
        data-test-id="third-party-ESG-table__table--risk"
      />
      <StickyTable
        v-if="showResilienceTable"
        :table-headers="resilienceESGTableHeaders"
        :table-rows="resilienceESGTableRows"
        class="third-party-ESG-table__table"
        data-test-id="third-party-ESG-table__table--resilience"
      />
      <StickyTable
        :table-headers="makeESGReportsHeaders"
        :table-rows="makeESGReportsRows"
        class="third-party-ESG-table__table"
        data-test-id="third-party-ESG-table__table--reports"
        @table-row-click="onTableRowCLick"
      />
    </template>
    <template v-else>
      <div
        class="third-party-ESG-table__not-activated"
        data-test-id="third-party-ESG-table__not-activated"
      >
        <BaseText
          text="This third party doesn't have any ESG Explore ratings or scores at present.
          These will appear once the ESG Explore process has completed for the TP.
          If ESG has not been activated on your account. For more information around our ESG offering, please visit "
          tag="span"
        />
        <a :href="ESGLink" :title="ESGLink" target="_blank">
          {{ ESGLink }}
        </a>
        <BaseText text="or speak with your Account Manager." tag="span" />
      </div>
    </template>
  </div>
</template>

<script>
import {
  getOverallPillarBackgroundColor,
  getPillarRatingTheme,
  getRiskRatingBackgroundColor,
  getRiskRatingTheme,
  getStrengthRatingBackgroundColor,
  getStrengthRatingTheme,
  getWidestElement,
  makeESGTableLabelObject,
  makeESGTableRatingObject,
  sanitizeFields,
  makeDateToMatchFormat
} from "@/utils";
import {
  actionName,
  externalUrls,
  getterName,
  ESG_DISPLAY_TEXT,
  ESGReportsKeys,
  ESGReportsTableHeaders,
  mutationName,
  themes,
  typographySize
} from "@/constants";
import StickyTable from "@/molecules/StickyTable/StickyTable";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import MessageBanner from "@/molecules/MessageBanner/MessageBanner";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { nextTick } from "vue";

export default {
  name: "ThirdPartyESGTable",
  components: {
    MessageBanner,
    BaseLoader,
    StickyTable,
    BaseText
  },
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    thirdPartyId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      themes,
      ESGLink: externalUrls.GREENLITE,
      overallESGTableHeaders: [],
      overallESGTableRows: [],
      riskESGTableHeaders: [],
      riskESGTableRows: [],
      resilienceESGTableHeaders: [],
      resilienceESGTableRows: [],
      loadingTableData: false,
      // TODO: Move to i18n file
      ratingDisplayText: {
        NO_MATCH: "None",
        MODERATE: "Moderate",
        WEAK: "Weak",
        STRONG: "Strong",
        NONE: "None"
      },
      riskDisplayText: {
        NONE: "None",
        NO_MATCH: "None",
        CRITICAL: "Critical",
        SERIOUS: "Serious",
        MODERATE: "Moderate"
      }
    };
  },
  created() {
    this.fetchAllReports();
  },
  computed: {
    ...mapState({
      latestESGReport: (state) => state.esg.latestESGReport,
      ESGReports: (state) => state.esg.ESGReports
    }),
    ...mapGetters({
      latestESGReportSummary: getterName.ESG.GET_LATEST_ESG_REPORT_SUMMARY
    }),
    showTables() {
      return (
        this.showOverallTable || this.showRiskTable || this.showResilienceTable
      );
    },
    showOverallTable() {
      return !!(
        this.overallESGTableHeaders?.length && this.overallESGTableRows?.length
      );
    },
    showRiskTable() {
      return !!(
        this.riskESGTableHeaders?.length && this.riskESGTableRows?.length
      );
    },
    showResilienceTable() {
      return !!(
        this.resilienceESGTableHeaders?.length &&
        this.resilienceESGTableRows?.length
      );
    },
    makeESGReportsListForTable() {
      return this.ESGReports?.length
        ? this.ESGReports.map(({ triggerDate }, index) => ({
            [ESGReportsKeys.VERSION]: `Version ${
              this.ESGReports.length - index
            }`,
            [ESGReportsKeys.TRIGGER_DATE]: makeDateToMatchFormat({
              stringifiedDate: triggerDate
            }),
            [ESGReportsKeys.REPORT_ID]: ""
          }))
        : [];
    },
    makeESGReportsHeaders() {
      return this.makeESGReportsListForTable.length
        ? Object.keys(this.makeESGReportsListForTable[0]).reduce((acc, key) => {
            if (ESGReportsTableHeaders[key]) {
              acc.push({
                value: key,
                component: BaseText,
                componentOptions: {
                  tag: "span",
                  text: ESGReportsTableHeaders[key],
                  size: typographySize.BODY_TEXT_BOLD,
                  styles: {
                    margin: "0"
                  }
                },
                styles: {
                  padding: "5px"
                }
              });
            }
            return acc;
          }, [])
        : [];
    },
    makeESGReportsRows() {
      return this.makeESGReportsListForTable.length
        ? this.makeESGReportsListForTable.map((action) =>
            Object.keys(action).reduce((acc, actionKey) => {
              if (actionKey === ESGReportsKeys.REPORT_ID) {
                acc[actionKey] = {
                  component: CallToAction,
                  componentOptions: {
                    value: "View Report"
                  }
                };
              } else {
                acc[actionKey] = {
                  component: BaseText,
                  componentOptions: {
                    tag: "span",
                    text: [action[actionKey]],
                    styles: {
                      padding: "0px 5px"
                    }
                  }
                };
              }

              return acc;
            }, {})
          )
        : [];
    }
  },
  watch: {
    latestESGReportSummary: {
      deep: true,
      handler(newValue) {
        if (newValue && Object.keys(newValue)?.length) {
          this.fetchESGData(newValue?.[ESGReportsKeys.REPORT_ID]);
        } else {
          this.resetTableData();
        }
      }
    },
    latestESGReport: {
      deep: true,
      handler(newValue) {
        if (newValue && Object.keys(newValue)?.length) {
          this.makeESGTableData(newValue);
        } else {
          this.resetTableData();
        }
      }
    }
  },
  beforeDestroy() {
    this.resetESGState();
  },
  methods: {
    ...mapActions({
      fetchESGReports: actionName.ESG.FETCH_ESG_REPORTS,
      fetchESGReportById: actionName.ESG.FETCH_ESG_REPORT_BY_ID
    }),
    ...mapMutations({
      resetESGState: mutationName.ESG.RESET_ESG_STATE
    }),
    onTableRowCLick(_, tableRowLevel) {
      this.loadingTableData = true;
      this.fetchESGData(this.ESGReports[tableRowLevel].reportId);
    },
    makeEndpointParameters(reportId) {
      return sanitizeFields({
        companyId: this.companyId,
        thirdPartyId: this.thirdPartyId,
        reportId
      });
    },
    async fetchAllReports() {
      this.loadingTableData = true;
      try {
        await this.fetchESGReports(this.makeEndpointParameters());
      } catch (error) {
        this.resetTableData();
      }
    },
    async fetchESGData(reportId) {
      try {
        await this.fetchESGReportById(this.makeEndpointParameters(reportId));
      } catch (error) {
        this.resetTableData();
      } finally {
        this.loadingTableData = false;
        await nextTick();
        await this.setBadgeWidth();
      }
    },
    async getMinimumBadgeWidth() {
      await nextTick();
      const allBadgeElements =
        this.$refs["third-party-ESG-table"]?.getElementsByClassName(
          "base-badge__text"
        );

      return getWidestElement(allBadgeElements) + 40;
    },
    async setBadgeWidth() {
      const width = await this.getMinimumBadgeWidth();

      this.overallESGTableRows.forEach((row) => {
        row.rating.componentOptions.minimumWidth = `${width}px`;
      });

      this.riskESGTableRows.forEach((row) => {
        row.riskSeverity.componentOptions.minimumWidth = `${width}px`;
      });

      this.resilienceESGTableRows.forEach((row) => {
        if (!isNaN(row?.resilience?.componentOptions?.minimumWidth)) {
          row.resilience.componentOptions.minimumWidth = `${width}px`;
        }
      });
    },
    makeOverallESGTableData(data) {
      this.overallESGTableHeaders = [
        {
          ...makeESGTableLabelObject("Overall ESG Rating", true, true)
        },
        {
          value: "rating",
          component: BaseText,
          componentOptions: {
            tag: "span",
            text: data.esg.rating,
            theme: getPillarRatingTheme(data.esg.rating),
            size: typographySize.DISPLAY_TEXT
          },
          styles: {
            width: "25%",
            backgroundColor: getOverallPillarBackgroundColor(data.esg.rating),
            textAlign: "center"
          }
        }
      ];
      this.overallESGTableRows = this.getOverallESGTableRows(data);
    },
    getOverallESGTableRows(data) {
      const rows = [
        {
          name: "Environmental",
          property: "environment"
        },
        {
          name: "Social",
          property: "social"
        },
        {
          name: "Governance",
          property: "governance"
        }
      ];

      return rows.map(({ name, property }) => {
        const overallValue = data[property].rating;
        const theme = getPillarRatingTheme(overallValue);

        return {
          label: {
            ...makeESGTableLabelObject(`${name} Overall Rating`, false, true)
          },
          tableRowLevel: 2,
          rating: {
            ...makeESGTableRatingObject(overallValue, theme)
          }
        };
      });
    },
    makeESGRiskTableData(data) {
      this.riskESGTableHeaders = [
        {
          ...makeESGTableLabelObject("Overall Risk Severity", true, true)
        },
        {
          value: "riskSeverity",
          component: BaseText,
          componentOptions: {
            tag: "span",
            text: data.risk.severity,
            theme: getRiskRatingTheme(data.risk.severity),
            size: typographySize.DISPLAY_TEXT
          },
          styles: {
            width: "25%",
            backgroundColor: getRiskRatingBackgroundColor(data.risk.severity),
            textAlign: "center"
          }
        }
      ];
      this.riskESGTableRows = this.getRiskESGTableRows(data);
    },
    getRiskESGTableRows(data) {
      const riskComponents = Object.keys(data.risk.components);
      return riskComponents.map((component) => {
        const currentComponent = data.risk.components[component];
        const riskValue =
          ESG_DISPLAY_TEXT[
            currentComponent.riskSeverity.replace(/ /g, "_").toUpperCase()
          ];

        const theme = getRiskRatingTheme(riskValue);

        return {
          label: {
            ...makeESGTableLabelObject(
              `${currentComponent.pillar} Risk Severity`,
              false,
              true
            )
          },
          riskSeverity: {
            ...makeESGTableRatingObject(riskValue, theme)
          },
          tableRowLevel: 2
        };
      });
    },
    makeESGResilienceTableData(data) {
      this.resilienceESGTableHeaders = [
        {
          ...makeESGTableLabelObject("Overall Resilience Strength", true, true)
        },
        {
          value: "resilience",
          component: BaseText,
          componentOptions: {
            tag: "span",
            text: data.resilience.strength,
            theme: getStrengthRatingTheme(data.resilience.strength),
            size: typographySize.DISPLAY_TEXT
          },
          styles: {
            width: "25%",
            backgroundColor: getStrengthRatingBackgroundColor(
              data.resilience.strength
            ),
            textAlign: "center"
          }
        }
      ];
      this.resilienceESGTableRows =
        this.getResilienceESGTableRows(data).flat(1);
    },
    getResilienceESGTableRows(data) {
      const riskComponents = Object.keys(data.resilience.components).reverse();
      return riskComponents.map((component) => {
        const currentComponent = data.resilience.components[component];

        const componentIndicators = currentComponent.resilienceIndicators.map(
          (indicator) => {
            const resilienceValue =
              this.ratingDisplayText[
                indicator.strength.replace(/ /g, "_").toUpperCase()
              ];
            const theme = getStrengthRatingTheme(resilienceValue);

            return {
              label: {
                ...makeESGTableLabelObject(`${indicator.name} Strength`)
              },
              resilience: {
                ...makeESGTableRatingObject(resilienceValue, theme)
              },
              tableRowLevel: 3
            };
          }
        );
        const themeResilienceStrength = getStrengthRatingTheme(
          currentComponent.resilienceStrength
        );
        return [
          {
            label: {
              ...makeESGTableLabelObject(
                `${currentComponent.pillar} Resilience Strength`,
                false,
                true
              )
            },
            resilience: {
              ...makeESGTableRatingObject(
                currentComponent.resilienceStrength,
                themeResilienceStrength
              )
            },
            tableRowLevel: 2
          },
          ...componentIndicators
        ];
      });
    },
    makeESGTableData(data) {
      this.makeOverallESGTableData(data);
      this.makeESGRiskTableData(data);
      this.makeESGResilienceTableData(data);
    },
    resetTableData() {
      this.loadingTableData = false;
      this.overallESGTableHeaders = [];
      this.overallESGTableRows = [];
      this.riskESGTableHeaders = [];
      this.riskESGTableRows = [];
      this.resilienceESGTableHeaders = [];
      this.resilienceESGTableRows = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.third-party-ESG-table {
  @include page-container;
  padding-top: 0;

  &__table {
    margin-bottom: 15px;
    min-width: 500px;
  }

  &__not-activated {
    max-width: 900px;
    width: 100%;
  }

  &__message--no-url {
    margin-bottom: 15px;
  }
}
</style>
