<template>
  <div v-if="isUserSuperAdmin">
    <b-tabs card>
      <b-tab title="Email Configuration">
        <b-tabs vertical small nav-class="custom-tab">
          <b-tab title="Email Tags">
            <EmailTags />
          </b-tab>
          <b-tab title="Email Languages">
            <EmailLanguages />
          </b-tab>
          <b-tab title="Email Type">
            <EmailTypes />
          </b-tab>
        </b-tabs>
      </b-tab>
      <b-tab title="EDD Configuration">
        <b-tabs vertical small nav-class="custom-tab">
          <b-tab active title="EDD Scope Options">
            <EddProducts />
          </b-tab>
          <b-tab title="EDD Report Types">
            <EddOrderType />
          </b-tab>
        </b-tabs>
      </b-tab>
      <b-tab active title="Clients">
        <div>
          <b-row>
            <b-col cols="12">
              <b-overlay :show="isLoading" rounded="sm">
                <b-card no-body class="shadow-sm pt-0 pl-0 pr-0 m-4 mb-0">
                  <b-navbar toggleable="lg">
                    <b-navbar-brand href="#" class="text-dark">
                      <strong style="color: #3b3e66">Clients</strong>
                    </b-navbar-brand>

                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="ml-auto">
                      <b-nav-form>
                        <!-- reload btn -->
                        <b-button
                          variant="outline-info"
                          size="sm"
                          class="mr-4"
                          @click="reloadTable"
                        >
                          <font-awesome-icon icon="sync" />
                        </b-button>

                        <!-- search bar -->
                        <b-form-group>
                          <b-input-group size="sm">
                            <b-form-input
                              v-model="filter"
                              type="search"
                              id="filterInput"
                              placeholder="Type to Search"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button :disabled="!filter" @click="filter = ''"
                                >Clear</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-nav-form>
                    </b-navbar-nav>
                  </b-navbar>

                  <!-- clients tablel data start -->
                  <b-table
                    sticky-header="calc(100vh - 295px)"
                    :busy.sync="isLoading"
                    bordered
                    ref="table"
                    id="clients-table"
                    :fields="fields"
                    :items="getClientsTableData"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :filter="filter"
                    no-sort-reset
                  >
                    <template v-slot:cell(created_at)="data">{{
                      createdAtDate(data.item.created_date)
                    }}</template>
                    <template v-slot:cell(company_name)="data">
                      <a
                        :href="`/client-configuration-profile/${data.item.id}/platform-settings`"
                        @click="setCompanyName(data)"
                      >
                        {{ data.item.company_name }}
                      </a>
                    </template>
                    <template v-slot:cell(actions)="data">
                      <b-button
                        size="sm"
                        variant="outline-success"
                        @click="openProfilePage(data)"
                      >
                        <font-awesome-icon icon="eye" />
                      </b-button>
                    </template>
                  </b-table>
                  <!-- clients tablel data end -->

                  <template v-slot:footer>
                    <b-row>
                      <b-col cols="2">
                        <b-form-select
                          v-model="perPage"
                          id="perPageSelect"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select>
                      </b-col>
                      <b-col cols="6">
                        <p class="text-center">
                          Showing Result From
                          <b>{{ fromRows }}</b> to
                          <b>{{ toRows }}</b>
                        </p>
                      </b-col>
                      <b-col cols="4">
                        <b-pagination
                          class="float-right"
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          first-text="First"
                          prev-text="Prev"
                          next-text="Next"
                          last-text="Last"
                          aria-controls="email-tags-table"
                        ></b-pagination>
                      </b-col>
                    </b-row>
                  </template>
                </b-card>
              </b-overlay>
            </b-col>
          </b-row>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import { HTTP, makeAuthorizationHeader } from "@/utils";
import moment from "moment";
import "moment/locale/es";
import EmailTags from "../../components/eb2/emailTags.vue";
import EmailTypes from "../../components/eb2/emailTypes.vue";
import EmailLanguages from "../../components/eb2/emailLanguages.vue";
import EddProducts from "../../components/eb2/eddProducts.vue";
import EddOrderType from "../../components/eb2/eddOrderType.vue";
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import { getterName } from "@/constants";

export default {
  name: "ClientsPage",
  components: {
    EmailTags,
    EmailTypes,
    EmailLanguages,
    EddProducts,
    EddOrderType
  },
  data() {
    return {
      values: {},
      totalRows: 0,
      isLoading: false,
      filter: null,
      perPage: 10,
      currentPage: 1,
      toRows: 0,
      fromRows: 0,
      sortBy: "id",
      sortDesc: true,
      pageOptions: [5, 10, 25, 50, 100],
      fields: [
        { key: "id", label: "Id", sortable: true },
        { key: "created_at", label: "Created Date", sortable: true },
        { key: "company_name", label: "Company Name", sortable: true },
        { key: "contact_name", label: "Contact Name", sortable: true },
        { key: "actions", label: "Actions" }
      ]
    };
  },

  computed: {
    ...mapGetters({
      isUserSuperAdmin: getterName.USER.IS_USER_SUPER_ADMIN
    }),
    rows() {
      return this.totalRows;
    }
  },
  methods: {
    setCompanyName(data) {
      localStorage.setItem("openedCompanyProfile", data.item.company_name);
    },
    openProfilePage(data) {
      localStorage.setItem("openedCompanyProfile", data.item.company_name);
      window.location.href = `/client-configuration-profile/${data.item.id}/platform-settings`;
    },
    showAddModal() {
      EventBus.emit("onShowAddModal", "");
    },
    showEditModal(data) {
      EventBus.emit("onShowEditModal", data.item);
    },
    createdAtDate(value) {
      moment.locale("en");
      return moment(value).format("DD/MM/YYYY");
    },

    // get clients data
    async getClientsTableData() {
      let formData = {
        filter: this.filter,
        sort: `${this.sortBy}|${this.sortDesc == true ? "desc" : "asc"}`,
        per_page: this.perPage,
        page: this.currentPage
      };

      this.isLoading = true;
      let result = HTTP(
        "post",
        "v1/getclients",
        formData,
        makeAuthorizationHeader()
      );
      return result
        .then((response) => {
          const items = response.data.data;
          this.isLoading = false;
          this.fromRows = response.data.from;
          this.toRows = response.data.to;
          this.totalRows = response.data.total;
          return items;
        })
        .catch((error) => {
          this.isLoading = false;
          return [error];
        });
    },
    reloadTable() {
      this.$root.$emit("bv::refresh::table", "clients-table");
    }
  }
};
</script>

<style lang="scss">
.custom-tab {
  background-color: $bright-gray;
  height: 100% !important;
  width: 220px;
}
.nav-tabs {
  border-bottom: none;
}
.custom-tab .nav-link {
  color: $black;
  font-weight: bold;
  border-bottom: 3px solid $bright-gray;
}
.custom-tab .nav-link.disabled {
  color: $white;
  font-weight: bold;
  border-bottom: 3px solid $bright-gray;
}
.custom-tab .nav-link.active {
  color: $black;
  background-color: $bright-gray;
  border-color: $bright-gray;
  border-bottom: 3px solid $robin-egg-blue;
}
.custom-tab .nav-link.active:hover {
  background-color: $bright-gray;
  border-color: $bright-gray;
  border-bottom: 3px solid $robin-egg-blue;
}
</style>
