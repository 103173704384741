<template>
  <div class="permissions-platform-features">
    <BaseLoader
      v-if="isDataLoading"
      class="permissions-platform-features__loader"
    />
    <ErrorPageTemplate v-else-if="hasPageError" v-bind="errorPageOptions" />
    <div v-else class="permissions-platform-features__content">
      <BaseText
        :size="typographySize.BODY_TEXT_ITALIC"
        text="Use the options below to grant permissions to selected user roles."
        class="permissions-platform-features__description"
      />
      <div
        v-for="(item, index) in listOptions"
        :key="index"
        class="permissions-platform-features__container"
      >
        <BaseText :size="typographySize.LARGE_TEXT_BOLD" :text="item.name" />
        <BaseText
          v-if="item.description"
          :size="typographySize.BODY_TEXT_SMALL"
          :text="item.description"
        />
        <MultiSelect
          :id="item.name"
          :options="item.options"
          data-test-id="permissions-platform-features__multiselect"
          @change="updateRoleOptions($event, index)"
        />
      </div>
      <div class="permissions-platform-features__footer">
        <BaseText
          v-if="permissionsDataUpdateError"
          :size="typographySize.BODY_TEXT_SMALL"
          :text="permissionsDataUpdateError"
          :theme="themes.ERROR_INVERSE"
          hasDefaultSpacingRemoved
        />
        <CallToAction v-bind="CTAOptions" @click="handleSaveClick" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import BaseText from "@/atoms/BaseText/BaseText";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import MultiSelect from "@/molecules/MultiSelect/MultiSelect.vue";
import ErrorPageTemplate from "@/templates/ErrorPageTemplate/ErrorPageTemplate";
import { makeOptionsForMultiSelect } from "@/molecules/MultiSelect/MultiSelect.dto";
import {
  typographySize,
  roles,
  operations,
  themes,
  permissionsEvents
} from "@/constants";
import { cloneDeep } from "lodash";

export default {
  name: "PermissionsPlatformFeatures",
  components: {
    BaseText,
    BaseLoader,
    CallToAction,
    ErrorPageTemplate,
    MultiSelect
  },
  props: {
    roles: {
      type: Array,
      default: () => []
    },
    getAllRolesAsMultiselectOptions: {
      type: Array,
      default: () => []
    },
    isLoadingRoles: {
      type: Boolean,
      default: false
    },
    featurePermissions: {
      type: Array,
      default: () => []
    },
    isFeaturePermissionsLoading: {
      type: Boolean,
      default: false
    },
    isUpdatingFeaturePermissions: {
      type: Boolean,
      default: false
    },
    permissionsDataUpdatedSuccessfully: {
      type: Boolean,
      default: false
    },
    permissionsDataUpdateError: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      errorPageOptions: {
        iconOptions: {
          icon: "exclamation"
        },
        headingOptions: {
          text: "Oops! Something went wrong"
        },
        contentOptions: {
          text: [
            "An error has occurred, please try again. If the issue persists please contact support."
          ]
        }
      },
      typographySize,
      themes,
      featureList: []
    };
  },
  watch: {
    featurePermissions(newValue) {
      if (newValue?.length) {
        this.featureList = newValue;
      }
    }
  },
  created() {
    if (this.featurePermissions?.length) {
      this.featureList = this.featurePermissions;
    }
  },
  computed: {
    CTAOptions() {
      return {
        value: "Save",
        isLoading: this.isUpdatingFeaturePermissions,
        isSuccess: this.permissionsDataUpdatedSuccessfully,
        isError: !!this.permissionsDataUpdateError
      };
    },
    amendableRoles() {
      return this.getAllRolesAsMultiselectOptions
        ?.filter((role) => role?.value !== roles.ADMIN)
        ?.map((role) => role?.value);
    },
    isDataLoading() {
      return this.isLoadingRoles || this.isFeaturePermissionsLoading;
    },
    hasPageError() {
      return (
        (!this.roles?.length && !this.isLoadingRoles) ||
        (!this.featurePermissions?.length && !this.isFeaturePermissionsLoading)
      );
    },
    listOptions() {
      return this.featureList?.map((feature) => ({
        name: feature?.name,
        description: feature?.description,
        options: this.makeFeatureRolesOptions(feature?.roleIds)
      }));
    }
  },
  methods: {
    makeFeatureRolesOptions(featureRoles = []) {
      return makeOptionsForMultiSelect(
        featureRoles,
        this.amendableRoles,
        this.getAllRolesAsMultiselectOptions
      );
    },
    updateRoleOptions({ eventType, value }, index) {
      const clonedList = cloneDeep(this.featureList);

      if (eventType === operations.ADD) {
        clonedList[index].roleIds.push(value);
      } else {
        clonedList[index].roleIds = clonedList[index].roleIds.filter(
          (roleValue) => roleValue !== value
        );
      }

      this.featureList = clonedList;
    },
    handleSaveClick() {
      this.$emit(permissionsEvents.SAVE_CLICK, this.featureList);
    }
  }
};
</script>

<style lang="scss" scoped>
.permissions-platform-features {
  position: relative;
  text-align: left;

  &__loader {
    margin-top: 48px;
  }

  &__content {
    height: calc(100vh - 232px);
    overflow-y: auto;
    padding: 24px 12px 54px;
  }

  &__description {
    margin-bottom: 24px;
  }

  &__container {
    margin-bottom: 12px;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    width: calc(100% - 24px);
    border-top: 1px solid $spanish-gray;
    background-color: $white;
    padding: 8px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
  }
}
</style>
