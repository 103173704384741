import { state as makeState } from "@/store/uboEntity/uboEntity.state.store";

export const mutations = {
  setUBOEntityData(state, payload) {
    state.UBOEntityData = payload;
  },
  setUBOEntityDataIsLoading(state, payload) {
    state.UBOEntityDataIsLoading = payload;
  },
  setUBOEntityErrorMessage(state, payload) {
    state.UBOEntityErrorMessage = payload;
  },
  setUBODueDiligenceReportData(state, payload) {
    state.UBODueDiligenceReportData = payload;
  },
  setUBODueDiligenceReportDataIsLoading(state, payload) {
    state.UBODueDiligenceReportDataIsLoading = payload;
  },
  setUBODueDiligenceErrorMessage(state, payload) {
    state.UBODueDiligenceErrorMessage = payload;
  },
  resetUboEntity(state) {
    Object.assign(state, makeState());
  }
};
