import { featurePermissionsKeys, getterName } from "@/constants";

export const getters = {
  getIsTaskReopenAllowed(state, _, rootState, rootGetters) {
    return (
      rootGetters[getterName.USER.IS_USER_SUPER_ADMIN_OR_ADMIN] ||
      !!rootState.permissions?.permissions
        ?.find?.(({ id }) => id === featurePermissionsKeys.REOPEN_TASK)
        ?.contexts?.find?.(({ taskId }) => taskId === state.task.id)
    );
  },
  getIsTaskEditAllowed(state, _, rootState, rootGetters) {
    return (
      rootGetters[getterName.USER.IS_USER_SUPER_ADMIN_OR_ADMIN] ||
      !!rootState.permissions?.permissions
        ?.find?.(({ id }) => id === featurePermissionsKeys.EDIT_TASK)
        ?.contexts?.find?.(({ taskId }) => taskId === state.task.id)
    );
  },
  getIsTaskCompleteAllowed:
    (state, _, rootState, rootGetters) =>
    (selectedActionId = "") =>
      rootGetters[getterName.USER.IS_USER_SUPER_ADMIN_OR_ADMIN] ||
      !!rootState.permissions?.permissions
        ?.find?.(({ id }) => id === featurePermissionsKeys.PICK_TASK_OUTCOME)
        ?.contexts?.find?.(
          ({ taskId, actionId }) =>
            taskId === state.task.id && actionId === selectedActionId
        )
};
