<template>
  <b-tabs
    v-model="$store.state.tabIndexRiskSummary"
    nav-class="custom-tab-css"
    vertical
    small
  >
    <b-tab
      :lazy="$store.state.riskSummaryOverviewTabLazyLoad"
      :title="$t('eb360.riskSummary.title')"
    >
      <div>
        <RiskOverview
          :companyId="companyId"
          :thirdPartyId="thirdPartyId"
          :isDisabled="isDisabled"
          :thirdPartyData="thirdPartyData"
          :is-dnb-modal="isDnbModal"
          :custom-attributes="customAttributes"
          :has-u-b-o-feature="hasUBOFeature"
          @click="$emit('click', $event)"
        />
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import RiskOverview from "@/organisms/RiskOverview/RiskOverview.vue";

export default {
  name: "RiskSummaryTabComponent",
  components: {
    RiskOverview
  },
  props: {
    companyId: Number,
    isDisabled: Boolean,
    thirdPartyId: Number,
    thirdPartyData: Object,
    customAttributes: {
      type: Array,
      default: () => []
    },
    isDnbModal: {
      type: Boolean,
      required: true
    },
    hasUBOFeature: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/custom.scss";

.custom-tab-css {
  background-color: $chinese-silver !important;
  height: 100% !important;
  width: 220px;

  & .nav-link {
    color: $black !important;
    font-weight: bold;
    border-bottom: 3px solid $chinese-silver !important;
  }

  & .nav-link.disabled {
    color: $white !important;
    font-weight: bold;
    border-bottom: 3px solid $chinese-silver !important;
  }
}

.nav-tabs {
  border-bottom: none;
}
</style>
