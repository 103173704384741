export const state = () => ({
  passwordCreated: null,
  registrationStatus: null,
  memberGroupId: null,
  userId: null,
  landingPage: null,
  loginType: null,
  userFullName: null,
  userFirstName: null,
  userLastName: null,
  email: null,
  userTourStatus: null,
  platformVersion: null,
  formDefaultLanguage: null,
  roles: [],
  thirdPartyId: null
});
