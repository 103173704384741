<template>
  <div class="verified-details">
    <BaseInfoBox
      v-if="isDnbDisabled"
      :text="thirdPartyInfoMessages.ENABLE_PREMIUM_CORPORATE_DATA"
      data-test-id="verified-details__disable-text--dnb-disabled"
      :icon-options="defaultIcons.INFO"
      :theme="themes.INFO"
    />
    <div v-else>
      <BaseInfoBox
        data-test-id="verified-details__disable-text--dnb-not-verified"
        v-if="verifiedDetailsData"
        :text="thirdPartyInfoMessages.VERIFIED_DETAILS"
        :icon-options="defaultIcons.INFO"
        :theme="themes.INFO"
      />
      <BaseInfoBox
        v-if="isShowDnbVerifiedInfoBox"
        data-test-id="verified-details__disable-text--dnb-not-verified"
        :text="thirdPartyInfoMessages.ADD_PREMIUM_CORPORATE_DATA"
        :icon-options="defaultIcons.INFO"
        :theme="themes.INFO"
      />
      <div
        data-test-id="verified-details__table"
        class="verified-details__table"
      >
        <StickyTable
          caption="Verified Details"
          :table-headers="verifiedDetailsTableHeaders"
          :table-rows="verifiedDetailsTableRows"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StickyTable from "@/molecules/StickyTable/StickyTable";
import {
  typographySize,
  defaultIcons,
  themes,
  thirdPartyInfoMessages
} from "@/constants";
import { makeDateToMatchFormat } from "@/utils";
import BaseInfoBox from "@/atoms/BaseInfoBox/BaseInfoBox";
import BaseText from "@/atoms/BaseText/BaseText";

export default {
  name: "VerifiedDetails",
  components: {
    StickyTable,
    BaseInfoBox
  },
  props: {
    verifiedDetailsData: {
      type: Object,
      default: () => ({})
    },
    isDnbDisabled: {
      type: Boolean,
      default: false
    },
    isDnbVerified: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isShowDnbVerifiedInfoBox() {
      return !this.isDnbVerified && !this.verifiedDetailsData;
    },
    verifiedDetailsTableDataMap() {
      return [
        {
          label: "Company Name",
          value: this.verifiedDetailsData?.name || ""
        },
        {
          label: "Company Address",
          value: this.verifiedDetailsData?.address || ""
        },
        {
          label: "Operating Status",
          value: this.verifiedDetailsData?.operating_status || ""
        },
        {
          label: "Corporate Linkage",
          value: this.verifiedDetailsData?.corporate_linkage || ""
        },
        {
          label: "Key Person",
          value: this.verifiedDetailsData?.key_person || ""
        },
        {
          label: "Company Registration No.",
          value: this.verifiedDetailsData?.company_registration_number || ""
        },
        {
          label: "Date Verified",
          value: this.verifiedDetailsData?.created_at
            ? this.makeDateToMatchFormat({
                stringifiedDate: this.verifiedDetailsData.created_at
              })
            : ""
        }
      ];
    },
    verifiedDetailsTableRows() {
      return this.verifiedDetailsTableDataMap.map(({ label, value }) => ({
        label: {
          component: BaseText,
          componentOptions: {
            tag: "span",
            text: label,
            size: typographySize.BODY_TEXT_BOLD
          },
          styles: {
            border: "none"
          }
        },
        value: {
          component: BaseText,
          componentOptions: {
            tag: "span",
            text: [value]
          },
          styles: {
            border: "none"
          }
        }
      }));
    }
  },
  data() {
    return {
      verifiedDetailsTableHeaders: [
        {
          value: "label",
          styles: {
            display: "none"
          }
        },
        {
          value: "value",
          styles: {
            display: "none"
          }
        }
      ],
      typographySize,
      defaultIcons,
      themes,
      thirdPartyInfoMessages
    };
  },
  methods: {
    makeDateToMatchFormat
  }
};
</script>

<style lang="scss" scoped>
.verified-details {
  &__table {
    margin-top: $spacing20;
  }
}
</style>
