export default {
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仪表板"])},
  "dashboardTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合规待办事宜"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页"])},
  "loadingSpinnerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["载入中..."])},
  "nav": {
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板"])},
    "dandi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D&I"])}
  },
  "questionnaires": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问卷"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问卷，政策及证明"])},
    "submitModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交问卷"])},
    "formSubmittedModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问卷已提交"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我同意"])},
    "backToDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回仪表板"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总览"])},
    "dateOfAssigned:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分配日期:"])},
    "dateOfCompleted:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成日期:"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载"])},
    "batchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])},
    "batchInProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行中"])},
    "batchNotStartedext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未开始"])},
    "requestedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申请日期"])},
    "linkUpModalHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要采取进一步措施"])},
    "linkUpModalBodyText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["感谢您填写Salesforce第三方尽职调查申请表。"])},
    "linkUpModalBodyText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据所提供的答复，Salesforce恳请贵公司完成Salesforce第三方尽职调查问卷。"])},
    "linkUpModalBodyText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请尽快完成此问卷。未完成问卷调查可能会影响贵公司与Salesforce建立业务关系的请求。"])},
    "linkUpModalBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始尽职调查问卷"])},
    "linkUpModalCancelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回到问卷"])},
    "deleteRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除行"])},
    "editRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑行"])},
    "noSearchOptionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抱歉，没有匹配的选项."])},
    "selectAtLeast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择至少 "])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选项"])}
  },
  "training": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培训课程"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阅读学习资料并准备考试"])},
    "trainingTite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的培训课程"])},
    "codeOfConduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帝肯经销商行为准则"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["审查"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
    "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在进行中"])},
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])},
    "notComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未开始"])},
    "toFirstSlide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回到简报第一页"])},
    "toDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回仪表板"])},
    "slide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简报"])},
    "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在查看:"])},
    "nextSlide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一页"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一页"])},
    "startCertification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始您的认证"])},
    "trainingCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培训完成"])},
    "proceedToExamination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培训的课程阶段已完成。 开始测验。"])},
    "fromBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从新开始"])},
    "continueTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续培训"])},
    "ContinueText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您要由简报"])},
    "ContinueText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续或从新开始？"])}
  },
  "certifications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资质认证"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参加考试并获取证书"])},
    "noOfQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问题数"])},
    "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mins"])},
    "maxAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再试一次"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失败"])},
    "timeLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Left"])},
    "guidance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "guidanceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "guidanceText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "guidanceText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "summaryText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "summaryText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "startNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即开始"])},
    "doLate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我以后再做"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一页"])},
    "attemptsToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "remainingAttempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "maximumReach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问题"])},
    "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "backToQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一页"])},
    "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在查看："])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一页"])},
    "submitAnswers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交答案"])},
    "answerOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["答案概述"])},
    "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已查看"])},
    "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已回答"])},
    "notAnswered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未回答"])},
    "timeLimitText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "timeLimitText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "examAboutToStartText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的测验将在"])},
    "examAboutToStartText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秒内开始"])},
    "startImmediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即开始"])},
    "startExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始测验"])},
    "aboutToSubmitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您即将提交答案。 这将计入您的最大尝试次数。 如果您对答案满意，请确认提交。"])},
    "aboutToSubmitTextSimple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您即将提交测验。此操作无法撤消，是否继续？"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注:"])},
    "noteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以选择返回并查看答案，然后再提交测验。"])},
    "submitExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交测验"])},
    "cancelExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消测验"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交"])},
    "noAnsGiven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未作出回答"])},
    "cancelExamText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要取消完成测验吗？"])},
    "cancelExamText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "cancelExamText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "cancelExamText4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "examSubmittedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验已提交"])},
    "closeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
    "thankYouText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["感谢您选择我们的测验门户。 如有其他问题，请联系我们的管理员。"])},
    "examResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验成绩"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分数:"])},
    "outOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/"])},
    "yourResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验成绩:"])},
    "toPassExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过测验所需的最低分数:"])},
    "examStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验状态:"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["及格"])},
    "congrats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恭喜，您已及格！"])},
    "PlsTryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请再试一遍"])},
    "goToQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["去问题"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert!"])},
    "blankAns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法提交空白答案。"])},
    "englishOriginalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英文原版"])}
  },
  "formLeaveAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您即将离开页面。 任何未保存的更改将丢失。 您确定要离开吗？"])},
  "formSubmitingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您即将提交问卷。 此操作将无法撤消。 您确定要提交吗？"])},
  "formSubmittedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已成功提交问卷"])},
  "formValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发现一个或多个缺少的字段或错误。 请检查并进行更新。"])},
  "toast": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["警告"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存成功"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进度未保存。 请重试"])},
    "updateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新成功"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
    "generatePDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们正在生成您的PDF ..."])},
    "successPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF下载成功"])},
    "errorPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF未下载，请重试"])},
    "logoutSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出成功"])}
  },
  "createProfile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成注册"])},
    "titleUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新个人信息"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
    "contactHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系方式"])},
    "passwordHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新密码"])},
    "fNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名字"])},
    "lNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓氏"])},
    "contactLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系电话"])},
    "jobTitleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["职称"])},
    "currentPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前密码"])},
    "newPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码"])},
    "confirmPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码"])},
    "fNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入您的名字"])},
    "lNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入您的姓/名"])},
    "contactPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入您的联系电话"])},
    "jobTitlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入您的职位或职称"])},
    "currentPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入当前密码"])},
    "newPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入新密码"])},
    "confirmPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入确认密码"])},
    "requiredValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此为必填字段"])},
    "passwordValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码必须相同"])},
    "loaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的请求正在处理中…"])}
  },
  "upload": {
    "dragDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拖放上传或单击从计算机中选择文件"])},
    "dragDropContnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件名称"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件类型"])},
    "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件大小（比特）"])},
    "fileAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行动"])}
  },
  "signature": {
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除"])},
    "require": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此为必填字段"])}
  },
  "workflow": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重启"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新资料"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
    "deleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您是否确定要删除此项目？"])},
    "deleteHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])}
  },
  "eb360": {
    "analyticsTab": {
      "partyRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Third-Party Records on ethixbase360"])},
      "registrationRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Third-Party Records on ethixbase360"])},
      "dueDilogenceRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Due Diligence PLUS"])},
      "enhancedRiskDataRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhanced S&E, PEP & Other Risk Data"])},
      "moniotringAlerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Wide Ongoing Monitoring Alerts"])},
      "analyticsSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANALYTICS"])},
      "questionariesSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUESTIONNAIRES, POLICIES,  COMMUNICATIONS & TRAINING"])}
    },
    "analyticsTabChart": {
      "thirdPartyCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Parties"])},
      "monitoringCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing Monitoring Results"])},
      "monitoringResults": {
        "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Month"])},
        "momitoringAlerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring Alerts"])},
        "thirdParyties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Parties With Alerts"])},
        "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Month"])},
        "allTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Time"])}
      },
      "selectChart": {
        "pie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pie"])},
        "pyramid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyramid"])},
        "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funnel"])}
      }
    },
    "chartTables": {
      "enforcementTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanctions & Enforcements Results (IDD)"])},
      "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Date"])},
      "names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Name"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "sanctionAlertsErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available"])},
      "sanctionAlertsShow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing Result From"])},
      "sanctionAlertsTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
      "predictiveRiskTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perceived Risk Indicator Results"])},
      "iddPlusPreTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Due Diligence PLUS Results Pre-Remediation"])},
      "iddPlusPostTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Due Diligence PLUS Results Post-Remediation"])},
      "eddTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Due Diligence PLUS Results Post-Remediation"])},
      "tpCountriesTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Parties by Country"])},
      "riskScoreTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Risk"])}
    },
    "statsTabChart": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Questionnaire, Policy or Communication :"])},
      "totalCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "progressCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
      "completedCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
      "startedCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Started"])},
      "overviewTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
      "pie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pie"])},
      "pyramid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyramid"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funnel"])},
      "configTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Configs"])}
    },
    "changePasswordModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
      "fieldPassOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Old Password:"])},
      "oldPassErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Password is required"])},
      "fieldPassNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter New Password:"])},
      "newPassErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is required"])},
      "errLeast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 10 characters"])},
      "errCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has a capital letter"])},
      "errLower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has a lowercase letter"])},
      "errNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has a number"])},
      "errSpecial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has a special character"])},
      "fieldConfirmPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password:"])},
      "errConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password is required"])},
      "errMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords must match"])},
      "loadingMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitting new Password......"])},
      "successPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password successfully changed."])}
    },
    "manageThirdParties": {
      "selectColumns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select columns"])},
      "availableColumns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Columns"])},
      "selectedCol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Columns"])},
      "fixedCol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed Columns"])},
      "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display Name"])},
      "colWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column Width"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALL"])},
      "filterResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter Result"])},
      "entityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity Type:"])},
      "countryLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "nameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
      "dateLabelFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded Date (From):"])},
      "dateLabelTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded Date (To):"])},
      "iddLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD S&E Results:"])},
      "iddPlusLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD⁺ Alert:"])},
      "iddAlertLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD Alert:"])},
      "eddLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDD Alert:"])},
      "priLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard PRI™:"])},
      "riskLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Risk Rating:"])},
      "questionareLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire:"])},
      "addNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "iddNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD"])},
      "iddPlusPlusNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD++"])},
      "iddPlusNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD+"])},
      "eddNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDD"])},
      "questionareNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire"])},
      "sendTpNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Questionnaire to All TP"])},
      "trainingNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
      "archiveNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
      "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to Search"])},
      "noRiskBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Risk Alert"])},
      "riskBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Alert"])},
      "noRiskRBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Risk Alert(R)"])},
      "riskRBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Alert(R)"])},
      "lowBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
      "lowContentBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low PRI"])},
      "mediumBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
      "mediumContentBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium PRI"])},
      "highBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
      "highContentBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High PRI"])},
      "orderPRI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order PRI"])},
      "noAssociates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Associates Added"])},
      "notStartedStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Started"])},
      "progressStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
      "holdStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On Hold"])},
      "reviewLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
      "completeBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
      "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Required"])},
      "notSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Sent"])},
      "loadingSending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending..."])},
      "riskTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Update My Risk Rating'"])},
      "resultRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing Result From"])},
      "toRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
      "questionare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUESTIONNAIRES"])},
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARCHIVED"])},
      "sendQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to send Questionnaire(s)"])},
      "yourQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sending your Questionnaire(s)......"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading.."])},
      "sentQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire(s) Sent."])},
      "sendTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Questionnaires"])},
      "sendTitleFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Questionnaire For "])},
      "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to send Questionnaires to all Third parties"])},
      "weAreSending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sending your Questionnaires to all Third parties......"])},
      "sentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaires Sent."])},
      "addEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Add Email For"])},
      "thirdPartyFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party First"])},
      "thirdPartyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Email :"])},
      "thirdPirtyplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Third Party Email"])},
      "archieveTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive Third Parties"])},
      "selectedRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to archive the selected record(s)?"])},
      "archiveTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiving your Third Parties......"])},
      "thirdPartyAchieved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Parties Archived."])},
      "iddOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure to Order IDD?"])},
      "iddOrdering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordering IDD For"])},
      "iddOrdered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD Ordered."])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note this functionality is currently not provisioned on your account. Should you be interested in enabling this functionality please contact"])},
      "supportEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support", "@", "ethixbase.com"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Third Parties"])},
      "iddReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order IDD Report"])},
      "iddPlusReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order IDD+ Report<br/>(click to learn more)"])},
      "eddReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order EDD Report<br/>(click to learn more)"])},
      "filterEntityOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
      "filterEntityIndi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
      "filterEddGreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green"])},
      "notOrderedStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
      "filterIddGreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remediated Green"])},
      "filterIddRed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remediated Red"])},
      "filterIddProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remediated In Progress"])},
      "notOrdered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Ordered"])},
      "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Date"])},
      "thirdPartyDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Detail"])},
      "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
      "entityType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity Type"])},
      "associates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associates"])},
      "configPRI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Config PRI"])},
      "questionnaireStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire Status"])},
      "riskRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Risk Rating"])},
      "predictiveRisk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predictive Risk Indicator:"])},
      "reactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure to reactivate Third Parties?"])},
      "reactivating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reactivating your Third Parties......"])},
      "reactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Parties reactivated."])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["text"])},
      "riskScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Score:"])},
      "divisionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divisionName"])},
      "noAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Answer"])},
      "optionsIndustry": {
        "mining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mining"])},
        "manufacturing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manufacturing"])},
        "construction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction"])},
        "wholesale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wholesale Trade"])},
        "retail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retail Trade"])},
        "rlectricity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electricity"])},
        "gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas"])},
        "waterWaste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water and Waste Services"])}
      },
      "n/a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
    },
    "addThirdParty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Third Party"])},
      "entityDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity Details"])},
      "labelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type :"])},
      "radioCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
      "radioIndi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
      "placeholderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity Name"])},
      "placeholderFName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
      "requiredErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Required field has no value."])},
      "placeholderLName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "placeholderCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-- Select Country --"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL :"])},
      "selectErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no selected actions."])},
      "additionalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Details"])},
      "labelAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "labelState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
      "labelZip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal/Zip Code"])},
      "labelContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person"])},
      "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification"])},
      "matchFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is 1 match against this entity name in ROMANIA"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
      "currPages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Page:"])},
      "rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rows"])},
      "matchGlobally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are 4,459 matches against this entity name GLOBALLY"])},
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
      "showMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show me"])},
      "screenAssociates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shall We Screen Associate Entities As Well?"])},
      "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
      "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
      "companyCollapseOne": {
        "fictionCorp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiction Corp"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Number :"])},
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCHD231"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status :"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Type :"])},
        "pvt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Pvt ltd."])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address :"])},
        "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United Kingdom"])},
        "filings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filings :"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis Donec pede justo, fringilla vel, aliquet vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo."])}
      },
      "companyCollapseTwo": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paul Smith (Associate)"])},
        "iddReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Due Dilligence Report"])},
        "iddPlusReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD Plus Report"])},
        "selectRemediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select to remediate"])},
        "sourceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source Name :"])},
        "riskContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Contact"])},
        "entityType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity Type :"])},
        "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])}
      },
      "occur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What actions will occur?"])},
      "subs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Included in subscription"])},
      "abcApri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABC APRI"])},
      "extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What extra actions can I take?"])},
      "edd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDD"])},
      "modern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modern Questionnaire"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions not available"])},
      "enviromental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviromental Questionnaire"])},
      "notAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Available"])},
      "idd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD Report"])},
      "standerd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
      "iddPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD+"])},
      "eddReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDD Report"])},
      "msq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MSQ"])},
      "ddq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDQ"])},
      "labourDdq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LabourDDQ"])},
      "abc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABC"])},
      "pri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRI"])},
      "ddqPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDQ+"])},
      "submitCredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit (1 Credit)"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "items": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coperate Fiction"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romania"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "name1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coperate"])},
        "country1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uk"])},
        "status1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In-Active"])}
      },
      "optionsCredit": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Credit"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use 1 Credit"])}
      }
    },
    "myCompany": {
      "successfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your company details have been saved successfully."])},
      "saveCompanyDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you would like to save your company details?"])},
      "oneMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more filed required"])},
      "pdfToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pdf Downloaded Successfully"])}
    },
    "associateDetailModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Associates Detail"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name :"])},
      "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type :"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country :"])},
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position :"])},
      "enterDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Description"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
      "noIdentifiers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Identifiers Available for this Company Please add Identifiers First"])},
      "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
      "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
      "noNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Notes Available Please add Notes First"])},
      "omg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OGM Alerts"])},
      "addingIdentifiers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Identifiers is being Adding......"])},
      "successfullIdrntifiers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Identifiers has been Added Successfully."])}
    },
    "associateTabComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associates"])},
      "createAssociates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Associates"])},
      "associateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associates Name"])},
      "enterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Associates Name"])},
      "associateType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associates Type"])},
      "associateSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Associates Type"])},
      "associateCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associates Country"])},
      "associatePosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associates Position"])},
      "enterPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Associates Position"])},
      "iddRequire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Require IDD"])},
      "iddPlusRequire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Require IDD Plus"])},
      "enableOgm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable OGM"])},
      "ogm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OGM"])},
      "associateReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associates Report Type"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "creatingAssociates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Associates is being Creating......"])},
      "successfullAssociates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Associates has been added successfully."])},
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])}
    },
    "auditTrailTabComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUDIT"])},
      "activityChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Changes"])},
      "tpComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Comments"])},
      "enterComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Comment..."])},
      "commentAdding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit Comment adding......"])},
      "commentAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit Comment has been added successfully."])}
    },
    "contactsTabComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACTS"])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
      "auditTrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUDIT TRAIL"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Third Party Contact"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Third Party Contact"])},
      "fName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name:"])},
      "enterFName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter First Name"])},
      "lName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name:"])},
      "enterLName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Last Name"])},
      "emailAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
      "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Email Address"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country:"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "isPrimary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Primary Contact"])},
      "tpCreating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Third Party Contact is being Creating......"])},
      "tpUpdating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Third Party Contact is being Updating......"])},
      "tpCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Third Party Contact has been added successfully."])},
      "tpUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Third Party Contact has been updated successfully."])},
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Title"])},
      "enterJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Job Title"])},
      "rsidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Of Residence"])},
      "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Contact"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])}
    },
    "documentsTabComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
      "uploadDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Supporting Document"])},
      "dragDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag and drop to upload content!"])},
      "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...or click to select a file from your computer"])},
      "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Display Name"])},
      "uploadingDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Document is being Uploading......"])},
      "uploadSuccessfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Document has been uploaded successfully"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded Date"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "completeDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Upload is complete"])}
    },
    "dueDiligenceRemediation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Diligence Reports"])},
      "initialReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intial Report"])},
      "remediation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remediation"])},
      "idd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD"])},
      "iddPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD+"])},
      "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative"])},
      "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive"])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
      "n/a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
      "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
      "eddReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhanced Due Diligence Reports"])},
      "addIdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Instant Due Diligence Reports"])},
      "addModal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Modal"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Date"])},
      "riskStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Status"])},
      "completedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed Date"])}
    },
    "monitoringTabComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing Monitoring Results"])},
      "remediatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remediated By"])},
      "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts"])},
      "noAlerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Alerts"])}
    },
    "commsComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comms"])},
      "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communications"])},
      "sentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent Date"])}
    },
    "policiesComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policies"])},
      "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy Attestations"])},
      "requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested Date"])},
      "completedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed Date"])},
      "statusLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status & Link"])}
    },
    "questionnaireComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaires"])},
      "questionnaireDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire Detail"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data Available"])},
      "hideDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Details"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reissue"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
      "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Required"])},
      "assigning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigning..."])},
      "requesting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are requesting to send the"])},
      "primaryContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the primary contact at this supplier."])},
      "thisIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is:"])},
      "primaryContactName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Contact Name:"])},
      "primaryContactEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Contact Email:"])},
      "pleaseNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note this questionnaire is 'In Progress'. You may resend the questionnaire to the primary contact above, however, please note that any information that has already been entered within this 'In Progress' questionnaire will be deleted as part of this action. This cannot be undone. Please click 'Send' to confirm you wish to proceed."])},
      "titles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])}
    },
    "questionnairePoliciesTrainingTabComponent": {
      "questionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUESTIONNAIRES"])},
      "policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POLICIES"])},
      "traning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRAINING"])},
      "comms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMMS"])}
    },
    "trainingComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])}
    },
    "antiBriberiyPredictiveRisk": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historic Predictive Risk Indicators"])},
      "onlineSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An online search of both initial and drill down results for XX key terms most often associated with Anti-Bribery & Corruption has resulted in:"])},
      "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chart"])},
      "resultsInConjunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results in conjunction with [Third Party Name]. This is predicative of a [low] risk indicator for Anti-Bribery & Corruption."])},
      "noteThatHistoric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that the Anti-Bribery & Corruption Historic Predictive Risk Indicator indicates only the potential for risk. It should not be used in isolation and should be used in combination with your organisation's own risk assessment, procedures and policies."])},
      "futurePredictive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future Predictive Risk Benchmarks"])},
      "aSearchOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A search of [Third Party Name]’s domain has revealed the bellow results in conjunction with visibility of and preparedness for Anti-Bribery & Corruption Compliance."])},
      "bribery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bribery & Corruption"])},
      "political": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Political Exposure"])},
      "fruad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraud"])},
      "noteThatFuture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that the Anti-Bribery & Corruption Future Predicative Risk Benchmarks indicate only the prevalence of material related to ta risk area. This is not a comprehensive policy review. Should you wish for such a service please contact us to discuss."])}
    },
    "enviormentalPredictiveRisk": {
      "onlineSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An online search of both initial and drill down results for XX key terms most often associated with Environmental Non-compliance n has resulted in:"])},
      "xxx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XXX"])},
      "resultsInConjunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results in conjunction with [Third Party Name]. This is predicative of a [high] risk indicator for Environmental Risk."])},
      "noteThatHistoric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that the Environmental Historic Predictive Risk Indicator indicates only the potential for risk. It should not be used in isolation and should be used in combination with your organisation's own risk assessment, procedures and policies."])},
      "futurePredictive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future Predictive Risk Benchmarks"])},
      "aSearchOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A search of [Third Party Name]’s domain has revealed the bellow results in conjunction with visibility of and preparedness for Environmental Compliance."])},
      "enviromental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environmental Practices"])},
      "noteThatFuture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that the Environmental Future Predicative Risk Benchmarks indicate only the prevalence of material related to ta risk area. This is not a comprehensive policy review. Should you wish for such a service please contact us to discuss."])}
    },
    "humanRightsPredictiveRisk": {
      "onlineSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An online search of both initial and drill down results for XX key terms most often associated with Human Rights violations has resulted in:"])},
      "xx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XX"])},
      "resultsInConjunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results in conjunction with [Third Party Name]. This is predicative of a [] risk indicator for Human Rights."])},
      "noteThatHistoric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that the Human Rights Historic Predictive Risk Indicator indicates only the potential for risk. It should not be used in isolation and should be used in combination with your organisation's own risk assessment, procedures and policies."])},
      "futurePredictive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future Predictive Risk Benchmarks"])},
      "aSearchOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A search of [Third Party Name]’s domain has revealed the bellow results in conjunction with visibility of and preparedness for Human Rights Compliance."])},
      "humanRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Rights"])},
      "modernSlavery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modern Slavery"])},
      "noteThatFuture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that the Human Rights Future Predicative Risk Benchmarks indicate only the prevalence of material related to ta risk area. This is not a comprehensive policy review. Should you wish for such a service please contact us to discuss."])}
    },
    "labourPredictiveRisk": {
      "notInScope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOT IN SCOPE. Please Contact Us should you wish to discuss provisioning this service on your account."])},
      "futurePredictive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future Predictive Risk Benchmarks"])},
      "labourPractices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labour Practices"])},
      "healthSafety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health & Safety"])}
    },
    "overallPredictiveRisk": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic PRI"])},
      "advance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced Algorithmic PRI"])},
      "accountName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Account Name] Configured PRI"])},
      "entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTITY"])},
      "sanctions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SANCTIONS"])},
      "bribery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bribery & Corruption"])},
      "historic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HISTORIC"])},
      "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FUTURE"])},
      "humanRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Rights"])},
      "labour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labour"])},
      "environmental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environmental"])},
      "nameAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Analysis & Confidence Score"])},
      "falsePositive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FALSE POSITIVE PROBABILITY CALCULATOR"])},
      "companyScope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPANY SCOPE"])},
      "nameFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Frequency in Country"])},
      "nearExact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEAR EXACT"])},
      "partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PARTIAL"])},
      "nameFrequencyGlobal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Frequency Global"])},
      "falsePositiveAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FALSE POSITIVE ASSESSMENT"])},
      "commonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common Name"])},
      "dueToPrevalence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due to prevalence of name [locally] [and/or] [globally] it is probable that false positives exists within the historic risk indicator score"])}
    },
    "predictiveRisk": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configured PRI"])},
      "riskIndicators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Risk by Configured Risk Indicators"])},
      "dealValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deal Value = $30M"])},
      "longevity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longevity of Relationship=< 1yr"])},
      "tpReseller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Type = Reseller"])},
      "tpInventive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Inventive = 30%"])},
      "sanctionsPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanctions PRI"])},
      "potentialSanctions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a potential sanctions match at 95% match score or above. We encourage you to take additional steps to confirm the Third Parties risk status."])},
      "scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scope:"])},
      "listSanctions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Sanctions Lists searched. Suggest no more than 5 – 10 so as not to impinge on IDD service"])},
      "pleaseNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that this is NOT a comprehensive sanctions screen against the full sanctions database (or other services such as negative media and political exposure). Nor has this third party been enabled for monitoring."])},
      "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended Next Steps:"])},
      "toEnable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To enable monitoring please order an Instant Due Diligence (IDD), Instant Due Diligence PLUS (IDD+) (Best Practice) or Enhanced Due Diligence (EDD) report should further scrutiny be warranted for this third party. Remediation services can also be performed to provide assurance on the match."])}
    },
    "riskOverview": {
      "perceivedRisk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perceived Risk Indicators"])},
      "basicPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic PRI"])},
      "configuredPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configured PRI"])},
      "antiCorruptionPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Corruption PRI"])},
      "humanRightsPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Rights PRI"])},
      "labourPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labour PRI"])},
      "environmentalPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environmental PRI"])},
      "ddResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Diligence Results"])},
      "iddSe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Due Diligence (S&E)"])},
      "iddPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Due Diligence PLUS"])},
      "edd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhanced Due Diligence"])},
      "execSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exec Summary Analysis"])},
      "policyAttestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire & Policy Attestation Status"])},
      "ddQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Diligence Questionnaire"])},
      "modernSlavery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modern Slavery Questionnaire (MSQ)"])},
      "codeOfConduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code of Conduct Attestation"])},
      "antiCorruption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Corruption Policy"])},
      "trainingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Status"])},
      "antiBribery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Bribery & Corruption"])}
    },
    "riskSummary": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RISK OVERVIEW"])},
      "configured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONFIGURED PRI™"])},
      "antiCorruption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANTI-CORRUPTION PRI™"])},
      "humanRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUMAN RIGHTS PRI™"])},
      "labour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LABOUR PRI™"])},
      "environmental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENVIRONMENTAL PRI™"])}
    },
    "tpDetailsAssocTab": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETAILS"])},
      "lowerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])},
      "tpName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Name"])},
      "uploadedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded Date"])},
      "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
      "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode/Zip Code"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "duns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DUNS Number"])},
      "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Number"])},
      "contactDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTRACT DETAILS"])},
      "contactDetailsLower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Details"])},
      "validForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Valid From"])},
      "contractExpires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Expires"])},
      "contractNetValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Net Value"])},
      "contractCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Currency"])},
      "onboardDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onboard Date"])},
      "customAttribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUSTOM ATTRIBUTES"])},
      "customAttributeLower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Attributes"])},
      "wordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
      "customAttributesExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Attributes does not exist for this company."])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACTS"])},
      "associates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASSOCIATES"])},
      "selfReported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SELF REPORTED DATA"])},
      "selfReportedLower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self Reported Data"])},
      "questionnaireData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire data does not exist for this company."])}
    },
    "footerComponent": {
      "orderIddPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORDER IDD+"])},
      "orderEdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORDER EDD"])},
      "sendQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEND QUESTIONNAIRE"])},
      "sendPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEND POLICY"])},
      "sendTrain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEND TRAIN"])},
      "sendQuestionnaireFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Questionnaire For "])},
      "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to send Questionnaire"])},
      "weAreSending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sending your Questionnaire......"])},
      "questionnaireSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire Sent."])},
      "addEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Add Email For"])},
      "tpFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party First"])},
      "tpEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Email"])},
      "enterTpEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Third Party Email"])}
    },
    "riskSummeryTab": {
      "pritm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predictive Risk Indicators (PRITM)"])},
      "entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity (inc. Sanctions)"])},
      "ddResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Diligence Results"])},
      "iddSe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Due Diligence (S&E)"])},
      "edd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhanced Due Diligence"])},
      "exec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exec Summary Analysis"])},
      "policyAttestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire & Policy Attestation Status"])},
      "ddq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Diligence Questionnaire"])},
      "msq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modern Slavery Questionnaire"])},
      "codeOfConduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code of Conduct Attestation"])},
      "antiCorruption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Corruption Policy"])},
      "trainingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Status"])},
      "antiBribery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Bribery & Corruption"])}
    }
  },
  "colors": {
    "green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green"])},
    "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red"])},
    "amber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amber"])},
    "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black"])},
    "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White"])}
  },
  "wordNumber": {
    "zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zero"])},
    "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One"])},
    "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two"])},
    "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Three"])},
    "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Four"])},
    "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Five"])},
    "six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Six"])},
    "seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seven"])},
    "eight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eight"])},
    "nine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nine"])},
    "ten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten"])}
  },
  "languages": {
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
    "bulgarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarian"])},
    "burmese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burmese"])},
    "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
    "chinesetw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Taiwan"])},
    "chinesesm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Simplified"])},
    "chinesetr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Traditional"])},
    "croatian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatian"])},
    "czech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech"])},
    "dutch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "greek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greek"])},
    "gujarati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gujarati"])},
    "haitian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haitian Creole"])},
    "hebrew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebrew"])},
    "hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
    "hungarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungarian"])},
    "indonesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesian"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
    "kannada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kannada"])},
    "khmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khmer"])},
    "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
    "laos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "marathi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marathi"])},
    "polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
    "portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
    "portuguesebr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Brazilian"])},
    "portuguesemz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Mozambique"])},
    "portuguesepo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese Portugal"])},
    "romanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romanian"])},
    "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
    "serbian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbian"])},
    "sinhalese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinhalese"])},
    "slovak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovak"])},
    "slovenian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenian"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
    "spanishmx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish Mexican"])},
    "swedish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
    "tamil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamil"])},
    "telugu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telugu"])},
    "thai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai"])},
    "turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "ukrainian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrainian"])},
    "vietnamese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamese"])}
  },
  "validations": {
    "invalidFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["格式无效"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件无效"])},
    "wrongPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前密码无效。"])}
  },
  "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])},
  "savingProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存..."])},
  "startBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始"])},
  "saveBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "continueBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
  "cancelBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "closeBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
  "okBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
  "deleteBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
  "editBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
  "submitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交"])},
  "saveProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存进度"])},
  "loginBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
  "logoutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
  "signInBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
  "signOutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出"])},
  "updateBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新资料"])},
  "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人资料"])},
  "annual-certifications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年度认证"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["认证多次培训"])}
  }
}