<template>
  <div>
    <BaseText text="Training" tag="h2" :size="typographySize.HEADING_TWO" />
    <ThirdPartyTrainingList
      :third-party-id="thirdPartyId"
      @click="handleChangeView"
    />
  </div>
</template>

<script>
import ThirdPartyTrainingList from "@/organisms/ThirdPartyTrainingList/ThirdPartyTrainingList";
import { mapState } from "vuex";
import BaseText from "@/atoms/BaseText/BaseText";
import { sizes, typographySize, urls } from "@/constants";

export default {
  name: "UserTraining",
  components: { BaseText, ThirdPartyTrainingList },
  data() {
    return {
      sizes,
      typographySize
    };
  },
  computed: {
    ...mapState({
      thirdPartyId: (state) => state.user.thirdPartyId
    })
  },
  methods: {
    handleChangeView({ selectedCourse }) {
      this.$router.push(urls.TRAINING_DETAILS(selectedCourse?.id));
    }
  }
};
</script>
