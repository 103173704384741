<template>
  <div>
    <b-navbar toggleable="lg" class="text-left headerClass" type="light">
      <b-navbar-brand>
        <span @click="sentHomePage">
          <BaseImage
            class="main-header-logo"
            :src="getPlatformLogo"
            height="75px"
          />
        </span>
      </b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-navbar-brand style="margin-top: 25px">
          <div class="d-flex" style="margin-right: 80px">
            <div class="d-block">
              <div
                class="m-0 p-0"
                style="
                  margin-left: 5px !important;
                  min-width: 100px;
                  transform: translateY(-10px);
                "
              >
                <span
                  style="font-size: 17px"
                  class="headerTextColor"
                  data-test-id="main-header__username"
                >
                  {{ fullName }}
                </span>
              </div>
              <div
                class="d-flex headerTextColor"
                style="margin-left: 5px !important"
              >
                <div
                  class="m-0 p-0"
                  style="min-width: 100px; transform: translateY(-10px)"
                >
                  <span style="font-size: 17px">{{ companyName }}</span>
                </div>
                <div
                  class="m-0 p-0"
                  style="
                    margin-left: 5px !important;
                    min-width: 100px;
                    transform: translateY(-10px);
                  "
                >
                  <span style="font-size: 14px">
                    Version {{ platformVersion }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </b-navbar-brand>
        <b-nav-item-dropdown
          v-if="hasAddNewViewsPermission || hasAddThirdPartiesPermission"
          right
          toggle-class="header-dropdown"
          class="header-dropdown-outline"
          style="margin-top: 25px"
          data-test-id="main-header__button--add"
        >
          <template v-slot:button-content>
            <em class="headerTextColor">
              <font-awesome-icon style="font-size: x-large" icon="plus" />
            </em>
          </template>
          <b-dropdown-item
            v-if="hasAddThirdPartiesPermission"
            @click="showAddThirdPartyModal"
            data-test-id="main-header__dropdown-button--entity"
          >
            Entity(Third Party)
          </b-dropdown-item>
          <b-dropdown-item
            v-if="hasAddNewViewsPermission"
            @click="showAddNewViewModal"
            data-test-id="main-header__dropdown-button--view"
          >
            View
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          right
          toggle-class="header-dropdown"
          class="header-dropdown-outline"
          style="margin-top: 20px"
          data-test-id="main-header__button--hamburger"
        >
          <template v-slot:button-content>
            <em class="headerTextColor">
              <font-awesome-icon style="font-size: xx-large" icon="bars" />
            </em>
          </template>
          <div v-if="companyList.length !== 1" class="dropdown-submenu">
            <b-dropdown-item
              v-if="!isUserThirdParty"
              data-test-id="main-header__dropdown-button--platforms"
            >
              Platforms
            </b-dropdown-item>
            <ul
              class="
                dropdown-menu dropdown-menu-right
                header__dropdown-sub-menu
              "
            >
              <li
                v-for="({ companyName, id }, index) in companyList"
                :key="index"
                class="border-acccount-platform-brand text-nowrap hover-class"
              >
                <p
                  @click="setNewCompany(id, companyName)"
                  style="margin: 0 15px"
                  :data-test-id="getCompanyDataTestId(companyName)"
                >
                  {{ companyName }}
                </p>
              </li>
            </ul>
            <b-dropdown-divider></b-dropdown-divider>
          </div>
          <b-dropdown-item
            v-if="platformReleaseNoteLink"
            @click="openTab(platformReleaseNoteLink)"
            data-test-id="main-header__dropdown-button--release-notes"
          >
            Latest Release Notes
          </b-dropdown-item>
          <b-dropdown-item
            v-if="platformKnowledgeCentreLink"
            @click="openTab(platformKnowledgeCentreLink)"
          >
            Knowledge Centre
          </b-dropdown-item>
          <b-dropdown-item
            v-if="platformRequestSupportLink"
            data-test-id="main-header__dropdown-button--support"
            @click="openTab(platformRequestSupportLink)"
          >
            Request Support
          </b-dropdown-item>
          <b-dropdown-item
            v-if="isLoggedInViaThePlatform"
            data-test-id="main-header__dropdown-button--change-password"
            @click="showChangePassword"
          >
            Change Password
          </b-dropdown-item>
          <b-dropdown-item
            @click="onLogOut"
            data-test-id="main-header__dropdown-button--logout"
          >
            {{ $t("signOutBtnText") }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
    <b-modal
      v-model="showChangeAccountModal"
      size="lg"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      title="Switch Company"
    >
      <b-overlay>
        <div
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-left p-3"
        >
          <p>
            Are you sure you want to switch company from
            <strong id="form-confirm-label"> {{ companyName }}</strong>
            to
            <strong id="form-confirm-label">{{ selectedCompanyName }}</strong>
            ?
          </p>
        </div>
      </b-overlay>
      <template v-slot:modal-footer>
        <b-button
          size="md"
          variant="outline-danger"
          class="mr-3"
          @click="hideChangeAccountModal"
        >
          No
        </b-button>
        <b-button size="md" variant="outline-success" @click="onCompanyChange">
          Yes
        </b-button>
      </template>
    </b-modal>

    <RequestSupportModal />
    <ChangePasswordModal />
    <AddModal
      v-if="isThirdPartyAddModalVisible"
      :companyId="parseInt(companyId)"
    />
    <AddNewThirdPartyModal v-if="isAddThirdPartyModalNewVisible" />
    <AddNewViewModal />
    <InputErrorMessage :error="errorMessage" />
  </div>
</template>
<script>
import { EventBus } from "@/event-bus.js";
import ChangePasswordModal from "@/organisms/ChangePassword/ChangePasswordModal";
import RequestSupportModal from "@/components/requestSupportModal";
import AddModal from "@/components/eb2/manageThirdParty/addModal";
import AddNewThirdPartyModal from "@/components/eb2/manageThirdParty/addThirdPartyModalNew";
import AddNewViewModal from "@/components/eb2/manageThirdParty/addNewViewModal";
import { HTTP, makeAuthorizationHeader, hasPermission } from "@/utils";
import {
  actionName,
  getterName,
  httpStatuses,
  localStorageItem,
  mutationName,
  urls,
  userPassword,
  featurePermissionsKeys
} from "@/constants";
import defaultActionJson from "@/utils/defaultAction.json";
import BaseImage from "@/atoms/BaseImage/BaseImage";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { Helper } from "@/helpers";

export default {
  name: "AppEb2Header",
  components: {
    RequestSupportModal,
    ChangePasswordModal,
    AddModal,
    AddNewThirdPartyModal,
    AddNewViewModal,
    BaseImage,
    InputErrorMessage
  },
  inject: ["$posthog"],
  data() {
    return {
      logo: {
        width: "auto",
        height: "50px"
      },
      logoSolo: {
        width: 35
      },
      formDefaultLanguage: "english",
      selectedCompanyName: "",
      errorMessage: [],
      updatePassword: null,
      showChangeAccountModal: false,
      selectedCompanyId: null,
      questionnaires: []
    };
  },
  created() {
    this.resetErrorMessage();
    this.fetchAdditionalVouchersWrapper();
    this.fetchCompaniesWrapper();
    this.fetchCountries();
    this.updatePassword = localStorage.getItem(
      localStorageItem.UPDATED_PASSWORD
    );
    this.checkUpdatePassword();
    this.setLanguagesToStore();
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId,
      platformVersion: (state) => state.user.platformVersion,
      triggerFetchAdditionalVouchers: (state) =>
        state.company.triggerFetchAdditionalVouchers,
      companyName: (state) => state.company.companyName,
      companyList: (state) => state.company.companyList,
      passwordCreated: (state) => state.user.passwordCreated,
      platformMainHeaderLogo: (state) =>
        state.platformSettings.platformMainHeaderLogo,
      platformReleaseNoteLink: (state) =>
        state.platformSettings.platformReleaseNoteLink,
      platformRequestSupportLink: (state) =>
        state.platformSettings.platformRequestSupportLink,
      platformKnowledgeCentreLink: (state) =>
        state.platformSettings.platformKnowledgeCentreLink,
      companyDetails: (state) => state.company.companyDetails,
      isThirdPartyAddModalVisible: (state) =>
        state.thirdParty.isThirdPartyAddModalVisible,
      isAddThirdPartyModalNewVisible: (state) =>
        state.thirdParty.isAddThirdPartyModalNewVisible,
      defaultProperties: (state) => state.posthog.defaultProperties
    }),
    ...mapGetters({
      isLoggedInViaThePlatform: getterName.USER.IS_LOGGED_IN_VIA_THE_PLATFORM,
      getCompanyName: getterName.USER.GET_COMPANY_NAME,
      fullName: getterName.USER.GET_FULL_NAME,
      isUserThirdParty: getterName.USER.IS_USER_THIRD_PARTY,
      permittedLandingPage: getterName.USER.PERMITTED_LANDING_PAGE
    }),
    getPlatformLogo() {
      return this.platformMainHeaderLogo || "";
    },
    hasAddThirdPartiesPermission() {
      return hasPermission({ key: featurePermissionsKeys.ADD_THIRD_PARTIES });
    },
    hasAddNewViewsPermission() {
      return hasPermission({
        key: featurePermissionsKeys.CREATE_AND_REMOVE_USER_VIEWS
      });
    }
  },
  watch: {
    triggerFetchAdditionalVouchers(newValue) {
      if (newValue) {
        this.resetErrorMessage();
        this.fetchAdditionalVouchersWrapper();
        this.setTriggerFetchAdditionalVouchers(false);
      }
    }
  },
  methods: {
    ...mapMutations({
      setPasswordCreated: mutationName.USER.SET_PASSWORD_CREATED,
      setTriggerFetchAdditionalVouchers:
        mutationName.COMPANY.SET_TRIGGER_FETCH_ADDITIONAL_VOUCHERS,
      setCountries: mutationName.COUNTRY.SET_COUNTRIES,
      setIsThirdPartyAddModalVisible:
        mutationName.THIRD_PARTY.SET_IS_THIRD_PARTY_ADD_MODAL_VISIBLE
    }),
    ...mapActions({
      logOut: actionName.AUTH.LOG_OUT,
      fetchAdditionalVouchers: actionName.COMPANY.FETCH_ADDITIONAL_VOUCHERS,
      selectCompany: actionName.COMPANY.SELECT_COMPANY,
      fetchCompanies: actionName.COMPANY.FETCH_COMPANIES,
      fetchFormsLanguagesList:
        actionName.THIRD_PARTY.FETCH_FORMS_LANGUAGES_LIST,
      fetchCompanyFeatures: actionName.COMPANY.FETCH_COMPANY_FEATURES,
      fetchPlatformUsersList:
        actionName.PLATFORM_SETTINGS.FETCH_PLATFORM_USER_LIST
    }),
    async onLogOut() {
      await this.logOut(true);
    },
    async fetchCountries() {
      try {
        let { status, data } = await HTTP(
          "get",
          "v1/countries",
          null,
          makeAuthorizationHeader()
        );
        if (status === 200) {
          this.setCountries(data.countries);
        }
      } catch ({ status }) {
        const description = status
          ? `There was an error with status ${status}.`
          : "";
        this.errorMessage.push(
          `Failed to retrieve the country list. ${description}`
        );
      }
    },
    getCompanyDataTestId(companyName) {
      return `main-header__platform-list--${
        companyName?.replace(/ /g, "-")?.toLowerCase() || "no-name"
      }`;
    },
    showAddNewViewModal() {
      EventBus.emit("addNewViewModal", "");
    },
    openTab(url) {
      window.open(url, "_blank").focus();
    },
    showAddThirdPartyModal() {
      this.setIsThirdPartyAddModalVisible(true);
    },
    async fetchAdditionalVouchersWrapper(companyId = "") {
      try {
        await this.fetchAdditionalVouchers(companyId);
        this.setActionSchema();
      } catch {
        this.errorMessage.push(
          "Something went wrong, please try again. If the issue persists please contact support."
        );
      }
    },
    setActionSchema() {
      let actionsData = this.companyDetails.additionalData;

      let actionsSchema = {};
      if (actionsData) {
        let allActions = [];
        allActions.push(...defaultActionJson.extraActions);
        allActions.push(...defaultActionJson.actionsOccur);
        allActions.push(...defaultActionJson.actionsNotAvailable);
        if (actionsData.extraActions && actionsData.extraActions.length != 0) {
          actionsData.extraActions.map((items, index) => {
            if (items.title == undefined) {
              let extraObjectIndex = allActions.findIndex(
                (x) => x.id == items.id
              );
              actionsData.extraActions[index] = allActions[extraObjectIndex];
            }
          });
        }
        if (actionsData.actionsOccur && actionsData.actionsOccur.length != 0) {
          actionsData.actionsOccur.map((items, index) => {
            if (items.title == undefined) {
              let extraObjectIndex = allActions.findIndex(
                (x) => x.id == items.id
              );
              actionsData.actionsOccur[index] = allActions[extraObjectIndex];
            }
          });
        }
        if (
          actionsData.actionsNotAvailable &&
          actionsData.actionsNotAvailable.length != 0
        ) {
          actionsData.actionsNotAvailable.map((items, index) => {
            if (items.title == undefined) {
              let extraObjectIndex = allActions.findIndex(
                (x) => x.id == items.id
              );
              actionsData.actionsNotAvailable[index] =
                allActions[extraObjectIndex];
            }
          });
        }

        if (!actionsData.extraActions) {
          actionsData.extraActions = defaultActionJson.extraActions;
        }
        if (!actionsData.actionsNotAvailable) {
          actionsData.actionsNotAvailable =
            defaultActionJson.actionsNotAvailable;
        }
        if (!actionsData.actionsOccur) {
          actionsData.actionsOccur = defaultActionJson.actionsOccur;
        }
        actionsSchema = actionsData;
        localStorage.setItem(
          localStorageItem.ACTIONS_SCHEMA,
          JSON.stringify(actionsSchema)
        );
        let actionsSidebarSchema = localStorage.getItem(
          localStorageItem.ACTIONS_SCHEMA
        );
        let parsedSidebarSchema = JSON.parse(actionsSidebarSchema);
        this.$store.state.newSidebarData = parsedSidebarSchema;
      } else {
        actionsSchema = {};
        actionsSchema.extraActions = defaultActionJson.extraActions;
        actionsSchema.actionsNotAvailable =
          defaultActionJson.actionsNotAvailable;
        actionsSchema.actionsOccur = defaultActionJson.actionsOccur;
        localStorage.setItem(
          localStorageItem.ACTIONS_SCHEMA,
          JSON.stringify(actionsSchema)
        );
        let actionsSidebarSchema = localStorage.getItem(
          localStorageItem.ACTIONS_SCHEMA
        );
        let parsedSidebarSchema = JSON.parse(actionsSidebarSchema);
        this.$store.state.newSidebarData = parsedSidebarSchema;
      }

      this.companyDetails.onboardingForms.map((onboardingForm) => {
        const actionsOccurIndex = actionsSchema.actionsOccur.findIndex(
          ({ btnText }) => btnText === onboardingForm.btnText
        );
        if (actionsOccurIndex !== -1) {
          actionsSchema.actionsOccur[actionsOccurIndex] = onboardingForm;
        }

        const extraActionsIndex = actionsSchema.extraActions.findIndex(
          ({ btnText }) => btnText === onboardingForm.btnText
        );
        if (extraActionsIndex !== -1) {
          actionsSchema.extraActions[extraActionsIndex] = onboardingForm;
        }

        const actionsNotAvailableIndex =
          actionsSchema.actionsNotAvailable.findIndex(
            ({ btnText }) => btnText === onboardingForm.btnText
          );
        if (actionsNotAvailableIndex != -1) {
          actionsSchema.actionsNotAvailable[actionsNotAvailableIndex] =
            onboardingForm;
        }
      });

      localStorage.setItem(
        localStorageItem.ACTIONS_SCHEMA,
        JSON.stringify(actionsSchema)
      );
    },
    async getQuestionnariesData() {
      try {
        let result = await HTTP(
          "get",
          `v1/getactiveformslist/${this.companyId}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.data) {
          result.data.questionnaires.map((items) => {
            this.questionnaires.push(items);
          });
        }
      } catch ({ status }) {
        Helper.makeToast(
          this.$bvToast,
          "danger",
          `There is an unknown error with status of ${status}. Please contact support`
        );
      }
    },
    setLanguagesToStore() {
      let languageInLocalStore =
        localStorage.getItem(localStorageItem.FORM_DEFAULT_LANGUAGE) ||
        "english";
      if (languageInLocalStore == "chinese simplified") {
        this.$i18n.locale = "chinese";
      } else if (languageInLocalStore == "chinese traditional taiwan") {
        this.$i18n.locale = "chinesetr";
      } else if (languageInLocalStore == "bahasa indonesia") {
        this.$i18n.locale = "bahasaIndonesia";
      } else {
        this.$i18n.locale = languageInLocalStore;
      }
      this.$store.state.form1.formDefaultLanguage = languageInLocalStore;
      this.$store.state.selectedLanguage = languageInLocalStore;
      this.formDefaultLanguage = languageInLocalStore;
    },
    defaultLanguageText(lang) {
      let ll = lang.toLowerCase();
      if (ll == "chinese simplified") {
        return this.$t("languages.chinese");
      } else if (ll == "chinese traditional taiwan") {
        return this.$t("languages.chinesetr");
      } else if (ll == "bahasa indonesia") {
        return this.$t("bahasaIndonesia");
      } else {
        return this.$i18n.availableLocales.includes(ll)
          ? this.$t(`languages."+${ll}`)
          : ll;
      }
    },
    onUpdateLanguage(lang) {
      let yy = lang.toLowerCase();
      if (yy == "chinese simplified") {
        lang = "chinese";
      }
      if (yy == "chinese traditional taiwan") {
        lang = "chinesetr";
      }
      if (yy == "bahasa indonesia") {
        lang = "bahasaIndonesia";
      }
      let ll = lang.toLowerCase();
      let ff = this.$i18n.availableLocales.includes(ll); // checking selected languages with saved messages
      this.$i18n.locale = ff ? ll : "english";
      this.$store.state.form1.formDefaultLanguage = yy;
      this.$store.state.selectedLanguage = yy;
      localStorage.setItem(localStorageItem.FORM_DEFAULT_LANGUAGE, yy);
      this.formDefaultLanguage = ff ? ll : "english";
      if (ll == "arabic" || ll == "hebrew") {
        this.$store.state.rightAlign = true;
      } else {
        this.$store.state.rightAlign = false;
      }
    },
    async fetchCompaniesWrapper() {
      try {
        const { data = {}, status = "" } = await this.fetchCompanies();
        if (data.fakeHttpStatus === httpStatuses.FAIL) {
          this.fetchCompaniesFailure({ status });
        }
      } catch {
        this.fetchCompaniesFailure();
      }
    },
    fetchCompaniesFailure() {
      this.errorMessage.push("Failed to receive the available platforms.");
    },
    resetErrorMessage() {
      this.errorMessage = [];
    },
    setNewCompany(companyId, companyName) {
      this.selectedCompanyId = companyId;
      this.selectedCompanyName = companyName;
      this.showChangeAccountModal = true;
    },
    async onCompanyChange() {
      this.resetErrorMessage();
      this.hideChangeAccountModal();
      await this.selectCompanyWrapper();
      await Promise.allSettled([
        this.fetchAdditionalVouchersWrapper(this.selectedCompanyId),
        this.fetchFormsLanguagesList({ companyId: this.selectedCompanyId }),
        this.fetchCompanyFeatures(),
        this.fetchPlatformUsersList()
      ]);
      this.$router.push(urls.MANAGE_THIRD_PARTY);
    },
    selectCompanySuccess() {
      localStorage.removeItem(localStorageItem.BULK_ACTIONS);
      this.activeCompanyName = this.companyName;
      this.$posthog.register(this.defaultProperties);
    },
    async selectCompanyWrapper() {
      try {
        await this.selectCompany(this.selectedCompanyId);
        this.selectCompanySuccess();
      } catch {
        this.errorMessage.push("Failed to set the new platform.");
      }
    },
    hideChangeAccountModal() {
      this.showChangeAccountModal = false;
    },
    checkUpdatePassword() {
      if (this.passwordCreated == 2 && this.updatePassword == 0) {
        this.showChangePassword();
      }
    },
    sentHomePage() {
      this.$router.push(this.permittedLandingPage);
    },
    showRequestModal() {
      EventBus.emit("onShowRequestSupportModal", "");
    },
    showChangePassword() {
      this.setPasswordCreated(userPassword.RESET_PASSWORD);
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/custom.scss";

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  height: 490px !important;
}
.videoModalBody {
  height: 550px;
}
.capitalize {
  text-transform: capitalize;
}
.dropdown-item {
  color: $jet !important;
}
.header-dropdown-outline {
  outline: 0;
}
.header-dropdown {
  outline: 0;
}
.header-dropdown::after {
  margin-left: 25px;
  transform: translate3d(10rem, -1.7rem, 0);
}
.header-dropdown .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0em solid !important;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: $chinese-silver;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-submenu:hover > a:after {
  border-left-color: $white;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.header__dropdown-sub-menu {
  height: 200px;
  overflow-y: auto;
  right: 100% !important;
  top: -11px !important;
}
.main-header-logo {
  cursor: pointer;
  padding: 10px;
}
</style>
