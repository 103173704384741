import { routeAccessRules, routeAccessMapper } from "@/constants";
import { mapObject } from "@/utils";

export const getters = {
  appRoutePermissions: (state, __, rootState) => {
    const permissionIds = state.permissions?.map(
      (permission) => permission?.id
    );
    const defaultRoutePermissions = mapObject({
      object: routeAccessRules,
      callback: (key) =>
        rootState.user.roles.some((userRole) =>
          routeAccessRules[key].includes(userRole?.id)
        )
    });
    const clientSetPermissions = mapObject({
      object: routeAccessMapper,
      callback: (key) => permissionIds.includes(routeAccessMapper[key])
    });

    return {
      ...defaultRoutePermissions,
      ...clientSetPermissions
    };
  }
};
