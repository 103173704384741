import { actions } from "./onboardingTasks.actions.store";
import { mutations } from "./onboardingTasks.mutations.store";
import { state } from "./onboardingTasks.state.store";

export default {
  namespaced: true,
  actions,
  mutations,
  state
};
