<template>
  <td
    class="full-width-table-row"
    :style="styles"
    data-test-id="full-width-table-row"
  >
    <component v-if="component" :is="component" v-bind="componentOptions" />
  </td>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";

export default {
  name: "FullWidthTableRow",
  components: {
    BaseText
  },
  props: {
    component: {
      type: Object,
      default: () => ({})
    },
    componentOptions: {
      type: Object,
      default: () => ({})
    },
    styles: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss" scoped>
.full-width-table-row {
  background-color: $grey-light;
}
</style>
