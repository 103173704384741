import { languagesKeysDTO, languagesAPIDTO } from "@/constants";

const makeLanguagesDTO = ({ languages = [] }) =>
  languages?.map?.((language) => ({
    [languagesKeysDTO.ID]: language[languagesAPIDTO.ID],
    [languagesKeysDTO.LANGUAGE_CODE]: language[languagesAPIDTO.LANGUAGE_CODE],
    [languagesKeysDTO.TEXT]: language[languagesAPIDTO.TEXT],
    [languagesKeysDTO.VALUE]: language[languagesAPIDTO.VALUE]
  })) || [];

export { makeLanguagesDTO };
