import { tasksFilterKeysDTO } from "@/constants/keys-constant/tasks.keys.constant";

const taskFilterOptions = [
  { text: "assignee", value: tasksFilterKeysDTO.ASSIGNEE },
  { text: "edit permissions", value: tasksFilterKeysDTO.EDIT_PERMISSIONS },
  { text: "task template name", value: tasksFilterKeysDTO.TEMPLATE_ID },
  { text: "id", value: tasksFilterKeysDTO.COMPANY_TASK_ID },
  { text: "status", value: tasksFilterKeysDTO.STATUS },
  { text: "subject", value: tasksFilterKeysDTO.SUBJECT },
  { text: "description", value: tasksFilterKeysDTO.DESCRIPTION },
  {
    text: "subject or description",
    value: tasksFilterKeysDTO.SUBJECT_OR_DESCRIPTION
  },
  {
    text: "created date on or after",
    value: tasksFilterKeysDTO.CREATED_DATE_AFTER
  },
  {
    text: "created date before",
    value: tasksFilterKeysDTO.CREATED_DATE_BEFORE
  },
  {
    text: "updated date on or after",
    value: tasksFilterKeysDTO.UPDATED_DATE_AFTER
  },
  { text: "updated date before", value: tasksFilterKeysDTO.UPDATED_DATE_BEFORE }
];

export { taskFilterOptions };
