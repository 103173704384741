import {
  actionKeys,
  actionStepType,
  inputType,
  numericBinaryOperatorOptions,
  operations,
  variableAssignmentOptionsWithNoValue,
  variableAssignmentOptionsWithNoValueAndEmptyOptions,
  variableAssignmentType,
  variableType,
  variableTypeText,
  variableTypeToDisplayTextMap,
  variableValueBooleanOptions,
  variableValueTypeOptions,
  urls,
  erafCasting,
  predefinedLiteralOptions,
  roundEvents,
  endOperations as endOperationsMap,
  endOperationsPerType
} from "@/constants";
import { SetStepClass } from "@/molecules/SetStep/SetStep.class";
import { getSelectedOption } from "@/utils";
import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";
import CalculationBracket from "@/molecules/CalculationBracket/CalculationBracket";
import BracketSection from "@/molecules/BracketSection/BracketSection";
import Input from "@/molecules/Input/Input";
import SearchSelect from "@/molecules/SearchSelect/SearchSelect";
import Select from "@/molecules/Select/Select";
import SetStep from "@/molecules/SetStep/SetStep";
import DynamicComponentWithLink from "@/molecules/DynamicComponentWithLink/DynamicComponentWithLink";
import {
  makeBracketDTO,
  makeCalculationStepDTO,
  makeFlatSetStepDTO
} from "@/services/actions/dto/setStepDTO/setStep.dto";
import { cloneDeep, isEmpty } from "lodash";
import { makeOptionsForMultiSelect } from "@/molecules/MultiSelect/MultiSelect.dto";
import MultiSelect from "@/molecules/MultiSelect/MultiSelect";

export default {
  data() {
    return {
      setStepList: []
    };
  },
  components: {
    CalculationBracket,
    BracketSection,
    Input,
    SearchSelect,
    Select,
    DynamicComponentWithLink,
    MultiSelect,
    SetStep
  },
  methods: {
    cloneDeep,
    isCRUDSetStep(setStepId) {
      return !!this.functionSteps.find(({ id }) => id === setStepId);
    },
    isEntityValueType(valueType) {
      return valueType === variableAssignmentType.ENTITY;
    },
    isLiteralValueType(valueType) {
      return valueType === variableAssignmentType.LITERAL;
    },
    isNoValueType(valueType) {
      return valueType === variableAssignmentType.NO_VALUE;
    },
    isExpressionValueDataType(valueDataType) {
      return valueDataType === variableType.EXPRESSION;
    },
    isNumericValueDataType(valueDataType) {
      return valueDataType === variableType.NUMERIC;
    },
    isStringValueDataType(valueDataType) {
      return valueDataType === variableType.STRING;
    },
    isBooleanValueDataType(valueDataType) {
      return valueDataType === variableType.BOOLEAN;
    },
    isProxyValueDataType(valueDataType) {
      return valueDataType === variableType.PROXY;
    },
    isDatetimeValueDataType(valueDataType) {
      return valueDataType === variableType.DATE_TIME;
    },
    isSettableExpectedData(variableName) {
      return !!this.getExpectedDataBaseLists.settableOptions.find(
        ({ text }) => text === variableName
      );
    },
    isSetStepCompleted(setStep) {
      const { value: valueType } = setStep.getSelectedValueTypeOption();
      const isBaseStepCompleted =
        !!setStep.variableNameSearchValue &&
        !!setStep.variableScope &&
        !!setStep.variableType &&
        !!setStep.valueDataType &&
        !!valueType;

      if (valueType === variableAssignmentType.NO_VALUE) {
        return isBaseStepCompleted;
      } else {
        return (
          isBaseStepCompleted &&
          (!!setStep.componentValue || setStep.componentValue === 0)
        );
      }
    },
    isMultipleSelectionExpectedForEntityPropertyName(entityPropertyName) {
      return (
        this.getAllEntities[entityPropertyName]?.data?.data?.isArray || false
      );
    },
    isMultiSelectLiteralRequired({
      entityPropertyName,
      subType,
      comparator,
      valueType
    } = {}) {
      if (this.isLiteralValueType(valueType)) {
        return (
          this.isMultipleSelectionExpectedForEntityPropertyName(
            entityPropertyName
          ) ||
          (subType === variableType.COUNTRY && comparator === operations.IN)
        );
      } else {
        return false;
      }
    },
    removeBelowValuesAssociatedToStep(index) {
      const removedLocalVariableName =
        this.functionSteps[index].variableNameSearchValue;
      if (
        !this.getAboveStepBasedOnVariableName(index, removedLocalVariableName)
      ) {
        for (let i = index + 1; i < this.functionSteps.length; i++) {
          if (this.functionSteps[i].stepType === actionStepType.SET) {
            if (
              this.functionSteps[i].variableNameSearchValue ===
              removedLocalVariableName
            ) {
              break;
            } else if (
              this.functionSteps[i].componentValue === removedLocalVariableName
            ) {
              this.functionSteps[i].setComponentValue(undefined);
              if (this.functionSteps[i]?.componentOptions?.options) {
                this.functionSteps[i].setComponentOptions({
                  ...this.functionSteps[i].componentOptions,
                  options: makeOptionsForSelect(
                    undefined,
                    this.functionSteps[i]?.componentOptions?.options
                  )
                });
              }
            }
          }
        }
      }
    },
    getAboveStepBasedOnVariableName(index, variableName) {
      let functionStep;
      for (let i = index - 1; i >= 0; i--) {
        if (this.functionSteps[i]?.variableNameSearchValue === variableName) {
          functionStep = this.cloneDeep(this.functionSteps[i]);
          break;
        }
      }
      return functionStep;
    },
    getStepValue(value, valueDataType) {
      if (value) {
        if (this.isStringValueDataType(valueDataType)) {
          return `${value}`;
        } else if (
          this.isNumericValueDataType(valueDataType) ||
          this.isDatetimeValueDataType(valueDataType)
        ) {
          return parseFloat(value);
        }
      } else {
        return value;
      }
    },
    getInputTypeForValue(valueDataType) {
      if (this.isStringValueDataType(valueDataType)) {
        return "text";
      } else if (
        this.isNumericValueDataType(valueDataType) ||
        this.isDatetimeValueDataType(valueDataType)
      ) {
        return "number";
      }
    },
    getValueForMakeOptionsForLiteralValueSelect(type, value) {
      return type === variableType.NUMERIC ? Number.parseInt(value) : value;
    },
    makeOptionsForLiteralValueSelect(
      functionStep,
      isEmptyOptionRequired = true
    ) {
      const {
        value,
        variableName,
        variableType: functionVariableType
      } = functionStep;
      const expectedDataBaseItem = this.getAllEntities[variableName];
      const parsedValue = this.getValueForMakeOptionsForLiteralValueSelect(
        functionVariableType,
        value
      );

      if (expectedDataBaseItem) {
        return makeOptionsForSelect(parsedValue, [
          ...this.makeFirstOptionForSelect(isEmptyOptionRequired),
          ...expectedDataBaseItem.data.data.options
        ]);
      } else {
        return [];
      }
    },
    makeAvailableAndRemainingLists({
      options = [],
      variableType = "",
      valueDataType = "",
      variableName = ""
    }) {
      return options.reduce(
        (acc, option) => {
          const { type, subType, isArray, text, value } = option;
          const entity = this.getAllEntities?.[variableName];
          const isSameTypeAndSubType =
            type === variableType && subType === valueDataType;
          if (
            entity &&
            isSameTypeAndSubType &&
            isArray === entity?.data?.data?.isArray
          ) {
            acc.availableOptionsList.push({ text, value });
          } else if (!entity && isSameTypeAndSubType && isArray === false) {
            acc.availableOptionsList.push({ text, value });
          } else {
            acc.remainingOptionsList.push(option);
          }

          return acc;
        },
        { availableOptionsList: [], remainingOptionsList: [] }
      );
    },
    makeOptionsForExistingValueSelect(
      functionStep = {},
      stepIndex,
      isEmptyOptionRequired = true
    ) {
      const { variableName, variableType, valueDataType, valueType, value } =
        functionStep;
      if (valueDataType && valueType) {
        const baseOptions = this.getAllAvailableOptionsByStepIndex(stepIndex);
        const { availableOptionsList, remainingOptionsList } =
          this.makeAvailableAndRemainingLists({
            options: baseOptions,
            variableType,
            valueDataType,
            variableName
          });
        const options = this.expandOptionsForExistingValueSelect({
          valueDataType: functionStep.valueDataType,
          options: [
            ...this.makeFirstOptionForSelect(isEmptyOptionRequired),
            ...availableOptionsList,
            ...this.getCastedOptionsForVariableType({
              options: remainingOptionsList,
              variableType,
              valueDataType,
              isArray: this.getIsArrayFromVariableName(variableName)
            })
          ]
        });
        return makeOptionsForSelect(value, options);
      } else {
        return [];
      }
    },
    getIsArrayFromVariableName(variableName) {
      return this.getAllEntities?.[variableName]?.data?.data?.isArray || false;
    },
    getCastedOptionsForVariableType({
      options = [],
      variableType = "",
      valueDataType = "",
      isArray = false
    }) {
      const { castTo = [] } =
        variableType === valueDataType
          ? erafCasting.types.find(
              (erafCastType) =>
                erafCastType.type === variableType &&
                erafCastType.isArray === isArray
            ) || {}
          : {};
      return castTo.reduce((fullCastedList, castedObj) => {
        const castedList = options.filter(
          (option) =>
            option.type === castedObj.type &&
            option.subType === castedObj.subType &&
            option.isArray === castedObj.isArray
        );
        fullCastedList.push(...castedList);
        return fullCastedList;
      }, []);
    },
    makeBracketSectionsObj(calculationList, options) {
      return Array.isArray(calculationList)
        ? calculationList.map(({ bracket, operator }) => ({
            bracket: {
              component: CalculationBracket,
              componentOptions: {
                calculation: bracket.map((bracketStep) => {
                  const searchSelectOptions = makeOptionsForSelect(
                    bracketStep.value,
                    options
                  );

                  return {
                    operator: {
                      options: makeOptionsForSelect(
                        bracketStep.operator,
                        numericBinaryOperatorOptions
                      ),
                      error: ""
                    },
                    value: {
                      value:
                        getSelectedOption(searchSelectOptions)?.text ||
                        bracketStep.value,
                      options: searchSelectOptions,
                      error: ""
                    }
                  };
                }),
                name: "calculation-bracket"
              }
            },
            operator: {
              options: makeOptionsForSelect(
                operator,
                numericBinaryOperatorOptions
              ),
              error: ""
            }
          }))
        : [];
    },
    makeInputComponent(value, type) {
      return {
        component: Input,
        componentOptions: {
          isLabelHidden: true,
          label: "Set variable value",
          id: "setVariableTo",
          name: "setVariableTo",
          class: "add-edit-action__form-input",
          "data-test-id": "add-edit-action__form-input",
          value,
          type,
          ...(type === inputType.NUMBER && { step: 0.000001 })
        }
      };
    },
    makeSelectInput({ options = [], events = {} }) {
      return {
        component: Select,
        componentOptions: {
          isLabelHidden: true,
          label: "Set variable value",
          name: "set-value",
          id: "set-value",
          options,
          value: getSelectedOption(options)?.value || "",
          ...events
        }
      };
    },
    makeMultiSelectInput(selectedOptions, amendableOptions, allOptions) {
      return {
        component: MultiSelect,
        componentOptions: {
          isLabelHidden: true,
          label: "Set variable values",
          name: "set-multiselect-values",
          id: "set-multiselect-values",
          showAllSelectedOptions: true,
          options: makeOptionsForMultiSelect(
            selectedOptions,
            amendableOptions,
            allOptions
          )
        }
      };
    },
    makeValueComponent(functionStep = this.makeSetStepFlatObj(), index) {
      if (this.isLiteralValueType(functionStep[actionKeys.VALUE_TYPE])) {
        return this.makeValueComponentForLiteral(functionStep, index);
      } else if (this.isEntityValueType(functionStep[actionKeys.VALUE_TYPE])) {
        return this.makeValueComponentForEntity(functionStep, index);
      } else if (this.isNoValueType(functionStep[actionKeys.VALUE_TYPE])) {
        return {
          componentOptions: { value: null },
          component: null
        };
      }
    },
    makeValueComponentForLiteralExpression(functionStep, stepIndex) {
      return {
        component: BracketSection,
        componentOptions: {
          bracketSection: this.makeBracketSectionsObj(
            functionStep.value,
            this.makeOptionsForExistingValueSelect(
              {
                ...functionStep,
                valueDataType: variableType.NUMERIC
              },
              stepIndex,
              false
            )
          )
        }
      };
    },
    makeValueComponentForEntityProxy(functionStep, index) {
      return {
        component: DynamicComponentWithLink,
        componentOptions: {
          ...this.makeSelectInput({
            options: this.makeOptionsForExistingValueSelect(
              functionStep,
              index
            ),
            events: {
              onChange: (event) => {
                this.changeValue({ event, id: functionStep.id }, index);
              }
            }
          }),
          routerLinkOptions: {
            to: ""
          },
          linkName: ""
        }
      };
    },
    makeValueComponentForLiteralDefault(functionStep) {
      const optionsForLiteralValueSelect =
        this.makeOptionsForLiteralValueSelect(functionStep);
      if (
        isEmpty(getSelectedOption(optionsForLiteralValueSelect)) &&
        optionsForLiteralValueSelect?.length
      ) {
        optionsForLiteralValueSelect[0].selected = true;
      }
      return this.makeSelectInput({ options: optionsForLiteralValueSelect });
    },
    makeValueComponentForLiteralMultiselect(functionStep) {
      const optionsForLiteralValueSelect =
        this.makeOptionsForLiteralValueSelect(functionStep, false);
      const amendableOptions = optionsForLiteralValueSelect.map(
        ({ value }) => value
      );

      return this.makeMultiSelectInput(
        functionStep.value,
        amendableOptions,
        optionsForLiteralValueSelect
      );
    },
    makeValueComponentForLiteralAlphaNumeric(functionStep) {
      const type = this.getInputTypeForValue(
        functionStep[actionKeys.VALUE_DATA_TYPE]
      );
      const value = this.getStepValue(
        functionStep[actionKeys.VALUE],
        functionStep[actionKeys.VALUE_DATA_TYPE]
      );

      return this.makeInputComponent(value, type);
    },
    makeValueComponentForLiteral(functionStep, index) {
      if (
        this.isExpressionValueDataType(functionStep[actionKeys.VALUE_DATA_TYPE])
      ) {
        return this.makeValueComponentForLiteralExpression(functionStep, index);
      } else if (
        this.isNumericValueDataType(functionStep[actionKeys.VALUE_DATA_TYPE]) ||
        this.isStringValueDataType(functionStep[actionKeys.VALUE_DATA_TYPE]) ||
        this.isDatetimeValueDataType(functionStep[actionKeys.VALUE_DATA_TYPE])
      ) {
        return this.makeValueComponentForLiteralAlphaNumeric(functionStep);
      } else if (
        this.isBooleanValueDataType(functionStep[actionKeys.VALUE_DATA_TYPE])
      ) {
        return this.makeSelectInput({
          options: makeOptionsForSelect(
            `${functionStep[actionKeys.VALUE]}`,
            variableValueBooleanOptions
          )
        });
      } else if (
        this.isMultipleSelectionExpectedForEntityPropertyName(
          functionStep[actionKeys.VARIABLE_NAME]
        )
      ) {
        return this.makeValueComponentForLiteralMultiselect(functionStep);
      } else {
        return this.makeValueComponentForLiteralDefault(functionStep);
      }
    },
    makeValueComponentForEntity(functionStep, index) {
      if (this.isProxyValueDataType(functionStep[actionKeys.VALUE_DATA_TYPE])) {
        return this.makeValueComponentForEntityProxy(functionStep, index);
      } else {
        return this.makeSelectInput({
          options: this.makeOptionsForExistingValueSelect(functionStep, index)
        });
      }
    },
    expandOptionsForExistingValueSelect({ valueDataType = "", options = [] }) {
      if (predefinedLiteralOptions[valueDataType]) {
        return [...options, ...predefinedLiteralOptions[valueDataType]];
      } else {
        return options;
      }
    },
    getStepBasedOnVariableName(index, variableName) {
      let functionStep = this.getAboveStepBasedOnVariableName(
        index,
        variableName
      );
      if (!functionStep) {
        const entity = this.getAllEntities[variableName];
        functionStep = this.makeSetStep(
          this.makeSetStepFlatObj({
            [actionKeys.DISPLAY_VALUE_TYPE]: entity?.data?.type,
            [actionKeys.VARIABLE_NAME]: entity?.data?.data?.name,
            [actionKeys.VARIABLE_SCOPE]: entity?.type,
            [actionKeys.VARIABLE_TYPE]: entity?.data?.type,
            [actionKeys.VALUE_DATA_TYPE]:
              entity?.data?.data?.subType || entity?.data?.type,
            [actionKeys.VALUE_TYPE]: variableAssignmentType.LITERAL
          })
        );
      }
      return functionStep;
    },
    makeSuccessSetStep(
      functionStep = this.makeSetStepFlatObj(),
      index = this.functionSteps.length - 1
    ) {
      return {
        stepType: actionStepType.SET,
        component: SetStep,
        componentOptions: this.makeSetStepAndExtendSetStepList(
          functionStep,
          index
        )
      };
    },
    getValueTypeForMakeSetStep({ valueType, valueDataType }) {
      return this.isExpressionValueDataType(valueDataType)
        ? variableAssignmentType.LITERAL
        : valueType;
    },
    getValueDataTypeForMakeSetStep({ valueDataType, variableType }) {
      return this.isExpressionValueDataType(valueDataType)
        ? valueDataType
        : variableType;
    },
    getIsReadOnlyForMakeSetStep({ variableName, index }) {
      return (
        this.isSettableExpectedData(variableName) ||
        !!this.getAboveStepBasedOnVariableName(index, variableName)
      );
    },
    getIsMiddleSectionHiddenForMakeSetStep({ valueDataType }) {
      return (
        this.isExpressionValueDataType(valueDataType) ||
        this.isProxyValueDataType(valueDataType)
      );
    },
    makeSetStep(
      functionStep = this.makeSetStepFlatObj(),
      index = this.functionSteps.length - 1 || 0
    ) {
      const { component = null, componentOptions = null } =
        this.makeValueComponent(functionStep, index) || {};
      const valueType = this.getValueTypeForMakeSetStep(functionStep);
      const valueDataType = this.getValueDataTypeForMakeSetStep(functionStep);
      const isReadOnly = this.getIsReadOnlyForMakeSetStep({
        ...functionStep,
        index
      });
      const endOperations = this.makeEndOperations(functionStep);
      const isMiddleSelectHidden =
        this.getIsMiddleSectionHiddenForMakeSetStep(functionStep);
      return new SetStepClass({
        variableNameSearchValue: functionStep.variableName,
        variableNameOptions: makeOptionsForSelect(
          functionStep[actionKeys.VARIABLE_NAME],
          this.getListOfSettableOptionsPerEachStep[index]
        ),
        variableType: functionStep.variableType,
        variableScope: functionStep.variableScope,
        valueDataTypeOptions: makeOptionsForSelect(
          valueDataType,
          variableValueTypeOptions
        ),
        valueDataTypeText:
          variableTypeToDisplayTextMap[
            functionStep[actionKeys.DISPLAY_VALUE_TYPE]
          ],
        isReadOnly,
        isMiddleSelectHidden,
        valueTypeOptions: makeOptionsForSelect(
          valueType,
          variableAssignmentOptionsWithNoValueAndEmptyOptions
        ),
        valueDataType: functionStep.valueDataType,
        componentValue: functionStep.value,
        component,
        componentOptions,
        isHidden: false,
        comment: functionStep?.comment,
        hasComment: !!functionStep?.comment,
        endOperations
      });
    },
    makeSetStepAndExtendSetStepList(
      functionStep = this.makeSetStepFlatObj(),
      index = this.functionSteps.length - 1
    ) {
      const setStep = this.makeSetStep(functionStep, index);
      this.setStepList.push({
        stepIndex: index,
        setStep
      });

      return setStep;
    },
    amendSuccessSetStep({ event }, index) {
      if (event.name === actionKeys.VARIABLE_NAME) {
        this.amendVariableNameSetStep(event, index);
      } else if (event.name === actionKeys.VALUE_TYPE) {
        this.changeValueType(event, index);
      } else if (event.name === actionKeys.VALUE) {
        this.changeValue(event, index);
      } else if (event.name === roundEvents.ADD_ROUND) {
        this.addRoundToSetStep({ setStepId: event.setStepId });
      } else if (event.name === roundEvents.REMOVE_ROUND) {
        this.removeRoundFromSetStep({
          roundId: event.id,
          setStepId: event.setStepId
        });
      } else if (event.name === roundEvents.ROUND_DECIMAL) {
        this.amendRoundInstance({
          roundId: event.id,
          decimal: event.event
        });
      }
    },
    onSetStepChange($event, index) {
      if ($event.name === actionKeys.VARIABLE_NAME) {
        this.changeVariableName($event, index);
      } else if ($event.name === actionKeys.VALUE_DATA_TYPE) {
        this.changeValueDataType($event, index);
      } else if ($event.name === actionKeys.VALUE_TYPE) {
        this.changeValueType($event, index);
      } else if ($event.name === actionKeys.VALUE) {
        this.changeValue($event, index);
      } else if ($event.name === roundEvents.ADD_ROUND) {
        this.addRoundToSetStep({ setStepId: $event.setStepId });
      } else if ($event.name === roundEvents.REMOVE_ROUND) {
        this.removeRoundFromSetStep({
          roundId: $event.id,
          setStepId: $event.setStepId
        });
      } else if ($event.name === roundEvents.ROUND_DECIMAL) {
        this.amendRoundInstance({
          roundId: $event.id,
          decimal: $event.event
        });
      }
    },
    onSetStepCreate($event, index) {
      if ($event.name === actionKeys.VARIABLE_NAME) {
        this.createVariableName($event, index);
      }
    },
    amendVariableNameSetStep({ event, id }, index) {
      const successSetStep = this.getSetStepBasedOnId(id);
      let setStep;

      if (event) {
        setStep = this.getStepBasedOnVariableName(index, event);
      } else {
        setStep = this.makeSetStep();
      }
      const isReadOnly =
        this.isSettableExpectedData(setStep.variableNameSearchValue) ||
        this.isCRUDSetStep(setStep.id);
      const mappedVariableType =
        setStep.valueDataType === variableType.EXPRESSION
          ? variableType.NUMERIC
          : setStep.valueDataType;
      const mappedValueDataTypeText =
        setStep.valueDataTypeText === variableTypeText.EXPRESSION
          ? variableTypeText.NUMERIC
          : setStep.valueDataTypeText;
      const newValueDataTypeOptions = makeOptionsForSelect(
        mappedVariableType,
        variableValueTypeOptions
      );
      const temporaryFunctionStep = {
        [actionKeys.VALUE_TYPE]: variableAssignmentType.LITERAL,
        [actionKeys.VALUE_DATA_TYPE]: mappedVariableType,
        [actionKeys.VARIABLE_NAME]: event
      };

      successSetStep.setVariableNameSearchValue(
        setStep.variableNameSearchValue || event
      );
      successSetStep.setVariableNameOptions(
        makeOptionsForSelect(
          setStep.variableNameSearchValue,
          this.getListOfSettableOptionsPerEachStep[index]
        )
      );
      successSetStep.setVariableType(setStep.variableType);
      successSetStep.setVariableScope(setStep.variableScope);
      successSetStep.setValueDataTypeOptions(newValueDataTypeOptions);
      successSetStep.setValueDataType(mappedVariableType);
      successSetStep.setValueDataTypeText(mappedValueDataTypeText);
      successSetStep.setIsReadOnly(isReadOnly);
      successSetStep.setHideNewExisting(false);
      successSetStep.setValueTypeOptions(
        makeOptionsForSelect(
          variableAssignmentType.LITERAL,
          variableAssignmentOptionsWithNoValueAndEmptyOptions
        )
      );
      successSetStep.setComponentValue("");
      successSetStep.setIsHidden(false);
      const { component = null, componentOptions = null } =
        this.makeValueComponent(temporaryFunctionStep, index);
      successSetStep.setComponent(component);
      successSetStep.setComponentOptions(componentOptions);
    },
    changeVariableName({ event, id }, index) {
      const setStep = this.getSetStepBasedOnId(id);
      const providerSetStep = this.getProviderSteStepForChangeVariableName({
        event,
        index,
        setStep
      });

      const isReadOnly =
        this.isSettableExpectedData(providerSetStep.variableNameSearchValue) ||
        !!this.getAboveStepBasedOnVariableName(index, event);
      setStep.setVariableNameSearchValue(
        providerSetStep.variableNameSearchValue
      );
      setStep.setVariableNameOptions(providerSetStep.variableNameOptions);
      setStep.setVariableType(providerSetStep.variableType);
      setStep.setVariableScope(providerSetStep.variableScope);
      setStep.setValueDataTypeOptions(providerSetStep.valueDataTypeOptions);
      setStep.setValueDataType(providerSetStep.valueDataType);
      setStep.setValueDataTypeText(providerSetStep.valueDataTypeText);
      setStep.setIsReadOnly(isReadOnly);
      setStep.setHideNewExisting(providerSetStep.isMiddleSelectHidden);
      setStep.setValueTypeOptions(providerSetStep.valueTypeOptions);
      setStep.setComponentValue(providerSetStep.componentValue);
      setStep.setIsHidden(providerSetStep.isHidden);
      setStep.setComponent(providerSetStep.component);
      setStep.setComponentOptions(providerSetStep.componentOptions);
    },
    getProviderSteStepForChangeVariableName({ event, index, setStep }) {
      if (event) {
        return this.getStepBasedOnVariableName(index, event);
      } else {
        this.removeBelowValuesAssociatedToStep(index);
        this.validateUniqueAssignmentForProxyVariables("", index);
        this.removeSetStepEndOperations({ setStep });
        return this.makeSetStep();
      }
    },
    getProviderSetStepForCreateVariableName(event) {
      const existingSetStep = this.functionSteps.find(
        ({ variableNameSearchValue }) => variableNameSearchValue === event
      );
      return this.cloneDeep(existingSetStep) || this.makeSetStep();
    },
    async createVariableName({ event, id }, index) {
      const setStep = this.getSetStepBasedOnId(id);
      const providerSetStep =
        this.getProviderSetStepForCreateVariableName(event);

      if (!this.isProxyValueDataType(providerSetStep.valueDataType)) {
        const isReadOnly =
          this.isSettableExpectedData(
            providerSetStep.variableNameSearchValue
          ) || !!this.getAboveStepBasedOnVariableName(index, event);
        const valueType = providerSetStep.variableNameSearchValue
          ? providerSetStep.getSelectedValueTypeOption().value
          : variableAssignmentType.LITERAL;
        setStep.setVariableNameSearchValue(
          providerSetStep.variableNameSearchValue || event
        );
        setStep.setVariableType(providerSetStep.variableType);
        setStep.setVariableScope(providerSetStep.variableScope);
        setStep.setValueDataTypeOptions(providerSetStep.valueDataTypeOptions);
        setStep.setValueDataType(providerSetStep.valueDataType);
        setStep.setValueDataTypeText(providerSetStep.valueDataTypeText);
        setStep.setIsReadOnly(isReadOnly);
        setStep.setHideNewExisting(providerSetStep.isMiddleSelectHidden);
        setStep.setValueTypeOptions(
          makeOptionsForSelect(valueType, providerSetStep.valueTypeOptions)
        );
        setStep.setComponent(providerSetStep.component);
        setStep.setComponentOptions(providerSetStep.componentOptions);
        setStep.setComponentValue(providerSetStep.componentValue);
        setStep.setIsHidden(providerSetStep.isHidden);
        this.validateUniqueAssignmentForProxyVariables("", index);
      } else {
        setStep.setIsReadOnly(false);
        setStep.setValueDataTypeOptions(this.makeFirstOptionForSelect());
        this.validateUniqueAssignmentForProxyVariables(event, index);
      }
    },
    changeValueDataTypeToExpression({ setStep, index }) {
      setStep.setVariableType(variableType.NUMERIC);
      setStep.setValueDataTypeText(
        variableTypeToDisplayTextMap[variableType.EXPRESSION]
      );
      setStep.setValueDataTypeOptions(
        makeOptionsForSelect(
          variableType.EXPRESSION,
          setStep.valueDataTypeOptions
        )
      );
      setStep.setValueDataType(variableType.EXPRESSION);
      setStep.setHideNewExisting(true);
      setStep.setValueTypeOptions(
        makeOptionsForSelect(
          variableAssignmentType.LITERAL,
          variableAssignmentOptionsWithNoValue
        )
      );
      setStep.setComponentValue([makeBracketDTO()]);
      const { component = null, componentOptions = null } =
        this.makeValueComponent(makeFlatSetStepDTO(setStep), index);
      setStep.setComponent(component);
      setStep.setComponentOptions(componentOptions);
      setStep.setIsHidden(false);

      this.amendSetStepOperations({ setStep });
    },
    changeValueDataTypeDefault({ event, setStep, index }) {
      setStep.setVariableType(event);
      setStep.setValueDataTypeText(variableTypeToDisplayTextMap[event]);
      setStep.setValueDataTypeOptions(
        makeOptionsForSelect(event, setStep.valueDataTypeOptions)
      );
      setStep.setHideNewExisting(event === variableType.PROXY);
      setStep.setValueDataType(event);
      setStep.setValueTypeOptions(
        makeOptionsForSelect(
          event === variableType.PROXY
            ? variableAssignmentType.ENTITY
            : variableAssignmentType.LITERAL,
          setStep.valueTypeOptions
        )
      );
      setStep.setComponentValue(undefined);
      const { component = null, componentOptions = null } =
        this.makeValueComponent(makeFlatSetStepDTO(setStep), index);
      setStep.setComponent(component);
      setStep.setComponentOptions(componentOptions);
      setStep.setIsHidden(false);
    },
    changeValueDataType({ event, id }, index) {
      const setStep = this.getSetStepBasedOnId(id);
      this.resetVariableReassignments({
        stepIndex: index,
        variableNameSearchValue: setStep.variableNameSearchValue
      });

      if (this.isExpressionValueDataType(event)) {
        this.changeValueDataTypeToExpression({ setStep, index });
      } else {
        this.changeValueDataTypeDefault({ setStep, index, event });
      }
    },
    resetVariableReassignments({ stepIndex, variableNameSearchValue }) {
      if (stepIndex + 1 < this.functionSteps.length) {
        for (let i = stepIndex + 1; i <= this.functionSteps.length; i++) {
          if (
            this.functionSteps[i]?.stepType === actionStepType.SET &&
            this.functionSteps[i]?.variableNameSearchValue ===
              variableNameSearchValue
          ) {
            this.resetSetStepData(this.functionSteps[i], i);
          }
        }
      }
    },
    changeValueType({ event, id }, index) {
      const setStep = this.getSetStepBasedOnId(id);
      setStep.setComponentValue(undefined);
      setStep.setValueTypeOptions(
        makeOptionsForSelect(event, setStep.valueTypeOptions)
      );
      const { component = null, componentOptions = null } =
        this.makeValueComponent(makeFlatSetStepDTO(setStep), index);
      setStep.setComponent(component);
      setStep.setComponentOptions(componentOptions);
    },
    amendSetStepOperations({ setStep }) {
      const deletedOperations = [];
      const endOperationsForType = setStep.endOperations.filter(
        ({ endOperationType, id } = {}) => {
          const isOperationIncluded =
            endOperationsPerType[setStep.variableType]?.includes(
              endOperationType
            ) || false;

          if (!isOperationIncluded)
            deletedOperations.push({ endOperationType, id });

          return isOperationIncluded;
        }
      );
      setStep.setEndOperations(endOperationsForType);

      deletedOperations.forEach(({ endOperationType, id }) => {
        if (endOperationType === endOperationsMap.ROUND) {
          this.removeIdFromRoundList({ roundId: id });
        }
      });
    },
    removeSetStepEndOperations({ setStep = {} } = {}) {
      setStep?.endOperations?.forEach(({ endOperationType, id }) => {
        if (endOperationType === endOperationsMap.ROUND) {
          this.removeRoundFromSetStep({
            setStepId: setStep.id,
            roundId: id
          });
        }
      });
    },
    addRoundToSetStep({ setStepId }) {
      const roundInstance = this.makeRoundOperationAndExtendRoundList();

      const { setStep } = this.setStepList.find(
        ({ setStep }) => setStep.id === setStepId
      );
      setStep.endOperations.push(roundInstance);
      setStep.setEndOperations(setStep.endOperations);
    },
    removeRoundFromSetStep({ roundId, setStepId }) {
      const { setStep } = this.setStepList.find(
        ({ setStep }) => setStep.id === setStepId
      );
      const endOperations = setStep.endOperations.filter(
        ({ id }) => id !== roundId
      );
      setStep.setEndOperations(endOperations);
      this.removeIdFromRoundList({ roundId });
    },
    getSetStepBasedOnId(id) {
      return (
        this.setStepList.find(({ setStep }) => setStep.id === id)?.setStep || {}
      );
    },
    changeValueForLiteralExpression({ event, successSetStep, index }) {
      this.amendBracketSection(event, successSetStep.componentValue);
      const componentOptions = {
        bracketSection: this.makeBracketSectionsObj(
          successSetStep.componentValue,
          this.makeOptionsForExistingValueSelect(successSetStep, index)
        )
      };
      successSetStep.setComponentOptions(componentOptions);
    },
    changeValueForLiteralMultiselect({ successSetStep, event }) {
      let componentValue = Array.isArray(successSetStep.componentValue)
        ? successSetStep.componentValue
        : [];
      if (event.eventType === operations.ADD) {
        componentValue.push(event.value);
      } else if (event.eventType === operations.DELETE) {
        componentValue = componentValue.filter(
          (value) => value !== event.value
        );
      }
      successSetStep.setComponentValue(componentValue);
    },
    changeValueForLiteral({ successSetStep, event, index }) {
      if (this.isExpressionValueDataType(successSetStep.valueDataType)) {
        this.changeValueForLiteralExpression({
          event,
          successSetStep,
          index
        });
      } else if (
        this.isMultipleSelectionExpectedForEntityPropertyName(
          successSetStep.variableNameSearchValue
        )
      ) {
        this.changeValueForLiteralMultiselect({ successSetStep, event });
      } else {
        successSetStep.setComponentValue(event);
      }

      const { component = null, componentOptions = null } =
        this.makeValueComponent(makeFlatSetStepDTO(successSetStep), index);
      successSetStep.setComponent(component);
      successSetStep.setComponentOptions(componentOptions);
    },
    changeValueForEntityProxy({ successSetStep, event, componentOptions }) {
      const { formId } = this.getAllEntities[event]?.data?.data?.context || {};
      successSetStep.setComponentOptions({
        ...componentOptions,
        routerLinkOptions: {
          to: urls.QUESTIONS_REFERENCE(formId),
          target: "_blank"
        },
        linkName: formId ? "Legend" : ""
      });
      this.fetchParsedProxyExpectedDataWrapper(successSetStep);
    },
    changeValueForEntity({ successSetStep, event, index }) {
      successSetStep.setComponentValue(event);

      const { component = null, componentOptions = null } =
        this.makeValueComponent(makeFlatSetStepDTO(successSetStep), index);
      successSetStep.setComponent(component);

      if (this.isProxyValueDataType(successSetStep.valueDataType)) {
        this.changeValueForEntityProxy({
          successSetStep,
          event,
          componentOptions
        });
      } else {
        successSetStep.setComponentOptions(componentOptions);
      }
    },
    changeValue({ event, id }, index) {
      const successSetStep = this.getSetStepBasedOnId(id);
      const selectedValueType =
        getSelectedOption(successSetStep.valueTypeOptions)?.value || "";

      if (this.isLiteralValueType(selectedValueType)) {
        this.changeValueForLiteral({
          event,
          successSetStep,
          index
        });
      } else if (this.isEntityValueType(selectedValueType)) {
        this.changeValueForEntity({
          successSetStep,
          event,
          index
        });
      }
    },
    updateSetSteps() {
      this.setStepList.forEach(({ stepIndex, setStep }) => {
        const isCRUDStep = this.isCRUDSetStep(setStep.id);
        const isPreviouslyDefined = !!this.getListOfSettableOptionsPerEachStep[
          stepIndex
        ]?.find(({ value }) => value === setStep.variableNameSearchValue);
        setStep.setIsReadOnly(isPreviouslyDefined);
        const { value: selectedValueTypeOption } = getSelectedOption(
          setStep.valueTypeOptions
        );

        if (!isCRUDStep && !isPreviouslyDefined) {
          this.resetSetStepData(setStep, stepIndex);
        } else {
          if (this.isEntityValueType(selectedValueTypeOption)) {
            const tempFunctionStep = {
              variableName: setStep.variableNameSearchValue,
              variableType: setStep.variableType,
              valueDataType: setStep.valueDataType,
              valueType: setStep.variableScope,
              value: setStep.componentValue
            };

            if (this.isProxyValueDataType(setStep.valueDataType)) {
              const options = this.makeOptionsForExistingValueSelect(
                tempFunctionStep,
                stepIndex
              );
              const { formId } =
                this.getAllEntities[setStep.componentValue]?.data?.data
                  ?.context || {};
              setStep.setComponentOptions({
                ...setStep.componentOptions,
                componentOptions: {
                  ...setStep.componentOptions.componentOptions,
                  options
                },
                routerLinkOptions: {
                  to: urls.QUESTIONS_REFERENCE(formId),
                  target: "_blank"
                },
                linkName: formId ? "Legend" : ""
              });

              if (!this.proxyExpectedData[setStep.id]) {
                this.fetchParsedProxyExpectedDataWrapper(setStep);
              }
            } else {
              setStep.setComponentOptions({
                ...setStep.componentOptions,
                options: this.makeOptionsForExistingValueSelect(
                  tempFunctionStep,
                  stepIndex
                )
              });
            }
          } else if (this.isBooleanValueDataType(setStep.valueDataType)) {
            setStep.setComponentOptions({
              ...setStep.componentOptions,
              options: makeOptionsForSelect(
                `${setStep.componentValue}`,
                variableValueBooleanOptions
              )
            });
          } else if (this.isExpressionValueDataType(setStep.valueDataType)) {
            setStep.setComponentOptions({
              bracketSection: this.makeBracketSectionsObj(
                setStep.componentValue,
                this.makeOptionsForExistingValueSelect(
                  {
                    ...makeFlatSetStepDTO(setStep),
                    valueDataType: variableType.NUMERIC
                  },
                  stepIndex,
                  false
                )
              )
            });
          }

          setStep.setVariableNameOptions(
            makeOptionsForSelect(
              setStep.variableNameSearchValue,
              this.getListOfSettableOptionsPerEachStep[stepIndex]
            )
          );
        }
      });
    },
    resetSetStepData(setStep, stepIndex) {
      const emptyStep = this.makeSetStep(undefined, stepIndex);
      setStep.setVariableNameSearchValue("");
      setStep.setVariableNameOptions(emptyStep.variableNameOptions);
      setStep.setVariableType(emptyStep.variableType);
      setStep.setVariableScope(emptyStep.variableScope);
      setStep.setValueDataTypeOptions(emptyStep.valueDataTypeOptions);
      setStep.setValueDataType(emptyStep.valueDataType);
      setStep.setValueDataTypeText(emptyStep.valueDataTypeText);
      setStep.setIsReadOnly(false);
      setStep.setHideNewExisting(false);
      setStep.setValueTypeOptions(emptyStep.valueTypeOptions);
      setStep.setComponent(emptyStep.component);
      setStep.setComponentOptions(emptyStep.componentOptions);
      setStep.setComponentValue(emptyStep.componentValue);
      setStep.setIsHidden(emptyStep.isHidden);
    },
    deleteSetStepIndexFromList(index) {
      this.setStepList = this.setStepList.filter(({ stepIndex, setStep }) => {
        if (
          this.isProxyValueDataType(setStep.valueDataType) &&
          stepIndex === index
        ) {
          delete this.proxyExpectedData[setStep.id];
        }
        return stepIndex !== index;
      });
    },
    deleteSetStepIdFromList(id) {
      this.setStepList = this.setStepList.filter(
        ({ setStep }) => setStep.id !== id
      );
    },
    updateSetStepsIndexes(editType, index) {
      for (
        let successSetStepsIndex = 0;
        successSetStepsIndex < this.setStepList.length;
        successSetStepsIndex++
      ) {
        if (this.setStepList[successSetStepsIndex].stepIndex >= index) {
          if (
            editType === operations.ADD &&
            this.setStepList[successSetStepsIndex].stepIndex >= index
          ) {
            this.setStepList[successSetStepsIndex].stepIndex += 1;
          } else if (
            editType === operations.DELETE &&
            this.setStepList[successSetStepsIndex].stepIndex > index
          ) {
            this.setStepList[successSetStepsIndex].stepIndex -= 1;
          }
        }
      }
    },
    updateCalculationBracket({ index, property, event }, calculationList) {
      if (property === actionKeys.COMPONENT) {
        if (event.type === operations.ADD) {
          calculationList[index].bracket.splice(
            event.index,
            0,
            makeCalculationStepDTO()
          );
        } else if (event.type === operations.DELETE) {
          calculationList[index].bracket.splice(event.index, 1);
        } else if (event.type === operations.EDIT) {
          calculationList[index].bracket[event.index][event.property] =
            event.value;
        }
      } else {
        calculationList[index][property] = event;
      }
    },
    makeNewSetStep(stepIndex) {
      this.updateAllStepIndexes(operations.ADD, stepIndex);
      this.functionSteps.splice(
        stepIndex,
        0,
        this.makeSetStepAndExtendSetStepList(undefined, this.addBlockIndex)
      );
    }
  }
};
