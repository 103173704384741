export default {
  "actionsOccur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What action will occur"])},
  "extraActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra actions"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ផ្ទាំងគ្រប់គ្រង"])},
  "dashboardTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compliance Dashboard"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "loadingSpinnerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
  "nav": {
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["គំរូ"])},
    "dandi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D&I"])}
  },
  "questionnaires": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កម្រងសំនួរ"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បំពេញសំនួរកម្រងសំនួរត្រួតពិនិត្យ និងផ្ទល់ឯកសារ"])},
    "submitModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ជូនកម្រងសំនួរ"])},
    "formSubmittedModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បែបបទបានបញ្ជូន"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I’ve read and understand these terms and definitions"])},
    "backToDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ត្រឡប់ទៅផ្ទាំងគ្រប់គ្រង, ត្រឡប់ទៅផ្ទាំងដើម"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បន្ត"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទូទៅ, មើលទូទៅ, ទិដ្ឋភាពទូទៅ"])},
    "dateOfAssigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Assigned:"])},
    "dateOfCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Completed:"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទាញយក"])},
    "batchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["រួចរាល់"])},
    "batchInProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កំពុងដំណើរការ"])},
    "batchInProgressPauseRemindersText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress - Pause Reminders"])},
    "batchNotStartedext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["មិនទាន់បានចាប់ផ្ដើម"])},
    "requestedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កាលបរិច្ឆេទដែលបានស្នើសុំ"])},
    "batchCancelledtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANCELLED"])},
    "batchDeclinedtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DECLINED / REFUSED"])},
    "batchSelfEvidencetext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SELF EVIDENCED"])},
    "linkUpModalHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Action Required"])},
    "linkUpModalBodyText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for completing the Salesforce Third Party Due Diligence Intake Form."])},
    "linkUpModalBodyText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on the responses provided, Salesforce requires that your organization complete the Salesforce Third Party Due Diligence Questionnaire."])},
    "linkUpModalBodyText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete this questionnaire as soon as possible. Failure to complete the questionnaire may affect your organisation’s request to enter into a business relationship with Salesforce."])},
    "linkUpModalBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចាប់ផ្ដើមកម្រងសំនួរត្រួតពិនិត្យ"])},
    "linkUpModalCancelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ត្រឡប់ទៅកម្រងសំនួរ"])},
    "startFromBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ផ្ដើមពីតំបូង"])},
    "myTrainingCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["វគ្គបណ្តុះបណ្តាលរបស់ខ្ញុំ"])},
    "continueTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បណ្ដាការបណ្ដុះបណ្ដាល"])},
    "continueFromBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["តើអ្នកចង់បន្តពីស្លាយលេខ X ឬចាប់ផ្ដើមពីតំបូង"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ព្យាយាមម្ដងទៀត"])},
    "goToFirstSlide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទៅស្លាយដំបូង"])},
    "slideXX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ស្លាយ XX"])},
    "viewing:X/Y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["មើល"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បន្ទាប់"])},
    "numberOfQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សំនួរបន្ទាប់"])},
    "startNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចាប់ផ្តើមឥឡូវនេះ, ចាប់ផ្ដើមភ្លាម"])},
    "illDoItLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ខ្ញុំនឹងធ្វើលើកក្រោយ, ធ្វើលើកក្រោយ"])},
    "startExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចាប់ផ្ដើមប្រឡង"])},
    "yourExamisAboutToStartinXSeconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ការប្រលងនឹងចាប់ផ្ដើមក្នុងពេល X នាទី"])},
    "startImmediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចាប់ផ្ដើមភ្លាម"])},
    "questionX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សំនួរ X"])},
    "answerOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer Overview"])},
    "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បង្ហាញ"])},
    "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បានឆ្លើយ"])},
    "notAnswered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["មិនបានឆ្លើយ"])},
    "submitAnswers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ចូនចម្លើយ"])},
    "submitExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ចូនការប្រឡង"])},
    "reviewAnswersBeforeSubmitting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: You can go back and review your answers before you submit."])},
    "cancelExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បោះបង់ការប្រឡង"])},
    "sureCancelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["តើអ្នកចង់បោះបង់ដោយមិនបញ្ចប់ការប្រឡងឬទេ?"])},
    "examSubmitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ការប្រឡងបានបញ្ជូនរួចរាល់"])},
    "thanksMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អរគុណសម្រាប់ការជ្រើសរើសគេហទំព័រប្រឡងយើងខ្ញុំ, សម្រាប់សំនួរបន្ថែមសួរទាក់ទងមកផ្នែកគ្រប់គ្រងយើងខ្ញុំ"])},
    "examResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["លទ្ធផលប្រឡង"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ពិន្ទុ"])},
    "xoutOfY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X លើ Y"])},
    "scoreNeededToPassExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ពិន្ទុដើម្បីប្រឡងជាប់"])},
    "examStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ស្ថានភាពប្រឡង"])},
    "passed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ជាប់"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ធ្លាក់"])},
    "pleaseTryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមព្យាយាមម្ដងទៀត"])},
    "congratulationsYouHavePassed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អបអរសាទរអ្នកបានប្រឡងជាប់"])},
    "generatingPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are generating your PDF…"])},
    "toCertfy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["នេះដើម្បីបញ្ជាក់លើ"])},
    "certifyDateMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ថ្ងៃទី ខែ ឆ្នាំ"])},
    "certifyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<<ឈ្មោះ>>បានបំពេញជោគជ័យនូវរាល់វគ្គសិក្សា និងការប្រឡងរបស់ Tecan's Anti-Bribery Policy  ដោយបានធ្វើតេស្តបញ្ជាក់ និងចេញវិញ្ញាបនបត្រនេះ"])},
    "trainings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បណ្ដុះបណ្ដាល"])},
    "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចាកចេញ"])},
    "fieldErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចន្លោះមួយ ឬច្រើនមានបញ្ហា។ សូមពិនិត្រនិងកែប្រែជាចាំបាច់។"])},
    "progressNotSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ដំណើរការមិនបានរក្សាទុក។ សូមព្យាយាមម្ដងទៀត"])},
    "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កំពុងរក្សាទុក"])},
    "deleteConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["តើអ្នកប្រាកដជាលុបមែនទេ?"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ត្រឡប់ទៅវិញ"])},
    "generatingFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["យើងកំពុងទាញយកឯកសាររបស់អ្នក"])},
    "successfulDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ឯកសារទាញយកជោគជ័យ"])},
    "startCertification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ត្រឡប់ទៅកម្រងសំនួរ"])},
    "trainingCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ការបណ្ដុះបណ្ដាលបញ្ចប់"])},
    "learningStageComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["វគ្គសិក្សានៃការបណ្ដុះបណ្ដាលបានបញ្ចប់. បន្តទៅកម្រងសំនួរ"])},
    "noAnswerGiven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["គ្មានចម្លើយបានឆ្លើយ, គ្មានចម្លើយ"])},
    "fileNotDownloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ឯកសារទាញយកមិនជោគជ័យ ព្យាយាមម្ដងទៀត"])},
    "learningMaterialReadnote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សូមបញ្ចាក់ថាអ្នកបានអានឯកសារសិក្សាមុននឹងបំពេញការប្រលង"])},
    "submitExamConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អ្នកនឹងបញ្ជូនសំនួរនេះ។ ការបញ្ជូននេះនឹងមិនអាចត្រឡប់ក្រោយវិញបានទេ។ តើអ្នកចង់បន្តទេ?"])},
    "goToQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ត្រឡប់ទៅសំនួរ"])},
    "cannotSubmitBlankAnswers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អ្នកមិនអាចបញ្ចូលចម្លើតទទេ"])},
    "englishOriginal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អង់គ្លេសដើម"])},
    "privacyPolicyNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["រាល់ព័ត៌មានដែលបានផ្តល់នឹងត្រូវបានដោះស្រាយតាមគោលការណ៍ឯកជនភាពរបស់ Tecan ។"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "editRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កែជួរ"])},
    "deleteRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["លុបជួរ"])},
    "selectAtLeast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["option/s"])}
  },
  "training": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainings"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អានឯកសារសិក្សារ និងត្រៀមខ្លួនសម្រាប់ប្រឡង"])}
  },
  "certifications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["វិញ្ញាបនប័ត្រ"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ប្រឡងនិងទទួលវិញ្ញាបនបត្រ"])}
  },
  "annual-certifications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual Certifications"])},
    "cardText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certify Multiple Trainings "])}
  },
  "formLeaveAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អ្នកនឹងចាកចេញពីទំព័រនេះ។ រាល់ព័ត៌មានដែលមិនបានរក្សាទុកនឹងត្រូវបាត់បង់។ តើអ្នកចង់ចាកចេញទេ?"])},
  "formSubmitingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អ្នកនឹងបញ្ចូនការប្រឡង។ ដំណើរការនេះមិនអាចត្រឡប់បានទេ។ តើអ្នកចង់បញ្ចូនទេ?"])},
  "formSubmittedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បែបបទរបស់អ្នកបានបញ្ជូនដោយជោកជ័យ"])},
  "formValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more missing fields or errors found. Please review and update as necessary."])},
  "toast": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ដាស់តឿន"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["រក្សាទុកជោគជ័យ"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ដំណើរការមិនបានរក្សាទុក។ សូមព្យាយាមម្ដងទៀត"])},
    "updateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កែប្រែជោគជ័យ/បច្ចុប្បន្នភាពជោគជ័យ"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទម្រង់ PDF"])},
    "generatePDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["យើងកំពុងបង្កើតឯកសារ PDF"])},
    "successPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF ទាញយកជោគជ័យ"])},
    "errorPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF មិនបានទាញយក សូមព្យាយាមម្ដងទៀត"])},
    "logoutSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចាកចេញជោគជ័យ"])}
  },
  "createProfile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete Registration"])},
    "titleUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កែប្រែប្រវត្តិរូប/បច្ចុប្បន្នភាពប្រវត្តិរូប"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "contactHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Details"])},
    "passwordHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Password"])},
    "fNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "lNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "contactLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Number"])},
    "jobTitleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Title"])},
    "currentPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
    "newPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "confirmPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "fNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ចូលនាមខ្លួន"])},
    "lNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ចូលនាមត្រកូល"])},
    "contactPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ចូលលេខទំនាក់ទំនង"])},
    "jobTitlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ចូលមុខតំណែងការងារ"])},
    "currentPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ចូលលេខសម្ងាត់បច្ចុប្បន្ន"])},
    "newPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ចូលលេខសម្ងាត់ថ្មី"])},
    "confirmPasswordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បញ្ជាក់ពាក្យសម្ងាត់"])},
    "requiredValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចន្លោះ​នេះ​ត្រូវតែ​បំពេញ"])},
    "passwordValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords must be identical"])},
    "loaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សំណើរកំពុងដំណើរការ"])}
  },
  "upload": {
    "dragDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទាញ និងដាក់ចុះដើម្បីផ្ទុកមាតិកា"])},
    "dragDropContnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ឬក៏ជ្រើរើសឯកសារពីកុំព្យូទ័រអ្នក"])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ឈ្មោះឯកសារ"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ប្រភេទឯកសារ"])},
    "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទំហំឯកសារ (បៃ)"])},
    "fileAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សកម្មភាព"])}
  },
  "signature": {
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["លុប"])},
    "require": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["តម្រូវ"])}
  },
  "workflow": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បន្ថែម"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["លុប"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["សារឡើងវិញ"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កែប្រែ, បច្ចុប្បន្នភាព"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["លុប"])}
  },
  "eb360": {
    "analyticsTab": {
      "partyRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Third-Party Records on ethixbase360"])},
      "registrationRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Third-Party Records on ethixbase360"])},
      "dueDilogenceRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Due Diligence PLUS"])},
      "enhancedRiskDataRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhanced S&E, PEP & Other Risk Data"])},
      "moniotringAlerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Wide Ongoing Monitoring Alerts"])},
      "analyticsSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANALYTICS"])},
      "questionariesSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUESTIONNAIRES, POLICIES,  COMMUNICATIONS & TRAINING"])}
    },
    "analyticsTabChart": {
      "thirdPartyCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Parties"])},
      "monitoringCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing Monitoring Results"])},
      "monitoringResults": {
        "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Month"])},
        "momitoringAlerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring Alerts"])},
        "thirdParyties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Parties With Alerts"])},
        "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Month"])},
        "allTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Time"])}
      },
      "selectChart": {
        "pie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pie"])},
        "pyramid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyramid"])},
        "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funnel"])}
      }
    },
    "chartTables": {
      "enforcementTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanctions & Enforcements Results (IDD)"])},
      "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Date"])},
      "names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Name"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "sanctionAlertsErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available"])},
      "sanctionAlertsShow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing Result From"])},
      "sanctionAlertsTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
      "predictiveRiskTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perceived Risk Indicator Results"])},
      "iddPlusPreTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Due Diligence PLUS Results Pre-Remediation"])},
      "iddPlusPostTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Due Diligence PLUS Results Post-Remediation"])},
      "eddTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhanced Due Diligence Results"])},
      "tpCountriesTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Parties by Country"])},
      "riskScoreTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Risk"])}
    },
    "statsTabChart": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Questionnaire, Policy or Communication :"])},
      "totalCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "progressCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
      "completedCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
      "startedCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Started"])},
      "AlternateCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternate End State"])},
      "overviewTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
      "pie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pie"])},
      "pyramid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyramid"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funnel"])},
      "configTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Configs"])}
    },
    "changePasswordModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
      "fieldPassOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Old Password:"])},
      "oldPassErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Password is required"])},
      "fieldPassNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter New Password:"])},
      "newPassErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is required"])},
      "errLeast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 10 characters"])},
      "errCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has a capital letter"])},
      "errLower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has a lowercase letter"])},
      "errNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has a number"])},
      "errSpecial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has a special character"])},
      "fieldConfirmPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password:"])},
      "errConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password is required"])},
      "errMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords must match"])},
      "loadingMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitting new Password......"])},
      "successPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password successfully changed."])}
    },
    "manageThirdParties": {
      "selectColumns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure Table Widget"])},
      "availableColumns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Columns"])},
      "selectedCol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Columns"])},
      "fixedCol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed Columns"])},
      "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display Name"])},
      "colWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column Width"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALL"])},
      "filterResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter Result"])},
      "entityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity Type:"])},
      "countryLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "nameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
      "dateLabelFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded Date (From):"])},
      "dateLabelTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded Date (To):"])},
      "iddLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD S&E Results:"])},
      "iddPlusLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD⁺ Alert:"])},
      "iddAlertLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD Alert:"])},
      "eddLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDD Alert:"])},
      "priLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard PRI:"])},
      "standardPriTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard PRI"])},
      "riskLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Risk Rating"])},
      "questionareLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire:"])},
      "addNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "iddNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD"])},
      "iddPlusPlusNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD++"])},
      "iddPlusNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD+"])},
      "eddNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDD"])},
      "questionareNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire"])},
      "sendTpNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Questionnaire to All TP"])},
      "trainingNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
      "archiveNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
      "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to Search"])},
      "noRiskBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Risk Alert"])},
      "riskBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Alert"])},
      "noRiskRBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Risk Alert(R)"])},
      "riskRBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Alert(R)"])},
      "lowBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
      "lowContentBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low PRI"])},
      "mediumBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
      "mediumContentBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium PRI"])},
      "highBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
      "highContentBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High PRI"])},
      "orderPRI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order PRI"])},
      "noAssociates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Associates Added"])},
      "notStartedStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Started"])},
      "cancelledStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
      "declineStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declined / Refused"])},
      "selfEvidencedStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self Evidenced"])},
      "progressStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
      "holdStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On Hold"])},
      "reviewLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ពិនិត្យឡើងវិញ"])},
      "completeBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
      "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Required"])},
      "notSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Sent"])},
      "loadingSending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending..."])},
      "riskTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Update My Risk Rating'"])},
      "resultRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing Result From"])},
      "toRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
      "ofRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
      "questionare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUESTIONNAIRES"])},
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARCHIVED"])},
      "sendQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to send Questionnaire(s)"])},
      "yourQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sending your Questionnaire(s)......"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading.."])},
      "sentQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire(s) Sent."])},
      "sendTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Questionnaire to All Third Parties"])},
      "sendTitleFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Questionnaire For "])},
      "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you would like to send the Modern Slavery Questionnaire to all third parties who have not previously been sent the questionnaire?"])},
      "weAreSending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sending your Questionnaires to all Third parties......"])},
      "sentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaires Sent."])},
      "addEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Add Email For"])},
      "thirdPartyFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party First"])},
      "thirdPartyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Email :"])},
      "thirdPirtyplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Third Party Email"])},
      "archieveTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive Third Parties"])},
      "selectedRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to archive the selected record(s)?"])},
      "archiveTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiving your Third Parties......"])},
      "thirdPartyAchieved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Parties Archived."])},
      "iddOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure to Order IDD?"])},
      "iddOrdering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordering IDD For"])},
      "iddOrdered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD Ordered."])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note this functionality is currently not provisioned on your account. Should you be interested in enabling this functionality please contact"])},
      "supportEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support", "@", "ethixbase.com"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទំព័រដើម"])},
      "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Third Parties"])},
      "iddReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order IDD Report"])},
      "iddPlusReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order IDD+ Report<br/>(click to learn more)"])},
      "eddReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order EDD Report<br/>(click to learn more)"])},
      "filterEntityOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
      "filterEntityIndi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
      "filterEddGreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green"])},
      "notOrderedStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
      "filterIddGreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remediated Green"])},
      "filterIddRed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remediated Red"])},
      "filterIddProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remediated In Progress"])},
      "notOrdered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Ordered"])},
      "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Date"])},
      "thirdPartyDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Detail"])},
      "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
      "entityType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity Type"])},
      "associates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associates"])},
      "configPRI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Config PRI"])},
      "questionnaireStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire Status"])},
      "riskRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Risk Rating"])},
      "predictiveRisk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predictive Risk Indicator:"])},
      "reactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure to reactivate Third Parties?"])},
      "reactivating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reactivating your Third Parties......"])},
      "reactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Parties reactivated."])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["text"])},
      "riskScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Score:"])},
      "divisionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divisionName"])},
      "noAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Answer"])},
      "optionsIndustry": {
        "mining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mining"])},
        "manufacturing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manufacturing"])},
        "construction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction"])},
        "wholesale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wholesale Trade"])},
        "retail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retail Trade"])},
        "rlectricity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electricity"])},
        "gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas"])},
        "waterWaste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water and Waste Services"])}
      },
      "n/a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "abcApriRiskLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABC APRI Risk"])},
      "abcApriResilienceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABC APRI Resilience"])},
      "hrPriRiskLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HR APRI Risk"])},
      "hrPriResilienceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HR APRI Resilience"])},
      "labPriRiskLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LAB APRI Risk"])},
      "labPriResilienceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LAB APRI Resilience"])},
      "envPriRiskLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENV APRI Risk"])},
      "envPriResilienceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENV APRI Resilience"])}
    },
    "addThirdParty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Third Party"])},
      "entityDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity Details"])},
      "labelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type :"])},
      "radioCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
      "radioIndi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
      "placeholderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity Name"])},
      "placeholderFName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
      "requiredErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Required field has no value."])},
      "placeholderLName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "placeholderCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-- Select Country --"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL :"])},
      "selectErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no selected actions."])},
      "additionalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Details"])},
      "labelAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "labelState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
      "labelZip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal/Zip Code"])},
      "labelContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Name"])},
      "labelEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Email"])},
      "labelNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Number"])},
      "labelRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Number"])},
      "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification"])},
      "thereIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is "])},
      "matchAgainst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" match against this entity name in "])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
      "currPages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Page:"])},
      "rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rows"])},
      "matchGlobally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are 4,459 matches against this entity name GLOBALLY"])},
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
      "showMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show me"])},
      "screenAssociates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shall We Screen Associate Entities As Well?"])},
      "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
      "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
      "noResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Results Found"])},
      "thereAre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are "])},
      "globalMatches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" matches against this entity name GLOBALLY"])},
      "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
      "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggested associate data is not available for the selected entity"])},
      "companyCollapseOne": {
        "fictionCorp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiction Corp"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Number :"])},
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCHD231"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status :"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Type :"])},
        "pvt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Pvt ltd."])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address :"])},
        "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United Kingdom"])},
        "filings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filings :"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis Donec pede justo, fringilla vel, aliquet vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo."])}
      },
      "companyCollapseTwo": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paul Smith (Associate)"])},
        "iddReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Due Dilligence Report"])},
        "iddPlusReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD Plus Report"])},
        "selectRemediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select to remediate"])},
        "sourceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source Name :"])},
        "riskContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Contact"])},
        "entityType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity Type :"])},
        "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])}
      },
      "occur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What actions will occur?"])},
      "subs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Included in subscription"])},
      "abcApri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABC APRI"])},
      "extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What extra actions can I take?"])},
      "edd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDD"])},
      "modern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modern Questionnaire"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions not available"])},
      "enviromental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviromental Questionnaire"])},
      "notAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Available"])},
      "idd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD Report"])},
      "standerd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
      "iddPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD+"])},
      "eddReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDD Report"])},
      "msq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MSQ"])},
      "ddq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDQ"])},
      "labourDdq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LabourDDQ"])},
      "abc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABC"])},
      "pri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRI"])},
      "ddqPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDQ+"])},
      "submitCredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit (1 Credit)"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "items": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coperate Fiction"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romania"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "name1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coperate"])},
        "country1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uk"])},
        "status1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In-Active"])}
      },
      "optionsCredit": {
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Credit"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use 1 Credit"])}
      }
    },
    "myCompany": {
      "successfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your company details have been saved successfully."])},
      "saveCompanyDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you would like to save your company details?"])},
      "oneMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more field required"])},
      "pdfToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pdf Downloaded Successfully"])}
    },
    "associateDetailModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Associates Detail"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name :"])},
      "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type :"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country :"])},
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position :"])},
      "enterDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Description"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
      "noIdentifiers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Identifiers Available for this Company Please add Identifiers First"])},
      "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
      "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
      "noNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Notes Available Please add Notes First"])},
      "omg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OGM Alerts"])},
      "addingIdentifiers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Identifiers is being Adding......"])},
      "successfullIdrntifiers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Identifiers has been Added Successfully."])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទាញយក"])}
    },
    "associateTabComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associates"])},
      "createAssociates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Associates"])},
      "associateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associates Name"])},
      "enterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Associates Name"])},
      "associateType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associates Type"])},
      "associateSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Associates Type"])},
      "associateCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associates Country"])},
      "associatePosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associates Position"])},
      "enterPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Associates Position"])},
      "iddRequire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Require IDD"])},
      "iddPlusRequire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Require IDD Plus"])},
      "enableOgm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable OGM"])},
      "ogm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OGM"])},
      "associateReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associates Report Type"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "creatingAssociates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Associates is being Creating......"])},
      "successfullAssociates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Associates has been added successfully."])},
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
    },
    "auditTrailTabComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUDIT"])},
      "activityChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Changes"])},
      "tpComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Comments"])},
      "enterComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Comment..."])},
      "commentAdding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit Comment adding......"])},
      "commentAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit Comment has been added successfully."])}
    },
    "contactsTabComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACTS"])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
      "auditTrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUDIT TRAIL"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Third Party Contact"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Third Party Contact"])},
      "fName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name:"])},
      "enterFName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter First Name"])},
      "lName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name:"])},
      "enterLName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Last Name"])},
      "emailAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
      "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Email Address"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country:"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
      "isPrimary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Primary Contact"])},
      "tpCreating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Third Party Contact is being Creating......"])},
      "tpUpdating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Third Party Contact is being Updating......"])},
      "tpCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Third Party Contact has been added successfully."])},
      "tpUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Third Party Contact has been updated successfully."])},
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Title"])},
      "enterJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Job Title"])},
      "rsidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Of Residence"])},
      "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Contact"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])}
    },
    "documentsTabComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
      "uploadDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Supporting Document"])},
      "dragDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទាញ និងដាក់ចុះដើម្បីផ្ទុកមាតិកា"])},
      "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ឬក៏ជ្រើរើសឯកសារពីកុំព្យូទ័រអ្នក"])},
      "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Display Name"])},
      "uploadingDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Document is being Uploading......"])},
      "uploadSuccessfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Document has been uploaded successfully"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded Date"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "completeDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Upload is complete"])}
    },
    "dueDiligenceRemediation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Diligence Reports"])},
      "initialReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
      "remediation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remediation"])},
      "idd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD"])},
      "iddPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDD+"])},
      "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative"])},
      "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive"])},
      "noAlerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Alerts"])},
      "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts"])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
      "n/a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
      "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
      "eddReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhanced Due Diligence Reports"])},
      "addIdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Instant Due Diligence Reports"])},
      "addModal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Modal"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded Date"])},
      "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Date"])},
      "riskStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Status"])},
      "completedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed Date"])}
    },
    "monitoringTabComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing Monitoring Results"])},
      "remediatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remediated By"])},
      "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts"])},
      "noAlerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Alerts"])}
    },
    "commsComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comms"])},
      "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communications"])},
      "sentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent Date"])}
    },
    "policiesComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy Attestations"])},
      "requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested Date"])},
      "completedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed Date"])},
      "statusLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status & Link"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])}
    },
    "questionnaireComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaires"])},
      "questionnaireDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire Detail"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data Available"])},
      "hideDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Details"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reissue"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ពិនិត្យឡើងវិញ"])},
      "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Required"])},
      "assigning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigning..."])},
      "requesting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are requesting to send the"])},
      "primaryContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the primary contact at this supplier."])},
      "thisIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is:"])},
      "primaryContactName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Contact Name:"])},
      "primaryContactEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Contact Email:"])},
      "pleaseNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note this questionnaire is 'In Progress'. You may resend the questionnaire to the primary contact above, however, please note that any information that has already been entered within this 'In Progress' questionnaire will be deleted as part of this action. This cannot be undone. Please click 'Send' to confirm you wish to proceed."])},
      "titles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])}
    },
    "questionnairePoliciesTrainingTabComponent": {
      "questionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUESTIONNAIRES"])},
      "policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POLICIES"])},
      "traning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRAINING"])},
      "comms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMMS"])}
    },
    "trainingComponent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])}
    },
    "antiBriberiyPredictiveRisk": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historic Predictive Risk Indicators"])},
      "onlineSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An online search of both initial and drill down results for XX key terms most often associated with Anti-Bribery & Corruption has resulted in:"])},
      "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chart"])},
      "resultsInConjunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results in conjunction with [Third Party Name]. This is predicative of a [low] risk indicator for Anti-Bribery & Corruption."])},
      "noteThatHistoric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that the Anti-Bribery & Corruption Historic Predictive Risk Indicator indicates only the potential for risk. It should not be used in isolation and should be used in combination with your organisation's own risk assessment, procedures and policies."])},
      "futurePredictive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future Predictive Risk Benchmarks"])},
      "aSearchOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A search of [Third Party Name]’s domain has revealed the bellow results in conjunction with visibility of and preparedness for Anti-Bribery & Corruption Compliance."])},
      "bribery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bribery & Corruption"])},
      "political": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Political Exposure"])},
      "fruad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraud"])},
      "noteThatFuture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that the Anti-Bribery & Corruption Future Predicative Risk Benchmarks indicate only the prevalence of material related to ta risk area. This is not a comprehensive policy review. Should you wish for such a service please contact us to discuss."])}
    },
    "enviormentalPredictiveRisk": {
      "onlineSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An online search of both initial and drill down results for XX key terms most often associated with Environmental Non-compliance n has resulted in:"])},
      "xxx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XXX"])},
      "resultsInConjunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results in conjunction with [Third Party Name]. This is predicative of a [high] risk indicator for Environmental Risk."])},
      "noteThatHistoric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that the Environmental Historic Predictive Risk Indicator indicates only the potential for risk. It should not be used in isolation and should be used in combination with your organisation's own risk assessment, procedures and policies."])},
      "futurePredictive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future Predictive Risk Benchmarks"])},
      "aSearchOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A search of [Third Party Name]’s domain has revealed the bellow results in conjunction with visibility of and preparedness for Environmental Compliance."])},
      "enviromental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environmental Practices"])},
      "noteThatFuture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that the Environmental Future Predicative Risk Benchmarks indicate only the prevalence of material related to ta risk area. This is not a comprehensive policy review. Should you wish for such a service please contact us to discuss."])}
    },
    "humanRightsPredictiveRisk": {
      "onlineSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An online search of both initial and drill down results for XX key terms most often associated with Human Rights violations has resulted in:"])},
      "xx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XX"])},
      "resultsInConjunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results in conjunction with [Third Party Name]. This is predicative of a [] risk indicator for Human Rights."])},
      "noteThatHistoric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that the Human Rights Historic Predictive Risk Indicator indicates only the potential for risk. It should not be used in isolation and should be used in combination with your organisation's own risk assessment, procedures and policies."])},
      "futurePredictive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future Predictive Risk Benchmarks"])},
      "aSearchOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A search of [Third Party Name]’s domain has revealed the bellow results in conjunction with visibility of and preparedness for Human Rights Compliance."])},
      "humanRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Rights"])},
      "modernSlavery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modern Slavery"])},
      "noteThatFuture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that the Human Rights Future Predicative Risk Benchmarks indicate only the prevalence of material related to ta risk area. This is not a comprehensive policy review. Should you wish for such a service please contact us to discuss."])}
    },
    "labourPredictiveRisk": {
      "notInScope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOT IN SCOPE. Please Contact Us should you wish to discuss provisioning this service on your account."])},
      "futurePredictive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future Predictive Risk Benchmarks"])},
      "labourPractices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labour Practices"])},
      "healthSafety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health & Safety"])}
    },
    "overallPredictiveRisk": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic PRI"])},
      "advance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced Algorithmic PRI"])},
      "accountName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Account Name] Configured PRI"])},
      "entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTITY"])},
      "sanctions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SANCTIONS"])},
      "bribery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bribery & Corruption"])},
      "historic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HISTORIC"])},
      "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FUTURE"])},
      "humanRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Rights"])},
      "labour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labour"])},
      "environmental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environmental"])},
      "nameAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Analysis & Confidence Score"])},
      "falsePositive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FALSE POSITIVE PROBABILITY CALCULATOR"])},
      "companyScope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPANY SCOPE"])},
      "nameFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Frequency in Country"])},
      "nearExact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEAR EXACT"])},
      "partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PARTIAL"])},
      "nameFrequencyGlobal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Frequency Global"])},
      "falsePositiveAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FALSE POSITIVE ASSESSMENT"])},
      "commonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common Name"])},
      "dueToPrevalence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due to prevalence of name [locally] [and/or] [globally] it is probable that false positives exists within the historic risk indicator score"])}
    },
    "predictiveRisk": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configured PRI"])},
      "riskIndicators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Risk by Configured Risk Indicators"])},
      "dealValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deal Value = $30M"])},
      "longevity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longevity of Relationship=< 1yr"])},
      "tpReseller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Type = Reseller"])},
      "tpInventive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Inventive = 30%"])},
      "sanctionsPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanctions PRI"])},
      "potentialSanctions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a potential sanctions match at 95% match score or above. We encourage you to take additional steps to confirm the Third Parties risk status."])},
      "scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scope:"])},
      "listSanctions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Sanctions Lists searched. Suggest no more than 5 – 10 so as not to impinge on IDD service"])},
      "pleaseNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that this is NOT a comprehensive sanctions screen against the full sanctions database (or other services such as negative media and political exposure). Nor has this third party been enabled for monitoring."])},
      "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended Next Steps:"])},
      "toEnable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To enable monitoring please order an Instant Due Diligence (IDD), Instant Due Diligence PLUS (IDD+) (Best Practice) or Enhanced Due Diligence (EDD) report should further scrutiny be warranted for this third party. Remediation services can also be performed to provide assurance on the match."])}
    },
    "riskOverview": {
      "perceivedRisk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perceived Risk Indicators"])},
      "basicPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic PRI"])},
      "standardPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard PRI"])},
      "configuredPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configured PRI"])},
      "antiCorruptionPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti Bribery and Corruption APRI"])},
      "humanRightsPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Right APRI"])},
      "labourPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labour APRI"])},
      "environmentalPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environmental Practices APRI"])},
      "ddResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Diligence Results"])},
      "iddSe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Due Diligence (S&E)"])},
      "iddPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Due Diligence PLUS"])},
      "edd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhanced Due Diligence"])},
      "execSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executive Summary Analysis"])},
      "policyAttestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire & Policy Attestation"])},
      "ddQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Bribery & Corruption Questionnaire (ABCQ)"])},
      "modernSlavery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modern Slavery Questionnaire"])},
      "codeOfConduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code of Conduct Attestation"])},
      "antiCorruption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Corruption Policy Attestation"])},
      "trainingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Bribery & Corruption Training"])},
      "antiBribery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Bribery & Corruption"])}
    },
    "riskSummary": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Summary"])},
      "configured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONFIGURED PRI™"])},
      "antiCorruption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANTI-CORRUPTION PRI™"])},
      "humanRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUMAN RIGHTS PRI™"])},
      "labour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LABOUR PRI™"])},
      "environmental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENVIRONMENTAL PRI™"])},
      "advancedPri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced PRI"])}
    },
    "tpDetailsAssocTab": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETAILS"])},
      "lowerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])},
      "tpName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Name"])},
      "uploadedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded Date"])},
      "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
      "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode/Zip Code"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "duns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DUNS Number"])},
      "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Number"])},
      "contactDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTRACT DETAILS"])},
      "contactDetailsLower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Details"])},
      "validForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Valid From"])},
      "contractExpires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Expires"])},
      "contractNetValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Net Value"])},
      "contractCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Currency"])},
      "onboardDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onboard Date"])},
      "customAttribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUSTOM ATTRIBUTES"])},
      "customAttributeLower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Attributes"])},
      "wordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
      "customAttributesExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Attributes does not exist for this company."])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACTS"])},
      "associates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASSOCIATES"])},
      "selfReported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SELF REPORTED DATA"])},
      "selfReportedLower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self Reported Data"])},
      "questionnaireData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire data does not exist for this company."])}
    },
    "footerComponent": {
      "orderIddPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORDER IDD+"])},
      "orderEdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORDER EDD"])},
      "sendQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEND QUESTIONNAIRE"])},
      "sendPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEND POLICY"])},
      "sendTrain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEND TRAIN"])},
      "sendQuestionnaireFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Questionnaire For "])},
      "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure to send Questionnaire"])},
      "weAreSending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sending your Questionnaire......"])},
      "questionnaireSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire Sent."])},
      "addEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Add Email For"])},
      "tpFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party First"])},
      "tpEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Email"])},
      "enterTpEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Third Party Email"])}
    },
    "riskSummeryTab": {
      "pritm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predictive Risk Indicators (PRITM)"])},
      "entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity (inc. Sanctions)"])},
      "ddResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Diligence Results"])},
      "iddSe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Due Diligence (S&E)"])},
      "edd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhanced Due Diligence"])},
      "exec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exec Summary Analysis"])},
      "policyAttestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire & Policy Attestation"])},
      "ddq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Bribery & Corruption Questionnaire"])},
      "msq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modern Slavery Questionnaire"])},
      "codeOfConduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code of Conduct Attestation"])},
      "antiCorruption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Corruption Policy Attestation"])},
      "trainingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Bribery & Corruption Training"])},
      "antiBribery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Bribery & Corruption"])}
    },
    "reviewResult": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Results & Download Report"])},
      "nameSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Searched"])},
      "jurisdiction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurisdiction"])},
      "subjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject Name"])},
      "entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity Type"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "asrReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASR Report Results"])},
      "asrPlusReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASR+ Report Results"])},
      "asrDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download ASR Report"])},
      "asrPlusDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download ASR+ Report"])},
      "asrPlusOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order ASR+"])},
      "sne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S&E"])},
      "riskAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Alert"])},
      "noRiskAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Risk Alert"])},
      "asrPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASR+"])},
      "entityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity Name"])},
      "compNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Number"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "compType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Type"])},
      "addr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "incorpDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorporation Date"])},
      "dissolutionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dissolution Date"])},
      "registryUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registry URL"])},
      "prevName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous Names"])},
      "fillings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filings"])},
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
      "occupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupation"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
      "compName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
      "dunsNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DUNS Number"])},
      "currStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Status"])},
      "industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry"])},
      "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated At"])},
      "registeredAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered Address"])},
      "primaryAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Address"])},
      "mailingAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailing Address"])},
      "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])},
      "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
      "officers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Officers"])},
      "srcName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source Name"])},
      "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
      "aliases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aliases"])},
      "riskAlertInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Alert Information"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "subCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Category"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "srcUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source URL"])},
      "srcDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source Date"])},
      "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresses"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "addInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Information"])},
      "businessEntity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Entity Type"])},
      "srcInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sources Information"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: Your third party has an ADIT Screening Report PLUS (ASR+) risk alert. We have automatically sent an email notification to the ADIT support team to remediate this report for any possible false positive alerts. Please expect an IDD⁺ remediated report for your third party in the EB platform within 48 business hours."])}
    }
  },
  "colors": {
    "green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green"])},
    "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red"])},
    "amber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amber"])},
    "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black"])},
    "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White"])}
  },
  "wordNumber": {
    "zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zero"])},
    "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One"])},
    "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two"])},
    "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Three"])},
    "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Four"])},
    "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Five"])},
    "six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Six"])},
    "seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seven"])},
    "eight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eight"])},
    "nine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nine"])},
    "ten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten"])}
  },
  "languages": {
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aarabic"])},
    "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
    "chinesetr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chineset Traditional"])},
    "dutch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "gujarati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gujarati"])},
    "hebrew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebrew"])},
    "hindi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
    "indonesian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesian"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
    "kannada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kannada"])},
    "marathi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marathi"])},
    "polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
    "portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
    "romanian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romanian"])},
    "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
    "swedish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
    "tamil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamil"])},
    "telugu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telugu"])},
    "thai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai"])},
    "turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "ukrainian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrainian"])},
    "vietnamese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamese"])},
    "tagalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagalog"])},
    "khmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khmer"])}
  },
  "validations": {
    "invalidFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ទម្រង់មិនត្រឹមត្រូវ"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["អាស័យដ្ឋានអ៊ីម៉ែលមិនត្រឹមត្រូវ"])},
    "wrongPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid current password."])}
  },
  "savingProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កំពុងរក្សាទុក"])},
  "startBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចាប់ផ្ដើម"])},
  "saveBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["រក្សាទុក"])},
  "continueBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បន្ត"])},
  "cancelBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បោះបង់"])},
  "closeBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["បិទ"])},
  "deleteBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "editBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កែ/កែតម្រូវ"])},
  "clearBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
  "submitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ស្នើរ, បញ្ជូន"])},
  "saveProgressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["កំពុងដំណើរការរក្សាទុក"])},
  "loginBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចូល"])},
  "logoutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចាកចេញ"])},
  "signInBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចូល"])},
  "signOutBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ចាកចេញ"])},
  "updateBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "addBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "resetBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "applyBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
  "sendBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "searchBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "uploadBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "okBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["យល់ព្រម"])},
  "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ប្រវត្តិរូប"])}
}