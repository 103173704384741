import { taskTemplatesService } from "@/services";
import { actionName, mutationName, taskTemplateKeysAPIDTO } from "@/constants";

export const actions = {
  async fetchTaskTemplates(
    { commit, rootState },
    { isLoadingTaskTemplatesSuppressed = false, ...payload } = {}
  ) {
    try {
      if (!isLoadingTaskTemplatesSuppressed) {
        commit(
          mutationName.TASK_TEMPLATES.SET_IS_LOADING_TASK_TEMPLATES,
          true,
          {
            root: true
          }
        );
      }
      const { data } = await taskTemplatesService.fetchTaskTemplates({
        companyId: rootState.company.companyId,
        excluded_fields: [
          taskTemplateKeysAPIDTO.EXPECTED_DATA_DEFINITION,
          taskTemplateKeysAPIDTO.OUTCOME_ACTIONS
        ],
        with_options: true,
        ...payload
      });
      commit(mutationName.TASK_TEMPLATES.SET_TASK_TEMPLATES, data.data, {
        root: true
      });
    } finally {
      commit(mutationName.TASK_TEMPLATES.SET_IS_LOADING_TASK_TEMPLATES, false, {
        root: true
      });
    }
  },
  async fetchTaskTemplate({ commit, rootState }, id) {
    try {
      commit(mutationName.TASK_TEMPLATES.SET_IS_LOADING_TASK_TEMPLATE, true, {
        root: true
      });
      const { data } = await taskTemplatesService.fetchTaskTemplate({
        companyId: rootState.company.companyId,
        id
      });
      commit(mutationName.TASK_TEMPLATES.SET_TASK_TEMPLATE, data, {
        root: true
      });
    } finally {
      commit(mutationName.TASK_TEMPLATES.SET_IS_LOADING_TASK_TEMPLATE, false, {
        root: true
      });
    }
  },
  async makeTaskTemplate({ dispatch, rootState }, taskTemplate) {
    await taskTemplatesService.makeTaskTemplate({
      companyId: rootState.company.companyId,
      taskTemplate: {
        ...taskTemplate,
        companyId: rootState.company.companyId
      },
      storeExpectedData: rootState.expectedData.expectedData
    });
    await dispatch(actionName.TASK_TEMPLATES.FETCH_TASK_TEMPLATES, undefined, {
      root: true
    });
  },
  async updateTaskTemplate({ dispatch, rootState }, taskTemplate) {
    await taskTemplatesService.updateTaskTemplate({
      companyId: rootState.company.companyId,
      taskTemplate,
      storeExpectedData: rootState.expectedData.expectedData
    });
    await dispatch(actionName.TASK_TEMPLATES.FETCH_TASK_TEMPLATES, undefined, {
      root: true
    });
  },
  async cloneTaskTemplate(
    { dispatch, rootState },
    { isLoadingTaskTemplatesSuppressed = false, id } = {}
  ) {
    await taskTemplatesService.cloneTaskTemplate({
      companyId: rootState.company.companyId,
      id
    });
    await dispatch(
      actionName.TASK_TEMPLATES.FETCH_TASK_TEMPLATES,
      { isLoadingTaskTemplatesSuppressed },
      {
        root: true
      }
    );
  },
  async deleteTaskTemplate(
    { dispatch, rootState },
    { isLoadingTaskTemplatesSuppressed = false, id } = {}
  ) {
    await taskTemplatesService.deleteTaskTemplate({
      companyId: rootState.company.companyId,
      id
    });
    await dispatch(
      actionName.TASK_TEMPLATES.FETCH_TASK_TEMPLATES,
      { isLoadingTaskTemplatesSuppressed },
      {
        root: true
      }
    );
  }
};
