<template>
  <ErrorPageTemplate v-bind="errorPageOptions" />
</template>

<script>
import ErrorPageTemplate from "@/templates/ErrorPageTemplate/ErrorPageTemplate";

export default {
  name: "AccessRestrictedTemplate",
  components: {
    ErrorPageTemplate
  },
  data() {
    return {
      errorPageOptions: {
        iconOptions: {
          icon: "ban"
        },
        headingOptions: {
          text: "Access Restricted"
        },
        contentOptions: {
          text: [
            "You don't have access to this page.",
            "If you need to access it, please contact your administrator or the Ethixbase360 Support team."
          ]
        }
      }
    };
  }
};
</script>
