<template>
  <div class="row">
    <b-col cols="12">
      <b-tabs content-class="mt-3">
        <!-- Text -->
        <b-tab title="Text" active>
          <b-tabs pills card vertical>
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab
              @click="tabLabelClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <!-- translation form wrappe -->
              <div>
                <b-form-group label="Title">
                  <b-form-input
                    @blur="addLabelLanguageToField(language)"
                    v-model="label"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Description">
                  <b-form-textarea
                    @blur="addLabelLanguageToField(language)"
                    v-model="description"
                  ></b-form-textarea>
                </b-form-group>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Referee Name label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="refereeNameTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Referee Position Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="refereePositionRoleTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Referee Company Name label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="refereeCompanyTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Referee Email Address label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="refereeEmailTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Referee Contact number label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="refereePhoneTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Company Website label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="refereeWebsiteTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Nature of relationship label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="refereeNatureOfRelationshipTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Length of relationship label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="refereeLengthOfRelationTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Free Form label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="freeFormTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Helper Text------------------- -->
        <b-tab title="Helper Text">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabHelperTextClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for Referee Name</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="refereeNameHelperText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Helper Text for Referee Position</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="refereePositionRoleHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for Referee Company Name</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="refereeCompanyHelperText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Helper Text for Referee Email Address</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="refereeEmailHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for Referee Contact number</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="refereePhoneHelperText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Helper Text for Company Website</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="refereeWebsiteHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for Nature of relationship</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="refereeNatureOfRelationshipHelperText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Helper Text for Length of relationship</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="refereeLengthOfRelationHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for Free Form</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="freeFormHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Place Holder Text------------------- -->
        <b-tab title="Place Holder">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabPlaceHolderTextClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Place Holder for Referee Name</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="refereeNamePlaceHolderText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Place Holder for Referee Position</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="refereePositionRolePlaceHolderText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Place Holder for Referee Company Name</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="refereeCompanyPlaceHolderText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Place Holder for Referee Email Address</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="refereeEmailPlaceHolderText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Place Holder for Referee Contact number</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="refereePhonePlaceHolderText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Place Holder for Company Website</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="refereeWebsitePlaceHolderText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Place Holder for Nature of relationship</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="refereeNatureOfRelationshipPlaceHolderText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Place Holder for Length of relationship</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="refereeLengthOfRelationPlaceHolderText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Place Holder for Free Form</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="freeFormPlaceHolderText"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Validation Message------------------- -->
        <b-tab title="Validation Message">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabValidationMessageClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message for Referee Name</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="refereeNameValidationMessage"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Validation Message for Referee Position</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="refereePositionRoleValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for
                      >Validation Message for Referee Company Name</label
                    >
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="refereeCompanyValidationMessage"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for
                      >Validation Message for Referee Email Address</label
                    >
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="refereeEmailValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for
                      >Validation Message for Referee Contact number</label
                    >
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="refereePhoneValidationMessage"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Validation Message for Company Website</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="refereeWebsiteValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for
                      >Validation Message for Nature of relationship</label
                    >
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="refereeNatureOfRelationshipValidationMessage"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for
                      >Validation Message for Length of relationship</label
                    >
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="refereeLengthOfRelationValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message for Free Form</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="freeFormValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Attributes------------------- -->
        <b-tab title="Attributes">
          <b-form @submit.stop.prevent class="text-left mt-2">
            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Referee Name</label>
                <b-form-input v-model="refereeNamePostName"></b-form-input>
              </b-col>

              <b-col cols="6">
                <label for>Post name for Referee Position</label>
                <b-form-input
                  v-model="refereePositionRolePostName"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Referee Company Name</label>
                <b-form-input v-model="refereeCompanyPostName"></b-form-input>
              </b-col>

              <b-col cols="6">
                <label for>Post name for Referee Email Address</label>
                <b-form-input v-model="refereeEmailPostName"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Referee Contact number</label>
                <b-form-input v-model="refereePhonePostName"></b-form-input>
              </b-col>

              <b-col cols="6">
                <label for>Post name for Company Website</label>
                <b-form-input v-model="refereeWebsitePostName"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Nature of relationship</label>
                <b-form-input
                  v-model="refereeNatureOfRelationshipPostName"
                ></b-form-input>
              </b-col>

              <b-col cols="6">
                <label for>Post name for Length of relationship</label>
                <b-form-input
                  v-model="refereeLengthOfRelationPostName"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Free Form</label>
                <b-form-input v-model="freeFormPostName"></b-form-input>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>

        <!-- ----------------Advance------------------->
        <b-tab title="Advanced">
          <b-row>
            <b-col cols="10">
              <b-form-checkbox
                class="text-left mb-3"
                v-model="refereeNameDisabled"
                >Disable Referee Name?</b-form-checkbox
              >

              <b-form-checkbox
                class="text-left mb-3"
                v-model="refereePositionRoleDisabled"
                >Disable Referee Position?</b-form-checkbox
              >

              <b-form-checkbox
                class="text-left mb-3"
                v-model="refereeCompanyDisabled"
                >Disable Referee Company Name?</b-form-checkbox
              >

              <b-form-checkbox
                class="text-left mb-3"
                v-model="refereeEmailDisabled"
                >Disable Referee Email Address?</b-form-checkbox
              >

              <b-form-checkbox
                class="text-left mb-3"
                v-model="refereePhoneDisabled"
                >Disable Referee Contact number</b-form-checkbox
              >

              <b-form-checkbox
                class="text-left mb-3"
                v-model="refereeWebsiteDisabled"
                >Disable Referee Company Website</b-form-checkbox
              >

              <b-form-checkbox
                class="text-left mb-3"
                v-model="refereeNatureOfRelationshipDisabled"
                >Disable Referee Nature of relationship</b-form-checkbox
              >

              <b-form-checkbox
                class="text-left mb-3"
                v-model="refereeLengthOfRelationDisabled"
                >Disable Referee Length of relationship</b-form-checkbox
              >

              <b-form-checkbox class="text-left mb-3" v-model="freeFormDisabled"
                >Disable Referee Free Form</b-form-checkbox
              >
            </b-col>
          </b-row>
        </b-tab>

        <!-- data validation -->
        <b-tab title="Validation">
          <div class="text-left">
            <b-col>
              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireRefereeName"
                >Is required Referee Name?</b-form-checkbox
              >

              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireRefereePositionRole"
                >Is required Referee Position?</b-form-checkbox
              >

              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireRefereeCompany"
                >Is required Referee Company Name?</b-form-checkbox
              >

              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireRefereeEmail"
                >Is required Referee Email Address?</b-form-checkbox
              >

              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireRefereePhone"
                >Is required Referee Contact number?</b-form-checkbox
              >

              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireRefereeWebsite"
                >Is required Company Website?</b-form-checkbox
              >

              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireRefereeNatureOfRelationship"
                >Is required Nature of relationship?</b-form-checkbox
              >

              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireRefereeLengthOfRelation"
                >Is required Length of relationship</b-form-checkbox
              >

              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireFreeForm"
                >Is required Free Form</b-form-checkbox
              >
            </b-col>
          </div>
        </b-tab>

        <!-- for conditions -->
        <b-tab title="Advance">
          <b-col cols="12" class="mr-0">
            <b-form-checkbox
              v-model="isConditional"
              name="conditional"
              value="1"
              unchecked-value="0"
              >Is Conditional?</b-form-checkbox
            >

            <div v-if="isConditional == 1">
              <b-row>
                <table class="table table-sm">
                  <thead>
                    <tr class="text-center">
                      <th>No</th>
                      <th>When Field</th>
                      <th>Has Value</th>
                      <th>Actions</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(selectedOpt, index) in fieldData.conditions"
                      :key="selectedOpt.id"
                    >
                      <td>{{ index }}</td>
                      <td>
                        <b-form-input disabled :value="selectedOpt.label" />
                      </td>
                      <td>
                        <b-form-input disabled :value="selectedOpt.value" />
                      </td>
                      <td>
                        <b-button
                          variant="outline-danger"
                          class="btn-sm ml-1"
                          @click="onRemoveConditionOpt(selectedOpt.id, index)"
                        >
                          <i class="fa fa-trash"></i>
                        </b-button>
                      </td>
                    </tr>

                    <tr
                      v-for="(noOfOption, index) in noOfConditions"
                      :key="index"
                    >
                      <td>
                        <v-select
                          v-model="conditionalField"
                          class="mb-2"
                          :options="conditionalSectionElementLables"
                          lablel="label"
                          @update:modelValue="getSelectedElement"
                        ></v-select>
                      </td>
                      <td>
                        <v-select
                          class="mb-2"
                          label="text"
                          v-model="conditionalFieldValue"
                          :options="selectedElementOption"
                        ></v-select>
                      </td>

                      <td>
                        <b-button
                          class="btn-sm"
                          variant="outline-primary"
                          @click="setFieldConditions"
                          >Apply</b-button
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-row>
            </div>
          </b-col>
        </b-tab>
      </b-tabs>
    </b-col>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import uid from "uid";

export default {
  name: "TradeReferencesSettings",
  props: {
    fieldData: Object,
    sectionIndex: Number,
    fieldIndex: Number
  },
  components: {},

  data() {
    return {
      transalationLanguage: "english",
      // Input General

      description: null,
      // labels
      label: null,
      refereeNameTitle: null,
      refereePositionRoleTitle: null,
      refereeCompanyTitle: null,
      refereeEmailTitle: null,
      refereePhoneTitle: null,
      refereeWebsiteTitle: null,
      refereeNatureOfRelationshipTitle: null,
      refereeLengthOfRelationTitle: null,
      freeFormTitle: null,

      // Helper Text
      refereeNameHelperText: null,
      refereePositionRoleHelperText: null,
      refereeCompanyHelperText: null,
      refereeEmailHelperText: null,
      refereePhoneHelperText: null,
      refereeWebsiteHelperText: null,
      refereeNatureOfRelationshipHelperText: null,
      refereeLengthOfRelationHelperText: null,
      freeFormHelperText: null,

      // Place Holder Text
      refereeNamePlaceHolderText: null,
      refereePositionRolePlaceHolderText: null,
      refereeCompanyPlaceHolderText: null,
      refereeEmailPlaceHolderText: null,
      refereePhonePlaceHolderText: null,
      refereeWebsitePlaceHolderText: null,
      refereeNatureOfRelationshipPlaceHolderText: null,
      refereeLengthOfRelationPlaceHolderText: null,
      freeFormPlaceHolderText: null,

      // Validation Message
      refereeNameValidationMessage: null,
      refereePositionRoleValidationMessage: null,
      refereeCompanyValidationMessage: null,
      refereeEmailValidationMessage: null,
      refereePhoneValidationMessage: null,
      refereeWebsiteValidationMessage: null,
      refereeNatureOfRelationshipValidationMessage: null,
      refereeLengthOfRelationValidationMessage: null,
      freeFormValidationMessage: null,

      // hidden/diabled
      refereeNameDisabled: false,
      refereePositionRoleDisabled: false,
      refereeCompanyDisabled: false,
      refereeEmailDisabled: false,
      refereePhoneDisabled: false,
      refereeWebsiteDisabled: false,
      refereeNatureOfRelationshipDisabled: false,
      refereeLengthOfRelationDisabled: false,
      freeFormDisabled: true,

      // validation
      isRequireRefereeName: true,
      isRequireRefereePositionRole: true,
      isRequireRefereeCompany: true,
      isRequireRefereeEmail: true,
      isRequireRefereePhone: true,
      isRequireRefereeWebsite: true,
      isRequireRefereeNatureOfRelationship: true,
      isRequireRefereeLengthOfRelation: true,
      isRequireFreeForm: false,

      // attributes
      refereeNamePostName: null,
      refereePositionRolePostName: null,
      refereeCompanyPostName: null,
      refereeEmailPostName: null,
      refereePhonePostName: null,
      refereeWebsitePostName: null,
      refereeNatureOfRelationshipPostName: null,
      refereeLengthOfRelationPostName: null,
      freeFormPostName: null,

      // conditions
      conditionalSectionElements: [],
      conditionalSectionElementLables: [],
      selectedElementOption: [],
      conditionalField: null,
      conditionalFieldValue: null,
      condition: {},
      isConditional: 0,
      noOfConditions: [1]
    };
  },

  created() {
    EventBus.on("onSaveSettings", () => {
      this.updateAdvancedSettings();
      this.setValidation();
      this.setAttributes();
    });
  },

  mounted() {
    this.setFiledsLabelText();
    this.setFiledsHelperText();
    this.setFiledsPlaceHolderText();
    this.setFiledsValidationMessage();
    this.setFiledsAttributes();
    this.setFiledsAdvance();
    this.setFiledsValidations();
    this.getCurrentSectionList();
  },

  computed: {
    ...mapGetters({ form: "form1" })
  },

  methods: {
    setFiledsLabelText() {
      this.description = this.fieldData.description[this.transalationLanguage];

      this.label = this.fieldData.label[this.transalationLanguage];

      this.refereeNameTitle =
        this.fieldData.refereeNameLabel[this.transalationLanguage];
      this.refereePositionRoleTitle =
        this.fieldData.refereePositionRoleLabel[this.transalationLanguage];
      this.refereeCompanyTitle =
        this.fieldData.refereeCompanyLabel[this.transalationLanguage];

      this.refereeEmailTitle =
        this.fieldData.refereeEmailLabel[this.transalationLanguage];

      this.refereePhoneTitle =
        this.fieldData.refereePhoneLabel[this.transalationLanguage];

      this.refereeWebsiteTitle =
        this.fieldData.refereeWebsiteLabel[this.transalationLanguage];

      this.refereeNatureOfRelationshipTitle =
        this.fieldData.refereeNatureOfRelationshipLabel[
          this.transalationLanguage
        ];

      this.refereeLengthOfRelationTitle =
        this.fieldData.refereeLengthOfRelationLabel[this.transalationLanguage];

      this.freeFormTitle =
        this.fieldData.freeFormLabel[this.transalationLanguage];
    },

    setFiledsHelperText() {
      // Helper Text

      this.refereeNameHelperText =
        this.fieldData.refereeNameHelperText[this.transalationLanguage];
      this.refereePositionRoleHelperText =
        this.fieldData.refereePositionRoleHelperText[this.transalationLanguage];
      this.refereeCompanyHelperText =
        this.fieldData.refereeCompanyHelperText[this.transalationLanguage];

      this.refereeEmailHelperText =
        this.fieldData.refereeEmailHelperText[this.transalationLanguage];

      this.refereePhoneHelperText =
        this.fieldData.refereePhoneHelperText[this.transalationLanguage];

      this.refereeWebsiteHelperText =
        this.fieldData.refereeWebsiteHelperText[this.transalationLanguage];

      this.refereeNatureOfRelationshipHelperText =
        this.fieldData.refereeNatureOfRelationshipHelperText[
          this.transalationLanguage
        ];

      this.refereeLengthOfRelationHelperText =
        this.fieldData.refereeLengthOfRelationHelperText[
          this.transalationLanguage
        ];

      this.freeFormHelperText =
        this.fieldData.freeFormHelperText[this.transalationLanguage];
    },

    setFiledsPlaceHolderText() {
      // Place Holder Text

      this.refereeNamePlaceHolderText =
        this.fieldData.refereeNamePlaceholderText[this.transalationLanguage];
      this.refereePositionRolePlaceHolderText =
        this.fieldData.refereePositionRolePlaceholderText[
          this.transalationLanguage
        ];
      this.refereeCompanyPlaceHolderText =
        this.fieldData.refereeCompanyPlaceholderText[this.transalationLanguage];

      this.refereeEmailPlaceHolderText =
        this.fieldData.refereeEmailPlaceholderText[this.transalationLanguage];

      this.refereePhonePlaceHolderText =
        this.fieldData.refereePhonePlaceholderText[this.transalationLanguage];

      this.refereeWebsitePlaceHolderText =
        this.fieldData.refereeWebsitePlaceholderText[this.transalationLanguage];

      this.refereeNatureOfRelationshipPlaceHolderText =
        this.fieldData.refereeNatureOfRelationshipPlaceholderText[
          this.transalationLanguage
        ];

      this.refereeLengthOfRelationPlaceHolderText =
        this.fieldData.refereeLengthOfRelationPlaceholderText[
          this.transalationLanguage
        ];

      this.freeFormPlaceHolderText =
        this.fieldData.freeFormPlaceholderText[this.transalationLanguage];
    },

    setFiledsValidationMessage() {
      // Validation Message

      this.refereeNameValidationMessage =
        this.fieldData.refereeNameValidationMessage[this.transalationLanguage];
      this.refereePositionRoleValidationMessage =
        this.fieldData.refereePositionRoleValidationMessage[
          this.transalationLanguage
        ];
      this.refereeCompanyValidationMessage =
        this.fieldData.refereeCompanyValidationMessage[
          this.transalationLanguage
        ];

      this.refereeEmailValidationMessage =
        this.fieldData.refereeEmailValidationMessage[this.transalationLanguage];

      this.refereePhoneValidationMessage =
        this.fieldData.refereePhoneValidationMessage[this.transalationLanguage];

      this.refereeWebsiteValidationMessage =
        this.fieldData.refereeWebsiteValidationMessage[
          this.transalationLanguage
        ];

      this.refereeNatureOfRelationshipValidationMessage =
        this.fieldData.refereeNatureOfRelationshipValidationMessage[
          this.transalationLanguage
        ];

      this.refereeLengthOfRelationValidationMessage =
        this.fieldData.refereeLengthOfRelationValidationMessage[
          this.transalationLanguage
        ];

      this.freeFormValidationMessage =
        this.fieldData.freeFormValidationMessage[this.transalationLanguage];
    },

    setFiledsAttributes() {
      (this.refereeNamePostName =
        this.fieldData.attributes &&
        this.fieldData.attributes.refereeNamePostName
          ? this.fieldData.attributes.refereeNamePostName
          : undefined),
        (this.refereePositionRolePostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.refereePositionRolePostName
            ? this.fieldData.attributes.refereePositionRolePostName
            : undefined),
        (this.refereeCompanyPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.refereeCompanyPostName
            ? this.fieldData.attributes.refereeCompanyPostName
            : undefined),
        (this.refereeEmailPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.refereeEmailPostName
            ? this.fieldData.attributes.refereeEmailPostName
            : undefined),
        (this.refereePhonePostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.refereePhonePostName
            ? this.fieldData.attributes.refereePhonePostName
            : undefined),
        (this.refereeWebsitePostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.refereeWebsitePostName
            ? this.fieldData.attributes.refereeWebsitePostName
            : undefined),
        (this.refereeNatureOfRelationshipPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.refereeNatureOfRelationshipPostName
            ? this.fieldData.attributes.refereeNatureOfRelationshipPostName
            : undefined),
        (this.refereeLengthOfRelationPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.refereeLengthOfRelationPostName
            ? this.fieldData.attributes.refereeLengthOfRelationPostName
            : undefined),
        (this.freeFormPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.freeFormPostName
            ? this.fieldData.attributes.freeFormPostName
            : undefined);
    },

    setFiledsAdvance() {
      (this.refereeNameDisabled =
        this.fieldData.advance && this.fieldData.advance.refereeNameDisabled),
        (this.refereePositionRoleDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.refereePositionRoleDisabled),
        (this.refereeCompanyDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.refereeCompanyDisabled),
        (this.refereeEmailDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.refereeEmailDisabled),
        (this.refereePhoneDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.refereePhoneDisabled),
        (this.refereeWebsiteDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.refereeWebsiteDisabled),
        (this.refereeNatureOfRelationshipDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.refereeNatureOfRelationshipDisabled),
        (this.refereeLengthOfRelationDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.refereeLengthOfRelationDisabled),
        (this.freeFormDisabled =
          this.fieldData.advance && this.fieldData.advance.freeFormDisabled);
    },

    setFiledsValidations() {
      (this.isRequireRefereeName =
        this.fieldData.validation.isRequireRefereeName),
        (this.isRequireRefereePositionRole =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireRefereePositionRole),
        (this.isRequireRefereeCompany =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireRefereeCompany),
        (this.isRequireRefereeEmail =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireRefereeEmail),
        (this.isRequireRefereePhone =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireRefereePhone),
        (this.isRequireRefereeWebsite =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireRefereeWebsite),
        (this.isRequireRefereeNatureOfRelationship =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireRefereeNatureOfRelationship),
        (this.isRequireRefereeLengthOfRelation =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireRefereeLengthOfRelation),
        (this.isRequireFreeForm =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireFreeForm);
    },

    tabLabelClick(language) {
      this.transalationLanguage = language.toLowerCase();

      this.label = this.fieldData.label[this.transalationLanguage];
      this.description = this.fieldData.description[this.transalationLanguage];
      this.refereeNameTitle =
        this.fieldData.refereeNameLabel[this.transalationLanguage];
      this.refereePositionRoleTitle =
        this.fieldData.refereePositionRoleLabel[this.transalationLanguage];
      this.refereeCompanyTitle =
        this.fieldData.refereeCompanyLabel[this.transalationLanguage];
      this.refereeEmailTitle =
        this.fieldData.refereeEmailLabel[this.transalationLanguage];
      this.refereePhoneTitle =
        this.fieldData.refereePhoneLabel[this.transalationLanguage];
      this.refereeWebsiteTitle =
        this.fieldData.refereeWebsiteLabel[this.transalationLanguage];
      this.refereeNatureOfRelationshipTitle =
        this.fieldData.refereeNatureOfRelationshipLabel[
          this.transalationLanguage
        ];
      this.refereeLengthOfRelationTitle =
        this.fieldData.refereeLengthOfRelationLabel[this.transalationLanguage];
      this.freeFormTitle =
        this.fieldData.freeFormLabel[this.transalationLanguage];
    },

    tabHelperTextClick(language) {
      // Helper Text

      this.transalationLanguage = language.toLowerCase();
      this.refereeNameHelperText =
        this.fieldData.refereeNameHelperText[this.transalationLanguage];
      this.refereePositionRoleHelperText =
        this.fieldData.refereePositionRoleHelperText[this.transalationLanguage];
      this.refereeCompanyHelperText =
        this.fieldData.refereeCompanyHelperText[this.transalationLanguage];

      this.refereeEmailHelperText =
        this.fieldData.refereeEmailHelperText[this.transalationLanguage];

      this.refereePhoneHelperText =
        this.fieldData.refereePhoneHelperText[this.transalationLanguage];

      this.refereeWebsiteHelperText =
        this.fieldData.refereeWebsiteHelperText[this.transalationLanguage];

      this.refereeNatureOfRelationshipHelperText =
        this.fieldData.refereeNatureOfRelationshipHelperText[
          this.transalationLanguage
        ];

      this.refereeLengthOfRelationHelperText =
        this.fieldData.refereeLengthOfRelationHelperText[
          this.transalationLanguage
        ];

      this.freeFormHelperText =
        this.fieldData.freeFormHelperText[this.transalationLanguage];
    },

    tabPlaceHolderTextClick(language) {
      // Place Holder Text

      this.transalationLanguage = language.toLowerCase();
      this.refereeNamePlaceHolderText =
        this.fieldData.refereeNamePlaceholderText[this.transalationLanguage];
      this.refereePositionRolePlaceHolderText =
        this.fieldData.refereePositionRolePlaceholderText[
          this.transalationLanguage
        ];
      this.refereeCompanyPlaceHolderText =
        this.fieldData.refereeCompanyPlaceholderText[this.transalationLanguage];

      this.refereeEmailPlaceHolderText =
        this.fieldData.refereeEmailPlaceholderText[this.transalationLanguage];

      this.refereePhonePlaceHolderText =
        this.fieldData.refereePhonePlaceholderText[this.transalationLanguage];

      this.refereeWebsitePlaceHolderText =
        this.fieldData.refereeWebsitePlaceholderText[this.transalationLanguage];

      this.refereeNatureOfRelationshipPlaceHolderText =
        this.fieldData.refereeNatureOfRelationshipPlaceholderText[
          this.transalationLanguage
        ];

      this.refereeLengthOfRelationPlaceHolderText =
        this.fieldData.refereeLengthOfRelationPlaceholderText[
          this.transalationLanguage
        ];

      this.freeFormPlaceHolderText =
        this.fieldData.freeFormPlaceholderText[this.transalationLanguage];
    },

    tabValidationMessageClick(language) {
      // Validation Message

      this.transalationLanguage = language.toLowerCase();
      this.refereeNameValidationMessage =
        this.fieldData.refereeNameValidationMessage[this.transalationLanguage];
      this.refereePositionRoleValidationMessage =
        this.fieldData.refereePositionRoleValidationMessage[
          this.transalationLanguage
        ];
      this.refereeCompanyValidationMessage =
        this.fieldData.refereeCompanyValidationMessage[
          this.transalationLanguage
        ];

      this.refereeEmailValidationMessage =
        this.fieldData.refereeEmailValidationMessage[this.transalationLanguage];

      this.refereePhoneValidationMessage =
        this.fieldData.refereePhoneValidationMessage[this.transalationLanguage];

      this.refereeWebsiteValidationMessage =
        this.fieldData.refereeWebsiteValidationMessage[
          this.transalationLanguage
        ];

      this.refereeNatureOfRelationshipValidationMessage =
        this.fieldData.refereeNatureOfRelationshipValidationMessage[
          this.transalationLanguage
        ];

      this.refereeLengthOfRelationValidationMessage =
        this.fieldData.refereeLengthOfRelationValidationMessage[
          this.transalationLanguage
        ];

      this.freeFormValidationMessage =
        this.fieldData.freeFormValidationMessage[this.transalationLanguage];
    },

    addLabelLanguageToField(language) {
      let tempKey = language.toLowerCase();
      this.fieldData.label[tempKey] = this.label;
      this.fieldData.description[tempKey] = this.description;
      this.fieldData.refereeNameLabel[tempKey] = this.refereeNameTitle;
      this.fieldData.refereePositionRoleLabel[tempKey] =
        this.refereePositionRoleTitle;
      this.fieldData.refereeCompanyLabel[tempKey] = this.refereeCompanyTitle;
      this.fieldData.refereeEmailLabel[tempKey] = this.refereeEmailTitle;
      this.fieldData.refereePhoneLabel[tempKey] = this.refereePhoneTitle;
      this.fieldData.refereeWebsiteLabel[tempKey] = this.refereeWebsiteTitle;
      this.fieldData.refereeNatureOfRelationshipLabel[tempKey] =
        this.refereeNatureOfRelationshipTitle;
      this.fieldData.refereeLengthOfRelationLabel[tempKey] =
        this.refereeLengthOfRelationTitle;
      this.fieldData.freeFormLabel[tempKey] = this.freeFormTitle;
    },

    addHelperTextLanguageToField(language) {
      // Helper Text

      let tempKey = language.toLowerCase();
      this.fieldData.refereeNameHelperText[tempKey] =
        this.refereeNameHelperText;
      this.fieldData.refereePositionRoleHelperText[tempKey] =
        this.refereePositionRoleHelperText;
      this.fieldData.refereeCompanyHelperText[tempKey] =
        this.refereeCompanyHelperText;
      this.fieldData.refereeEmailHelperText[tempKey] =
        this.refereeEmailHelperText;
      this.fieldData.refereePhoneHelperText[tempKey] =
        this.refereePhoneHelperText;
      this.fieldData.refereeWebsiteHelperText[tempKey] =
        this.refereeWebsiteHelperText;
      this.fieldData.refereeNatureOfRelationshipHelperText[tempKey] =
        this.refereeNatureOfRelationshipHelperText;
      this.fieldData.refereeLengthOfRelationHelperText[tempKey] =
        this.refereeLengthOfRelationHelperText;
      this.fieldData.freeFormHelperText[tempKey] = this.freeFormHelperText;
    },

    addPlaceHolderTextLanguageToField(language) {
      // Place Holder Text

      let tempKey = language.toLowerCase();
      this.fieldData.refereeNamePlaceholderText[tempKey] =
        this.refereeNamePlaceHolderText;
      this.fieldData.refereePositionRolePlaceholderText[tempKey] =
        this.refereePositionRolePlaceHolderText;
      this.fieldData.refereeCompanyPlaceholderText[tempKey] =
        this.refereeCompanyPlaceHolderText;
      this.fieldData.refereeEmailPlaceholderText[tempKey] =
        this.refereeEmailPlaceHolderText;
      this.fieldData.refereePhonePlaceholderText[tempKey] =
        this.refereePhonePlaceHolderText;
      this.fieldData.refereeWebsitePlaceholderText[tempKey] =
        this.refereeWebsitePlaceHolderText;
      this.fieldData.refereeNatureOfRelationshipPlaceholderText[tempKey] =
        this.refereeNatureOfRelationshipPlaceHolderText;
      this.fieldData.refereeLengthOfRelationPlaceholderText[tempKey] =
        this.refereeLengthOfRelationPlaceHolderText;
      this.fieldData.freeFormPlaceholderText[tempKey] =
        this.freeFormPlaceHolderText;
    },

    addValidationMessageLanguageToField(language) {
      // Validation Message

      let tempKey = language.toLowerCase();
      this.fieldData.refereeNameValidationMessage[tempKey] =
        this.refereeNameValidationMessage;
      this.fieldData.refereePositionRoleValidationMessage[tempKey] =
        this.refereePositionRoleValidationMessage;
      this.fieldData.refereeCompanyValidationMessage[tempKey] =
        this.refereeCompanyValidationMessage;
      this.fieldData.refereeEmailValidationMessage[tempKey] =
        this.refereeEmailValidationMessage;
      this.fieldData.refereePhoneValidationMessage[tempKey] =
        this.refereePhoneValidationMessage;
      this.fieldData.refereeWebsiteValidationMessage[tempKey] =
        this.refereeWebsiteValidationMessage;
      this.fieldData.refereeNatureOfRelationshipValidationMessage[tempKey] =
        this.refereeNatureOfRelationshipValidationMessage;
      this.fieldData.refereeLengthOfRelationValidationMessage[tempKey] =
        this.refereeLengthOfRelationValidationMessage;
      this.fieldData.freeFormValidationMessage[tempKey] =
        this.freeFormValidationMessage;
    },

    setValidation() {
      let validation = {
        isRequireRefereeName: this.refereeNameDisabled
          ? false
          : this.isRequireRefereeName,
        isRequireRefereePositionRole: this.refereePositionRoleDisabled
          ? false
          : this.isRequireRefereePositionRole,
        isRequireRefereeCompany: this.refereeCompanyDisabled
          ? false
          : this.isRequireRefereeCompany,
        isRequireRefereeEmail: this.refereeEmailDisabled
          ? false
          : this.isRequireRefereeEmail,
        isRequireRefereePhone: this.refereePhoneDisabled
          ? false
          : this.isRequireRefereePhone,
        isRequireRefereeWebsite: this.refereeWebsiteDisabled
          ? false
          : this.isRequireRefereeWebsite,
        isRequireRefereeNatureOfRelationship: this
          .refereeNatureOfRelationshipDisabled
          ? false
          : this.isRequireRefereeNatureOfRelationship,
        isRequireRefereeLengthOfRelation: this.refereeLengthOfRelationDisabled
          ? false
          : this.isRequireRefereeLengthOfRelation,
        isRequireFreeForm: this.freeFormDisabled
          ? false
          : this.isRequireFreeForm
      };
      Object.assign(this.fieldData, { validation });
    },

    updateAdvancedSettings() {
      let advance = {
        refereeNameDisabled: this.refereeNameDisabled,
        refereePositionRoleDisabled: this.refereePositionRoleDisabled,
        refereeCompanyDisabled: this.refereeCompanyDisabled,
        refereeEmailDisabled: this.refereeEmailDisabled,
        refereePhoneDisabled: this.refereePhoneDisabled,
        refereeWebsiteDisabled: this.refereeWebsiteDisabled,
        refereeNatureOfRelationshipDisabled:
          this.refereeNatureOfRelationshipDisabled,
        refereeLengthOfRelationDisabled: this.refereeLengthOfRelationDisabled,
        freeFormDisabled: this.freeFormDisabled
      };
      Object.assign(this.fieldData, { advance });
    },

    setAttributes() {
      let attributes = {
        refereeNamePostName: this.refereeNamePostName,
        refereePositionRolePostName: this.refereePositionRolePostName,
        refereeCompanyPostName: this.refereeCompanyPostName,
        refereeEmailPostName: this.refereeEmailPostName,
        refereePhonePostName: this.refereePhonePostName,
        refereeWebsitePostName: this.refereeWebsitePostName,
        refereeNatureOfRelationshipPostName:
          this.refereeNatureOfRelationshipPostName,
        refereeLengthOfRelationPostName: this.refereeLengthOfRelationPostName,
        freeFormPostName: this.freeFormPostName
      };
      Object.assign(this.fieldData, { attributes });
    },
    getCurrentSectionList() {
      let conditionalSectionElements =
        this.form.formSection[this.sectionIndex].formElements;
      conditionalSectionElements = conditionalSectionElements.filter(
        (element) => {
          if (element.inputType === "radio") {
            // return element
            let dd = {
              label: element.label[this.transalationLanguage],
              element
            };
            return this.conditionalSectionElementLables.push(dd);
          }
        }
      );
      this.conditionalSectionElements = conditionalSectionElements;
    },

    getSelectedElement(selectedElement) {
      this.selectedElementOption = [];
      this.conditionalFieldValue = null;
      selectedElement.element.options.map((opt) => {
        this.selectedElementOption.push(opt.value);
      });
    },

    setFieldConditions() {
      if (this.conditionalField && this.conditionalFieldValue) {
        let condition = {
          id: uid(),
          sourceFieldId: this.conditionalField.element.id,
          targetFieldId: this.fieldData.id,
          value: this.conditionalFieldValue,
          label: this.conditionalField.label
        };
        let elements = [
          ...this.form.formSection[this.sectionIndex].formElements
        ];
        elements.map((element) => {
          if (element.options) {
            element.options.map((opt) => {
              if (
                opt.value === this.conditionalFieldValue &&
                this.conditionalField.element.id === element.id
              ) {
                element.conditionalFields.push(condition);
              }
            });
          }
        });
        this.fieldData.isConditional = this.isConditional;
        this.fieldData.isHidden = true;
        // Object.assign(this.fieldData, { condition });
        this.fieldData.conditions.push(condition);
        this.conditionalFieldValue = null;
        this.conditionalField = null;
      }
    },

    onRemoveConditionOpt(optId, index) {
      if (optId && index !== -1) {
        this.fieldData.conditions.splice(index, 1);
      }
    }
  }
};
</script>
<style>
.cursor {
  cursor: pointer;
}
</style>
