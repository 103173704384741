<template>
  <div class="financial-information">
    <BaseInfoBox
      v-if="isDnbDisabled"
      :text="thirdPartyInfoMessages.ENABLE_PREMIUM_CORPORATE_DATA"
      data-test-id="financial-information__disable-text--dnb-disabled"
      :icon-options="defaultIcons.INFO"
      :theme="themes.INFO"
    />
    <div v-else>
      <BaseInfoBox
        data-test-id="financial-information__disable-text--dnb-not-verified"
        v-if="!isDnbVerified"
        :text="thirdPartyInfoMessages.ADD_PREMIUM_CORPORATE_DATA"
        :icon-options="defaultIcons.INFO"
        :theme="themes.INFO"
      />
      <div
        data-test-id="financial-information__table"
        class="financial-information__table"
      >
        <StickyTable
          caption="Financial Information"
          :table-headers="financialInformationTableHeaders"
          :table-rows="financialInformationTableRows"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StickyTable from "@/molecules/StickyTable/StickyTable";
import {
  typographySize,
  defaultIcons,
  themes,
  thirdPartyInfoMessages
} from "@/constants";
import BaseInfoBox from "@/atoms/BaseInfoBox/BaseInfoBox";
import BaseText from "@/atoms/BaseText/BaseText";

export default {
  name: "FinancialInformation",
  components: {
    StickyTable,
    BaseInfoBox
  },
  props: {
    premiumCorporateData: {
      type: Object,
      default: () => ({})
    },
    isDnbDisabled: {
      type: Boolean,
      default: false
    },
    isDnbVerified: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      financialInformationTableHeaders: [
        {
          value: "label",
          styles: {
            display: "none"
          }
        },
        {
          value: "value",
          styles: {
            display: "none"
          }
        }
      ],
      financialInformationTableDataMap: [
        {
          label: "Accountants",
          value:
            this.premiumCorporateData?.financials?.[0]?.accountantName || ""
        },
        {
          label: "Yearly Revenue",
          value:
            this.premiumCorporateData?.financials?.[0]?.yearlyRevenue?.[0]
              ?.value || ""
        },
        {
          label: "Currency",
          value:
            this.premiumCorporateData?.financials?.[0]?.yearlyRevenue?.[0]
              ?.currency || ""
        },
        {
          label: "Growth Rate",
          value: this.getGrowthRate()
        }
      ],
      financialInformationTableRows: [],
      typographySize,
      defaultIcons,
      themes,
      thirdPartyInfoMessages
    };
  },
  created() {
    this.setTableData();
  },
  methods: {
    getGrowthRate() {
      return this.premiumCorporateData?.financials?.[0]?.yearlyRevenue?.[0]?.trend?.map(
        (trend) => [trend.timePeriod.description, ":-", trend.growthRate]
      );
    },
    setTableData() {
      this.setFinancialInformationTableRows();
    },
    setFinancialInformationTableRows() {
      this.financialInformationTableRows = this.makeTableRowData(
        this.financialInformationTableDataMap
      );
    },
    makeTableRowData(dataMap) {
      return dataMap.map(({ label, value }) => ({
        label: {
          component: BaseText,
          componentOptions: {
            tag: "span",
            text: label,
            size: typographySize.BODY_TEXT_BOLD
          },
          styles: {
            border: "none"
          }
        },
        value: {
          component: BaseText,
          componentOptions: {
            tag: "span",
            text: [value]
          },
          styles: {
            border: "none"
          }
        }
      }));
    }
  }
};
</script>
<style lang="scss" scoped>
.financial-information {
  &__disable-text {
    text-align: center;
  }

  &__table {
    margin-top: $spacing8;
  }
}
</style>
