<template>
  <div data-test-id="icon-with-text" class="icon-with-text">
    <BaseIcon
      v-bind="iconOptions"
      data-test-id="icon-with-text__logo"
      class="icon-with-text__logo"
    />
    <BaseText
      v-bind="combinedTextOptions"
      data-test-id="icon-with-text__text"
      class="icon-with-text__text"
      hasDefaultSpacingRemoved
    />
  </div>
</template>

<script>
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import BaseText from "@/atoms/BaseText/BaseText";
import { themes, typographySize, icons } from "@/constants";

export default {
  name: "IconWithText",
  components: {
    BaseIcon,
    BaseText
  },
  props: {
    iconOptions: {
      type: Object,
      default: () => ({})
    },
    textOptions: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    combinedTextOptions() {
      return {
        tag: "p",
        ...this.textOptions
      };
    }
  },
  data() {
    return {
      typographySize,
      themes,
      icons
    };
  }
};
</script>

<style lang="scss" scoped>
.icon-with-text {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__logo {
    margin-bottom: 5px;
  }

  :deep(.icon-with-text__text .base-text) {
    margin-bottom: 0;
    word-break: break-word;
  }
}
</style>
