const changeLogConstants = {
  VALUE_CREATED: "created",
  VALUE_UPDATED: "updated",
  VALUE_DELETED: "deleted",
  VALUE_UNCHANGED: "---",
  KEY_ORIGINAL: "_original",
  KEY_UPDATED: "_updated"
};

export { changeLogConstants };
