import { endpoints } from "@/constants";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { makeLanguagesDTO } from "./languages.dto";

const fetchLanguages = async () => {
  const result = await HTTP(
    "get",
    endpoints.FETCH_LANGUAGES,
    null,
    makeAuthorizationHeader()
  );
  result.data = makeLanguagesDTO({
    languages: result?.data?.EmailLanguages
  });
  return result;
};

export const languagesService = {
  fetchLanguages
};
