import { expectedDataService } from "@/services";
import { mutationName } from "@/constants";

export const actions = {
  async fetchExpectedData({ rootState, commit }) {
    try {
      commit(mutationName.EXPECTED_DATA.SET_IS_LOADING_EXPECTED_DATA, true, {
        root: true
      });
      const { data = {} } = await expectedDataService.fetchExpectedData(
        rootState.company.companyId
      );
      commit(mutationName.EXPECTED_DATA.SET_EXPECTED_DATA, data, {
        root: true
      });
    } finally {
      commit(mutationName.EXPECTED_DATA.SET_IS_LOADING_EXPECTED_DATA, false, {
        root: true
      });
    }
  },
  async fetchEntitiesDynamicReference({ commit, state }, id) {
    let entityIndex;
    const { referenceUrl } = state.expectedData.find((data, index) => {
      entityIndex = index;
      return data?.context?.formId === id;
    });
    const { data = {} } =
      await expectedDataService.fetchEntitiesDynamicReference(referenceUrl);
    commit(
      mutationName.EXPECTED_DATA.SET_EXPECTED_DATA_ENTITY_REFERENCES,
      {
        references: data,
        index: entityIndex
      },
      {
        root: true
      }
    );
    return entityIndex;
  }
};
