import { multiSelectSearchKeys, operations } from "@/constants";
import { makeUUID, getAllSelectedOptionValues } from "@/utils";

const makeOptionsForMultiSelect = (
  selectedValues = [],
  amendableValues = [],
  options = []
) =>
  options?.reduce(
    (
      acc,
      { text, value, id, isRemovedAtDeselection, ...customProperties },
      index
    ) => {
      const isSelected = selectedValues?.includes(value);
      const isAmendable = amendableValues?.includes(value);
      const option = {
        ...customProperties,
        [multiSelectSearchKeys.ID]: id || makeUUID(),
        [multiSelectSearchKeys.TEXT]: options[index].hasOwnProperty("text")
          ? text
          : "",
        [multiSelectSearchKeys.VALUE]: options[index].hasOwnProperty("value")
          ? value
          : "",
        [multiSelectSearchKeys.SELECTED]: isSelected || false,
        [multiSelectSearchKeys.HIDDEN]: isSelected || false,
        [multiSelectSearchKeys.AMENDABLE]: isAmendable || false,
        [multiSelectSearchKeys.IS_REMOVED_AT_DESELECTION]:
          !!isRemovedAtDeselection
      };

      if (isRemovedAtDeselection && isSelected) {
        acc.push(option);
      } else if (!isRemovedAtDeselection) {
        acc.push(option);
      }
      return acc;
    },
    []
  );

const makeLiteralOptionForMultiSelect = ({
  text,
  value,
  ...customProperties
}) => ({
  [multiSelectSearchKeys.ID]: makeUUID(),
  [multiSelectSearchKeys.TEXT]: text,
  [multiSelectSearchKeys.VALUE]: value,
  [multiSelectSearchKeys.SELECTED]: true,
  [multiSelectSearchKeys.HIDDEN]: false,
  [multiSelectSearchKeys.AMENDABLE]: true,
  [multiSelectSearchKeys.IS_REMOVED_AT_DESELECTION]: true,
  ...customProperties
});

const makeMultiSelectSelectedValues = ({
  options = [],
  eventType = "",
  value,
  _getAllSelectedOptionValues = getAllSelectedOptionValues
} = {}) => {
  let selectedValues = _getAllSelectedOptionValues(options);
  if (eventType === operations.ADD) {
    if (Array.isArray(value)) {
      selectedValues = [...selectedValues, ...value];
    } else {
      selectedValues.push(value);
    }
  } else if (eventType === operations.DELETE) {
    selectedValues = selectedValues.filter(
      (currentValue) => currentValue !== value
    );
  }

  return selectedValues;
};

export {
  makeLiteralOptionForMultiSelect,
  makeOptionsForMultiSelect,
  makeMultiSelectSelectedValues
};
