import { state as makeState } from "./taskTemplates.state.store";

export const mutations = {
  setIsLoadingTaskTemplates(state, payload) {
    state.isLoadingTaskTemplates = payload;
  },
  setIsLoadingTaskTemplate(state, payload) {
    state.isLoadingTaskTemplate = payload;
  },
  setTaskTemplates(state, payload) {
    state.taskTemplates = payload;
  },
  setTaskTemplate(state, payload) {
    state.taskTemplate = payload;
  },
  resetTaskTemplates(state) {
    Object.assign(state, makeState());
  },
  resetTaskTemplate(state) {
    state.taskTemplate = {};
  }
};
