import { state as makeState } from "./thirdParty.state.store";

export const mutations = {
  setTCertificationOrderTypes(state, payload) {
    state.tCertificationOrderTypes = payload;
  },
  setESGMetricsOrderTypes(state, payload) {
    state.ESGMetricsOrderTypes = payload;
  },
  setFormsLanguagesList(state, payload) {
    state.formsLanguagesList = payload;
  },
  resetThirdParty(state) {
    Object.assign(state, makeState());
  },
  setIsThirdPartyAddModalVisible(state, value) {
    state.isThirdPartyAddModalVisible = value;
  },
  setIsBulkUploadModalVisible(state, value) {
    state.isBulkUploadModalVisible = value;
  },
  setIsThirdPartyNewModalVisible(state, value) {
    state.isAddThirdPartyModalNewVisible = value;
  },
  setThirdPartyData(state, data) {
    state.thirdPartyData = data;
  },
  resetThirdPartyData(state) {
    state.thirdPartyData = null;
  },
  setOCStatesList(state, payload) {
    state.OCStatesList = payload;
  },
  setHeaderConfigData(state, payload) {
    state.headerConfigData = payload;
  },
  setEddClientAllPackages(state, payload) {
    state.EDDClientAllPackages = payload;
  },
  setCustomAttributes(state, payload) {
    state.customAttributes = payload;
  },
  setIsUBODataLoading(state, payload) {
    state.isUBODataLoading = payload;
  },
  setThirdPartyUBOList(state, payload) {
    state.thirdPartyUBOList = payload;
  },
  setThirdPartyUBOError(state, payload) {
    state.thirdPartyUBOError = payload;
  },
  setCreateThirdPartyError(state, payload) {
    state.createThirdPartyError = payload;
  },
  setIsCreatingThirdParty(state, payload) {
    state.isCreatingThirdParty = payload;
  },
  setIsCreatingThirdPartySuccessful(state, payload) {
    state.isCreatingThirdPartySuccessful = payload;
  },
  setThirdPartyTrainingList(state, payload) {
    state.thirdPartyTrainingList = payload;
  },
  resetThirdPartyTrainingList(state) {
    state.thirdPartyTrainingList = [];
  },
  setIsLoadingThirdPartyTrainingList(state, value) {
    state.isLoadingThirdPartyTrainingList = value;
  },
  setIsLoadingThirdPartyTrainingDetails(state, value) {
    state.isLoadingThirdPartyTrainingDetails = value;
  },
  setThirdPartyTrainingDetails(state, payload) {
    state.thirdPartyTraining = payload;
  },
  resetThirdPartyTraining(state) {
    state.thirdPartyTraining = null;
  },
  setIsLoadingSendEmailForThirdPartyUpdateExcelSheet(state, value) {
    state.isLoadingSendEmailForThirdPartyUpdateExcelSheet = value;
  },
  setIsLoadingThirdPartyBulkUploadSampleFile(state, value) {
    state.isLoadingThirdPartyBulkUploadSampleFile = value;
  }
};
